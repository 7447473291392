import React, { Component } from "react";
import { connect } from "react-redux";
import { workplaceHOC } from "../WorkplaceHOC";
import MergeWorkFunction from "./children/MergeWorkFunction";
import actions from "@/action";
import Utils from "@/utils/utils";
import ReactGA from "react-ga";
import TipsModal from "@/components/common/TipsModal";
import "@/assets/mergeWork.scss";
import { request } from "@/api/request";

class MergeWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recommendFile: null,
      warningInfo: false,
      warningInfoText: "",
      warningInfoObj: {},
    };
  }

  draggerProps = {
    accept: ".MKV, .MOV, .MP4, .AVI, .WMV, .VOB, .WEBM, .3GP, .ASF, .F4V, .FLV, .MPG, .MPEG, .RM, .RMVB, .MTS, .MXF, .OGV",
    beforeUpload: file => {
      // if (file.size >= 20 * 1024 * 1024) {
      // this.setState({
      //   warningInfo: true,
      //   warningInfoText: "Max file size for videos is 20 MB.",
      //   warningInfoObj: {
      //     type: "size",
      //     size: (file.size / 1024 / 1024).toFixed(2),
      //   },
      // });
      // } else
      if (!Utils.validate(file)) {
        this.setState({
          warningInfo: true,
          warningInfoText: "The video format is not supported.",
          warningInfoObj: {
            type: "format",
            format: /\.[^.]+$/.exec(file.name)[0].toLocaleLowerCase(),
          },
        });
      } else {
        if (file.size >= 20 * 1024 * 1024) {
          if (Utils.isNeedToShowPurchaseModal()) {
            this.props.onPurchaseModalObjChange({
              visible: true,
              type: "uploadSizeBiggerThanLimit",
            });
            return false;
          }
        }
        this.props.onCurrFileChange(null);
        this.props.onPreUploadFileChange(file);
      }
      return false;
    },
  };

  handleResolutionChange = value => {
    this.props.onResolutionChange(value);
    localStorage.setItem("resolution", value);
    this.props.onUserActionChange(true);
  };

  handleSubmit = async () => {
    const startTime = { ...this.props.startTime };
    startTime.merge = new Date();
    this.props.onStartTimeChange(startTime);
    if (!this.props.uploading && this.props.uploadPercent < 100) {
      this.props.onProcessPercentChange(1);
      this.props.onProcessStepChange(1);
    }
    const actionsList = [];
    const mergeUrlList = [];
    let newMergeWorkList = this.getNewMergeWork();
    for (let i = 0; i < newMergeWorkList.length; i++) {
      actionsList.push(this.getResolutionVideo(newMergeWorkList, i));
      mergeUrlList.push(newMergeWorkList[i].videofileurl);
    }
    // console.log(actionsList, mergeUrlList);
    let videoResponse;
    try {
      videoResponse = await this.getMergeVideo(mergeUrlList, actionsList);
    } catch (error) {
      this.props.handleSubmitFailModal(true);
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "Merge",
          action: "MergeFail",
          label: error + "_none_none_" + this.props.tokenId + "_none_none",
        },
        ["OnlineTracker", "Tracker"]
      );
      return false;
    }
    if (videoResponse.code === 200) {
      let guids = [];
      try {
        guids = await this.props.getNewVideoByProgress("merge", videoResponse);
      } catch (error) {
        if (error !== "cancel") {
          ReactGA.event(
            {
              category: "Speed",
              action: "SpeedFail",
              label: "concat_200_200_" + error,
            },
            ["OnlineTracker", "Tracker"]
          );
        }
        return false;
      }
      // const modal = {
      //   getContainer: document.querySelector(".Home"),
      //   show: true,
      //   type: "mergeSuccess",
      //   title: "Generate Files Successfully!",
      //   guid: guids[0],
      //   buttons: [
      //     {
      //       type: "download",
      //       text: "Download",
      //     },
      //     {
      //       type: "editNew",
      //       text: "Edit New Video",
      //     },
      //   ],
      // };
      // this.props.onFileListModalChange(modal);
      this.props.onSuccessFileGuidsChange(guids);
      this.props.onPrevWorkChange("merge");
      // this.props.onWorkChange(null);
      this.props.onProcessPercentChange(0);
      this.props.onUploadPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "Merge",
          action: "MergSuccess",
          label: "1",
        },
        ["OnlineTracker", "Tracker"]
      );
    } else {
      if (videoResponse.code === 402102) {
        this.props.onPurchaseModalObjChange({
          visible: true,
          type: "videoEditorTimesMoreThanLimit",
        });
      } else {
        this.props.handleSubmitFailModal(true);
      }
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "Merge",
          action: "MergeFail",
          label: "concat_200_" + videoResponse.code + "_none_" + this.props.tokenId + "_none_" + videoResponse.msg,
        },
        ["OnlineTracker", "Tracker"]
      );
    }
  };

  getResolutionVideo = (arr, i) => {
    // console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx", JSON.stringify(arr));
    // 选取宽度最大的视频最为目标视频容器的宽
    let baseVideo = arr[0];
    arr.forEach(item => {
      if (item.wh.substring(0, item.wh.indexOf("x")) > baseVideo.wh.substring(0, item.wh.indexOf("x"))) {
        baseVideo = item;
      }
    });
    let warpW = baseVideo.wh.substring(0, baseVideo.wh.indexOf("x"));
    warpW = warpW % 2 === 0 ? warpW : warpW * 1 + 1;
    let warpH = parseInt(warpW / this.props.resolution);
    warpH = warpH % 2 === 0 ? warpH : warpH * 1 + 1;
    const w = arr[i].wh.substring(0, arr[i].wh.indexOf("x")) * 1;
    const h = arr[i].wh.substring(arr[i].wh.indexOf("x") + 1) * 1;
    let videoW;
    let videoH;
    if (w / h >= this.props.resolution) {
      videoW = warpW;
      videoH = parseInt((videoW / w) * h);
    } else {
      videoH = warpH;
      videoW = parseInt((videoH * w) / h);
    }
    let actions = [];
    if (this.props.videoBgColor === "blur") {
      let aspect;
      switch (this.props.resolution) {
        case 1.77:
          aspect = "16:9";
          break;
        case 0.56:
          aspect = "9:16";
          break;
        case 1.33:
          aspect = "4:3";
          break;
        case 1.25:
          aspect = "5:4";
          break;
        default:
          aspect = "1:1";
          break;
      }
      actions.push({
        type: "blur",
        direction: w / h >= this.props.resolution ? "vertical" : "horizontal",
        scale: `${warpW}:${warpH}`,
        aspect,
      });
    } else {
      actions.push({
        type: "cropblack",
        pad: `${warpW}:${warpH}:${(warpW - videoW) / 2}:${(warpH - videoH) / 2}`,
        scale: `${videoW}:${videoH}`,
        color: this.props.videoBgColor,
      });
    }
    return actions;
  };

  getMergeVideo = (mergeUrlList, actionsList) => {
    const formData = new FormData();
    formData.append("ext", "mp4");
    formData.append("token", this.props.tokenId);
    for (let i = 0; i < mergeUrlList.length; i++) {
      formData.append("video_url[]", mergeUrlList[i]);
    }
    for (let i = 0; i < actionsList.length; i++) {
      formData.append("add_action[]", `{"actions": ${JSON.stringify(actionsList[i])}}`);
    }
    return new Promise((resolve, reject) => {
      request("concat", {
        method: "post",
        body: formData,
      })
        .then(responseJSON => {
          resolve(responseJSON);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  preSubmit = () => {
    ReactGA.event(
      {
        category: "Merge",
        action: "MergeSave",
        label: this.props.mergeWorkList.length + "_" + this.props.resolution + "_" + this.props.videoBgColor,
      },
      ["OnlineTracker", "Tracker"]
    );
    //函数返回true，则是当前已经超过限制
    let flag = this.props.handleVideoEditorTimes();
    if (!flag) {
      this.props.preSubmit(1, this.handleSubmit);
    }
  };

  handleSetColor = value => {
    if (value.hex === "#000") {
      this.props.onVideoBgColorChange("blur");
    } else {
      this.props.onVideoBgColorChange(value.hex);
      this.props.onUserActionChange(true);
    }
  };

  removeMergeWork = (event, index) => {
    event.stopPropagation();
    const mergeWorkList = [...this.props.mergeWorkList];
    if (this.props.currFile) {
      const currIndex = Utils.getIndex(this.props.currFile, mergeWorkList);
      console.log(this.props.currFile, currIndex, index);
      mergeWorkList.splice(index, 1);
      if (currIndex === index) {
        this.props.onCurrFileChange(mergeWorkList[mergeWorkList.length - 1]);
      }
    } else {
      mergeWorkList.splice(index, 1);
    }
    this.props.onMergeWorkListChange(mergeWorkList);
  };

  getNewMergeWork = () => {
    const { files, mergeWorkList } = this.props;
    let newMergeWorkList = [];
    mergeWorkList.forEach(item => {
      if (!item.uploadStatus) {
        newMergeWorkList.push(files.filter(file => file.guid === item.guid)[0]);
      } else {
        newMergeWorkList.push(item);
      }
    });
    this.props.onMergeWorkListChange(newMergeWorkList);
    return newMergeWorkList;
  };

  handleQuickSetColor = color => {
    this.props.onVideoBgColorChange(color);
    this.props.onUserActionChange(true);
  };

  handleMergeItemClick = (obj, i) => {
    this.props.onCurrFileChange(obj);
  };

  orderMergeList = (dragIndex, hoverIndex) => {
    const mergeWorkList = [...this.props.mergeWorkList];
    this.setState({
      nextCurrFile: mergeWorkList[dragIndex],
    });
    mergeWorkList[dragIndex] = mergeWorkList.splice(hoverIndex, 1, mergeWorkList[dragIndex])[0];
    this.props.onMergeWorkListChange(mergeWorkList);
  };

  setCurrFile = () => {
    if (this.state.nextCurrFile) {
      this.props.onCurrFileChange(this.state.nextCurrFile);
    }
  };

  recommendFileConfirm = () => {
    const mergeWorkList = [...this.props.mergeWorkList];
    mergeWorkList.push(this.state.recommendFile);
    this.props.onMergeWorkListChange(mergeWorkList);
    this.setState({ recommendFile: null });
    ReactGA.event(
      {
        category: "Merge",
        action: "MergeClickTip",
      },
      ["OnlineTracker", "Tracker"]
    );
  };

  componentDidMount() {
    this.props.onResolutionChange(1);
    const mergeWorkList = [...this.props.mergeWorkList];
    const videoFileList = this.props.files.filter(item => item.videofileurl && item.status !== "gif");
    if (this.props.currFile && Utils.getIndex(this.props.currFile, mergeWorkList) === null) {
      mergeWorkList.push(this.props.currFile);
    }
    if (this.props.files.length > 0 && Utils.getIndex(videoFileList[videoFileList.length - 1], mergeWorkList) === null) {
      this.setState({ recommendFile: videoFileList[videoFileList.length - 1] });
      ReactGA.event(
        {
          category: "Merge",
          action: "MergeTip",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    this.props.onMergeWorkListChange(mergeWorkList);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mergeWorkList !== this.props.mergeWorkList && this.props.mergeWorkList.length >= 4) {
      this.setState({
        warningInfo: true,
        warningInfoText: "Add 3 videos at most.",
        warningInfoObj: { type: "merge" },
      });
      ReactGA.event(
        {
          category: "Merge",
          action: "MergOver10",
        },
        ["OnlineTracker", "Tracker"]
      );
      const mergeWorkList = [...this.props.mergeWorkList];
      mergeWorkList.splice(mergeWorkList.length - 1, 1);
      this.props.onMergeWorkListChange(mergeWorkList);
      const currFile = this.props.files.filter(item => item.guid === this.props.mergeWorkList[2].guid)[0];
      this.props.onCurrFileChange(currFile);
    }
    // 预览区视频变化
    if (this.props.currFile && prevProps.currFile !== this.props.currFile && Utils.getIndex(this.props.currFile, this.props.mergeWorkList) === null) {
      const mergeWorkList = [...this.props.mergeWorkList];
      mergeWorkList.push({ ...this.props.currFile });
      this.props.onMergeWorkListChange(mergeWorkList);
    }
    // 合并列表删除选中视频
    // if(!this.props.currFile && prevProps.currFile !== this.props.currFile && this.props.mergeWorkList.length > 0 && !this.props.uploading){
    //   const mergeWorkList = [...this.props.mergeWorkList]
    //   this.props.onCurrFileChange(mergeWorkList[mergeWorkList.length - 1])
    // }
    // 文件列表有变化
    if (this.props.files !== prevProps.files) {
      // console.log("===ddd", prevProps.files);
      const files = [...this.props.files];
      const mergeWorkList = [...this.props.mergeWorkList];
      const mergeWorkList_new = [];
      mergeWorkList.forEach((item, i) => {
        if (files.some(file => file.guid === item.guid)) {
          mergeWorkList_new.push(item);
        }
      });
      this.props.onMergeWorkListChange(mergeWorkList_new);
      setTimeout(() => {
        if (!this.props.currFile) {
          this.props.onCurrFileChange(mergeWorkList_new[mergeWorkList_new.length - 1]);
        }
      }, 500);
    }
  }

  render() {
    return (
      <div
        style={{ height: "100%" }}
        onClick={() => {
          this.setState({ recommendFile: null });
        }}
      >
        <MergeWorkFunction
          mergeWorkList={this.props.mergeWorkList}
          currFile={this.props.currFile}
          videoBgColor={this.props.videoBgColor}
          resolution={this.props.resolution}
          draggerProps={this.draggerProps}
          recommendFile={this.state.recommendFile}
          recommendFileConfirm={this.recommendFileConfirm}
          handleQuickSetColor={this.handleQuickSetColor}
          handleResolutionChange={this.handleResolutionChange}
          handleSetColor={this.handleSetColor}
          handleMergeItemClick={this.handleMergeItemClick}
          removeMergeWork={this.removeMergeWork}
          orderMergeList={this.orderMergeList}
          setCurrFile={this.setCurrFile}
          preSubmit={this.preSubmit}
        />
        <TipsModal
          showGuidText
          visible={this.state.warningInfo}
          onCancel={() => {
            this.setState({ warningInfo: false });
          }}
          content={this.state.warningInfoText}
          tipsObj={this.state.warningInfoObj}
          cancelBtnHandle={() => {
            this.setState({ warningInfo: false });
          }}
          hasDownload
          productKey={window.userClient.platform === "win" ? "hitpaw-video-editor" : undefined}
        />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  fileListModal: state.common.fileListModal,
  resolution: state.work.resolution,
  videoBgColor: state.work.videoBgColor,
  tokenId: state.common.tokenId,
  mergeWorkList: state.work.mergeWorkList,
  startTime: state.work.startTime,
  uploading: state.work.uploading,
  processStep: state.work.processStep,
  processPercent: state.work.processPercent,
  cancelProgress: state.work.cancelProgress,
  submitting: state.work.submitting,
  uploadPercent: state.work.uploadPercent,
});
const mapDispatchToProps = dispatch => ({
  onFilesChange: files => dispatch(actions.onFilesChange(files)),
  onFileListModalChange: fileListModal => dispatch(actions.onFileListModalChange(fileListModal)),
  onResolutionChange: resolution => dispatch(actions.onResolutionChange(resolution)),
  onVideoBgColorChange: videoBgColor => dispatch(actions.onVideoBgColorChange(videoBgColor)),
  onPreUploadFileChange: preUploadFile => dispatch(actions.onPreUploadFileChange(preUploadFile)),
  onProcessPercentChange: processPercent => dispatch(actions.onProcessPercentChange(processPercent)),
  onProcessStepChange: processStep => dispatch(actions.onProcessStepChange(processStep)),
  onMergeWorkListChange: mergeWorkList => dispatch(actions.onMergeWorkListChange(mergeWorkList)),
  onStartTimeChange: startTime => dispatch(actions.onStartTimeChange(startTime)),
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
  onUserActionChange: userAction => dispatch(actions.onUserActionChange(userAction)),
  onSubmittingChange: submitting => dispatch(actions.onSubmittingChange(submitting)),
  onUploadAgainFileChange: uploadAgainFile => dispatch(actions.onUploadAgainFileChange(uploadAgainFile)),
  onUploadPercentChange: uploadPercent => dispatch(actions.onUploadPercentChange(uploadPercent)),
  onWorkChange: work => dispatch(actions.onWorkChange(work)),
  onPrevWorkChange: prevWork => dispatch(actions.onPrevWorkChange(prevWork)),
  onSuccessFileGuidsChange: successFileGuids => dispatch(actions.onSuccessFileGuidsChange(successFileGuids)),
  onPurchaseModalObjChange: purchaseModalObj => dispatch(actions.onPurchaseModalObjChange(purchaseModalObj)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(workplaceHOC(MergeWork));
