import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "antd";
import "@/assets/convertWork.scss";

export const ConvertWorkRender = ({ convert, currFile, onConvertChange, preSubmit }) => {
  const { t } = useTranslation(["convertWork", "common"]);
  return (
    <div className={"ConvertWork" + (!currFile ? " disabled" : "")}>
      <div>
        <span className="title">{t("Please select an output format")}</span>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Row gutter={[0, 16]}>
              <Col span={12}>
                <div
                  className={`cenvertBtn ${convert === "mp4" ? "active" : ""}`}
                  onClick={() => {
                    onConvertChange("mp4");
                  }}
                >
                  MP4
                </div>
              </Col>
              <Col span={12}>
                <div
                  className={`cenvertBtn ${convert === "vob" ? "active" : ""}`}
                  onClick={() => {
                    onConvertChange("vob");
                  }}
                >
                  VOB
                </div>
              </Col>
            </Row>
            <Row gutter={[0, 16]}>
              <Col span={12}>
                <div
                  className={`cenvertBtn ${convert === "mov" ? "active" : ""}`}
                  onClick={() => {
                    onConvertChange("mov");
                  }}
                >
                  MOV
                </div>
              </Col>
              <Col span={12}>
                <div
                  className={`cenvertBtn ${convert === "webm" ? "active" : ""}`}
                  onClick={() => {
                    onConvertChange("webm");
                  }}
                >
                  WEBM
                </div>
              </Col>
            </Row>
            <Row gutter={[0, 16]}>
              <Col span={12}>
                <div
                  className={`cenvertBtn ${convert === "mkv" ? "active" : ""}`}
                  onClick={() => {
                    onConvertChange("mkv");
                  }}
                >
                  MKV
                </div>
              </Col>
              <Col span={12}>
                <div
                  className={`cenvertBtn ${convert === "ogv" ? "active" : ""}`}
                  onClick={() => {
                    onConvertChange("ogv");
                  }}
                >
                  OGV
                </div>
              </Col>
            </Row>
            <Row gutter={[0, 16]}>
              <Col span={12}>
                <div
                  className={`cenvertBtn ${convert === "avi" ? "active" : ""}`}
                  onClick={() => {
                    onConvertChange("avi");
                  }}
                >
                  AVI
                </div>
              </Col>
              <Col span={12}>
                <div
                  className={`cenvertBtn ${convert === "mxf" ? "active" : ""}`}
                  onClick={() => {
                    onConvertChange("mxf");
                  }}
                >
                  MXF
                </div>
              </Col>
            </Row>
            <Row gutter={[0, 16]}>
              <Col span={12}>
                <div
                  className={`cenvertBtn ${convert === "wmv" ? "active" : ""}`}
                  onClick={() => {
                    onConvertChange("wmv");
                  }}
                >
                  WMV
                </div>
              </Col>
              <Col span={12}>
                <div
                  className={`cenvertBtn ${convert === "ts" ? "active" : ""}`}
                  onClick={() => {
                    onConvertChange("ts");
                  }}
                >
                  TS
                </div>
              </Col>
            </Row>
            <Row gutter={[0, 16]}>
              <Col span={12}>
                <div
                  className={`cenvertBtn ${convert === "asf" ? "active" : ""}`}
                  onClick={() => {
                    onConvertChange("asf");
                  }}
                >
                  ASF
                </div>
              </Col>
              <Col span={12}>
                <div
                  className={`cenvertBtn ${convert === "mpg" ? "active" : ""}`}
                  onClick={() => {
                    onConvertChange("mpg");
                  }}
                >
                  MPG
                </div>
              </Col>
            </Row>
            <Row gutter={[0, 16]}>
              <Col span={12}>
                <div
                  className={`cenvertBtn ${convert === "mpeg" ? "active" : ""}`}
                  onClick={() => {
                    onConvertChange("mpeg");
                  }}
                >
                  MPEG
                </div>
              </Col>
              <Col span={12}>
                <div
                  className={`cenvertBtn ${convert === "flv" ? "active" : ""}`}
                  onClick={() => {
                    onConvertChange("flv");
                  }}
                >
                  FLV
                </div>
              </Col>
            </Row>
            <Row gutter={[0, 16]}>
              <Col span={12}>
                <div
                  className={`cenvertBtn ${convert === "f4v" ? "active" : ""}`}
                  onClick={() => {
                    onConvertChange("f4v");
                  }}
                >
                  F4V
                </div>
              </Col>
              <Col span={12}>
                <div
                  className={`cenvertBtn ${convert === "swf" ? "active" : ""}`}
                  onClick={() => {
                    onConvertChange("swf");
                  }}
                >
                  SWF
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="save">
        <Button type="primary" block={true} onClick={preSubmit} size="middle" disabled={currFile ? false : true}>
          {t("common:Save")}
        </Button>
      </div>
    </div>
  );
};
