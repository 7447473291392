import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import Utils from "@/utils/utils";
import actions from "@/action";
import TipsModal from "@/components/common/TipsModal";
import ProcessModal from "@/components/common/processModal/ProcessModal";
import ReactGA from "react-ga";
import Cookies from "js-cookie";
import api_domain_config from "@/constants/common";

export const workplaceHOC = WorkplaceComponent => {
  class Workplace extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        waitingUpload: false,
        processStep: 1,
        submitFail: false,
        submitFailMsg: "Processing failed.",
        audioUploading: false,
      };
      this.handleSubmit = () => {
        return null;
      };
    }

    componentDidUpdate(prevProps, prevStates) {
      if (this.props.work === "merge") {
        if (
          this.state.waitingUpload &&
          this.props.submitting &&
          !this.props.files.filter(item => !item.uploadStatus).length &&
          this.props.files !== prevProps.files
        ) {
          this.preSubmit(this.state.processStep, this.handleSubmit);
        }
      } else if (this.props.work === "addMusic") {
        if (prevProps.uploading && !this.props.uploading && this.state.waitingUpload && this.props.submitting && !this.state.audioUploading) {
          this.preSubmit(this.state.processStep, this.handleSubmit);
        }
        if (prevStates.audioUploading && !this.state.audioUploading && this.props.submitting && !this.props.uploading) {
          this.preSubmit(this.state.processStep, this.handleSubmit);
        }
      } else {
        if (prevProps.uploading && !this.props.uploading && this.state.waitingUpload && this.props.submitting) {
          this.preSubmit(this.state.processStep, this.handleSubmit);
        }
      }
    }

    preSubmit = (processStep, handleSubmit, isUnkownTaskRequest) => {
      this.setState({ processStep });
      this.handleSubmit = handleSubmit;
      if (this.props.uploading) {
        console.log("提交前文件正在上传");
        if (!isUnkownTaskRequest) {
          this.props.onProcessStepChange(this.props.processStep + processStep);
        }
        setTimeout(() => {
          this.props.onSubmittingChange(true);
        }, 500);
        // 提交前文件正在上传
        this.setState({ waitingUpload: true });
        return false;
      } else if (!this.props.uploading && this.props.currFile && !this.props.currFile.uploadStatus) {
        // 提交前文件上传失败
        console.log("提交前文件上传失败");
        this.props.onSubmittingChange(true);
        this.setState({ waitingUpload: true });
        this.props.onUploadAgainFileChange(this.props.currFile);
        this.props.onCurrFileChange(null);
        this.props.onPreUploadFileChange(this.props.currFile.fileObj);
      } else if (!this.props.uploading && this.props.currFile && this.props.currFile.uploadStatus) {
        if (this.state.audioUploading) {
          //音频正在上传
          console.log("提交前文件上传成功，音频正在上传");
          if (!isUnkownTaskRequest) {
            this.props.onProcessStepChange(this.props.processStep + 3);
          }
          this.props.onProcessPercentChange(this.props.processPercent + 1);
          this.setState({ waitingUpload: true });
          this.props.onSubmittingChange(true);
        } else {
          console.log("提交前文件上传成功");
          //提交前文件上传成功
          this.setState({ waitingUpload: false });
          this.props.onSubmittingChange(true);
          handleSubmit();
        }
      }
    };

    getNewVideoByProgress = async (status, response, options) => {
      let guids = [];
      let progressResponse;
      const number = response.data.result.number;
      console.log("response", response);
      return new Promise(async (resolve, reject) => {
        try {
          progressResponse = await Utils.getProgress(number, false, true);
        } catch (error) {
          if (error !== "cancel") {
            this.setState({ submitFail: true });
          }
          this.props.onProcessPercentChange(0);
          this.props.onProcessStepChange(0);
          this.props.onSubmittingChange(false);
          reject(error);
          return false;
        }
        const files = [...this.props.files];
        if (this.props.work === "mute") {
          if ((options.muteType === "video" || options.muteType === "video_audio") && response.data.response_file.mute_video) {
            const guid = new Date().getTime();
            guids.push(guid);
            const response_file = response.data.response_file.mute_video;
            files.unshift(this.getNewVideo(progressResponse, guid, status, response_file, options));
          }
          if ((options.muteType === "audio" || options.muteType === "video_audio") && response.data.response_file.audio) {
            const guid = new Date().getTime() + 1;
            guids.push(guid);
            let info;
            if (response.data.response_file.mute_video) {
              info = progressResponse.info[1];
            } else {
              info = progressResponse.info[0];
            }
            const response_file = response.data.response_file.audio;
            files.unshift(this.getNewAudio(info, guid, status, response_file));
          }
        } else {
          const guid = new Date().getTime();
          guids.push(guid);
          const response_file = response.data.response_file[0];
          files.unshift(this.getNewVideo(progressResponse, guid, status, response_file, options, number));
        }
        this.props.onFilesChange(files);
        localStorage.setItem("files", JSON.stringify(files));
        resolve(guids);
      });
    };

    getNewVideo = (progressResponse, guid, status, videofileurl, options, jobId) => {
      let videoObj = {};
      if (this.props.work === "convert") {
        videoObj = {
          name: moment(new Date()).format("YYYYMMDDHHmmss") + "." + options.convertType,
          guid: guid,
          duration: this.props.currFile.duration,
          size: this.props.currFile.size,
          thumbnail: this.props.currFile.thumbnail,
          status,
          videofileurl: this.props.currFile.videofileurl,
          downloadurl: videofileurl,
          screenshots: this.props.currFile.screenshots,
          mark: false,
          wh: this.props.currFile.wh,
          fps: this.props.currFile.fps,
          uploadStatus: true,
          jobId,
        };
        return videoObj;
      }
      const duration = moment("00:00:00", "mm:ss:SS")
        .add(parseFloat(progressResponse.info[0].duration), "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1");
      const size = parseInt(progressResponse.info[0].size);
      const wh = progressResponse.info[0].wh.match(/^\d+x\d+/)[0];
      const fps = progressResponse.info[0].fps;
      const screenshots = progressResponse.thumbnail[0].img;
      const thumbnail = screenshots[0];
      videoObj = {
        name: moment(new Date()).format("YYYYMMDDHHmmss") + ".mp4",
        guid: guid,
        duration: duration,
        size: size,
        thumbnail: thumbnail,
        status,
        videofileurl,
        screenshots: screenshots,
        mark: false,
        wh: wh,
        fps: fps,
        uploadStatus: true,
        createTime: guid,
        jobId,
      };
      //当使用视频循环并且生成gif时
      if (this.props.work === "loop" && this.props.loopTimes === "gif") {
        videoObj.name = moment(new Date()).format("YYYYMMDDHHmmss") + ".gif";
        videoObj.fileType = "gif"; //生成时文件为gif，用于区分视频文件
        videoObj.originalFileObj = this.props.currFile; //保存原视频guid,用于转换成视频进行编辑
      }
      if (this.props.work === "reverse") {
        ReactGA.event(
          {
            category: "Video_Reverse",
            action: "Reverse_output_size",
            label: (this.props.currFile.size / 1024).toFixed(2) + "kb_" + (size / 1024).toFixed(2) + "kb",
          },
          ["OnlineTracker", "Tracker"]
        );
        ReactGA.event(
          {
            category: "Video_Reverse",
            action: "Reverse_add_duration",
            label: (new Date().getTime() - this.props.startTime.reverse) / 1000,
          },
          ["OnlineTracker", "Tracker"]
        );
      }
      return videoObj;
    };

    getNewAudio = (info, guid, status, audiofileurl) => {
      let audioObj = {};
      const duration = moment("00:00:00", "mm:ss:SS")
        .add(parseFloat(info.duration), "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1");
      const size = info.size;
      audioObj = {
        name: "Extract audio.mp3",
        guid: guid,
        duration: duration,
        size: size,
        thumbnail: "https://api.hitpaw.com/static/1610361530lrp/download/1610361556_1.jpg",
        status,
        audiofileurl: audiofileurl,
        mark: false,
        uploadStatus: true,
      };
      return audioObj;
    };

    submitAgain = () => {
      this.setState({ submitFail: false });
      if (this.props.work === "cut" || this.props.work === "gif") {
        if (!this.props.cutWorkList.length) {
          return false;
        }
      }
      if (this.props.work === "merge") {
        if (!this.props.mergeWorkList.length) {
          return false;
        }
      } else {
        if (!this.props.currFile) {
          return false;
        }
      }
      if (!this.props.uploading && this.props.uploadPercent < 100) {
        this.props.onProcessPercentChange(0);
      }
      this.preSubmit(this.state.processStep, this.handleSubmit);
    };

    cancelAgain = () => {
      this.setState({ submitFail: false });
      this.props.onProcessPercentChange(0);
    };

    /**
     *取消进程成功的回调方法
     */
    handleCancleProcessSuccess = () => {
      if (this.props.work === "reverse" && this.props.currFile && !this.props.currFile.reverseVideourl) {
        if (!this.props.processModalVisible) {
          this.props.onCurrFileChange(null);
        } else {
          this.props.onProcessModalVisibleChange(false);
        }
      }
    };

    /**
     * 判断游客用户是否超过了6次的操作记录，超出则直接弹出购买窗口
     * @returns true 直接跳出提交 | false 继续执行
     */
    handleVideoEditorTimes = () => {
      console.log(this.props.videoEditorTimes);
      if (this.props.videoEditorTimes >= 6) {
        console.log("游客超过了限制");
        if (Utils.isNeedToShowPurchaseModal()) {
          this.props.onPurchaseModalObjChange({
            visible: true,
            type: "videoEditorTimesMoreThanLimit",
          });
          return true;
        }
      } else {
        Cookies.set("videoEditorTimes", this.props.videoEditorTimes + 1, { expires: 1, domain: api_domain_config.DOMAIN });
        this.props.onVideoEditorTimesChange(this.props.videoEditorTimes + 1);
      }
      return false;
    };

    render() {
      return (
        <div style={{ height: "100%" }}>
          <WorkplaceComponent
            {...this.props}
            preSubmit={this.preSubmit}
            submitAgain={this.submitAgain}
            cancelAgain={this.cancelAgain}
            getVideo={this.getVideo}
            audioUploading={this.state.audioUploading}
            handleAudioUploading={audioUploading => this.setState({ audioUploading })}
            getNewVideoByProgress={this.getNewVideoByProgress}
            handleSubmitFailModal={submitFail => this.setState({ submitFail })}
            handleSubmitFailModalMsg={submitFailMsg => this.setState({ submitFailMsg })}
            handleVideoEditorTimes={this.handleVideoEditorTimes}
          />
          <TipsModal
            showGuidText
            hasDownload
            visible={this.state.submitFail}
            content={this.state.submitFailMsg}
            onCancel={() => {
              this.setState({ submitFail: false });
            }}
            tipsObj={{ type: "fail" }}
            cancelBtnHandle={this.cancelAgain}
            confirmBtnHandle={this.submitAgain}
            confirmBtnText="Try Again"
            productKey={
              this.props.work === "removeWatermark"
                ? "hitpaw-watermark-remover"
                : window.userClient.platform === "win"
                ? "hitpaw-video-editor"
                : undefined
            }
            // hasDownload
            // hasTryAgain
          />
          <ProcessModal
            isGuideToDownload={
              (this.props.work === "subtitle" || this.props.work === "addWatermark") && window.userClient.platform === "mac" ? false : true
            }
            productKey={
              this.props.work === "removeWatermark"
                ? "hitpaw-watermark-remover"
                : window.userClient.platform === "win"
                ? "hitpaw-video-editor"
                : undefined
            }
            cancleCallbackFunc={this.handleCancleProcessSuccess}
          />
        </div>
      );
    }
  }
  const mapStateToProps = state => ({
    uploading: state.work.uploading,
    currFile: state.files.currFile,
    processStep: state.work.processStep,
    work: state.work.work,
    startTime: state.work.startTime,
    mergeWorkList: state.work.mergeWorkList,
    cutWorkList: state.work.cutWorkList,
    loopTimes: state.work.loopTimes,
    processModalVisible: state.work.processModalVisible,
    videoEditorTimes: state.common.videoEditorTimes,
  });
  const mapDispatchToProps = dispatch => ({
    onProcessPercentChange: processPercent => dispatch(actions.onProcessPercentChange(processPercent)),
    onProcessStepChange: processStep => dispatch(actions.onProcessStepChange(processStep)),
    onPreUploadFileChange: preUploadFile => dispatch(actions.onPreUploadFileChange(preUploadFile)),
    onUploadAgainFileChange: uploadAgainFile => dispatch(actions.onUploadAgainFileChange(uploadAgainFile)),
    onSubmittingChange: submitting => dispatch(actions.onSubmittingChange(submitting)),
    onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
    onProcessModalVisibleChange: processModalVisible => dispatch(actions.onProcessModalVisibleChange(processModalVisible)),
    onVideoEditorTimesChange: videoEditorTimes => dispatch(actions.onVideoEditorTimesChange(videoEditorTimes)),
    onPurchaseModalObjChange: purchaseModalObj => dispatch(actions.onPurchaseModalObjChange(purchaseModalObj)),
  });
  return connect(mapStateToProps, mapDispatchToProps)(Workplace);
};
