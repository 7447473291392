export default {
  Start: "开始",
  End: "结束",
  Save: "保存",
  Cancel: "取消",
  Download: "下载",
  downloading: "下载中",
  "Drag and drop from Files or local disk": "从文件库或本地拖拽文件",
  "Click to Add File": "点击添加文件",
  "The minimum time length is 1s!": "最小时长为1s!",
  "The maximum time length is 50s!": "最大时长为50s！",
  "Try Again": "再试一次",
  "Coming Soon": "即将到来",
  "Learn more": "了解更多",
  Yes: "是的",
  "Please click the Save button to ensure the previous editing to take effect.": "如果没有点击保存按钮，设置项都不会生效。",
  // **************************TipsModal Message Title*****************************
  "Cut 10 video fragments at most. Get HitPaw Toolkit for more features.": "最多截取10个片段。下载 HitPaw Toolkit 以获取更多功能。",
  "Max file size for videos is 20 MB. Get HitPaw Toolkit for big file.": "最大支持20MB的文件。下载 HitPaw Toolkit 以获取更多功能。",
  "Max file size for videos is 20 MB. Get HitPaw Watermark Remover for big file.":
    "最大支持20MB的文件。下载 HitPaw Watermark Remover 以获取更多功能。",
  "The minimum time length is 1s! Get HitPaw Toolkit for more features.": "最小时长为1s！下载 HitPaw Toolkit 以获取更多功能。",
  "Add 3 videos at most. Get HitPaw Toolkit for more features.": "最多添加3个视频。下载 HitPaw Toolkit 以获取更多功能。",
  "This format is not supported currently.": "暂不支持该格式。",
  "This format is not supported currently. Get HitPaw Toolkit for more formats": "暂不支持该格式。下载 HitPaw Toolkit 以获取更多功能。",
  "This format is not supported currently. Get HitPaw Watermark Remover for more formats":
    "暂不支持该格式。下载 HitPaw Watermark Remover 以获取更多功能。",
  "Processing failed. Get HitPaw Toolkit for stable processing.": "生成视频失败！",
  "Failed to load!  Get HitPaw Watermark Remover for stable processing.": "加载失败！",
  "Are you sure you want to delete it?": "您确定要删除吗？",
  "Failed to extract the audio!": "提取音频失败！",
  "Video link copied": "视频链接已复制",
  "Cut 10 video fragments at most.": "最多截取10个片段。",
  "Max file size for videos is 20 MB.": "最大支持20MB的文件。",
  "Max file size for videos is 100 MB.": "最大支持100MB的文件。",
  "Failed to load!": "加载失败！",

  //******************************2021.05.17 v1.3.0 *******************************/
  "Screenshots downloaded.": "已截图并下载。",
  "Online service, no download required": "在线编辑，无需下载",
  "Add up to 20MB file": "文件大小上限为20MB",
  "Support common formats": "支持流行格式",
  "Best for general editing requirements": "解决你的日常编辑需求",
  "0 second wait": "0秒等待",
  "No size limits": "没有文件大小限制",
  "Support 1000+ formats": "支持1000多种格式",
  "Stable processing": "处理稳定",
  "Get access to HitPaw Toolkit!": "查看HitPaw Toolkit！",
  "Support cut, crop & rotate, adjust, speed, add music, stop motion, etc.": "提供截取、旋转&翻转、调色、变速、加音乐等功能",
  "The easiest video editing software for beginners": "最适合初学者的编辑软件",
  "Remove watermark from videos and images in simple clicks": "只需简单点击几下，即可从视频和图像中删除水印",
  "TRY IT FREE": "免费尝试",
  "BUY NOW": "立即购买",

  //******************************2021.09.06 v1.5.0********************************/
  "Add Video": "添加视频",
  More: "更多",
  "History files": "历史文件",
  Share: "分享",
  Duration: "时长",
  "Download all": "下载全部",
  "Download selected": "下载选中部分",
  "Add Audio": "加音频",

  //********************************2021.10.19 v1.6.0*********************************/
  "GIF does not support editing. Do you want to convert the MP4 for editing?": "GIF文件不支持编辑，是否要转成MP4文件继续编辑？",
  "Go to Edit": "去编辑",
  "Download GIF": "下载GIF",

  //********************************2021.11.02 v1.6.1*********************************/
  "Download HitPaw Video Editor for more features.": "下载 HitPaw Video Editor 以获取更多功能。",
  "Get HitPaw Watermark Remover for more features.": "下载 HitPaw Watermark Remover 以获取更多功能。",
  "Get HitPaw Toolkit for more features.": "下载 HitPaw Toolkit 以获取更多功能。",
  "Add 3 videos at most.": "最多添加3个视频。",
  "Processing failed.": "生成视频失败！",
  "The uploaded video is less than 1 second.": "上传视频时长不能少于1s",
  "The video format is not supported.": "该视频格式不支持。",
  "The audio format is not supported.": "该音频格式不支持。",
  "AddFileByURL.largerSize.HitPawToolkit": "视频解析成功！但是目前暂不支持处理大小超过100MB的文件，请<1><0>下载视频</0></1>后用Toolkit进行处理。",
  "AddFileByURL.largerSize.HitPawWatermarkRemover":
    "视频解析成功！但是目前暂不支持处理大小超过100MB的文件，请<1><0>下载视频</0></1>后用HitPaw Watermark Remover 进行处理。",
  "AddFileByURL.largerSize.HitPawVideoEditor":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Video Editor to proceed.",
};
