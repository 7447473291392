export default {
  'Image': '이미지',
  'Text': '문자',
  'Add image': '이미지 추가',
  'Opacity': '불투명도',
  "Add text": '텍스트 추가',
  'Text Color': '텍스트 색상',
  'Outline': '개요',
  'Background': '배경',
  'Text here': '여기에 문자 입력해 주세요.',
}