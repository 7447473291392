import store from "../store";
import moment from "moment";
import { message } from "antd";
import ReactGA from "react-ga";
import {
  HitPaw_Home_LinkData,
  HitPaw_OnlineTools_LinkData,
  HitPawToolkit_Buy_LinkData,
  HitPawToolkit_EXE_LinkData,
  HitPawWatermarkRemover_EXE_LinkData,
  HitPawWatermarkRemover_Buy_LinkData,
  HitPaw_WatermarkRemover_Product_LinkData,
  HitPawVideoEditor_EXE_LinkData,
  HitPawVideoEditor_Buy_LinkData,
  HitPawVideoEdito_Product_LinkData,
} from "./linkData";
import { api_config } from "@/constants/api";
import videoWorkType from "@/constants/videoWorkType";

console.log(window.i18n);
const Utils = {
  cancelProgress: () => {
    store.dispatch({
      type: "CANCELPROGRESS_CHANGE",
      cancelProgress: true,
    });
    store.dispatch({
      type: "PROCESSPERCENT_CHANGE",
      processPercent: 0,
    });
    if (store.getState().work.currProgress) {
      const formData = new FormData();
      formData.append("number", store.getState().work.currProgress.number);
      // formData.append("pid", store.getState().work.currProgress.pid);
      formData.append("token", store.getState().common.tokenId);
      fetch(`${api_config.WS_API_URL}/stopTask`, {
        method: "post",
        body: formData,
      })
        .then(response => response.json())
        .then(responseJSON => {
          if (responseJSON.code === 200) {
            let startTime, category, action;
            if (store.getState().files.currFile) {
              switch (store.getState().work.work) {
                case "merge":
                  startTime = store.getState().work.startTime.merge;
                  category = "Merge";
                  action = "MergeCancel";
                  break;
                case "cut":
                  startTime = store.getState().work.startTime.cut;
                  category = "Cut";
                  action = "CutCancel";
                  break;
                case "gif":
                  startTime = store.getState().work.startTime.gif;
                  category = "ToGif";
                  action = "ToGifCancel";
                  break;
                case "speed":
                  startTime = store.getState().work.startTime.speed;
                  category = "Speed";
                  action = "SpeedCancel";
                  break;
                case "crop_rotate":
                  startTime = store.getState().work.startTime.crop_rotate;
                  category = "Crop&Rotate";
                  action = "Crop&RotateCancel";
                  break;
                case "resize":
                  startTime = store.getState().work.startTime.resize;
                  category = "Resize";
                  action = "ResizeCancel";
                  break;
                case "removeWatermark":
                  startTime = store.getState().work.startTime.removeWatermark;
                  category = "RemoveMark";
                  action = "RemoveMarkCancel";
                  break;
                case "convert":
                  startTime = store.getState().work.startTime.convert;
                  category = "Convert";
                  action = "ConvertCancel";
                  break;
                case "mute":
                  startTime = store.getState().work.startTime.convert;
                  category = "SplitAudio";
                  action = "SplitAudioCancel";
                  break;
                case "addWatermark":
                  startTime = store.getState().work.startTime.addWatermark;
                  category = "AddMark";
                  action = "AddMarkCancel";
                  break;
                case "addMusic":
                  startTime = store.getState().work.startTime.addMusic;
                  category = "AddMusic";
                  action = "MusicCancel";
                  break;
                case "subtitle":
                  startTime = store.getState().work.startTime.subtitle;
                  category = "AddText";
                  action = "TextCancel";
                  break;
                case "loop":
                  startTime = store.getState().work.startTime.loop;
                  category = "Video_Loop";
                  action = "Loop_cancel";
                  break;
                case "reverse":
                  startTime = store.getState().work.startTime.reverse;
                  category = "Video_Reverse";
                  action = "Reverse_cancel";
                  break;
                default:
                  startTime = 0;
              }
              ReactGA.event(
                {
                  category: category,
                  action: action,
                  label: (new Date().getTime() - startTime) / 1000,
                },
                ["OnlineTracker", "Tracker"]
              );
            } else {
              ReactGA.event(
                {
                  category: "Upload",
                  action: "LoadCancel",
                  label: (new Date().getTime() - store.getState().work.startTime.upload) / 1000,
                },
                ["OnlineTracker", "Tracker"]
              );
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  },

  getProgress: (number, unChangeProcess, isFirstTime) => {
    store.dispatch({
      type: "CURRPROGRESS_CHANGE",
      currProgress: {
        number: number,
      },
    });
    let timeOut;
    let percent;
    return new Promise((resolve, reject) => {
      const _getProgress = () => {
        console.log("websocket：进入请求");
        const ws = new WebSocket(`${api_config.WS_API_URL}/progressbar?authorization=${store.getState().common.authorization}`);
        console.log(ws);
        ws.onopen = event => {
          console.log("websocket：请求websocket");
          ws.send(
            JSON.stringify({
              number,
              is_info: 1,
              is_thumbnail: 1,
              thumbnail_ext: "jpg",
              thumbnail_size: "70x40",
              thumbnail_number: 12,
            })
          );
        };
        ws.onmessage = event => {
          console.log("websocket：返回信息");
          if (timeOut) {
            clearTimeout(timeOut);
          }
          // timeOut = setTimeout(() => {
          //   console.log("websocket: 进来判断超时了没", percent);
          //   if (percent === 100) {
          //     return false;
          //   }
          //   console.log("websocket: 接收信息超时,重新请求");
          //   resolve(Utils.getProgress(number, unChangeProcess));
          //   ws.close();
          // }, 30000);
          const data = JSON.parse(event.data);
          console.log("websocket：收到websocket的message", data);
          if (data.code !== 200) {
            reject(new Error(data.msg));
            ws.close();
            return false;
          }
          if (store.getState().work.cancelProgress) {
            reject("cancel");
            ws.close();
            store.dispatch({
              type: "CANCELPROGRESS_CHANGE",
              cancelProgress: false,
            });
            return false;
          }
          percent = data.data.progress * 1;
          if (data.data.status !== 1 && data.data.status !== 2) {
            console.log("websocket: 进度条不对劲");
            // if (data.data.status === 0) {
            //   console.log("websocket: 未知错误,重新请求");
            //   let timer = setTimeout(() => {
            //     resolve(Utils.getProgress(number, pid, unChangeProcess));
            //     clearTimeout(timer);
            //   }, 2000);
            // } else {
            reject(percent + "_" + store.getState().common.tokenId + "_" + number + "_none");
            // }
            ws.close();
            return false;
          }
          if (store.getState().work.processPercent < 100 && !unChangeProcess) {
            store.dispatch({
              type: "PROCESSPERCENT_CHANGE",
              processPercent: percent === 0 ? 1 : percent,
            });
          } else if (!unChangeProcess) {
            store.dispatch({
              type: "PROCESSPERCENT_CHANGE",
              processPercent: percent + (store.getState().work.processPercent - (store.getState().work.processPercent % 100)),
            });
          }
          console.log("websocket: 进度", percent);
          if (percent === 100) {
            console.log("websocket: ====返回信息");
            resolve(data.data);
            ws.close();
          }
        };
        ws.onerror = event => {
          console.log("error", event);
          reject("-1_" + store.getState().common.tokenId + "_" + number + "_none");
          ws.close();
          // _getProgress();
        };
        ws.onclose = event => {
          if (timeOut) {
            clearTimeout(timeOut);
            timeOut = null;
          }
          window.addEventListener("offline", function () {
            console.log("网络断开");
            reject("offLine");
          });
          console.log("websocket: 关闭");
        };
      };
      // if (isFirstTime) {
      //   console.log("websocket：websocket之前的接口请求完成");
      //   const timer = setTimeout(() => {
      //     console.log("websocket：时隔3s准备请求websocket");
      //     _getProgress();
      //     clearTimeout(timer);
      //   }, 3000);
      // } else {
      _getProgress();
      // }
    });
  },

  getDuration: videofileurl => {
    console.log("getDuration");
    const formData = new FormData();
    formData.append("url", videofileurl);
    formData.append("token", store.getState().common.tokenId);
    return new Promise((resolve, reject) => {
      fetch(`${api_config.API_URL}/getvideosize`, {
        method: "post",
        body: formData,
      })
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            reject("getvideosize_" + response.status + "_none_none_" + store.getState().common.tokenId + "_none_none");
          }
        })
        .then(responseJSON => {
          if (responseJSON.code === 1) {
            resolve(responseJSON);
          } else {
            reject("getvideosize_200_" + responseJSON.code + "_none_" + store.getState().common.tokenId + "_none_none");
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getScreenshots: (videofileurl, num) => {
    const formData = new FormData();
    formData.append("number", num);
    formData.append("size", "100x40");
    formData.append("ext", "jpg");
    formData.append("videofileurl", videofileurl);
    formData.append("token", store.getState().common.tokenId);
    return new Promise((resolve, reject) => {
      fetch(`${api_config.API_URL}/videoscreenshot`, {
        method: "post",
        body: formData,
      })
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            reject("videoscreenshot_" + response.status + "_none_none_" + store.getState().common.tokenId + "_none_none");
          }
        })
        .then(responseJSON => {
          if (responseJSON.code === 1) {
            resolve(responseJSON);
          } else {
            reject("videoscreenshot_200_" + responseJSON.code + "_" + store.getState().common.tokenId + "_none_none");
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getAudioDuration: audiofileurl => {
    const formData = new FormData();
    formData.append("url", audiofileurl);
    formData.append("token", store.getState().common.tokenId);
    return new Promise((resolve, reject) => {
      fetch(`${api_config.API_URL}/getaudioinfo`, {
        method: "post",
        body: formData,
      })
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            reject("getaudioinfo_" + response.status + "_none_none_" + store.getState().common.tokenId + "_none_none");
          }
        })
        .then(responseJSON => {
          if (responseJSON.code === 1) {
            resolve(responseJSON);
          } else {
            reject("getaudioinfo_200_" + responseJSON.code + "_" + store.getState().common.tokenId + "_none_none");
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  downloadFile: (obj, format) => {
    message.loading(window.i18n.t("common:downloading"), 0);
    let url = obj.downloadurl || obj.videofileurl || obj.audiofileurl;
    let name = obj.name ? obj.name.substring(0, obj.name.lastIndexOf(".") - 1) : moment(new Date()).format("YYYYMMDDHHmmss") + ".mp4";
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        message.destroy();
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `${name}.${format}`;
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        message.destroy();
        message.error("Download failed.");
      });
  },

  urlToBlob: url => {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          let blobUrl = window.URL.createObjectURL(blob);
          resolve(blobUrl);
        })
        .catch(error => {
          reject(error);
          message.error("URL to Blob failed.");
        });
    });
  },

  urlToArrayBuffer: url => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        responseType: "arraybuffer",
      })
        .then(res => res.arrayBuffer())
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
          message.error("URL To ArrayBuffer failed.");
        });
    });
  },

  downloadBase64: url => {
    // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
    if (window.navigator.msSaveOrOpenBlob) {
      var bstr = atob(url.split(",")[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      var blob = new Blob([u8arr]);
      window.navigator.msSaveOrOpenBlob(blob, "HitPaw-screenshot.jpg");
    } else {
      // 这里就按照chrome等新版浏览器来处理
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute("download", "HitPaw-screenshot");
      a.click();
    }
  },

  handleBlurFormat: (type, list, index) => {
    let obj;
    if (index) {
      obj = list[index];
    } else {
      obj = list.filter(item => item.selected)[0];
    }
    if (
      !/^\d+:\d+\.\d+$/.test(obj.start) ||
      Utils.getSeconds(obj.start) >= Utils.getSeconds(store.getState().files.currFile.duration) ||
      !moment(obj.start.replace(".", ":"), "mm:ss:SS").isValid()
    ) {
      obj.start = "00:00.00";
    }
    if (
      !/^\d+:\d+\.\d+$/.test(obj.end) ||
      Utils.getSeconds(obj.end) <= Utils.getSeconds(obj.start) ||
      !moment(obj.end.replace(".", ":"), "mm:ss:SS").isValid()
    ) {
      obj.end = store.getState().files.currFile.duration;
    }
    if (store.getState().work.work === "gif" && Utils.getSeconds(obj.end) - Utils.getSeconds(obj.start) > 50) {
      obj.end = moment(obj.start.replace(".", ":"), "mm:ss:SS")
        .add(50, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1");
    }
    if (Utils.getSeconds(obj.end) - Utils.getSeconds(obj.start) <= 1) {
      obj.end = moment(obj.start.replace(".", ":"), "mm:ss:SS")
        .add(1, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1");
      if (Utils.getSeconds(obj.end) >= Utils.getSeconds(store.getState().files.currFile.duration)) {
        obj.end = store.getState().files.currFile.duration;
        obj.start = moment(obj.end.replace(".", ":"), "mm:ss:SS")
          .subtract(1, "seconds")
          .format("mm:ss:SS")
          .replace(/:([^:]*)$/, ".$1");
      }
    }
    if (type === "CUTWORKLIST_CHANGE") {
      store.dispatch({
        type: type,
        cutWorkList: list,
      });
    }
    if (type === "REMOVEWATERMARKLIST_CHANGE") {
      store.dispatch({
        type: type,
        removeWatermarkList: list,
      });
    }
    if (type === "SUBTITLEWORKLIST_CHANGE") {
      store.dispatch({
        type: type,
        subtitleWorkList: list,
      });
    }
  },

  getSize: param => {
    if (param <= 1024 * 1024) {
      return (param / 1024).toFixed(0) + "KB";
    } else if (param <= 1024 * 1024 * 1024) {
      return (param / 1024 / 1024).toFixed(2) + "MB";
    }
  },

  getUpdateTime: param => {
    const duration = parseInt((new Date().getTime() - param) / 1000);
    if (duration <= 60) {
      return { text: "Just now" };
    } else if (duration < 60 * 2) {
      return { text: "minute ago", num: 1 };
    } else if (duration < 60 * 60) {
      return { text: "minutes ago", num: parseInt(duration / 60) };
    } else if (duration < 60 * 60 * 2) {
      return { text: "hour ago", num: 1 };
    } else if (duration <= 60 * 60 * 24) {
      return { text: "hours ago", num: parseInt(duration / 60 / 60) };
    } else {
      return { text: "day ago", num: 1 };
    }
  },

  resolution: (width, height, scale) => {
    if (scale * height <= width) {
      return {
        width: parseInt(scale * height),
        height: height,
      };
    } else {
      return {
        width: width,
        height: parseInt(width / scale),
      };
    }
  },

  getSeconds: obj => {
    if (moment(obj.replace(".", ":"), "mm:ss:SS").isValid()) {
      return moment.duration(moment(obj.replace(".", ":"), "mm:ss:SS").valueOf() - moment("00:00:00", "mm:ss:SS").valueOf()).as("seconds");
    } else {
      return 0;
    }
  },

  getAllSeconds: arr => {
    let s = 0;
    arr.forEach(item => {
      s = s + Utils.getSeconds(item.duration);
    });
    return s;
  },

  initCutWork: () => {
    const cutWorkList = [];
    const d = Utils.getSeconds(store.getState().files.currFile.duration);
    let end;
    if (store.getState().work.work === "gif") {
      if (d < 30) {
        end = moment("00:00:00", "mm:ss:SS")
          .add(d, "seconds")
          .format("mm:ss:SS")
          .replace(/:([^:]*)$/, ".$1");
      } else {
        end = "00:30.00";
      }
    } else {
      if (d <= 1) {
        end = moment("00:00:00", "mm:ss:SS")
          .add(d, "seconds")
          .format("mm:ss:SS")
          .replace(/:([^:]*)$/, ".$1");
      } else if (d / 2 <= 1) {
        end = moment("00:00:00", "mm:ss:SS")
          .add(1, "seconds")
          .format("mm:ss:SS")
          .replace(/:([^:]*)$/, ".$1");
      } else {
        end = moment("00:00:00", "mm:ss:SS")
          .add(d / 2, "seconds")
          .format("mm:ss:SS")
          .replace(/:([^:]*)$/, ".$1");
      }
    }
    cutWorkList.push({
      start: "00:00.00",
      end: end,
      selected: true,
    });
    store.dispatch({
      type: "CUTWORKLIST_CHANGE",
      cutWorkList: cutWorkList,
    });
  },

  handleImgErr: (event, src) => {
    const handleImgErr = [...store.getState().common.handleImgErr];
    if (handleImgErr.filter(item => item.src === src).length > 0) {
      if (handleImgErr.filter(item => item.src === src)[0].times >= 5) {
        return false;
      } else {
        handleImgErr.filter(item => item.src === src)[0].times += 1;
        event.target.src = src;
        store.dispatch({
          type: "HANDLEIMGERR_CHANGE",
          handleImgErr: handleImgErr,
        });
      }
    } else {
      handleImgErr.push({
        src: src,
        times: 1,
      });
      store.dispatch({
        type: "HANDLEIMGERR_CHANGE",
        handleImgErr: handleImgErr,
      });
      event.target.src = src;
    }
  },

  validate: obj => {
    let result = false;
    const accept = [
      "MKV",
      "MOV",
      "MP4",
      "AVI",
      "WMV",
      "VOB",
      "WEBM",
      "3GP",
      "ASF",
      "F4V",
      "FLV",
      "MPG",
      "MPEG",
      "RM",
      "RMVB",
      "MTS",
      "MXF",
      "OGV",
      "SWF",
      "TS",
    ];
    accept.forEach(item => {
      if (/\.[^.]+$/.exec(obj.name)[0].toLocaleLowerCase().match(item.toLocaleLowerCase()) !== null) {
        result = true;
        return false;
      }
    });
    if (!result) {
      ReactGA.event(
        {
          category: "Upload",
          action: "UnSupportFormat",
          label: /\.[^.]+$/.exec(obj.name)[0].toLocaleLowerCase() + "_" + (obj.size / 1024 / 1024).toFixed(2),
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    return result;
  },

  validateAudio: obj => {
    let result = false;
    const accept = ["WAV", "MP3", "M4A", "AAC", "WEB", "F4A", "WMA", "FLA", "MP4", "MOV"];
    console.log(/\.[^.]+$/.exec(obj.name)[0].toLocaleLowerCase());
    accept.forEach(item => {
      if (/\.[^.]+$/.exec(obj.name)[0].toLocaleLowerCase().match(item.toLocaleLowerCase()) !== null) {
        result = true;
        return false;
      }
    });
    if (!result) {
      ReactGA.event(
        {
          category: "Upload",
          action: "UnSupportFormat",
          label: /\.[^.]+$/.exec(obj.name)[0].toLocaleLowerCase() + "_" + (obj.size / 1024 / 1024).toFixed(2),
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    return result;
  },

  getIndex: (obj, arr) => {
    let index = null;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].guid === obj.guid && arr[i].update === obj.update) {
        index = i;
        break;
      }
    }
    return index;
  },

  delFiles: files => {
    const formData = new FormData();
    files.forEach(item => {
      formData.append("file_url[]", item);
    });
    formData.append("token", store.getState().common.tokenId);
    fetch(`${api_config.API_URL}/delPathFiles`, {
      method: "post",
      body: formData,
    });
  },

  initWork: () => {
    if (store.getState().work.work === "merge") {
      store.dispatch({
        type: "MERGEWORKLIST_CHANGE",
        mergeWorkList: [],
      });
    }
    if (store.getState().work.work === "cut" || store.getState().work.work === "gif") {
      store.dispatch({
        type: "CUTWORKLIST_CHANGE",
        cutWorkList: [],
      });
    }
    store.dispatch({
      type: "CURRFILE_CHANGE",
      currFile: null,
    });
  },

  getBlobDuration: async blob => {
    const tempVideoEl = document.createElement("video");
    const durationP = new Promise((resolve, reject) => {
      tempVideoEl.addEventListener("loadedmetadata", () => {
        if (tempVideoEl.duration === Infinity) {
          tempVideoEl.currentTime = Number.MAX_SAFE_INTEGER;
          tempVideoEl.ontimeupdate = () => {
            tempVideoEl.ontimeupdate = null;
            resolve(tempVideoEl.duration);
            tempVideoEl.currentTime = 0;
          };
        } else {
          resolve(tempVideoEl.duration);
        }
      });
      tempVideoEl.addEventListener("error", () => {
        reject(new Error("can load video"));
      });
    });
    tempVideoEl.src = typeof blob === "string" || blob instanceof String ? blob : window.URL.createObjectURL(blob);
    return durationP;
  },

  blobToImage: async blob => {
    return new Promise((resolve, reject) => {
      const url = URL.createObjectURL(blob);
      let img = new Image();
      img.onload = () => {
        URL.revokeObjectURL(url);
        resolve(img);
      };
      img.onerror = () => {
        reject("can't transform to image");
      };
      img.src = url;
    });
  },

  getLanguage: () => {
    const pathname = window.location.pathname; // path
    const hostname = window.location.hostname; // 域名
    const isDEV_NODE_ENV = process.env.NODE_ENV === "development" || process.env.REACT_APP_BUILD_ENV === "development"; //当前环境是否是 测试环境||本地环境
    let lang = "en";
    // ar.hitpaw.com/... ||  测试地址：hitpaw-test.afirstsoft.cn/ar/...
    if (/ar\./.test(hostname) || (isDEV_NODE_ENV && /(\/ar\/)/.test(pathname))) {
      lang = "ar";
    }
    // tr.hitpaw.com/... ||  测试地址：hitpaw-test.afirstsoft.cn/tr/...
    else if (/tr\./.test(hostname) || (isDEV_NODE_ENV && /(\/tr\/)/.test(pathname))) {
      lang = "tr";
    } // 正式地址：online.hitpaw.fr ||  测试地址：hitpaw-test.afirstsoft.cn/fr/...
    else if (/\.fr/.test(hostname) || (isDEV_NODE_ENV && /(\/fr\/)/.test(pathname))) {
      lang = "fr";
    }
    // 正式地址：online.hitpaw.de ||  测试地址：hitpaw-test.afirstsoft.cn/de/...
    else if (/\.de/.test(hostname) || (isDEV_NODE_ENV && /(\/de\/)/.test(pathname))) {
      lang = "de";
    }
    // 正式地址：online.hitpaw.es ||  测试地址：hitpaw-test.afirstsoft.cn/es/...
    else if (/\.es/.test(hostname) || (isDEV_NODE_ENV && /(\/es\/)/.test(pathname))) {
      lang = "es";
    }
    // 正式地址：online.hitpaw.tr ||  测试地址：hitpaw-test.afirstsoft.cn/tr/...
    else if (/\.jp/.test(hostname) || (isDEV_NODE_ENV && /(\/jp\/)/.test(pathname))) {
      lang = "jp";
    }
    // 正式地址：online.hitpaw.jp ||  测试地址：hitpaw-test.afirstsoft.cn/jp/...
    else if (/\.tw/.test(hostname) || (isDEV_NODE_ENV && /(\/tw\/)/.test(pathname))) {
      lang = "tw";
    }
    // 正式地址：online.hitpaw.it ||  测试地址：hitpaw-test.afirstsoft.cn/it/...
    else if (/\.it/.test(hostname) || (isDEV_NODE_ENV && /(\/it\/)/.test(pathname))) {
      lang = "it";
    }
    // 正式地址：online.hitpaw.com.br ||  测试地址：hitpaw-test.afirstsoft.cn/br/...
    else if (/\.br/.test(hostname) || (isDEV_NODE_ENV && /(\/br\/)/.test(pathname))) {
      lang = "br";
    }
    // 正式地址：online.hitpaw.cn ||  测试地址：hitpaw-test.afirstsoft.cn/cn/...
    else if ((hostname !== "hitpaw-test.afirstsoft.cn" && /\.cn/.test(hostname)) || (isDEV_NODE_ENV && /(\/cn\/)/.test(pathname))) {
      lang = "cn";
    }
    // 正式地址：online.hitpaw.ru ||  测试地址：hitpaw-test.afirstsoft.cn/ru/...
    else if (/\.ru/.test(hostname) || (isDEV_NODE_ENV && /(\/ru\/)/.test(pathname))) {
      lang = "ru";
    }
    // 正式地址：online.hitpaw.kr ||  测试地址：hitpaw-test.afirstsoft.cn/kr/...
    else if (/\.kr/.test(hostname) || (isDEV_NODE_ENV && /(\/kr\/)/.test(pathname))) {
      lang = "kr";
    }
    // 正式地址：online.hitpaw.in ||  测试地址：hitpaw-test.afirstsoft.cn/in/...
    else if (/\.in/.test(hostname) || (isDEV_NODE_ENV && /(\/in\/)/.test(pathname))) {
      lang = "in";
    }
    // 正式地址：online.hitpaw.id ||  测试地址：hitpaw-test.afirstsoft.cn/id/...
    else if (/\.id/.test(hostname) || (isDEV_NODE_ENV && /(\/id\/)/.test(pathname))) {
      lang = "id";
    }
    // 正式地址：online.hitpaw.nl ||  测试地址：hitpaw-test.afirstsoft.cn/tr/...
    else if (/\.nl/.test(hostname) || (isDEV_NODE_ENV && /(\/nl\/)/.test(pathname))) {
      lang = "nl";
    } else {
      lang = "en";
    }
    return lang;
  },

  // HitPaw Toolkit 购买 链接
  getHitPawToolKitBuyLink: () => {
    let lang = Utils.getLanguage();
    lang = lang === "in" ? "inn" : lang;
    const sys = window.userClient.platform === "win" ? "win" : "mac";
    return HitPawToolkit_Buy_LinkData[lang][sys] ? HitPawToolkit_Buy_LinkData[lang][sys] : HitPawToolkit_Buy_LinkData.en[sys];
  },

  // HitPaw Toolkit 安装包 链接
  getHitPawToolKitEXELink: () => {
    let lang = Utils.getLanguage();
    lang = lang === "in" ? "inn" : lang;
    const sys = window.userClient.platform === "win" ? "win" : "mac";
    return HitPawToolkit_EXE_LinkData[lang][sys] ? HitPawToolkit_EXE_LinkData[lang][sys] : HitPawToolkit_EXE_LinkData.en[sys];
  },

  // HitPaw Video Editor 购买 链接
  getHitPawVideoEditorBuyLink: () => {
    let lang = Utils.getLanguage();
    lang = lang === "in" ? "inn" : lang;
    const sys = window.userClient.platform === "win" ? "win" : "mac";
    return HitPawVideoEditor_Buy_LinkData[lang][sys] ? HitPawVideoEditor_Buy_LinkData[lang][sys] : HitPawVideoEditor_Buy_LinkData.en[sys];
  },

  // HitPaw Video Editor 安装包 链接
  getHitPawVideoEditorEXELink: () => {
    let lang = Utils.getLanguage();
    lang = lang === "in" ? "inn" : lang;
    const sys = window.userClient.platform === "win" ? "win" : "mac";
    return HitPawVideoEditor_EXE_LinkData[lang][sys] ? HitPawVideoEditor_EXE_LinkData[lang][sys] : HitPawVideoEditor_EXE_LinkData.en[sys];
  },

  // HitPaw Watermark Remover 购买 链接
  getHitPawWatermarkRemoverBuyLink: () => {
    let lang = Utils.getLanguage();
    lang = lang === "in" ? "inn" : lang;
    const sys = window.userClient.platform === "win" ? "win" : "mac";
    return HitPawWatermarkRemover_Buy_LinkData[lang][sys]
      ? HitPawWatermarkRemover_Buy_LinkData[lang][sys]
      : HitPawWatermarkRemover_Buy_LinkData.en[sys];
  },

  // HitPaw Watermark Remover 安装包 链接
  getHitPawWatermarkRemoverEXELink: () => {
    let lang = Utils.getLanguage();
    lang = lang === "in" ? "inn" : lang;
    const sys = window.userClient.platform === "win" ? "win" : "mac";
    return HitPawWatermarkRemover_EXE_LinkData[lang][sys]
      ? HitPawWatermarkRemover_EXE_LinkData[lang][sys]
      : HitPawWatermarkRemover_EXE_LinkData.en[sys];
  },

  // HitPaw 落地页 链接
  getHitPawLink: () => {
    let lang = Utils.getLanguage();
    lang = lang === "in" ? "inn" : lang;
    return HitPaw_OnlineTools_LinkData[lang] ? HitPaw_OnlineTools_LinkData[lang] : HitPaw_OnlineTools_LinkData.en;
  },

  // HitPaw 首页 链接
  getHitPawHomeLink: () => {
    let lang = Utils.getLanguage();
    lang = lang === "in" ? "inn" : lang;
    return HitPaw_Home_LinkData[lang] ? HitPaw_Home_LinkData[lang] : HitPaw_Home_LinkData.en;
  },

  // HitPaw 去水印产品 链接
  getHitPawWatermarkRemoverPageLink: () => {
    let lang = Utils.getLanguage();
    lang = lang === "in" ? "inn" : lang;
    return HitPaw_WatermarkRemover_Product_LinkData[lang]
      ? HitPaw_WatermarkRemover_Product_LinkData[lang]
      : HitPaw_WatermarkRemover_Product_LinkData.en;
  },

  // HitPaw 去水印产品 链接
  getHitPawVideoEditorPageLink: () => {
    let lang = Utils.getLanguage();
    lang = lang === "in" ? "inn" : lang;
    return HitPawVideoEdito_Product_LinkData[lang] ? HitPawVideoEdito_Product_LinkData[lang] : HitPawVideoEdito_Product_LinkData.en;
  },

  isImageLoadedSuccess: async (list, key, callback) => {
    console.log(list);
    let i = 0;
    const _load = async () => {
      if (list[i].audiofileurl) {
        if (i < list.length - 1) {
          i++;
          _load();
        }
        return false;
      }
      let loaded = await new Promise(resolve => {
        const img = new Image();
        img.onerror = error => {
          message.warn(
            window.i18n.t(
              "common:The thumbnail is missing, but you can download and continue editing videos normally. If you feel troubled, please download the video first and then upload it again."
            ),
            7
          );
          console.log("图片请求失败");
          resolve(false);
          callback("0");
        };
        img.onload = () => {
          resolve(true);
          if (i === list.length - list.filter(item => item.audiofileurl).length - 1) {
            console.log("图片全部请求完成");
            callback("1");
          }
        };
        img.src = list[i][key];
      });
      if (loaded && i < list.length - 1) {
        i++;
        _load();
      }
    };
    _load();
  },

  isVideoBlobURLLoadedSuccess: async blobUrl => {
    return new Promise((resolve, reject) => {
      const videoCont = document.createElement("video");
      videoCont.setAttribute("src", blobUrl);
      videoCont.load();
      videoCont.addEventListener("loadedmetadata", () => {
        if (videoCont.videoWidth && videoCont.videoHeight) {
          resolve(true);
        } else {
          reject(false);
        }
      });
    });
  },

  screenshots: videoDOM => {
    ReactGA.event(
      {
        category: "Screenshot",
        action: "ScreenshotSave",
        label: store.getState().work.work,
      },
      ["OnlineTracker", "Tracker"]
    );
    try {
      const video = document.querySelector("#videoRef video");
      const canvas = document.querySelector("#canvas");
      const context = canvas.getContext("2d");
      const w = video.videoWidth;
      const h = video.videoHeight;
      canvas.width = w;
      canvas.height = h;
      // video.addEventListener("canplaythrough", () => {});
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const dataUrl = canvas.toDataURL("image/jpg");
      Utils.downloadBase64(dataUrl);
      message.success(window.i18n.t("common:Screenshots downloaded."));
    } catch (err) {
      console.log(err);
      message.warn("截图失败");
    }
  },

  setVideoContainerSize: (container, video, callback, type, cutWidth) => {
    console.log("videoWidth", video.videoWidth);
    if (video.videoWidth && video.handleLoadEvent) {
      console.log("videoWidth加载完成");
      const windowWidth = window.innerWidth;
      const videoWidth = video.videoWidth;
      const videoHeight = video.videoHeight;
      const limitWidth = cutWidth ? container.clientWidth - cutWidth : container.clientWidth;
      const limitHeight = container.clientHeight - (windowWidth >= 992 ? 350 : 100);
      const PreviewProportion = limitWidth / limitHeight;
      const videoProportion = videoWidth / videoHeight;
      if (videoProportion >= PreviewProportion) {
        video.style.width = limitWidth + "px";
        video.style.height = limitWidth / videoProportion + "px";
      } else {
        video.style.height = limitHeight + "px";
        video.style.width = limitHeight * videoProportion + "px";
      }
      if (callback) callback(type);
    } else {
      video.load();
      if (!video.handleLoadEvent) {
        video.handleLoadEvent = e => {
          const windowWidth = window.innerWidth;
          const videoWidth = e.target.videoWidth;
          const videoHeight = e.target.videoHeight;
          const limitWidth = cutWidth ? container.clientWidth - cutWidth : container.clientWidth;
          const limitHeight = container.clientHeight - (windowWidth >= 992 ? 350 : 100);
          const PreviewProportion = limitWidth / limitHeight;
          const videoProportion = videoWidth / videoHeight;
          if (videoProportion >= PreviewProportion) {
            video.style.width = limitWidth + "px";
            video.style.height = limitWidth / videoProportion + "px";
          } else {
            video.style.height = limitHeight + "px";
            video.style.width = limitHeight * videoProportion + "px";
          }
          if (callback) callback(type);
        };
        video.addEventListener("loadeddata", video.handleLoadEvent);
      }
    }
  },

  copyText: text => {
    console.log(text);
    let textarea = document.createElement("input");
    let currentFocus = document.activeElement;
    let flag;
    document.body.appendChild(textarea);
    textarea.value = text;
    textarea.focus();
    if (textarea.setSelectionRange) textarea.setSelectionRange(0, textarea.value.length);
    else textarea.select();
    try {
      flag = document.execCommand("copy");
    } catch (eo) {
      flag = false;
    }
    document.body.removeChild(textarea);
    currentFocus.focus();
    message.success(flag ? window.i18n.t("common:Video link copied") : "拷贝失败");
    return flag;
  },

  /**
   * 当用户没有登录，或者用户当前不是会员的时候，购买弹窗的判定条件
   * @returns 是否需要弹出购买弹窗
   */
  isNeedToShowPurchaseModal: () => {
    if (
      !store.getState().common.profileObj ||
      !store.getState().common.subscription ||
      (store.getState().common.subscription && store.getState().common.subscription.status !== "Active")
    ) {
      return true;
    }
    return false;
  },

  /**
   * 因为改版后后端返回的文件对象跟之前定义的差很多，所以通过该方法对其文件对象进行修改
   */
  getFileObj: fileObj => {
    const newFileObj = {
      name: fileObj.file_name,
      guid: fileObj.job_id,
      jobId: fileObj.job_id,
      duration: moment("00:00:00", "mm:ss:SS")
        .add(fileObj.video_media_info.duration, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1"),
      size: fileObj.file_size,
      thumbnail: fileObj.thumbnail_urls.length ? fileObj.thumbnail_urls[0] : null,
      status: videoWorkType.get(fileObj.media_job_type),
      videofileurl: fileObj.url,
      screenshots: fileObj.thumbnail_urls,
      mark: false,
      uploadStatus: true,
      createTime: Number(`${fileObj.created_at}000`),
      fileObj: {},
      wh: `${fileObj.video_media_info.width}x${fileObj.video_media_info.height}`,
      fps: fileObj.video_media_info.fps,
      bitrate: fileObj.video_media_info.bit_rate,
    };
    return newFileObj;
  },

  omissionString: (str, preLength, nextLength) => {
    if (str && str.length > preLength + nextLength) {
      let subStr1 = str.substr(0, preLength);
      let subStr2 = str.substr(str.length - nextLength, nextLength);
      let subStr = subStr1 + "..." + subStr2;
      return subStr;
    }
    return str;
  },
};

export default Utils;
