import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from "react-router-dom";
import "./App_ar.less";
import "./App.scss";
import "./App.less";
import Home from "./components/Home";

function App({ lang }) {
  return (
    <Router>
      <Switch>
        <Route path="/">
          <Home lang={lang} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
