export default {
  freeTimesLimitTitle: "Uw proefperiode is vandaag afgelopen, upgrade naar Pro om toegang te krijgen tot alle premium functies",
  fileSizeLimitTitle:
    "De bestandsgrootte voor gratis accounts mag niet groter zijn dan 20MB, upgrade naar Pro om toegang te krijgen tot alle premium functies",
  total: "Totaal",
  enterCode: "Voer code in",
  signUpToPurchaseBtn: "Meld u aan om te kopen",
  goPurchseBtn: "Ga naar Kopen",
  saveTips: "Bespaar tot {{num}}%",
  discount: "Korting",
  montly: "Maandelijks",
  yearly: "Jaarlijks",
  vipTips1: "Geen video grootte en hoeveelheid limiet",
  vipTips2: "Verwijder beeld achtergronden zonder limieten",
  vipTips3: "Snellere videoverwerking en downloadsnelheid",
  vipTips4: "Toegang tot alle gratis gereedschap & premium functies",
  purchaseTips: "Automatisch verlengen en op elk moment opzeggen.",
  tips: "Tips:",
  promoCodeInvalidTips: "Uw promotiecode is ongeldig",
};
