import React from "react";
import { Button, Progress } from "antd";
import { useTranslation } from "react-i18next";
import { ReactComponent as Close } from "@/assets/icons/close-circle.svg";
import { ReactComponent as ToolkitLogo } from "@/assets/images/hitpaw-toolkit-logo.svg";
import { ReactComponent as WatermarkRmoverLogo } from "@/assets/images/hitpaw-watermark-remover-logo.svg";
import { ReactComponent as VideoEditorLogo } from "@/assets/images/hitpaw-video-editor-logo.svg";

import Utils from "@/utils/utils";

export const ProcessGuideToDownloadModalRender = ({
  currFile,
  cancleProcess,
  uploadPercent,
  processPercent,
  processStep,
  productKey,
  handleDownloadGA,
}) => {
  const { t } = useTranslation(["fileList", "common"]);
  const productIntrInfoList = [
    {
      key: "hitpaw-toolkit",
      title: "HitPaw Toolkit",
      logo: () => <ToolkitLogo />,
      list: [
        "0 second wait",
        "No size limits",
        "Support 1000+ formats",
        "Support cut, crop & rotate, adjust, speed, add music, stop motion, etc.",
        "The easiest video editing software for beginners",
      ],
    },
    {
      key: "hitpaw-watermark-remover",
      title: "HitPaw Watermark Remover",
      logo: () => <WatermarkRmoverLogo />,
      list: ["0 second wait", "No size limits", "Support 1000+ formats", "Remove watermark from videos and images in simple clicks"],
    },
    {
      key: "hitpaw-video-editor",
      title: "HitPaw Video Editor",
      logo: () => <VideoEditorLogo />,
      list: [
        "No video size limits",
        "Cut/crop/rotate/resize/merge video easily",
        "Tons of video effects are available for your video creativity",
        "Unlimited tracks allow you to add multiple audios as you want",
      ],
    },
  ];

  const product =
    productKey === "hitpaw-watermark-remover"
      ? productIntrInfoList[1]
      : productKey === "hitpaw-video-editor"
      ? productIntrInfoList[2]
      : productIntrInfoList[0];
  return (
    <div className={`uploading guideToDownloadCont horizontal ${!currFile ? "upload" : ""}`}>
      {uploadPercent < 100 && uploadPercent > 0 ? (
        <div className="percent-title">{t("Uploading... Please wait")}</div>
      ) : (
        <div className="percent-title">{t("Processing... Please wait")}</div>
      )}
      <div className="progress">
        <Progress strokeColor="#8372FF" percent={parseInt((uploadPercent + processPercent) / processStep)} status="active" />
        <div className="close" onClick={cancleProcess}>
          <Close />
        </div>
      </div>
      <div className="product-card">
        {product.logo()}
        <h4>{t("common:" + product.title)}</h4>
        <ul>
          {product.list.map((item, index) => (
            <li key={index}>{t(`common:${item}`)}</li>
          ))}
        </ul>
        <Button
          href={
            productKey === "hitpaw-watermark-remover"
              ? Utils.getHitPawWatermarkRemoverEXELink()
              : productKey === "hitpaw-video-editor"
              ? Utils.getHitPawVideoEditorEXELink()
              : Utils.getHitPawToolKitEXELink()
          }
          onClick={() =>
            handleDownloadGA(
              productKey === "hitpaw-watermark-remover"
                ? "RemoverDownload"
                : window.userClient.platform === "win"
                ? "Video_Editor_Download"
                : "ToolkitDownload"
            )
          }
          type="primary"
          size="large"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("common:TRY IT FREE")}
        </Button>
        <Button
          href={
            productKey === "hitpaw-watermark-remover"
              ? Utils.getHitPawWatermarkRemoverBuyLink(window.userClient.platform === "win" ? "win" : "mac")
              : productKey === "hitpaw-video-editor"
              ? Utils.getHitPawVideoEditorBuyLink()
              : Utils.getHitPawToolKitBuyLink()
          }
          type="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("common:BUY NOW")}
        </Button>
      </div>
    </div>
  );
};
