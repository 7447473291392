import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "antd";
import AddMusicTrimAudioFunction from "./children/AddMusicTrimAudioModalFunction";

class AddMusicTrimAudio extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal
        centered
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        footer={null}
        width="600px"
        // height="250px"
        getContainer={document.querySelector(".Home")}
        className="addMusicTrimAudioModal"
      >
        <AddMusicTrimAudioFunction
          currFile={this.props.currFile}
          addMusicWorkObj={this.props.addMusicWorkObj}
          addMusicAudioTimeObj={this.props.addMusicAudioTimeObj}
          confirmBtnHandle={this.props.confirmBtnHandle}
          onCancel={this.props.onCancel}
        />
      </Modal>
    );
  }
}

const mapStateToPropos = (state) => ({
  addMusicWorkObj: state.work.addMusicWorkObj,
  currFile: state.files.currFile,
  addMusicAudioTimeObj: state.work.addMusicAudioTimeObj,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToPropos, mapDispatchToProps)(AddMusicTrimAudio);
