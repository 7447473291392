import {
  onFilesChange,
  onPreUploadFileChange,
  onCurrFileChange,
  onUploadAgainFileChange,
  onSuccessFileGuidsChange,
  onSelectedFileChange,
} from "./files";
import {
  onFileListModalChange,
  onTokenIdChange,
  onHandleImgErrChange,
  onUserActionChange,
  onPurchaseModalObjChange,
  onAuthorizationChange,
  onProfileObjChange,
  onSubscriptionChange,
  onThemeChange,
  onVideoEditorTimesChange,
} from "./common";
import {
  onWorkChange,
  onPrevWorkChange,
  onCutWorkListChange,
  onResolutionChange,
  onVideoBgColorChange,
  onUploadingChange,
  onUploadPercentChange,
  onProcessPercentChange,
  onProcessStepChange,
  onMergeWorkListChange,
  onCurrProgressChange,
  onStartTimeChange,
  onCancelProgressChange,
  onPlaybackRateChange,
  onCropChange,
  onRotateChange,
  onFlipChange,
  onFillingChange,
  onRemoveWatermarkListChange,
  onDragVideoPositionChange,
  onFileToBlobStatusChange,
  onSubmittingChange,
  onUploadXHRChange,
  onAddMusicWorkObjChange,
  onAddMusicAudioStartPlayTimeChange,
  onAddMusicAudioTimeObjChange,
  onAddMusicAudioVolumeObjChange,
  onSubtitleWorkListChange,
  onAddWatermarkWorkListChange,
  onQuicklyPositionChange,
  onFetchAbortChange,
  onLoopTimesChange,
  onReverseObjChange,
  onProcessModalVisibleChange,
} from "./work";

export default {
  onFilesChange,
  onWorkChange,
  onPrevWorkChange,
  onCutWorkListChange,
  onMergeWorkListChange,
  onResolutionChange,
  onFileListModalChange,
  onProcessPercentChange,
  onVideoBgColorChange,
  onTokenIdChange,
  onPreUploadFileChange,
  onUploadingChange,
  onUploadPercentChange,
  onProcessStepChange,
  onHandleImgErrChange,
  onCurrProgressChange,
  onStartTimeChange,
  onCurrFileChange,
  onCancelProgressChange,
  onUserActionChange,
  onPlaybackRateChange,
  onCropChange,
  onRotateChange,
  onFlipChange,
  onFillingChange,
  onRemoveWatermarkListChange,
  onDragVideoPositionChange,
  onFileToBlobStatusChange,
  onSubmittingChange,
  onUploadAgainFileChange,
  onUploadXHRChange,
  onAddMusicWorkObjChange,
  onAddMusicAudioTimeObjChange,
  onAddMusicAudioStartPlayTimeChange,
  onAddMusicAudioVolumeObjChange,
  onSubtitleWorkListChange,
  onQuicklyPositionChange,
  onAddWatermarkWorkListChange,
  onFetchAbortChange,
  onSuccessFileGuidsChange,
  onSelectedFileChange,
  onLoopTimesChange,
  onReverseObjChange,
  onProcessModalVisibleChange,
  onPurchaseModalObjChange,
  onAuthorizationChange,
  onProfileObjChange,
  onSubscriptionChange,
  onThemeChange,
  onVideoEditorTimesChange,
};
