import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { workplaceHOC } from "../WorkplaceHOC";
import AddWatermarkWorkFunction from "./children/AddWatermarkWorkFunction";
import actions from "@/action";
import Utils from "@/utils/utils";
import Konva from "konva";
import ReactGA from "react-ga";
import TipsModal from "@/components/common/TipsModal";
import "gifler";
import "@/assets/addWatermarkWork.scss";
import { request } from "@/api/request";

class AddWatermarkWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentItemIndex: 0,
      color: "#ffffff",
      border: "none",
      background: "none",
      fontSize: 22,
      fontSizeList: [8, 10, 12, 14, 18, 22, 28, 32, 36, 42, 48, 60, 72],
      fontFamily: "System",
      fontFamilyList: [
        "System",
        "Impact",
        "Arial",
        "Verdana",
        "Courier",
        "Helvetica",
        "Times New Roman",
        "Arial Unicode MS",
        "Georgia",
        "Trebuchet MS",
        "Arial Black",
        "Calibri",
        "Cambria",
        "Cambria Math",
        "Courier New",
        "Microsoft Yahei",
        "Modern",
        "MS Sans Serif",
        "MS Serif",
        "Tahoma",
        "Symbol",
        "Sagoe UI",
      ],
      warningInfo: false,
      warningInfoText: "",
      warningInfoObj: {},
    };
    this.textAreaRef = [];
  }

  initWork = () => {
    const { currFile } = this.props;
    const obj = {
      subtitle: window.i18n.t("addWatermarkWork:Text here"),
      start: "00:00.00",
      end: Utils.getSeconds(currFile.duration) > 3 ? "00:03.00" : currFile.duration,
      initFlag: true,
      selected: true,
      color: this.state.color,
      border: this.state.border,
      background: this.state.background,
      fontSize: this.state.fontSize,
      fontFamily: this.state.fontFamily,
      position: { x: 0, y: 0 },
      tabVal: "image",
    };
    this.props.onAddWatermarkWorkListChange([obj]);
  };

  addWork = () => {
    this.props.onUserActionChange(true);
    const myvideo = document.querySelector("#videoRef video");
    if (this.checkEmptySubtitle()) {
      return false;
    }
    const { addWatermarkWorkList, currFile } = this.props;
    if (!addWatermarkWorkList.length) {
      const obj = {
        subtitle: window.i18n.t("addWatermarkWork:Text here"),
        // start:'00:00.00',
        // end: Utils.getSeconds(currFile.duration) > 3 ? '00:03.00' : currFile.duration,
        start: moment("00:00:00", "mm:ss:SS")
          .add(myvideo.currentTime, "seconds")
          .format("mm:ss:SS")
          .replace(/:([^:]*)$/, ".$1"),
        end: currFile.duration,
        initFlag: true,
        selected: true,
        color: this.state.color,
        border: this.state.border,
        background: this.state.background,
        fontSize: this.state.fontSize,
        fontFamily: this.state.fontFamily,
        position: { x: 0, y: 0 },
      };
      this.props.onAddWatermarkWorkListChange([obj]);
    } else {
      let list = [...addWatermarkWorkList];
      list.forEach(item => {
        item.selected = false;
      });
      const subtitleList = addWatermarkWorkList.filter(item => item.subtitle && !item.imageObj);
      // const lastItem = addWatermarkWorkList[addWatermarkWorkList.length - 1]
      // const remainingTime = Utils.getSeconds(currFile.duration) - Utils.getSeconds(lastItem.end)
      const style = subtitleList.length
        ? { ...subtitleList[0] }
        : {
            color: this.state.color,
            border: this.state.border,
            background: this.state.background,
            fontSize: this.state.fontSize,
          };
      let newItem = {
        ...style,
        subtitle: window.i18n.t("addWatermarkWork:Text here"),
        initFlag: true,
        selected: true,
        // start:'00:00.00',
        start: moment("00:00:00", "mm:ss:SS")
          .add(myvideo.currentTime, "seconds")
          .format("mm:ss:SS")
          .replace(/:([^:]*)$/, ".$1"),
        end: currFile.duration,
        position: { x: 0, y: 0 },
      };
      console.log(newItem);
      // if(remainingTime <= 0){
      //   newItem.start = lastItem.start
      //   newItem.end = lastItem.end
      // }else{
      //   newItem.start = lastItem.end
      //   newItem.end = remainingTime > 3 ? moment(lastItem.end.replace('.'), 'mm:ss:SS').add(3,'seconds').format('mm:ss:SS').replace(/:([^:]*)$/, '.$1') : currFile.duration
      // }
      list.push(newItem);
      this.props.onAddWatermarkWorkListChange(list);
    }
  };

  checkEmptySubtitle = () => {
    let flag = false;
    this.props.addWatermarkWorkList.some((item, index) => {
      if (!item.subtitle && !item.imageObj) {
        this.textAreaRef[index].focus();
        flag = true;
        let list = [...this.props.addWatermarkWorkList];
        list.forEach(obj => {
          obj.selected = false;
        });
        list[index].selected = true;
        this.props.onAddWatermarkWorkListChange(list);
        // this.setState({ currentItemIndex: index })
        return true;
      }
      return false;
    });
    return flag;
  };

  removeWork = (e, index) => {
    e.stopPropagation();
    // if(this.checkEmptySubtitle()){
    //   return false
    // }
    const { addWatermarkWorkList } = this.props;
    let list = [...addWatermarkWorkList];
    list.splice(index, 1);
    this.props.onAddWatermarkWorkListChange(list);
  };

  selectWork = index => {
    if (this.checkEmptySubtitle()) {
      return false;
    }
    let list = [...this.props.addWatermarkWorkList];
    list.forEach((item, i) => {
      if (i === index) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
    this.props.onAddWatermarkWorkListChange(list);
    this.setState({ currentItemIndex: index });
  };

  handleSetColor = (key, { hex }) => {
    this.props.onUserActionChange(true);
    if (hex === "#000") {
      return false;
    }
    const { addWatermarkWorkList } = this.props;
    const list = [...addWatermarkWorkList];
    list.forEach(item => {
      item[key] = hex;
    });
    this.props.onAddWatermarkWorkListChange(list);
    switch (key) {
      case "color":
        this.setState({ color: hex });
        break;
      case "border":
        this.setState({ border: hex });
        break;
      case "background":
        this.setState({ background: hex });
        break;
      default:
        break;
    }
  };

  handleSubtitleChange = (e, index) => {
    this.props.onUserActionChange(true);
    const subtitle = e.target.value;
    const { addWatermarkWorkList } = this.props;
    const list = [...addWatermarkWorkList];
    list[index] = {
      ...list[index],
      subtitle,
      initFlag: false,
    };
    this.props.onAddWatermarkWorkListChange(list);
  };

  onBlurSubtitle = (e, index) => {
    if (!e.target.value) {
      this.textAreaRef[index].focus();
    }
  };

  onFocusSubtitle = index => {
    const { addWatermarkWorkList } = this.props;
    const list = [...addWatermarkWorkList];
    if (list[index].initFlag) {
      list[index].subtitle = "";
      this.props.onAddWatermarkWorkListChange(list);
    }
    this.setState({ currentItemIndex: index });
  };

  handleSetFontSize = val => {
    this.props.onUserActionChange(true);
    const { addWatermarkWorkList } = this.props;
    const list = [...addWatermarkWorkList];
    list.forEach(item => {
      item.fontSize = val;
    });
    this.props.onAddWatermarkWorkListChange(list);
    this.setState({ fontSize: val });
  };

  handleSetFontFamily = val => {
    this.props.onUserActionChange(true);
    const { addWatermarkWorkList } = this.props;
    const list = [...addWatermarkWorkList];
    list.forEach(item => {
      item.fontFamily = val;
    });
    this.props.onAddWatermarkWorkListChange(list);
    this.setState({ fontFamily: val });
  };

  handleQuickSetColor = (key, val) => {
    this.props.onUserActionChange(true);
    const { addWatermarkWorkList } = this.props;
    const list = [...addWatermarkWorkList];
    list.forEach(item => {
      item[key] = val;
    });
    this.props.onAddWatermarkWorkListChange(list);
    switch (key) {
      case "color":
        this.setState({ color: val });
        break;
      case "border":
        this.setState({ border: val });
        break;
      case "background":
        this.setState({ background: val });
        break;
      default:
        break;
    }
  };

  handleStartChange = e => {
    const value = e.target.value;
    const addWatermarkWorkList = [...this.props.addWatermarkWorkList];
    const subtitleWork = addWatermarkWorkList.filter(item => item.selected)[0];
    subtitleWork.start = value;
    this.props.onAddWatermarkWorkListChange(addWatermarkWorkList);
    this.props.onUserActionChange(true);
  };

  handleEndChange = e => {
    const value = e.target.value;
    const addWatermarkWorkList = [...this.props.addWatermarkWorkList];
    const subtitleWork = addWatermarkWorkList.filter(item => item.selected)[0];
    subtitleWork.end = value;
    this.props.onAddWatermarkWorkListChange(addWatermarkWorkList);
    this.props.onUserActionChange(true);
  };

  handleSetOpacity = (value, index) => {
    this.props.onUserActionChange(true);
    const addWatermarkWorkList = [...this.props.addWatermarkWorkList];
    const subtitleWork = addWatermarkWorkList[index];
    subtitleWork.opacity = value;
    this.props.onAddWatermarkWorkListChange(addWatermarkWorkList);
  };

  handleSubmit = async () => {
    const action = "addWatermark";
    const startTime = { ...this.props.startTime };
    startTime.addWatermark = new Date();
    this.props.onStartTimeChange(startTime);
    if (!this.props.uploading && this.props.uploadPercent < 100) {
      this.props.onProcessPercentChange(1);
      this.props.onProcessStepChange(1);
    }
    const list = this.getFormData();
    let subtitleResponse;
    try {
      subtitleResponse = await this.getAddSubtitleToVideo(list, action);
    } catch (error) {
      this.props.handleSubmitFailModal(true);
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "AddMark",
          action: "AddMarkFail",
          label: error + "_none_none_" + this.props.tokenId + "_none_none",
        },
        ["OnlineTracker", "Tracker"]
      );
      return false;
    }
    if (subtitleResponse.code === 200) {
      let guids = [];
      try {
        guids = await this.props.getNewVideoByProgress("addWatermark", subtitleResponse);
      } catch (error) {
        if (error !== "cancel") {
          ReactGA.event(
            {
              category: "AddMark",
              action: "AddMarkFail",
              label: action + "_200_200_" + error,
            },
            ["OnlineTracker", "Tracker"]
          );
        }
        return false;
      }
      // const modal = {
      //   getContainer: document.querySelector(".Home"),
      //   show: true,
      //   type: "addWatermarkSuccess",
      //   guid: guids[0],
      //   title: "Generate Files Successfully!",
      //   buttons: [
      //     {
      //       type: "download",
      //       text: "Download",
      //     },
      //     {
      //       type: "editNew",
      //       text: "Edit New Video",
      //     },
      //   ],
      // };
      // this.props.onFileListModalChange(modal);
      this.props.onSuccessFileGuidsChange(guids);
      this.props.onPrevWorkChange("addWatermark");
      // this.props.onWorkChange(null);
      this.props.onUploadPercentChange(0);
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      // this.props.onAddWatermarkWorkListChange([])
      ReactGA.event(
        {
          category: "AddMark",
          action: "AddMarkSuccess",
        },
        ["OnlineTracker", "Tracker"]
      );
    } else {
      if (subtitleResponse.code === 402102) {
        this.props.onPurchaseModalObjChange({
          visible: true,
          type: "videoEditorTimesMoreThanLimit",
        });
      } else {
        this.props.handleSubmitFailModal(true);
      }
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "AddMark",
          action: "AddMarkFail",
          label: action + "_200_" + subtitleResponse.code + "_none_" + this.props.tokenId + "_none_" + subtitleResponse.msg,
        },
        ["OnlineTracker", "Tracker"]
      );
    }
  };

  preSubmit = () => {
    const { addWatermarkWorkList } = this.props;
    let imageNum = 0;
    let textNum = 0;
    let opacity = 0;
    let fontSize = "n";
    let fontFamily = "n";
    addWatermarkWorkList.forEach(item => {
      if (item.imageObj) {
        imageNum++;
        if (item.opacity !== 1) {
          opacity = 1;
        }
      } else {
        textNum++;
        fontSize = item.fontSize;
        fontFamily = item.fontFamily;
      }
    });
    let label = `${imageNum}_${imageNum === 0 ? "n" : opacity}_${textNum}_${textNum === 0 ? "n" : fontFamily}_${textNum === 0 ? "n" : fontSize}`;
    ReactGA.event(
      {
        category: "AddMark",
        action: "AddMarkSave",
        label: label,
      },
      ["OnlineTracker", "Tracker"]
    );
    //函数返回true，则是当前已经超过限制
    let flag = this.props.handleVideoEditorTimes();
    if (!flag) {
      this.props.preSubmit(1, this.handleSubmit);
    }
  };

  getFormData = () => {
    const video = document.querySelector("#videoRef video");
    const { addWatermarkWorkList } = this.props;
    const w = video.videoWidth;
    const h = video.videoHeight;
    const list = [];
    const stage = new Konva.Stage({
      container: "container",
      width: w,
      height: h,
    });
    let anim;
    const layer = new Konva.Layer();
    addWatermarkWorkList.forEach(item => {
      layer.clearBeforeDraw();
      layer.destroyChildren();
      if (item.imageObj) {
        if (item.gif) {
          const canvas = document.querySelector("#GIFRef");
          window.gifler(item.imageUrl).get(a => {
            anim = a;
            anim.animateInCanvas(canvas);
            anim.onDrawFrame = (ctx, frame) => {
              ctx.drawImage(frame.buffer, frame.x, frame.y);
            };
          });
          const image = new Konva.Image({
            image: canvas,
            width: item.width,
            height: item.height,
            opacity: item.opacity,
            x: item.position.x,
            y: item.position.y,
          });
          layer.add(image);
        } else {
          const image = new Konva.Image({
            image: item.imageObj,
            width: item.width,
            height: item.height,
            opacity: item.opacity,
            x: item.position.x,
            y: item.position.y,
          });
          layer.add(image);
        }
      } else {
        const label = new Konva.Label({
          x: item.position.x,
          y: item.position.y,
        });
        const tag = new Konva.Tag({
          fill: item.background === "none" ? null : item.background,
        });
        const text = new Konva.Text({
          text: item.subtitle,
          fill: item.color,
          fontSize: item.fontSize,
          padding: item.background !== "none" || item.border !== "none" ? 8 : 0,
          stroke: item.border === "none" ? null : item.border,
          strokeWidth: 0.5,
          fontFamily: item.fontFamily,
        });
        label.add(tag);
        label.add(text);
        layer.add(label);
      }
      stage.add(layer);
      layer.draw();
      const obj = {
        enable: `${Utils.getSeconds(item.start)},${Utils.getSeconds(item.end)}`,
        content: stage.toDataURL(),
      };
      list.push(obj);
    });
    return list;
  };

  getAddSubtitleToVideo = (list, action) => {
    const formData = new FormData();
    list.forEach(item => {
      let subtitle = JSON.stringify(item);
      formData.append("locations[]", subtitle);
    });
    formData.append("token", this.props.tokenId);
    formData.append("type", "image");
    formData.append("video_url", this.props.currFile.videofileurl);
    return new Promise((resolve, reject) => {
      request(action, {
        method: "post",
        body: formData,
      })
        .then(responseJSON => {
          resolve(responseJSON);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  uploadProps = {
    accept: ".jpg, .jpeg, .png, .bmp, .ico",
    onChange: info => {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
      } else if (status === "error") {
        this.setState({ uploadFail: true });
      }
    },
    beforeUpload: async file => {
      this.props.onUserActionChange(true);
      const video = document.querySelector("#videoRef video");
      const blob = new Blob([file], { type: file.type });
      const imageUrl = URL.createObjectURL(blob);
      let imageObj;
      try {
        imageObj = await Utils.blobToImage(blob);
      } catch (error) {
        this.setState({
          warningInfo: true,
          warningInfoText: "This format is not supported currently.",
          warningInfoObj: {
            type: "format",
            format: /\.[^.]+$/.exec(file.name)[0].toLocaleLowerCase(),
          },
        });
        return false;
      }
      const { addWatermarkWorkList, currFile } = this.props;
      const myvideo = document.querySelector("#videoRef video");
      const imageRatio = imageObj.width / imageObj.height;
      let imageWidth;
      let imageHeight;
      if (imageObj.width >= imageObj.height) {
        if (imageObj.width > video.videoWidth / 3) {
          imageWidth = video.videoWidth / 3;
          imageHeight = imageWidth / imageRatio;
        } else {
          imageWidth = imageObj.width;
          imageHeight = imageObj.height;
        }
      } else {
        if (imageObj.height > video.videoHeight / 3) {
          imageHeight = video.videoHeight / 3;
          imageWidth = imageHeight * imageRatio;
        } else {
          imageWidth = imageObj.width;
          imageHeight = imageObj.height;
        }
      }
      let list = [...addWatermarkWorkList];
      list.forEach(item => {
        item.selected = false;
      });
      let newItem = {
        imageObj: imageObj,
        selected: true,
        position: { x: 0, y: 0 },
        imageUrl: imageUrl,
        start: moment("00:00:00", "mm:ss:SS")
          .add(myvideo.currentTime, "seconds")
          .format("mm:ss:SS")
          .replace(/:([^:]*)$/, ".$1"),
        end: currFile.duration,
        opacity: 1,
        width: imageWidth,
        height: imageHeight,
        gif: file.type === "image/gif",
      };
      list.push(newItem);
      this.props.onAddWatermarkWorkListChange(list);
      return false;
    },
  };

  componentDidMount() {
    this.setState({
      color: "#ffffff",
      border: "none",
      background: "none",
      fontSize: 22,
      fontFamily: "System",
    });
    this.props.onAddWatermarkWorkListChange([]);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currFile !== this.props.currFile || prevProps.work !== this.props.work) {
      this.props.onAddWatermarkWorkListChange([]);
      this.setState({
        color: "#ffffff",
        border: "none",
        background: "none",
        fontSize: 22,
        fontFamily: "System",
      });
    }
  }

  render() {
    return (
      <div style={{ height: "100%" }}>
        <AddWatermarkWorkFunction
          textAreaRef={this.textAreaRef}
          fontFamilyList={this.state.fontFamilyList}
          fontSizeList={this.state.fontSizeList}
          color={this.state.color}
          border={this.state.border}
          background={this.state.background}
          addWatermarkWorkList={this.props.addWatermarkWorkList}
          currFile={this.props.currFile}
          uploadProps={this.uploadProps}
          handleStartChange={this.handleStartChange}
          handleEndChange={this.handleEndChange}
          selectWork={this.selectWork}
          handleQuickSetColor={this.handleQuickSetColor}
          handleSetColor={this.handleSetColor}
          addWork={this.addWork}
          removeWork={this.removeWork}
          handleSetOpacity={this.handleSetOpacity}
          onBlurSubtitle={this.onBlurSubtitle}
          onFocusSubtitle={this.onFocusSubtitle}
          handleSetFontSize={this.handleSetFontSize}
          handleSetFontFamily={this.handleSetFontFamily}
          handleSubtitleChange={this.handleSubtitleChange}
          preSubmit={this.preSubmit}
        />
        <div id="container" style={{ display: "none" }}></div>
        <TipsModal
          showGuidText
          visible={this.state.warningInfo}
          onCancel={() => {
            this.setState({ warningInfo: false });
          }}
          content={this.state.warningInfoText}
          tipsObj={this.state.warningInfoObj}
          cancelBtnHandle={() => {
            this.setState({ warningInfo: false });
          }}
          hasDownload
        />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  addWatermarkWorkList: state.work.addWatermarkWorkList,
  tokenId: state.common.tokenId,
  uploading: state.work.uploading,
  processStep: state.work.processStep,
  processPercent: state.work.processPercent,
  cancelProgress: state.work.cancelProgress,
  submitting: state.work.submitting,
  uploadPercent: state.work.uploadPercent,
  startTime: state.work.startTime,
  work: state.work.work,
});
const mapDispatchToProps = dispatch => ({
  onAddWatermarkWorkListChange: addWatermarkWorkList => dispatch(actions.onAddWatermarkWorkListChange(addWatermarkWorkList)),
  onProcessPercentChange: processPercent => dispatch(actions.onProcessPercentChange(processPercent)),
  onProcessStepChange: processStep => dispatch(actions.onProcessStepChange(processStep)),
  onPreUploadFileChange: preUploadFile => dispatch(actions.onPreUploadFileChange(preUploadFile)),
  onUploadAgainFileChange: uploadAgainFile => dispatch(actions.onUploadAgainFileChange(uploadAgainFile)),
  onUploadPercentChange: uploadPercent => dispatch(actions.onUploadPercentChange(uploadPercent)),
  onSubmittingChange: submitting => dispatch(actions.onSubmittingChange(submitting)),
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
  onFilesChange: files => dispatch(actions.onFilesChange(files)),
  onFileListModalChange: fileListModal => dispatch(actions.onFileListModalChange(fileListModal)),
  onStartTimeChange: startTime => dispatch(actions.onStartTimeChange(startTime)),
  onUserActionChange: userAction => dispatch(actions.onUserActionChange(userAction)),
  onWorkChange: work => dispatch(actions.onWorkChange(work)),
  onPrevWorkChange: prevWork => dispatch(actions.onPrevWorkChange(prevWork)),
  onSuccessFileGuidsChange: successFileGuids => dispatch(actions.onSuccessFileGuidsChange(successFileGuids)),
  onPurchaseModalObjChange: purchaseModal => dispatch(actions.onPurchaseModalObjChange(purchaseModal)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(workplaceHOC(AddWatermarkWork));
