import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "@/action";
import Utils from "@/utils/utils";
import ReactGA from "react-ga";
import { Drawer } from "antd";
import { ProcessModalRender } from "./children/ProcessModalRender";
import { ProcessGuideToDownloadModalRender } from "./children/ProcessGuideToDownloadModalRender";

class ProcessModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  cancleProcess = () => {
    //后台上传时，进入 reverse 功能，显示进度条，此时点击取消，隐藏上传进度条，并退出 reverse 功能
    if (this.props.work === "reverse" && this.props.currFile && !this.props.currFile.reverseVideourl && this.props.processModalVisible) {
      this.props.onProcessModalVisibleChange(false);
      this.props.onWorkChange(null);
      return false;
    }

    if (this.props.currFile && this.props.fileToBlobStatus && this.props.uploading) {
      this.props.onSubmittingChange(false);
      return false;
    }
    this.props.onSubmittingChange(false);
    this.props.onUploadingChange(false);
    this.props.onUploadPercentChange(0);
    this.props.onProcessPercentChange(0);
    this.props.onUploadXHRChange(null);
    const xhr = this.props.uploadXHR;
    if (xhr && this.props.uploadPercent < 100) {
      xhr.abort();
    } else {
      if (this.props.fetchAbort) {
        this.props.fetchAbort();
      }
      Utils.cancelProgress();
    }
    if (this.props.cancleCallbackFunc) {
      this.props.cancleCallbackFunc();
    }
  };

  handleDownloadGA = action => {
    ReactGA.event(
      {
        category: "Download",
        action: action,
        label: "processing",
      },
      ["OnlineTracker", "Tracker"]
    );
  };

  render() {
    return (
      <Drawer
        visible={
          this.props.processModalVisible
            ? this.props.processModalVisible
            : this.props.currFile
            ? this.props.submitting && (this.props.uploadPercent > 0 || this.props.processPercent > 0)
            : !this.props.fileToBlobStatus && (this.props.uploadPercent > 0 || this.props.processPercent > 0)
        }
        footer={null}
        height="100%"
        getContainer={document.querySelector(".Home")}
        className="processModal"
        keyboard={false}
        closable={false}
        placement="right"
        mask={false}
      >
        <div className="process-cont">
          {this.props.isGuideToDownload ? (
            <ProcessGuideToDownloadModalRender
              cancleProcess={this.cancleProcess}
              uploadPercent={this.props.uploadPercent}
              processPercent={this.props.processPercent}
              processStep={this.props.processStep}
              currFile={this.props.currFile}
              isGuideToDownload={this.props.isGuideToDownload}
              productKey={this.props.productKey}
              handleDownloadGA={this.handleDownloadGA}
            />
          ) : (
            <ProcessModalRender
              cancleProcess={this.cancleProcess}
              uploadPercent={this.props.uploadPercent}
              processPercent={this.props.processPercent}
              processStep={this.props.processStep}
              currFile={this.props.currFile}
            />
          )}
        </div>
      </Drawer>
    );
  }
}

const mapStateToPropos = state => ({
  processPercent: state.work.processPercent,
  processStep: state.work.processStep,
  currFile: state.files.currFile,
  fileToBlobStatus: state.work.fileToBlobStatus,
  processModalVisible: state.work.processModalVisible,
  uploading: state.work.uploading,
  uploadPercent: state.work.uploadPercent,
  submitting: state.work.submitting,
  uploadXHR: state.work.uploadXHR,
  fetchAbort: state.work.fetchAbort,
  work: state.work.work,
});
const mapDispatchToProps = dispatch => ({
  onProcessStepChange: processStep => dispatch(actions.onProcessStepChange(processStep)),
  onUploadXHRChange: uploadXHR => dispatch(actions.onUploadXHRChange(uploadXHR)),
  onProcessPercentChange: processPercent => dispatch(actions.onProcessPercentChange(processPercent)),
  onUploadingChange: uploading => dispatch(actions.onUploadingChange(uploading)),
  onUploadPercentChange: uploadPercent => dispatch(actions.onUploadPercentChange(uploadPercent)),
  onSubmittingChange: submitting => dispatch(actions.onSubmittingChange(submitting)),
  onProcessModalVisibleChange: processModalVisible => dispatch(actions.onProcessModalVisibleChange(processModalVisible)),
  onWorkChange: work => dispatch(actions.onWorkChange(work)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(ProcessModal);
