export default {
  Start: "開始",
  End: "結束",
  Save: "保存",
  Cancel: "取消",
  Download: "下載",
  downloading: "正在下載",
  "Drag and drop from Files or local disk": "從文件庫或本地拖拽文件",
  "Click to Add File": "點擊添加文件",
  "The minimum time length is 1s!": "最小時長為1s！",
  "The maximum time length is 50s!": "最大時長為50s！",
  "Try Again": "再試一次",
  "Coming Soon": "即將到來",
  "Learn more": "立即前往",
  Yes: "確定",
  "Please click the Save button to ensure the previous editing to take effect.": "如果沒有點擊保存按鈕，設置項都不會生效。",
  // **************************TipsModal Message Title*****************************
  "Cut 10 video fragments at most. Get HitPaw Toolkit for more features.": "最多截取10個片段。下載 HitPaw Toolkit 獲取更多功能。",
  "Max file size for videos is 20 MB. Get HitPaw Toolkit for big file.": "最大支持20MB的文件。下載 HitPaw Toolkit 獲取更多功能。",
  "Max file size for videos is 20 MB. Get HitPaw Watermark Remover for big file.": "最大支持20MB的文件。下載 HitPaw Watermark Remover 獲取更多功能。",
  "The minimum time length is 1s! Get HitPaw Toolkit for more features.": "最小時長為1s！下載 HitPaw Toolkit 獲取更多功能。",
  "Add 3 videos at most. Get HitPaw Toolkit for more features.": "最多添加3個視頻。下載 HitPaw Toolkit 獲取更多功能。",
  "This format is not supported currently.": "暫不支持該格式。",
  "This format is not supported currently. Get HitPaw Toolkit for more formats": "暫不支持該格式。下載 HitPaw Toolkit 獲取更多功能。",
  "This format is not supported currently. Get HitPaw Watermark Remover for more formats":
    "暫不支持該格式。下載 HitPaw Watermark Remover 獲取更多功能。",
  "Processing failed. Get HitPaw Toolkit for stable processing.": "生成視頻失敗！",
  "Failed to load!  Get HitPaw Watermark Remover for stable processing.": "加載失敗！",
  "Are you sure you want to delete it?": "您確定要刪除嗎？",
  "Failed to extract the audio!": "無法提取音頻！",
  "Video link copied": "影片鏈接已複製",
  "Cut 10 video fragments at most.": "最多截取10個片段。",
  "Max file size for videos is 20 MB.": "上傳檔案大小不超過 20Mb。",
  "Max file size for videos is 100 MB.": "上傳檔案大小不超過 100Mb。",
  "Failed to load!": "加載失敗！",

  //******************************2021.05.17 v1.3.0 *******************************/
  "Screenshots downloaded.": "已截圖並下載。",
  "Online service, no download required": "線上編輯，無需下載",
  "Add up to 20MB file": "檔案大小上限為20MB",
  "Support common formats": "支持流行格式",
  "Best for general editing requirements": "解決你的日常編輯需求",
  "0 second wait": "0秒等待",
  "No size limits": "沒有檔案大小限制",
  "Support 1000+ formats": "支持1000多種格式",
  "Stable processing": "處理穩定",
  "Get access to HitPaw Toolkit!": "查看HitPaw Toolkit！",
  "Support cut, crop & rotate, adjust, speed, add music, stop motion, etc.": "提供截取、旋轉&翻轉、調色、變速、加音樂等功能",
  "The easiest video editing software for beginners": "最適合初學者的編輯軟體",
  "Remove watermark from videos and images in simple clicks": "只需簡單點擊幾下，即可從影片和圖像中刪除浮水印",
  "TRY IT FREE": "免費下載",
  "BUY NOW": "立即購買",

  //******************************2021.09.06 v1.5.0********************************/
  "Add Video": "添加視頻",
  More: "更多",
  "History files": "歷史檔案",
  Share: "分享",
  Duration: "時長",
  "Download all": "下載全部",
  "Download selected": "下載選中部分",
  "Add Audio": "加音頻",

  //********************************2021.10.19 v1.6.0*********************************/
  "GIF does not support editing. Do you want to convert the MP4 for editing?": "GIF檔案不支援編輯，是否要轉成MP4檔案繼續編輯？",
  "Go to Edit": "去編輯",
  "Download GIF": "下載GIF",

  //********************************2021.10.19 v1.6.1*********************************/
  "HitPaw Video Editor": "HitPaw Video Editor",
  "No video size limits": "無影片大小限制。",
  "Cut/crop/rotate/resize/merge video easily": "輕鬆剪切/裁剪/旋轉/調整大小/合併影片。",
  "Tons of video effects are available for your video creativity": "大量的影片特效可供選擇。",
  "Unlimited tracks allow you to add multiple audios as you want": "無限音訊可供挑選。",

  //********************************2021.11.02 v1.6.1*********************************/
  "Download HitPaw Video Editor for more features.": "下載 HitPaw Video Editor 獲取更多功能。",
  "Get HitPaw Watermark Remover for more features.": "下載 HitPaw Watermark Remover 獲取更多功能。",
  "Get HitPaw Toolkit for more features.": "下載 HitPaw Toolkit 獲取更多功能。",
  "Add 3 videos at most.": "最多添加 3 隻影片。",
  "Processing failed.": "處理失敗。",
  "The uploaded video is less than 1 second.": "上傳的影片少於 1 秒。 ",
  "The video format is not supported.": "不支持該影片格式。 ",
  "The audio format is not supported.": "不支持音訊格式。",
  "AddFileByURL.largerSize.HitPawToolkit": "影片解析成功！但是目前暫不支援處理大小超過100MB的文件，請 <1><0>下載影片</0></1> 後用Toolkit進行處理。",
  "AddFileByURL.largerSize.HitPawWatermarkRemover":
    "影片解析成功！但是目前暫不支援處理大小超過100MB的文件，請 <1><0>下載影片</0></1> 後用HitPaw Watermark Remover進行處理。",
  "AddFileByURL.largerSize.HitPawVideoEditor":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Video Editor to proceed.",
};
