export default {
  'Files': '文件库',
  'Refresh': '刷新',
  'Original file': '原文件',
  'Just now': '刚刚',
  'hour ago': '{{num}} 小时前',
  'hours ago': '{{num}} 小时前',
  'minute ago': '{{num}} 分钟前',
  'minutes ago': '{{num}} 分钟前',
  'day ago': '{{num}} 天前',
  'View All': '查看全部',
  'No files': '暂无文件...',
  "Don't forget to download your file, it will be deleted after 24 hours": '不要忘记下载你的文件，它将在24小时后被遗弃',
  'Select All': '全选',
  'Delete': '删除',
  'Generate Files Successfully!': '生成文件成功!',
  'Continue Editing': '继续编辑',
  'Edit New Video': '编辑新视频',
  'Download': '下载',
  'Click here to open the Files again':'点击这里再次打开文件库。',
  'Processing... Please wait': '正在处理…请稍等',
  'Uploading... Please wait': '正在上传…请稍后',
  'Edit Now': '立即编辑',
  'Back to Edit':'返回编辑',
  'Go Edit':'编辑',
}