export default {
  'Files': 'Files',
  'Refresh': 'Refresh',
  'Original file': 'Original file',
  'Just now': 'Just now',
  'hour ago': '{{num}} hour ago',
  'hours ago': '{{num}} hours ago',
  'minute ago': '{{num}} minute ago',
  'minutes ago': '{{num}} minutes ago',
  'day ago': '{{num}} day ago',
  'View All': 'View All',
  'No files': 'No files...',
  "Don't forget to download your file, it will be deleted after 24 hours": "Don't forget to download your file, it will be deleted after 24 hours.",
  'Select All': 'Select All',
  'Delete': 'Delete',
  'Generate Files Successfully!': 'Generate Files Successfully!',
  'Continue Editing': 'Continue Editing',
  'Edit New Video': 'Edit New Video',
  'Download': 'Download',
  'Click here to open the Files again':'Click here to open the Files again',
  'Processing... Please wait': 'Processing... Please wait',
  'Uploading... Please wait': 'Uploading... Please wait',
  'Edit Now': 'Edit Now',
  'Back to Edit':'Back to Edit',
  'Go Edit':'Go Edit'
}