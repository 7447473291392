export default {
  Back: "Geri",
  Cut: "Kes",
  Speed: "Hız",
  "Crop & Rotate": "Kırp ve Döndür",
  Resize: "Boyutlandır",
  Extract: "Çıkar",
  "Add Music": "Müzik ekle",
  Subtitles: "Altyazılar",
  Merge: "Birleştir",
  "Watermark Remover": "Filigran Kaldırıcı",
  "Add Watermark": "Filigran ekle",
  "To GIF": "GIF'e",
  Convert: "Dönüştür",
  Screenshot: "Ekran görüntüsü",
  Reverse: "Ters Çevir",
  Loop: "Döngü",
};
