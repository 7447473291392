export default {
  Start: "Início",
  End: "Fim",
  Save: "Salvar",
  Cancel: "Cancelar",
  Download: "Baixar",
  downloading: "baixando",
  "Drag and drop from Files or local disk": "Arrastar e Soltar de Pastas ou Disco Local",
  "Click to Add File": "Clique para Adicionar Arquivos",
  "The minimum time length is 1s!": "A duração mínima é 1s!",
  "The maximum time length is 50s!": "A duração máxima é 50s!",
  "Try Again": "Tente Novamente",
  "Coming Soon": "Em breve",
  "Learn more": "Saiba mais",
  Yes: "Sim",
  "Please click the Save button to ensure the previous editing to take effect.":
    "Clique no botão Salvar para garantir que todas as configurações tenham efeito.",
  // **************************TipsModal Message Title*****************************
  "Cut 10 video fragments at most. Get HitPaw Toolkit for more features.":
    "Cortar 10 clips de vídeo no máximo. Obtenha o HitPaw Toolkit para mais recursos.",
  "Max file size for videos is 20 MB. Get HitPaw Toolkit for big file.":
    "O tamanho do arquivo não pode ser maior do que 20MB. Obtenha o HitPaw Toolkit para mais recursos.",
  "Max file size for videos is 20 MB. Get HitPaw Watermark Remover for big file.":
    "O tamanho do arquivo não pode ser maior do que 20MB. Obtenha o HitPaw Watermark Remover para mais recursos.",
  "The minimum time length is 1s! Get HitPaw Toolkit for more features.": "A duração mínima é 1s! Obtenha o HitPaw Toolkit para mais recursos.",
  "Add 3 videos at most. Get HitPaw Toolkit for more features.": "Pode adicionar no máximo 3 vídeos. Obtenha o HitPaw Toolkit para mais recursos.",
  "This format is not supported currently.": "Este formato não é suportado atualmente.",
  "This format is not supported currently. Get HitPaw Toolkit for more formats":
    "Este formato não é suportado atualmente. Obtenha o HitPaw Toolkit para mais recursos.",
  "This format is not supported currently. Get HitPaw Watermark Remover for more formats":
    "Este formato não é suportado atualmente. Obtenha o HitPaw Watermark Remover para mais recursos.",
  "Processing failed. Get HitPaw Toolkit for stable processing.": "Falha ao Gerar Vídeo!",
  "Failed to load!  Get HitPaw Watermark Remover for stable processing.": "Falha ao carregar!",
  "Are you sure you want to delete it?": "Tem certeza que deseja deletar?",
  "Failed to extract the audio!": "Falha ao extrair o áudio!",
  "Video link copied": "Link de vídeo copiado",
  "Cut 10 video fragments at most.": "Cortar 10 clips de vídeo no máximo. ",
  "Max file size for videos is 20 MB.": "O tamanho do arquivo não pode ser maior do que 20Mb.",
  "Max file size for videos is 100 MB.": "O tamanho do arquivo não pode ser maior do que 100Mb.",
  "Failed to load!": "Falha ao carregar!",

  //******************************2021.05.17 v1.3.0 *******************************/
  "Screenshots downloaded.": "Capturas de tela baixadas.",
  "Online service, no download required": "Editar online, sem necessidade de download",
  "Add up to 20MB file": "Adicionar até 20MB de arquivo",
  "Support common formats": "Suportar formatos comuns",
  "Best for general editing requirements": "Resolução para suas necessidades de edição diária",
  "0 second wait": "0 segundo de espera",
  "No size limits": "Sem limites de tamanho",
  "Support 1000+ formats": "Suportar 1000+ de formatos",
  "Stable processing": "Processamento estável",
  "Get access to HitPaw Toolkit!": "Obtenha acesso ao HitPaw Toolkit!",
  "Support cut, crop & rotate, adjust, speed, add music, stop motion, etc.":
    "Suportar cortar, recortar & girar, ajustar, acelerar, adicionar música, stop motion, etc.",
  "The easiest video editing software for beginners": "O software de edição de vídeo mais fácil para iniciantes",
  "Remove watermark from videos and images in simple clicks": "Remover a marca d'água de vídeos e de imagens em cliques simples",
  "TRY IT FREE": "OBTENHA GRÁTIS",
  "BUY NOW": "Compra Agora",
  "HitPaw Online Video Editor": "HitPaw Online Editor de Vídeo",

  //******************************2021.09.06 v1.5.0********************************/
  "Add Video": "Adicionar Vídeos",
  More: "Mais",
  "History files": "Arquivo do histórico",
  Share: "Compartilhar",
  Duration: "Duração",
  "Download all": "Baixar Tudo",
  "Download selected": "Baixar o(s) Selecionado(s)",
  "Add Audio": "Adicionar Áudio",

  //********************************2021.10.19 v1.6.0*********************************/
  "GIF does not support editing. Do you want to convert the MP4 for editing?":
    "Não suporta edição de GIF,  quer converter o arquivo para MP4 e continuar a editar?",
  "Go to Edit": "Ir para Editar",
  "Download GIF": "Baixar GIF",

  //********************************2021.10.19 v1.6.1*********************************/
  "HitPaw Video Editor": "HitPaw Editor de Vídeo",
  "No video size limits": "Sem limites de tamanho de vídeo.",
  "Cut/crop/rotate/resize/merge video easily": "Cortar/recortar/redimensionar/girar/juntar vídeos facilmente.",
  "Tons of video effects are available for your video creativity": "Muitos recursos de vídeo estão disponíveis para sua criatividade de vídeo.",
  "Unlimited tracks allow you to add multiple audios as you want": "Faixas ilimitadas permitem que você adicione múltiplos áudios como quiser.",

  //********************************2021.11.02 v1.6.1*********************************/
  "Download HitPaw Video Editor for more features.": " Baixe o HitPaw Editor de Vídeo para mais recursos.",
  "Get HitPaw Watermark Remover for more features.": "Obtenha o HitPaw Watermark Remover para mais recursos.",
  "Get HitPaw Toolkit for more features.": "Obtenha o HitPaw Toolkit para mais recursos.",
  "Add 3 videos at most.": "No máximo pode juntar 3 vídeos.",
  "Processing failed.": "O processamento falhou. ",
  "The uploaded video is less than 1 second.": "O vídeo carregado é menos de 1 segundo.",
  "The video format is not supported.": "O formato do vídeo não é suportado. ",
  "The audio format is not supported.": "O formato de áudio não é suportado. ",
  "AddFileByURL.largerSize.HitPawToolkit":
    "Análise feita com sucesso! Seu arquivo tem mais de 100 MB e não pode ser editado pelo HitPaw Online, <1><0>baixe o vídeo</0></1> e use o HitPaw Toolkit para prosseguir.",
  "AddFileByURL.largerSize.HitPawWatermarkRemover":
    "Análise feita com sucesso! Seu arquivo tem mais de 100 MB e não pode ser editado pelo HitPaw Online, <1><0>baixe o vídeo</0></1> e use o HitPaw Watermark Remover para prosseguir.",
  "AddFileByURL.largerSize.HitPawVideoEditor":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Video Editor to proceed.",
};
