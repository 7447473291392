import { request } from "./request";
import { api_config } from "@/constants/api";

let apiUrlV2 = api_config.API_V2_URL;
// let apiUrl = api_config.API_URL;

/**
 * 请求游客文件列表
 * @param {object} 请求参数
 */
const requsetGetAnonymousFileList = () => {
  const options = {
    method: "GET",
  };
  return new Promise((resolve, reject) => {
    request(apiUrlV2 + "history-files", options)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

/**
 * 请求游客文件列表
 * @param {object} 请求参数
 */
const requsetDeleteAnonymousFileList = params => {
  const options = {
    ...params,
    method: "DELETE",
  };
  return new Promise((resolve, reject) => {
    request(apiUrlV2 + "history-files", options)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export { requsetGetAnonymousFileList, requsetDeleteAnonymousFileList };
