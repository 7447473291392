import React, { Component } from "react";
import { SpeedWorkRender } from "./children/SpeedWorkRender";

export default class SpeedWorkFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <SpeedWorkRender
        preSubmit={this.props.preSubmit}
        handleSpeedChange={this.props.handleSpeedChange}
        currFile={this.props.currFile}
        playbackRate={this.props.playbackRate}
        submitting={this.props.submitting}
        fileListModal={this.props.fileListModal}
      />
    );
  }
}
