export default {
  'Files': 'ファイル',
  'Refresh': '更新',
  'Original file': '元のファイル',
  'Just now': '現在',
  'hour ago': '{{num}}時間前',
  'hours ago': '{{num}}時間前',
  'minute ago': '{{num}}分前',
  'minutes ago': '{{num}}分前',
  'day ago': '{{num}} 日前',
  'View All': 'すべてを見る',
  'No files': 'ファイルがありません…',
  "Don't forget to download your file, it will be deleted after 24 hours": 'ファイルのダウンロードをお忘れなく。24時間後にはファイルは廃棄されてしまいます。',
  'Select All': 'すべてを選択',
  'Delete': '消去',
  'Generate Files Successfully!': 'ファイルの作成に成功しました！',
  'Continue Editing': '編集を続ける',
  'Edit New Video': '新しいビデオを編集する',
  'Download': 'ダウンロード',
  'Click here to open the Files again':'再びファイルを開くにはここをクリックしてください。',
  'Processing... Please wait': '処理中です…しばらくお待ちください',
  'Uploading... Please wait': 'アップロード…しばらくお待ちください',
  'Edit Now': '編集',
  'Back to Edit': "編集に戻る",
  'Go Edit':'編集する'
}