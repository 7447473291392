export default {
  Back: "Retour",
  Cut: "Couper",
  Speed: "Vitesse",
  "Crop & Rotate": "Réduire et faire tourner",
  Resize: "Redimensionner",
  Extract: "Extraire",
  "Add Music": "Ajouter de la musique",
  Subtitles: "Sous-titres",
  Merge: "Fusionner",
  "Watermark Remover": "Suppression du filigrane",
  "Add Watermark": "Ajouter un filigrane",
  "To GIF": "Vers le GIF",
  Convert: "Convertir",
  Screenshot: "Capture d'écran",
  Reverse: "Inverse",
  Loop: "Boucle",
};
