export default {
  Back: "返回",
  Cut: "截取",
  Speed: "变速",
  "Crop & Rotate": "裁剪&旋转",
  Resize: "改变大小",
  Extract: "提取音视频",
  "Add Music": "加音乐",
  Subtitles: "加字幕",
  Merge: "合并",
  "Watermark Remover": "去水印",
  "Add Watermark": "加水印",
  "To GIF": "转GIF",
  Convert: "转格式",
  Screenshot: "截图",
  Reverse: "倒放",
  Loop: "循环",
};
