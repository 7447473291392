import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "@/action";
import SpeedPreviewFunction from "./children/SpeedPreviewFunction";
import { useDrop } from "react-dnd";
import { ReactComponent as Close } from "@/assets/icons/close-circle.svg";

const VideoWrap = ({ currFile, onCurrFileChange }) => {
  const [, drop] = useDrop({
    accept: "FileListItem",
    drop: () => ({ name: "SpeedVideoWrap" }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: item => {
      if ((item.file && item.file.audiofileurl) || (item.file && item.file.status === "gif")) {
        return false;
      }
      return true;
    },
  });
  return (
    <div ref={drop} className="videoWrap" id="videoRef">
      <div style={{ width: "fit-content", height: "fit-content", margin: "auto", position: "relative" }}>
        <button className="close" onClick={() => onCurrFileChange(null)}>
          <Close />
        </button>
        <video className="active" crossOrigin="Anonymous">
          <source src={currFile.videofileurl} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

class SpeedPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const myvideo = document.querySelector("#videoRef video");
    myvideo.playbackRate = this.props.playbackRate;
  }

  render() {
    return (
      <div className="SpeedPreview">
        <VideoWrap currFile={this.props.currFile} onCurrFileChange={this.props.onCurrFileChange} />
        <SpeedPreviewFunction currFile={this.props.currFile} playbackRate={this.props.playbackRate} submitting={this.props.submitting} />
        {/* <button
          className="close"
          onClick={() => {
            this.props.onCurrFileChange(null);
          }}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g stroke="#A1A1A2" fill="none" fillRule="evenodd">
              <circle cx="12" cy="12" r="11.5" />
              <path d="M7.76 7.757l8.486 8.486m0-8.486L7.76 16.243" strokeLinecap="round" />
            </g>
          </svg>
        </button> */}
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  work: state.work.work,
  playbackRate: state.work.playbackRate,
  submitting: state.work.submitting,
});

const mapDispatchToProps = dispatch => ({
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(SpeedPreview);
