import React, { Component } from "react";
import { connect } from "react-redux";
import { message } from "antd";
import ReactGA from "react-ga";
import NarbarFunction from "./children/NarbarFunction";
import TipsModalSimple from "../common/TipsModalSimple";
import actions from "@/action";
import Utils from "@/utils/utils";
import moment from "moment";
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editGIFModalVisible: false,
      editGIFWork: null,
    };
  }

  handleClick = work => {
    //判断视频正在处理或者正在上传时，不能点击跳转具体工作区
    if (
      this.props.currFile
        ? this.props.submitting && (this.props.uploadPercent > 0 || this.props.processPercent > 0)
        : !this.props.fileToBlobStatus && (this.props.uploadPercent > 0 || this.props.processPercent > 0)
    ) {
      return false;
    }
    if (work) {
      ReactGA.event(
        {
          category: "FuncRate",
          action: "Click" + work,
          label: this.props.work + "to" + work,
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.userAction) {
      message.warning(window.i18n.t("common:Please click the Save button to ensure the previous editing to take effect."), 2.5);
      this.props.onUserActionChange(false);
    }
    if (this.props.prevWork && this.props.prevWork !== work && this.props.selectedFile.guid) {
      if (this.props.prevWork === "loop" && this.props.selectedFile.fileType === "gif") {
        this.setState({
          editGIFModalVisible: true,
          editGIFWork: work,
        });
        return false;
      }
      this.props.onCurrFileChange(this.props.selectedFile);
    } else if (this.props.prevWork === work) {
      this.sentGAFromBackToEdit(work);
      if (this.props.prevWork === "loop" && this.props.selectedFile.fileType === "gif") {
        this.handleClearGIFFileFromFiles();
      }
    }
    // this.props.onWorkChange(work);
    // this.props.onSelectedFileChange({});
    this.props.onSuccessFileGuidsChange([]);
    this.props.onPrevWorkChange("");
    this.props.onWorkChange(work);
    localStorage.setItem("currWork", work);
  };

  sentGAFromBackToEdit = work => {
    let category, action;
    switch (work) {
      case "cut":
        category = "Cut";
        action = "CutBackEdit";
        break;
      case "speed":
        category = "Speed";
        action = "SpeedBackEdit";
        break;
      case "crop_rotate":
        category = "Crop&Rotate";
        action = "Crop&RotateBackEdit";
        break;
      case "resize":
        category = "Resize";
        action = "ResizeBackEdit";
        break;
      case "merge":
        category = "Merge";
        action = "MergBackEdit";
        break;
      case "subtitle":
        category = "AddText";
        action = "TextBackEdit";
        break;
      case "removeWatermark":
        category = "RemoveMark";
        action = "RemoveBackEdit";
        break;
      case "addWatermark":
        category = "AddMark";
        action = "AddMarkBackEdit";
        break;
      case "addMusic":
        category = "AddMusic";
        action = "MusicBackEdit";
        break;
      default:
        break;
    }
    ReactGA.event(
      {
        category: category,
        action: action,
      },
      ["OnlineTracker", "Tracker"]
    );
  };

  /**
   * 当操作的work为loop时，并且生成的是GIF，则清除当前GIF
   */

  handleClearGIFFileFromFiles = () => {
    let files = [...this.props.files];
    files = files.filter(item => item.guid !== this.props.selectedFile.guid);
    this.props.onFilesChange(files);
    localStorage.setItem("files", JSON.stringify(files));
  };

  render() {
    return (
      <>
        <NarbarFunction
          handleClick={this.handleClick}
          work={this.props.work}
          fileToBlobStatus={this.props.fileToBlobStatus}
          uploading={this.props.uploading}
          successFileGuids={this.props.successFileGuids}
          currFile={this.props.currFile}
          submitting={this.props.submitting}
          uploadPercent={this.props.uploadPercent}
          processPercent={this.props.processPercent}
          theme={this.props.theme}
        />
        <TipsModalSimple
          visible={this.state.editGIFModalVisible}
          onCancel={() => {
            this.setState({ editGIFModalVisible: false });
          }}
          content="GIF does not support editing. Do you want to convert the MP4 for editing?"
          customBtns={[
            {
              text: "Go to Edit",
              style: {
                minWidth: "120px",
                marginTop: "20px",
                marginRight: "20px",
                fontSize: "14px",
              },
              clickFunc: () => {
                this.setState({
                  editGIFModalVisible: false,
                });
                const file = { ...this.props.currFile };
                let files = [...this.props.files];
                file.guid = new Date().getTime();
                file.name = moment(new Date()).format("YYYYMMDDHHmmSS") + ".mp4";
                files.unshift(file);
                this.props.onCurrFileChange(file);
                this.props.onFilesChange(files);
                this.props.onSuccessFileGuidsChange([]);
                this.props.onPrevWorkChange("");
                this.props.onWorkChange(this.state.editGIFWork);
                localStorage.setItem("currWork", this.state.editGIFWork);
                localStorage.setItem("files", JSON.stringify(files));
              },
            },
            {
              text: "Download GIF",
              style: {
                minWidth: "120px",
                marginTop: "20px",
                fontSize: "14px",
              },
              ghost: true,
              clickFunc: () => {
                this.setState({
                  editGIFModalVisible: false,
                });
                Utils.downloadFile(this.props.selectedFile, ".gif");
              },
            },
          ]}
        />
      </>
    );
  }
}

const mapStateToPropos = state => ({
  work: state.work.work,
  prevWork: state.work.prevWork,
  uploading: state.work.uploading,
  files: state.files.files,
  selectedFile: state.files.selectedFile,
  currFile: state.files.currFile,
  userAction: state.common.userAction,
  fileToBlobStatus: state.work.fileToBlobStatus,
  successFileGuids: state.files.successFileGuids,
  uploadPercent: state.work.uploadPercent,
  submitting: state.work.submitting,
  processPercent: state.work.processPercent,
  theme: state.common.theme,
});

const mapDispatchToProps = dispatch => ({
  onWorkChange: work => dispatch(actions.onWorkChange(work)),
  onFilesChange: files => dispatch(actions.onFilesChange(files)),
  onUserActionChange: userAction => dispatch(actions.onUserActionChange(userAction)),
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
  onPrevWorkChange: prevWork => dispatch(actions.onPrevWorkChange(prevWork)),
  onSuccessFileGuidsChange: successFileGuids => dispatch(actions.onSuccessFileGuidsChange(successFileGuids)),
  onSelectedFileChange: selectedFile => dispatch(actions.onSelectedFileChange(selectedFile)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(Navbar);
