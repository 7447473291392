export default {
  'Files': 'الملفات',
  'Refresh': 'تحديث',
  'Original file': 'الملف الأصلي',
  'Just now': 'في هذة اللحظة',
  'hour ago': 'قبل {{num}} ساعة ',
  'hours ago': 'قبل {{num}} ساعةساعات',
  'minute ago': 'قبل {{num}} دقيقة',
  'minutes ago': 'قبل {{num}} دقيقةدقائق',
  'day ago': '{{num}} منذ يوم',
  'View All': 'مشاهدة الكل',
  'No files': 'لا ملفات...',
  "Don't forget to download your file, it will be deleted after 24 hours": 'لا تنس تنزيل ملفك ، فسيتم التخلي عنه بعد 24 ساعة.',
  'Select All': 'اختر الكل',
  'Delete': 'حذف',
  'Generate Files Successfully!': 'إنشاء الملفات بنجاح!',
  'Continue Editing': 'الاستمرار في التعديل',
  'Edit New Video': 'تحرير فيديو جديد',
  'Download': 'تحميل',
  'Click here to open the Files again':'انقر هنا لفتح الملفات مرة أخرى.',
  'Processing... Please wait': 'جارى المعالجة ... انتظر من فضلك',
  'Uploading... Please wait': 'جاري التحميل .. الرجاء الانتظار',
  'Edit Now':'تحرير الآن',
  'Back to Edit':'عودة للتعديل',
  'Go Edit':'ابدأ في '
}