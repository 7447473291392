export default {
  'Files': 'Bestanden',
  'Refresh': 'Vernieuwen',
  'Original file': 'Origineel bestand',
  'Just now': 'Nu net',
  'hour ago': '{{num}} uur geleden',
  'hours ago': '{{num}} uuren geleden',
  'minute ago': '{{num}} minuut geleden',
  'minutes ago': '{{num}} minuuten geleden',
  'day ago': '{{num}} dag geleden',
  'View All': 'Bekijk alles',
  'No files': 'Geen bestanden...',
  "Don't forget to download your file, it will be deleted after 24 hours": 'Vergeet niet je bestand te downloaden, het wordt na 24 uur verwijderd.',
  'Select All': 'Alles selecteren',
  'Delete': 'Verwijder',
  'Generate Files Successfully!': 'Bestanden met succes genereren!',
  'Continue Editing': 'Doorgaan met bewerken',
  'Edit New Video': 'Nieuwe video bewerken',
  'Download': 'Downloaden',
  'Click here to open the Files again':'Klik hier om de Bestanden opnieuw te openen.',
  'Processing... Please wait': 'Verwerken... Even geduld a.u.b.',
  'Uploading... Please wait': 'Uploaden... Even geduld a.u.b.',
  'Edit Now': 'Nu bewerken',
  'Back to Edit':'Terug naar Bewerken',
  'Go Edit':'Ga naar bewerk'

}