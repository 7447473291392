import React, { Component } from "react";
import { connect } from "react-redux";
import { workplaceHOC } from "../WorkplaceHOC";
import RemoveWatermarkWorkFunction from "./children/RemoveWatermarkWorkFunction";
import actions from "@/action";
import Utils from "@/utils/utils";
import ReactGA from "react-ga";
import TipsModal from "@/components/common/TipsModal";
import "@/assets/removeWatermarkWork.scss";
import { request } from "@/api/request";

class RemoveWatermarkWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warningInfo: false,
      warningInfoText: "",
    };
  }

  handleStartChange = e => {
    const value = e.target.value;
    const removeWatermarkList = [...this.props.removeWatermarkList];
    const removeWatermark = removeWatermarkList.filter(item => item.selected)[0];
    removeWatermark.start = value;
    this.props.onRemoveWatermarkListChange(removeWatermarkList);
    this.props.onUserActionChange(true);
  };

  handleEndChange = e => {
    const value = e.target.value;
    const removeWatermarkList = [...this.props.removeWatermarkList];
    const removeWatermark = removeWatermarkList.filter(item => item.selected)[0];
    removeWatermark.end = value;
    this.props.onRemoveWatermarkListChange(removeWatermarkList);
    this.props.onUserActionChange(true);
  };

  handleSubmit = async () => {
    const action = "removeWatermark";
    const startTime = { ...this.props.startTime };
    startTime.removeWatermark = new Date();
    this.props.onStartTimeChange(startTime);
    if (!this.props.uploading && this.props.uploadPercent < 100) {
      this.props.onProcessPercentChange(1);
      this.props.onProcessStepChange(1);
    }
    let videoResponse;
    try {
      videoResponse = await this.getRemoveWatermarkVideo(this.props.removeWatermarkList, action);
    } catch (error) {
      this.props.handleSubmitFailModal(true);
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "RemoveWatermark",
          action: "RemoveWatermarkFail",
          label: error + "_none_none_" + this.props.tokenId + "_none_none",
        },
        ["OnlineTracker", "Tracker"]
      );
      return false;
    }
    if (videoResponse.code === 200) {
      let guids = [];
      try {
        guids = await this.props.getNewVideoByProgress("removeWatermark", videoResponse);
      } catch (error) {
        if (error !== "cancel") {
          ReactGA.event(
            {
              category: "RemoveWatermark",
              action: "RemoveWatermarkFail",
              label: action + "_200_200_" + error,
            },
            ["OnlineTracker", "Tracker"]
          );
        }
        return false;
      }
      // const modal = {
      //   getContainer: document.querySelector(".Home"),
      //   show: true,
      //   type: "removeWatermarkSuccess",
      //   guid: guids[0],
      //   title: "Generate Files Successfully!",
      //   buttons: [
      //     {
      //       type: "download",
      //       text: "Download",
      //     },
      //     {
      //       type: "editNew",
      //       text: "Edit New Video",
      //     },
      //   ],
      // };
      // this.props.onFileListModalChange(modal);
      this.props.onSuccessFileGuidsChange(guids);
      this.props.onPrevWorkChange("removeWatermark");
      // this.props.onWorkChange(null);
      this.props.onProcessPercentChange(0);
      this.props.onUploadPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "RemoveWatermark",
          action: "RemoveWatermarkSuccess",
        },
        ["OnlineTracker", "Tracker"]
      );
    } else {
      if (videoResponse.code === 402102) {
        this.props.onPurchaseModalObjChange({
          visible: true,
          type: "videoEditorTimesMoreThanLimit",
        });
      } else {
        this.props.handleSubmitFailModal(true);
      }
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "RemoveWatermark",
          action: "RemoveWatermarkFail",
          label: action + "_200_" + videoResponse.code + "_none_" + this.props.tokenId + "_none_" + videoResponse.msg,
        },
        ["OnlineTracker", "Tracker"]
      );
    }
  };

  getRemoveWatermarkVideo = (arr, action) => {
    const formData = new FormData();
    formData.append("video_url", this.props.currFile.videofileurl);
    formData.append("token", this.props.tokenId);
    const video = document.querySelector(".videoWrap video");
    arr.forEach(item => {
      const width = ((item.coordinate.width / video.clientWidth) * 100).toFixed(2);
      const height = ((item.coordinate.height / video.clientHeight) * 100).toFixed(2);
      let x, y;
      x = (((item.coordinate.x - (video.parentNode.clientWidth / 2 - video.clientWidth / 2)) / video.clientWidth) * 100).toFixed(2);
      y = (((item.coordinate.y - (video.parentNode.clientHeight / 2 - video.clientHeight / 2)) / video.clientHeight) * 100).toFixed(2);
      formData.append(
        "locations[]",
        `{"x":${x},"y":${y},"w":${width},"h":${height},"show":"0","enable":"${Utils.getSeconds(item.start)},${Utils.getSeconds(item.end)}"}`
      );
    });
    return new Promise((resolve, reject) => {
      request(action, {
        method: "post",
        body: formData,
      })
        .then(responseJSON => {
          resolve(responseJSON);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  preSubmit = () => {
    ReactGA.event(
      {
        category: "RemoveWatermark",
        action: "RemoveWatermarkSave",
        label: this.props.removeWatermarkList.length,
      },
      ["OnlineTracker", "Tracker"]
    );
    console.log("pre", this.props.processStep);
    //函数返回true，则是当前已经超过限制
    let flag = this.props.handleVideoEditorTimes();
    if (!flag) {
      this.props.preSubmit(1, this.handleSubmit);
    }
  };

  handleSwitchSelect = (e, index) => {
    e.stopPropagation();
    const removeWatermarkList = [...this.props.removeWatermarkList];
    removeWatermarkList.forEach(item => {
      item.selected = false;
    });
    if (index !== -1) {
      removeWatermarkList[index].selected = true;
    }
    this.props.onRemoveWatermarkListChange(removeWatermarkList);
  };

  removeWork = (e, index) => {
    e.stopPropagation();
    const removeWatermarkList = [...this.props.removeWatermarkList];
    if (removeWatermarkList[index].selected) {
      if (index === 0 && removeWatermarkList.length > 1) {
        removeWatermarkList[index + 1].selected = true;
      } else if (index !== 0) {
        removeWatermarkList[index - 1].selected = true;
      }
    }
    removeWatermarkList.splice(index, 1);
    this.props.onRemoveWatermarkListChange(removeWatermarkList);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.removeWatermarkList !== this.props.removeWatermarkList) {
      this.props.onUserActionChange(true);
    }
  }
  render() {
    return (
      <div style={{ height: "100%" }}>
        <RemoveWatermarkWorkFunction
          currFile={this.props.currFile}
          removeWatermarkList={this.props.removeWatermarkList}
          removeWork={this.removeWork}
          handleStartChange={this.handleStartChange}
          handleEndChange={this.handleEndChange}
          handleSwitchSelect={this.handleSwitchSelect}
          preSubmit={this.preSubmit}
        />
        <TipsModal
          showGuidText
          visible={this.state.warningInfo}
          content={this.state.warningInfoText}
          onCancel={() => {
            this.setState({ warningInfo: false });
          }}
          cancelBtnHandle={() => {
            this.setState({ warningInfo: false });
          }}
          hasDownload
        />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  work: state.work.work,
  files: state.files.files,
  currFile: state.files.currFile,
  fileListModal: state.common.fileListModal,
  tokenId: state.common.tokenId,
  startTime: state.work.startTime,
  removeWatermarkList: state.work.removeWatermarkList,
  uploading: state.work.uploading,
  processStep: state.work.processStep,
  processPercent: state.work.processPercent,
  cancelProgress: state.work.cancelProgress,
  submitting: state.work.submitting,
  uploadPercent: state.work.uploadPercent,
});
const mapDispatchToProps = dispatch => ({
  onFilesChange: files => dispatch(actions.onFilesChange(files)),
  onFileListModalChange: fileListModal => dispatch(actions.onFileListModalChange(fileListModal)),
  onProcessPercentChange: processPercent => dispatch(actions.onProcessPercentChange(processPercent)),
  onProcessStepChange: processStep => dispatch(actions.onProcessStepChange(processStep)),
  onStartTimeChange: startTime => dispatch(actions.onStartTimeChange(startTime)),
  onUserActionChange: userAction => dispatch(actions.onUserActionChange(userAction)),
  onRemoveWatermarkListChange: removeWatermarkList => dispatch(actions.onRemoveWatermarkListChange(removeWatermarkList)),
  onSubmittingChange: submitting => dispatch(actions.onSubmittingChange(submitting)),
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
  onPreUploadFileChange: preUploadFile => dispatch(actions.onPreUploadFileChange(preUploadFile)),
  onUploadAgainFileChange: uploadAgainFile => dispatch(actions.onUploadAgainFileChange(uploadAgainFile)),
  onUploadPercentChange: uploadPercent => dispatch(actions.onUploadPercentChange(uploadPercent)),
  onWorkChange: work => dispatch(actions.onWorkChange(work)),
  onPrevWorkChange: prevWork => dispatch(actions.onPrevWorkChange(prevWork)),
  onSuccessFileGuidsChange: successFileGuids => dispatch(actions.onSuccessFileGuidsChange(successFileGuids)),
  onPurchaseModalObjChange: purchaseModal => dispatch(actions.onPurchaseModalObjChange(purchaseModal)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(workplaceHOC(RemoveWatermarkWork));
