import React, { Component } from "react";
import { MergeWorkRender } from "./children/MergeWorkRender";

export default class MergeWorkFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: "#000000",
      colorList: ["#000000", "#d7d7d7", "#ffffff", "#fdf668", "#1E88E5"],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.videoBgColor !== this.props.videoBgColor && !/.*blur.*/.test(this.props.videoBgColor)) {
      this.setState({
        color: this.props.videoBgColor,
      });
    }
  }

  render() {
    return (
      <MergeWorkRender
        mergeWorkList={this.props.mergeWorkList}
        currFile={this.props.currFile}
        videoBgColor={this.props.videoBgColor}
        color={this.state.color}
        resolution={this.props.resolution}
        draggerProps={this.props.draggerProps}
        recommendFile={this.props.recommendFile}
        recommendFileConfirm={this.props.recommendFileConfirm}
        handleQuickSetColor={this.props.handleQuickSetColor}
        handleResolutionChange={this.props.handleResolutionChange}
        handleSetColor={this.props.handleSetColor}
        handleMergeItemClick={this.props.handleMergeItemClick}
        removeMergeWork={this.props.removeMergeWork}
        orderMergeList={this.props.orderMergeList}
        setCurrFile={this.props.setCurrFile}
        preSubmit={this.props.preSubmit}
        colorList={this.state.colorList}
      />
    );
  }
}
