import Types from "../types";

export function onFilesChange(files) {
  return { type: Types.FILES_CHANGE, files: files };
}

export function onPreUploadFileChange(preUploadFile) {
  return { type: Types.PREUPLOADFILE_CHANGE, preUploadFile: preUploadFile };
}

export function onCurrFileChange(currFile) {
  return { type: Types.CURRFILE_CHANGE, currFile: currFile };
}

export function onUploadAgainFileChange(uploadAgainFile) {
  return { type: Types.UPLOADAGAINFILE_CHANGE, uploadAgainFile: uploadAgainFile };
}

export function onSuccessFileGuidsChange(successFileGuids) {
  return { type: Types.SUCCESSFILEGUIDS_CHANGE, successFileGuids: successFileGuids };
}

export function onSelectedFileChange(selectedFile) {
  return { type: Types.SELECTEDFILE_CHANGE, selectedFile: selectedFile };
}
