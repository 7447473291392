import React from "react";
import { Progress } from "antd";
import { useTranslation } from "react-i18next";
import { ReactComponent as Close } from "@/assets/icons/close-circle.svg";

export const ProcessModalRender = ({ currFile, cancleProcess, uploadPercent, processPercent, processStep }) => {
  const { t } = useTranslation(["fileList", "common"]);
  const proportion = currFile && currFile.wh ? currFile.wh.split("x")[0] / currFile.wh.split("x")[1] : 0;
  return (
    <div
      className={`uploading normalCont ${
        proportion && proportion >= 1 ? "horizontal" : currFile && !currFile.thumbnail.startsWith("blob:") ? "vertical" : ""
      } ${!currFile ? "upload" : ""}`}
    >
      {currFile && currFile.wh && !currFile.thumbnail.startsWith("blob:") ? (
        <>
          <img src={currFile.thumbnail} alt="thumnail" />
          <div className="mask"></div>
        </>
      ) : null}
      <div className="progress">
        <Progress strokeColor="#8372FF" percent={parseInt((uploadPercent + processPercent) / processStep)} showInfo={false} status="active" />
        <div className="close" onClick={cancleProcess}>
          <div className="precent-num">{parseInt((uploadPercent + processPercent) / processStep)}%</div>
          {uploadPercent < 100 && uploadPercent > 0 ? (
            <div className="percent-title">{t("Uploading... Please wait")}</div>
          ) : (
            <div className="percent-title">{t("Processing... Please wait")}</div>
          )}
          <Close />
        </div>
      </div>
    </div>
  );
};
