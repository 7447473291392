import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "@/action";
import Preview from "./children/preview/Preview";
import Upload from "./children/upload/Upload";
import "@/assets/previewContent.scss";

class PreviewContent extends Component {
  // constructor(props) {
  //   super(props)
  // }
  setfileListModal = () => {
    const modal = {
      getContainer: document.querySelector(".Home"),
      desc: "Don't forget to download your file, it will be deleted after 24 hours",
      show: true,
      type: "files",
      title: "Files",
      buttons: [
        {
          type: "delete",
          text: "Delete",
        },
        {
          type: "download",
          text: "Download",
        },
      ],
    };
    this.props.onFileListModalChange(modal);
  };

  componentDidUpdate(prevProps) {
    //该监听用于判断：当视频文件在后台上传时，用户点击关闭视频预览按钮，给当前视频对象添加isUploadSuccessNotShow属性，用于当视频加载完成后不载入当前视频为currFile
    if (prevProps.currFile !== this.props.currFile && !this.props.currFile && prevProps.currFile && !prevProps.currFile.uploadStatus) {
      const files = [...this.props.files];
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        if (file.guid === prevProps.currFile.guid) {
          file.isUploadSuccessNotShow = true;
        }
      }
      this.props.onFilesChange(files);
    }
  }

  render() {
    return (
      <div className="PreviewContent">
        <div className="PreviewContent-container">
          {(this.props.work === "reverse" && this.props.currFile && this.props.currFile.reverseVideourl) ||
          (this.props.work !== "reverse" && this.props.currFile) ? (
            <div className="PreviewContent-content">
              <Preview />
            </div>
          ) : null}
          <Upload visible={this.props.currFile ? false : true} />
        </div>
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  work: state.work.work,
  uploading: state.work.uploading,
  mergeWorkList: state.work.mergeWorkList,
});
const mapDispatchToProps = dispatch => ({
  onFileListModalChange: fileListModal => dispatch(actions.onFileListModalChange(fileListModal)),
  onFilesChange: files => dispatch(actions.onFilesChange(files)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(PreviewContent);
