import React, { Component } from "react";
import { CropRotateWorkRender } from "./children/CropRotateWorkRender";
import ReactGA from "react-ga";
export default class CropRotateWorkFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleWidthChange = e => {
    let value = e.target.value;
    if (!/^[1-9]\d*$|^$/.test(value)) {
      return false;
    }
    const crop = { ...this.props.crop };
    if (value) {
      value = parseInt(value);
      const video = document.querySelector(".videoWrap video");
      if (this.props.rotate === 90 || this.props.rotate === -90 || this.props.rotate === -270 || this.props.rotate === 270) {
        crop.width = (value / video.videoHeight) * video.clientHeight;
        if (value >= video.videoHeight) {
          value = video.videoHeight;
          crop.width = video.clientHeight;
        }
        if ((value / video.videoHeight) * video.clientHeight + crop.x >= video.clientHeight) {
          crop.x = video.parentNode.clientWidth / 2 - video.clientHeight / 2;
        }
      } else {
        crop.width = (value / video.videoWidth) * video.clientWidth;
        if (value >= video.videoWidth) {
          value = video.videoWidth;
          crop.width = video.clientWidth;
        }
        if ((value / video.videoWidth) * video.clientWidth + crop.x >= video.clientWidth) {
          crop.x = video.parentNode.clientWidth / 2 - video.clientWidth / 2;
        }
      }
    }
    crop.actualWidth = value;
    this.props.onCropChange(crop);
  };

  handleHeightChange = e => {
    let value = e.target.value;
    if (!/^[1-9]\d*$|^$/.test(value)) {
      return false;
    }
    const crop = { ...this.props.crop };
    if (value) {
      value = parseInt(value);
      const video = document.querySelector(".videoWrap video");
      if (this.props.rotate === 90 || this.props.rotate === -90 || this.props.rotate === -270 || this.props.rotate === 270) {
        crop.height = (value / video.videoWidth) * video.clientWidth;
        if (value >= video.videoWidth) {
          value = video.videoWidth;
          crop.height = video.clientWidth;
        }
        if ((value / video.videoWidth) * video.clientWidth + crop.y >= video.clientWidth) {
          crop.y = video.parentNode.clientHeight / 2 - video.clientWidth / 2;
        }
      } else {
        crop.height = (value / video.videoHeight) * video.clientHeight;
        if (value >= video.videoHeight) {
          value = video.videoHeight;
          crop.height = video.clientHeight;
        }
        if ((value / video.videoHeight) * video.clientHeight + crop.y >= video.clientHeight) {
          crop.y = video.parentNode.clientHeight / 2 - video.clientHeight / 2;
        }
      }
    }
    crop.actualHeight = value;
    this.props.onCropChange(crop);
  };

  handleWidthBlur = () => {
    ReactGA.event(
      {
        category: "Crop&Rotate",
        action: "Crop&RotateCustom",
      },
      ["OnlineTracker", "Tracker"]
    );
    const crop = { ...this.props.crop };
    if (crop.actualWidth === "" || crop.actualWidth < 100) {
      const video = document.querySelector(".videoWrap video");
      crop.actualWidth = 100;
      if (this.props.rotate === 90 || this.props.rotate === -90 || this.props.rotate === -270 || this.props.rotate === 270) {
        crop.width = (100 / video.videoHeight) * video.clientHeight;
      } else {
        crop.width = (100 / video.videoWidth) * video.clientWidth;
      }
    }
    this.props.onCropChange(crop);
  };

  handleHeightBlur = () => {
    ReactGA.event(
      {
        category: "Crop&Rotate",
        action: "Crop&RotateCustom",
      },
      ["OnlineTracker", "Tracker"]
    );
    const crop = { ...this.props.crop };
    if (crop.actualHeight === "" || crop.actualHeight < 100) {
      const video = document.querySelector(".videoWrap video");
      crop.actualHeight = 100;
      if (this.props.rotate === 90 || this.props.rotate === -90 || this.props.rotate === -270 || this.props.rotate === 270) {
        crop.height = (100 / video.videoWidth) * video.clientWidth;
      } else {
        crop.height = (100 / video.videoHeight) * video.clientHeight;
      }
    }
    this.props.onCropChange(crop);
  };

  render() {
    return (
      <CropRotateWorkRender
        currFile={this.props.currFile}
        resolution={this.props.resolution}
        width={this.props.crop.actualWidth}
        height={this.props.crop.actualHeight}
        onResolutionChange={this.props.onResolutionChange}
        handleRotateChange={this.props.handleRotateChange}
        handleFlipChange={this.props.handleFlipChange}
        preSubmit={this.props.preSubmit}
        handleWidthChange={this.handleWidthChange}
        handleHeightChange={this.handleHeightChange}
        handleHeightFocus={this.handleHeightFocus}
        handleWidthFocus={this.handleWidthFocus}
        handleWidthBlur={this.handleWidthBlur}
        handleHeightBlur={this.handleHeightBlur}
      />
    );
  }
}
