import React, { Component } from "react";
import { connect } from "react-redux";
import { workplaceHOC } from "../WorkplaceHOC";
import { request } from "@/api/request";
import actions from "@/action";
import ReactGA from "react-ga";
import SpeedWorkFunction from "./children/SpeedWorkFunction";
import "@/assets/speedWork.scss";

class SpeedWork extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = async () => {
    const action = "changeSpeed";
    const startTime = { ...this.props.startTime };
    startTime.speed = new Date();
    this.props.onStartTimeChange(startTime);
    if (!this.props.uploading && this.props.uploadPercent < 100) {
      this.props.onProcessPercentChange(1);
      this.props.onProcessStepChange(1);
    }
    let speedResponse;
    try {
      speedResponse = await this.getSpeedVideo(action);
    } catch (error) {
      this.props.handleSubmitFailModal(true);
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "Speed",
          action: "SpeedFail",
          label: error + "_none_none_" + this.props.tokenId + "_none_none",
        },
        ["OnlineTracker", "Tracker"]
      );
      return false;
    }
    if (speedResponse.code === 200) {
      let guids = [];
      try {
        guids = await this.props.getNewVideoByProgress("speed", speedResponse);
      } catch (error) {
        if (error !== "cancel") {
          ReactGA.event(
            {
              category: "Speed",
              action: "SpeedFail",
              label: action + "_200_200_" + error,
            },
            ["OnlineTracker", "Tracker"]
          );
        }
        return false;
      }
      // const modal = {
      //   getContainer: document.querySelector(".Home"),
      //   show: true,
      //   type: "speedSuccess",
      //   guid: guids[0],
      //   title: "Generate Files Successfully!",
      //   buttons: [
      //     {
      //       type: "download",
      //       text: "Download",
      //     },
      //     {
      //       type: "editNew",
      //       text: "Edit New Video",
      //     },
      //   ],
      // };
      // this.props.onFileListModalChange(modal);
      this.props.onSuccessFileGuidsChange(guids);
      this.props.onPrevWorkChange("speed");
      // this.props.onWorkChange(null);
      this.props.onUploadPercentChange(0);
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      // ========封面图生成失败埋点===========
      // Utils.isImageLoadedSuccess(
      //   this.props.files.filter(item => guids.indexOf(item.guid) !== -1),
      //   "thumbnail",
      //   label => {
      //     ReactGA.event({
      //       category: "Speed",
      //       action: "SpeedSuccess",
      //       label,
      //     }, );
      //   }
      // );
      //=====================================
      ReactGA.event(
        {
          category: "Speed",
          action: "SpeedSuccess",
        },
        ["OnlineTracker", "Tracker"]
      );
    } else {
      if (speedResponse.code === 402102) {
        this.props.onPurchaseModalObjChange({
          visible: true,
          type: "videoEditorTimesMoreThanLimit",
        });
      } else {
        this.props.handleSubmitFailModal(true);
      }
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "Speed",
          action: "SpeedFail",
          label: action + "_200_" + speedResponse.code + "_none_" + this.props.tokenId + "_none_" + speedResponse.msg,
        },
        ["OnlineTracker", "Tracker"]
      );
    }
  };

  preSubmit = () => {
    ReactGA.event(
      {
        category: "Speed",
        action: "SpeedSave",
        label: this.props.playbackRate,
      },
      ["OnlineTracker", "Tracker"]
    );
    //函数返回true，则是当前已经超过限制
    let flag = this.props.handleVideoEditorTimes();
    if (!flag) {
      this.props.preSubmit(1, this.handleSubmit);
    }
  };

  getSpeedVideo = action => {
    const formData = new FormData();
    formData.append("speed", this.props.playbackRate);
    formData.append("mute_type", 0);
    formData.append("file_url", this.props.currFile.videofileurl);
    formData.append("token", this.props.tokenId);
    return new Promise((resolve, reject) => {
      request(action, {
        method: "post",
        body: formData,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  handleSpeedChange = value => {
    this.props.onPlaybackRateChange(value);
  };

  componentDidMount() {
    this.props.onPlaybackRateChange(1);
  }

  componentDidUpdate(prevProps) {
    if (this.props.currFile !== prevProps.currFile) {
      this.props.onPlaybackRateChange(1);
    }
  }

  render() {
    return (
      <div style={{ height: "100%" }}>
        <SpeedWorkFunction
          preSubmit={this.preSubmit}
          handleSpeedChange={this.handleSpeedChange}
          currFile={this.props.currFile}
          playbackRate={this.props.playbackRate}
          submitting={this.props.submitting}
          fileListModal={this.props.fileListModal}
        />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  tokenId: state.common.tokenId,
  work: state.work.work,
  startTime: state.work.startTime,
  playbackRate: state.work.playbackRate,
  uploading: state.work.uploading,
  processStep: state.work.processStep,
  processPercent: state.work.processPercent,
  cancelProgress: state.work.cancelProgress,
  submitting: state.work.submitting,
  uploadPercent: state.work.uploadPercent,
  fileListModal: state.common.fileListModal,
});
const mapDispatchToProps = dispatch => ({
  onFilesChange: files => dispatch(actions.onFilesChange(files)),
  onFileListModalChange: fileListModal => dispatch(actions.onFileListModalChange(fileListModal)),
  onProcessPercentChange: processPercent => dispatch(actions.onProcessPercentChange(processPercent)),
  onProcessStepChange: processStep => dispatch(actions.onProcessStepChange(processStep)),
  onStartTimeChange: startTime => dispatch(actions.onStartTimeChange(startTime)),
  onUserActionChange: userAction => dispatch(actions.onUserActionChange(userAction)),
  onPlaybackRateChange: playbackRate => dispatch(actions.onPlaybackRateChange(playbackRate)),
  onUploadPercentChange: uploadPercent => dispatch(actions.onUploadPercentChange(uploadPercent)),
  onSubmittingChange: submitting => dispatch(actions.onSubmittingChange(submitting)),
  onWorkChange: work => dispatch(actions.onWorkChange(work)),
  onPrevWorkChange: prevWork => dispatch(actions.onPrevWorkChange(prevWork)),
  onSuccessFileGuidsChange: successFileGuids => dispatch(actions.onSuccessFileGuidsChange(successFileGuids)),
  onPurchaseModalObjChange: purchaseModal => dispatch(actions.onPurchaseModalObjChange(purchaseModal)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(workplaceHOC(SpeedWork));
