import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "@/assets/screenshotWork.scss";

export const ScreenshotWorkRender = ({
  screenshotList,
  currFile,
  w,
  h,
  ready,
  screenshotContentList,
  handleScreenshot,
  deleteItem,
  handleSubmit,
}) => {
  const { t } = useTranslation(["screenshotWork", "common"]);
  return (
    <div style={{ height: "100%" }}>
      <div className="ScreenshotWork">
        <div className="wrap">
          <div className="add">
            <button disabled={currFile ? (screenshotList.length >= 10 || !ready ? true : false) : true} onClick={handleScreenshot}>
              <PlusOutlined />
              <span>{t("Screenshot")}</span>
            </button>
          </div>
          {currFile && screenshotContentList && screenshotList && (
            <div className="itemList">
              <Row gutter={[8, 8]}>
                {screenshotContentList.map((item, index) => (
                  <Col span={12} key={index}>
                    <div className={screenshotList.length && screenshotList.length - 1 >= index ? "item active" : "item"}>
                      {screenshotList.length - 1 >= index && (
                        <div>
                          <img src={screenshotList[index].picUrl} style={w > h ? { width: "100%" } : { height: "100%" }} alt="screenshot" />
                          <button className="close" onClick={() => deleteItem(index)}>
                            <svg
                              width="1rem"
                              height="1rem"
                              viewBox="0 0 16 16"
                              className="bi bi-x"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                              />
                            </svg>
                          </button>
                        </div>
                      )}
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </div>
        {/* <canvas id="canvas" style={{ display: "none" }}></canvas> */}
        <div className="save">
          <Button type="primary" block={true} onClick={handleSubmit} size="middle" disabled={currFile && screenshotList.length ? false : true}>
            {t("common:Save")}
          </Button>
        </div>
      </div>
    </div>
  );
};
