export default {
  Back: "戻る",
  Cut: "カット",
  Speed: "スピード",
  "Crop & Rotate": "クロップと回転",
  Resize: "サイズ変更",
  Extract: "抽出",
  "Add Music": "音楽を追加",
  Subtitles: "サブタイトル",
  Merge: "結合",
  "Watermark Remover": "ウォーターマーク除去",
  "Add Watermark": "ウォーターマークを追加",
  "To GIF": "GIFへ",
  Convert: "変換",
  Screenshot: "スクリーンショット",
  Reverse: "逆再生",
  Loop: "ループ再生",
};
