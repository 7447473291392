export default {
  'Image': 'छवि',
  'Text': 'टेक्स्ट',
  'Add image': 'छवि डालें',
  'Opacity': 'अपारदर्शिता',
  "Add text": 'टेक्स्ट डालें',
  'Text Color': 'टेक्स्ट का रंग',
  'Outline': 'आउटलाइन',
  'Background': 'बैकग्राउंड',
  'Text here': 'यहाँ टेक्स्ट डालें',
}