import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "@/action";
import { useDrop } from "react-dnd";
import RemoveWatermarkPreviewFunction from "./children/RemoveWatermarkPreviewFunction";
import moment from "moment";
import MultiCrops from "react-multi-crops";
import ReactGA from "react-ga";
import Utils from "@/utils/utils";
import { ReactComponent as Close } from "@/assets/icons/close-circle.svg";

const VideoWrap = ({ currFile, videoReady, coordinates, changeCoordinate, deleteCoordinate, onCurrFileChange }) => {
  const [, drop] = useDrop({
    accept: "FileListItem",
    drop: () => ({ name: "RemoveWatermarkVideoWrap" }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: item => {
      if ((item.file && item.file.audiofileurl) || (item.file && item.file.status === "gif")) {
        return false;
      }
      return true;
    },
  });
  return (
    <div ref={drop} className="videoWrap" id="videoRef">
      <div style={{ width: "fit-content", height: "fit-content", margin: "auto", position: "relative" }}>
        <button className="close" onClick={() => onCurrFileChange(null)}>
          <Close />
        </button>
        <video className="active" crossOrigin="Anonymous">
          <source src={currFile.videofileurl} type="video/mp4" />
        </video>
        {videoReady ? (
          <div className="MultiCrops">
            <MultiCrops
              src="https://images.hitpaw.com/images/common/logo-dark.svg"
              coordinates={coordinates}
              onChange={changeCoordinate}
              onDelete={deleteCoordinate}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

class RemoveWatermarkPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoReady: false,
      coordinates: [],
      cacheCutTime: [],
    };
  }

  getRemoveWatermarkPercent = value => {
    const d = Utils.getSeconds(this.props.currFile.duration);
    const t = Utils.getSeconds(value);
    return (t / d) * 100;
  };

  AddRemoveWatermarkWork = coordinate => {
    const removeWatermarkList = [...this.props.removeWatermarkList];
    if (removeWatermarkList.length > 0) {
      removeWatermarkList.forEach(item => {
        item.selected = false;
      });
    }
    const d = Utils.getSeconds(this.props.currFile.duration);
    let start, end;
    if (this.state.playing) {
      start = this.state.currentTime;
      end = moment("00:00:00", "mm:ss:SS")
        .add(d, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1");
    } else {
      if (removeWatermarkList.length > 0) {
        if (Utils.getSeconds(this.props.currFile.duration) - Utils.getSeconds(removeWatermarkList[removeWatermarkList.length - 1].end) >= 1) {
          start = moment("00:00:00", "mm:ss:SS")
            .add(Utils.getSeconds(removeWatermarkList[removeWatermarkList.length - 1].end), "seconds")
            .format("mm:ss:SS")
            .replace(/:([^:]*)$/, ".$1");
          end = this.props.currFile.duration;
        } else {
          start = removeWatermarkList[removeWatermarkList.length - 1].start;
          end = removeWatermarkList[removeWatermarkList.length - 1].end;
        }
      } else {
        start = "00:00.00";
        end = moment("00:00:00", "mm:ss:SS")
          .add(d, "seconds")
          .format("mm:ss:SS")
          .replace(/:([^:]*)$/, ".$1");
      }
    }
    const video = document.querySelector(".videoWrap video");
    let x, y, width, height;
    if (video) {
      width = video.clientWidth / 4;
      height = video.clientHeight / 4;
      x = video.parentNode.clientWidth / 2 - width / 2;
      y = video.parentNode.clientHeight / 2 - height / 2;
    }
    removeWatermarkList.push({
      start: start,
      end: end,
      selected: true,
      coordinate: coordinate || {
        id: removeWatermarkList.length,
        x: x || 525,
        y: y || 200,
        width: width || 150,
        height: height || 150,
      },
    });
    this.props.onRemoveWatermarkListChange(removeWatermarkList);
    ReactGA.event(
      {
        category: "RemoveWatermark",
        action: "AddRemoveWatermark",
      },
      ["OnlineTracker", "Tracker"]
    );
  };

  getVideoSize = () => {
    const Preview = document.querySelector(".Preview");
    const video = document.querySelector(".videoWrap video");
    const callback = () => {
      this.setState({ videoReady: true }, () => {
        const controls = document.querySelector(".controls");
        controls.style.width = video.clientWidth + "px";
      });
      this.props.onRemoveWatermarkListChange([]);
      this.AddRemoveWatermarkWork();
      this.props.removeWatermarkList.forEach((item, i) => {
        if (item.selected) {
          document.querySelectorAll(".Preview .RemoveWatermarkPreview .MultiCrops > div > div")[i].classList.add("active");
        } else {
          document.querySelectorAll(".Preview .RemoveWatermarkPreview .MultiCrops > div > div")[i].classList.remove("active");
        }
      });
    };
    Utils.setVideoContainerSize(Preview, video, callback);
    // this.setState({ videoReady: true }, () => {
    //   const controls = document.querySelector(".controls");
    //   controls.style.width = video.clientWidth + "px";
    // });
    // video.style.width = "100%";
    // video.style.height = "auto";
    // video.parentNode.style.height = "auto";
    // video.load();
    // if (!video.handleLoadEvent) {
    //   video.handleLoadEvent = () => {
    //     if (video.clientHeight > Preview.clientHeight - 200) {
    //       video.style.width = "auto";
    //       video.style.height = Preview.clientHeight - 200 + "px";
    //       video.parentNode.style.height = Preview.clientHeight - 200 + "px";
    //     } else {
    //       video.parentNode.style.height = video.clientHeight + "px";
    //     }
    //     this.setState({ videoReady: true });
    //     this.props.onRemoveWatermarkListChange([]);
    //     this.AddRemoveWatermarkWork();
    //     this.props.removeWatermarkList.forEach((item, i) => {
    //       if (item.selected) {
    //         document.querySelectorAll(".Preview .RemoveWatermarkPreview .MultiCrops > div > div")[i].classList.add("active");
    //       } else {
    //         document.querySelectorAll(".Preview .RemoveWatermarkPreview .MultiCrops > div > div")[i].classList.remove("active");
    //       }
    //     });
    //   };
    //   video.addEventListener("loadeddata", video.handleLoadEvent);
    // }
  };

  changeCoordinate = (coordinate, index, coordinates) => {
    if (index >= 10) {
      document.querySelector(".Preview .RemoveWatermarkPreview .MultiCrops").style.cursor = "no-drop";
      return false;
    } else {
      document.querySelector(".Preview .RemoveWatermarkPreview .MultiCrops").style.cursor = "cell";
    }
    const video = document.querySelector(".videoWrap video");
    const coordinates_new = this.state.coordinates;
    const removeWatermarkList = [...this.props.removeWatermarkList];
    const removeWatermark = removeWatermarkList.filter(value => value.selected)[0];
    if (coordinate.x < video.parentNode.clientWidth / 2 - video.clientWidth / 2) {
      coordinate.x = video.parentNode.clientWidth / 2 - video.clientWidth / 2;
    }
    if (coordinate.x > video.parentNode.clientWidth / 2 + video.clientWidth / 2 - coordinate.width) {
      coordinate.x = video.parentNode.clientWidth / 2 + video.clientWidth / 2 - coordinate.width;
    }
    if (coordinate.y < video.parentNode.clientHeight / 2 - video.clientHeight / 2) {
      coordinate.y = video.parentNode.clientHeight / 2 - video.clientHeight / 2;
    }
    if (coordinate.y > video.parentNode.clientHeight / 2 + video.clientHeight / 2 - coordinate.height) {
      coordinate.y = video.parentNode.clientHeight / 2 + video.clientHeight / 2 - coordinate.height;
    }
    if (coordinate.width > video.clientWidth) {
      coordinate.width = video.clientWidth;
      return false;
    }
    if (coordinate.height > video.clientHeight) {
      coordinate.height = video.clientHeight;
      return false;
    }
    coordinates_new[index] = coordinate;
    this.setState({
      coordinates: coordinates_new,
    });
    if (removeWatermarkList.length < coordinates_new.length) {
      this.AddRemoveWatermarkWork(coordinate);
    } else if ((removeWatermark && removeWatermark.coordinate.id !== coordinate.id) || !removeWatermark) {
      removeWatermarkList.forEach((item, i) => {
        if (index === i) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      });
      this.props.onRemoveWatermarkListChange(removeWatermarkList);
    } else {
      removeWatermark.coordinate = coordinates_new[index];
      this.props.onRemoveWatermarkListChange(removeWatermarkList);
    }
    this.setState({
      playing: false,
    });
    video.pause();
  };

  deleteCoordinate = (coordinate, index, coordinates) => {
    const removeWatermarkList = [...this.props.removeWatermarkList];
    if (removeWatermarkList.length <= 1) {
      return false;
    }
    removeWatermarkList.forEach((item, i) => {
      if (index === i) {
        removeWatermarkList.splice(i, 1);
        return false;
      }
    });
    this.props.onRemoveWatermarkListChange(removeWatermarkList);
    this.setState({
      coordinates,
    });
  };

  componentDidMount() {
    this.getVideoSize();
    if (this.props.removeWatermarkList.length > this.state.coordinates.length) {
      this.setState({
        coordinates: this.props.removeWatermarkList.map(item => item.coordinate),
        cacheCutTime: [],
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currFile !== this.props.currFile) {
      this.getVideoSize();
      this.setState({
        videoReady: false,
      });
    }
    if (this.props.removeWatermarkList !== prevProps.removeWatermarkList) {
      const removeWatermarkList = [...this.props.removeWatermarkList];
      if (this.props.removeWatermarkList.length > 0 && this.props.removeWatermarkList.some(value => value.selected)) {
        const removeWatermark = removeWatermarkList.filter(item => item.selected)[0];
        let currentTime;
        if (removeWatermark.start === this.state.cacheCutTime[0] && removeWatermark.end !== this.state.cacheCutTime[1]) {
          currentTime = removeWatermark.end;
        } else {
          currentTime = removeWatermark.start;
        }
        this.setState({
          cacheCutTime: [removeWatermark.start, removeWatermark.end],
        });
        if (currentTime) {
          if (document.querySelectorAll(".Preview .RemoveWatermarkPreview .MultiCrops > div > div").length > 0) {
            removeWatermarkList.forEach((item, i) => {
              if (Utils.getSeconds(currentTime) >= Utils.getSeconds(item.start) && Utils.getSeconds(currentTime) <= Utils.getSeconds(item.end)) {
                document.querySelectorAll(".Preview .RemoveWatermarkPreview .MultiCrops > div > div")[i].style.display = "block";
              } else {
                document.querySelectorAll(".Preview .RemoveWatermarkPreview .MultiCrops > div > div")[i].style.display = "none";
              }
            });
          }
        }
      }
      if (document.querySelectorAll(".Preview .RemoveWatermarkPreview .MultiCrops > div > div").length > 0) {
        removeWatermarkList.forEach((item, i) => {
          if (item.selected) {
            document.querySelectorAll(".Preview .RemoveWatermarkPreview .MultiCrops > div > div")[i].classList.add("active");
          } else {
            document.querySelectorAll(".Preview .RemoveWatermarkPreview .MultiCrops > div > div")[i].classList.remove("active");
          }
        });
      }
      if (prevProps.removeWatermarkList.length === 0 || this.props.removeWatermarkList.length < this.state.coordinates.length) {
        this.setState({
          coordinates: this.props.removeWatermarkList.map(item => item.coordinate),
        });
      }
    }
  }

  render() {
    return (
      <div className="RemoveWatermarkPreview">
        <VideoWrap
          currFile={this.props.currFile}
          videoReady={this.state.videoReady}
          coordinates={this.state.coordinates}
          changeCoordinate={this.changeCoordinate}
          deleteCoordinate={this.deleteCoordinate}
          onCurrFileChange={this.props.onCurrFileChange}
        />
        <RemoveWatermarkPreviewFunction
          removeWatermarkList={this.props.removeWatermarkList}
          onRemoveWatermarkListChange={this.props.onRemoveWatermarkListChange}
          currFile={this.props.currFile}
          videoReady={this.state.videoReady}
          cacheCutTime={this.state.cacheCutTime}
          submitting={this.props.submitting}
        />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  work: state.work.work,
  removeWatermarkList: state.work.removeWatermarkList,
  submitting: state.work.submitting,
});

const mapDispatchToProps = dispatch => ({
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
  onRemoveWatermarkListChange: removeWatermarkList => dispatch(actions.onRemoveWatermarkListChange(removeWatermarkList)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(RemoveWatermarkPreview);
