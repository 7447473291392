import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "@/action";
import Utils from "@/utils/utils";
import ReactGA from "react-ga";
import TipsModal from "@/components/common/TipsModal";
import TipsModalSimple from "@/components/common/TipsModalSimple";
import FileListModalFunction from "./children/FileListModalFunction";
import moment from "moment";
import { requsetDeleteAnonymousFileList } from "@/api/apisFiles";
import "@/assets/fileListModal.scss";

class FileListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipsClosed: false,
      targetFiles: [],
      deleteModal: false,
      warningInfo: false,
      warningInfoText: "",
      warningInfoObj: {},
      selectFile: {},
      selectToEditGIFFile: {},
      editGIFModalVisible: false,
    };
  }

  handleSelectToEditClick = () => {
    const files = [...this.props.files];
    const file = files.filter(file => file.mark)[0];
    if (file.size >= 20 * 1024 * 1024) {
      if (Utils.isNeedToShowPurchaseModal()) {
        this.props.onPurchaseModalObjChange({
          visible: true,
          type: "uploadSizeBiggerThanLimit",
        });
      }
      //==============之前的限制弹窗跟埋点：start===============
      // this.setState({
      //   warningInfo: true,
      //   warningInfoText: "The file size cannot be larger than 20MB.",

      //   warningInfoObj: {
      //     type: "size",
      //     size: (file.size / 1024 / 1024).toFixed(2),
      //   },
      // });
      // ReactGA.event({
      //   category: this.props.work === "merge" ? "Merge" : this.props.work === "cut" ? "Cut" : "ToGif",
      //   action: "ExceedSize",
      //   label: /\.[^.]+$/.exec(file.name)[0].toLocaleLowerCase() + "_" + (file.size / 1024 / 1024).toFixed(2),
      // });
      //==============之前的限制弹窗跟埋点：end===============
      return false;
    }
    file.update = new Date().getTime();
    this.props.onCurrFileChange(file);
    this.handleClose();
  };

  handleSelectClick = obj => {
    if (obj.fileType === "gif") {
      this.setState({ editGIFModalVisible: true, selectToEditGIFFile: obj });
      return;
    }
    this.props.onCurrFileChange(obj);
    this.props.onFileListModalChange(null);
  };

  /**
   *将文件库的gif文件转换为video
   * @memberof FileListModal
   */
  handleConvertGIFToVideo = () => {
    this.setState({
      editGIFModalVisible: false,
    });

    if (this.state.selectToEditGIFFile.originalFileObj) {
      const file = { ...this.state.selectToEditGIFFile.originalFileObj };
      let files = [...this.props.files];
      file.guid = new Date().getTime();
      file.name = moment(new Date()).format("YYYYMMDDHHmmSS") + ".mp4";
      files.unshift(file);
      this.props.onCurrFileChange(file);
      this.props.onFilesChange(files);
      localStorage.setItem("files", JSON.stringify(files));
      this.props.onFileListModalChange(null);
    }
  };

  handleDeleteClick = () => {
    this.setState({ deleteModal: false });
    const files = [...this.props.files];
    const delFileUrls = [];
    for (let i = files.length - 1; i >= 0; i--) {
      if (files[i].guid === this.state.deleteFile.guid) {
        files.splice(i, 1);
        delFileUrls.push(this.state.deleteFile.jobId);
      }
    }
    console.log(delFileUrls);
    // Utils.delFiles(delFileUrls);
    this.requsetDeleteAnonymousFileList(delFileUrls);
    this.props.onFilesChange(files);
    localStorage.setItem("files", JSON.stringify(files));
  };

  /**
   * 请求删除文件夹
   * @param {Array} jobIds 删除的文件id
   */
  requsetDeleteAnonymousFileList = jobIds => {
    const formData = new FormData();
    formData.append("job_ids", jobIds);
    requsetDeleteAnonymousFileList({ body: formData })
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleDownloadClick = e => {
    if (this.props.fileListModal.type !== "files") {
      this.state.targetFiles.forEach(item => {
        Utils.downloadFile(
          item,
          item.status === "gif" ? `${item.name}.gif` : item.status === "convert" || item.status === "mute" ? item.name : `${item.name}.mp4`
        );
      });
    } else if (!this.props.files.some(file => file.mark)) {
      return false;
    } else {
      const files = [...this.props.files];
      files.forEach((item, i) => {
        if (item.mark) {
          Utils.downloadFile(
            item,
            item.status === "gif" ? `${item.name}.gif` : item.status === "convert" || item.status === "mute" ? item.name : `${item.name}.mp4`
          );
        }
      });
    }
  };

  handleCloseClick = () => {
    if (this.props.fileListModal.type === "cutSuccess") {
      ReactGA.event(
        {
          category: "Cut",
          action: "CutBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "mergeSuccess") {
      ReactGA.event(
        {
          category: "Merge",
          action: "MergBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "gifSuccess") {
      ReactGA.event(
        {
          category: "ToGif",
          action: "GifBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "speedSuccess") {
      ReactGA.event(
        {
          category: "Speed",
          action: "SpeedBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "crop_rotateSuccess") {
      ReactGA.event(
        {
          category: "Crop&Rotate",
          action: "Crop&RotateBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "resizeSuccess") {
      ReactGA.event(
        {
          category: "Resize",
          action: "ResizeBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "removeWatermarkSuccess") {
      ReactGA.event(
        {
          category: "RemoveWatermark",
          action: "RemoveWatermarkBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "convertSuccess") {
      ReactGA.event(
        {
          category: "Convert",
          action: "ConvertBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "muteSuccess") {
      ReactGA.event(
        {
          category: "SplitAudio",
          action: "SplitAudioBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "subtitleSuccess") {
      ReactGA.event(
        {
          category: "AddText",
          action: "TextBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "addWatermarkSuccess") {
      ReactGA.event(
        {
          category: "AddMark",
          action: "AddMarkBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "addMusicSuccess") {
      ReactGA.event(
        {
          category: "AddMusic",
          action: "MusicBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    this.handleClose();
  };

  handleClose = () => {
    const fileListModal = { ...this.props.fileListModal };
    fileListModal.show = false;
    this.props.onFileListModalChange(fileListModal);
    const files = [...this.props.files];
    files.forEach(item => {
      item.mark = false;
    });
  };

  handleEditNew = () => {
    this.handleClose();
    Utils.initWork();
    if (this.props.fileListModal.type === "cutSuccess") {
      ReactGA.event(
        {
          category: "Cut",
          action: "CutNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "gifSuccess") {
      ReactGA.event(
        {
          category: "ToGif",
          action: "GifNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "mergeSuccess") {
      ReactGA.event(
        {
          category: "Merge",
          action: "MergNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "speedSuccess") {
      ReactGA.event(
        {
          category: "Speed",
          action: "SpeedNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "crop_rotateSuccess") {
      ReactGA.event(
        {
          category: "Crop&Rotate",
          action: "Crop&RotateNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "resizeSuccess") {
      ReactGA.event(
        {
          category: "Resize",
          action: "ResizeNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "removeWatermarkSuccess") {
      ReactGA.event(
        {
          category: "RemoveWatermark",
          action: "RemoveWatermarkNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "convertSuccess") {
      ReactGA.event(
        {
          category: "Convert",
          action: "ConvertNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "muteSuccess") {
      ReactGA.event(
        {
          category: "SplitAudio",
          action: "SplitAudioNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "subtitleSuccess") {
      ReactGA.event(
        {
          category: "AddText",
          action: "TextNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "addWatermarkSuccess") {
      ReactGA.event(
        {
          category: "AddMark",
          action: "AddMarkNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "addMusicSuccess") {
      ReactGA.event(
        {
          category: "AddMusic",
          action: "MusicNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
  };

  generateTargetFiles = () => {
    let targetFiles = [];
    switch (this.props.fileListModal.type) {
      case "cutSuccess":
        targetFiles = this.props.files.filter(file => file.status === "cut" && this.props.fileListModal.guids.some(value => value === file.guid));
        break;
      case "mergeSuccess":
        targetFiles = this.props.files.filter(file => file.status === "merge" && file.guid === this.props.fileListModal.guid);
        break;
      case "gifSuccess":
        targetFiles = this.props.files.filter(file => file.status === "gif" && file.guid === this.props.fileListModal.guid);
        break;
      case "speedSuccess":
        targetFiles = this.props.files.filter(file => file.status === "speed" && file.guid === this.props.fileListModal.guid);
        break;
      case "crop_rotateSuccess":
        targetFiles = this.props.files.filter(file => file.status === "crop_rotate" && file.guid === this.props.fileListModal.guid);
        break;
      case "resizeSuccess":
        targetFiles = this.props.files.filter(file => file.status === "resize" && file.guid === this.props.fileListModal.guid);
        break;
      case "removeWatermarkSuccess":
        targetFiles = this.props.files.filter(file => file.status === "removeWatermark" && file.guid === this.props.fileListModal.guid);
        break;
      case "convertSuccess":
        targetFiles = this.props.files.filter(file => file.status === "convert" && file.guid === this.props.fileListModal.guid);
        break;
      case "muteSuccess":
        targetFiles = this.props.files.filter(file => file.status === "mute" && this.props.fileListModal.guids.some(value => value === file.guid));
        break;
      case "addMusicSuccess":
        targetFiles = this.props.files.filter(file => file.status === "addMusic" && file.guid === this.props.fileListModal.guid);
        break;
      case "subtitleSuccess":
        targetFiles = this.props.files.filter(file => file.status === "subtitle" && file.guid === this.props.fileListModal.guid);
        break;
      case "addWatermarkSuccess":
        targetFiles = this.props.files.filter(file => file.status === "addWatermark" && file.guid === this.props.fileListModal.guid);
        break;
      default:
        targetFiles = this.props.files;
        break;
    }
    return targetFiles;
  };

  handleSelectFile = item => {
    if (item.audiofileurl || item.status === "gif") {
      return false;
    }
    this.setState({
      selectFile: item,
    });
  };

  initSelectFile = () => {
    const { targetFiles } = this.state;
    let selectFile;
    if (this.props.fileListModal.type !== "files") {
      selectFile = targetFiles.filter(file => !file.audiofileurl && file.status !== "gif")[0];
      this.setState({ selectFile });
    }
  };

  showActive = guid => {
    const { selectFile, targetFiles } = this.state;
    if (targetFiles.filter(file => !file.audiofileurl).length > 1 && selectFile.guid === guid) {
      return true;
    }
    return false;
  };

  componentDidUpdate(prevProps) {
    if (this.props.fileListModal && this.props.fileListModal !== prevProps.fileListModal) {
      this.setState({ targetFiles: this.generateTargetFiles() }, this.initSelectFile);
    }
  }

  render() {
    return (
      <div>
        {this.props.fileListModal ? (
          <FileListModalFunction
            fileListModal={this.props.fileListModal}
            files={this.props.files}
            targetFiles={this.state.targetFiles}
            selectFile={this.state.selectFile}
            currFile={this.props.currFile}
            addMusicWorkObj={this.props.addMusicWorkObj}
            handleClose={this.handleClose}
            handleSelectClick={this.handleSelectClick}
            handleSelectAll={this.handleSelectAll}
            handleDeleteClick={this.handleDeleteClick}
            handleSelectFile={this.handleSelectFile}
            handleDeleteModalShow={file => this.setState({ deleteModal: true, deleteFile: file })}
            showActive={this.showActive}
          />
        ) : null}
        <TipsModalSimple
          visible={this.state.deleteModal}
          onCancel={() => {
            this.setState({ deleteModal: false });
          }}
          content="Are you sure you want to delete it?"
          cancelBtnHandle={() => {
            this.setState({ deleteModal: false });
          }}
          confirmBtnHandle={this.handleDeleteClick}
          confirmBtnText="Yes"
        />
        {this.props.work === "addWatermark" || this.props.work === "subtitle" ? (
          <TipsModalSimple
            visible={this.state.warningInfo}
            onCancel={() => {
              this.setState({ warningInfo: false });
            }}
            content={this.state.warningInfoText}
            tipsObj={this.state.warningInfoObj}
            cancelBtnHandle={() => {
              this.setState({ warningInfo: false });
            }}
          />
        ) : (
          <TipsModal
            showGuidText
            visible={this.state.warningInfo}
            onCancel={() => {
              this.setState({ warningInfo: false });
            }}
            content={this.state.warningInfoText}
            tipsObj={this.state.warningInfoObj}
            cancelBtnHandle={() => {
              this.setState({ warningInfo: false });
            }}
            hasDownload
          />
        )}
        <TipsModalSimple
          visible={this.state.editGIFModalVisible}
          onCancel={() => {
            this.setState({ editGIFModalVisible: false });
          }}
          content="GIF does not support editing. Do you want to convert the MP4 for editing?"
          customBtns={[
            {
              text: "Go to Edit",
              style: {
                minWidth: "120px",
                marginTop: "20px",
                marginRight: "20px",
                fontSize: "14px",
              },
              clickFunc: this.handleConvertGIFToVideo,
            },
            {
              text: "Download GIF",
              style: {
                minWidth: "120px",
                marginTop: "20px",
                fontSize: "14px",
              },
              ghost: true,
              clickFunc: () => {
                this.setState({
                  editGIFModalVisible: false,
                });
                Utils.downloadFile(this.state.selectToEditGIFFile, "gif");
              },
            },
          ]}
        />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  fileListModal: state.common.fileListModal,
  files: state.files.files,
  currFile: state.files.currFile,
  addMusicWorkObj: state.work.addMusicWorkObj,
  work: state.work.work,
});

const mapDispatchToProps = dispatch => ({
  onFilesChange: files => dispatch(actions.onFilesChange(files)),
  onFileListModalChange: fileListModal => dispatch(actions.onFileListModalChange(fileListModal)),
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
  onMergeWorkListChange: mergeWorkList => dispatch(actions.onMergeWorkListChange(mergeWorkList)),
  onRemoveWatermarkListChange: removeWatermarkList => dispatch(actions.onRemoveWatermarkListChange(removeWatermarkList)),
  onWorkChange: work => dispatch(actions.onWorkChange(work)),
  onAddMusicWorkObjChange: addMusicWorkObj => dispatch(actions.onAddMusicWorkObjChange(addMusicWorkObj)),
  onPurchaseModalObjChange: purchaseModal => dispatch(actions.onPurchaseModalObjChange(purchaseModal)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(FileListModal);
