export default {
  Start: "Starten",
  End: "Enden",
  Save: "Speichern",
  Cancel: "Stornieren",
  Download: "Herunterladen",
  downloading: "wird heruntergeladen",
  "Drag and drop from Files or local disk": "Ziehen & Tropfen von Dateien oder lokaler Festplatte",
  "Click to Add File": "Klicken, um Datei hinzufügen",
  "The minimum time length is 1s!": "Die minimale Zeitdauer beträgt 1s!",
  "The maximum time length is 50s!": "Die maximale Zeitdauer beträgt 50s!",
  "Try Again": "Erneut versuchen",
  "Coming Soon": "Kommt bald",
  "Learn more": "Erfahren Sie mehr",
  Yes: "Ja",
  "Please click the Save button to ensure the previous editing to take effect.":
    "Klicken Sie auf die „Speichern“, um sicherzustellen, dass die Einstellungen wirksam werden.",
  // **************************TipsModal Message Title*****************************
  "Cut 10 video fragments at most. Get HitPaw Toolkit for more features.":
    "Schneiden Sie höchstens 10 Video Fragmente. Holen Sie sich das HitPaw Toolkit für weitere Funktionen.",
  "Max file size for videos is 20 MB. Get HitPaw Toolkit for big file.":
    "Die Dateigröße darf nicht größer als 20MB sein. Holen Sie sich das HitPaw Toolkit für weitere Funktionen.",
  "Max file size for videos is 20 MB. Get HitPaw Watermark Remover for big file.":
    "Die Dateigröße darf nicht größer als 20MB sein. Holen Sie sich das HitPaw Watermark Remover für weitere Funktionen.",
  "The minimum time length is 1s! Get HitPaw Toolkit for more features.":
    "Die minimale Zeitdauer beträgt 1s! Holen Sie sich das HitPaw Toolkit für weitere Funktionen.",
  "Add 3 videos at most. Get HitPaw Toolkit for more features.":
    "Fügen maximal 3 Videos hinzu. Holen Sie sich das HitPaw Toolkit für weitere Funktionen.",
  "This format is not supported currently.": "Dieses Format wird derzeit nicht unterstützt.",
  "This format is not supported currently. Get HitPaw Toolkit for more formats":
    "Dieses Format wird derzeit nicht unterstützt. Holen Sie sich das HitPaw Toolkit für weitere Funktionen.",
  "This format is not supported currently. Get HitPaw Watermark Remover for more formats":
    "Dieses Format wird derzeit nicht unterstützt. Holen Sie sich das HitPaw Watermark Remover für weitere Funktionen.",
  "Processing failed. Get HitPaw Toolkit for stable processing.": "Video konnte nicht generiert werden!",
  "Failed to load!  Get HitPaw Watermark Remover for stable processing.": "Laden ist fehlgeschlagen!",
  "Are you sure you want to delete it?": "Möchten Sie es wirklich löschen?",
  "Failed to extract the audio!": "Audio konnte nicht extrahiert werden!",
  "Video link copied": "Der Video-Link wurde kopiert",
  "Cut 10 video fragments at most.": "Schneiden Sie höchstens 10 Video Fragmente.",
  "Max file size for videos is 20 MB.": "Die Dateigröße darf nicht größer als 20 MB sein.",
  "Max file size for videos is 100 MB.": "Die Dateigröße darf nicht größer als 100 MB sein.",
  "Failed to load!": "Laden ist fehlgeschlagen!",

  //******************************2021.05.17 v1.3.0 *******************************/
  "Screenshots downloaded.": "Screenshot werden heruntergeladen.",
  "Online service, no download required": "Online-Service, kein Download erforderlich",
  "Add up to 20MB file": "Maximal 20 MB Datei hinzufügen",
  "Support common formats": "Gängige Formate werden unterstützt",
  "Best for general editing requirements": "Das Beste für allgemeine Bearbeitungs-Anforderungen",
  "0 second wait": "0 Sekunde Wartung",
  "No size limits": "Keine Größenbeschränkungen",
  "Support 1000+ formats": "1000+ Formate werden unterstützt",
  "Stable processing": "Stabile Verarbeitung",
  "Get access to HitPaw Toolkit!": "Zugriff auf HitPaw Toolkit!",
  "Support cut, crop & rotate, adjust, speed, add music, stop motion, etc.":
    "Unterstützung für Schneiden, Zuschneiden & Drehen, Anpassen, Geschwindigkeit, Musik, Stop-Motion usw.",
  "The easiest video editing software for beginners": "Die einfachste Videobearbeitungssoftware für Anfänger",
  "Remove watermark from videos and images in simple clicks": "Wasserzeichen von Video- und Bild entfernen mit Klicks",
  "TRY IT FREE": "KOSTENLOS TESTEN",
  "BUY NOW": "JETZT KAUFEN",
  "HitPaw Online Video Editor": "HitPaw Online Editor de Vídeo",

  //******************************2021.09.06 v1.5.0********************************/
  "Add Video": "Video hinzufügen",
  More: "Mehr",
  "History files": "Historische Dateien",
  Share: "Sich teilen",
  Duration: "Dauer",
  "Download all": "Alle herunterladen",
  "Download selected": "Ausgewähltes herunterladen",
  "Add Audio": "Audio hinzufügen",

  //********************************2021.10.19 v1.6.0*********************************/
  "GIF does not support editing. Do you want to convert the MP4 for editing?":
    "GIF-Dateien werden für die Bearbeitung nicht unterstützt. Möchten Sie die MP4-Datei für die Bearbeitung konvertieren?",
  "Go to Edit": "Zum Bearbeiten gehen",
  "Download GIF": "GIF herunterladen",

  //********************************2021.10.19 v1.6.1*********************************/
  "HitPaw Video Editor": "HitPaw Video Editor",
  "No video size limits": "Keine Begrenzung der Videogröße.",
  "Cut/crop/rotate/resize/merge video easily": "Schneiden/Schnitt/Drehen/Größe ändern/Zusammenfügen von Videos einfach.",
  "Tons of video effects are available for your video creativity": "Für Ihre Videokreativität stehen eine Vielzahl von Videoeffekten zur Verfügung.",
  "Unlimited tracks allow you to add multiple audios as you want":
    "Dank der unbegrenzten Anzahl von Spuren können Sie beliebig viele Audios hinzufügen.",

  //********************************2021.11.02 v1.6.1*********************************/
  "Download HitPaw Video Editor for more features.": "Laden Sie HitPaw Video Editor für weitere Funktionen herunter. ",
  "Get HitPaw Watermark Remover for more features.": "Holen Sie sich das HitPaw Watermark Remover für weitere Funktionen.",
  "Get HitPaw Toolkit for more features.": "Holen Sie sich das HitPaw Toolkit für weitere Funktionen.",
  "Add 3 videos at most.": "Maximal 3 Videos hinzufügen.",
  "Processing failed.": "Verarbeitung fehlgeschlagen. ",
  "The uploaded video is less than 1 second.": "Das hochgeladene Video ist kürzer als 1 Sekunde. ",
  "The video format is not supported.": "Das Videoformat wird nicht unterstützt. ",
  "The audio format is not supported.": "Das Audioformat wird nicht unterstützt. ",
  "AddFileByURL.largerSize.HitPawToolkit":
    "Erfolgreich analysiert! Ihre Datei ist größer als 100 MB und kann von HitPaw Online nicht bearbeitet werden. <1><0>Laden Sie bitte das Video</0></1> herunter und bearbeiten mit dem HitPaw Toolkit.",
  "AddFileByURL.largerSize.HitPawWatermarkRemover":
    "Erfolgreich analysiert! Ihre Datei ist größer als 100 MB und kann von HitPaw Online nicht bearbeitet werden. <1><0>Laden Sie bitte das Video</0></1> herunter und bearbeiten mit dem HitPaw Watermark Remover.",
  "AddFileByURL.largerSize.HitPawVideoEditor":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Video Editor to proceed.",
};
