import React, { Component } from "react";
import { connect } from "react-redux";
import { workplaceHOC } from "../WorkplaceHOC";
import GifWorkFunction from "./children/GifWorkFunction";
import actions from "@/action";
import { request } from "@/api/request";
import moment from "moment";
import Utils from "@/utils/utils";
import ReactGA from "react-ga";
import "@/assets/gifWork.scss";

class GifWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resolution: "480:-1",
      framesRate: 10,
      fps: 30,
      waitingUpload: false,
    };
  }

  handleStartChange = e => {
    const value = e.target.value;
    const cutWorkList = [...this.props.cutWorkList];
    const cutWork = cutWorkList.filter(item => item.selected)[0];
    cutWork.start = value;
    this.props.onCutWorkListChange(cutWorkList);
    this.props.onUserActionChange(true);
  };

  handleEndChange = e => {
    const value = e.target.value;
    const cutWorkList = [...this.props.cutWorkList];
    const cutWork = cutWorkList.filter(item => item.selected)[0];
    cutWork.end = value;
    this.props.onCutWorkListChange(cutWorkList);
    this.props.onUserActionChange(true);
  };

  handleResolutionChange = value => {
    this.setState({ resolution: value });
    this.props.onUserActionChange(true);
  };

  handleRateChange = value => {
    this.setState({ framesRate: value });
    this.props.onUserActionChange(true);
  };

  handleSubmit = async () => {
    console.log("handleSubmit", this.props.uploadPercent, this.props.uploading);
    // this.props.onProcessStepChange(2)
    const startTime = { ...this.props.startTime };
    startTime.gif = new Date();
    this.props.onStartTimeChange(startTime);
    if (!this.props.uploading && this.props.uploadPercent < 100) {
      this.props.onProcessStepChange(1);
      this.props.onProcessPercentChange(1);
    }
    let gifResponse;
    try {
      gifResponse = await this.getGif();
    } catch (error) {
      console.log(error);
      this.props.handleSubmitFailModal(true);
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "ToGif",
          action: "GifFail",
          label: error + "_none_none_" + this.props.tokenId + "_none_none",
        },
        ["OnlineTracker", "Tracker"]
      );
      return false;
    }
    if (gifResponse.code === 200) {
      let guids = [];
      try {
        guids = await this.props.getNewVideoByProgress("gif", gifResponse);
      } catch (error) {
        if (error !== "cancel") {
          this.props.handleSubmitFailModal(true);
          ReactGA.event(
            {
              category: "ToGif",
              action: "GifFail",
              label: "videotogif_200_200_" + error,
            },
            ["OnlineTracker", "Tracker"]
          );
        }
        return false;
      }
      const modal = {
        getContainer: document.querySelector(".Home"),
        show: true,
        type: "gifSuccess",
        guid: guids[0],
        title: "Generate Files Successfully!",
        buttons: [
          {
            type: "download",
            text: "Download",
          },
          {
            type: "editNew",
            text: "Edit New Video",
          },
        ],
      };
      this.props.onFileListModalChange(modal);
      this.props.onUploadPercentChange(0);
      this.props.onProcessPercentChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "ToGif",
          action: "GifSuccess",
        },
        ["OnlineTracker", "Tracker"]
      );
    } else {
      this.props.handleSubmitFailModal(true);
      this.props.onUploadPercentChange(0);
      this.props.onProcessPercentChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "ToGif",
          action: "GifFail",
          label: "videotogif_200_" + gifResponse.code + "_none_" + this.props.tokenId + "_none_" + gifResponse.msg,
        },
        ["OnlineTracker", "Tracker"]
      );
    }
  };

  getGif = () => {
    const formData = new FormData();
    formData.append("ext", "gif");
    formData.append("start_time", this.props.cutWorkList.filter(item => item.selected)[0].start);
    formData.append(
      "continued_time",
      moment
        .duration(
          moment(this.props.cutWorkList.filter(item => item.selected)[0].end.replace(".", ":"), "mm:ss:SS").valueOf() -
            moment(this.props.cutWorkList.filter(item => item.selected)[0].start.replace(".", ":"), "mm:ss:SS").valueOf()
        )
        .as("seconds")
    );
    formData.append("video_url", this.props.currFile.videofileurl);
    if (this.state.resolution === "origin") {
      const file = this.props.currFile;
      formData.append("resolution", `${file.wh.substring(0, file.wh.indexOf("x"))}:${file.wh.substring(file.wh.indexOf("x") + 1)}`);
    } else {
      formData.append("resolution", this.state.resolution);
    }
    formData.append("number_of_frames", this.state.framesRate);
    formData.append("token", this.props.tokenId);
    return new Promise((resolve, reject) => {
      request("convertGif", {
        method: "post",
        body: formData,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  preSubmit = () => {
    ReactGA.event(
      {
        category: "ToGif",
        action: "GifSave",
        label:
          Utils.getSeconds(this.props.cutWorkList.filter(item => item.selected)[0].end) -
          Utils.getSeconds(this.props.cutWorkList.filter(item => item.selected)[0].start) +
          "s_" +
          this.state.resolution +
          "_" +
          this.state.framesRate,
      },
      ["OnlineTracker", "Tracker"]
    );
    this.props.preSubmit(1, this.handleSubmit);
  };

  componentDidMount() {
    if (this.props.currFile) {
      this.setState({
        fps: this.props.currFile.fps,
        framesRate: this.props.currFile.fps,
      });
      Utils.initCutWork();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currFile) {
      if (this.props.currFile.fps && prevState.fps !== this.props.currFile.fps) {
        this.setState({
          fps: this.props.currFile.fps,
          framesRate: this.props.currFile.fps,
        });
      }
    }
    if (JSON.stringify(prevProps.currFile) !== JSON.stringify(this.props.currFile)) {
      if (!this.props.currFile) {
        // this.setState({ waitingUpload: false })
        this.props.onCutWorkListChange([]);
      } else {
        Utils.initCutWork();
      }
    }
  }

  render() {
    const fps = this.state.fps < 15 ? this.state.fps : 15;
    const marks = {
      1: "1",
    };
    marks[fps] = fps;

    return (
      <div style={{ height: "100%" }}>
        <GifWorkFunction
          fps={fps}
          marks={marks}
          currFile={this.props.currFile}
          cutWorkList={this.props.cutWorkList}
          submitting={this.props.submitting}
          fileListModal={this.props.fileListModal}
          resolution={this.state.resolution}
          framesRate={this.state.framesRate}
          handleEndChange={this.handleEndChange}
          handleStartChange={this.handleStartChange}
          handleResolutionChange={this.handleResolutionChange}
          handleRateChange={this.handleRateChange}
          preSubmit={this.preSubmit}
        />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  cutWorkList: state.work.cutWorkList,
  tokenId: state.common.tokenId,
  work: state.work.work,
  startTime: state.work.startTime,
  uploading: state.work.uploading,
  processStep: state.work.processStep,
  processPercent: state.work.processPercent,
  cancelProgress: state.work.cancelProgress,
  submitting: state.work.submitting,
  fileListModal: state.common.fileListModal,
  uploadPercent: state.work.uploadPercent,
});
const mapDispatchToProps = dispatch => ({
  onFilesChange: files => dispatch(actions.onFilesChange(files)),
  onCutWorkListChange: cutWorkList => dispatch(actions.onCutWorkListChange(cutWorkList)),
  onFileListModalChange: fileListModal => dispatch(actions.onFileListModalChange(fileListModal)),
  onProcessPercentChange: processPercent => dispatch(actions.onProcessPercentChange(processPercent)),
  onProcessStepChange: processStep => dispatch(actions.onProcessStepChange(processStep)),
  onStartTimeChange: startTime => dispatch(actions.onStartTimeChange(startTime)),
  onUserActionChange: userAction => dispatch(actions.onUserActionChange(userAction)),
  onSubmittingChange: submitting => dispatch(actions.onSubmittingChange(submitting)),
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
  onPreUploadFileChange: preUploadFile => dispatch(actions.onPreUploadFileChange(preUploadFile)),
  onUploadAgainFileChange: uploadAgainFile => dispatch(actions.onUploadAgainFileChange(uploadAgainFile)),
  onUploadPercentChange: uploadPercent => dispatch(actions.onUploadPercentChange(uploadPercent)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(workplaceHOC(GifWork));
