import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Dropdown, Tooltip } from "antd";
import { ReactComponent as More } from "@/assets/icons/more.svg";
import { ReactComponent as Download } from "@/assets/icons/download.svg";
import { ReactComponent as Delete } from "@/assets/icons/delete.svg";
import { ReactComponent as Cut } from "@/assets/icons/thin/cut.svg";
import { ReactComponent as Merge } from "@/assets/icons/thin/merge.svg";
import { ReactComponent as Speed } from "@/assets/icons/thin/speed.svg";
import { ReactComponent as CropRotate } from "@/assets/icons/thin/crop-rotate.svg";
import { ReactComponent as Resize } from "@/assets/icons/thin/resize.svg";
import { ReactComponent as RemoveWatermark } from "@/assets/icons/thin/remove-watermark.svg";
import { ReactComponent as Mute } from "@/assets/icons/mute.svg";
import { ReactComponent as AddMusic } from "@/assets/icons/thin/add-music.svg";
import { ReactComponent as Subtitle } from "@/assets/icons/thin/subtitle.svg";
import { ReactComponent as AddWatermark } from "@/assets/icons/thin/add-watermark.svg";
import { ReactComponent as Reverse } from "@/assets/icons/thin/reverse.svg";
import { ReactComponent as Loop } from "@/assets/icons/thin/loop.svg";
import Utils from "@/utils/utils";

const SampleFileItemRender = ({ file, handleDownloadFile, handleDeleteFileModalVisible, handleSelectFile }) => {
  const { t } = useTranslation(["navbar", "fileList"]);
  const operation = () => {
    return (
      <div className="file-operation">
        <Download onClick={handleDownloadFile} />
        <Delete onClick={handleDeleteFileModalVisible} />
      </div>
    );
  };
  // 操作图标
  const renderSwitch = param => {
    switch (param) {
      case "cut":
        return (
          <Tooltip title={t("Cut")}>
            <Cut />
          </Tooltip>
        );
      case "merge":
        return (
          <Tooltip title={t("Merge")}>
            <Merge />
          </Tooltip>
        );
      case "speed":
        return (
          <Tooltip title={t("Speed")}>
            <Speed />
          </Tooltip>
        );
      case "crop_rotate":
        return (
          <Tooltip title={t("Crop & Rotate")}>
            <CropRotate />
          </Tooltip>
        );
      case "resize":
        return (
          <Tooltip title={t("Resize")}>
            <Resize />
          </Tooltip>
        );
      case "removeWatermark":
        return (
          <Tooltip title={t("Watermark Remover")}>
            <RemoveWatermark />
          </Tooltip>
        );
      case "mute":
        return (
          <Tooltip title={t("Extract")}>
            <Mute />
          </Tooltip>
        );
      case "addMusic":
        return (
          <Tooltip title={t("Add Music")}>
            <AddMusic />
          </Tooltip>
        );
      case "subtitle":
        return (
          <Tooltip title={t("Subtitles")}>
            <Subtitle />
          </Tooltip>
        );
      case "addWatermark":
        return (
          <Tooltip title={t("Add Watermark")}>
            <AddWatermark />
          </Tooltip>
        );
      case "reverse":
        return (
          <Tooltip title={t("Reverse")}>
            <Reverse />
          </Tooltip>
        );
      case "loop":
        return (
          <Tooltip title={t("Loop")}>
            <Loop />
          </Tooltip>
        );
      default:
        // return <span style={{ fontSize: 12, transform: "scale(0.2)" }}>{t("fileList:Original file")}</span>;
        return "";
    }
  };
  let ItemRef = React.createRef();
  return (
    <Col>
      <div className="fileItem" onClick={handleSelectFile}>
        <img src={file.thumbnail} alt="thumbnail" />
        <div className="fileItem-modal" ref={ItemRef}>
          <div className="fileItem-status">{renderSwitch(file.status)}</div>
          <Dropdown overlay={operation()} placement="topLeft" getPopupContainer={() => ItemRef.current} onClick={e => e.stopPropagation()}>
            <More className="more-btn" />
          </Dropdown>
          <div className="fileItem-info">
            <span>{file.duration}</span>
            <span>{Utils.getSize(file.size)}</span>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default SampleFileItemRender;
