import React, { Component } from "react";
import LoopWorkRender from "./children/LoopWorkRender";
import Utils from "@/utils/utils";
import moment from "moment";

export default class LoopWorkFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loopVideoDuration: "0 sec",
    };
  }

  getLoopVideoDuration = () => {
    let loopVideoDuration = (Utils.getSeconds(this.props.currFile.duration) * this.props.loopTimes).toFixed(2);
    loopVideoDuration =
      loopVideoDuration > 60
        ? moment("00:00:00", "mm:ss:SS")
            .add(loopVideoDuration, "seconds")
            .format("mm:ss:SS")
            .replace(/:([^:]*)$/, ".$1") + " min"
        : loopVideoDuration + " sec";
    this.setState({
      loopVideoDuration,
    });
  };

  componentDidMount() {
    if (this.props.currFile) {
      this.getLoopVideoDuration();
    }
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.currFile !== this.props.currFile || prevProps.loopTimes !== this.props.loopTimes) && this.props.currFile) {
      this.getLoopVideoDuration();
    }
  }

  render() {
    return (
      <LoopWorkRender
        preSubmit={this.props.preSubmit}
        handleSpeedChange={this.props.handleSpeedChange}
        currFile={this.props.currFile}
        loopTimes={this.props.loopTimes}
        submitting={this.props.submitting}
        fileListModal={this.props.fileListModal}
        handleLoopTimes={this.props.handleLoopTimes}
        loopVideoDuration={this.state.loopVideoDuration}
      />
    );
  }
}
