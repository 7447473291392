import React from "react";
import { useTranslation } from "react-i18next";
import { Input, Col, Row, Slider, Button, Upload, Tooltip } from "antd";
import { ReactComponent as Clock } from "@/assets/icons/clock.svg";
import { ReactComponent as Cut } from "@/assets/icons/thin/cut.svg";
import { AddMusicAddFileFunction } from "./addMusicAddFile/AddMusicAddFileFunction";
import Utils from "@/utils/utils";
import "@/assets/addMusicWork.scss";

// const { Option } = Select
const { Dragger } = Upload;

export const AddMusicWorkRender = ({
  currFile,
  draggerProps,
  addMusicWorkObj,
  clearAudio,
  audioStartTime,
  handleAudioStartTime,
  handleStateAudioStareTime,
  handleTrimAudioModal,
  trimAudioDuration,
  selectCurrentTime,
  changeAudioVolumn,
  changeVideoVolumn,
  handleUploadClick,
  preSubmit,
}) => {
  const { t } = useTranslation(["addMusicWork", "common"]);

  const formatter = value => {
    return `${parseInt(value * 200)}%`;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        position: "relative",
      }}
    >
      {addMusicWorkObj.guid ? (
        <>
          <button className="close" onClick={clearAudio}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                stroke="currentColor"
              />
              <path d="M9 9L15 15" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" />
              <path d="M15 9L9 15" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" />
            </svg>
          </button>
          <div className="workplace">
            <p className="title">{Utils.omissionString(addMusicWorkObj.name, 8, 8)}</p>
            <hr />
            <Row className="trimAudio" align="middle" justify="space-between" onClick={handleTrimAudioModal}>
              <Row align="middle">
                <Cut />
                <span style={{ marginLeft: ".2rem" }}>{t("Trim audio")}</span>
              </Row>
              <span className="time">{trimAudioDuration}</span>
            </Row>
            <div>
              <p>{t("Start at")}</p>
              <Row className="inputBox" justify="space-between" align="middle">
                <Col span={20}>
                  <Input bordered={false} value={audioStartTime} onBlur={handleAudioStartTime} onChange={handleStateAudioStareTime} />
                </Col>
                <Col className="inputIcon" onClick={selectCurrentTime}>
                  <Tooltip title={t("Set to current time")}>
                    <Clock />
                  </Tooltip>
                </Col>
              </Row>
            </div>
            <div>
              <p>{t("Audio volume")}</p>
              <Slider className="normal" defaultValue={0.5} min={0} max={1} step={0.01} onChange={changeAudioVolumn} tipFormatter={formatter} />
            </div>
            <div>
              <p>{t("Video volume")}</p>
              <Slider className="normal" defaultValue={0.5} min={0} max={1} step={0.01} onChange={changeVideoVolumn} tipFormatter={formatter} />
            </div>
          </div>
        </>
      ) : (
        <div className="fileList">
          <div style={{ position: "relative" }}>
            <Dragger {...draggerProps} onClick={handleUploadClick}>
              <div>
                <AddMusicAddFileFunction />
              </div>
            </Dragger>
          </div>
        </div>
      )}

      <div className="save">
        <Button type="primary" block={true} onClick={preSubmit} size="large" disabled={currFile && addMusicWorkObj.name ? false : true}>
          {t("common:Save")}
        </Button>
      </div>
    </div>
  );
};
