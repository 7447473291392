import React, { Component } from "react";
import { Drawer } from "antd";
import Utils from "@/utils/utils";
import ReactGA from "react-ga";
import { ReactComponent as Close } from "@/assets/icons/close.svg";
import FileListModalRender from "./children/FileListModalRender";
import { withTranslation } from "react-i18next";
import "@/assets/fileListModal.scss";

class FileListModalFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleDownloadClick = file => {
    Utils.downloadFile(file, file.fileType ? file.fileType : "mp4");
  };

  handleCloseClick = () => {
    if (this.props.fileListModal.type === "cutSuccess") {
      ReactGA.event(
        {
          category: "Cut",
          action: "CutBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "mergeSuccess") {
      ReactGA.event(
        {
          category: "Merge",
          action: "MergBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "gifSuccess") {
      ReactGA.event(
        {
          category: "ToGif",
          action: "GifBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "speedSuccess") {
      ReactGA.event(
        {
          category: "Speed",
          action: "SpeedBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "crop_rotateSuccess") {
      ReactGA.event(
        {
          category: "Crop&Rotate",
          action: "Crop&RotateBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "resizeSuccess") {
      ReactGA.event(
        {
          category: "Resize",
          action: "ResizeBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "removeWatermarkSuccess") {
      ReactGA.event(
        {
          category: "RemoveWatermark",
          action: "RemoveWatermarkBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "convertSuccess") {
      ReactGA.event(
        {
          category: "Convert",
          action: "ConvertBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "muteSuccess") {
      ReactGA.event(
        {
          category: "SplitAudio",
          action: "SplitAudioBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "subtitleSuccess") {
      ReactGA.event(
        {
          category: "AddText",
          action: "TextBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "addWatermarkSuccess") {
      ReactGA.event(
        {
          category: "AddMark",
          action: "AddMarkBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "addMusicSuccess") {
      ReactGA.event(
        {
          category: "AddMusic",
          action: "MusicBackEdit",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    this.props.handleClose();
  };

  handleEditNew = () => {
    this.props.handleClose();
    Utils.initWork();
    if (this.props.fileListModal.type === "cutSuccess") {
      ReactGA.event(
        {
          category: "Cut",
          action: "CutNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "gifSuccess") {
      ReactGA.event(
        {
          category: "ToGif",
          action: "GifNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "mergeSuccess") {
      ReactGA.event(
        {
          category: "Merge",
          action: "MergNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "speedSuccess") {
      ReactGA.event(
        {
          category: "Speed",
          action: "SpeedNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "crop_rotateSuccess") {
      ReactGA.event(
        {
          category: "Crop&Rotate",
          action: "Crop&RotateNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "resizeSuccess") {
      ReactGA.event(
        {
          category: "Resize",
          action: "ResizeNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "removeWatermarkSuccess") {
      ReactGA.event(
        {
          category: "RemoveWatermark",
          action: "RemoveWatermarkNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "convertSuccess") {
      ReactGA.event(
        {
          category: "Convert",
          action: "ConvertNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "muteSuccess") {
      ReactGA.event(
        {
          category: "SplitAudio",
          action: "SplitAudioNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "subtitleSuccess") {
      ReactGA.event(
        {
          category: "AddText",
          action: "TextNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "addWatermarkSuccess") {
      ReactGA.event(
        {
          category: "AddMark",
          action: "AddMarkNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    if (this.props.fileListModal.type === "addMusicSuccess") {
      ReactGA.event(
        {
          category: "AddMusic",
          action: "MusicNew",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
  };

  handleButtonListClick = type => {
    switch (type) {
      case "delete":
        this.props.handleDeleteModalShow();
        break;
      case "download":
        this.handleDownloadClick();
        break;
      case "editNew":
        this.handleEditNew();
        break;
      default:
        console.log("null");
    }
  };

  render() {
    return (
      <Drawer
        visible={this.props.fileListModal.show}
        mask
        onClose={this.handleCloseClick}
        closeIcon={<Close style={{ color: "#9F9FA1", width: 36, height: 36 }} />}
        footer={null}
        width="100%"
        height="100%"
        getContainer={this.props.fileListModal.getContainer}
        maskClosable={false}
        className="FileListModal"
        placement="bottom"
      >
        <div
          style={{
            textAlign: "center",
            maxWidth: "1268px",
            margin: "auto",
            width: "100%",
            height: "100%",
          }}
        >
          <FileListModalRender
            files={this.props.files}
            fileListModal={this.props.fileListModal}
            handleSelectAll={this.props.handleSelectAll}
            handleSelectClick={this.props.handleSelectClick}
            handleSelectToEditClick={this.props.handleSelectToEditClick}
            handleButtonListClick={this.handleButtonListClick}
            handleDeleteModalShow={this.props.handleDeleteModalShow}
            handleDownloadClick={this.handleDownloadClick}
          />
        </div>
      </Drawer>
    );
  }
}

export default withTranslation(["fileList"])(FileListModalFunction);
