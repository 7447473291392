import store from "@/store";
import { api_config } from "@/constants/api";
import Cookies from "js-cookie";
import api_domain_config from "@/constants/common";
import { accountList } from "@/constants/url";

/**
 *
 * @param {String} url 接口地址
 * @param {Object} options 请求参数
 * @param {Boolean} isNotNeedAuth 是否不需要认证信息
 * @returns
 */
export const request = (url, options, isNotNeedAuth) => {
  let controller = new AbortController(); //可用于中止fetch请求
  let HTTPUrl = url.startsWith("http") ? url : api_config.API_URL + "/" + url;
  let HTTPOptions = { method: "GET", signal: controller.signal };
  // Consolidation request
  Object.assign(HTTPOptions, options, { credentials: "include" });

  //如果没有不需要验证信息参数的话，给添加authorization认证
  if (HTTPOptions.headers && !isNotNeedAuth) {
    HTTPOptions.headers = { ...HTTPOptions.headers, authorization: store.getState().common.authorization };
  } else if (!isNotNeedAuth) {
    HTTPOptions.headers = { authorization: store.getState().common.authorization };
  }
  //GET Method: query characters are attached to url behind;
  if ((HTTPOptions.method === "GET" && options.data) || HTTPOptions.method === "HEAD") {
    let searchStr = "";
    if (options.data instanceof Object) {
      for (let i in options.data) {
        searchStr += i + "=" + options.data[i];
      }
    }
    HTTPUrl = url + "?" + searchStr;
  }
  store.dispatch({
    type: "FETCHABORT_CHANGE",
    fetchAbort: controller.abort.bind(controller),
  });

  console.log(HTTPOptions);

  return new Promise((resolve, reject) => {
    fetch(HTTPUrl, HTTPOptions)
      .then(res => {
        if (res) {
          return res.json();
        } else {
          reject(url + "_" + res.status);
        }
      })
      .then(res => {
        store.dispatch({
          type: "FETCHABORT_CHANGE",
          fetchAbort: null,
        });
        if (res.code === 401) {
          Cookies.remove("authorization", { domain: api_domain_config.DOMAIN });
          window.location.replace(`${accountList.get("accountLoginPage")}?redirect=${window.location.href}`);
        }
        resolve(res);
      })
      .catch(error => {
        if (error.name === "AbortError") {
          console.log("Fetch was aborted");
        } else {
          reject(error);
        }
      });
  });
};
