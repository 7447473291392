import React, { Component } from "react";
import { CutPreviewRender } from "./children/CutPreviewRender";
import moment from "moment";
import Utils from "@/utils/utils";

export default class CutPreviewFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soundSlide: false,
      playing: false,
      currentTime: "00:00:00",
      currentTimePercent: 0,
      currentVolume: 0,
      cacheCutTime: [],
      miniLengthLimit: false,
      maxLengthLimit: false,
      videoReady: false,
    };
  }

  handleSoundClick = () => {
    const myvideo = document.querySelector("#videoRef video");
    this.setState({
      currentVolume: myvideo.volume * 100,
      soundSlide: !this.state.soundSlide,
    });
  };

  handleSoundDrag = value => {
    const myvideo = document.querySelector("#videoRef video");
    myvideo.volume = value / 100;
    return value;
  };

  handlePlayClick = () => {
    const myvideo = document.querySelector("#videoRef video");
    if (this.state.playing) {
      myvideo.pause();
    } else {
      // 保证指针在选框范围内
      if (
        myvideo.currentTime < Utils.getSeconds(this.props.cutWorkList.filter(item => item.selected)[0].start) ||
        myvideo.currentTime >= Utils.getSeconds(this.props.cutWorkList.filter(item => item.selected)[0].end)
      ) {
        myvideo.currentTime = Utils.getSeconds(this.props.cutWorkList.filter(item => item.selected)[0].start);
      }
      myvideo.play();
      window.requestAnimationFrame(this._getCurrentTime);
    }
    this.setState({ playing: !this.state.playing });
  };

  _getCurrentTime = () => {
    if (!this.state.playing) {
      return false;
    }
    const myvideo = document.querySelector("#videoRef video");
    this.setState({
      currentTime: moment("00:00:00", "mm:ss:SS")
        .add(myvideo.currentTime, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1"),
      currentTimePercent: (myvideo.currentTime / myvideo.duration) * 100,
    });
    let endTime = Utils.getSeconds(this.props.cutWorkList.filter(item => item.selected)[0].end);
    if (endTime > myvideo.duration) {
      endTime = myvideo.duration;
    }
    if (myvideo.currentTime < endTime) {
      window.requestAnimationFrame(this._getCurrentTime);
    } else {
      if (this.props.cutWorkList.length > 0) {
        // 播放结束停留在选框开始位置
        this.setState({
          playing: false,
          currentTimePercent: this.getCutWorkPercent(this.props.cutWorkList.filter(item => item.selected)[0].start),
          currentTime: moment(this.props.cutWorkList.filter(item => item.selected)[0].start.replace(".", ":"), "mm:ss:SS")
            .format("mm:ss:SS")
            .replace(/:([^:]*)$/, ".$1"),
        });
        myvideo.currentTime = Utils.getSeconds(this.props.cutWorkList.filter(item => item.selected)[0].start);
        myvideo.pause();
      } else {
        this.setState({
          playing: false,
          currentTimePercent: 0,
          currentTime: "00:00:00",
        });
        myvideo.currentTime = 0;
      }
    }
  };

  handleCurrentTimeChange = value => {
    const myvideo = document.querySelector("#videoRef video");
    myvideo.currentTime = myvideo.duration * (value / 100);
    this.setState({
      currentTime: moment("00:00:00", "mm:ss:SS")
        .add(myvideo.currentTime, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1"),
      currentTimePercent: value,
    });
  };

  handleRangeChange = value => {
    if (this.state.playing) {
      this.handlePlayClick();
    }
    const d = Utils.getSeconds(this.props.currFile.duration);
    if ((d * value[1]) / 100 - (d * value[0]) / 100 <= 1) {
      this.setState({ miniLengthLimit: true });
      return false;
    }
    if ((d * value[1]) / 100 - (d * value[0]) / 100 > 51 && this.props.work === "gif") {
      this.setState({ maxLengthLimit: true });
      return false;
    }
    this.setState({ miniLengthLimit: false });
    this.setState({ maxLengthLimit: false });

    const start = moment("00:00:00", "mm:ss:SS")
      .add((d * value[0]) / 100, "seconds")
      .format("mm:ss:SS")
      .replace(/:([^:]*)$/, ".$1");
    const end = moment("00:00:00", "mm:ss:SS")
      .add((d * value[1]) / 100, "seconds")
      .format("mm:ss:SS")
      .replace(/:([^:]*)$/, ".$1");
    const cutWorkList = [...this.props.cutWorkList];
    let cutWork = cutWorkList.filter(item => item.selected)[0];
    cutWork.start = start;
    cutWork.end = end;
    this.props.onCutWorkListChange(cutWorkList);
  };

  getCutWorkPercent = value => {
    const d = Utils.getSeconds(this.props.currFile.duration);
    const t = Utils.getSeconds(value);
    return (t / d) * 100;
  };

  getVideoSize = () => {
    const Preview = document.querySelector(".Preview");
    const video = document.querySelector(".videoWrap video");
    const callback = () =>
      this.setState({ videoReady: true }, () => {
        console.log(video.videoWidth, video.videoHeight);
        const controls = document.querySelector(".controls");
        controls.style.width = video.clientWidth + "px";
      });
    Utils.setVideoContainerSize(Preview, video, callback);
  };

  componentDidMount() {
    this.getVideoSize();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currFile !== this.props.currFile) {
      this.getVideoSize();
      this.setState({
        soundSlide: false,
        playing: false,
        currentTime: "00:00:00",
        currentTimePercent: 0,
        currentVolume: 0,
        cacheCutTime: [],
        miniLengthLimit: false,
        maxLengthLimit: false,
        videoReady: false,
      });
    }
    if (this.props.cutWorkList !== prevProps.cutWorkList) {
      const myvideo = document.querySelector("#videoRef video");
      const cutWork = this.props.cutWorkList.filter(item => item.selected)[0];
      if (cutWork.start === this.state.cacheCutTime[0] && cutWork.end !== this.state.cacheCutTime[1]) {
        myvideo.currentTime = Utils.getSeconds(this.props.cutWorkList.filter(item => item.selected)[0].end);
        this.setState({
          currentTime: this.props.cutWorkList.filter(item => item.selected)[0].end,
          currentTimePercent: this.getCutWorkPercent(this.props.cutWorkList.filter(item => item.selected)[0].end),
        });
      } else {
        myvideo.currentTime = Utils.getSeconds(this.props.cutWorkList.filter(item => item.selected)[0].start);
        this.setState({
          currentTime: this.props.cutWorkList.filter(item => item.selected)[0].start,
          currentTimePercent: this.getCutWorkPercent(this.props.cutWorkList.filter(item => item.selected)[0].start),
        });
      }
      this.setState({
        cacheCutTime: [this.props.cutWorkList.filter(item => item.selected)[0].start, this.props.cutWorkList.filter(item => item.selected)[0].end],
      });
    }

    if (this.props.submitting && !prevProps.submitting) {
      const myvideo = document.querySelector("#videoRef video");
      if (this.state.playing) {
        myvideo.pause();
        this.setState({ playing: false });
      }
    }
  }

  render() {
    return (
      <CutPreviewRender
        videoReady={this.state.videoReady}
        currentVolume={this.state.currentVolume}
        currentTime={this.state.currentTime}
        currentTimePercent={this.state.currentTimePercent}
        cutWorkList={this.props.cutWorkList}
        currFile={this.props.currFile}
        miniLengthLimit={this.state.miniLengthLimit}
        maxLengthLimit={this.state.maxLengthLimit}
        playing={this.state.playing}
        getCutWorkPercent={this.getCutWorkPercent}
        handlePlayClick={this.handlePlayClick}
        handleSoundClick={this.handleSoundClick}
        handleSoundDrag={this.handleSoundDrag}
        handleCurrentTimeChange={this.handleCurrentTimeChange}
        handleRangeChange={this.handleRangeChange}
        soundSlide={this.state.soundSlide}
        screenshots={Utils.screenshots}
      />
    );
  }
}
