import React from "react";
import { useTranslation } from "react-i18next";
import { Breadcrumb, Slider, Tooltip } from "antd";
import { ReactComponent as Loading } from "@/assets/images/timg.svg";
import { ReactComponent as Screenshot } from "@/assets/icons/camera.svg";
import { ReactComponent as Pause } from "@/assets/icons/pause.svg";
import { ReactComponent as Play } from "@/assets/icons/play.svg";

export const RemoveWatermarkPreviewRender = ({
  videoReady,
  soundSlide,
  currFile,
  currentTime,
  currentTimePercent,
  currentVolume,
  playing,
  removeWatermarkList,
  miniLengthLimit,
  maxLengthLimit,
  handlePlayClick,
  handleSoundClick,
  handleSoundDrag,
  handleRangeChange,
  handleCurrentTimeChange,
  getRemoveWatermarkPercent,
  screenshots,
}) => {
  const { t } = useTranslation(["common", "navbar"]);

  return videoReady ? (
    <div>
      <div className="controls">
        <div className="controls-lft">{playing ? <Pause onClick={handlePlayClick} /> : <Play onClick={handlePlayClick} />}</div>
        <div className="controls-rgt">
          <Breadcrumb>
            <Breadcrumb.Item className="current-time">{currentTime}</Breadcrumb.Item>
            <Breadcrumb.Item>{currFile.duration}</Breadcrumb.Item>
          </Breadcrumb>
          <Tooltip placement="top" title={t("navbar:Screenshot")}>
            <Screenshot className="screenshot-icon" onClick={() => screenshots()} />
          </Tooltip>
        </div>
      </div>
      <div className="track">
        <div className="screenshots">
          {currFile.screenshots.map((item, index) => (
            <div
              className="screenshot"
              key={index}
              style={{ width: parseInt((document.querySelector(".PreviewContent").clientWidth - 40) / currFile.screenshots.length) + "px" }}
            >
              {item.startsWith("blob:") ? (
                <video preload="metadata" src={item} className="loaded" style={{ width: "100%", height: "100%", objectFit: "cover" }}></video>
              ) : (
                <div className="img-box" style={{ backgroundImage: `url(${item})` }}></div>
              )}
            </div>
          ))}
        </div>
        <div className="frame">
          <Slider value={currentTimePercent} tipFormatter={null} onChange={handleCurrentTimeChange} />
        </div>
        {removeWatermarkList.length > 0 && removeWatermarkList.some(value => value.selected) ? (
          <div className="range">
            <div
              className="cover"
              style={{ left: 0, width: getRemoveWatermarkPercent(removeWatermarkList.filter(item => item.selected)[0].start) + "%" }}
            ></div>
            <div
              className="cover"
              style={{
                left: getRemoveWatermarkPercent(removeWatermarkList.filter(item => item.selected)[0].end) + "%",
                width: 100 - getRemoveWatermarkPercent(removeWatermarkList.filter(item => item.selected)[0].end) + "%",
              }}
            ></div>
            <div
              className="include"
              style={{
                left: Math.round(getRemoveWatermarkPercent(removeWatermarkList.filter(item => item.selected)[0].start)) + "%",
                width:
                  Math.round(getRemoveWatermarkPercent(removeWatermarkList.filter(item => item.selected)[0].end)) -
                  Math.round(getRemoveWatermarkPercent(removeWatermarkList.filter(item => item.selected)[0].start)) +
                  "%",
              }}
            ></div>
            <Slider
              range
              value={[
                getRemoveWatermarkPercent(removeWatermarkList.filter(item => item.selected)[0].start),
                getRemoveWatermarkPercent(removeWatermarkList.filter(item => item.selected)[0].end),
              ]}
              tipFormatter={null}
              onChange={handleRangeChange}
            />
            {miniLengthLimit ? <div className="miniLengthLimit">{t("common:The minimum time length is 1s!")}</div> : null}
            {maxLengthLimit ? <div className="miniLengthLimit">{t("common:The maximum time length is 50s!")}</div> : null}
          </div>
        ) : null}
      </div>
    </div>
  ) : (
    <div style={{ textAlign: "center" }}>
      <Loading />
    </div>
  );
};
