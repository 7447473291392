import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "@/action";
import { useDrop } from "react-dnd";
import CropRotatePreviewFunction from "./children/CropRotatePreviewFunction";
import { ReactComponent as Close } from "@/assets/icons/close-circle.svg";
import ReactCrop from "react-image-crop";
import Utils from "@/utils/utils";
import "react-image-crop/dist/ReactCrop.css";

const VideoWrap = ({ currFile, crop, rotate, flip, showCrop, changeCrop, limitW, limitH, onCurrFileChange }) => {
  const [, drop] = useDrop({
    accept: "FileListItem",
    drop: () => ({ name: "CropRotateVideoWrap" }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: item => {
      if ((item.file && item.file.audiofileurl) || (item.file && item.file.status === "gif")) {
        return false;
      }
      return true;
    },
  });
  return (
    <div ref={drop} className="videoWrap" id="videoRef">
      <div style={{ width: "fit-content", height: "fit-content", margin: "auto", position: "relative" }}>
        <button className="close" onClick={() => onCurrFileChange(null)}>
          <Close />
        </button>
        <video
          className="active"
          style={{
            transform: `rotateZ(${rotate}deg) ${rotate === -90 ? " translateX(-100%)" : rotate === 90 ? "translateY(-100%)" : ""} ${
              flip === "horizontal" ? "rotateY(180deg)" : flip === "vertical" ? "rotateX(180deg)" : ""
            } ${
              flip === "horizontal" && rotate === 90
                ? "translateX(-100%)"
                : flip === "vertical" && rotate === -90
                ? "translateY(-100%)"
                : flip === "horizontal" && rotate === -90
                ? "translateX(-100%)"
                : flip === "vertical" && rotate === 90
                ? "translateY(-100%)"
                : ""
            }`,
          }}
          crossOrigin="Anonymous"
          src={currFile.videofileurl}
        >
          {/* <source src={currFile.videofileurl} type="video/mp4" /> */}
        </video>
        {showCrop ? (
          <ReactCrop
            src="https://images.hitpaw.com/images/common/logo-dark.svg"
            minWidth={limitW}
            minHeight={limitH}
            crop={crop}
            onChange={newCrop => changeCrop(newCrop)}
          />
        ) : null}
      </div>
    </div>
  );
};

class CropRotatePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoReady: false,
      limitH: 0,
      limitW: 0,
      isTimeRangeVisible: true,
    };
  }

  handleChangeCrop = crop => {
    const video = document.querySelector(".videoWrap video");
    if (this.props.rotate === 90 || this.props.rotate === -90 || this.props.rotate === -270 || this.props.rotate === 270) {
      crop.actualHeight = (crop.height / video.clientWidth) * video.videoWidth;
      crop.actualWidth = (crop.width / video.clientHeight) * video.videoHeight;
      if (crop.actualHeight < 100 || crop.actualWidth < 100 || crop.width < 1 || crop.height < 1) {
        crop.actualHeight = 100;
        crop.actualWidth = 100;
        return false;
      }
      if (crop.x < video.parentNode.clientWidth / 2 - video.clientHeight / 2) {
        crop.x = video.parentNode.clientWidth / 2 - video.clientHeight / 2;
      }
      if (crop.x > video.parentNode.clientWidth / 2 + video.clientHeight / 2 - crop.width) {
        crop.x = video.parentNode.clientWidth / 2 + video.clientHeight / 2 - crop.width;
      }
      if (crop.y < video.parentNode.clientHeight / 2 - video.clientWidth / 2) {
        crop.y = video.parentNode.clientHeight / 2 - video.clientWidth / 2;
      }
      if (crop.y > video.parentNode.clientHeight / 2 + video.clientWidth / 2 - crop.height) {
        crop.y = video.parentNode.clientHeight / 2 + video.clientWidth / 2 - crop.height;
      }
      if (crop.width > video.clientHeight) {
        crop.width = video.clientHeight;
        crop.actualWidth = video.videoHeight;
        return false;
      }
      if (crop.height > video.clientWidth) {
        crop.height = video.clientWidth;
        crop.actualHeight = video.videoWidth;
        return false;
      }
    } else {
      crop.actualHeight = (crop.height / video.clientHeight) * video.videoHeight;
      crop.actualWidth = (crop.width / video.clientWidth) * video.videoWidth;
      if (crop.actualHeight < 100 || crop.actualWidth < 100 || crop.width < 1 || crop.height < 1) {
        crop.actualHeight = 100;
        crop.actualWidth = 100;
        return false;
      }
      if (crop.x < video.parentNode.clientWidth / 2 - video.clientWidth / 2) {
        crop.x = video.parentNode.clientWidth / 2 - video.clientWidth / 2;
      }
      if (crop.x > video.parentNode.clientWidth / 2 + video.clientWidth / 2 - crop.width) {
        crop.x = video.parentNode.clientWidth / 2 + video.clientWidth / 2 - crop.width;
      }
      if (crop.y < video.parentNode.clientHeight / 2 - video.clientHeight / 2) {
        crop.y = video.parentNode.clientHeight / 2 - video.clientHeight / 2;
      }
      if (crop.y > video.parentNode.clientHeight / 2 + video.clientHeight / 2 - crop.height) {
        crop.y = video.parentNode.clientHeight / 2 + video.clientHeight / 2 - crop.height;
      }
      if (crop.width > video.clientWidth) {
        crop.width = video.clientWidth;
        crop.actualWidth = video.videoWidth;
        return false;
      }
      if (crop.height > video.clientHeight) {
        crop.height = video.clientHeight;
        crop.actualHeight = video.videoHeight;
        return false;
      }
    }
    this.props.onCropChange(crop);
    // this.getVideoSize()
  };

  getVideoSize = () => {
    const Preview = document.querySelector(".Preview");
    const video = document.querySelector(".videoWrap video");
    const callback = () => {
      const containerClientWidth = video.parentNode.clientWidth < video.clientWidth ? video.clientWidth : video.parentNode.clientWidth;
      this.props.onCropChange({
        x: containerClientWidth / 2 - video.clientWidth / 2 + video.clientWidth * 0.1,
        y: video.clientHeight * 0.1,
        width: video.clientWidth * 0.8,
        height: video.clientHeight * 0.8,
        actualWidth: 0.8 * video.videoWidth,
        actualHeight: 0.8 * video.videoHeight,
      });
      this.setState(
        {
          videoReady: true,
          limitH: (100 / video.videoHeight) * video.clientHeight,
          limitW: (100 / video.videoWidth) * video.clientWidth,
        },
        () => {
          const controls = document.querySelector(".controls");
          controls.style.width = video.clientWidth + "px";
          video.parentNode.style.width = video.clientWidth + "px";
          video.parentNode.style.height = video.clientHeight + "px";
          this.setState({
            isTimeRangeVisible: video.clientWidth < 360 ? false : true,
          });
        }
      );
    };
    Utils.setVideoContainerSize(Preview, video, callback);
    // video.style.width = "100%";
    // video.style.height = "auto";
    // video.parentNode.style.height = "auto";
    // video.load();
    // if (!video.handleLoadEvent) {
    //   video.handleLoadEvent = () => {
    //     if (video.clientHeight > Preview.clientHeight - 200) {
    //       video.style.width = "auto";
    //       video.style.height = Preview.clientHeight - 200 + "px";
    //       video.parentNode.style.height = Preview.clientHeight - 200 + "px";
    //     } else {
    //       video.parentNode.style.height = video.clientHeight + "px";
    //     }
    //     this.props.onCropChange({
    //       x: video.parentNode.clientWidth / 2 - video.clientWidth / 2 + video.clientWidth * 0.1,
    //       y: video.clientHeight * 0.1,
    //       width: video.clientWidth * 0.8,
    //       height: video.clientHeight * 0.8,
    //       actualWidth: 0.8 * video.videoWidth,
    //       actualHeight: 0.8 * video.videoHeight,
    //     });
    //     this.setState({
    //       videoReady: true,
    //       limitH: (100 / video.videoHeight) * video.clientHeight,
    //       limitW: (100 / video.videoWidth) * video.clientWidth,
    //     });
    //   };
    //   video.addEventListener("loadeddata", video.handleLoadEvent);
    // }
  };

  componentDidMount() {
    this.props.onRotateChange(0);
    this.getVideoSize();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currFile !== this.props.currFile || prevProps.work !== this.props.work) {
      this.getVideoSize();
      this.setState({
        videoReady: false,
      });
    }
    // listen rotate
    if (this.props.rotate !== prevProps.rotate) {
      this.props.onResolutionChange("custom");
      const Preview = document.querySelector(".Preview");
      const video = document.querySelector(".videoWrap video");
      if (this.props.rotate === 90 || this.props.rotate === -90 || this.props.rotate === -270 || this.props.rotate === 270) {
        // if (video.clientWidth > video.clientHeight) {
        //   video.style.width = video.parentNode.clientHeight + "px";
        //   video.style.height = "auto";
        // } else {
        //   video.style.height = video.parentNode.clientWidth + "px";
        //   if (video.clientWidth >= video.parentNode.clientHeight) {
        //     video.style.width = video.parentNode.clientHeight + "px";
        //     video.style.height = "auto";
        //   }
        // }
        // this.props.onCropChange({
        //   x: video.parentNode.clientWidth / 2 - video.clientHeight / 2,
        //   y: video.parentNode.clientHeight / 2 - video.clientWidth / 2,
        //   width: video.clientHeight,
        //   height: video.clientWidth,
        //   actualWidth: video.videoHeight,
        //   actualHeight: video.videoWidth,
        // });
        // this.setState({
        //   limitH: (100 / video.videoWidth) * video.clientWidth,
        //   limitW: (100 / video.videoHeight) * video.clientHeight,
        // });
        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;
        const limitWidth = Preview.clientWidth;
        const limitHeight = Preview.clientHeight - 350;
        console.log(limitWidth, limitWidth, videoWidth, videoHeight);
        const PreviewProportion = limitWidth / limitHeight;
        const videoProportion = videoHeight / videoWidth;
        if (videoProportion > PreviewProportion) {
          console.log("这里");
          video.style.width = limitWidth / videoProportion + "px";
          video.style.height = limitWidth + "px";
        } else {
          video.style.height = limitHeight * videoProportion + "px";
          video.style.width = limitHeight + "px";
        }
        video.style.transformOrigin = `0px 0px`;
        video.style.margin = "0";
        video.parentNode.style.height = video.clientWidth + "px";
        video.parentNode.style.width = video.clientHeight + "px";
        this.props.onCropChange({
          x: video.parentNode.clientWidth / 2 - video.clientHeight / 2,
          y: video.parentNode.clientHeight / 2 - video.clientWidth / 2,
          width: video.clientHeight,
          height: video.clientWidth,
          actualWidth: video.videoHeight,
          actualHeight: video.videoWidth,
        });
        this.setState({
          limitH: (100 / video.videoWidth) * video.clientWidth,
          limitW: (100 / video.videoHeight) * video.clientHeight,
        });
        const controls = document.querySelector(".controls");
        if (controls) {
          controls.style.width = video.clientHeight + "px";
        }
        this.setState({
          isTimeRangeVisible: video.clientHeight < 360 ? false : true,
        });
      } else {
        // video.style.width = "100%";
        // video.style.height = "auto";
        // video.parentNode.style.height = "auto";
        // if (video.clientHeight > Preview.clientHeight - 200) {
        //   video.style.width = "auto";
        //   video.style.height = Preview.clientHeight - 200 + "px";
        //   video.parentNode.style.height = Preview.clientHeight - 200 + "px";
        // } else {
        //   video.parentNode.style.height = video.clientHeight + "px";
        // }
        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;
        const limitWidth = Preview.clientWidth;
        const limitHeight = Preview.clientHeight - 350;
        const PreviewProportion = limitWidth / limitHeight;
        const videoProportion = videoWidth / videoHeight;
        if (videoProportion > PreviewProportion) {
          video.style.width = limitWidth + "px";
          video.style.height = limitWidth / videoProportion + "px";
        } else {
          video.style.height = limitHeight + "px";
          video.style.width = limitHeight * videoProportion + "px";
        }
        video.style.transformOrigin = "center";
        video.parentNode.style.height = video.clientHeight + "px";
        video.parentNode.style.width = video.clientWidth + "px";
        const controls = document.querySelector(".controls");
        if (controls) {
          controls.style.width = video.clientWidth + "px";
        }
        this.props.onCropChange({
          x: video.parentNode.clientWidth / 2 - video.clientWidth / 2,
          y: 0,
          width: video.clientWidth,
          height: video.clientHeight,
          actualWidth: video.videoWidth,
          actualHeight: video.videoHeight,
        });
        this.setState({
          limitH: (100 / video.videoHeight) * video.clientHeight,
          limitW: (100 / video.videoWidth) * video.clientWidth,
        });
      }
    }
    // listen flip
    if (this.props.flip !== prevProps.flip) {
      const video = document.querySelector(".videoWrap video");
      this.props.onResolutionChange("custom");
      if (this.props.rotate === 90 || this.props.rotate === -90 || this.props.rotate === -270 || this.props.rotate === 270) {
        this.props.onCropChange({
          x: video.parentNode.clientWidth / 2 - video.clientHeight / 2,
          y: video.parentNode.clientHeight / 2 - video.clientWidth / 2,
          width: video.clientHeight,
          height: video.clientWidth,
          actualWidth: video.videoHeight,
          actualHeight: video.videoWidth,
        });
      } else {
        this.props.onCropChange({
          x: video.parentNode.clientWidth / 2 - video.clientWidth / 2,
          y: 0,
          width: video.clientWidth,
          height: video.clientHeight,
          actualWidth: video.videoWidth,
          actualHeight: video.videoHeight,
        });
      }
    }
    //  listen resolution
    if (this.props.resolution !== prevProps.resolution) {
      const video = document.querySelector(".videoWrap video");
      if (this.props.resolution === "origin") {
        if (this.props.rotate === 90 || this.props.rotate === -90 || this.props.rotate === -270 || this.props.rotate === 270) {
          this.props.onCropChange({
            aspect: video.clientWidth / video.clientHeight,
            x: video.parentNode.clientWidth / 2 - video.clientHeight / 2,
            y: video.parentNode.clientHeight / 2 - video.clientWidth / 2,
            width: video.clientHeight,
            height: video.clientWidth,
            actualWidth: video.videoHeight,
            actualHeight: video.videoWidth,
          });
        } else {
          this.props.onCropChange({
            aspect: video.clientWidth / video.clientHeight,
            x: video.parentNode.clientWidth / 2 - video.clientWidth / 2,
            y: video.parentNode.clientHeight / 2 - video.clientHeight / 2,
            width: video.clientWidth,
            height: video.clientHeight,
            actualWidth: video.videoWidth,
            actualHeight: video.videoHeight,
          });
        }
      } else if (this.props.resolution !== "custom") {
        if (this.props.rotate === 90 || this.props.rotate === -90 || this.props.rotate === -270 || this.props.rotate === 270) {
          if (this.props.resolution >= video.videoHeight / video.videoWidth) {
            this.props.onCropChange({
              aspect: this.props.resolution,
              x: video.parentNode.clientWidth / 2 - video.clientHeight / 2 + video.clientHeight * 0.1,
              y: video.parentNode.clientHeight / 2 - video.clientWidth / 2 + video.clientWidth * 0.1,
              width: video.clientHeight * 0.8,
              height: (video.clientHeight * 0.8) / this.props.resolution,
              actualWidth: 0.8 * video.videoHeight,
              actualHeight: ((video.clientHeight * 0.8) / this.props.resolution / video.clientWidth) * video.videoWidth,
            });
          } else {
            this.props.onCropChange({
              aspect: this.props.resolution,
              x: video.parentNode.clientWidth / 2 - video.clientHeight / 2 + video.clientHeight * 0.1,
              y: video.parentNode.clientHeight / 2 - video.clientWidth / 2 + video.clientWidth * 0.1,
              width: video.clientWidth * 0.8 * this.props.resolution,
              height: video.clientWidth * 0.8,
              actualWidth: ((video.clientWidth * 0.8 * this.props.resolution) / video.clientHeight) * video.videoHeight,
              actualHeight: 0.8 * video.videoWidth,
            });
          }
        } else {
          if (this.props.resolution >= video.videoWidth / video.videoHeight) {
            this.props.onCropChange({
              aspect: this.props.resolution,
              x: video.parentNode.clientWidth / 2 - video.clientWidth / 2 + video.clientWidth * 0.1,
              y: video.parentNode.clientHeight / 2 - video.clientHeight / 2 + video.clientHeight * 0.1,
              width: video.clientWidth * 0.8,
              height: (video.clientWidth * 0.8) / this.props.resolution,
              actualWidth: 0.8 * video.videoWidth,
              actualHeight: ((video.clientWidth * 0.8) / this.props.resolution / video.clientHeight) * video.videoHeight,
            });
          } else {
            this.props.onCropChange({
              aspect: this.props.resolution,
              x: video.parentNode.clientWidth / 2 - video.clientWidth / 2 + video.clientWidth * 0.1,
              y: video.parentNode.clientHeight / 2 - video.clientHeight / 2 + video.clientHeight * 0.1,
              width: video.clientHeight * 0.8 * this.props.resolution,
              height: video.clientHeight * 0.8,
              actualWidth: ((video.clientHeight * 0.8 * this.props.resolution) / video.clientWidth) * video.videoWidth,
              actualHeight: 0.8 * video.videoHeight,
            });
          }
        }
      } else {
        const crop = { ...this.props.crop };
        crop.aspect = false;
        this.props.onCropChange(crop);
      }
    }
  }

  render() {
    return (
      <div className="CropRotatePreview">
        <VideoWrap
          currFile={this.props.currFile}
          crop={this.props.crop}
          rotate={this.props.rotate}
          flip={this.props.flip}
          showCrop={this.state.videoReady}
          changeCrop={this.handleChangeCrop}
          limitH={this.state.limitH}
          limitW={this.state.limitW}
          onCurrFileChange={this.props.onCurrFileChange}
        />
        <CropRotatePreviewFunction
          currFile={this.props.currFile}
          onCropChange={this.props.onCropChange}
          videoReady={this.state.videoReady}
          isTimeRangeVisible={this.state.isTimeRangeVisible}
          submitting={this.props.submitting}
        />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  work: state.work.work,
  crop: state.work.crop,
  rotate: state.work.rotate,
  resolution: state.work.resolution,
  flip: state.work.flip,
  submitting: state.work.submitting,
});

const mapDispatchToProps = dispatch => ({
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
  onCropChange: crop => dispatch(actions.onCropChange(crop)),
  onResolutionChange: resolution => dispatch(actions.onResolutionChange(resolution)),
  onRotateChange: rotate => dispatch(actions.onRotateChange(rotate)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(CropRotatePreview);
