export default {
  Start: "Start",
  End: "Конец",
  Save: "Сохранить",
  Cancel: "Отменить",
  Download: "Скачать",
  downloading: "скачивание",
  "Drag and drop from Files or local disk": "Перетаскивать из файлов или локального диска",
  "Click to Add File": "Нажмите, чтобы добавить файл",
  "The minimum time length is 1s!": "Минимальная продолжительность - 1s!",
  "The maximum time length is 50s!": "Максимальная продолжительность - 50s!",
  "Try Again": "Попробуйте еще раз",
  "Coming Soon": "Скоро будет",
  "Learn more": "Узнать больше",
  Yes: "Да",
  "Please click the Save button to ensure the previous editing to take effect.": "Нажмите кнопку «Сохранить», чтобы параметры вступили в силу.",
  // **************************TipsModal Message Title*****************************
  "Cut 10 video fragments at most. Get HitPaw Toolkit for more features.":
    "Вырезать не более 10 фрагментов видео. Получите HitPaw Toolkit для получения дополнительных функций.",
  "Max file size for videos is 20 MB. Get HitPaw Toolkit for big file.":
    "Размер файла не может быть больше 20MB. Получите HitPaw Toolkit для получения дополнительных функций.",
  "Max file size for videos is 20 MB. Get HitPaw Watermark Remover for big file.":
    "Размер файла не может быть больше 20MB. Получите HitPaw Watermark Remover для получения дополнительных функций.",
  "The minimum time length is 1s! Get HitPaw Toolkit for more features.":
    "Минимальная продолжительность - 1s! Получите HitPaw Toolkit для получения дополнительных функций.",
  "Add 3 videos at most. Get HitPaw Toolkit for more features.":
    "Добавить не более 3 видео. Получите HitPaw Toolkit для получения дополнительных функций.",
  "This format is not supported currently.": "Этот формат в настоящее время не поддерживается.",
  "This format is not supported currently. Get HitPaw Toolkit for more formats":
    "Этот формат в настоящее время не поддерживается. Получите HitPaw Toolkit для получения дополнительных функций.",
  "This format is not supported currently. Get HitPaw Watermark Remover for more formats":
    "Этот формат в настоящее время не поддерживается. Получите HitPaw Watermark Remover для получения дополнительных функций.",
  "Processing failed. Get HitPaw Toolkit for stable processing.": "Не удалось создать видео!",
  "Failed to load!  Get HitPaw Watermark Remover for stable processing.": "Ошибка загрузки!",
  "Are you sure you want to delete it?": "Вы уверены, что хотите это удалить?",
  "Failed to extract the audio!": "Не удалось извлечь аудио!",
  "Video link copied": "Ссылка на видео скопирована",
  "Cut 10 video fragments at most.": "Вырезать не более 10 фрагментов видео. ",
  "Max file size for videos is 20 MB.": "Размер файла не может превышать 20 Мб. ",
  "Max file size for videos is 100 MB.": "Размер файла не может превышать 100 Мб. ",
  "Failed to load!": "Ошибка загрузки!",

  //******************************2021.05.17 v1.3.0 *******************************/
  "Screenshots downloaded.": "Скриншоты загружены.",
  "Online service, no download required": "Онлайн-сервис, не требуется скачать",
  "Add up to 20MB file": "Добавьте файл размером до 20 МБ",
  "Support common formats": "Поддерживает общие форматы",
  "Best for general editing requirements": "Лучше для общих требований редактирования",
  "0 second wait": "0 секунд ожидания",
  "No size limits": "Никаких ограничений по размеру",
  "Support 1000+ formats": "Поддержка более 1000 форматов",
  "Stable processing": "Стабильная обработка",
  "Get access to HitPaw Toolkit!": "Получите доступ к инструментарию HitPaw!",
  "Support cut, crop & rotate, adjust, speed, add music, stop motion, etc.":
    "Поддержка вырезки, обрезки и поворота, регулировки, скорости, добавления музыки, остановки движения и т.д.",
  "The easiest video editing software for beginners": "Самое простое ПО для редактирования видео для начинающих",
  "Remove watermark from videos and images in simple clicks": "Удаление водяных знаков с видео и изображений простым щелчком мыши",
  "TRY IT FREE": "ПОПРОБУЙТЕ БЕСПЛАТНО",
  "BUY NOW": "КУПИТЬ СЕЙЧАС",

  //******************************2021.09.06 v1.5.0********************************/
  "Add Video": "Добавить видео",
  More: "Более",
  "History files": "Файлы истории",
  Share: "Делиться",
  Duration: "Продолжительность",
  "Download all": "Скачать все",
  "Download selected": "Скачать выбранное",
  "Add Audio": "Добавить аудио",

  //********************************2021.10.19 v1.6.0*********************************/
  "GIF does not support editing. Do you want to convert the MP4 for editing?":
    "GIF не поддерживает редактирование. Вы хотите конвертировать в MP4 для редактирования?",
  "Go to Edit": "Перейти к редактированию",
  "Download GIF": "Скачать GIF",

  //********************************2021.10.19 v1.6.1*********************************/
  "HitPaw Video Editor": "HitPaw Video Editor",
  "No video size limits": "Нет ограничений по размеру видео.",
  "Cut/crop/rotate/resize/merge video easily": "Легко вырезать/обрезать/поворачивать/изменять размер/объединять видео.",
  "Tons of video effects are available for your video creativity": "Множество видеоэффектов доступно для вашего творчества о видео.",
  "Unlimited tracks allow you to add multiple audios as you want":
    "Неограниченное количество треков позволяет добавлять несколько аудио по вашему желанию.",

  //********************************2021.11.02 v1.6.1*********************************/
  "Download HitPaw Video Editor for more features.": "Скачайте HitPaw  Video Editor для получения дополнительных функций.",
  "Get HitPaw Watermark Remover for more features.": "Получите HitPaw Watermark Remover для получения дополнительных функций.",
  "Get HitPaw Toolkit for more features.": "Получите HitPaw Toolkit для получения дополнительных функций.",
  "Add 3 videos at most.": "Добавьте не более 3 видео. ",
  "Processing failed.": "Обработка не удалась. ",
  "The uploaded video is less than 1 second.": "Загруженное видео меньше 1 секунда. ",
  "The video format is not supported.": "Формат видео не поддерживается.",
  "The audio format is not supported.": "Формат аудио не поддерживается. ",
  "AddFileByURL.largerSize.HitPawToolkit":
    "Видео успешно проанализировано!Однако в настоящее время мы не поддерживаем обработку файлов размером более 100 МБ. <1><0>Загрузите видео</0></1> и используйте Toolkit для его обработки.",
  "AddFileByURL.largerSize.HitPawWatermarkRemover":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Watermark Remover to proceed.",
  "AddFileByURL.largerSize.HitPawVideoEditor":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Video Editor to proceed.",
};
