export default {
  'Image': 'Afbeelding',
  'Text': 'Tekst',
  'Add image': 'Afbeelding toevoegen',
  'Opacity': 'Ondoorzichtigheid',
  "Add text": 'Tekst toevoegen',
  'Text Color': 'Tekst kleur',
  'Outline': 'Outline',
  'Background': 'Achtergrond',
  'Text here': 'Tekst hier',
}