import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "@/action";
import { useDrop } from "react-dnd";
import Draggable from "react-draggable";
import ResizePreviewFuntion from "./children/ResizePreviewFunction";
import { ReactComponent as Close } from "@/assets/icons/close-circle.svg";

// import Utils from '@/utils/utils'

const VideoWrap = ({ currFile, videoBgColor, handleVideoDrag, handleVideoStop, DragPosition, filling, onCurrFileChange }) => {
  const [, drop] = useDrop({
    accept: "FileListItem",
    drop: () => ({ name: "ResizeVideoWrap" }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: item => {
      if ((item.file && item.file.audiofileurl) || (item.file && item.file.status === "gif")) {
        return false;
      }
      return true;
    },
  });
  return (
    <div ref={drop} className="videoWrap" id="videoRef">
      <button className="close" onClick={() => onCurrFileChange(null)}>
        <Close />
      </button>
      <div
        className={`videoWrap-box ${videoBgColor.indexOf("bg") !== -1 ? videoBgColor : ""}`}
        style={{
          overflow: "hidden",
          margin: "0 auto",
          position: "relative",
          width: "100%",
          height: "100%",
          backgroundColor: videoBgColor.indexOf("blur") !== -1 && videoBgColor.indexOf("bg") === -1 ? null : videoBgColor,
        }}
      >
        <Draggable
          handle={filling === "fill" ? ".resizeVideoWrap" : "null"}
          position={{ x: DragPosition.x, y: DragPosition.y }}
          grid={[1, 1]}
          scale={1}
          onDrag={handleVideoDrag}
          onStop={handleVideoStop}
        >
          <div className="resizeVideoWrap active">
            <video crossOrigin="Anonymous">
              <source src={currFile.videofileurl} type="video/mp4" />
            </video>
            <video
              crossOrigin="Anonymous"
              muted
              style={{
                zIndex: 1,
                filter: videoBgColor === "blur1" ? "blur(3px)" : videoBgColor === "blur2" ? "blur(6px)" : "blur(8px)",
                opacity: videoBgColor.indexOf("blur") !== -1 ? 1 : 0,
              }}
            >
              <source src={currFile.videofileurl} type="video/mp4" />
            </video>
          </div>
        </Draggable>
      </div>
    </div>
  );
};

class ResizePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoReady: false,
      DragVideoPosition: {
        x: 0,
        y: 0,
      },
      isTimeRangeVisible: true,
    };
    this.resizePreviewRef = null;
  }

  getVideoWrapBoxSize = () => {
    const Preview = document.querySelector(".Preview");
    const videoWrapBox = document.querySelector("#videoRef .videoWrap-box");
    const myvideo = document.querySelectorAll("#videoRef .resizeVideoWrap video");
    if (Preview.clientWidth >= Preview.clientHeight - 200 && Preview.clientWidth / this.props.resolution <= Preview.clientHeight - 200) {
      videoWrapBox.style.width =
        parseInt(Preview.clientWidth) % 2 === 0 ? parseInt(Preview.clientWidth) + "px" : parseInt(Preview.clientWidth) + 1 + "px";
      videoWrapBox.style.height = parseInt(Preview.clientWidth / this.props.resolution) + "px";
    } else {
      videoWrapBox.style.height = parseInt(Preview.clientHeight - 200) + "px";
      videoWrapBox.style.width =
        parseInt((Preview.clientHeight - 200) * this.props.resolution) % 2 === 0
          ? parseInt((Preview.clientHeight - 200) * this.props.resolution) + "px"
          : parseInt((Preview.clientHeight - 200) * this.props.resolution) + 1 + "px";
    }
    myvideo.forEach(item => {
      item.load();
    });
    if (!myvideo[0].handleLoadEvent) {
      myvideo[0].handleLoadEvent = e => {
        const videoWidth = e.target.videoWidth;
        const videoHeight = e.target.videoHeight;
        if (videoWidth / videoHeight >= this.props.resolution) {
          if (this.props.filling === "fit") {
            myvideo[0].style.width = "100%";
            myvideo[0].style.height = "auto";
            myvideo[1].style.width = "auto";
            myvideo[1].style.height = "100%";
          } else {
            myvideo[0].style.width = "auto";
            myvideo[0].style.height = "100%";
            myvideo[1].style.width = "100%";
            myvideo[1].style.height = "auto";
          }
        } else {
          if (this.props.filling === "fit") {
            myvideo[0].style.width = "auto";
            myvideo[0].style.height = "100%";
            myvideo[1].style.width = "100%";
            myvideo[1].style.height = "auto";
          } else {
            myvideo[0].style.width = "100%";
            myvideo[0].style.height = "auto";
            myvideo[1].style.width = "auto";
            myvideo[1].style.height = "100%";
          }
        }
        this.setState({ videoReady: true }, () => {
          const controls = document.querySelector(".controls");
          controls.style.width = videoWrapBox.clientWidth + "px";
          this.setState({
            isTimeRangeVisible: videoWrapBox.clientWidth < 360 ? false : true,
          });
        });
        if (this.resizePreviewRef.state.playing) {
          this.resizePreviewRef.handlePlayClick(true);
        }
      };
      myvideo[0].addEventListener("loadeddata", myvideo[0].handleLoadEvent);
    }
  };

  handleVideoDrag = (e, ui) => {
    const video = document.querySelectorAll("#videoRef .resizeVideoWrap video")[0];
    const limitX = video.clientWidth / 2 - video.parentNode.clientWidth / 2;
    const limitY = video.clientHeight / 2 - video.parentNode.clientHeight / 2;
    console.log(video.clientWidth, video.parentNode.clientWidth, limitX, limitY);
    const { x, y } = this.state.DragVideoPosition;
    const obj = {};
    if (x + ui.deltaX > limitX || x + ui.deltaX < -limitX) {
      obj.x = x;
    } else {
      obj.x = x + ui.deltaX;
    }
    if (y + ui.deltaY > limitY || y + ui.deltaY < -limitY) {
      obj.y = y;
    } else {
      obj.y = y + ui.deltaY;
    }
    this.setState({
      DragVideoPosition: obj,
    });
  };

  handleVideoStop = () => {
    this.props.onDragVideoPositionChange(this.state.DragVideoPosition);
  };

  componentDidMount() {
    this.getVideoWrapBoxSize();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currFile !== this.props.currFile) {
      this.getVideoWrapBoxSize();
      this.setState({ DragVideoPosition: { x: 0, y: 0 } });
    }
    if (prevProps.resolution !== this.props.resolution) {
      this.getVideoWrapBoxSize();
      this.setState({ DragVideoPosition: { x: 0, y: 0 } });
    }
    if (prevProps.filling !== this.props.filling) {
      this.getVideoWrapBoxSize();
      this.setState({ DragVideoPosition: { x: 0, y: 0 } });
    }
  }

  render() {
    console.log("DragPosition", this.state.DragVideoPosition);
    return (
      <div className="ResizePreview">
        <VideoWrap
          currFile={this.props.currFile}
          videoBgColor={this.props.videoBgColor}
          DragPosition={this.state.DragVideoPosition}
          handleVideoDrag={this.handleVideoDrag}
          handleVideoStop={this.handleVideoStop}
          filling={this.props.filling}
          onCurrFileChange={this.props.onCurrFileChange}
        />
        <ResizePreviewFuntion
          onRef={ref => {
            this.resizePreviewRef = ref;
          }}
          videoReady={this.state.videoReady}
          handleVideoStop={this.handleVideoStop}
          currFile={this.props.currFile}
          isTimeRangeVisible={this.state.isTimeRangeVisible}
          submitting={this.props.submitting}
        />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  cutWorkList: state.work.cutWorkList,
  work: state.work.work,
  resolution: state.work.resolution,
  videoBgColor: state.work.videoBgColor,
  filling: state.work.filling,
  submitting: state.work.submitting,
});

const mapDispatchToProps = dispatch => ({
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
  onDragVideoPositionChange: dragVideoPosition => dispatch(actions.onDragVideoPositionChange(dragVideoPosition)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(ResizePreview);
