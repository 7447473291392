import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import Utils from "@/utils/utils";
import { Row, Col } from "antd";
import { ReactComponent as Close } from "@/assets/icons/close.svg";

export const MergeItem = ({ mergeWork, index, active, handleMergeItemClick, removeMergeWork, orderMergeList, setCurrFile }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: "MergeItem",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      orderMergeList(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop: () => ({ name: "MergeItem" }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: item => {
      if ((item.file && item.file.audiofileurl) || (item.file && item.file.status === "gif")) {
        return false;
      }
      return true;
    },
  });

  const [, drag] = useDrag({
    item: { index, type: "MergeItem" },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult && dropResult.name === "MergeItem") {
        setCurrFile();
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));
  return (
    <div
      ref={ref}
      className={"item " + (active ? "active" : "")}
      onClick={() => {
        handleMergeItemClick(mergeWork, index);
      }}
    >
      <Row align="middle">
        <Col span={8}>
          {mergeWork.thumbnail.startsWith("blob:") ? (
            <div className="img-box">
              <video
                preload="metadata"
                src={mergeWork.thumbnail}
                className="img"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              ></video>
            </div>
          ) : (
            <div className="img-box" style={{ backgroundImage: `url(${mergeWork.thumbnail})` }}></div>
          )}
        </Col>
        <Col span={16}>
          <div className="name">{mergeWork.name}</div>
          <div className="info">
            <div>{Utils.getSize(mergeWork.size)}</div>
            <div>{mergeWork.duration}</div>
          </div>
        </Col>
      </Row>
      <button
        className="close"
        onClick={e => {
          removeMergeWork(e, index);
        }}
      >
        <Close />
      </button>
    </div>
  );
};
