import React from "react";
import { useTranslation } from "react-i18next";
import { Upload, Button } from "antd";
import { useDrop } from "react-dnd";
import AddFileByURL from "./addFileByUrl/AddFileByURL";
import SampleFilesRender from "./sampleFiles/SampleFilesRender";
const { Dragger } = Upload;

const UploadAddFile = ({ files, onCurrFileChange, requsetDeleteAnonymousFileList, onFilesChange, onFileListModalChange }) => {
  const [, drop] = useDrop({
    accept: "FileListItem",
    drop: () => ({ name: "UploadAddFile" }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: item => {
      if ((item.file && item.file.audiofileurl) || (item.file && item.file.status === "gif")) {
        return false;
      }
      return true;
    },
  });
  const { t } = useTranslation(["upload", "common"]);

  return (
    <div ref={drop} className="addFile-box">
      <div>
        <p className="ant-upload-text">{t("common:Drag and drop from Files or local disk")}</p>
        <Button type="primary" size="large">
          {t("Choose File")}
        </Button>
      </div>
      <AddFileByURL />
      <SampleFilesRender
        files={files}
        onCurrFileChange={onCurrFileChange}
        onFilesChange={onFilesChange}
        onFileListModalChange={onFileListModalChange}
        requsetDeleteAnonymousFileList={requsetDeleteAnonymousFileList}
      />
    </div>
  );
};

export const AddFileRender = ({
  draggerProps,
  requsetDeleteAnonymousFileList,
  handleUploadClick,
  files,
  onCurrFileChange,
  onFilesChange,
  onFileListModalChange,
}) => {
  return (
    <Dragger {...draggerProps} onClick={handleUploadClick}>
      <UploadAddFile
        files={files}
        onCurrFileChange={onCurrFileChange}
        onFilesChange={onFilesChange}
        onFileListModalChange={onFileListModalChange}
        requsetDeleteAnonymousFileList={requsetDeleteAnonymousFileList}
      />
    </Dragger>
  );
};
