import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "@/action";
import Navbar from "./navbar/Navbar";
import FileList from "./fileList/FileList";
import PreviewContent from "./previewContent/PreviewContent";
import WorkPlace from "./workplace/WorkPlace";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ReactGA from "react-ga";
import Utils from "@/utils/utils";
import { ReactComponent as Logo } from "@/assets/icons/logo-title.svg";
import { ReactComponent as Loadding } from "@/assets/images/timg.svg";
import { Drawer } from "antd";
import PurchaseModal from "@/components/common/purchaseModal/PurchaseModal";
import { requsetGetAnonymousSessions, requsetGetProfile, requsetGetSubscription, requsetGetAppearance } from "@/api/apis";
import Cookies from "js-cookie";
import api_domain_config from "@/constants/common";
import "@/assets/home.scss";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collspan: false,
      tipsClosed: false,
      show: false,
      init: false,
    };
    // this.navbarRef = React.createRef();
  }

  handleCollspanClick = e => {
    if (this.state.collspan) {
      document.querySelector(".Stage .center").style.maxWidth = "calc(100% - 450px)";
    } else {
      document.querySelector(".Stage .center").style.maxWidth = "calc(100% - 280px)";
    }
    this.setState({ collspan: !this.state.collspan });
  };

  childrenHandleClose = value => {
    this.setState({ tipsClosed: value });
  };

  componentDidMount() {
    this.handleInitUserInfo();
    // this.getToken();
    this.handleInitWork();
    const timeOut = setTimeout(() => {
      this.setState({ init: true }); //触发重新渲染，确保能得到this.navbarRef.current的高
      clearTimeout(timeOut);
    }, 300);
  }

  /**
   * 用户进来本页面，登录流程
   */
  handleInitUserInfo = () => {
    //如果当前内存中没有 authorization, 则是说明没有登录，直接获取token用作路人身份
    if (!Cookies.get("authorization")) {
      this.getToken();
      window.Storage.remove("theme");
      this.props.onThemeChange("dark");
    } else {
      if (Cookies.get("anonymousAuth")) {
        Cookies.remove("anonymousAuth", { domain: api_domain_config.DOMAIN });
        Cookies.remove("videoEditorTimes", { domain: api_domain_config.DOMAIN });
      }
      this.getAppearance();
      this.getProfile();
      this.getSubscription();
    }
  };

  /**
   * 获取url中的参数，设置当前work区块
   */
  handleInitWork = () => {
    let currWork = "";
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("work") && !urlParams.has("blob")) {
      currWork = urlParams.get("work");
      // window.history.replaceState({}, document.title, window.location.origin);
      // window.history.replaceState({}, document.title, window.location.pathname);
      window.history.replaceState({}, document.title, window.location.origin + window.location.pathname);
    } else {
      currWork = localStorage.getItem("currWork");
    }
    if (currWork) {
      this.props.onWorkChange(currWork);
      localStorage.setItem("currWork", currWork);
    }
    ReactGA.event(
      {
        category: "FuncRate",
        action: "PageEnter",
        label: Utils.getLanguage() + "_" + (currWork || this.props.work),
      },
      ["OnlineTracker", "Tracker"]
    );
  };

  /**
   * 请求获取用户个人信息
   */
  getProfile = () => {
    requsetGetProfile()
      .then(res => {
        if (res.code === 200) {
          this.setState({ show: true });
          this.props.onProfileObjChange(res.data);
        } else {
          // localStorage.removeItem("authorization");
          this.getToken();
        }
      })
      .catch(err => {
        this.getToken();
      });
  };

  /**
   * 请求获取用户订阅信息
   */
  getSubscription = () => {
    requsetGetSubscription()
      .then(res => {
        if (res.code === 200) {
          this.props.onSubscriptionChange(res.data.subscription);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  /**
   * 请求获取用户主题信息
   */
  getAppearance = () => {
    requsetGetAppearance()
      .then(res => {
        if (res.code === 200) {
          let theme = res.data.appearance === "Light" ? "light" : "dark";
          document.documentElement.setAttribute("data-theme", theme);
          window.Storage.set("theme", theme);
          this.props.onThemeChange(theme);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  /**
   * 获取Token
   */
  getToken = () => {
    if (!Cookies.get("anonymousAuth")) {
      requsetGetAnonymousSessions()
        .then(res => {
          Cookies.set("anonymousAuth", res.data.authorization, { expires: 1, domain: api_domain_config.DOMAIN });
          Cookies.set("videoEditorTimes", 0, { expires: 1, domain: api_domain_config.DOMAIN });
          this.props.onAuthorizationChange(res.data.authorization);
          this.props.onVideoEditorTimesChange(0);
          this.setState({ show: true });
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      this.props.onTokenIdChange(window.Storage.get("tokenId"));
      this.setState({ show: true });
    }
  };

  render() {
    return (
      <div className="Home">
        <div className="Mobile-top-navbar">
          <a href={Utils.getHitPawLink()}>
            <svg style={{ display: "block" }} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 10L13 18L21 26" stroke="currentColor" strokeOpacity="0.8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </a>
          <a href={Utils.getHitPawHomeLink()} className="logo">
            <Logo style={{ display: "block" }} />
          </a>
          <a
            href={
              this.props.work === "removeWatermark"
                ? Utils.getHitPawWatermarkRemoverEXELink()
                : window.userClient.platform === "win"
                ? Utils.getHitPawVideoEditorEXELink()
                : Utils.getHitPawToolKitEXELink()
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="10" y="11" width="20" height="15" rx="1" stroke="currentcolor" strokeWidth="1.86667" />
              <path
                d="M19.5 15.5V21.5M19.5 21.5L17 19M19.5 21.5L22 19"
                stroke="currentcolor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M26 31L14 31" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" />
            </svg>
          </a>
        </div>
        <Navbar />
        <DndProvider backend={HTML5Backend}>
          {this.state.show ? (
            <div className="Stage">
              <div>
                <FileList />
              </div>
              <div className={`center ${this.props.work && !this.props.successFileGuids.length ? "open" : ""}`}>
                <PreviewContent />
              </div>
              {document.body.clientWidth <= 992 ? (
                <div className="right" style={this.props.prevWork ? { display: "none" } : {}}>
                  <WorkPlace />
                </div>
              ) : (
                <Drawer
                  visible={this.props.work}
                  closable={false}
                  mask={false}
                  getContainer={false}
                  style={this.props.successFileGuids.length ? { display: "none" } : {}}
                  className="pc-workplace"
                  placement={this.props.lang === "ar" ? "left" : "right"}
                >
                  <div className="right" style={this.props.prevWork ? { display: "none" } : {}}>
                    <WorkPlace />
                  </div>
                </Drawer>
              )}
            </div>
          ) : (
            <div className="Loadding-cont">
              <Loadding />
            </div>
          )}
          {this.props.purchaseModalObj && this.props.purchaseModalObj.visible ? <PurchaseModal /> : null}
        </DndProvider>
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  work: state.work.work,
  prevWork: state.work.prevWork,
  successFileGuids: state.files.successFileGuids,
  authorization: state.common.authorization,
  theme: state.common.theme,
  purchaseModalObj: state.common.purchaseModalObj,
});
const mapDispatchToProps = dispatch => ({
  onWorkChange: work => dispatch(actions.onWorkChange(work)),
  onTokenIdChange: tokenId => dispatch(actions.onTokenIdChange(tokenId)),
  onAuthorizationChange: authorization => dispatch(actions.onAuthorizationChange(authorization)),
  onProfileObjChange: profileObj => dispatch(actions.onProfileObjChange(profileObj)),
  onSubscriptionChange: subscription => dispatch(actions.onSubscriptionChange(subscription)),
  onThemeChange: theme => dispatch(actions.onThemeChange(theme)),
  onVideoEditorTimesChange: videoEditorTimes => dispatch(actions.onVideoEditorTimesChange(videoEditorTimes)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(Home);
