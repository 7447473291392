export default {
  'Image': 'Imagem',
  'Text': 'Texto',
  'Add image': 'Adicionar imagem',
  'Opacity': 'Opacidade',
  "Add text": 'Adicionar texto',
  'Text Color': 'Cor de texto',
  'Outline': 'Contorno',
  'Background': 'Fundo',
  'Text here': 'Texto aqui',
}