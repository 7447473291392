import React, { Component } from "react";
import { connect } from "react-redux";
import { workplaceHOC } from "../WorkplaceHOC";
import { request } from "@/api/request";
import ReverseWorkFunction from "./children/ReverseWorkFunction";
import actions from "@/action";
import Utils from "@/utils/utils";
import ReactGA from "react-ga";
import "@/assets/reverseWork.scss";

class ReverseWork extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * 改变视频播放速度
   * @param {*} speed 速度
   */
  handleSpeedChange = speed => {
    const reverseObj = { ...this.props.reverseObj };
    reverseObj.speed = speed;
    this.props.onReverseObjChange(reverseObj);
  };

  /**
   * 改变视频是否静音
   * @param {*} mute 是否静音
   */
  handleMuteChange = mute => {
    const reverseObj = { ...this.props.reverseObj };
    reverseObj.mute = mute;
    this.props.onReverseObjChange(reverseObj);
  };

  handleSubmit = async () => {
    const startTime = { ...this.props.startTime };
    startTime.reverse = new Date();
    this.props.onStartTimeChange(startTime);
    if (!this.props.uploading && this.props.uploadPercent < 100) {
      this.props.onProcessPercentChange(1);
      this.props.onProcessStepChange(1);
    }
    let reverseResponse;
    try {
      reverseResponse = await this.getSpeedVideo();
    } catch (error) {
      this.props.handleSubmitFailModal(true);
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "Video_Reverse",
          action: "Reverse_fail",
          label: error + "_none_none_" + this.props.tokenId + "_none_none",
        },
        ["OnlineTracker", "Tracker"]
      );
      return false;
    }
    if (reverseResponse.code === 200) {
      let guids = [];
      try {
        guids = await this.props.getNewVideoByProgress("reverse", reverseResponse);
      } catch (error) {
        if (error !== "cancel") {
          ReactGA.event(
            {
              category: "Video_Reverse",
              action: "Reverse_fail",
              label: "changeSpeed_200_200_" + error,
            },
            ["OnlineTracker", "Tracker"]
          );
        }
        return false;
      }
      this.props.onSuccessFileGuidsChange(guids);
      this.props.onPrevWorkChange("reverse");
      this.props.onUploadPercentChange(0);
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "Video_Reverse",
          action: "Reverse_success",
        },
        ["OnlineTracker", "Tracker"]
      );
    } else {
      if (reverseResponse.code === 402102) {
        this.props.onPurchaseModalObjChange({
          visible: true,
          type: "videoEditorTimesMoreThanLimit",
        });
      } else {
        this.props.handleSubmitFailModal(true);
      }
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "Video_Reverse",
          action: "Reverse_fail",
          label: "changeSpeed_200_" + reverseResponse.code + "_none_" + this.props.tokenId + "_none_" + reverseResponse.msg,
        },
        ["OnlineTracker", "Tracker"]
      );
    }
  };

  preSubmit = () => {
    ReactGA.event(
      {
        category: "Video_Reverse",
        action: "Reverse_save",
        label: this.props.reverseObj.speed,
      },
      ["OnlineTracker", "Tracker"]
    );
    //函数返回true，则是当前已经超过限制
    let flag = this.props.handleVideoEditorTimes();
    if (!flag) {
      this.props.preSubmit(1, this.handleSubmit);
    }
  };

  /**
   * 请求视频调速接口
   * @returns 返回请求后的promise
   */
  getSpeedVideo = () => {
    const formData = new FormData();
    formData.append("speed", this.props.reverseObj.speed);
    formData.append("mute_type", this.props.reverseObj.mute ? 1 : 0);
    formData.append("file_url", this.props.currFile.reverseVideourl);
    formData.append("token", this.props.tokenId);
    return new Promise((resolve, reject) => {
      request("changeSpeed", {
        method: "post",
        body: formData,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  /**
   * 请求视频调速接口
   * @returns 返回请求后的promise
   */
  getSpeedVideo = () => {
    const formData = new FormData();
    formData.append("speed", this.props.reverseObj.speed);
    formData.append("mute_type", this.props.reverseObj.mute ? 1 : 0);
    formData.append("file_url", this.props.currFile.reverseVideourl);
    formData.append("token", this.props.tokenId);
    return new Promise((resolve, reject) => {
      request("changeSpeed", {
        method: "post",
        body: formData,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  /**
   * 请求视频倒数接口
   * @returns 返回请求后的promise
   */
  getReverseVideo = url => {
    const formData = new FormData();
    formData.append("speed", 1);
    formData.append("mute_type", 0);
    formData.append("file_url", url);
    formData.append("token", this.props.tokenId);
    return new Promise((resolve, reject) => {
      request("reverse", {
        method: "post",
        body: formData,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  handleReverseVideo = async () => {
    this.props.onProcessPercentChange(1);
    this.props.onProcessStepChange(1);
    this.props.onSubmittingChange(true);
    let reverseResponse = await this.getReverseVideo(this.props.currFile.videofileurl);
    if (reverseResponse.code === 200) {
      const number = reverseResponse.data.result.number;
      let progressResponse;
      try {
        progressResponse = await Utils.getProgress(number, false, true);
      } catch (error) {
        if (error !== "cancel") {
          ReactGA.event(
            {
              category: "Video_Reverse",
              action: "Reverse_fail",
              label: "reverse_200_200_" + error,
            },
            ["OnlineTracker", "Tracker"]
          );
        }
        this.props.onProcessPercentChange(0);
        this.props.onProcessStepChange(0);
        this.props.onSubmittingChange(false);
        return false;
      }
      const files = [...this.props.files];
      const currFile = { ...this.props.currFile };
      currFile.reverseVideourl = progressResponse.info[0].url;
      files.forEach(item => {
        if (item.guid === currFile.guid) {
          item.reverseVideourl = currFile.reverseVideourl;
        }
      });
      this.props.onCurrFileChange(currFile);
      this.props.onFilesChange(files);
      localStorage.setItem("files", JSON.stringify(files));
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
    } else {
      ReactGA.event(
        {
          category: "Video_Reverse",
          action: "Reverse_fail",
          label: "reverse_200_" + reverseResponse.code + "_none_" + this.props.tokenId + "_none_" + reverseResponse.msg,
        },
        ["OnlineTracker", "Tracker"]
      );
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
    }
  };

  componentDidMount() {
    if (this.props.currFile && !this.props.currFile.reverseVideourl && !this.props.uploading) {
      this.handleReverseVideo();
    }
    //重置配置项
    this.props.onReverseObjChange({
      speed: 1,
      mute: true,
    });
  }

  componentDidUpdate(prevProps) {
    //重置配置项
    if (this.props.currFile !== prevProps.currFile || this.props.work !== prevProps.work) {
      this.props.onReverseObjChange({
        speed: 1,
        mute: true,
      });
    }
    //请求倒放视频
    if (
      this.props.currFile !== prevProps.currFile &&
      this.props.currFile &&
      !this.props.currFile.reverseVideourl &&
      !this.props.submitting &&
      !this.props.uploading
    ) {
      this.handleReverseVideo();
    }
  }

  render() {
    return (
      <div style={{ height: "100%" }}>
        <ReverseWorkFunction
          preSubmit={this.preSubmit}
          currFile={this.props.currFile}
          reverseObj={this.props.reverseObj}
          submitting={this.props.submitting}
          fileListModal={this.props.fileListModal}
          handleSpeedChange={this.handleSpeedChange}
          handleMuteChange={this.handleMuteChange}
          processModalVisible={this.props.processModalVisible}
        />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  tokenId: state.common.tokenId,
  work: state.work.work,
  startTime: state.work.startTime,
  reverseObj: state.work.reverseObj,
  uploading: state.work.uploading,
  processStep: state.work.processStep,
  processPercent: state.work.processPercent,
  cancelProgress: state.work.cancelProgress,
  submitting: state.work.submitting,
  uploadPercent: state.work.uploadPercent,
  fileListModal: state.common.fileListModal,
  processModalVisible: state.work.processModalVisible,
});
const mapDispatchToProps = dispatch => ({
  onFilesChange: files => dispatch(actions.onFilesChange(files)),
  onFileListModalChange: fileListModal => dispatch(actions.onFileListModalChange(fileListModal)),
  onProcessPercentChange: processPercent => dispatch(actions.onProcessPercentChange(processPercent)),
  onProcessStepChange: processStep => dispatch(actions.onProcessStepChange(processStep)),
  onStartTimeChange: startTime => dispatch(actions.onStartTimeChange(startTime)),
  onUserActionChange: userAction => dispatch(actions.onUserActionChange(userAction)),
  onUploadPercentChange: uploadPercent => dispatch(actions.onUploadPercentChange(uploadPercent)),
  onSubmittingChange: submitting => dispatch(actions.onSubmittingChange(submitting)),
  onWorkChange: work => dispatch(actions.onWorkChange(work)),
  onPrevWorkChange: prevWork => dispatch(actions.onPrevWorkChange(prevWork)),
  onSuccessFileGuidsChange: successFileGuids => dispatch(actions.onSuccessFileGuidsChange(successFileGuids)),
  onReverseObjChange: reverseObj => dispatch(actions.onReverseObjChange(reverseObj)),
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
  onPurchaseModalObjChange: purchaseModal => dispatch(actions.onPurchaseModalObjChange(purchaseModal)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(workplaceHOC(ReverseWork));
