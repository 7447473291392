export default {
  Start: "開始",
  End: "終了",
  Save: "保存",
  Cancel: "キャンセル",
  Download: "ダウンロード",
  downloading: "ダウンロード",
  "Drag and drop from Files or local disk": "ファイルまたはローカルディスクからドラッグ＆ドロップ",
  "Click to Add File": "クリックしてファイルを追加",
  "The minimum time length is 1s!": "時間の長さは最小1sです！",
  "The maximum time length is 50s!": "時間の長さは最大50sです！",
  "Try Again": "もう一度お試しください",
  "Coming Soon": "近日公開",
  "Learn more": "詳しくはこちら",
  Yes: "はい",
  "Please click the Save button to ensure the previous editing to take effect.": "設定した項目を有効にするには［保存］ボタンをクリックしてください。",
  "The thumbnail is missing, but you can download and continue editing videos normally. If you feel troubled, please download the video first and then upload it again.":
    "サムネイルがありませんが、ビデオをダウンロードして通常どおり編集を続けることができます。 問題が発生した場合は、最初にビデオをダウンロードしてから、もう一度アップロードしてください。",
  // **************************TipsModal Message Title*****************************
  "Cut 10 video fragments at most. Get HitPaw Toolkit for more features.":
    "最大 10 個のビデオフラグメントをカットする。その他の機能については、HitPawToolkitを入手してください。",
  "Max file size for videos is 20 MB. Get HitPaw Toolkit for big file.":
    "ファイルのサイズは20MBまでです。その他の機能については、HitPaw Watermark Removerを入手してください。",
  "Max file size for videos is 20 MB. Get HitPaw Watermark Remover for big file.":
    "ファイルのサイズは20MBまでです。その他の機能については、HitPawToolkitを入手してください。",
  "The minimum time length is 1s! Get HitPaw Toolkit for more features.":
    "時間の長さは最小1sです！その他の機能については、HitPawToolkitを入手してください。",
  "Add 3 videos at most. Get HitPaw Toolkit for more features.":
    "ビデオ 3 個を最大限に追加する。その他の機能については、HitPawToolkitを入手してください。",
  "This format is not supported currently.": "このファイル形式は現在サポートされていません。",
  "This format is not supported currently. Get HitPaw Toolkit for more formats":
    "このファイル形式は現在サポートされていません。その他の機能については、HitPawToolkitを入手してください。",
  "This format is not supported currently. Get HitPaw Watermark Remover for more formats":
    "このファイル形式は現在サポートされていません。その他の機能については、HitPaw Watermark Removerを入手してください。",
  "Processing failed. Get HitPaw Toolkit for stable processing.": "動画の作成に失敗しました！",
  "Failed to load!  Get HitPaw Watermark Remover for stable processing.": "読み込みに失敗しました！",
  "Are you sure you want to delete it?": "消去してもよろしいですか？",
  "Failed to extract the audio!": "音声の抽出に失敗しました！",
  "Video link copied": "ビデオリンクがコピーされました。",
  "Cut 10 video fragments at most.": "最大 10 個のビデオフラグメントをカットする。",
  "Max file size for videos is 20 MB.": "添付ファイルのサイズが最大値20MBをこえています。",
  "Max file size for videos is 100 MB.": "添付ファイルのサイズが最大値100MBをこえています。",
  "Failed to load!": "読み込みに失敗しました！",

  //******************************2021.05.17 v1.3.0 *******************************/
  "Screenshots downloaded.": "スクリーンショットをダウンロードしました",
  "Online service, no download required": "オンラインサービス、ダウンロードは不要です",
  "Add up to 20MB file": "最大20MBのファイルを追加",
  "Support common formats": "一般的なフォーマットに対応",
  "Best for general editing requirements": "一般的な編集要件に最適です",
  "0 second wait": "待ち時間無し",
  "No size limits": "サイズ制限なし",
  "Support 1000+ formats": "1000以上のフォーマットに対応",
  "Stable processing": "安定した処理",
  "Get access to HitPaw Toolkit!": "HitPawツールキットをご利用いただけます。",
  "Support cut, crop & rotate, adjust, speed, add music, stop motion, etc.":
    "カット、クロップ＆回転、調整、スピード、音楽追加、ストップモーションなどをサポートします",
  "The easiest video editing software for beginners": "初心者のための最も簡単なビデオ編集ソフトウェア",
  "Remove watermark from videos and images in simple clicks": "簡単なクリックで動画や画像からウォーターマークを削除する",
  "TRY IT FREE": "無料お試し",
  "BUY NOW": "今すぐ購入",

  //******************************2021.09.06 v1.5.0********************************/
  "Add Video": "ビデオを追加",
  More: "さらに",
  "History files": "履歴ファイル",
  Share: "共用",
  Duration: "期間",
  "Download all": "全ての項目をダウンロード",
  "Download selected": "選択した項目をダウンロード",
  "Add Audio": "音声を追加",

  //********************************2021.10.19 v1.6.0*********************************/
  "GIF does not support editing. Do you want to convert the MP4 for editing?": "GIFの編集がサポートされていません。GIFをMP4に変更して編集しますか？",
  "Go to Edit": "編集ツールへ移動",
  "Download GIF": "GIFをダウンロード",

  //********************************2021.10.19 v1.6.1*********************************/
  "HitPaw Video Editor": "HitPaw Video Editor",
  "No video size limits": "ビデオサイズの制限はありません。",
  "Cut/crop/rotate/resize/merge video easily": "ビデオを簡単にカット/トリミング/回転/サイズ変更/併合できる。",
  "Tons of video effects are available for your video creativity":
    "多くのビデオエフェクトが利用可能で動画作品をさらに魅力的にし、見る人を感動させることができる。",
  "Unlimited tracks allow you to add multiple audios as you want": "無制限のトラックを使用すると、必要に応じて複数のオーディオを追加できる。",

  //********************************2021.11.02 v1.6.1*********************************/
  "Download HitPaw Video Editor for more features.": "HitPaw Video Editorをダウンロードしてより多くの機能が利用可能になります。",
  "Get HitPaw Watermark Remover for more features.": "その他の機能については、HitPaw Watermark Remover を入手してください。",
  "Get HitPaw Toolkit for more features.": "その他の機能については、HitPawToolkitを入手してください。",
  "Add 3 videos at most.": "最大3本までの動画を合併できます。",
  "Processing failed.": "処理に失敗しました。",
  "The uploaded video is less than 1 second.": "アップロードされたビデオは1秒未満です。",
  "The video format is not supported.": "動画のフォーマットはサポートされていません。",
  "The audio format is not supported.": "音声のフォーマットはサポートされていません。",
  "AddFileByURL.largerSize.HitPawToolkit":
    "解析が完了しました。ファイルのサイズが100MB以上のため、HitPawオンラインでは編集できません。<1><0>動画をダウンロードし</0></1>、HitPawツールキットを使って編集してください。",
  "AddFileByURL.largerSize.HitPawWatermarkRemover":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Watermark Remover to proceed.",
  "AddFileByURL.largerSize.HitPawVideoEditor":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Video Editor to proceed.",
};
