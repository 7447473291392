export default {
  Start: "Début",
  End: "Fin",
  Save: "Enregistrer",
  Cancel: "Annuler",
  Download: "Télécharger",
  downloading: "Téléchargement",
  "Drag and drop from Files or local disk": "Glisser ou déposer à partir d’un fichier ou d’un disque local",
  "Click to Add File": "Cliquez pour ajouter un fichier",
  "The minimum time length is 1s!": "La durée minimale est de 1s!",
  "The maximum time length is 50s!": "La durée maximale est de 50s!",
  "Try Again": "Réessayer",
  "Coming Soon": "Prochainement",
  "Learn more": "En savoir plus",
  Yes: "Oui",
  "Please click the Save button to ensure the previous editing to take effect.":
    "Veuillez cliquer sur le bouton Enregistrer pour vous assurer que les éléments de configuration prennent effet.",
  "The thumbnail is missing, but you can download and continue editing videos normally. If you feel troubled, please download the video first and then upload it again.":
    "La vignette est manquante, mais vous pouvez télécharger et continuer à éditer des vidéos normalement. Si vous vous sentez troublé, téléchargez d'abord la vidéo, puis téléchargez-la à nouveau.",
  // **************************TipsModal Message Title*****************************
  "Cut 10 video fragments at most. Get HitPaw Toolkit for more features.":
    "Ajoutez au maximum 10 clips vidéo. Obtenez HitPaw Toolkit pour plus de fonctionnalités.",
  "Max file size for videos is 20 MB. Get HitPaw Toolkit for big file.":
    "La taille du fichier ne peut pas être supérieure à 20MB. Obtenez HitPaw Toolkit pour plus de fonctionnalités.",
  "Max file size for videos is 20 MB. Get HitPaw Watermark Remover for big file.":
    "La taille du fichier ne peut pas être supérieure à 20MB. Obtenez HitPaw Watermark Remover pour plus de fonctionnalités.",
  "The minimum time length is 1s! Get HitPaw Toolkit for more features.":
    "La durée minimale est de 1s! Obtenez HitPaw Toolkit pour plus de fonctionnalités.",
  "Add 3 videos at most. Get HitPaw Toolkit for more features.": "Ajoutez au maximum 3 vidéos. Obtenez HitPaw Toolkit pour plus de fonctionnalités.",
  "This format is not supported currently.": "Ce format n'est pas pris en charge actuellement.",
  "This format is not supported currently. Get HitPaw Toolkit for more formats":
    "Ce format n'est pas pris en charge actuellement. Obtenez HitPaw Toolkit pour plus de fonctionnalités.",
  "This format is not supported currently. Get HitPaw Watermark Remover for more formats":
    "Ce format n'est pas pris en charge actuellement. Obtenez HitPaw Watermark Remover pour plus de fonctionnalités.",
  "Processing failed. Get HitPaw Toolkit for stable processing.": "Échec de la génération de la vidéo !",
  "Failed to load!  Get HitPaw Watermark Remover for stable processing.": "Échec du chargement!",
  "Are you sure you want to delete it?": "Êtes-vous sur de vouloir le supprimer ?",
  "Failed to extract the audio!": "Échec de l'extraction de l'audio!",
  "Video link copied": "Lien vidéo copié",
  "Cut 10 video fragments at most.": "Ajoutez au maximum 10 clips vidéo.",
  "Max file size for videos is 20 MB.": "La taille du fichier ne peut pas dépasser 20 Mo.",
  "Max file size for videos is 100 MB.": "La taille du fichier ne peut pas dépasser 100 Mo.",
  "Failed to load!": "Échec du chargement!",

  //******************************2021.05.17 v1.3.0 *******************************/
  "Screenshots downloaded.": "Captures d'écran téléchargées.",
  "Online service, no download required": "Service en ligne, aucun téléchargement requis",
  "Add up to 20MB file": "Ajoutez un fichier jusqu'à 20 Mo",
  "Support common formats": "Prise en charge des formats courants",
  "Best for general editing requirements": "Idéal pour les exigences d'édition générales",
  "0 second wait": "0 seconde d'attente",
  "No size limits": "Aucune limite de taille",
  "Support 1000+ formats": "Supporte plus de 1000 formats",
  "Stable processing": "Traitement stable",
  "Get access to": "Accédez au",
  "Get access to HitPaw Toolkit!": "Accédez au Toolkit de HitPaw !",
  "Support cut, crop & rotate, adjust, speed, add music, stop motion, etc.":
    "Supporte le découpage, le recadrage et la rotation, l'ajustement, la vitesse, l'ajout de musique, les arrêts de mouvement, etc.",
  "The easiest video editing software for beginners": "Le logiciel de montage vidéo le plus simple pour les débutants",
  "Remove watermark from videos and images in simple clicks": "Supprimez le filigrane des vidéos et des images en quelques clics.",
  "TRY IT FREE": "ESSAI GRATUIT",
  "BUY NOW": "ACHETEZ MAINTENANT",
  "HitPaw Online Video Editor": "HitPaw Montage Vidéo En Ligne",

  //******************************2021.09.06 v1.5.0********************************/
  "Add Video": "Ajouter une vidéo",
  More: "Plus",
  "History files": "Fichiers d'historique",
  Share: "Partager",
  Duration: "Durée",
  "Download all": "Tout télécharger",
  "Download selected": "Télécharger sélectionné",
  "Add Audio": "Ajouter de l’audio",

  //********************************2021.10.19 v1.6.0*********************************/
  "GIF does not support editing. Do you want to convert the MP4 for editing?":
    "GIF ne prend pas en charge l'édition. Voulez-vous convertir le MP4 pour l'éditer ?",
  "Go to Edit": "Aller à Modifier",
  "Download GIF": "Télécharger GIF",

  //********************************2021.10.19 v1.6.1*********************************/
  "HitPaw Video Editor": "HitPaw Montage vidéo",
  "No video size limits": "Aucune limite de taille vidéo.",
  "Cut/crop/rotate/resize/merge video easily": "Couper / recadrer / faire pivoter / redimensionner / fusionner la vidéo facilement.",
  "Tons of video effects are available for your video creativity": "Des tonnes d’effets vidéo sont disponibles pour votre créativité vidéo.",
  "Unlimited tracks allow you to add multiple audios as you want":
    "Les pistes illimitées vous permettent d’ajouter plusieurs audios comme vous le souhaitez.",

  //********************************2021.11.02 v1.6.1*********************************/
  "Download HitPaw Video Editor for more features.": "Téléchargez HitPaw Montage vidéo pour plus de fonctionnalités.",
  "Get HitPaw Watermark Remover for more features.": "Obtenez HitPaw Watermark Remover pour plus de fonctionnalités.",
  "Get HitPaw Toolkit for more features.": "Obtenez HitPaw Toolkit pour plus de fonctionnalités.",
  "Add 3 videos at most.": "Ajoutez 3 vidéos au maximum.",
  "Processing failed.": "Échec du traitement.",
  "The uploaded video is less than 1 second.": "La vidéo téléchargée est inférieure à 1 seconde. ",
  "The video format is not supported.": "Le format vidéo n’est pas pris en charge. ",
  "The audio format is not supported.": "Le format audio n’est pas pris en charge. ",
  "AddFileByURL.largerSize.HitPawToolkit":
    "Une analyse réussie ! Votre fichier est supérieur à 100 Mo et ne peut pas être édité par HitPaw Online, veuillez <1><0>télécharger la vidéo</0></1> et utiliser ensuite la boîte à outils HitPaw pour continuer.",
  "AddFileByURL.largerSize.HitPawWatermarkRemover":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Watermark Remover to proceed.",
  "AddFileByURL.largerSize.HitPawVideoEditor":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Video Editor to proceed.",
};
