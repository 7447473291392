export default {
  'Image': 'Immagine',
  'Text': 'Testo',
  'Add image': 'Aggiungi Immagine',
  'Opacity': 'Opacità',
  "Add text": 'Aggiungi testo',
  'Text Color': 'Colore testo',
  'Outline': 'Contorno',
  'Background': 'Sfondo',
  'Text here': 'Testo qui',
}