/**
 * api 常量文件夹
 */

/**
 * 生产环境
 */
const prod = {
  // API_URL: "https://appsrv.hitpaw.com/app",
  // WS_API_URL: "wss://appsrv.hitpaw.com/app",
  // API_V2_URL: "https://appsrv.hitpaw.com/api/v2/",
  API_URL: "https://api.hitpaw.com/app",
  WS_API_URL: "wss://api.hitpaw.com/app",
  API_V2_URL: "https://api.hitpaw.com/api/v2/",
};

/**
 * 开发和测试环境
 */
const dev = {
  API_URL: "https://hitpaw-test-api.afirstsoft.cn/app",
  WS_API_URL: "wss://hitpaw-test-api.afirstsoft.cn/app",
  API_V2_URL: "https://hitpaw-test-api.afirstsoft.cn/api/v2/",
};

export const api_config = process.env.NODE_ENV === "development" || process.env.REACT_APP_BUILD_ENV === "development" ? dev : prod;
