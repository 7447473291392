export default {
  'Image': '图片',
  'Text': '文字',
  'Add image': '添加图片',
  'Opacity': '不透明度',
  "Add text": '添加文字',
  'Text Color': '文字颜色',
  'Outline': '外框',
  'Background': '背景',
  'Text here': '在这里输入文字',
}