import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "@/action";
import { useDrop } from "react-dnd";
import CutPreviewFunction from "./children/CutPreviewFunction";
import { ReactComponent as Close } from "@/assets/icons/close-circle.svg";

const VideoWrap = ({ currFile, onCurrFileChange, onSubmittingChange }) => {
  const [, drop] = useDrop({
    accept: "FileListItem",
    drop: () => ({ name: "CutVideoWrap" }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: item => {
      if ((item.file && item.file.audiofileurl) || (item.file && item.file.status === "gif")) {
        return false;
      }
      return true;
    },
  });
  return (
    <div ref={drop} className="videoWrap" id="videoRef">
      <div style={{ width: "fit-content", height: "fit-content", margin: "auto", position: "relative" }}>
        <button
          className="close"
          onClick={() => {
            onCurrFileChange(null);
            onSubmittingChange(false);
          }}
        >
          <Close />
        </button>
        <video className="active" crossOrigin="Anonymous">
          <source src={currFile.videofileurl} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

class CutPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="CutPreview">
        <VideoWrap currFile={this.props.currFile} onCurrFileChange={this.props.onCurrFileChange} onSubmittingChange={this.props.onSubmittingChange} />
        <CutPreviewFunction
          files={this.props.files}
          currFile={this.props.currFile}
          cutWorkList={this.props.cutWorkList}
          work={this.props.work}
          onCutWorkListChange={this.props.onCutWorkListChange}
          submitting={this.props.submitting}
        />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  cutWorkList: state.work.cutWorkList,
  work: state.work.work,
  submitting: state.work.submitting,

});

const mapDispatchToProps = dispatch => ({
  onCutWorkListChange: cutWorkList => dispatch(actions.onCutWorkListChange(cutWorkList)),
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
  onSubmittingChange: submitting => dispatch(actions.onSubmittingChange(submitting)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(CutPreview);
