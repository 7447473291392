import React, { Component } from "react";
import { ResizeWorkRender } from "./children/ResizeWorkRender";

export default class ResizeWorkFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundImageList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      color: "#000000",
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.videoBgColor !== this.props.videoBgColor &&
      this.props.videoBgColor.indexOf("blur") === -1 &&
      this.props.videoBgColor.indexOf("bg") === -1
    ) {
      this.setState({
        color: this.props.videoBgColor,
      });
    }
  }

  render() {
    return (
      <ResizeWorkRender
        currFile={this.props.currFile}
        videoBgColor={this.props.videoBgColor}
        color={this.state.color}
        resolution={this.props.resolution}
        onResolutionChange={this.props.onResolutionChange}
        onFillingChange={this.props.onFillingChange}
        handleSetColor={this.props.handleSetColor}
        handleQuickSetColor={this.props.handleQuickSetColor}
        preSubmit={this.props.preSubmit}
        backgroundImageList={this.state.backgroundImageList}
      />
    );
  }
}
