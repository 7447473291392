import React, { Component } from "react";
import { RemoveWatermarkPreviewRender } from "./children/RemoveWatermarkPreviewRender";
import moment from "moment";
import Utils from "@/utils/utils";

export default class RemoveWatermarkPreviewFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soundSlide: false,
      playing: false,
      currentTime: "00:00:00",
      currentTimePercent: 0,
      currentVolume: 0,
      miniLengthLimit: false,
      maxLengthLimit: false,
      videoReady: false,
    };
  }

  handleSoundClick = () => {
    const myvideo = document.querySelector("#videoRef video");
    this.setState({
      currentVolume: myvideo.volume * 100,
      soundSlide: !this.state.soundSlide,
    });
  };

  handleSoundDrag = value => {
    const myvideo = document.querySelector("#videoRef video");
    myvideo.volume = value / 100;
    return value;
  };

  handlePlayClick = () => {
    const myvideo = document.querySelector("#videoRef video");
    if (this.state.playing) {
      myvideo.pause();
    } else if (this.props.removeWatermarkList.some(value => value.selected)) {
      // 保证指针在选框范围内
      if (
        myvideo.currentTime < Utils.getSeconds(this.props.removeWatermarkList.filter(item => item.selected)[0].start) ||
        myvideo.currentTime >= Utils.getSeconds(this.props.removeWatermarkList.filter(item => item.selected)[0].end)
      ) {
        myvideo.currentTime = Utils.getSeconds(this.props.removeWatermarkList.filter(item => item.selected)[0].start);
      }
      myvideo.play();
      window.requestAnimationFrame(this._getCurrentTime);
    } else {
      myvideo.play();
      window.requestAnimationFrame(this._getCurrentTime);
    }
    this.setState({ playing: !this.state.playing });
  };

  _getCurrentTime = () => {
    if (!this.state.playing) {
      return false;
    }
    const myvideo = document.querySelector("#videoRef video");
    this.setState({
      currentTime: moment("00:00:00", "mm:ss:SS")
        .add(myvideo.currentTime, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1"),
      currentTimePercent: (myvideo.currentTime / myvideo.duration) * 100,
    });
    if (this.props.removeWatermarkList.some(value => value.selected)) {
      let endTime = Utils.getSeconds(this.props.removeWatermarkList.filter(item => item.selected)[0].end);
      if (endTime > myvideo.duration) {
        endTime = myvideo.duration;
      }
      if (myvideo.currentTime < endTime) {
        window.requestAnimationFrame(this._getCurrentTime);
      } else {
        this.setState({
          playing: false,
          currentTimePercent: this.getRemoveWatermarkPercent(this.props.removeWatermarkList.filter(item => item.selected)[0].start),
          currentTime: moment(this.props.removeWatermarkList.filter(item => item.selected)[0].start.replace(".", ":"), "mm:ss:SS")
            .format("mm:ss:SS")
            .replace(/:([^:]*)$/, ".$1"),
        });
        myvideo.currentTime = Utils.getSeconds(this.props.removeWatermarkList.filter(item => item.selected)[0].start);
        myvideo.pause();
      }
    } else {
      if (myvideo.currentTime < myvideo.duration) {
        window.requestAnimationFrame(this._getCurrentTime);
      } else {
        this.setState({
          playing: false,
          currentTimePercent: 0,
          currentTime: "00:00:00",
        });
        myvideo.currentTime = 0;
        myvideo.pause();
      }
    }
    if (document.querySelectorAll(".Preview .RemoveWatermarkPreview .MultiCrops > div > div").length > 0) {
      this.props.removeWatermarkList.forEach((item, i) => {
        if (
          Utils.getSeconds(this.state.currentTime) >= Utils.getSeconds(item.start) &&
          Utils.getSeconds(this.state.currentTime) <= Utils.getSeconds(item.end)
        ) {
          document.querySelectorAll(".Preview .RemoveWatermarkPreview .MultiCrops > div > div")[i].style.display = "block";
        } else {
          document.querySelectorAll(".Preview .RemoveWatermarkPreview .MultiCrops > div > div")[i].style.display = "none";
        }
      });
    }
  };

  handleCurrentTimeChange = value => {
    const myvideo = document.querySelector("#videoRef video");
    myvideo.currentTime = myvideo.duration * (value / 100);
    this.setState({
      currentTime: moment("00:00:00", "mm:ss:SS")
        .add(myvideo.currentTime, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1"),
      currentTimePercent: value,
    });
    if (document.querySelectorAll(".Preview .RemoveWatermarkPreview .MultiCrops > div > div").length > 0) {
      this.props.removeWatermarkList.forEach((item, i) => {
        if (
          Utils.getSeconds(this.state.currentTime) >= Utils.getSeconds(item.start) &&
          Utils.getSeconds(this.state.currentTime) <= Utils.getSeconds(item.end)
        ) {
          document.querySelectorAll(".Preview .RemoveWatermarkPreview .MultiCrops > div > div")[i].style.display = "block";
        } else {
          document.querySelectorAll(".Preview .RemoveWatermarkPreview .MultiCrops > div > div")[i].style.display = "none";
        }
      });
    }
  };

  handleRangeChange = value => {
    if (this.state.playing) {
      this.handlePlayClick();
    }
    const d = Utils.getSeconds(this.props.currFile.duration);
    if ((d * value[1]) / 100 - (d * value[0]) / 100 <= 1) {
      this.setState({ miniLengthLimit: true });
      return false;
    }
    if ((d * value[1]) / 100 - (d * value[0]) / 100 > 51 && this.props.work === "gif") {
      this.setState({ maxLengthLimit: true });
      return false;
    }
    this.setState({ miniLengthLimit: false });
    this.setState({ maxLengthLimit: false });

    const start = moment("00:00:00", "mm:ss:SS")
      .add((d * value[0]) / 100, "seconds")
      .format("mm:ss:SS")
      .replace(/:([^:]*)$/, ".$1");
    const end = moment("00:00:00", "mm:ss:SS")
      .add((d * value[1]) / 100, "seconds")
      .format("mm:ss:SS")
      .replace(/:([^:]*)$/, ".$1");
    const removeWatermarkList = [...this.props.removeWatermarkList];
    let removeWatermark = removeWatermarkList.filter(item => item.selected)[0];
    removeWatermark.start = start;
    removeWatermark.end = end;
    this.props.onRemoveWatermarkListChange(removeWatermarkList);
  };

  getRemoveWatermarkPercent = value => {
    const d = Utils.getSeconds(this.props.currFile.duration);
    const t = Utils.getSeconds(value);
    return (t / d) * 100;
  };

  componentDidUpdate(prevProps) {
    if (prevProps.currFile !== this.props.currFile) {
      this.setState({
        soundSlide: false,
        playing: false,
        currentTime: "00:00:00",
        currentTimePercent: 0,
        currentVolume: 0,
        miniLengthLimit: false,
        maxLengthLimit: false,
      });
    }
    if (this.props.removeWatermarkList !== prevProps.removeWatermarkList) {
      const myvideo = document.querySelector("#videoRef video");
      const removeWatermarkList = [...this.props.removeWatermarkList];
      if (this.props.removeWatermarkList.length > 0 && this.props.removeWatermarkList.some(value => value.selected)) {
        const removeWatermark = removeWatermarkList.filter(item => item.selected)[0];
        let currentTime;
        if (removeWatermark.start === this.props.cacheCutTime[0] && removeWatermark.end !== this.props.cacheCutTime[1]) {
          currentTime = removeWatermark.end;
        } else {
          currentTime = removeWatermark.start;
        }
        if (currentTime) {
          myvideo.currentTime = Utils.getSeconds(currentTime);
          this.setState({
            playing: false,
          });
          myvideo.pause();
          this.setState({
            currentTime: currentTime,
            currentTimePercent: this.getRemoveWatermarkPercent(currentTime),
          });
        }
      }
    }

    if (this.props.submitting && !prevProps.submitting) {
      const myvideo = document.querySelector("#videoRef video");
      if (this.state.playing) {
        myvideo.pause();
        this.setState({ playing: false });
      }
    }
  }

  render() {
    return (
      <RemoveWatermarkPreviewRender
        videoReady={this.props.videoReady}
        soundSlide={this.state.soundSlide}
        currFile={this.props.currFile}
        currentTime={this.state.currentTime}
        currentTimePercent={this.state.currentTimePercent}
        currentVolume={this.state.currentVolume}
        playing={this.state.playing}
        removeWatermarkList={this.props.removeWatermarkList}
        miniLengthLimit={this.state.miniLengthLimit}
        maxLengthLimit={this.state.maxLengthLimit}
        handlePlayClick={this.handlePlayClick}
        handleSoundClick={this.handleSoundClick}
        handleSoundDrag={this.handleSoundDrag}
        handleRangeChange={this.handleRangeChange}
        handleCurrentTimeChange={this.handleCurrentTimeChange}
        getRemoveWatermarkPercent={this.getRemoveWatermarkPercent}
        screenshots={Utils.screenshots}
      />
    );
  }
}
