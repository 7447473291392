import React, { Component } from "react";
import { connect } from "react-redux";
import { workplaceHOC } from "../WorkplaceHOC";
import ConvertWorkFunction from "./children/ConvertWorkFunction";
import { request } from "@/api/request";
import actions from "@/action";
import ReactGA from "react-ga";
import "@/assets/convertWork.scss";

class ConvertWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      convert: "mp4",
      waitingUpload: false,
    };
  }

  onConvertChange = convert => {
    this.setState({ convert });
  };

  handleSubmit = async () => {
    const { convert } = this.state;
    const startTime = { ...this.props.startTime };
    startTime.convert = new Date();
    this.props.onStartTimeChange(startTime);
    if (!this.props.submitting) {
      this.props.onProcessStepChange(1);
      this.props.onProcessPercentChange(1);
    }
    let files = [...this.props.files];
    let convertResponse;
    if (convert !== "mp4") {
      try {
        convertResponse = await this.getConvertVideo(convert);
      } catch (error) {
        this.props.onProcessPercentChange(0);
        this.props.onProcessStepChange(0);
        this.props.onSubmittingChange(false);
        this.props.handleSubmitFailModal(true);
        ReactGA.event(
          {
            category: "Convert",
            action: "ConvertFail",
            label: error + "_none_none_" + this.props.tokenId + "_none_none",
          },
          ["OnlineTracker", "Tracker"]
        );
        return false;
      }
      if (convertResponse.code === 200) {
        let guids = [];
        try {
          guids = await this.props.getNewVideoByProgress("convert", convertResponse, { convertType: this.state.convert });
        } catch (error) {
          if (error !== "cancel") {
            ReactGA.event(
              {
                category: "Convert",
                action: "ConvertFail",
                label: "formatConv_200_200_" + error,
              },
              ["OnlineTracker", "Tracker"]
            );
          }
          return false;
        }
        const modal = {
          getContainer: document.querySelector(".Home"),
          show: true,
          type: "convertSuccess",
          guid: guids[0],
          title: "Generate Files Successfully!",
          buttons: [
            {
              type: "download",
              text: "Download",
            },
            {
              type: "editNew",
              text: "Edit New Video",
            },
          ],
        };
        this.props.onFileListModalChange(modal);
        this.props.onUploadPercentChange(0);
        this.props.onProcessPercentChange(0);
        this.props.onSubmittingChange(false);
        ReactGA.event(
          {
            category: "Convert",
            action: "ConvertSuccess",
          },
          ["OnlineTracker", "Tracker"]
        );
        // ReactGA.event({
        //   category: "Convert",
        //   action: "ConvertSuccess",
        // });
      } else {
        if (convertResponse.code === 402102) {
          this.props.onPurchaseModalObjChange({
            visible: true,
            type: "videoEditorTimesMoreThanLimit",
          });
        } else {
          this.props.handleSubmitFailModal(true);
        }
        this.props.onProcessPercentChange(0);
        this.props.onProcessStepChange(0);
        this.props.onSubmittingChange(false);
        ReactGA.event(
          {
            category: "Convert",
            action: "ConvertFail",
            label: "formatConv_200_" + convertResponse.code + "_none_" + this.props.tokenId + "_none_" + convertResponse.msg,
          },
          ["OnlineTracker", "Tracker"]
        );
      }
    } else {
      this.props.onProcessPercentChange(this.props.processPercent + 100);
      const guid = new Date().getTime();
      files.push({
        ...this.props.currFile,
        name: "HitPawOnline_" + new Date().getHours() + new Date().getMinutes() + new Date().getSeconds() + "." + convert,
        guid,
        status: "convert",
        downloadurl: this.props.currFile.videofileurl,
        mark: false,
      });
      this.props.onFilesChange(files);
      localStorage.setItem("files", JSON.stringify(files));
      const modal = {
        getContainer: document.querySelector(".Home"),
        show: true,
        type: "convertSuccess",
        guid: guid,
        title: "Generate Files Successfully!",
        buttons: [
          {
            type: "download",
            text: "Download",
          },
          {
            type: "editNew",
            text: "Edit New Video",
          },
        ],
      };
      this.props.onFileListModalChange(modal);
      this.props.onUploadPercentChange(0);
      this.props.onProcessPercentChange(0);
      this.props.onSubmittingChange(false);
      console.log(
        JSON.stringify(this.props.files),
        guid,
        this.props.files.filter(item => item.guid === guid)
      );
      ReactGA.event(
        {
          category: "Convert",
          action: "ConvertSuccess",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
  };

  getConvertVideo = convert => {
    const formData = new FormData();
    formData.append("ext", convert);
    formData.append("file_url", this.props.currFile.videofileurl);
    formData.append("token", this.props.tokenId);
    return new Promise((resolve, reject) => {
      request(`formatConv`, {
        method: "post",
        body: formData,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  preSubmit = () => {
    const { convert } = this.state;
    ReactGA.event(
      {
        category: "Convert",
        action: "ConvertSave",
        label: convert,
      },
      ["OnlineTracker", "Tracker"]
    );
    this.props.preSubmit(1, this.handleSubmit);
  };

  render() {
    const { convert } = this.state;
    return (
      <div style={{ height: "100%" }}>
        <ConvertWorkFunction convert={convert} currFile={this.props.currFile} onConvertChange={this.onConvertChange} preSubmit={this.preSubmit} />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  currFile: state.files.currFile,
  files: state.files.files,
  tokenId: state.common.tokenId,
  submitting: state.work.submitting,
  processStep: state.work.processStep,
  uploading: state.work.uploading,
  startTime: state.work.startTime,
});
const mapDispatchToProps = dispatch => ({
  onProcessPercentChange: processPercent => dispatch(actions.onProcessPercentChange(processPercent)),
  onProcessStepChange: processStep => dispatch(actions.onProcessStepChange(processStep)),
  onFilesChange: files => dispatch(actions.onFilesChange(files)),
  onSubmittingChange: submitting => dispatch(actions.onSubmittingChange(submitting)),
  onFileListModalChange: fileListModal => dispatch(actions.onFileListModalChange(fileListModal)),
  onUploadPercentChange: uploadPercent => dispatch(actions.onUploadPercentChange(uploadPercent)),
  onUploadAgainFileChange: uploadAgainFile => dispatch(actions.onUploadAgainFileChange(uploadAgainFile)),
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
  onPreUploadFileChange: preUploadFile => dispatch(actions.onPreUploadFileChange(preUploadFile)),
  onStartTimeChange: startTime => dispatch(actions.onStartTimeChange(startTime)),
  onPurchaseModalObjChange: purchaseModal => dispatch(actions.onPurchaseModalObjChange(purchaseModal)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(workplaceHOC(ConvertWork));
