import React from "react";
import { useTranslation } from "react-i18next";
import { Radio, Button, Row, Col } from "antd";
import "@/assets/muteWork.scss";

export const MuteWorkRender = ({ currFile, handleMuteType, preSubmit, muteType }) => {
  const { t } = useTranslation(["muteWork", "common"]);
  return (
    <div className={"MuteWork" + (!currFile ? " disabled" : "")}>
      <div>
        <Radio.Group value={muteType} onChange={handleMuteType}>
          <Radio value="audio">{t("Extract audio")}</Radio>
          <Radio value="video">{t("Mute")}</Radio>
          <Radio value="video_audio">{t("Split video and audio")}</Radio>
        </Radio.Group>
        <hr />
        <Row gutter={[16, 16]}>
          <Col span={24} className={muteType === "video" || muteType === "video_audio" ? "active" : ""}>
            <div className="muteFileBox">Mute Video.mp4</div>
          </Col>
          <Col span={24} className={muteType === "audio" || muteType === "video_audio" ? "active" : ""}>
            <div className="muteFileBox">Extract audio.mp3</div>
          </Col>
        </Row>
      </div>
      <div className="save">
        <Button type="primary" block={true} onClick={preSubmit} size="middle" disabled={currFile ? false : true}>
          {t("common:Save")}
        </Button>
      </div>
    </div>
  );
};
