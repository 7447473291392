export default {
  'Files': 'फाइल',
  'Refresh': 'रिफ्रेश करें',
  'Original file': 'मूल फाइल',
  'Just now': 'अभी-अभी',
  'hour ago': '{{num}} घंटे पहले',
  'hours ago': '{{num}} घंटे पहले',
  'minute ago': '{{num}} मिनट पहले',
  'minutes ago': '{{num}} मिनट पहले',
  'day ago': '{{num}} 天前',
  'View All': 'सभी देखें',
  'No files': 'कोई फाइल नहीं......',
  "Don't forget to download your file, it will be deleted after 24 hours": 'अपनी फाइल डाउनलोड करना न भूलें, इसे 24 घंटे बाद हटा दिया जायेगा। ',
  'Select All': 'सभी चुनें',
  'Delete': 'हटाएं',
  'Generate Files Successfully!': 'फाइलों को सफलतापूर्वक जनरेट किया गया!',
  'Continue Editing': 'संपादन जारी रखें',
  'Edit New Video': 'नया वीडियो संपादित करें',
  'Download': 'डाउनलोड करें',
  'Click here to open the Files again':'फाइलों को दोबारा खोलने के लिए यहाँ क्लिक करें।',
  'Processing... Please wait': 'संसाधित हो रहा है... कृपया प्रतीक्षा करें',
  'Uploading... Please wait': 'अपलोड हो रहा है ... कृपया प्रतीक्षा करें',
  'Edit Now': 'अभी संपादित करें',
  'Back to Edit':'संपादन पर वापस जाएँ',
  'Go Edit':'संपादित करें',

}