export default {
  freeTimesLimitTitle: "आपका ट्रायल आज समाप्त हो गया है, कृपया सभी प्रीमियम सुविधाओं के एक्सेस के लिए प्रो में अपग्रेड करें",
  fileSizeLimitTitle:
    "मुफ़्त खातों के लिए फाइल का आकार 20 एमबी से ज़्यादा नहीं हो सकता, कृपया सभी प्रीमियम सुविधाओं के एक्सेस के लिए प्रो में अपग्रेड करें",
  total: "कुल",
  enterCode: "कोड डालें",
  signUpToPurchaseBtn: "ख़रीदने के लिए साइन अप करें",
  goPurchseBtn: "ख़रीदारी पर जाएं",
  saveTips: "{{num}}% तक बचाएं",
  discount: "छूट",
  montly: "मासिक",
  yearly: "वार्षिक",
  vipTips1: "वीडियो साइज़ और मात्रा के लिए कोई सीमा नहीं",
  vipTips2: "असीमित रूप से इमेज बैकग्राउंड हटाएं",
  vipTips3: "तेज़ वीडियो प्रॉसेसिंग और डाउनलोड स्पीड",
  vipTips4: "सभी मुफ़्त टूल और प्रीमियम सुविधाएं एक्सेस करें",
  purchaseTips: "स्वचालित रूप से नवीनीकृत करें और किसी भी समय रद्द करें।",
  tips: "उपाय:",
  promoCodeInvalidTips: "आपका प्रोमो कोड अमान्य है",
};
