import { request } from "./request";
import { api_config } from "@/constants/api";

let apiUrlV2 = api_config.API_V2_URL;

/**
 * 请求游客token
 * @param {object} 请求参数
 */
const requsetGetAnonymousSessions = () => {
  const options = {
    method: "POST",
  };
  return new Promise((resolve, reject) => {
    request(apiUrlV2 + "tmp-sessions", options, true)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

/**
 * 请求用户信息
 * @param {object} 请求参数
 */
const requsetGetProfile = () => {
  const options = {
    method: "GET",
  };
  return new Promise((resolve, reject) => {
    request(apiUrlV2 + "accounts/profile", options)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

/**
 * 请求获取用户订阅信息
 * @param {object} 请求参数
 */
const requsetGetSubscription = params => {
  const options = {
    ...params,
    method: "GET",
  };
  return new Promise((resolve, reject) => {
    request(apiUrlV2 + "accounts/subscription", options)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

/**
 * 请求获取用户订阅信息
 * @param {object} 请求参数
 */
const requsetGetPurchase = params => {
  const options = {
    ...params,
    method: "POST",
  };
  return new Promise((resolve, reject) => {
    request(apiUrlV2 + "check-out", options)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

/**
 * 请求获取用户主题信息
 * @param {object} 请求参数
 */
const requsetGetAppearance = params => {
  const options = {
    ...params,
    method: "GET",
  };
  return new Promise((resolve, reject) => {
    request(apiUrlV2 + "preferences", options)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export { requsetGetAnonymousSessions, requsetGetProfile, requsetGetSubscription, requsetGetPurchase, requsetGetAppearance };
