export default {
  'Image': 'Image',
  'Text': 'Texte',
  'Add image': 'Ajouter une image',
  'Opacity': 'Opacité',
  "Add text": 'Ajouter du texte',
  'Text Color': 'Couleur du texte',
  'Outline': 'Contour',
  'Background': 'Fond',
  'Text here': 'Texte ici',
}