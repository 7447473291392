export default {
  'Image': 'Imagen',
  'Text': 'Texto',
  'Add image': 'Añadir imagen',
  'Opacity': 'Opacidad',
  "Add text": 'Añadir texto',
  'Text Color': 'Color de texto',
  'Outline': 'Marco',
  'Background': 'Fondo',
  'Text here': 'Texto aquí',
}