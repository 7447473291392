/**
 * ar: 阿拉伯语
 * br: 葡萄牙语
 * cn: 简体中文
 * de: 德语
 * en: 英语
 * es: 西语
 * fr: 法语
 * id: 印尼语
 * inn: 印度语
 * it: 意大利语
 * jp: 日语
 * kr: 韩语
 * nl: 荷兰语
 * ru: 俄语
 * tr: 土耳其语
 * tw: 繁体中文
 */

//各语种 SEO站点 首页 链接
const HitPaw_Home_LinkData = {
  ar: "https://ar.hitpaw.com/",
  br: "https://www.hitpaw.com.br/",
  cn: "",
  de: "https://www.hitpaw.de/",
  en: "https://www.hitpaw.com/",
  es: "https://www.hitpaw.es/",
  fr: "https://www.hitpaw.fr/",
  id: "https://www.hitpaw.id/",
  inn: "https://www.hitpaw.in/",
  it: "https://www.hitpaw.it/",
  jp: "https://www.hitpaw.jp/",
  kr: "https://www.hitpaw.kr/",
  nl: "https://www.hitpaw.nl/",
  ru: "https://www.hitpaw.ru/",
  tr: "https://tr.hitpaw.com/",
  tw: "https://www.hitpaw.tw/",
};

//各语种 SEO站点 去水印产品页 链接
const HitPaw_WatermarkRemover_Product_LinkData = {
  ar: "https://ar.hitpaw.com/remove-watermark.html",
  br: "https://www.hitpaw.com.br/remove-watermark.html",
  cn: "",
  de: "https://www.hitpaw.de/remove-watermark.html",
  en: "https://www.hitpaw.com/remove-watermark.html",
  es: "https://www.hitpaw.es/remove-watermark.html",
  fr: "https://www.hitpaw.fr/remove-watermark.html",
  id: "https://www.hitpaw.id/remove-watermark.html",
  inn: "https://www.hitpaw.in/remove-watermark.html",
  it: "https://www.hitpaw.it/remove-watermark.html",
  jp: "https://www.hitpaw.jp/remove-watermark.html",
  kr: "https://www.hitpaw.kr/remove-watermark.html",
  nl: "https://www.hitpaw.nl/remove-watermark.html",
  ru: "https://www.hitpaw.ru/remove-watermark.html",
  tr: "https://tr.hitpaw.com/remove-watermark.html",
  tw: "https://www.hitpaw.tw/remove-watermark.html",
};

//各语种 SEO站点 落地页 链接
const HitPaw_OnlineTools_LinkData = {
  ar: "https://ar.hitpaw.com/online-tools.html",
  br: "https://online.hitpaw.com.br/",
  cn: "",
  de: "https://online.hitpaw.de/",
  en: "https://online.hitpaw.com/",
  es: "https://online.hitpaw.es/",
  fr: "https://online.hitpaw.fr/",
  id: "https://online.hitpaw.id/",
  inn: "https://online.hitpaw.in/",
  it: "https://online.hitpaw.it/",
  jp: "https://online.hitpaw.jp/",
  kr: "https://online.hitpaw.kr/",
  nl: "https://online.hitpaw.nl/",
  ru: "https://online.hitpaw.ru/",
  tr: "https://tr.hitpaw.com/online-tools.html",
  tw: "https://online.hitpaw.tw/",
};

//各语种 SEO站点 HitPaw Toolkit 购买页面 链接
const HitPawToolkit_Buy_LinkData = {
  ar: {
    win: "https://ar.hitpaw.com/purchase/buy-hitpaw-toolkit.html",
    mac: "https://ar.hitpaw.com/purchase/buy-hitpaw-toolkit-mac.html",
  },
  br: {
    win: "https://www.hitpaw.com.br/purchase/buy-hitpaw-toolkit.html",
    mac: "https://www.hitpaw.com.br/purchase/buy-hitpaw-toolkit-mac.html",
  },
  cn: {
    win: "",
    mac: "",
  },
  de: {
    win: "https://www.hitpaw.de/purchase/buy-hitpaw-toolkit.html",
    mac: "https://www.hitpaw.de/purchase/buy-hitpaw-toolkit-mac.html",
  },
  en: {
    win: "https://www.hitpaw.com/purchase/buy-hitpaw-toolkit.html",
    mac: "https://www.hitpaw.com/purchase/buy-hitpaw-toolkit-mac.html",
  },
  es: {
    win: "https://www.hitpaw.es/purchase/buy-hitpaw-toolkit.html",
    mac: "https://www.hitpaw.es/purchase/buy-hitpaw-toolkit-mac.html",
  },
  fr: {
    win: "https://www.hitpaw.fr/purchase/buy-hitpaw-toolkit.html",
    mac: "https://www.hitpaw.fr/purchase/buy-hitpaw-toolkit-mac.html",
  },
  id: {
    win: "https://www.hitpaw.id/purchase/buy-hitpaw-toolkit.html",
    mac: "https://www.hitpaw.id/purchase/buy-hitpaw-toolkit-mac.html",
  },
  inn: {
    win: "https://www.hitpaw.in/purchase/buy-hitpaw-toolkit.html",
    mac: "https://www.hitpaw.in/purchase/buy-hitpaw-toolkit-mac.html",
  },
  it: {
    win: "https://www.hitpaw.it/purchase/buy-hitpaw-toolkit.html",
    mac: "https://www.hitpaw.it/purchase/buy-hitpaw-toolkit-mac.html",
  },
  jp: {
    win: "https://www.hitpaw.jp/purchase/buy-hitpaw-toolkit.html",
    mac: "https://www.hitpaw.jp/purchase/buy-hitpaw-toolkit-mac.html",
  },
  kr: {
    win: "https://www.hitpaw.kr/purchase/buy-hitpaw-toolkit.html",
    mac: "https://www.hitpaw.kr/purchase/buy-hitpaw-toolkit-mac.html",
  },
  nl: {
    win: "https://www.hitpaw.nl/purchase/buy-hitpaw-toolkit.html",
    mac: "https://www.hitpaw.nl/purchase/buy-hitpaw-toolkit-mac.html",
  },
  ru: {
    win: "https://www.hitpaw.ru/purchase/buy-hitpaw-toolkit.html",
    mac: "https://www.hitpaw.ru/purchase/buy-hitpaw-toolkit-mac.html",
  },
  tr: {
    win: "https://tr.hitpaw.com/purchase/buy-hitpaw-toolkit.html",
    mac: "https://tr.hitpaw.com/purchase/buy-hitpaw-toolkit-mac.html",
  },
  tw: {
    win: "",
    mac: "",
  },
};

//各语种 SEO站点 HitPaw Watermark Remover 购买页面 链接
const HitPawWatermarkRemover_Buy_LinkData = {
  ar: {
    win: "https://ar.hitpaw.com/purchase/buy-hitpaw-remove-watermark.html",
    mac: "https://ar.hitpaw.com/purchase/buy-hitpaw-remove-watermark-mac.html",
  },
  br: {
    win: "https://www.hitpaw.com.br/purchase/buy-hitpaw-remove-watermark.html",
    mac: "https://www.hitpaw.com.br/purchase/buy-hitpaw-remove-watermark-mac.html",
  },
  cn: {
    win: "",
    mac: "",
  },
  de: {
    win: "https://www.hitpaw.de/purchase/buy-hitpaw-remove-watermark.html",
    mac: "https://www.hitpaw.de/purchase/buy-hitpaw-remove-watermark-mac.html",
  },
  en: {
    win: "https://www.hitpaw.com/purchase/buy-hitpaw-remove-watermark.html",
    mac: "https://www.hitpaw.com/purchase/buy-hitpaw-remove-watermark-mac.html",
  },
  es: {
    win: "https://www.hitpaw.es/purchase/buy-hitpaw-remove-watermark.html",
    mac: "https://www.hitpaw.es/purchase/buy-hitpaw-remove-watermark-mac.html",
  },
  fr: {
    win: "https://www.hitpaw.fr/purchase/buy-hitpaw-remove-watermark.html",
    mac: "https://www.hitpaw.fr/purchase/buy-hitpaw-remove-watermark-mac.html",
  },
  id: {
    win: "https://www.hitpaw.id/purchase/buy-hitpaw-remove-watermark.html",
    mac: "https://www.hitpaw.id/purchase/buy-hitpaw-remove-watermark-mac.html",
  },
  inn: {
    win: "https://www.hitpaw.in/purchase/buy-hitpaw-remove-watermark.html",
    mac: "https://www.hitpaw.in/purchase/buy-hitpaw-remove-watermark-mac.html",
  },
  it: {
    win: "https://www.hitpaw.it/purchase/buy-hitpaw-remove-watermark.html",
    mac: "https://www.hitpaw.it/purchase/buy-hitpaw-remove-watermark-mac.html",
  },
  jp: {
    win: "https://www.hitpaw.jp/purchase/buy-hitpaw-remove-watermark.html",
    mac: "https://www.hitpaw.jp/purchase/buy-hitpaw-remove-watermark-mac.html",
  },
  kr: {
    win: "https://www.hitpaw.kr/purchase/buy-hitpaw-remove-watermark.html",
    mac: "https://www.hitpaw.kr/purchase/buy-hitpaw-remove-watermark-mac.html",
  },
  nl: {
    win: "https://www.hitpaw.nl/purchase/buy-hitpaw-remove-watermark.html",
    mac: "https://www.hitpaw.nl/purchase/buy-hitpaw-remove-watermark-mac.html",
  },
  ru: {
    win: "https://www.hitpaw.ru/purchase/buy-hitpaw-remove-watermark.html",
    mac: "https://www.hitpaw.ru/purchase/buy-hitpaw-remove-watermark-mac.html",
  },
  tr: {
    win: "https://tr.hitpaw.com/purchase/buy-hitpaw-remove-watermark.html",
    mac: "https://tr.hitpaw.com/purchase/buy-hitpaw-remove-watermark-mac.html",
  },
  tw: {
    win: "https://www.hitpaw.tw/purchase/buy-hitpaw-remove-watermark.html",
    mac: "https://www.hitpaw.tw/purchase/buy-hitpaw-remove-watermark-mac.html",
  },
};

//各语种 HitPaw Toolkit 安装包 链接
const HitPawToolkit_EXE_LinkData = {
  ar: {
    win: "https://download.hitpaw.com/go/hitpaw-toolkit_2853.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-toolkit-mac_3253.dmg",
  },
  br: {
    win: "https://download.hitpaw.com/go/hitpaw-toolkit_2852.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-toolkit-mac_3252.dmg",
  },
  cn: {
    win: "",
    mac: "",
  },
  de: {
    win: "https://download.hitpaw.com/go/hitpaw-toolkit_2847.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-toolkit-mac_3247.dmg",
  },
  en: {
    win: "https://download.hitpaw.com/go/hitpaw-toolkit_2702.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-toolkit-mac_3244.dmg",
  },
  es: {
    win: "https://download.hitpaw.com/go/hitpaw-toolkit_2848.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-toolkit-mac_3248.dmg",
  },
  fr: {
    win: "https://download.hitpaw.com/go/hitpaw-toolkit_2849.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-toolkit-mac_3249.dmg",
  },
  id: {
    win: "https://download.hitpaw.com/go/hitpaw-toolkit_2921.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-toolkit-mac_3245.dmg",
  },
  inn: {
    win: "https://download.hitpaw.com/go/hitpaw-toolkit_3101.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-toolkit-mac_3367.dmg",
  },
  it: {
    win: "https://download.hitpaw.com/go/hitpaw-toolkit_2854.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-toolkit-mac_3366.dmg",
  },
  jp: {
    win: "https://download.hitpaw.com/go/hitpaw-toolkit_2850.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-toolkit-mac_3250.dmg",
  },
  kr: {
    win: "https://download.hitpaw.com/go/hitpaw-toolkit_2943.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-toolkit-mac_3352.dmg",
  },
  nl: {
    win: "https://download.hitpaw.com/go/hitpaw-toolkit_3320.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-toolkit-mac_3505.dmg",
  },
  ru: {
    win: "https://download.hitpaw.com/go/hitpaw-toolkit_2851.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-toolkit-mac_3251.dmg",
  },
  tr: {
    win: "https://download.hitpaw.com/go/hitpaw-toolkit_2947.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-toolkit-mac_3246.dmg",
  },
  tw: {
    win: "",
    mac: "",
  },
};

//各语种 HitPaw Watermark Remover 安装包 链接
const HitPawWatermarkRemover_EXE_LinkData = {
  ar: {
    win: "https://download.hitpaw.com/go/hitpaw-watermark-remover_3563.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-watermark-remover-mac_3564.dmg",
  },
  br: {
    win: "https://download.hitpaw.com/go/hitpaw-watermark-remover_2767.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-watermark-remover-mac_2768.dmg",
  },
  cn: {
    win: "",
    mac: "",
  },
  de: {
    win: "https://download.hitpaw.com/go/hitpaw-watermark-remover_2772.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-watermark-remover-mac_2773.dmg",
  },
  en: {
    win: "https://download.hitpaw.com/go/hitpaw-watermark-remover_2739.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-watermark-remover-mac_2740.dmg",
  },
  es: {
    win: "https://download.hitpaw.com/go/hitpaw-watermark-remover_2758.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-watermark-remover-mac_2760.dmg",
  },
  fr: {
    win: "https://download.hitpaw.com/go/hitpaw-watermark-remover_2759.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-watermark-remover-mac_2761.dmg",
  },
  id: {
    win: "https://download.hitpaw.com/go/hitpaw-watermark-remover_2948.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-watermark-remover-mac_2949.dmg",
  },
  inn: {
    win: "https://download.hitpaw.com/go/hitpaw-watermark-remover_3102.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-watermark-remover-mac_3103.dmg",
  },
  it: {
    win: "https://download.hitpaw.com/go/hitpaw-watermark-remover_2800.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-watermark-remover-mac_2801.dmg",
  },
  jp: {
    win: "https://download.hitpaw.com/go/hitpaw-watermark-remover_2770.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-watermark-remover-mac_2769.dmg",
  },
  kr: {
    win: "https://download.hitpaw.com/go/hitpaw-watermark-remover_2766.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-watermark-remover-mac_2765.dmg",
  },
  nl: {
    win: "https://download.hitpaw.com/go/hitpaw-watermark-remover_3330.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-watermark-remover-mac_3329.dmg",
  },
  ru: {
    win: "https://download.hitpaw.com/go/hitpaw-watermark-remover_2763.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-watermark-remover-mac_2764.dmg",
  },
  tr: {
    win: "https://download.hitpaw.com/go/hitpaw-watermark-remover_3301.exe",
    mac: "https://download.hitpaw.com/go/hitpaw-watermark-remover-mac_3302.dmg",
  },
  tw: {
    win: "",
    mac: "",
  },
};

//各语种 HitPaw Video Editor 安装包 链接
const HitPawVideoEditor_EXE_LinkData = {
  ar: {
    win: "https://download.hitpaw.com/go/hitpaw-video-editor_3541.exe",
    mac: "",
  },
  br: {
    win: "https://download.hitpaw.com/go/hitpaw-video-editor_3535.exe",
    mac: "",
  },
  cn: {
    win: "",
    mac: "",
  },
  de: {
    win: "https://download.hitpaw.com/go/hitpaw-video-editor_3528.exe",
    mac: "",
  },
  en: {
    win: "https://download.hitpaw.com/go/hitpaw-video-editor_3526.exe",
    mac: "",
  },
  es: {
    win: "https://download.hitpaw.com/go/hitpaw-video-editor_3537.exe",
    mac: "",
  },
  fr: {
    win: "https://download.hitpaw.com/go/hitpaw-video-editor_3536.exe",
    mac: "",
  },
  id: {
    win: "https://download.hitpaw.com/go/hitpaw-video-editor_3527.exe",
    mac: "",
  },
  inn: {
    win: "https://download.hitpaw.com/go/hitpaw-video-editor_3531.exe",
    mac: "",
  },
  it: {
    win: "https://download.hitpaw.com/go/hitpaw-video-editor_3539.exe",
    mac: "",
  },
  jp: {
    win: "https://download.hitpaw.com/go/hitpaw-video-editor_3538.exe",
    mac: "",
  },
  kr: {
    win: "https://download.hitpaw.com/go/hitpaw-video-editor_3534.exe",
    mac: "",
  },
  nl: {
    win: "https://download.hitpaw.com/go/hitpaw-video-editor_3532.exe",
    mac: "",
  },
  ru: {
    win: "https://download.hitpaw.com/go/hitpaw-video-editor_3529.exe",
    mac: "",
  },
  tr: {
    win: "https://download.hitpaw.com/go/hitpaw-video-editor_3533.exe",
    mac: "",
  },
  tw: {
    win: "https://download.hitpaw.com/go/hitpaw-video-editor_3540.exe",
    mac: "",
  },
};

//各语种 HitPaw Video Editor 购买 链接
const HitPawVideoEditor_Buy_LinkData = {
  ar: {
    win: "https://ar.hitpaw.com/purchase/buy-hitpaw-video-editor.html",
    mac: "",
  },
  br: {
    win: "https://www.hitpaw.com.br/purchase/buy-hitpaw-video-editor.html",
    mac: "",
  },
  cn: {
    win: "",
    mac: "",
  },
  de: {
    win: "https://www.hitpaw.com/purchase/buy-hitpaw-video-editor.html",
    mac: "",
  },
  en: {
    win: "https://www.hitpaw.com/purchase/buy-hitpaw-video-editor.html",
    mac: "",
  },
  es: {
    win: "https://www.hitpaw.es/purchase/buy-hitpaw-video-editor.html",
    mac: "",
  },
  fr: {
    win: "https://www.hitpaw.fr/purchase/buy-hitpaw-video-editor.html",
    mac: "",
  },
  id: {
    win: "https://www.hitpaw.com/purchase/buy-hitpaw-video-editor.html",
    mac: "",
  },
  inn: {
    win: "https://www.hitpaw.com/purchase/buy-hitpaw-video-editor.html",
    mac: "",
  },
  it: {
    win: "https://www.hitpaw.it/purchase/buy-hitpaw-video-editor.html",
    mac: "",
  },
  jp: {
    win: "https://www.hitpaw.jp/purchase/buy-hitpaw-video-editor.html",
    mac: "",
  },
  kr: {
    win: "https://www.hitpaw.kr/purchase/buy-hitpaw-video-editor.html",
    mac: "",
  },
  nl: {
    win: "https://www.hitpaw.com/purchase/buy-hitpaw-video-editor.html",
    mac: "",
  },
  ru: {
    win: "https://www.hitpaw.ru/purchase/buy-hitpaw-video-editor.html",
    mac: "",
  },
  tr: {
    win: "https://www.hitpaw.com/purchase/buy-hitpaw-video-editor.html",
    mac: "",
  },
  tw: {
    win: "https://www.hitpaw.tw/purchase/buy-hitpaw-video-editor.html",
    mac: "",
  },
};

//各语种 HitPaw Video Editor 产品页 链接
const HitPawVideoEdito_Product_LinkData = {
  ar: "https://ar.hitpaw.com/video-editor.html",
  br: "https://www.hitpaw.com.br/video-editor.html",
  cn: "",
  de: "https://www.hitpaw.com/video-editor.html",
  en: "https://www.hitpaw.com/video-editor.html",
  es: "https://www.hitpaw.es/video-editor.html",
  fr: "https://www.hitpaw.fr/video-editor.html",
  id: "https://www.hitpaw.id/video-editor.html",
  inn: "https://www.hitpaw.com/video-editor.html",
  it: "https://www.hitpaw.it/video-editor.html",
  jp: "https://www.hitpaw.jp/video-editor.html",
  kr: "https://www.hitpaw.kr/video-editor.html",
  nl: "https://www.hitpaw.com/video-editor.html",
  ru: "https://www.hitpaw.com/video-editor.html",
  tr: "https://www.hitpaw.com/video-editor.html",
  tw: "https://www.hitpaw.tw/video-editor.html",
};
export {
  HitPaw_Home_LinkData,
  HitPaw_OnlineTools_LinkData,
  HitPawToolkit_Buy_LinkData,
  HitPawToolkit_EXE_LinkData,
  HitPawWatermarkRemover_EXE_LinkData,
  HitPawWatermarkRemover_Buy_LinkData,
  HitPaw_WatermarkRemover_Product_LinkData,
  HitPawVideoEditor_EXE_LinkData,
  HitPawVideoEditor_Buy_LinkData,
  HitPawVideoEdito_Product_LinkData,
};
