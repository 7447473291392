import React, { Component } from "react";
import actions from "@/action";
import { connect } from "react-redux";
import { workplaceHOC } from "../WorkplaceHOC";
import ScreenshotWorkFunction from "./children/ScreenshotWorkFunction";
import "@/assets/screenshotWork.scss";

class ScreenshotWork extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleUserActionChange = () => {
    this.props.onUserActionChange(true);
  };

  render() {
    return (
      <div style={{ height: "100%" }}>
        <ScreenshotWorkFunction currFile={this.props.currFile} handleUserActionChange={this.handleUserActionChange} />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  currFile: state.files.currFile,
});
const mapDispatchToProps = dispatch => ({
  onUserActionChange: userAction => dispatch(actions.onUserActionChange(userAction)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(workplaceHOC(ScreenshotWork));
