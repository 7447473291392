import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Row, Col } from "antd";
import InputColor from "react-input-color";
import "@/assets/resizeWork.scss";

export const ResizeWorkRender = ({
  currFile,
  resolution,
  filling,
  onResolutionChange,
  onFillingChange,
  videoBgColor,
  color,
  handleQuickSetColor,
  handleSetColor,
  preSubmit,
  backgroundImageList,
}) => {
  const { t } = useTranslation(["resizeWork", "common"]);
  let resolutionDesc;
  switch (resolution) {
    case 1.77:
      resolutionDesc = t("YouTube and High Definition Videos");
      break;
    case 0.56:
      resolutionDesc = t("Story videos (Instagram/Snaps/TikTok)");
      break;
    case 1:
      resolutionDesc = t("Facebook and Instagram Feed videos");
      break;
    case 0.8:
      resolutionDesc = t("Facebook and Twitter Portrait Videos");
      break;
    case 1.25:
      resolutionDesc = t("Twitter and Facebbok Landscape Videos");
      break;
    case 2.33:
      resolutionDesc = t("Facebook Cover Videos And Blog Banners");
      break;
    default:
      resolutionDesc = "";
  }
  console.log(videoBgColor);
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflow: "hidden",
      }}
    >
      <div className="operation-cont">
        <Row gutter={[12, 12]} style={{ padding: "0 1.5rem" }}>
          <Col span={24}>
            <Row gutter={[0, 16]} style={{ marginLeft: "-8px", marginRight: "-8px" }}>
              <Col span={8}>
                <div
                  className={`setResolutionBtn ${resolution === 16 / 9 ? "active" : ""}`}
                  onClick={() => {
                    onResolutionChange(16 / 9);
                  }}
                >
                  16 : 9
                </div>
              </Col>
              <Col span={8}>
                <div
                  className={`setResolutionBtn ${resolution === 9 / 16 ? "active" : ""}`}
                  onClick={() => {
                    onResolutionChange(9 / 16);
                  }}
                >
                  9 : 16
                </div>
              </Col>
              <Col span={8}>
                <div
                  className={`setResolutionBtn ${resolution === 1 ? "active" : ""}`}
                  onClick={() => {
                    onResolutionChange(1);
                  }}
                >
                  1 : 1
                </div>
              </Col>
            </Row>
            <Row style={{ marginLeft: "-8px", marginRight: "-8px" }}>
              <Col span={8}>
                <div
                  className={`setResolutionBtn ${resolution === 4 / 5 ? "active" : ""}`}
                  onClick={() => {
                    onResolutionChange(4 / 5);
                  }}
                >
                  4 : 5
                </div>
              </Col>
              <Col span={8}>
                <div
                  className={`setResolutionBtn ${resolution === 5 / 4 ? "active" : ""}`}
                  onClick={() => {
                    onResolutionChange(5 / 4);
                  }}
                >
                  5 : 4
                </div>
              </Col>
              <Col span={8}>
                <div
                  className={`setResolutionBtn ${resolution === 21 / 9 ? "active" : ""}`}
                  onClick={() => {
                    onResolutionChange(21 / 9);
                  }}
                >
                  21 : 9
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <p className="tips">{resolutionDesc}</p>
        <hr />
        <Row gutter={[12, 12]} style={{ padding: "0 1.5rem" }}>
          <Col span={12}>
            <div
              className={"setFit" + (filling === "fit" ? " active" : "")}
              onClick={() => {
                onFillingChange("fit");
              }}
            >
              <i></i>
              {t("Fit")}
            </div>
          </Col>
          <Col span={12}>
            <div
              className={"setFill" + (filling === "fill" ? " active" : "")}
              onClick={() => {
                onFillingChange("fill");
              }}
            >
              <i></i>
              {t("Fill")}
            </div>
          </Col>
        </Row>
        <div className="color-picker" style={{ padding: "0 1.5rem" }}>
          <div className="more-color">
            <InputColor initialValue="#000" onChange={handleSetColor} placement="top" />
            {color}
          </div>

          <div
            className={`presetColor ${filling !== "fill" && videoBgColor === "blur1" ? "active" : ""}`}
            onClick={() => {
              handleQuickSetColor("blur1");
            }}
          >
            <div
              style={{
                background: "url(" + (currFile ? currFile.thumbnail : "#000000") + ")",
                filter: "blur(1px)",
              }}
            ></div>
          </div>
          <div
            className={`presetColor ${filling !== "fill" && videoBgColor === "blur2" ? "active" : ""}`}
            onClick={() => {
              handleQuickSetColor("blur2");
            }}
          >
            <div
              style={{
                background: "url(" + (currFile ? currFile.thumbnail : "#000000") + ")",
                filter: "blur(3px)",
              }}
            ></div>
          </div>
          <div
            className={`presetColor ${filling !== "fill" && videoBgColor === "blur3" ? "active" : ""}`}
            onClick={() => {
              handleQuickSetColor("blur3");
            }}
          >
            <div
              style={{
                background: "url(" + (currFile ? currFile.thumbnail : "#000000") + ")",
                filter: "blur(6px)",
              }}
            ></div>
          </div>
          <div
            className={`presetColor ${filling !== "fill" && videoBgColor === "#000000" ? "active" : ""}`}
            onClick={() => {
              handleQuickSetColor("#000000");
            }}
          >
            <div style={{ background: "#000000" }}></div>
          </div>
          <div
            className={`presetColor ${filling !== "fill" && videoBgColor === "#626262" ? "active" : ""}`}
            onClick={() => {
              handleQuickSetColor("#626262");
            }}
          >
            <div style={{ background: "#626262" }}></div>
          </div>
          <div
            className={`presetColor ${filling !== "fill" && videoBgColor === "#ffffff" ? "active" : ""}`}
            onClick={() => {
              handleQuickSetColor("#ffffff");
            }}
          >
            <div style={{ background: "#ffffff" }}></div>
          </div>
        </div>
        <Row className="video-bg-list" gutter={[11, 11]} style={{ padding: "0 1.5rem" }}>
          {backgroundImageList.map(item => (
            <Col
              className={filling !== "fill" && videoBgColor === `bg${item}` ? "active" : ""}
              span={8}
              key={item}
              onClick={() => handleQuickSetColor(`bg${item}`)}
            >
              <div className={`item bg${item}`}></div>
            </Col>
          ))}
        </Row>
      </div>
      <div className="save">
        <Button type="primary" block={true} onClick={preSubmit} size="large">
          {t("common:Save")}
        </Button>
      </div>
    </div>
  );
};
