import React, { Component } from "react";
import { Label, Tag, Text } from "react-konva";
import "gifler";

export class AddWatermarkTextCanvas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canvas: null,
    };
  }

  render() {
    return (
      <React.Fragment>
        <Label
          draggable
          x={this.props.shapeProps.position.x}
          y={this.props.shapeProps.position.y}
          onDragEnd={e => this.props.onDragEnd(e, this.props.index)}
          opacity={this.props.handleSubtitleShow(this.props.shapeProps) ? 1 : 0}
          listening={true}
          onMouseEnter={() => {
            if (this.props.layerRef.current) {
              this.props.layerRef.current.parent.container().style.cursor = "move";
            }
          }}
          onMouseLeave={() => {
            if (this.props.layerRef.current) {
              this.props.layerRef.current.parent.container().style.cursor = "default";
            }
          }}
        >
          <Tag fill={this.props.shapeProps.background === "none" ? null : this.props.shapeProps.background} />
          <Text
            text={this.props.shapeProps.subtitle}
            fill={this.props.shapeProps.color}
            fontSize={this.props.shapeProps.fontSize}
            fontFamily={this.props.shapeProps.fontFamily}
            // padding={this.props.shapeProps.background !== 'none' ? 8 : 0}
            padding={8}
            stroke={this.props.shapeProps.border === "none" ? null : this.props.shapeProps.border}
            strokeWidth={0.5}
            // ref={}
          />
        </Label>
      </React.Fragment>
    );
  }
}
