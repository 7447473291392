export default {
  'Files': 'Archivos',
  'Refresh': 'Actualizar',
  'Original file': 'Archivo original',
  'Just now': 'Acabar de',
  'hour ago': 'Hace {{num}} hora',
  'hours ago': 'Hace {{num}} horas',
  'minute ago': 'Hace {{num}} minuto',
  'minutes ago': 'Hace {{num}} minutos',
  'day ago': 'Hace {{num}} día',
  'View All': 'Ver todo',
  'No files': 'Sin archivos...',
  "Don't forget to download your file, it will be deleted after 24 hours": 'No olvides descargar tu archivo, estará eliminado 24 horas después.',
  'Select All': 'Seleccionar todo',
  'Delete': 'Eliminar',
  'Generate Files Successfully!': '¡Archivos se generaron con éxito!',
  'Continue Editing': 'Continuar editando',
  'Edit New Video': 'Editar vídeo nuevo',
  'Download': 'Descargar',
  'Click here to open the Files again':'Haz clic aquí para abrir los archivos nuevamente.',
  'Processing... Please wait': 'Procesando... Por favor espera un momento',
  'Uploading... Please wait': 'Subiendo... Por favor espera un momento',
  'Edit Now': 'Editar ahora',
  'Back to Edit':'Volver a editar',
  'Go Edit':'Editar',

}