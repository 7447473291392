export default {
  'Files': 'File',
  'Refresh': 'Aggiorna',
  'Original file': 'File originale',
  'Just now': 'Proprio ora',
  'hour ago': '{{num}} ora fa',
  'hours ago': '{{num}} ore fa',
  'minute ago': '{{num}} minuto fa',
  'minutes ago': '{{num}} minuti fa',
  'day ago': '{{num}} giorno fa',
  'View All': 'Mostra Tutto',
  'No files': 'Nessun File...',
  "Don't forget to download your file, it will be deleted after 24 hours": 'Non dimentica di scaricare il tuo file, sarà abbondato 24 ore dopo.',
  'Select All': 'Seleziona Tutto',
  'Delete': 'Elimina',
  'Generate Files Successfully!': 'Generazione File Riuscita!',
  'Continue Editing': 'Continua l’Editing',
  'Edit New Video': 'Modifica Nuovo Video',
  'Download': 'Download',
  'Click here to open the Files again':'Fai clic qui per aprire nuovamente i File.',
  'Processing... Please wait': 'Elaborazione in corso... Attendi prego',
  'Uploading... Please wait': 'Caricamento in corso...Attendi prego',
  'Edit Now': 'Modifica',
  'Back to Edit':"Torna a Modifica",
  'Go Edit':'Vai a modificare',

}