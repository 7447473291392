export default {
  fileSizeLimitTitle: "免费账户只允许编辑20MB以下文件，升级至Pro以访问所有高级功能",
  freeTimesLimitTitle: "您今日的试用机会已经用完，升级至Pro以访问所有高级功能",
  total: "全部的",
  enterCode: "输入码",
  signUpToPurchaseBtn: "注册并去购买",
  goPurchseBtn: "去购买",
  saveTips: "最多可节省{{num}}%",
  discount: "折扣",
  montly: "每月",
  yearly: "每年的",
  vipTips1: "无视频大小和数量限制",
  vipTips2: "无去除图像背景次数限制",
  vipTips3: "视频处理和下载速度提高",
  vipTips4: "访问所有免费工具和高级功能",
  purchaseTips: "自动续订并随时取消。",
  tips: "提示：",
  promoCodeInvalidTips: "你的优惠码无效",
};
