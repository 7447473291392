import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import Utils from "@/utils/utils";
import { ReactComponent as Logo } from "@/assets/icons/logo.svg";
import { ReactComponent as Download } from "@/assets/icons/download-pc.svg";
import { ReactComponent as Reback } from "@/assets/icons/reback.svg";
import { ReactComponent as NextThin } from "@/assets/icons/thin/next-page.svg";
import { ReactComponent as NextBold } from "@/assets/icons/bold/next-page.svg";
import { ReactComponent as PreThin } from "@/assets/icons/thin/pre-page.svg";
import { ReactComponent as PreBold } from "@/assets/icons/bold/pre-page.svg";
import "@/assets/navbar.scss";

export const NavbarRender = ({
  downloadClient,
  handleClick,
  handleCurrPage,
  uploading,
  fileToBlobStatus,
  work,
  menuList,
  pageMenuList,
  currPage,
  successFileGuids,
  currFile,
  submitting,
  uploadPercent,
  processPercent,
  theme,
}) => {
  const { t } = useTranslation(["navbar"]);
  return (
    <div className="Navbar">
      {work && !successFileGuids.length ? (
        <div className="nav-work">
          <span
            onClick={() => handleClick("")}
            className={`nav-reback ${
              (currFile ? submitting && (uploadPercent > 0 || processPercent > 0) : !fileToBlobStatus && (uploadPercent > 0 || processPercent > 0))
                ? "disabled"
                : ""
            }`}
          >
            <Reback />
          </span>
          {menuList
            .filter(item => item.key === work)
            .map(item => (
              <span className="nav-item" key={item.key}>
                <Tooltip placement="right" title={t(item.title)}>
                  {item.renderIcon()}
                </Tooltip>
              </span>
            ))}
        </div>
      ) : (
        <div className="nav-bottom" style={{ pointerEvents: uploading && !fileToBlobStatus ? "none" : "auto" }}>
          <a className="home-link" href={Utils.getHitPawHomeLink()} style={{ marginBottom: 38 }}>
            <Logo />
          </a>
          {pageMenuList.length > 1 && currPage > 0 ? (
            <div className="pre-icon page-btn" onClick={() => handleCurrPage(-1)}>
              {theme === "dark" ? <PreThin /> : <PreBold />}
            </div>
          ) : null}
          {pageMenuList.length
            ? pageMenuList[currPage].map(item => (
                <span className="nav-item" key={item.key}>
                  <Tooltip placement="right" title={t(item.title)} onClick={() => handleClick(item.key)}>
                    {item.renderIcon()}
                  </Tooltip>
                </span>
              ))
            : null}
          {pageMenuList.length > 1 && currPage < pageMenuList.length - 1 ? (
            <div className="next-icon page-btn" onClick={() => handleCurrPage(1)}>
              {theme === "dark" ? <NextThin /> : <NextBold />}
            </div>
          ) : null}
        </div>
      )}
      <a
        href={
          work === "removeWatermark"
            ? Utils.getHitPawWatermarkRemoverEXELink()
            : window.userClient.platform === "win"
            ? Utils.getHitPawVideoEditorEXELink()
            : Utils.getHitPawToolKitEXELink()
        }
        target="_blank"
        rel="noopener noreferrer"
        className="download"
        onClick={downloadClient}
      >
        <Download />
      </a>
    </div>
  );
};
