import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Select, Tooltip, Slider, Button, Input } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Utils from "@/utils/utils";
import "@/assets/gifWork.scss";

const { Option } = Select;

export const GifWorkRender = ({
  fps,
  marks,
  currFile,
  cutWorkList,
  resolution,
  handleStartChange,
  handleRateChange,
  handleResolutionChange,
  handleEndChange,
  framesRate,
  preSubmit,
  submitting,
  fileListModal,
}) => {
  const { t } = useTranslation(["gifWork", "common", "cropRotateWork"]);
  console.log("fileListModal", fileListModal);
  return (
    <div className={"GifWork" + (!currFile ? " disabled" : "")}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <div>{t("common:Start")}</div>
          <Input
            value={cutWorkList.length > 0 ? cutWorkList.filter(item => item.selected)[0].start : "01:00"}
            onChange={handleStartChange}
            onBlur={() => {
              Utils.handleBlurFormat("CUTWORKLIST_CHANGE", [...cutWorkList]);
            }}
            bordered={false}
          />
        </Col>
        <Col span={12}>
          <div>{t("common:End")}</div>
          <Input
            value={cutWorkList.length > 0 ? cutWorkList.filter(item => item.selected)[0].end : "03:00"}
            onChange={handleEndChange}
            onBlur={() => {
              Utils.handleBlurFormat("CUTWORKLIST_CHANGE", [...cutWorkList]);
            }}
            bordered={false}
          />
        </Col>
        <Col span={24}>
          <div>{t("Resolution")}</div>
          <Select defaultValue={resolution} style={{ width: "100%" }} size="small" onChange={handleResolutionChange}>
            <Option value="origin">{t("cropRotateWork:Original")}</Option>
            <Option value="600:-1">600P*AUTO</Option>
            <Option value="540:-1">540P*AUTO</Option>
            <Option value="480:-1">480P*AUTO</Option>
            <Option value="360:-1">360P*AUTO</Option>
            <Option value="260:-1">260P*AUTO</Option>
            <Option value="100:-1">100P*AUTO</Option>
          </Select>
        </Col>
        <Col span={24}>
          <div>
            <span>{t("Frame rate")} </span>
            <Tooltip placement="topLeft" autoAdjustOverflow={false} title={t("The higher the frame rate, the smoother the animation")}>
              <ExclamationCircleOutlined />
            </Tooltip>
          </div>
          <Slider
            defaultValue={framesRate}
            min={1}
            max={fps}
            tooltipVisible={currFile && !submitting && (!fileListModal || !fileListModal.show) ? true : false}
            marks={marks}
            onChange={handleRateChange}
          />
        </Col>
      </Row>
      <Button type="primary" block={true} onClick={preSubmit} size="middle">
        {t("common:Save")}
      </Button>
    </div>
  );
};
