import React, { Component } from "react";
import { connect } from "react-redux";
import CutPreview from "./children/cutPreview/CutPreview";
import MergePreview from "./children/mergePreview/MergePreview";
import SpeedPreview from "./children/speedPreview/SpeedPreview";
import LoopPreview from "./children/loopPreview/LoopPreview";
import VideoPreview from "./children/videoPreview/VideoPreview";
import CropRotatePreview from "./children/cropRotatePreview/CropRotatePreview";
import ResizePreview from "./children/resizePreview/ResizePreview";
import RemoveWatermarkPreview from "./children/removeWatermarkPreview/RemoveWatermarkPreview";
import AddMusicPreview from "./children/addMusicPreview/AddMusicPreview";
import SubtitlePreview from "./children/subtitlePreview/SubtitlePreview";
import AddWatermarkPreview from "./children/addWatermarkPreview/AddWatermarkPreview";
import ReversePreview from "./children/reversePreview/ReversePreview";
import "@/assets/preview.scss";

class Preview extends Component {
  render() {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        {this.props.successFileGuids.length ? (
          <div className="Preview">
            <VideoPreview />
          </div>
        ) : null}
        <div className="Preview" style={this.props.successFileGuids.length ? { display: "none" } : {}}>
          {!this.props.work ? <VideoPreview /> : null}
          {this.props.work === "cut" || this.props.work === "gif" ? <CutPreview /> : null}
          {this.props.work === "merge" ? <MergePreview /> : null}
          {this.props.work === "speed" || this.props.work === "screenshot" || this.props.work === "convert" || this.props.work === "mute" ? (
            <SpeedPreview />
          ) : null}
          {this.props.work === "crop_rotate" ? <CropRotatePreview /> : null}
          {this.props.work === "resize" ? <ResizePreview /> : null}
          {this.props.work === "removeWatermark" ? <RemoveWatermarkPreview /> : null}
          {this.props.work === "addMusic" ? <AddMusicPreview /> : null}
          {this.props.work === "subtitle" ? <SubtitlePreview /> : null}
          {this.props.work === "addWatermark" ? <AddWatermarkPreview /> : null}
          {this.props.work === "loop" ? <LoopPreview /> : null}
          {this.props.work === "reverse" ? <ReversePreview /> : null}
          <canvas id="canvas" style={{ display: "none" }}></canvas>
        </div>
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  work: state.work.work,
  successFileGuids: state.files.successFileGuids,
});

export default connect(mapStateToPropos, null)(Preview);
