export default {
  freeTimesLimitTitle: "لقد نفذت تجربتك المجانية اليوم، يرجى الترقية إلى الحساب المميز للوصول إلى جميع المزايا المدفوعة",
  fileSizeLimitTitle: "لا يمكن أن يتعدى حجم الملف للحسابات المجانية 20MB، يرجى الترقية إلى الحساب المميز للوصول إلى جميع المزايا المدفوعة",
  total: "الإجمالي",
  enterCode: "أدخل الرمز",
  signUpToPurchaseBtn: "أنشئ حسابًا للشراء",
  goPurchseBtn: "اذهب إلى الشراء",
  saveTips: "وفر حتى {{num}}%",
  discount: "خصم",
  montly: "سنويًا",
  yearly: "شهريًا",
  vipTips1: "لا يوجد حد لحجم الفيديو وكميته",
  vipTips2: "إزالة الخلفيات من الصور بلا حدود",
  vipTips3: "معالجة وتنزيل الفيديو بشكل أسرع",
  vipTips4: "الوصول إلى جميع المزايا المجانية والمدفوعة",
  purchaseTips: "التجديد تلقائيًا والإلغاء في أي وقت.",
  tips: "نصائح:",
  promoCodeInvalidTips: "رمزك الترويجي غير صالح",
};
