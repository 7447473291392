import React, { Component } from "react";
import { ConvertWorkRender } from "./children/ConvertWorkRender";

export default class ConvertWorkFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <ConvertWorkRender
        convert={this.props.convert}
        currFile={this.props.currFile}
        onConvertChange={this.props.onConvertChange}
        preSubmit={this.props.preSubmit}
      />
    );
  }
}
