import React from "react";
import { Breadcrumb, Slider, Tooltip } from "antd";
import { ReactComponent as Loading } from "@/assets/images/timg.svg";
import { ReactComponent as Screenshot } from "@/assets/icons/camera.svg";
import { ReactComponent as Pause } from "@/assets/icons/pause.svg";
import { ReactComponent as Play } from "@/assets/icons/play.svg";
import moment from "moment";
import Utils from "@/utils/utils";
import { useTranslation } from "react-i18next";

export const AddMusicPreviewRender = ({
  videoReady,
  currFile,
  currentTime,
  currentTimePercent,
  currentVolume,
  handleSoundClick,
  handleSoundDrag,
  handlePlayClick,
  handleCurrentTimeBlur,
  handleCurrentTimeChange,
  playbackRate,
  soundSlide,
  playing,
  screenshots,
  isTimeRangeVisible,
}) => {
  const { t } = useTranslation(["navbar"]);
  return videoReady ? (
    <div>
      <div className="controls">
        {/* <div className="soundControl">
          <SoundOutlined onClick={handleSoundClick} />
          {
            soundSlide ?
              <Slider value={currentVolume} onChange={handleSoundDrag} />
              : null
          }
        </div> */}

        <div className="controls-lft">{playing ? <Pause onClick={handlePlayClick} /> : <Play onClick={handlePlayClick} />}</div>
        <div className="frame-track">
          <div className="active" style={{ width: "100%" }}>
            <Slider
              className="normal play-track"
              value={currentTimePercent}
              tipFormatter={null}
              onChange={handleCurrentTimeChange}
              onAfterChange={handleCurrentTimeBlur}
            />
          </div>
        </div>
        {isTimeRangeVisible ? (
          <Breadcrumb>
            <Breadcrumb.Item className="current-time">
              {moment("00:00:00", "mm:ss:SS")
                .add(Utils.getSeconds(currentTime) / playbackRate, "seconds")
                .format("mm:ss:SS")
                .replace(/:([^:]*)$/, ".$1")}
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {moment("00:00:00", "mm:ss:SS")
                .add(Utils.getSeconds(currFile.duration) / playbackRate, "seconds")
                .format("mm:ss:SS")
                .replace(/:([^:]*)$/, ".$1")}
            </Breadcrumb.Item>
          </Breadcrumb>
        ) : null}

        <Tooltip placement="top" title={t("Screenshot")}>
          <Screenshot className="screenshot-icon" onClick={() => screenshots()} />
        </Tooltip>
      </div>

      {/* <div className="track">
        <div className="frame-track">
          <div className="active" style={{ width: "100%" }}>
            <Slider value={currentTimePercent} tipFormatter={null} onChange={handleCurrentTimeChange} onAfterChange={handleCurrentTimeBlur} />
          </div>
        </div>
      </div> */}
    </div>
  ) : (
    <div style={{ textAlign: "center" }}>
      <Loading />
    </div>
  );
};
