import React, { Component } from "react";
import ReverseWorkRender from "./children/ReverseWorkRender";

export default class ReverseWorkFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loopVideoDuration: "0 sec",
      speedList: [
        { title: "x 0.25", key: 0.25 },
        { title: "x 0.5", key: 0.5 },
        { title: "x 1.0", key: 1 },
        { title: "x 1.25", key: 1.25 },
        { title: "x 1.50", key: 1.5 },
        { title: "x 2.0", key: 2 },
        { title: "x 3.0", key: 3 },
        { title: "x 4.0", key: 4 },
        { title: "x 8.0", key: 8 },
      ],
    };
  }

  render() {
    return (
      <ReverseWorkRender
        speedList={this.state.speedList}
        preSubmit={this.props.preSubmit}
        handleSpeedChange={this.props.handleSpeedChange}
        handleMuteChange={this.props.handleMuteChange}
        currFile={this.props.currFile}
        reverseObj={this.props.reverseObj}
        submitting={this.props.submitting}
        fileListModal={this.props.fileListModal}
        processModalVisible={this.props.processModalVisible}
      />
    );
  }
}
