import React, { Component } from "react";
import SampleFileItemRender from "./children/SampleFileItemRender";
import TipsModalSimple from "@/components/common/TipsModalSimple";
import Utils from "@/utils/utils";
import moment from "moment";
class SampleFileItemFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModal: false,
      editGIFModalVisible: false,
    };
  }

  handleDownloadFile = e => {
    e.stopPropagation();
    Utils.downloadFile(this.props.file, this.props.file.fileType ? this.props.file.fileType : "mp4");
  };

  handleDeleteClick = e => {
    e.stopPropagation();
    this.setState({ deleteModal: false });
    const files = [...this.props.files];
    const delFileUrls = [];
    for (let i = files.length - 1; i >= 0; i--) {
      if (files[i].guid === this.props.file.guid) {
        delFileUrls.push(files[i].jobId);
        files.splice(i, 1);
        break;
      }
    }
    this.props.requsetDeleteAnonymousFileList(delFileUrls);
    this.props.onFilesChange(files);
    localStorage.setItem("files", JSON.stringify(files));
  };

  handleSelectFile = e => {
    e.stopPropagation();
    console.log(this.props.file);
    if (this.props.file.fileType === "gif") {
      this.setState({ editGIFModalVisible: true });
    } else {
      this.props.onCurrFileChange(this.props.file);
    }
  };

  handleConvertGIFToVideo = () => {
    this.setState({
      editGIFModalVisible: false,
    });

    if (this.props.file.originalFileObj) {
      const file = { ...this.props.file.originalFileObj };
      let files = [...this.props.files];
      file.guid = new Date().getTime();
      file.name = moment(new Date()).format("YYYYMMDDHHmmSS") + ".mp4";
      files.unshift(file);
      this.props.onCurrFileChange(file);
      this.props.onFilesChange(files);
      localStorage.setItem("files", JSON.stringify(files));
    }
  };

  render() {
    return (
      <div>
        <SampleFileItemRender
          file={this.props.file}
          handleDownloadFile={this.handleDownloadFile}
          handleDeleteFileModalVisible={e => {
            e.stopPropagation();
            this.setState({ deleteModal: true });
          }}
          handleSelectFile={this.handleSelectFile}
        />
        <TipsModalSimple
          visible={this.state.deleteModal}
          onCancel={() => {
            this.setState({ deleteModal: false });
          }}
          content="Are you sure you want to delete it?"
          cancelBtnHandle={() => {
            this.setState({ deleteModal: false });
          }}
          confirmBtnHandle={this.handleDeleteClick}
          confirmBtnText="Yes"
        />
        <TipsModalSimple
          visible={this.state.editGIFModalVisible}
          onCancel={() => {
            this.setState({ editGIFModalVisible: false });
          }}
          content="GIF does not support editing. Do you want to convert the MP4 for editing?"
          customBtns={[
            {
              text: "Go to Edit",
              style: {
                minWidth: "120px",
                marginTop: "20px",
                marginRight: "20px",
                fontSize: "14px",
              },
              clickFunc: this.handleConvertGIFToVideo,
            },
            {
              text: "Download GIF",
              style: {
                minWidth: "120px",
                marginTop: "20px",
                fontSize: "14px",
              },
              ghost: true,
              clickFunc: e => {
                this.setState({
                  editGIFModalVisible: false,
                });
                this.handleDownloadFile(e);
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default SampleFileItemFunction;
