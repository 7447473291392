export default {
  Start: "Inicio",
  End: "Fin",
  Save: "Guardar",
  Cancel: "Cancelar",
  Download: "Descargar",
  downloading: "descargando",
  "Drag and drop from Files or local disk": "Arrastra y suelta desde archivos o disco local",
  "Click to Add File": "Clic para agregar archivo",
  "The minimum time length is 1s!": "¡El tiempo mínimo es 1s!",
  "The maximum time length is 50s!": "¡El tiempo máximo es 50s!",
  "Try Again": "Reintentar",
  "Coming Soon": "Próximamente",
  "Learn more": "Conoce más",
  Yes: "Sí",
  "Please click the Save button to ensure the previous editing to take effect.":
    "Haz clic en el botón Guardar para asegurarte de que los elementos de configuración surtan efecto.",
  // **************************TipsModal Message Title*****************************
  "Cut 10 video fragments at most. Get HitPaw Toolkit for more features.":
    "Se puede cortar un máximo de 10 fragmentos. Obtén HitPaw Toolkit para disfrutar más funciones.",
  "Max file size for videos is 20 MB. Get HitPaw Toolkit for big file.":
    "El tamaño del archivo no puede ser mayor que 20MB. Obtén HitPaw Toolkit para disfrutar más funciones.",
  "Max file size for videos is 20 MB. Get HitPaw Watermark Remover for big file.":
    "El tamaño del archivo no puede ser mayor que 20MB. Obtén HitPaw Watermark Remover para disfrutar más funciones.",
  "The minimum time length is 1s! Get HitPaw Toolkit for more features.":
    "¡El tiempo mínimo es 1s! Obtén HitPaw Toolkit para disfrutar más funciones.",
  "Add 3 videos at most. Get HitPaw Toolkit for more features.":
    "Se puede agregar un máximo de 3 vídeos. Obtén HitPaw Toolkit para disfrutar más funciones.",
  "This format is not supported currently.": "Este formato no es compatible actualmente.",
  "This format is not supported currently. Get HitPaw Toolkit for more formats":
    "Este formato no es compatible actualmente. Obtén HitPaw Toolkit para disfrutar más funciones.",
  "This format is not supported currently. Get HitPaw Watermark Remover for more formats":
    "Este formato no es compatible actualmente. Obtén HitPaw Watermark Remover para disfrutar más funciones.",
  "Processing failed. Get HitPaw Toolkit for stable processing.": "Falló al generar el vídeo.",
  "Failed to load!  Get HitPaw Watermark Remover for stable processing.": "¡Falló al cargar!",
  "Are you sure you want to delete it?": "¿Estás seguro de eliminarlo?",
  "Failed to extract the audio!": "¡No se pudo extraer el audio!",
  "Video link copied": "Se copió el vínculo del video",
  "Cut 10 video fragments at most.": "Se puede cortar un máximo de 10 fragmentos.",
  "Max file size for videos is 20 MB.": "El tamaño del archivo no puede pesar más de 20MB.",
  "Max file size for videos is 100 MB.": "El tamaño del archivo no puede pesar más de 100MB.",
  "Failed to load!": "¡Falló al cargar!",

  //******************************2021.05.17 v1.3.0 *******************************/
  "Screenshots downloaded.": "Captura de pantalla tomada y descargada.",
  "Online service, no download required": "Edición online, no se necesita descargar",
  "Add up to 20MB file": "El tamaño máximo del archivo es de 20 MB",
  "Support common formats": "Admite formatos comunes",
  "Best for general editing requirements": "Resuelve tus necesidades de edición diarias",
  "0 second wait": "0 segundos de espera",
  "No size limits": "Sin límite de tamaño de archivo",
  "Support 1000+ formats": "Admite más de 1000 formatos",
  "Stable processing": "Procesamiento estable",
  "Get access to HitPaw Toolkit!": "¡Acceder a HitPaw Toolkit!",
  "Support cut, crop & rotate, adjust, speed, add music, stop motion, etc.":
    "Proporciona funciones como cortar, rotar y reflejar, ajustar color, cambiar velocidad y agregar música.",
  "The easiest video editing software for beginners": "El mejor software de edición para principiantes.",
  "Remove watermark from videos and images in simple clicks": "Solo unos clics para quitar marcas de agua de videos e imágenes",
  "TRY IT FREE": "PRUÉBALO GRATIS",
  "BUY NOW": "COMPRA AHORA",
  "HitPaw Online Video Editor": "HitPaw Editor de Video Online",

  //******************************2021.09.06 v1.5.0********************************/
  "Add Video": "Agregar vídeo",
  More: "Más",
  "History files": "Historial de archivos",
  Share: "Compartir",
  Duration: "Duración",
  "Download all": "Descargar todos",
  "Download selected": "Descargar los seleccionados",
  "Add Audio": "Agregar Audio",

  //********************************2021.10.19 v1.6.0*********************************/
  "GIF does not support editing. Do you want to convert the MP4 for editing?":
    "El GIF no admite la edición. ¿Quieres convertir el MP4 para poder editarlo?",
  "Go to Edit": "Ir a Editar",
  "Download GIF": "Descargar GIF",

  //********************************2021.10.19 v1.6.1*********************************/
  "HitPaw Video Editor": "Editor de Video HitPaw",
  "No video size limits": "Sin límite de tamaño de video",
  "Cut/crop/rotate/resize/merge video easily": "corta/recorta/redimensiona/junta videos facilmente",
  "Tons of video effects are available for your video creativity": "Hay toneladas de efectos de videos disponilbles para tu creatividad",
  "Unlimited tracks allow you to add multiple audios as you want": "Pistas ilimitadas que te permiten agregar multiples audios como lo desees.",

  //********************************2021.11.02 v1.6.1*********************************/
  "Download HitPaw Video Editor for more features.": "Descarga el editor de video HitPaw para más opciones.",
  "Get HitPaw Watermark Remover for more features.": "Obtén HitPaw Watermark Remover para disfrutar más funciones.",
  "Get HitPaw Toolkit for more features.": "Obtén HitPaw Toolkit para disfrutar más funciones.",
  "Add 3 videos at most.": "Añade 3 video como máximo.",
  "Processing failed.": "Proceso fallido.",
  "The uploaded video is less than 1 second.": "El video cargado es menor a 1 segundo. ",
  "The video format is not supported.": "El formato de video no es compatible. ",
  "The audio format is not supported.": "El formato de audio no es compatible. ",
  "AddFileByURL.largerSize.HitPawToolkit":
    "¡Analizado con éxito! Tu archivo tiene más de 100 MB y HitPaw Online no puede editarlo, <1><0>descarga el vídeo</0></1> y luego usa HitPaw Toolkit para continuar.",
  "AddFileByURL.largerSize.HitPawWatermarkRemover":
    "¡Analizado con éxito! Tu archivo tiene más de 100 MB y HitPaw Online no puede editarlo, <1><0>descarga el vídeo</0></1> y luego usa HitPaw Watermark Remover para continuar.",
  "AddFileByURL.largerSize.HitPawVideoEditor":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Video Editor to proceed.",
};
