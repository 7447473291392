import React from 'react'
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";

export const AddMusicAddFileRender = ({drop}) => {
  const { t } = useTranslation(["common"]);
  return (
    <div ref={drop} className="add">
      <div className="add-btn">
        <PlusOutlined />
        {t("Add Audio")}
      </div>
      <p>{t("Drag and drop from Files or local disk")}</p>
    </div>
  )
}
