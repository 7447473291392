import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ReactComponent as Close } from "@/assets/icons/close.svg";
import Utils from "@/utils/utils";
import "@/assets/cutWork.scss";

export const CutWorkRender = ({
  currFile,
  cutWorkList,
  addWork,
  handleSwitchSelect,
  handleStartChange,
  handleEndChange,
  removeCutWork,
  preSubmit,
}) => {
  const { t } = useTranslation(["cutWork", "common"]);
  return (
    <div className="CutWork">
      <div className="wrap">
        <div className="add">
          <button onClick={addWork} disabled={currFile ? false : true}>
            <PlusOutlined />
            <span>{t("Add Cut")}</span>
          </button>
        </div>
        {currFile ? (
          <div className="itemlist">
            {cutWorkList.map((item, index) => (
              <div className={`item ${item.selected ? "active" : ""}`} key={index}>
                <div onClick={() => handleSwitchSelect(index)}>
                  {item.thumbnail ? (
                    item.thumbnail.startsWith("blob:") ? (
                      <div className="img-box">
                        <video
                          preload="metadata"
                          src={item.thumbnail}
                          className="img"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        ></video>
                      </div>
                    ) : (
                      <div
                        className="img-box"
                        style={{
                          backgroundImage: `url(${item.thumbnail})`,
                        }}
                      ></div>
                    )
                  ) : currFile.thumbnail.startsWith("blob:") ? (
                    <div className="img-box">
                      <video
                        preload="metadata"
                        src={currFile.thumbnail}
                        className="img"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      ></video>
                    </div>
                  ) : (
                    <div
                      className="img-box"
                      style={{
                        backgroundImage: `url(${currFile.thumbnail})`,
                      }}
                    ></div>
                  )}
                  <div>
                    <div className="time-label">{t("common:Duration")}</div>
                    <div className="time-cont">
                      <Input
                        value={item.start}
                        onChange={handleStartChange}
                        onBlur={() => {
                          Utils.handleBlurFormat("CUTWORKLIST_CHANGE", [...cutWorkList], index);
                        }}
                        bordered={false}
                      />
                      -
                      <Input
                        value={item.end}
                        onChange={handleEndChange}
                        onBlur={() => {
                          Utils.handleBlurFormat("CUTWORKLIST_CHANGE", [...cutWorkList], index);
                        }}
                        bordered={false}
                      />
                    </div>
                  </div>
                </div>
                {cutWorkList.length > 1 ? (
                  <button
                    className="close"
                    onClick={() => {
                      removeCutWork(index);
                    }}
                  >
                    <Close />
                  </button>
                ) : null}
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <div className="save">
        <Button type="primary" block={true} onClick={preSubmit} size="large" disabled={currFile ? false : true}>
          {t("common:Save")}
        </Button>
      </div>
    </div>
  );
};
