import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "@/action";
import { useDrop } from "react-dnd";
import AddMusicPreviewFunction from "./children/AddMusicPreviewFunction";
import { ReactComponent as Close } from "@/assets/icons/close-circle.svg";
import Utils from "@/utils/utils";

const VideoWrap = ({ currFile, onCurrFileChange }) => {
  const [, drop] = useDrop({
    accept: "FileListItem",
    drop: () => ({ name: "SpeedVideoWrap" }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: item => {
      // if ((item.file && item.file.audiofileurl) || (item.file && item.file.status === 'gif')) {
      if ((item.file && item.file.audiofileurl) || (item.file && item.file.status === "gif")) {
        return false;
      }
      return true;
    },
  });
  return (
    <div ref={drop} className="videoWrap" id="videoRef">
      <div style={{ width: "fit-content", height: "fit-content", margin: "auto", position: "relative" }}>
        <button className="close" onClick={() => onCurrFileChange(null)}>
          <Close />
        </button>
        <video className="active" crossOrigin="Anonymous">
          <source src={currFile.videofileurl} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

class AddMusicPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoReady: false,
      isTimeRangeVisible: true,
    };
  }

  getVideoSize = () => {
    const Preview = document.querySelector(".Preview");
    const video = document.querySelector(".videoWrap video");
    const callback = () =>
      this.setState({ videoReady: true }, () => {
        const controls = document.querySelector(".controls");
        controls.style.width = video.clientWidth + "px";
        this.setState({
          isTimeRangeVisible: video.clientWidth < 360 ? false : true,
        });
      });
    Utils.setVideoContainerSize(Preview, video, callback);
  };

  componentDidMount() {
    this.getVideoSize();
    if (this.props.currFile) {
      const myvideo = document.querySelector("#videoRef video");
      myvideo.volume = this.props.addMusicAudioVolumeObj.video || 0.5;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currFile !== this.props.currFile) {
      this.getVideoSize();
      this.setState({
        videoReady: false,
      });
    }
    if (this.props.playbackRate !== prevProps.playbackRate) {
      const myvideo = document.querySelector("#videoRef video");
      myvideo.playbackRate = this.props.playbackRate;
    }
  }

  render() {
    return (
      <div className="SpeedPreview">
        <div id="audioBox">
          <audio muted={false} autoPlay={false}>
            <source src={this.props.addMusicWorkObj.audiofileurl} type="audio/mp3" />
          </audio>
        </div>
        <VideoWrap currFile={this.props.currFile} onCurrFileChange={this.props.onCurrFileChange} />
        <AddMusicPreviewFunction
          videoReady={this.state.videoReady}
          playbackRate={this.props.playbackRate}
          currFile={this.props.currFile}
          addMusicWorkObj={this.props.addMusicWorkObj}
          addMusicAudioTimeObj={this.props.addMusicAudioTimeObj}
          addMusicAudioStartPlayTime={this.props.addMusicAudioStartPlayTime}
          addMusicAudioVolumeObj={this.props.addMusicAudioVolumeObj}
          isTimeRangeVisible={this.state.isTimeRangeVisible}
          submitting={this.props.submitting}
        />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  work: state.work.work,
  playbackRate: state.work.playbackRate,
  addMusicWorkObj: state.work.addMusicWorkObj,
  addMusicAudioTimeObj: state.work.addMusicAudioTimeObj,
  addMusicAudioStartPlayTime: state.work.addMusicAudioStartPlayTime,
  addMusicAudioVolumeObj: state.work.addMusicAudioVolumeObj,
  submitting: state.work.submitting,
});

const mapDispatchToProps = dispatch => ({
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(AddMusicPreview);
