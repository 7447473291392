import Utils from "@/utils/utils";

const lang = Utils.getLanguage();
const path = lang === "inn" ? "/in" : lang === "en" ? "" : `/${lang}`;
const origin = window.location.origin;
const urlMap = new Map([
  ["ar", new Map([["accountLoginPage", "https://ar.hitpaw.com/tools/v2/account.html#/login"]])],
  ["br", new Map([["accountLoginPage", "https://online.hitpaw.com.br/tools/v2/account.html#/login"]])],
  ["cn", new Map([["accountLoginPage", "https://online.hitpaw.com/index.html"]])],
  ["de", new Map([["accountLoginPage", "https://online.hitpaw.de/tools/v2/account.html#/login"]])],
  ["es", new Map([["accountLoginPage", "https://online.hitpaw.es/tools/v2/account.html#/login"]])],
  ["en", new Map([["accountLoginPage", "https://online.hitpaw.com/tools/v2/account.html#/login"]])],
  ["fr", new Map([["accountLoginPage", "https://online.hitpaw.fr/tools/v2/account.html#/login"]])],
  ["id", new Map([["accountLoginPage", "https://online.hitpaw.id/tools/v2/account.html#/login"]])],
  ["in", new Map([["accountLoginPage", "https://online.hitpaw.in/tools/v2/account.html#/login"]])],
  ["it", new Map([["accountLoginPage", "https://online.hitpaw.it/tools/v2/account.html#/login"]])],
  ["jp", new Map([["accountLoginPage", "https://online.hitpaw.jp/tools/v2/account.html#/login"]])],
  ["kr", new Map([["accountLoginPage", "https://online.hitpaw.kr/tools/v2/account.html#/login"]])],
  ["nl", new Map([["accountLoginPage", "https://online.hitpaw.nl/tools/v2/account.html#/login"]])],
  ["ru", new Map([["accountLoginPage", "https://online.hitpaw.ru/tools/v2/account.html#/login"]])],
  ["tr", new Map([["accountLoginPage", "https://tr.hitpaw.com/tools/v2/account.html#/login"]])],
  ["tw", new Map([["accountLoginPage", "https://online.hitpaw.tw/tools/v2/account.html#/login"]])],
]);

//跳转到账号体系项目的链接
// const accountList = new Map([["login", `http://${window.location.hostname}:3001/v2/account.html#/login`]]);
const accountList =
  process.env.NODE_ENV === "development" || process.env.REACT_APP_BUILD_ENV === "development"
    ? new Map([["accountLoginPage", `${origin}${path}/tools/v2/account.html#/login`]])
    : urlMap.get(lang);

export { accountList };
