import React, { Component } from "react";
import { RemoveWatermarkWorkRender } from "./children/RemoveWatermarkWorkRender";

export default class RemoveWatermarkWorkFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <RemoveWatermarkWorkRender
        currFile={this.props.currFile}
        removeWatermarkList={this.props.removeWatermarkList}
        removeWork={this.props.removeWork}
        handleStartChange={this.props.handleStartChange}
        handleEndChange={this.props.handleEndChange}
        handleSwitchSelect={this.props.handleSwitchSelect}
        preSubmit={this.props.preSubmit}
      />
    );
  }
}
