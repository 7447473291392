import React, { Component } from "react";
import { connect } from "react-redux";
import { workplaceHOC } from "../WorkplaceHOC";
import { request } from "@/api/request";
import actions from "@/action";
import moment from "moment";
import Utils from "@/utils/utils";
import ReactGA from "react-ga";
import TipsModal from "@/components/common/TipsModal";
import CutWorkFunction from "./children/CutWorkFunction";
import "@/assets/cutWork.scss";

class CutWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warningInfo: false,
      warningInfoText: "",
    };
  }

  handleStartChange = e => {
    const value = e.target.value;
    const cutWorkList = [...this.props.cutWorkList];
    const cutWork = cutWorkList.filter(item => item.selected)[0];
    cutWork.start = value;
    this.props.onCutWorkListChange(cutWorkList);
    this.props.onUserActionChange(true);
  };

  handleEndChange = e => {
    const value = e.target.value;
    const cutWorkList = [...this.props.cutWorkList];
    const cutWork = cutWorkList.filter(item => item.selected)[0];
    cutWork.end = value;
    this.props.onCutWorkListChange(cutWorkList);
    this.props.onUserActionChange(true);
  };

  handleSubmit = async () => {
    const startTime = { ...this.props.startTime };
    startTime.cut = new Date();
    this.props.onStartTimeChange(startTime);
    if (!this.props.uploading && this.props.uploadPercent < 100) {
      this.props.onProcessPercentChange(1);
      this.props.onProcessStepChange(this.props.cutWorkList.length);
    }
    let guids = [];
    for (let i = 0; i < this.props.cutWorkList.length; i++) {
      let videoResponse;
      try {
        videoResponse = await this.getCutVideo(this.props.cutWorkList[i]);
      } catch (error) {
        this.props.handleSubmitFailModal(true);
        this.props.onProcessPercentChange(0);
        this.props.onProcessStepChange(0);
        this.props.onSubmittingChange(false);
        ReactGA.event(
          {
            category: "Cut",
            action: "CutFail",
            label: error + "_none_none_" + this.props.tokenId + "_none_none",
          },
          ["OnlineTracker", "Tracker"]
        );
        return false;
      }
      if (videoResponse.code === 200) {
        let guid = [];
        try {
          guid = await this.props.getNewVideoByProgress("cut", videoResponse);
        } catch (error) {
          if (error !== "cancel") {
            ReactGA.event(
              {
                category: "Cut",
                action: "CutFail",
                label: "cut_200_200_" + error,
              },
              ["OnlineTracker", "Tracker"]
            );
          }
          return false;
        }
        guids = guids.concat(guid);
      } else {
        if (videoResponse.code === 402102) {
          this.props.onPurchaseModalObjChange({
            visible: true,
            type: "videoEditorTimesMoreThanLimit",
          });
        } else {
          this.props.handleSubmitFailModal(true);
        }
        this.props.onProcessPercentChange(0);
        this.props.onProcessStepChange(0);
        this.props.onSubmittingChange(false);
        ReactGA.event(
          {
            category: "Cut",
            action: "CutFail",
            label: "cut_200_" + videoResponse.code + "_none_" + this.props.tokenId + "_none_" + videoResponse.msg,
          },
          ["OnlineTracker", "Tracker"]
        );
      }
    }
    if (guids.length > 0) {
      //   const modal = {
      //     getContainer: document.querySelector(".Home"),
      //     show: true,
      //     type: "cutSuccess",
      //     guids: guids,
      //     title: "Generate Files Successfully!",
      //     buttons: [
      //       {
      //         type: "download",
      //         text: "Download",
      //       },
      //       {
      //         type: "editNew",
      //         text: "Edit New Video",
      //       },
      //     ],
      //   };
      //   this.props.onFileListModalChange(modal);
      this.props.onSuccessFileGuidsChange(guids);
      // this.props.onWorkChange(null);
      this.props.onPrevWorkChange("cut");
      this.props.onProcessPercentChange(0);
      this.props.onUploadPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "Cut",
          action: "CutSuccess",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
  };

  getCutVideo = obj => {
    const formData = new FormData();
    formData.append("start_time", obj.start);
    formData.append("end_time", obj.end);
    formData.append("file_url", this.props.currFile.videofileurl);
    formData.append("token", this.props.tokenId);
    return new Promise((resolve, reject) => {
      request(`cut`, {
        method: "post",
        body: formData,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  preSubmit = () => {
    ReactGA.event(
      {
        category: "Cut",
        action: "CutSave",
        label: this.props.cutWorkList.length,
      },
      ["OnlineTracker", "Tracker"]
    );
    //函数返回true，则是当前已经超过限制
    let flag = this.props.handleVideoEditorTimes();
    if (!flag) {
      this.props.preSubmit(this.props.cutWorkList.length, this.handleSubmit);
    }
  };

  addWork = () => {
    const cutWorkList = [...this.props.cutWorkList];
    if (cutWorkList.length >= 10) {
      this.setState({
        warningInfo: true,
        warningInfoText: "Cut 10 video fragments at most.",
      });
      ReactGA.event(
        {
          category: "Cut",
          action: "CutOver10",
        },
        ["OnlineTracker", "Tracker"]
      );
      return false;
    }
    cutWorkList.forEach(item => {
      item.selected = false;
    });
    const d = Utils.getSeconds(this.props.currFile.duration);
    const t = d / 2;
    let start, end;
    if (cutWorkList.length > 0 && Utils.getSeconds(this.props.currFile.duration) - Utils.getSeconds(cutWorkList[cutWorkList.length - 1].end) >= 1) {
      start = moment("00:00:00", "mm:ss:SS")
        .add(Utils.getSeconds(cutWorkList[cutWorkList.length - 1].end), "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1");
      end = this.props.currFile.duration;
    } else if (d <= 1) {
      start = "00:00.00";
      end = moment("00:00:00", "mm:ss:SS")
        .add(d, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1");
    } else {
      start = "00:00.00";
      end = moment("00:00:00", "mm:ss:SS")
        .add(t, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1");
    }
    cutWorkList.push({
      start: start,
      end: end,
      selected: true,
      thumbnail: this.props.currFile.screenshots[parseInt((Utils.getSeconds(start) / d) * this.props.currFile.screenshots.length)],
    });
    this.props.onCutWorkListChange(cutWorkList);
    ReactGA.event(
      {
        category: "Cut",
        action: "AddCut",
      },
      ["OnlineTracker", "Tracker"]
    );
  };

  handleSwitchSelect = index => {
    const cutWorkList = [...this.props.cutWorkList];
    cutWorkList.forEach(item => {
      item.selected = false;
    });
    cutWorkList[index].selected = true;
    this.props.onCutWorkListChange(cutWorkList);
  };

  removeCutWork = index => {
    const cutWorkList = [...this.props.cutWorkList];
    if (cutWorkList[index].selected) {
      if (index === 0 && cutWorkList.length > 1) {
        cutWorkList[index + 1].selected = true;
      } else if (index !== 0) {
        cutWorkList[index - 1].selected = true;
      }
    }
    cutWorkList.splice(index, 1);
    this.props.onCutWorkListChange(cutWorkList);
  };

  componentDidMount() {
    console.log("初次进入cuttttew");
    if (this.props.currFile) {
      Utils.initCutWork();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currFile !== this.props.currFile) {
      if (this.props.currFile) {
        Utils.initCutWork();
      } else {
        this.props.onCutWorkListChange([]);
      }
    }
    if (this.props.cutWorkList !== prevProps.cutWorkList && this.props.cutWorkList.length > 0 && this.props.currFile) {
      const cutWorkList = [...this.props.cutWorkList];
      const currCutWork = cutWorkList.filter(item => item.selected)[0];
      const d = Utils.getSeconds(this.props.currFile.duration);
      currCutWork.thumbnail =
        this.props.currFile.screenshots[parseInt((Utils.getSeconds(currCutWork.start) / d) * this.props.currFile.screenshots.length)];
    }
  }

  render() {
    return (
      <div style={{ height: "100%" }}>
        <CutWorkFunction
          currFile={this.props.currFile}
          cutWorkList={this.props.cutWorkList}
          addWork={this.addWork}
          handleSwitchSelect={this.handleSwitchSelect}
          handleStartChange={this.handleStartChange}
          handleEndChange={this.handleEndChange}
          removeCutWork={this.removeCutWork}
          preSubmit={this.preSubmit}
        />
        <TipsModal
          showGuidText
          productKey={window.userClient.platform === "win" ? "hitpaw-video-editor" : undefined}
          visible={this.state.warningInfo}
          content={this.state.warningInfoText}
          onCancel={() => {
            this.setState({ warningInfo: false });
          }}
          cancelBtnHandle={() => {
            this.setState({ warningInfo: false });
          }}
          hasDownload
        />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  work: state.work.work,
  files: state.files.files,
  currFile: state.files.currFile,
  cutWorkList: state.work.cutWorkList,
  fileListModal: state.common.fileListModal,
  tokenId: state.common.tokenId,
  startTime: state.work.startTime,
  uploading: state.work.uploading,
  processStep: state.work.processStep,
  processPercent: state.work.processPercent,
  cancelProgress: state.work.cancelProgress,
  submitting: state.work.submitting,
  uploadPercent: state.work.uploadPercent,
});
const mapDispatchToProps = dispatch => ({
  onFilesChange: files => dispatch(actions.onFilesChange(files)),
  onCutWorkListChange: cutWorkList => dispatch(actions.onCutWorkListChange(cutWorkList)),
  onFileListModalChange: fileListModal => dispatch(actions.onFileListModalChange(fileListModal)),
  onProcessPercentChange: processPercent => dispatch(actions.onProcessPercentChange(processPercent)),
  onProcessStepChange: processStep => dispatch(actions.onProcessStepChange(processStep)),
  onStartTimeChange: startTime => dispatch(actions.onStartTimeChange(startTime)),
  onUserActionChange: userAction => dispatch(actions.onUserActionChange(userAction)),
  onUploadPercentChange: uploadPercent => dispatch(actions.onUploadPercentChange(uploadPercent)),
  onSubmittingChange: submitting => dispatch(actions.onSubmittingChange(submitting)),
  onWorkChange: work => dispatch(actions.onWorkChange(work)),
  onPrevWorkChange: prevWork => dispatch(actions.onPrevWorkChange(prevWork)),
  onSuccessFileGuidsChange: successFileGuids => dispatch(actions.onSuccessFileGuidsChange(successFileGuids)),
  onPurchaseModalObjChange: purchaseModal => dispatch(actions.onPurchaseModalObjChange(purchaseModal)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(workplaceHOC(CutWork));
