export default {
  Start: "Start",
  End: "End",
  Save: "Save",
  Cancel: "Cancel",
  Download: "Download",
  downloading: "downloading",
  "Drag and drop from Files or local disk": "Drag and drop from Files or local disk",
  "Click to Add File": "Click to Add File",
  "The minimum time length is 1s!": "The minimum time length is 1s!",
  "The maximum time length is 50s!": "The maximum time length is 50s!",
  "Try Again": "Try Again",
  "Coming Soon": "Coming Soon",
  "Learn more": "Learn more",
  Yes: "Yes",
  "Please click the Save button to ensure the previous editing to take effect.":
    "Please click the Save button to ensure setting items to take effect.",
  "The thumbnail is missing, but you can download and continue editing videos normally. If you feel troubled, please download the video first and then upload it again.":
    "The thumbnail is missing, but you can download and continue editing videos normally. If you feel troubled, please download the video first and then upload it again.",
  // **************************TipsModal Message Title*****************************/
  "Cut 10 video fragments at most. Get HitPaw Toolkit for more features.": "Cut 10 video fragments at most. Get HitPaw Toolkit for more features.",
  "Max file size for videos is 20 MB. Get HitPaw Toolkit for big file.":
    "The file size cannot be larger than 20MB. Get HitPaw Toolkit for more features.",
  "Max file size for videos is 20 MB. Get HitPaw Watermark Remover for big file.":
    "The file size cannot be larger than 20MB. Get HitPaw Watermark Remover for more features.",
  "The minimum time length is 1s! Get HitPaw Toolkit for more features.": "The minimum time length is 1s! Get HitPaw Toolkit for more features.",
  "Add 3 videos at most. Get HitPaw Toolkit for more features.": "Add 3 videos at most. Get HitPaw Toolkit for more features.",
  "This format is not supported currently.": "This format is not supported currently.",
  "This format is not supported currently. Get HitPaw Toolkit for more formats":
    "This format is not supported currently. Get HitPaw Toolkit for more formats.",
  "This format is not supported currently. Get HitPaw Watermark Remover for more formats":
    "This format is not supported currently. Get HitPaw Watermark Remover for more formats.",
  "Processing failed. Get HitPaw Toolkit for stable processing.": "Processing failed. Get {{name}} for stable processing.",
  "Failed to load!  Get HitPaw Watermark Remover for stable processing.": "Failed to load!  Get {{name}} for stable processing.",
  "Are you sure you want to delete it?": "Are you sure you want to delete it?",
  "Failed to extract the audio!": "Failed to extract the audio!",
  "Video link copied": "Video link copied",
  "Cut 10 video fragments at most.": "Cut 10 video fragments at most.",
  "Max file size for videos is 20 MB.": "The file size cannot be larger than 20Mb.",
  "Max file size for videos is 100 MB.": "The file size cannot be larger than 100Mb.",
  "Failed to load!": "Failed to load!",

  //******************************2021.05.17 v1.3.0 *******************************/
  "Screenshots downloaded.": "Screenshots downloaded.",
  "Online service, no download required": "Online service, no download required",
  "Add up to 20MB file": "Add up to 20MB file",
  "Support common formats": "Support common formats",
  "Best for general editing requirements": "Best for general editing requirements",
  "0 second wait": "0 second wait",
  "No size limits": "No size limits",
  "Support 1000+ formats": "Support 1000+ formats",
  "Stable processing": "Stable processing",
  "Get access to HitPaw Toolkit!": "Get access to HitPaw Toolkit!",
  "Support cut, crop & rotate, adjust, speed, add music, stop motion, etc.":
    "Support cut, crop & rotate, adjust, speed, add music, stop motion, etc.",
  "The easiest video editing software for beginners": "The easiest video editing software for beginners",
  "Remove watermark from videos and images in simple clicks": "Remove watermark from videos and images in simple clicks",
  "TRY IT FREE": "TRY IT FREE",
  "BUY NOW": "BUY NOW",

  //******************************2021.09.06 v1.5.0********************************/
  "Add Video": "Add Video",
  More: "More",
  "History files": "History files",
  Share: "Share",
  Duration: "Duration",
  "Download all": "Download all",
  "Download selected": "Download selected",
  "Add Audio": "Add Audio",

  //********************************2021.10.19 v1.6.0*********************************/
  "GIF does not support editing. Do you want to convert the MP4 for editing?":
    "GIF does not support editing. Do you want to convert the MP4 for editing?",
  "Go to Edit": "Go to Edit",
  "Download GIF": "Download GIF",

  //********************************2021.10.19 v1.6.1*********************************/
  "HitPaw Video Editor": "HitPaw Video Editor",
  "No video size limits": "No video size limits.",
  "Cut/crop/rotate/resize/merge video easily": "Cut/crop/rotate/resize/merge video easily.",
  "Tons of video effects are available for your video creativity": "Tons of video effects are available for your video creativity.",
  "Unlimited tracks allow you to add multiple audios as you want": "Unlimited tracks allow you to add multiple audios as you want.",

  //********************************2021.11.02 v1.6.1*********************************/
  "Download HitPaw Video Editor for more features.": "Download HitPaw Video Editor for more features.",
  "Get HitPaw Watermark Remover for more features.": "Get HitPaw Watermark Remover for more features.",
  "Get HitPaw Toolkit for more features.": "Get HitPaw Toolkit for more features.",
  "Add 3 videos at most.": "Add 3 videos at most.",
  "Processing failed.": "Processing failed.",
  "The uploaded video is less than 1 second.": "The uploaded video is less than 1 second.",
  "The video format is not supported.": "The video format is not supported.",
  "The audio format is not supported.": "The audio format is not supported.",
  "AddFileByURL.largerSize.HitPawToolkit":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Toolkit to proceed.",
  "AddFileByURL.largerSize.HitPawWatermarkRemover":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Watermark Remover to proceed.",
  "AddFileByURL.largerSize.HitPawVideoEditor":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Video Editor to proceed.",
};
