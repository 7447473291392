export default {
  Back: "Kembali",
  Cut: "Potong",
  Speed: "Kecepatan",
  "Crop & Rotate": "Crop & Rotate",
  Resize: "Resize",
  Extract: "Ekstrak",
  "Add Music": "Tambah Musik",
  Subtitles: "Subtitle",
  Merge: "Merge",
  "Watermark Remover": "Penghilang Watermark",
  "Add Watermark": "Tambahkan Watermark",
  "To GIF": "Ke GIF",
  Convert: "Konversi",
  Screenshot: "Screenshot",
  Reverse: "Reverse",
  Loop: "Loop",
};
