export default {
  'Files': 'Файлы',
  'Refresh': 'Обновить',
  'Original file': 'Исходный файл',
  'Just now': 'Прямо сейчас',
  'hour ago': '{{num}} час назад',
  'hours ago': '{{num}} часов назад',
  'minute ago': '{{num}} мин. назад',
  'minutes ago': '{{num}} мин. назад',
  'day ago': '{{num}} день назад',
  'View All': 'Посмотреть все',
  'No files': 'Файлов нет...',
  "Don't forget to download your file, it will be deleted after 24 hours": 'Не забудьте скачать свой файл, через 24 часа он будет удалён.',
  'Select All': 'Выбрать все',
  'Delete': 'Удалять',
  'Generate Files Successfully!': 'Создавать файлы успешно!',
  'Continue Editing': 'Продолжить редактирование',
  'Edit New Video': 'Редактировать новое видео',
  'Download': 'Скачать',
  'Click here to open the Files again':'Щелкните здесь, чтобы снова открыть файлы.',
  'Processing... Please wait': 'Обработка ... Подождите',
  'Uploading... Please wait': 'Загрузка... Подождите',
  'Edit Now': 'Редактировать',
  'Back to Edit': "Назад к редактированию",
  'Go Edit':'начать редактирование'

}