import React, { Component } from "react";
import { Transformer, Image } from "react-konva";
import "gifler";

export class AddWatermarkImageCanvas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canvas: null,
    };
    this.shapeRef = React.createRef();
    this.trRef = React.createRef();
  }

  componentDidMount() {
    this.trRef.current.nodes([this.shapeRef.current]);
    this.trRef.current.getLayer().batchDraw();
    if (this.props.shapeProps.gif && this.shapeRef.current && this.shapeRef.current.getLayer) {
      const canvas = document.querySelector("#GIFRef");
      this.setState({ canvas });
      let anim;
      window.gifler(this.props.shapeProps.imageUrl).get(a => {
        console.log(a);
        anim = a;
        anim.animateInCanvas(canvas);
        anim.onDrawFrame = (ctx, frame) => {
          ctx.drawImage(frame.buffer, frame.x, frame.y);
          this.shapeRef.current.getLayer().draw();
        };
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Image
          ref={this.shapeRef}
          x={this.props.shapeProps.position.x}
          y={this.props.shapeProps.position.y}
          image={this.props.shapeProps.gif ? this.state.canvas : this.props.shapeProps.imageObj}
          width={this.props.shapeProps.width}
          height={this.props.shapeProps.height}
          opacity={this.props.show ? this.props.shapeProps.opacity : 0}
          draggable
          dragBoundFunc={pos => {
            const newY =
              pos.y < 0
                ? 0
                : pos.y > this.props.canvasClient.h - this.props.shapeProps.height / this.props.videoScale.y
                ? this.props.canvasClient.h - this.props.shapeProps.height / this.props.videoScale.y
                : pos.y;
            const newX =
              pos.x < 0
                ? 0
                : pos.x > this.props.canvasClient.w - this.props.shapeProps.width / this.props.videoScale.x
                ? this.props.canvasClient.w - this.props.shapeProps.width / this.props.videoScale.x
                : pos.x;
            return {
              x: newX,
              y: newY,
            };
          }}
          onDragEnd={e => {
            const position = { x: e.target.x(), y: e.target.y() };
            this.props.onChange({
              ...this.props.shapeProps,
              position,
            });
          }}
          onTransformEnd={e => {
            const node = this.shapeRef.current;
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();
            node.scaleX(1);
            node.scaleY(1);
            const position = { x: node.x(), y: node.y() };
            this.props.onChange({
              ...this.props.shapeProps,
              position,
              width: Math.max(5, node.width() * scaleX),
              height: Math.max(node.height() * scaleY),
            });
          }}
          onClick={() => {
            this.props.onChange({
              ...this.props.shapeProps,
              selected: true,
            });
          }}
        />
        <Transformer
          ref={this.trRef}
          boundBoxFunc={(oldBox, newBox) => {
            const video = document.querySelector("#videoRef video");
            const limitX = video.videoWidth / this.props.videoScale.x;
            const limitY = video.videoHeight / this.props.videoScale.y;
            if (newBox.width > limitX || newBox.height > limitY || newBox.width < 50 || newBox.height < 50) {
              return oldBox;
            }
            return newBox;
          }}
          anchorStroke="#8372FF"
          anchorFill="#8372FF"
          anchorSize={5}
          borderStroke="#8372FF"
          rotateEnabled={false}
          opacity={this.props.show && this.props.shapeProps.selected ? 1 : 0}
          resizeEnabled={this.props.shapeProps.selected}
        />
      </React.Fragment>
    );
  }
}
