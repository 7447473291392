export default {
  Start: "Mulai",
  End: "Akhir",
  Save: "Simpan",
  Cancel: "Batal",
  Download: "Unduh",
  downloading: "Mengunduh",
  "Drag and drop from Files or local disk": "Geser dan lepas dari File atau disk lokal",
  "Click to Add File": "Klik untuk Menambahkan File",
  "The minimum time length is 1s!": "Panjang waktu minimal adalah 1s!",
  "The maximum time length is 50s!": "最大时长为50s！",
  "Try Again": "Coba Lagi",
  "Coming Soon": "Segera hadir",
  "Learn more": "Belajarlah lagi",
  Yes: "Ya",
  "Please click the Save button to ensure the previous editing to take effect.":
    "Silakan klik tombol Simpan untuk memastikan item pengaturan berjalan.",
  // **************************TipsModal Message Title*****************************
  "and then use HitPaw Toolkit to proceed.": "dan kemudian gunakan HitPaw Toolkit untuk melanjutkan.",
  "Cut 10 video fragments at most. Get HitPaw Toolkit for more features.":
    "Potong 10 fragmen video paling banyak. Dapatkan HitPaw Toolkit untuk fitur lebih banyak.",
  "Max file size for videos is 20 MB. Get HitPaw Toolkit for big file.":
    "Ukuran file tidak boleh lebih dari 20MB. Dapatkan HitPaw Toolkit untuk fitur lebih banyak.",
  "Max file size for videos is 20 MB. Get HitPaw Watermark Remover for big file.":
    "Ukuran file tidak boleh lebih dari 20MB. Dapatkan HitPaw Watermark Remover untuk fitur lebih banyak.",
  "The minimum time length is 1s! Get HitPaw Toolkit for more features.":
    "Panjang waktu minimal adalah 1s! Dapatkan HitPaw Toolkit untuk fitur lebih banyak.",
  "Add 3 videos at most. Get HitPaw Toolkit for more features.": "Tambah 3 video paling banyak. Dapatkan HitPaw Toolkit untuk fitur lebih banyak.",
  "This format is not supported currently.": "Format ini tidak didukung saat ini.",
  "This format is not supported currently. Get HitPaw Toolkit for more formats":
    "Format ini tidak didukung saat ini. Dapatkan HitPaw Toolkit untuk fitur lebih banyak.",
  "This format is not supported currently. Get HitPaw Watermark Remover for more formats":
    "Format ini tidak didukung saat ini. Dapatkan HitPaw Watermark Remover untuk fitur lebih banyak.",
  "Processing failed. Get HitPaw Toolkit for stable processing.": "Gagal Membuat Video!",
  "Failed to load!  Get HitPaw Watermark Remover for stable processing.": "Gagal memuat!",
  "Are you sure you want to delete it?": "Anda yakin ingin menghapusnya?",
  "Failed to extract the audio!": "Gagal mengekstrak audio!",
  "Video link copied": "Tautan video disalin",
  "Cut 10 video fragments at most.": "Potong 10 fragmen video paling banyak.",
  "Max file size for videos is 20 MB.": "Ukuran file tidak boleh lebih besar dari 20Mb.",
  "Max file size for videos is 100 MB.": "Ukuran file tidak boleh lebih besar dari 100Mb.",
  "Failed to load!": "Gagal memuat!",

  //******************************2021.05.17 v1.3.0 *******************************/
  "Screenshots downloaded.": "Screenshot diunduh.",
  "Online service, no download required": "Layanan online, tidak perlu mengunduh.",
  "Add up to 20MB file": "Tambahkan file hingga 20MB",
  "Support common formats": "Mendukung format umum",
  "Best for general editing requirements": "Terbaik untuk kebutuhan pengeditan umum.",
  "0 second wait": "Tunggu 0 detik",
  "No size limits": "Tanpa batas ukuran",
  "Support 1000+ formats": "Mendukung 1000+ format",
  "Stable processing": "Pemrosesan yang stabil",
  "Get access to HitPaw Toolkit!": "Dapatkan akses HitPaw Toolkit!",
  "Support cut, crop & rotate, adjust, speed, add music, stop motion, etc.":
    "Mendukung cut, crop & rotate, sesuaikan, kecepatan, tambahkan musik, stop motion, dll.",
  "The easiest video editing software for beginners": "Software pengeditan video termudah untuk pemula",
  "Remove watermark from videos and images in simple clicks": "Hilangkan watermark dari video dan gambar dengan beberapa klik",
  "TRY IT FREE": "COBA GRATIS",
  "BUY NOW": "BELI SEKARANG",

  //******************************2021.09.06 v1.5.0********************************/
  "Add Video": "Tambah Video",
  More: "Lagi",
  "History files": "File sejarah",
  Share: "Membagikan",
  Duration: "Durasi",
  "Download all": "Unduh Semua",
  "Download selected": "Unduh Sebagian",
  "Add Audio": "Tambah Audio",

  //********************************2021.10.19 v1.6.0*********************************/
  "GIF does not support editing. Do you want to convert the MP4 for editing?":
    "GIF tidak mendukung pengeditan. Apakah Anda ingin mengonversi MP4 untuk diedit?",
  "Go to Edit": "Pergi ke Edit",
  "Download GIF": "Unduh GIF",

  //********************************2021.10.19 v1.6.1*********************************/
  "HitPaw Video Editor": "Editor Video HitPaw",
  "No video size limits": "Tidak ada batasan ukuran video.",
  "Cut/crop/rotate/resize/merge video easily": "Potong/pangkas/putar/ubah ukuran/gabungkan video dengan mudah.",
  "Tons of video effects are available for your video creativity": "Banyak efek video tersedia untuk kreativitas video Anda.",
  "Unlimited tracks allow you to add multiple audios as you want": "Trek tanpa batas memungkinkan Anda menambahkan beberapa audio sesuai keinginan.",

  //********************************2021.11.02 v1.6.1*********************************/
  "Download HitPaw Video Editor for more features.": "Unduh HitPaw Video Editor untuk lebih banyak fitur.",
  "Get HitPaw Watermark Remover for more features.": "Dapatkan HitPaw Watermark Remover untuk fitur lebih banyak.",
  "Get HitPaw Toolkit for more features.": "Dapatkan HitPaw Toolkit untuk fitur lebih banyak.",
  "Add 3 videos at most.": "Tambahkan 3 video paling banyak.",
  "Processing failed.": "Pemrosesan gagal.",
  "The uploaded video is less than 1 second.": "Video yang diunggah kurang dari 1 detik. ",
  "The video format is not supported.": "Format video tidak didukung. ",
  "The audio format is not supported.": "Format audio tidak didukung. ",
  "AddFileByURL.largerSize.HitPawToolkit":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Toolkit to proceed.",
  "AddFileByURL.largerSize.HitPawWatermarkRemover":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Watermark Remover to proceed.",
  "AddFileByURL.largerSize.HitPawVideoEditor":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Video Editor to proceed.",
};
