import React, { Component } from "react";
import { MuteWorkRender } from "./children/MuteWorkRender";

export default class MuteWorkFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <MuteWorkRender
        currFile={this.props.currFile}
        muteType={this.props.muteType}
        handleMuteType={this.props.handleMuteType}
        preSubmit={this.props.preSubmit}
      />
    );
  }
}
