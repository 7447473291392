export default {
  'Files': 'Fichiers',
  'Refresh': 'Actualiser',
  'Original file': 'Fichier original',
  'Just now': "Tout à l'heure",
  'hour ago': 'Il y a {{num}} heure',
  'hours ago': 'Il y a {{num}} heures',
  'minute ago': 'Il y a {{num}} minute',
  'minutes ago': 'Il y a {{num}} minutes',
  'day ago': 'Il y a {{num}} jour',
  'View All': 'Voir tout',
  'No files': 'Aucun fichier...',
  "Don't forget to download your file, it will be deleted after 24 hours": "N'oubliez pas de télécharger votre fichier, il sera abondant 24h plus tard.",
  'Select All': 'Tout sélectionner',
  'Delete': 'Supprimer',
  'Generate Files Successfully!': 'Le fichier est généré avec succès!',
  'Continue Editing': 'Continuer à éditer',
  'Edit New Video': 'Éditer une nouvelle vidéo',
  'Download': 'Télécharger',
  'Click here to open the Files again':'Cliquez ici pour ouvrir à nouveau les Fichiers.',
  'Processing... Please wait': 'Traitement ... Veuillez patienter',
  'Uploading... Please wait': 'Téléchargement... Veuillez patienter',
  'Edit Now': 'Éditer',
  'Back to Edit':"Retour à l'édition",
  'Go Edit':'Allez modifier'
}