import Types from "../types";

export function onWorkChange(work) {
  return { type: Types.WORK_CHANGE, work: work };
}

export function onPrevWorkChange(prevWork) {
  return { type: Types.PREVWORK_CHANGE, prevWork: prevWork };
}

export function onCutWorkListChange(cutWorkList) {
  return { type: Types.CUTWORKLIST_CHANGE, cutWorkList: cutWorkList };
}

export function onMergeWorkListChange(mergeWorkList) {
  return { type: Types.MERGEWORKLIST_CHANGE, mergeWorkList: mergeWorkList };
}

export function onResolutionChange(resolution) {
  return { type: Types.RESOLUTION_CHANGE, resolution: resolution };
}

export function onVideoBgColorChange(videoBgColor) {
  return { type: Types.MERGEREBGCOLOR_CHANGE, videoBgColor: videoBgColor };
}

export function onUploadingChange(uploading) {
  return { type: Types.UPLOADING_CHANGE, uploading: uploading };
}

export function onUploadPercentChange(uploadPercent) {
  return { type: Types.UPLOADPERCENT_CHANGE, uploadPercent: uploadPercent };
}

export function onProcessPercentChange(processPercent) {
  return { type: Types.PROCESSPERCENT_CHANGE, processPercent: processPercent };
}

export function onProcessStepChange(processStep) {
  return { type: Types.PROGRESSSTEP_CHANGE, processStep: processStep };
}

export function onCurrProgressChange(currProgress) {
  return { type: Types.CURRPROGRESS_CHANGE, currProgress: currProgress };
}

export function onStartTimeChange(startTime) {
  return { type: Types.STARTTIME_CHANGE, startTime: startTime };
}

export function onCancelProgressChange(cancelProgress) {
  return { type: Types.CANCELPROGRESS_CHANGE, cancelProgress: cancelProgress };
}

export function onPlaybackRateChange(playbackRate) {
  return { type: Types.PLAYBACKRATE_CHANGE, playbackRate: playbackRate };
}

export function onCropChange(crop) {
  return { type: Types.CROP_CHANGE, crop: crop };
}

export function onRotateChange(rotate) {
  return { type: Types.ROTATE_CHANGE, rotate: rotate };
}

export function onFlipChange(flip) {
  return { type: Types.FLIP_CHANGE, flip: flip };
}

export function onFillingChange(filling) {
  return { type: Types.FILLING_CHANGE, filling: filling };
}

export function onRemoveWatermarkListChange(removeWatermarkList) {
  return { type: Types.REMOVEWATERMARKLIST_CHANGE, removeWatermarkList: removeWatermarkList };
}

export function onDragVideoPositionChange(dragVideoPosition) {
  return { type: Types.DROPVIDEOPOSITION_CHANGE, dragVideoPosition: dragVideoPosition };
}

export function onFileToBlobStatusChange(fileToBlobStatus) {
  return { type: Types.FILETOBLOBSTATUS_CHANGE, fileToBlobStatus: fileToBlobStatus };
}

export function onSubmittingChange(submitting) {
  return { type: Types.SUBMITTING_CHANGE, submitting: submitting };
}

export function onUploadXHRChange(uploadXHR) {
  return { type: Types.UPLOADXHR_CHANGE, uploadXHR: uploadXHR };
}

export function onAddMusicWorkObjChange(addMusicWorkObj) {
  return { type: Types.ADDMUSICWORKOBJ_CHANGE, addMusicWorkObj: addMusicWorkObj };
}

export function onAddMusicAudioTimeObjChange(addMusicAudioTimeObj) {
  return { type: Types.ADDMUSICAUDIOTIMEOBJ_CHANGE, addMusicAudioTimeObj: addMusicAudioTimeObj };
}

export function onAddMusicAudioStartPlayTimeChange(addMusicAudioStartPlayTime) {
  return { type: Types.ADDMUSICAUDIOSTARTPLAYTIME_CHANGE, addMusicAudioStartPlayTime: addMusicAudioStartPlayTime };
}

export function onAddMusicAudioVolumeObjChange(addMusicAudioVolumeObj) {
  return { type: Types.ADDMUSICAUDIOVOLUMEOBJ_CHANGE, addMusicAudioVolumeObj: addMusicAudioVolumeObj };
}

export function onSubtitleWorkListChange(subtitleWorkList) {
  return { type: Types.SUBTITLEWORKLIST_CHANGE, subtitleWorkList: subtitleWorkList };
}

export function onQuicklyPositionChange(quicklyPosition) {
  return { type: Types.QUICKLYPOSITION_CHANGE, quicklyPosition: quicklyPosition };
}

export function onAddWatermarkWorkListChange(addWatermarkWorkList) {
  return { type: Types.ADDWATERMARKWORKLIST_CHANGE, addWatermarkWorkList: addWatermarkWorkList };
}

export function onFetchAbortChange(fetchAbort) {
  return { type: Types.FETCHABORT_CHANGE, fetchAbort: fetchAbort };
}

export function onLoopTimesChange(loopTimes) {
  return { type: Types.LOOPTIMES_CHANGE, loopTimes: loopTimes };
}

export function onReverseObjChange(reverseObj) {
  return { type: Types.REVERSEOBJ_CHANGE, reverseObj: reverseObj };
}

export function onProcessModalVisibleChange(processModalVisible) {
  return { type: Types.PROCESSMODALVISIBLE_CHANGE, processModalVisible: processModalVisible };
}
