import React, { Component } from "react";
import { connect } from "react-redux";
import CutWork from "./children/cutWork/CutWork";
import GifWork from "./children/gifWork/GifWork";
import MergeWork from "./children/mergeWork/MergeWork";
import SpeedWork from "./children/speedWork/SpeedWork";
import CropRotateWork from "./children/cropRotateWork/CropRotateWork";
import ResizeWork from "./children/resizeWork/ResizeWork";
import RemoveWatermarkWork from "./children/removeWatermarkWork/RemoveWatermarkWork";
import ScreenshotWork from "./children/screenshotWork/ScreenshotWork";
import ConvertWork from "./children/convertWork/ConvertWork";
import MuteWork from "./children/muteWork/MuteWork";
import AddMusicWork from "./children/addMusicWork/AddMusicWork";
import SubtitleWork from "./children/subtitleWork/SubtitleWork";
import AddWatermarkWork from "./children/addWatermarkWork/AddWatermarkWork";
import LoopWork from "./children/loopWork/LoopWork";
import ReverseWork from "./children/reverseWork/ReverseWork";
import "@/assets/workplace.scss";

class WorkPlace extends Component {
  // constructor(props) {
  //   super(props)
  // }

  render() {
    const currWork = () => {
      switch (this.props.work) {
        case "gif":
          return <GifWork />;
        case "merge":
          return <MergeWork />;
        case "speed":
          return <SpeedWork />;
        case "crop_rotate":
          return <CropRotateWork />;
        case "resize":
          return <ResizeWork />;
        case "removeWatermark":
          return <RemoveWatermarkWork />;
        case "screenshot":
          return <ScreenshotWork />;
        case "convert":
          return <ConvertWork />;
        case "mute":
          return <MuteWork />;
        case "addMusic":
          return <AddMusicWork />;
        case "subtitle":
          return <SubtitleWork />;
        case "addWatermark":
          return <AddWatermarkWork />;
        case "cut":
          return <CutWork />;
        case "loop":
          return <LoopWork />;
        case "reverse":
          return <ReverseWork />;
        default:
          return "";
      }
    };

    return (
      <div
        className="WorkPlace"
        // style={{
        //   pointerEvents:
        //     this.props.uploading && !this.props.fileToBlobStatus
        //       ? "none"
        //       : "auto"
        // }}
        style={this.props.successFileGuids.length ? { display: "none" } : {}}
      >
        {currWork()}
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  work: state.work.work,
  prevWork: state.work.prevWork,
  uploading: state.work.uploading,
  fileToBlobStatus: state.work.fileToBlobStatus,
  successFileGuids: state.files.successFileGuids,
});

export default connect(mapStateToPropos, null)(WorkPlace);
