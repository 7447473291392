import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Dropdown, Tooltip } from "antd";
import { ReactComponent as More } from "@/assets/icons/more.svg";
import { ReactComponent as Download } from "@/assets/icons/download.svg";
import { ReactComponent as Delete } from "@/assets/icons/delete.svg";
import { ReactComponent as Cut } from "@/assets/icons/thin/cut.svg";
import { ReactComponent as Merge } from "@/assets/icons/thin/merge.svg";
import { ReactComponent as Speed } from "@/assets/icons/thin/speed.svg";
import { ReactComponent as CropRotate } from "@/assets/icons/thin/crop-rotate.svg";
import { ReactComponent as Resize } from "@/assets/icons/thin/resize.svg";
import { ReactComponent as RemoveWatermark } from "@/assets/icons/thin/remove-watermark.svg";
import { ReactComponent as Mute } from "@/assets/icons/mute.svg";
import { ReactComponent as AddMusic } from "@/assets/icons/thin/add-music.svg";
import { ReactComponent as Subtitle } from "@/assets/icons/thin/subtitle.svg";
import { ReactComponent as AddWatermark } from "@/assets/icons/thin/add-watermark.svg";
import { ReactComponent as Reverse } from "@/assets/icons/thin/reverse.svg";
import { ReactComponent as Loop } from "@/assets/icons/thin/loop.svg";
import Utils from "@/utils/utils";
import "@/assets/fileListModal.scss";

const FileItemRender = ({ file, handleSelectClick, handleDeleteModalShow, handleDownloadClick }) => {
  let ItemRef = React.createRef();
  const { t } = useTranslation(["fileList", "navbar"]);
  const operation = () => {
    return (
      <div className="file-operation">
        <Download
          onClick={e => {
            e.stopPropagation();
            handleDownloadClick(file);
          }}
        />
        <Delete
          onClick={e => {
            e.stopPropagation();
            handleDeleteModalShow(file);
          }}
        />
      </div>
    );
  };
  const renderSwitch = param => {
    switch (param) {
      case "cut":
        return (
          <Tooltip title={t("navbar:Cut")}>
            <Cut />
          </Tooltip>
        );
      case "merge":
        return (
          <Tooltip title={t("navbar:Merge")}>
            <Merge />
          </Tooltip>
        );
      case "speed":
        return (
          <Tooltip title={t("navbar:Speed")}>
            <Speed />
          </Tooltip>
        );
      case "crop_rotate":
        return (
          <Tooltip title={t("navbar:Crop & Rotate")}>
            <CropRotate />
          </Tooltip>
        );
      case "resize":
        return (
          <Tooltip title={t("navbar:Resize")}>
            <Resize />
          </Tooltip>
        );
      case "removeWatermark":
        return (
          <Tooltip title={t("navbar:Watermark Remover")}>
            <RemoveWatermark />
          </Tooltip>
        );
      case "mute":
        return (
          <Tooltip title={t("navbar:Extract")}>
            <Mute />
          </Tooltip>
        );
      case "addMusic":
        return (
          <Tooltip title={t("navbar:Add Music")}>
            <AddMusic />
          </Tooltip>
        );
      case "subtitle":
        return (
          <Tooltip title={t("navbar:Subtitles")}>
            <Subtitle />
          </Tooltip>
        );
      case "addWatermark":
        return (
          <Tooltip title={t("navbar:Add Watermark")}>
            <AddWatermark />
          </Tooltip>
        );
      case "reverse":
        return (
          <Tooltip title={t("navbar:Reverse")}>
            <Reverse />
          </Tooltip>
        );
      case "loop":
        return (
          <Tooltip title={t("navbar:Loop")}>
            <Loop />
          </Tooltip>
        );
      default:
        return "";
    }
  };
  return (
    <Col>
      <div className="fileItem" ref={ItemRef} onClick={() => handleSelectClick(file)}>
        <img src={file.thumbnail} alt="thumbnail" />
        <div className="fileItem-modal">
          <Dropdown overlay={operation()} placement="topLeft" getPopupContainer={() => ItemRef.current} onClick={e => e.stopPropagation()}>
            <More className="more-btn" />
          </Dropdown>
          <div className="fileItem-info">
            <span>{file.duration}</span>
            <span>{Utils.getSize(file.size)}</span>
          </div>
        </div>
      </div>

      <div className="fileItem-time">
        <span>
          {file.status !== "origin"
            ? t(`${Utils.getUpdateTime(file.createTime).text}`, { num: Utils.getUpdateTime(file.createTime).num })
            : t("Original file")}
        </span>
        {renderSwitch(file.status)}
      </div>
    </Col>
  );
};

export default FileItemRender;
