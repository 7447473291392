export default {
  fileSizeLimitTitle: "The file size for free accounts cannot exceed 20MB, please upgrade to Pro to access all premium features",
  freeTimesLimitTitle: "Your trial has run out today, please upgrade to Pro to access all premium features",
  total: "Total",
  enterCode: "Enter code",
  signUpToPurchaseBtn: "Sign Up to Purchase",
  goPurchseBtn: "Go to Purchase",
  saveTips: "Save Up to {{num}}%",
  discount: "Discount",
  montly: "Monthly",
  yearly: "Annual",
  vipTips1: "No video size and quantity limit",
  vipTips2: "Remove image backgrounds without limits",
  vipTips3: "Faster video processing and download speed",
  vipTips4: "Access to all free tools & premium features",
  purchaseTips: "Renew automatically and cancel any time.",
  tips: "Tips",
  promoCodeInvalidTips: "Your promo code is invalid",
};
