import React, { Component } from "react";
import moment from "moment";
import { ResizePreviewRender } from "./children/ResizePreviewRender";
import Utils from "@/utils/utils";

export default class ResizePreviewFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soundSlide: false,
      playing: false,
      currentTime: "00:00:00",
      currentTimePercent: 0,
      currentVolume: 0,
    };
  }

  handleSoundClick = () => {
    const myvideo = document.querySelectorAll("#videoRef .resizeVideoWrap video");
    this.setState({
      currentVolume: myvideo[0].volume * 100,
      soundSlide: !this.state.soundSlide,
    });
  };

  handleSoundDrag = value => {
    const myvideo = document.querySelectorAll("#videoRef .resizeVideoWrap video");
    myvideo[0].volume = value / 100;
    return value;
  };

  handlePlayClick = play => {
    const myvideo = document.querySelectorAll("#videoRef .resizeVideoWrap video");
    if (play) {
      this.setState({ playing: true });
      myvideo.forEach(item => {
        item.play();
      });
      window.requestAnimationFrame(this._getCurrentTime);
    } else {
      this.setState({ playing: false });
      myvideo.forEach(item => {
        item.pause();
      });
    }
  };

  _getCurrentTime = () => {
    if (!this.state.playing) {
      return false;
    }
    const myvideo = document.querySelectorAll("#videoRef .resizeVideoWrap video");
    this.setState({
      currentTime: moment("00:00:00", "mm:ss:SS")
        .add(myvideo[0].currentTime, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1"),
      currentTimePercent: parseInt((myvideo[0].currentTime / myvideo[0].duration) * 100),
    });
    if (myvideo[0].currentTime < myvideo[0].duration) {
      window.requestAnimationFrame(this._getCurrentTime);
    } else if (myvideo[0].duration) {
      if (!myvideo[0].paused) {
        myvideo[0].pause();
      }
      this.setState({
        playing: false,
        currentTimePercent: 0,
        currentTime: "00:00:00",
      });
    }
  };

  handleCurrentTimeChange = value => {
    const myvideo = document.querySelectorAll("#videoRef .resizeVideoWrap video");
    myvideo.forEach(item => {
      item.currentTime = item.duration * (value / 100);
    });
    this.setState({
      currentTime: moment("00:00:00", "mm:ss:SS")
        .add(myvideo[0].currentTime, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1"),
      currentTimePercent: value,
    });
  };

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.submitting && !prevProps.submitting) {
      const myvideos = document.querySelectorAll("#videoRef .resizeVideoWrap video");
      if (this.state.playing) {
        myvideos.forEach(video => video.pause());
        this.setState({ playing: false });
      }
    }
  }

  render() {
    return (
      <ResizePreviewRender
        videoReady={this.props.videoReady}
        soundSlide={this.state.soundSlide}
        handleSoundClick={this.handleSoundClick}
        handleSoundDrag={this.handleSoundDrag}
        handlePlayClick={this.handlePlayClick}
        handleCurrentTimeChange={this.handleCurrentTimeChange}
        currentTime={this.state.currentTime}
        currentTimePercent={this.state.currentTimePercent}
        currFile={this.props.currFile}
        currentVolume={this.state.currentVolume}
        playing={this.state.playing}
        screenshots={Utils.screenshots}
        isTimeRangeVisible={this.props.isTimeRangeVisible}
      />
    );
  }
}
