export default {
  Back: "返回",
  Cut: "剪切",
  Speed: "變速",
  "Crop & Rotate": "裁剪",
  Resize: "改變大小",
  Extract: "擷取音訊或影片",
  "Add Music": "加音樂",
  Subtitles: "加字幕",
  Merge: "合併",
  "Watermark Remover": "移除水印",
  "Add Watermark": "加水印",
  "To GIF": "轉 GIF",
  Convert: "轉格式",
  Screenshot: "截圖",
  Reverse: "倒放",
  Loop: "迴圈",
};
