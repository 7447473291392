export default {
  Back: "Back",
  Cut: "Cut",
  Speed: "Speed",
  "Crop & Rotate": "Crop & Rotate",
  Resize: "Resize",
  Extract: "Extract",
  "Add Music": "Add Music",
  Subtitles: "Subtitles",
  Merge: "Merge",
  "Watermark Remover": "Watermark Remover",
  "Add Watermark": "Add Watermark",
  "To GIF": "To GIF",
  Convert: "Convert",
  Screenshot: "Screenshot",
  Reverse: "Reverse",
  Loop: "Loop",
};
