export default {
  Start: "Inizio",
  End: "Fine",
  Save: "Salva",
  Cancel: "Cancella",
  Download: "Download",
  downloading: "download",
  "Drag and drop from Files or local disk": "Trascina e rilascia da File o disco locale",
  "Click to Add File": "Clicca per Aggiungere File",
  "The minimum time length is 1s!": "La durata minima è 1s!",
  "The maximum time length is 50s!": "La durata massima è 50s!",
  "Try Again": "Riprova",
  "Coming Soon": "A Breve",
  "Learn more": "Scopri di più.",
  Yes: "sì",
  "Please click the Save button to ensure the previous editing to take effect.":
    "Per favore, fai clic sul tasto Salva per assicurarti che gli elementi di impostazione siano effettivi.",
  "The thumbnail is missing, but you can download and continue editing videos normally. If you feel troubled, please download the video first and then upload it again.":
    "Manca la miniatura, ma puoi scaricare e continuare a modificare i video normalmente. Se ti senti turbato, scarica prima il video e poi caricalo di nuovo.",
  // **************************TipsModal Message Title*****************************
  "Cut 10 video fragments at most. Get HitPaw Toolkit for more features.":
    "Taglia 10 frammenti video al massimo. Ottieni HitPaw Toolkit per più funzionalità.",
  "Max file size for videos is 20 MB. Get HitPaw Toolkit for big file.":
    "La dimensione del file non può essere oltre 20MB. Ottieni HitPaw Toolkit per più funzionalità.",
  "Max file size for videos is 20 MB. Get HitPaw Watermark Remover for big file.":
    "La dimensione del file non può essere oltre 20MB. Ottieni HitPaw Watermark Remover per più funzionalità.",
  "The minimum time length is 1s! Get HitPaw Toolkit for more features.": "La durata minima è 1s! Ottieni HitPaw Toolkit per più funzionalità.",
  "Add 3 videos at most. Get HitPaw Toolkit for more features.": "Aggiungi 3 video al massimo. Ottieni HitPaw Toolkit per più funzionalità.",
  "This format is not supported currently.": "Questo formato non è al momento supportato.",
  "This format is not supported currently. Get HitPaw Toolkit for more formats":
    "Questo formato non è al momento supportato. Ottieni HitPaw Toolkit per più funzionalità.",
  "This format is not supported currently. Get HitPaw Watermark Remover for more formats":
    "Questo formato non è al momento supportato. Ottieni HitPaw Watermark Remover per più funzionalità.",
  "Processing failed. Get HitPaw Toolkit for stable processing.": "Generazione Video Non Riuscita!",
  "Failed to load!  Get HitPaw Watermark Remover for stable processing.": "Caricamento non riuscito!",
  "Are you sure you want to delete it?": "Sei sicuro di volerlo eliminare?",
  "Failed to extract the audio!": "Impossibile estrarre l'audio!",
  "Video link copied": "Link video copiato",
  "Cut 10 video fragments at most.": "Taglia 10 frammenti video al massimo.",
  "Max file size for videos is 20 MB.": "La dimensione del file non può essere maggiore di 20 Mb.",
  "Max file size for videos is 100 MB.": "La dimensione del file non può essere maggiore di 100 Mb.",
  "Failed to load!": "Caricamento non riuscito!",

  //******************************2021.05.17 v1.3.0 *******************************/
  "Screenshots downloaded.": "Screenshot scaricati.",
  "Online service, no download required": "Servizio online, nessun download richiesto.",
  "Add up to 20MB file": "Aggiunge fino a 20 MB di file",
  "Support common formats": "Supporta formati comuni",
  "Best for general editing requirements": "Ideale per requisiti di editing generali.",
  "0 second wait": "0 secondi di attesa",
  "No size limits": "Nessun limite di dimensione",
  "Support 1000+ formats": "Supporta oltre 1000 formati",
  "Stable processing": "Elaborazione stabile",
  "Get access to HitPaw Toolkit!": "Accedi a HitPaw Toolkit!",
  "Support cut, crop & rotate, adjust, speed, add music, stop motion, etc.":
    "Supporta il taglio, il ritaglio e la rotazione, la regolazione, la velocità, l'aggiunta di musica, stop-motion, ecc.",
  "The easiest video editing software for beginners": "Il software di editing video più semplice per i principianti",
  "Remove watermark from videos and images in simple clicks": "Rimuove la filigrana da video e immagini con semplici clic",
  "TRY IT FREE": "PROVA GRATIS",
  "BUY NOW": "COMPRA ORA",

  //******************************2021.09.06 v1.5.0********************************/
  "Add Video": "Aggiungi Video",
  More: "Di più",
  "History files": "File storici",
  Share: "Condividere",
  Duration: "Durata",
  "Download all": "Scarica tutto",
  "Download selected": "Scarica la parte selezionata",
  "Add Audio": "Aggiungere Audio",

  //********************************2021.10.19 v1.6.0*********************************/
  "GIF does not support editing. Do you want to convert the MP4 for editing?": "GIF non supporta la modifica. Vuoi convertire l'MP4 per l'editing?",
  "Go to Edit": "Vai a Modificare",
  "Download GIF": "Scarica GIF",

  //********************************2021.10.19 v1.6.1*********************************/
  "HitPaw Video Editor": "HitPaw Video Editor",
  "No video size limits": "Nessun limite di dimensione del video.",
  "Cut/crop/rotate/resize/merge video easily": "Tagliare/ritagliare/ruotare/ridimensionare/unire video facilmente.",
  "Tons of video effects are available for your video creativity": "Sono disponibili tantissimi effetti video per la tua creatività video.",
  "Unlimited tracks allow you to add multiple audios as you want": "Le tracce illimitate ti consentono di aggiungere più audio come desideri.",

  //********************************2021.11.02 v1.6.1*********************************/
  "Download HitPaw Video Editor for more features.": "Scarica HitPaw Video Editor per ulteriori funzionalità.",
  "Get HitPaw Watermark Remover for more features.": "Ottieni HitPaw Watermark Remover per più funzionalità.",
  "Get HitPaw Toolkit for more features.": "Ottieni HitPaw Toolkit per più funzionalità.",
  "Add 3 videos at most.": "Aggiungi al massimo 3 video. ",
  "Processing failed.": "Elaborazione non riuscita.",
  "The uploaded video is less than 1 second.": "Il video caricato è inferiore a 1 secondo. ",
  "The video format is not supported.": "Il formato video non è supportato.",
  "The audio format is not supported.": "Il formato audio non è supportato.",
  "AddFileByURL.largerSize.HitPawToolkit":
    "Analisi riuscita! Il file supera i 100 MB pertanto non può essere modificato da HitPaw Online, si prega di <1><0>scaricare il video</0></1> e quindi usare HitPaw Toolkit per procedere.",
  "AddFileByURL.largerSize.HitPawWatermarkRemover":
    "Analisi riuscita! Il file supera i 100 MB pertanto non può essere modificato da HitPaw Online, si prega di <1><0>scaricare il video</0></1> e quindi usare HitPaw Watermark Remover per procedere.",
  "AddFileByURL.largerSize.HitPawVideoEditor":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Video Editor to proceed.",
};
