import React, { Component } from "react";
import { connect } from "react-redux";
import { workplaceHOC } from "../WorkplaceHOC";
import MuteWorkFunction from "./children/MuteWorkFunction";
import actions from "@/action";
import { request } from "@/api/request";
import ReactGA from "react-ga";
import "@/assets/muteWork.scss";

class MuteWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      muteFail: false,
      waitingUpload: false,
      muteType: null,
    };
  }

  handleSubmit = async () => {
    const action = "splitVideoAudio";
    const startTime = { ...this.props.startTime };
    startTime.mute = new Date();
    this.props.onStartTimeChange(startTime);
    if (!this.props.submitting) {
      this.props.onProcessStepChange(1);
      this.props.onProcessPercentChange(1);
    }
    let muteResponse;
    try {
      muteResponse = await this.getSplitVideo(action);
    } catch (error) {
      this.props.handleSubmitFailModal(true);
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "SplitAudio",
          action: "SplitAudioFail",
          label: error + "_none_none_" + this.props.tokenId + "_none_none",
        },
        ["OnlineTracker", "Tracker"]
      );
      return false;
    }
    if (muteResponse.code === 200) {
      if (this.state.muteType === "audio" && !muteResponse.data.response_file.audio) {
        this.props.handleSubmitFailModal(true);
        this.props.onProcessPercentChange(0);
        this.props.onProcessStepChange(0);
        this.props.onSubmittingChange(false);
        return false;
      }
      let guids = [];
      try {
        guids = await this.props.getNewVideoByProgress("mute", muteResponse, { muteType: this.state.muteType });
      } catch (error) {
        if (error !== "cancel") {
          ReactGA.event(
            {
              category: "SplitAudio",
              action: "SplitAudioFail",
              label: action + "_200_200_" + error,
            },
            ["OnlineTracker", "Tracker"]
          );
        }
        return false;
      }
      const modal = {
        getContainer: document.querySelector(".Home"),
        show: true,
        type: "muteSuccess",
        guids: guids,
        title: "Generate Files Successfully!",
        buttons: [
          {
            type: "download",
            text: "Download",
          },
          {
            type: "editNew",
            text: "Edit New Video",
          },
        ],
      };
      this.props.onFileListModalChange(modal);
      this.props.onUploadPercentChange(0);
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "SplitAudio",
          action: "SplitAudioSuccess",
        },
        ["OnlineTracker", "Tracker"]
      );
    } else {
      this.props.handleSubmitFailModal(true);
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "SplitAudio",
          action: "SplitAudioFail",
          label: action + "_200_" + muteResponse.code + this.props.tokenId + "_none_none",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
  };

  getSplitVideo = action => {
    const { muteType } = this.state;
    const formData = new FormData();
    formData.append("file_url", this.props.currFile.videofileurl);
    formData.append("token", this.props.tokenId);
    if (muteType === "audio") {
      formData.append("is_audio", 1);
    } else if (muteType === "video") {
      formData.append("is_mute_video", 1);
    } else {
      formData.append("is_audio", 1);
      formData.append("is_mute_video", 1);
    }
    return new Promise((resolve, reject) => {
      request(action, {
        method: "post",
        body: formData,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  preSubmit = () => {
    const { muteType } = this.state;
    ReactGA.event(
      {
        category: "SplitAudio",
        action: "SplitAudioSave",
        label: muteType === "audio" ? "extract audio" : muteType === "video" ? "mute" : "split",
      },
      ["OnlineTracker", "Tracker"]
    );
    this.props.preSubmit(1, this.handleSubmit);
  };

  handleMuteType = e => {
    this.props.onUserActionChange(true);
    const muteType = e.target.value;
    this.setState({ muteType });
    switch (muteType) {
      case "audio":
        this.props.handleSubmitFailModalMsg("Failed to extract the audio!");
        break;
      case "video":
        this.props.handleSubmitFailModalMsg("Processing failed.");
        break;
      case "video_audio":
        this.props.handleSubmitFailModalMsg("Processing failed.");
        break;
      default:
        this.props.handleSubmitFailModalMsg("Processing failed.");
        break;
    }
  };

  initWork = () => {
    this.props.handleSubmitFailModalMsg("Failed to extract the audio!");
    this.setState({
      muteType: "audio",
    });
  };

  componentDidMount() {
    if (this.props.currFile) {
      this.initWork();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currFile !== this.props.currFile) {
      if (this.props.currFile) {
        this.initWork();
      } else {
        this.setState({ muteType: null });
      }
    }
  }

  render() {
    const { muteType } = this.state;
    return (
      <div style={{ height: "100%" }}>
        <MuteWorkFunction currFile={this.props.currFile} muteType={muteType} handleMuteType={this.handleMuteType} preSubmit={this.preSubmit} />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  currFile: state.files.currFile,
  files: state.files.files,
  tokenId: state.common.tokenId,
  submitting: state.work.submitting,
  processStep: state.work.processStep,
  processPercent: state.work.processPercent,
  uploading: state.work.uploading,
  startTime: state.work.startTime,
});
const mapDispatchToProps = dispatch => ({
  onProcessPercentChange: processPercent => dispatch(actions.onProcessPercentChange(processPercent)),
  onProcessStepChange: processStep => dispatch(actions.onProcessStepChange(processStep)),
  onFilesChange: files => dispatch(actions.onFilesChange(files)),
  onSubmittingChange: submitting => dispatch(actions.onSubmittingChange(submitting)),
  onFileListModalChange: fileListModal => dispatch(actions.onFileListModalChange(fileListModal)),
  onUploadPercentChange: uploadPercent => dispatch(actions.onUploadPercentChange(uploadPercent)),
  onUploadAgainFileChange: uploadAgainFile => dispatch(actions.onUploadAgainFileChange(uploadAgainFile)),
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
  onPreUploadFileChange: preUploadFile => dispatch(actions.onPreUploadFileChange(preUploadFile)),
  onStartTimeChange: startTime => dispatch(actions.onStartTimeChange(startTime)),
  onUserActionChange: userAction => dispatch(actions.onUserActionChange(userAction)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(workplaceHOC(MuteWork));
