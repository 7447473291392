export default {
  freeTimesLimitTitle: "평가판이 오늘 만료되었습니다. 모든 프리미엄 기능에 액세스하려면 Pro로 업그레이드하십시오.",
  fileSizeLimitTitle: "무료 계정은 20MB 이하의 파일만 편집할 수 있습니다. 모든 프리미엄 기능에 액세스하려면 Pro로 업그레이드하십시오.",
  total: "총가격",
  enterCode: "코드 입력",
  signUpToPurchaseBtn: "등록하고 구입하기",
  goPurchseBtn: "바로 구입",
  saveTips: "최대 {{num}}% 절약",
  discount: "할인",
  montly: "월간",
  yearly: "연간",
  vipTips1: "비디오 크기 및 수량 제한이 없습니다.",
  vipTips2: "제한 없이 이미지 배경을 제거할 수 있습니다.",
  vipTips3: "더 빠른 비디오 처리 및 다운로드 속도.",
  vipTips4: "모든 무료 도구와 프리미엄 기능을 체험할 수 있습니다.",
  purchaseTips: "자동으로 갱신하면 언제든지 취소 가능합니다.",
  tips: "추가 정보:",
  promoCodeInvalidTips: "할인 코드가 잘못되었습니다.",
};
