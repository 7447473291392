import Types from "../../action/types";

const defaultState = {
  work: "",
  prevWork: "",
  cutWorkList: [],
  mergeWorkList: [],
  resolution: 1,
  videoBgColor: "blur",
  uploading: false,
  uploadPercent: 0,
  processPercent: 0,
  processStep: 0,
  currProgress: null,
  startTime: {},
  cancelProgress: false,
  playbackRate: 1,
  crop: {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  },
  rotate: 0,
  flip: "",
  filling: "fit",
  removeWatermarkList: [],
  dragVideoPosition: {
    x: 0,
    y: 0,
  },
  fileToBlobStatus: false,
  submitting: false,
  uploadXHR: null,
  addMusicWorkObj: {},
  addMusicAudioTimeObj: {},
  addMusicAudioStartPlayTime: null,
  addMusicAudioVolumeObj: {
    video: 0.5,
    audio: 0.5,
  },
  subtitleWorkList: [],
  quicklyPosition: null,
  addWatermarkWorkList: [],
  fetchAbort: null,
  loopTimes: 2, //循环次数
  reverseObj: {
    speed: 1, // 视频倒放速度
    mute: true, //视频是否静音
  },
  processModalVisible: false,
};

export default function onAction(state = defaultState, action) {
  switch (action.type) {
    case Types.WORK_CHANGE:
      return {
        ...state,
        work: action.work,
      };
    case Types.PREVWORK_CHANGE:
      return {
        ...state,
        prevWork: action.prevWork,
      };
    case Types.CUTWORKLIST_CHANGE:
      return {
        ...state,
        cutWorkList: action.cutWorkList,
      };
    case Types.MERGEWORKLIST_CHANGE:
      return {
        ...state,
        mergeWorkList: action.mergeWorkList,
      };
    case Types.RESOLUTION_CHANGE:
      return {
        ...state,
        resolution: action.resolution,
      };
    case Types.MERGEREBGCOLOR_CHANGE:
      return {
        ...state,
        videoBgColor: action.videoBgColor,
      };
    case Types.UPLOADING_CHANGE:
      return {
        ...state,
        uploading: action.uploading,
      };
    case Types.UPLOADPERCENT_CHANGE:
      return {
        ...state,
        uploadPercent: action.uploadPercent,
      };
    case Types.PROCESSPERCENT_CHANGE:
      return {
        ...state,
        processPercent: action.processPercent,
      };
    case Types.PROGRESSSTEP_CHANGE:
      return {
        ...state,
        processStep: action.processStep,
      };
    case Types.CURRPROGRESS_CHANGE:
      return {
        ...state,
        currProgress: action.currProgress,
      };
    case Types.STARTTIME_CHANGE:
      return {
        ...state,
        startTime: action.startTime,
      };
    case Types.CANCELPROGRESS_CHANGE:
      return {
        ...state,
        cancelProgress: action.cancelProgress,
      };
    case Types.PLAYBACKRATE_CHANGE:
      return {
        ...state,
        playbackRate: action.playbackRate,
      };
    case Types.CROP_CHANGE:
      return {
        ...state,
        crop: action.crop,
      };
    case Types.ROTATE_CHANGE:
      return {
        ...state,
        rotate: action.rotate,
      };
    case Types.FLIP_CHANGE:
      return {
        ...state,
        flip: action.flip,
      };
    case Types.FILLING_CHANGE:
      return {
        ...state,
        filling: action.filling,
      };
    case Types.REMOVEWATERMARKLIST_CHANGE:
      return {
        ...state,
        removeWatermarkList: action.removeWatermarkList,
      };
    case Types.DROPVIDEOPOSITION_CHANGE:
      return {
        ...state,
        dragVideoPosition: action.dragVideoPosition,
      };
    case Types.FILETOBLOBSTATUS_CHANGE:
      return {
        ...state,
        fileToBlobStatus: action.fileToBlobStatus,
      };
    case Types.SUBMITTING_CHANGE:
      return {
        ...state,
        submitting: action.submitting,
      };
    case Types.UPLOADXHR_CHANGE:
      return {
        ...state,
        uploadXHR: action.uploadXHR,
      };
    case Types.ADDMUSICWORKOBJ_CHANGE:
      return {
        ...state,
        addMusicWorkObj: action.addMusicWorkObj,
      };
    case Types.ADDMUSICAUDIOTIMEOBJ_CHANGE:
      return {
        ...state,
        addMusicAudioTimeObj: action.addMusicAudioTimeObj,
      };
    case Types.ADDMUSICAUDIOSTARTPLAYTIME_CHANGE:
      return {
        ...state,
        addMusicAudioStartPlayTime: action.addMusicAudioStartPlayTime,
      };
    case Types.ADDMUSICAUDIOVOLUMEOBJ_CHANGE:
      return {
        ...state,
        addMusicAudioVolumeObj: action.addMusicAudioVolumeObj,
      };
    case Types.SUBTITLEWORKLIST_CHANGE:
      return {
        ...state,
        subtitleWorkList: action.subtitleWorkList,
      };
    case Types.QUICKLYPOSITION_CHANGE:
      return {
        ...state,
        quicklyPosition: action.quicklyPosition,
      };
    case Types.ADDWATERMARKWORKLIST_CHANGE:
      return {
        ...state,
        addWatermarkWorkList: action.addWatermarkWorkList,
      };
    case Types.FETCHABORT_CHANGE:
      return {
        ...state,
        fetchAbort: action.fetchAbort,
      };
    case Types.LOOPTIMES_CHANGE:
      return {
        ...state,
        loopTimes: action.loopTimes,
      };
    case Types.REVERSEOBJ_CHANGE:
      return {
        ...state,
        reverseObj: action.reverseObj,
      };
    case Types.PROCESSMODALVISIBLE_CHANGE:
      return {
        ...state,
        processModalVisible: action.processModalVisible,
      };

    default:
      return state;
  }
}
