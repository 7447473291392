export default {
  'Files': '文件庫',
  'Refresh': '刷新',
  'Original file': '原文件',
  'Just now': '剛剛',
  'hour ago': '{{num}}小時前',
  'hours ago': '{{num}}小時前',
  'minute ago': '{{num}}分鐘前',
  'minutes ago': '{{num}}分鐘前',
  'day ago': '{{num}}天前',
  'View All': '查看全部',
  'No files': '暫無文件…',
  "Don't forget to download your file, it will be deleted after 24 hours": '不要忘記下載你的檔案，它將在24小時後被刪除',
  'Select All': '全選',
  'Delete': '刪除',
  'Generate Files Successfully!': '生成文件成功！',
  'Continue Editing': '繼續編輯',
  'Edit New Video': '編輯新視頻',
  'Download': '下載',
  'Click here to open the Files again':'點擊這裡再次打開文件庫。',
  'Processing... Please wait': '正在處理...請稍等',
  'Uploading... Please wait': '上傳中…請稍等',
  'Edit Now': '立即編輯',
  'Back to Edit': "返回編輯",
  'Go Edit':'去編輯'

}