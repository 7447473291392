export default {
  'Files': 'Arquivos',
  'Refresh': 'Atualizar',
  'Original file': 'Arquivo original',
  'Just now': 'Agora mesmo',
  'hour ago': '{{num}} hora atrás',
  'hours ago': '{{num}} horas atrás',
  'minute ago': '{{num}} minuto atrás',
  'minutes ago': '{{num}} minutos atrás',
  'day ago': '{{num}} dia atrás',
  'View All': 'Ver Tudo',
  'No files': 'Sem arquivos ...',
  "Don't forget to download your file, it will be deleted after 24 hours": 'Não se esqueça de baixar seu arquivo, ele será apagado 24 horas depois.',
  'Select All': 'Selecionar Tudo',
  'Delete': 'Excluir',
  'Generate Files Successfully!': 'Gerar Arquivos com Sucesso!',
  'Continue Editing': 'Continuar a Editar',
  'Edit New Video': 'Editar Novo Vídeo',
  'Download': 'Baixar',
  'Click here to open the Files again':'Clique aqui para abrir Pastas novamente.',
  'Processing... Please wait': 'Processando... Por favor aguarde',
  'Uploading... Please wait': 'Enviando... Por favor aguarde',
  'Edit Now': 'Edite agora',
  'Back to Edit':'Voltar a Editar',
  'Go Edit':'Editar',
}