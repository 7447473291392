import React, { Component } from "react";
import { Modal, Button, Image } from "antd";
import ReactGA from "react-ga";
import Utils from "@/utils/utils";
import { ReactComponent as Notice } from "../../assets/icons/notice.svg";
import { ReactComponent as Mac } from "../../assets/icons/mac.svg";
import { ReactComponent as Win } from "../../assets/icons/win.svg";
import { Trans, withTranslation } from "react-i18next";
import hitpawToolkitBox from "@/assets/images/hitpaw-toolkit-box.png";
import HitPawWatermarkRemoverBox from "@/assets/images/hitpaw-watermark-remover-box.png";
import HitPawVideoEditorBox from "@/assets/images/hitpaw-video-editor-box.png";

import { connect } from "react-redux";

class TipsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productIntrInfoList: [
        {
          key: "hitpaw-toolkit",
          title: "HitPaw Toolkit",
          box: hitpawToolkitBox,
          list: [
            "0 second wait",
            "No size limits",
            "Support 1000+ formats",
            "Support cut, crop & rotate, adjust, speed, add music, stop motion, etc.",
            "The easiest video editing software for beginners",
          ],
        },
        {
          key: "hitpaw-watermark-remover",
          title: "HitPaw Watermark Remover",
          box: HitPawWatermarkRemoverBox,
          list: ["0 second wait", "No size limits", "Support 1000+ formats", "Remove watermark from videos and images in simple clicks"],
        },
        {
          key: "hitpaw-video-editor",
          title: "HitPaw Video Editor",
          box: HitPawVideoEditorBox,
          list: [
            "No video size limits",
            "Cut/crop/rotate/resize/merge video easily",
            "Tons of video effects are available for your video creativity",
            "Unlimited tracks allow you to add multiple audios as you want",
          ],
        },
      ],
    };
  }

  downloadFile = () => {
    ReactGA.event(
      {
        category: "Download",
        action: "URLVidDownload",
      },
      ["OnlineTracker", "Tracker"]
    );
  };

  getProductName = () => {
    const key = this.props.productKey ? this.props.productKey : "hitpaw-toolkit";
    return this.state.productIntrInfoList.filter(item => item.key === key)[0].title;
  };

  handleDownloadGA = action => {
    ReactGA.event(
      {
        category: "Download",
        action: action,
        label: this.props.tipsObj ? (this.props.tipsObj.type === "urlSize" ? "url" : this.props.tipsObj.type) : "",
      },
      ["OnlineTracker", "Tracker"]
    );
  };

  render() {
    const guideText = !this.props.showGuidText
      ? ""
      : this.props.work === "removeWatermark"
      ? "Get HitPaw Watermark Remover for more features."
      : window.userClient.platform === "win"
      ? "Download HitPaw Video Editor for more features."
      : "Get HitPaw Toolkit for more features.";
    const { t } = this.props;
    const { productIntrInfoList } = this.state;
    return (
      <Modal
        centered
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        footer={null}
        getContainer={document.querySelector(".Home")}
        className="tipsModal tipsProductModal"
      >
        <div className="m-header">
          <Notice />
          <p style={{ marginBottom: 0, paddingRight: "2rem" }}>
            {this.props.title && <span style={{ display: "block" }}>{t(this.props.title)}</span>}
            {this.props.hasFileDownload ? (
              <span>
                {/* {t(this.props.content)}
                <a
                  style={{ textDecoration: "underline" }}
                  href={this.props.fileObj.videofileurl}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={this.downloadFile}
                >
                  {t(this.props.contentLink)}
                </a>
                {t(this.props.content2)} */}
                <Trans
                  i18nKey={
                    this.props.work === "removeWatermark"
                      ? "common:AddFileByURL.largerSize.HitPawWatermarkRemover"
                      : window.userClient.platform === "win"
                      ? "common:AddFileByURL.largerSize.HitPawVideoEditor"
                      : "common:AddFileByURL.largerSize.HitPawToolkit"
                  }
                >
                  Text
                  <u>
                    <a href={this.props.fileObj.videofileurl} target="_blank" rel="noopener noreferrer" onClick={this.downloadFile}>
                      link
                    </a>
                  </u>
                </Trans>
              </span>
            ) : (
              <span>
                {t(this.props.content)} {t(guideText)}
              </span>
            )}
            {this.props.hasDownload && !this.props.learnMoreHidden ? (
              <a
                href={
                  this.props.work === "removeWatermark"
                    ? Utils.getHitPawWatermarkRemoverPageLink()
                    : window.userClient.platform === "win"
                    ? Utils.getHitPawVideoEditorPageLink()
                    : Utils.getHitPawHomeLink()
                }
                style={{ marginLeft: "0.5rem", textDecoration: "underline" }}
                onClick={this.props.cancelBtnHandle}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Learn more")}
              </a>
            ) : null}
          </p>
        </div>
        {productIntrInfoList
          .filter(item => item.key === (this.props.productKey ? this.props.productKey : "hitpaw-toolkit"))
          .map(item => (
            <div className="m-cont" key={item.key}>
              <div className="lft">
                <Image src={item.box} alt="product picture" />
              </div>
              <div className="rgt">
                <p>{t(item.title)}</p>
                <ul>
                  {item.list.map((con, i) => (
                    <li key={i}>{t(con)}</li>
                  ))}
                </ul>
                {item.key === "hitpaw-toolkit" ? (
                  <div className="btn-groups">
                    <Button
                      type="primary"
                      href={Utils.getHitPawToolKitEXELink()}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => this.handleDownloadGA("ToolkitDownload")}
                      size="large"
                    >
                      <Win />
                      {t("TRY IT FREE")}
                    </Button>
                    <Button ghost type="primary" href={Utils.getHitPawToolKitBuyLink()} target="_blank" rel="noopener noreferrer" size="large">
                      <Win />
                      {t("BUY NOW")}
                    </Button>
                  </div>
                ) : item.key === "hitpaw-video-editor" ? (
                  <div className="btn-groups">
                    <Button
                      type="primary"
                      href={Utils.getHitPawVideoEditorEXELink()}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => this.handleDownloadGA("Video_Editor_Download")}
                      size="large"
                    >
                      <Win />
                      {t("TRY IT FREE")}
                    </Button>
                    <Button ghost type="primary" href={Utils.getHitPawVideoEditorBuyLink()} target="_blank" rel="noopener noreferrer" size="large">
                      <Win />
                      {t("BUY NOW")}
                    </Button>
                  </div>
                ) : (
                  <div className="btn-groups">
                    <Button
                      type="primary"
                      href={Utils.getHitPawWatermarkRemoverEXELink()}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => this.handleDownloadGA("RemoverDownload")}
                      size="large"
                    >
                      {window.userClient.platform === "win" ? <Win /> : <Mac />}
                      {t("TRY IT FREE")}
                    </Button>
                    <Button
                      ghost
                      type="primary"
                      href={Utils.getHitPawWatermarkRemoverBuyLink()}
                      target="_blank"
                      rel="noopener noreferrer"
                      size="large"
                    >
                      {window.userClient.platform === "win" ? <Win /> : <Mac />}
                      {t("BUY NOW")}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ))}
      </Modal>
    );
  }
}
const mapStateToPropos = state => ({
  work: state.work.work,
});
export default connect(mapStateToPropos)(withTranslation(["common"])(TipsModal));
