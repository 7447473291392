export default {
  freeTimesLimitTitle: "トライアル版は本日終了しました。すべてのプレミアム機能にアクセスするには、Proにアップグレードしてください。",
  fileSizeLimitTitle:
    "無料アカウントのファイルサイズは20 MBを超えることはできません。すべてのプレミアム機能にアクセスするには、Proにアップグレードしてください。",
  total: "全て",
  enterCode: "コードを入力する",
  signUpToPurchaseBtn: "登録して購入する",
  goPurchseBtn: "購入する",
  saveTips: "最大{{num}}％割引",
  discount: "割引",
  montly: "毎月",
  yearly: "年間",
  vipTips1: "動画のサイズと数量には制限はありません",
  vipTips2: "画像背景の削除回数には制限はありません",
  vipTips3: "動画のダウンロードと処理速度が上がる",
  vipTips4: "全ての無料ツールとプレミアム機能を解放する",
  purchaseTips: "契約は自動的に更新され、いつでも解約できます。",
  tips: "ヒント",
  promoCodeInvalidTips: "販促コードが無限になりました。",
};
