import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "@/action";
import FileListModal from "./children/FileListModal";
import ReactGA from "react-ga";
import Utils from "@/utils/utils";
import { requsetGetAnonymousFileList } from "@/api/apisFiles";
import videoWorkType from "@/constants/videoWorkType";

class FileList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warningInfo: false,
      warningInfoText: "",
      warningInfoObj: {},
    };
  }

  componentDidMount() {
    this.getFiles();
    window.addEventListener("storage", e => {
      if (e.key === "files") {
        const files = JSON.parse(localStorage.getItem("files"));
        this.props.onFilesChange(files);
        if (this.props.currFile && !files.some(file => file.guid === this.props.currFile.guid)) {
          this.props.onCurrFileChange(null);
        }
      }
    });
  }

  /**
   * 请求获取files列表
   */
  getFiles = () => {
    const formData = new FormData();
    formData.append("token", this.props.tokenId);
    requsetGetAnonymousFileList()
      .then(res => {
        console.log("res", res);
        if (res.code === 200) {
          let historyFiles = res.data.history_files;
          if (historyFiles && historyFiles.length) {
            console.log(historyFiles);
            let files = [];
            historyFiles
              .filter(item => item.video_media_info && videoWorkType.has(item.media_job_type))
              .forEach(file => {
                files.push(Utils.getFileObj(file));
              });
            console.log(files);
            this.props.onFilesChange(files);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  /**
   * 拖拽选择文件到工作区
   * @param {*files列表的索引值} i
   * @param {*是否是音频文件} isAudio
   */
  handleSelectDrag = (i, isAudio) => {
    const file = { ...this.props.files[i] };
    if (isAudio) {
      this.props.onAddMusicWorkObjChange(file);
      ReactGA.event(
        {
          category: "AddMusic",
          action: "AudioAdd",
          label: "dragFiles",
        },
        ["OnlineTracker", "Tracker"]
      );
      return false;
    }
    if (file.size >= 20 * 1024 * 1024) {
      if (Utils.isNeedToShowPurchaseModal()) {
        this.props.onPurchaseModalObjChange({
          visible: true,
          type: "uploadSizeBiggerThanLimit",
        });
      }
      //==============之前的限制弹窗跟埋点：start===============
      // this.setState({
      //   warningInfo: true,
      //   warningInfoText: "Max file size for videos is 20 MB.",
      //   warningInfoObj: {
      //     type: "size",
      //     size: (file.size / 1024 / 1024).toFixed(2),
      //   },
      // });
      // ReactGA.event({
      //   category: "Upload",
      //   action: "ExceedSize",
      //   label: /\.[^.]+$/.exec(file.name)[0].toLocaleLowerCase() + "_" + (file.size / 1024 / 1024).toFixed(2),
      // });
      //==============之前的限制弹窗跟埋点：end===============
      return false;
    }
    file.update = new Date().getTime();
    this.props.onCurrFileChange(file);
    ReactGA.event(
      {
        category: "Upload",
        action: "DragFile",
      },
      ["OnlineTracker", "Tracker"]
    );
  };

  render() {
    return (
      <div className="FileList">
        <FileListModal />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  tokenId: state.common.tokenId,
  uploading: state.work.uploading,
  work: state.work.work,
  fileToBlobStatus: state.work.fileToBlobStatus,
  addMusicWorkObj: state.work.addMusicWorkObj,
  fileListModal: state.common.fileListModal,
});

const mapDispatchToProps = dispatch => ({
  onFilesChange: files => dispatch(actions.onFilesChange(files)),
  onFileListModalChange: fileListModal => dispatch(actions.onFileListModalChange(fileListModal)),
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
  onAddMusicWorkObjChange: addMusicWorkObj => dispatch(actions.onAddMusicWorkObjChange(addMusicWorkObj)),
  onMergeWorkListChange: mergeWorkList => dispatch(actions.onMergeWorkListChange(mergeWorkList)),
  onRemoveWatermarkListChange: removeWatermarkList => dispatch(actions.onRemoveWatermarkListChange(removeWatermarkList)),
  onWorkChange: work => dispatch(actions.onWorkChange(work)),
  onPurchaseModalObjChange: purchaseModalObj => dispatch(actions.onPurchaseModalObjChange(purchaseModalObj)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(FileList);
