import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "@/action";
import VideoPreviewFunction from "./children/videoPreviewFuntion/VideoPreviewFunction";
// import VideoPreviewShareModalFunction from "./children/videoPreviewShareModalFunction/VideoPreviewShareModalFunction";
import { useTranslation } from "react-i18next";
import { useDrop } from "react-dnd";
import { ReactComponent as Close } from "@/assets/icons/close-circle.svg";
import Utils from "@/utils/utils";
import { Dropdown } from "antd";
import ReactGA from "react-ga";

const VideoWrap = ({ currFile, handleCloseFile, successFiles, sentGAOfSuccessOperation }) => {
  const [, drop] = useDrop({
    accept: "FileListItem",
    drop: () => ({ name: "SpeedVideoWrap" }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: item => {
      if ((item.file && item.file.audiofileurl) || (item.file && item.file.status === "gif")) {
        return false;
      }
      return true;
    },
  });
  const { t } = useTranslation(["common"]);
  const downloadMenu = (
    <div className="downloadMenu">
      <div
        onClick={() => {
          sentGAOfSuccessOperation("DownloadVid", "Download All");
          successFiles.forEach(file => {
            Utils.downloadFile(file, ".mp4");
          });
        }}
      >
        {t("Download all")}
      </div>
      <div
        onClick={() => {
          sentGAOfSuccessOperation("DownloadVid", "Download Selected");
          Utils.downloadFile(currFile, ".mp4");
        }}
      >
        {t("Download selected")}
      </div>
    </div>
  );

  return (
    <div ref={drop} className="videoWrap" id="videoRef">
      <div
        style={{
          // width: "fit-content",
          // height: "fit-content",
          width: "max-content",
          maxWidth: "100%",
          height: "100%",
          margin: "auto",
          position: "relative",
        }}
      >
        <button
          className="close"
          onClick={() => {
            handleCloseFile();
            sentGAOfSuccessOperation("CloseVid");
          }}
        >
          <Close />
        </button>
        {successFiles.length ? (
          <div className="operation">
            {successFiles.length > 1 ? (
              <Dropdown overlay={downloadMenu} getPopupContainer={() => document.querySelector("#videoRef .operation")}>
                <div className="operation-item">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14 6.86155H10.4V0H5.6V6.86155H2L8 13L14 6.86155ZM15 16V14.5H1V16H15Z"
                      fill="white"
                      fillOpacity="0.8"
                    />
                  </svg>
                  <span>{t("Download")}</span>
                </div>
              </Dropdown>
            ) : (
              <div
                className="operation-item"
                onClick={() => {
                  Utils.downloadFile(currFile, currFile.fileType === "gif" ? ".gif" : ".mp4");
                  sentGAOfSuccessOperation("DownloadVid");
                }}
              >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14 6.86155H10.4V0H5.6V6.86155H2L8 13L14 6.86155ZM15 16V14.5H1V16H15Z"
                    fill="white"
                    fillOpacity="0.8"
                  />
                </svg>
                <span>{t("Download")}</span>
              </div>
            )}
            <div
              className="operation-item"
              onClick={() => {
                Utils.copyText(currFile.videofileurl);
                sentGAOfSuccessOperation("Share");
              }}
            >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.03444 5.34015V2L16 8L9.03444 14V10.7316C2.34403 10.2578 0 13.8958 0 13.8958C0 11.6292 0.198755 9.27687 2.09517 7.49566C4.29957 5.4235 7.63636 5.375 9.03444 5.34015Z"
                  fill="white"
                  fillOpacity="0.8"
                />
              </svg>
              <span>{t("Share")}</span>
            </div>
          </div>
        ) : null}

        {currFile.fileType === "gif" ? (
          <img src={currFile.videofileurl} alt="gif" style={{ width: "100%", height: "80vh", objectFit: "contain" }} />
        ) : (
          <video className="active" crossOrigin="Anonymous">
            <source src={currFile.videofileurl} type="video/mp4" />
          </video>
        )}
      </div>
    </div>
  );
};

class VideoPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successFiles: [],
      selectedFile: {},
    };
  }

  componentDidMount() {
    if (this.props.successFileGuids.length) {
      this.setState({
        successPerview: true,
        successFiles: this.props.files.filter(item => this.props.successFileGuids.indexOf(item.guid) !== -1),
      });
      this.props.onSelectedFileChange(this.props.files.filter(item => this.props.successFileGuids.indexOf(item.guid) !== -1)[0]);
    } else if (this.props.currFile) {
      this.props.onSelectedFileChange(this.props.currFile);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.successFileGuids && this.props.successFileGuids !== prevProps.successFileGuids) {
      this.setState({
        successPerview: true,
        successFiles: this.props.files.filter(item => this.props.successFileGuids.indexOf(item.guid) !== -1),
      });
    } else if (this.props.currFile && this.props.currFile !== prevProps.currFile) {
      this.props.onSelectedFileChange(this.props.currFile);
    }
  }

  handleCloseFile = () => {
    this.props.onCurrFileChange(null);
    this.props.onSelectedFileChange({});
    this.props.onSuccessFileGuidsChange([]);
    this.props.onWorkChange(null);
    // if (this.props.selectedFile.fileType === "gif" && this.props.prevWork === "loop") {
    //   let files = [...this.props.files];
    //   files = files.filter(item => item.guid !== this.props.selectedFile.guid);
    //   this.props.onFilesChange(files);
    //   localStorage.setItem("files", JSON.stringify(files));
    // }
  };

  handleSelectFile = selectedFile => {
    this.props.onSelectedFileChange(selectedFile);
  };

  sentGAOfSuccessOperation = (action, label) => {
    if (label === "Download Selected" || (label === "Download All" && this.props.work === "cut")) {
      ReactGA.event(
        {
          category: "Cut",
          action: "Cut Download",
          label: label,
        },
        ["OnlineTracker", "Tracker"]
      );
    }
    switch (this.props.work) {
      case "cut":
        label = "cut";
        break;
      case "speed":
        label = "speed";
        break;
      case "merge":
        label = "merge";
        break;
      case "crop_rotate":
        label = "crop_rotate";
        break;
      case "resize":
        label = "resize";
        break;
      case "removeWatermark":
        label = "removeWatermark";
        break;
      case "addMusic":
        label = "addMusic";
        break;
      case "subtitle":
        label = "addSubtitle";
        break;
      case "addWatermark":
        label = "addWatermark";
        break;
      default:
        break;
    }
    if (action === "DownloadVid") {
      label = label + "_" + (parseInt(this.props.selectedFile.size) / 1024).toFixed(2) + "kb_" + this.props.selectedFile.wh.replace("x", "*");
    }
    ReactGA.event(
      {
        category: "Common",
        action: action,
        label: label,
      },
      ["OnlineTracker", "Tracker"]
    );
  };

  render() {
    return (
      <div className="VideoPreview">
        <div style={{ width: this.props.work === "cut" && this.state.successFiles.length > 1 ? "auto" : "100%", height: "100%" }}>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <VideoWrap
              sentGAOfSuccessOperation={this.sentGAOfSuccessOperation}
              currFile={this.props.selectedFile}
              handleCloseFile={this.handleCloseFile}
              successFiles={this.state.successFiles}
            />
            {this.props.selectedFile.fileType !== "gif" ? (
              <VideoPreviewFunction work={this.props.work} currFile={this.props.selectedFile} successFiles={this.state.successFiles} />
            ) : null}
          </div>
        </div>
        {this.state.successFiles.length > 1 ? (
          <div className="videoPreview-files">
            <div>
              {this.state.successFiles.map(item => (
                <div
                  key={item.guid}
                  className={this.props.selectedFile.guid === item.guid ? "active" : ""}
                  onClick={() => this.handleSelectFile(item)}
                >
                  <img src={item.thumbnail} alt="thumbnail" />
                </div>
              ))}
            </div>
          </div>
        ) : null}
        {/* <VideoPreviewShareModalFunction /> */}
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  currFile: state.files.currFile,
  successFileGuids: state.files.successFileGuids,
  files: state.files.files,
  work: state.work.work,
  prevWork: state.work.prevWork,
  selectedFile: state.files.selectedFile,
});

const mapDispatchToProps = dispatch => ({
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
  onSelectedFileChange: selectedFile => dispatch(actions.onSelectedFileChange(selectedFile)),
  onSuccessFileGuidsChange: successFileGuids => dispatch(actions.onSuccessFileGuidsChange(successFileGuids)),
  onWorkChange: work => dispatch(actions.onWorkChange(work)),
  onFilesChange: files => dispatch(actions.onFilesChange(files)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(VideoPreview);
