export default {
  freeTimesLimitTitle:
    "Votre période d'essai d'aujourd'hui est terminée, veuillez passer à la version Pro pour accéder à toutes les fonctionnalités premium.",
  fileSizeLimitTitle:
    "La taille des fichiers pour les comptes gratuits ne peut pas dépasser 20 Mo, veuillez passer à la version Pro pour accéder à toutes les fonctions Premium.",
  total: "Tous",
  enterCode: "Entrez le code",
  signUpToPurchaseBtn: "Inscrivez-vous et acheter",
  goPurchseBtn: "Aller à l'achat",
  saveTips: "Économisez jusqu'à {{num}} %",
  discount: "Remises",
  montly: "Mensuel",
  yearly: "Chaque année",
  vipTips1: "Aucune limite de taille et de quantité de vidéos",
  vipTips2: "Suppression des arrière-plans d'image sans limite",
  vipTips3: "Augmentation des vitesses de traitement et de téléchargement des vidéos",
  vipTips4: "Accès à tous les outils gratuits et aux fonctions premium",
  purchaseTips: "Renouvellement automatique et annulation à tout moment",
  tips: "Note:",
  promoCodeInvalidTips: "Votre code promo n'est pas valide",
};
