import React from "react";
import { Divider, Row, Col } from "antd";
import { ReactComponent as Next } from "@/assets/icons/arrow-right.svg";
import SampleFileItemFunction from "./children/SampleFileItemFunction";
import { useTranslation } from "react-i18next";

const SampleFilesRender = ({ files, onCurrFileChange, onFilesChange, onFileListModalChange, requsetDeleteAnonymousFileList }) => {
  const { t } = useTranslation(["common"]);

  const handleFileModalVisible = () => {
    const modal = {
      getContainer: document.querySelector(".Home"),
      desc: "Don't forget to download your file, it will be deleted after 24 hours",
      show: true,
      type: "files",
      title: "Files",
      buttons: [
        {
          type: "delete",
          text: "Delete",
        },
        {
          type: "download",
          text: "Download",
        },
      ],
    };
    onFileListModalChange(modal);
  };

  const limitNum = document.documentElement.clientWidth >= 600 ? 4 : 3;
  const sampleFiles = files.length > limitNum ? files.slice(0, limitNum) : files;
  return files && files.length ? (
    <div className="fileList" onClick={e => e.stopPropagation()}>
      <Divider className="fileList-title">{t("History files")}</Divider>
      <div className="fileList-title-mobile">
        {t("History files")}
        {files.length > limitNum ? <div onClick={handleFileModalVisible}>{t("More")}</div> : null}
      </div>
      <Row gutter={[16, 0]}>
        {sampleFiles.map(file => (
          <SampleFileItemFunction
            files={files}
            key={file.guid}
            file={file}
            onCurrFileChange={onCurrFileChange}
            onFilesChange={onFilesChange}
            requsetDeleteAnonymousFileList={requsetDeleteAnonymousFileList}
          />
        ))}
        {files.length > limitNum ? (
          <Col className="more-files-btn-cont" onClick={handleFileModalVisible}>
            <div className="more-files-btn">
              <Next />
            </div>
          </Col>
        ) : null}
      </Row>
    </div>
  ) : null;
};

export default SampleFilesRender;
