export default {
  freeTimesLimitTitle: "Uji coba Anda telah habis hari ini, harap tingkatkan ke Pro untuk mengakses semua fitur premium",
  fileSizeLimitTitle: "Ukuran file untuk akun gratis tidak boleh melebihi 20MB, harap tingkatkan ke Pro untuk mengakses semua fitur premium",
  total: "Total",
  enterCode: "Memasukkan kode",
  signUpToPurchaseBtn: "Daftar untuk Membeli",
  goPurchseBtn: "Pergi ke Pembelian",
  saveTips: "Hemat Hingga {{num}}%",
  discount: "Diskon",
  montly: "Bulanan",
  yearly: "Tahunan",
  vipTips1: "Tidak ada batasan ukuran dan kuantitas video",
  vipTips2: "Hapus latar belakang gambar tanpa batas",
  vipTips3: "Pemrosesan video dan kecepatan unduh lebih cepat",
  vipTips4: "Akses ke semua alat gratis & fitur premium",
  purchaseTips: "Perbarui secara otomatis dan batalkan kapan saja.",
  tips: "Kiat:",
  promoCodeInvalidTips: "Kode promo Anda tidak valid",
};
