import React, { Component } from "react";
import ReactGA from "react-ga";
import Utils from "@/utils/utils";
import { AddFileRender } from "./children/AddFileRender";

export default class AddFileFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadClick: false,
    };
  }

  draggerProps = {
    accept: "video/*,.mkv,.3gp,.f4v,.rm,.rmvb,.mts,.mxf,.asf,.flv,.vob,.swf,.ts",
    onChange: info => {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        console.log(`${info.file.name} file upload failed.`);
        this.setState({ uploadFail: true });
      }
    },
    beforeUpload: file => {
      //==============之前的限制弹窗跟埋点：start===============
      // if (file.size >= 20 * 1024 * 1024) {

      // this.props.handleWarningObj({
      //   warningInfo: true,
      //   warningInfoText: "Max file size for videos is 20 MB.",
      //   warningInfoObj: {
      //     type: "size",
      //     size: (file.size / 1024 / 1024).toFixed(2),
      //   },
      // });
      // ReactGA.event({
      //   category: "Upload",
      //   action: "ExceedSize",
      //   label: /\.[^.]+$/.exec(file.name)[0].toLocaleLowerCase() + "_" + (file.size / 1024 / 1024).toFixed(2),
      // });
      //==============之前的限制弹窗跟埋点：end===============
      // } else
      if (!Utils.validate(file)) {
        this.props.handleWarningObj({
          warningInfo: true,
          warningInfoText: "The video format is not supported.",
          warningInfoObj: {
            type: "format",
            format: /\.[^.]+$/.exec(file.name)[0].toLocaleLowerCase(),
          },
        });
      } else {
        ReactGA.event(
          {
            category: "Upload",
            action: this.state.uploadClick ? "ClickUpload" : "DragLocal",
            label: /\.[^.]+$/.exec(file.name)[0].toLocaleLowerCase() + "_" + (file.size / 1024 / 1024).toFixed(2),
          },
          ["OnlineTracker", "Tracker"]
        );
        // if (this.props.files.some(file => file.status === "cut")) {
        //   ReactGA.event({
        //     category: "Cut",
        //     action: "CutNew",
        //   });
        // }
        // if (this.props.files.some(file => file.status === "merge")) {
        //   ReactGA.event({
        //     category: "Merge",
        //     action: "MergNew",
        //   });
        // }
        // if (this.props.files.some(file => file.status === "gif")) {
        //   ReactGA.event({
        //     category: "ToGif",
        //     action: "GifNew",
        //   });
        // }
        if (this.props.work === "reverse" || this.props.work === "loop") {
          let category = this.props.work === "reverse" ? "Video_Reverse" : "Video_Loop";
          let action = this.props.work === "reverse" ? "Reverse_add_files_click" : "Loop_add_files_click";
          let label = this.state.uploadClick ? "choose_file" : "drag_file";
          ReactGA.event(
            {
              category,
              action,
              label,
            },
            ["OnlineTracker", "Tracker"]
          );
        }
        if (file.size >= 20 * 1024 * 1024) {
          if (Utils.isNeedToShowPurchaseModal()) {
            this.props.handlePurchaseModalObjChange({
              visible: true,
              type: "uploadSizeBiggerThanLimit",
            });
            return false;
          }
        }
        this.setState({
          file: file,
          uploadClick: false,
        });
        this.props.upload(file);
      }
      return false;
    },
  };

  handleUploadClick = () => {
    this.setState({ uploadClick: true });
  };

  render() {
    return (
      <AddFileRender
        draggerProps={this.draggerProps}
        files={this.props.files}
        handleUploadClick={this.handleUploadClick}
        onCurrFileChange={this.props.onCurrFileChange}
        onFilesChange={this.props.onFilesChange}
        requsetDeleteAnonymousFileList={this.props.requsetDeleteAnonymousFileList}
        onFileListModalChange={this.props.onFileListModalChange}
      />
    );
  }
}
