export default {
  freeTimesLimitTitle: "Deneme süreniz bugün sona erdi, tüm premium özelliklere erişmek için lütfen Pro'ya yükseltin",
  fileSizeLimitTitle: "Ücretsiz hesaplar için dosya boyutu 20 MB'ı aşamaz, tüm premium özelliklere erişmek için lütfen Pro'ya yükseltin",
  total: "Toplam",
  enterCode: "Kodu girin",
  signUpToPurchaseBtn: "Satın Almak için Kaydolun",
  goPurchseBtn: "Satın Almaya Git",
  saveTips: "% {{num}}' ye Kadar Tasarruf Edin",
  discount: "İndirim",
  montly: "Aylık",
  yearly: "Yıllık",
  vipTips1: "Video boyutu ve miktar sınırı yok",
  vipTips2: "Sınırsız resim arka planlarını kaldırın",
  vipTips3: "Daha hızlı video işleme ve indirme hızı",
  vipTips4: "Tüm ücretsiz araçlara ve premium özelliklere erişim",
  purchaseTips: "Otomatik olarak yenileyin ve istediğiniz zaman iptal edin.",
  tips: "İpuçları:",
  promoCodeInvalidTips: "Promosyon kodunuz geçersiz",
};
