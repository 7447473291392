export default {
  freeTimesLimitTitle: "您今日的試用機會已經用完，升級至Pro以訪問所有高級功能",
  fileSizeLimitTitle: "免費帳戶只允許編輯20MB以下檔案，升級至Pro以訪問所有高級功能",
  total: "全部的",
  enterCode: "輸入碼",
  signUpToPurchaseBtn: "註冊並去購買",
  goPurchseBtn: "去購買",
  saveTips: "最多可節省{{num}}%",
  discount: "折扣",
  montly: "每月",
  yearly: "每年的",
  vipTips1: "無影片大小和數量限制",
  vipTips2: "無去除圖像背景次數限制",
  vipTips3: "影影片處理和下載速度提高",
  vipTips4: "訪問所有免費工具和高級功能",
  purchaseTips: "自動續訂並隨時取消。",
  tips: "提示：",
  promoCodeInvalidTips: "你的優惠碼無效",
};
