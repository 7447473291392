import Types from "../../action/types";

const defaultState = {
  files: [],
  preUploadFile: null,
  currFile: null,
  uploadAgainFile: null,
  successFileGuids: [],
  selectedFile: {}
};

export default function onAction(state = defaultState, action) {
  switch (action.type) {
    case Types.FILES_CHANGE:
      return {
        ...state,
        files: action.files,
      };
    case Types.PREUPLOADFILE_CHANGE:
      return {
        ...state,
        preUploadFile: action.preUploadFile,
      };
    case Types.CURRFILE_CHANGE:
      return {
        ...state,
        currFile: action.currFile,
      };
    case Types.UPLOADAGAINFILE_CHANGE:
      return {
        ...state,
        uploadAgainFile: action.uploadAgainFile,
      };
    case Types.SUCCESSFILEGUIDS_CHANGE:
      return {
        ...state,
        successFileGuids: action.successFileGuids,
      };
      case Types.SELECTEDFILE_CHANGE:
        return {
          ...state,
          selectedFile: action.selectedFile,
        };
    default:
      return state;
  }
}
