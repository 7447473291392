export default {
  'Image': 'Image',
  'Text': 'Text',
  'Add image': 'Add image',
  'Opacity': 'Opacity',
  "Add text": 'Add text',
  'Text Color': 'Text Color',
  'Outline': 'Outline',
  'Background': 'Background',
  'Text here': 'Text here',
}