export default {
  Back: "Zurück",
  Cut: "Schneiden",
  Speed: "Geschwindigkeit",
  "Crop & Rotate": "Zuschneiden & Drehen",
  Resize: "Größe ändern",
  Extract: "Extrakt",
  "Add Music": "Musik hinzufügen",
  Subtitles: "Untertitel",
  Merge: "Zusammenlegen",
  "Watermark Remover": "Wasserzeichen entfernen",
  "Add Watermark": "Wasserzeichen hinzufügen",
  "To GIF": "Zu GIF",
  Convert: "Konvertieren",
  Screenshot: "Bildschirmkopie",
  Reverse: "Zurückspulen",
  Loop: "Schleife",
};
