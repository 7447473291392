//i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ar from "./locales/ar/index";
import br from "./locales/br/index";
import cn from "./locales/cn/index";
import de from "./locales/de/index";
import en from "./locales/en/index";
import es from "./locales/es/index";
import fr from "./locales/fr/index";
import it from "./locales/it/index";
import jp from "./locales/jp/index";
import ru from "./locales/ru/index";
import tw from "./locales/tw/index";
import kr from "./locales/kr/index";
import id from "./locales/id/index";
import inn from "./locales/in/index";
import tr from "./locales/tk/index";
import nl from "./locales/nl/index";
import Utils from "./utils/utils";

const resources = { ar, br, cn, de, en, es, fr, it, jp, ru, tw, kr, id, inn, tr, nl };

let lang = Utils.getLanguage();
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: lang === "in" ? "inn" : lang,
    fallbackLng: "en",
    keySeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
window.i18n = i18n;
// export default i18n;
