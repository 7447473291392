import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "@/action";
import { message } from "antd";
import Utils from "@/utils/utils";
import AddFile from "./children/AddFile";
import ReactGA from "react-ga";
import TipsModal from "@/components/common/TipsModal";
import TipsModalSimple from "@/components/common/TipsModalSimple";
import moment from "moment";
import ProcessModal from "@/components/common/processModal/ProcessModal";
import { request } from "@/api/request";
import { api_config } from "@/constants/api";
import Cookies from "js-cookie";
import api_domain_config from "@/constants/common";
import { accountList } from "@/constants/url";

import "@/assets/upload.scss";

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      uploadXHR: null,
      uploadFail: false,
      warningInfo: false,
      warningInfoText: "",
      warningInfoObj: {},
      uploadTimeLimit: 3,
    };
  }

  /**
   * 视频文件导入成功埋点
   */
  sentGAImportVideoSuccess = () => {
    ReactGA.event(
      {
        category: "Upload",
        action: "Feature_Upload",
        label: this.props.work,
      },
      ["OnlineTracker", "Tracker"]
    );
    let category, action;
    switch (this.props.work) {
      case "cut":
        category = "Cut";
        action = "Add_file_cut";
        break;
      case "speed":
        category = "Speed";
        action = "Add_file_speed";
        break;
      case "crop_rotate":
        category = "Crop&Rotate";
        action = "Add_file_Crop&Rotate";
        break;
      case "resize":
        category = "Resize";
        action = "Add_file_Resize";
        break;
      case "merge":
        category = "Merge";
        action = "Add_file_Merge";
        break;
      case "subtitle":
        category = "AddText";
        action = "Add_file_Text";
        break;
      case "removeWatermark":
        category = "RemoveMark";
        action = "Add_file_RemoveWatermark";
        break;
      case "addWatermark":
        category = "AddMark";
        action = "Add_file_AddMark";
        break;
      case "addMusic":
        category = "AddMusic";
        action = "Add_file_AddMusic";
        break;
      default:
        break;
    }
    if (category && action) {
      ReactGA.event(
        {
          category: category,
          action: action,
        },
        ["OnlineTracker", "Tracker"]
      );
    }
  };

  fileToBlob = async (file, guid, recordVideo) => {
    console.log("fileblob", file);
    let blob;
    try {
      blob = new Blob([file], { type: file.type });
    } catch (err) {
      console.log("Turn Blob Error: ", err);
      return false;
    }
    const blobUrl = URL.createObjectURL(blob);
    let blobDuration;
    try {
      blobDuration = await Utils.getBlobDuration(blobUrl);
      await Utils.isVideoBlobURLLoadedSuccess(blobUrl);
    } catch (error) {
      console.log("blobDuration Err:", error);
      return false;
    }
    const duration = moment("00:00.00", "mm:ss.SS").add(blobDuration.toFixed(2), "seconds").format("mm:ss.SS");
    //screenshots
    let screenshots = [];
    const shot = blobDuration / 12;
    for (let i = 0; i < 12; i++) {
      screenshots.push(blobUrl + "#t=" + i * shot);
    }
    const thumbnail = blobUrl + "#t=0";
    const files = [...this.props.files];
    // console.log("fileblob7777", file);
    const arrItem = {
      name: file.name,
      guid,
      duration: duration,
      size: file.size,
      thumbnail: thumbnail,
      status: "origin",
      videofileurl: blobUrl,
      screenshots: screenshots,
      mark: false,
      uploadStatus: false,
      uploadTimes: 0,
      fileObj: file,
      recordVideo,
    };
    files.unshift(arrItem);
    // console.log("blob请求完成，转", files);
    localStorage.setItem("files", JSON.stringify(files));
    this.props.onFilesChange(files);
    this.props.onCurrFileChange(arrItem);
    return true;
  };

  upload = async (file, fileExiting, recordVideo) => {
    console.log("uploaduploaduploaduploaduploadfile", file);
    this.props.onFileToBlobStatusChange(false);
    this.props.onUploadPercentChange(0);
    this.props.onProcessPercentChange(0);
    this.props.onProcessStepChange(this.props.processStep + 3);
    const startTime = { ...this.props.startTime };
    startTime.upload = new Date().getTime();
    this.props.onStartTimeChange(startTime);
    if (this.props.work === "merge" && this.props.mergeWorkList.length >= 3) {
      // console.log("merge超过长度", this.props.mergeWorkList);
      const currFile = this.props.files.filter(item => item.guid === this.props.mergeWorkList[2].guid)[0];
      this.props.onCurrFileChange(currFile);
      this.setState({
        warningInfo: true,
        warningInfoText: "Add 3 videos at most.",
        warningInfoObj: { type: "merge" },
      });
      ReactGA.event(
        {
          category: "Merge",
          action: "MergOver10",
        },
        ["OnlineTracker", "Tracker"]
      );
      return false;
    }
    this.setState({ file: file });

    let guid = new Date().getTime();

    //当前工作区是work的话，需要直接上传，则不进入转blob逻辑
    if (this.props.work !== "reverse") {
      // 上传的文件已经存在列表中
      if (!fileExiting) {
        // V1.2.0: 先将视频转为Blob，转换失败再出现进度条
        let fileToBlobResponse = await this.fileToBlob(file, guid, recordVideo);
        if (fileToBlobResponse) {
          this.props.onFileToBlobStatusChange(true);
          this.sentGAImportVideoSuccess();
        }
      } else if (this.props.uploadAgainFile) {
        guid = this.props.uploadAgainFile.guid;
      }
    }

    // V1.1.0版本：一开始就需要出现进度条
    this.props.onUploadingChange(true);
    const newFileName = file.name.replace(/\(|\)|\s+|\uff08|\uff09|\u3010|\u3011|\$|#/g, "-");
    file = new File([file], newFileName, { type: file.type });
    // upload and converit to mp4
    let fileResponse;
    try {
      fileResponse = await this.uploadFile(file);
    } catch (error) {
      if (error !== "cancel") {
        if (!this.props.fileToBlobStatus) {
          this.setState({ uploadFail: true });
        }
        ReactGA.event(
          {
            category: "Upload",
            action: "LocalSerFail",
            label: error + "_none_none_" + this.props.tokenId + "_none_none_none",
          },
          ["OnlineTracker", "Tracker"]
        );
      }
      this.props.onUploadingChange(false);
      this.props.onUploadPercentChange(0);
      this.props.onProcessPercentChange(0);
      return false;
    }
    if (fileResponse.code === 200) {
      let progressResponse;
      if (fileResponse.data.result.number) {
        // get videofileurl
        const fileResponseNumber = fileResponse.data.result.number;
        // const fileResponsePid = fileResponse.data.result.pid;
        // console.log(fileResponseNumber, fileResponsePid);
        try {
          progressResponse = await Utils.getProgress(fileResponseNumber, false, true);
        } catch (error) {
          if (error !== "cancel") {
            if (!this.props.fileToBlobStatus) {
              this.setState({ uploadFail: true });
            }
            ReactGA.event(
              {
                category: "Upload",
                action: "LocalSerFail",
                label: "uploadVideo_200_200_" + error,
              },
              ["OnlineTracker", "Tracker"]
            );
          }
          this.props.onUploadingChange(false);
          this.props.onUploadPercentChange(0);
          this.props.onProcessPercentChange(0);
          return false;
        }
        console.log("progressResponse", progressResponse);
      } else {
        this.props.onProcessPercentChange(this.props.processPercent + 100);
      }
      const videofileurl = fileResponse.data.response_file[0];
      const duration = moment("00:00:00", "mm:ss:SS")
        .add(parseFloat(progressResponse.info[0].duration), "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1");
      const fps = progressResponse.info[0].fps;
      const bitrate = progressResponse.info[0].bit_rate;
      const jobId = fileResponse.data.result.number;
      let wh;
      try {
        wh = progressResponse.info[0].wh.match(/^\d+x\d+/)[0];
      } catch (e) {
        wh = "0x0";
      }
      // get screenshots
      const d = Utils.getSeconds(duration);
      if (d < 1) {
        this.setState({
          warningInfo: true,
          warningInfoText: "The uploaded video is less than 1 second.",
          warningInfoObj: { type: "duration", duration: d },
        });
        this.props.onUploadingChange(false);
        const newFiles = this.props.files.filter(item => item.guid !== guid);
        this.props.onProcessPercentChange(0);
        this.props.onUploadPercentChange(0);
        this.props.onProcessStepChange(0);
        this.props.onCurrFileChange(null);
        this.props.onFilesChange(newFiles);
        return false;
      }

      const screenshots = progressResponse.thumbnail[0].img;
      const thumbnail = screenshots[0];
      const files = [...this.props.files];
      let addFileLable = ""; //用于底下的埋点
      let reverseVideourl;
      if (this.props.work === "reverse") {
        reverseVideourl = await this.handleReverseVideo(videofileurl);
      }

      // thumbnail V1.1.0 版本，获取所有视频信息后才设置文件信息
      if (!this.props.fileToBlobStatus && !fileExiting) {
        console.log("进入这里这里这里");
        const arrItem = {
          name: file.name,
          guid: guid,
          duration: duration,
          size: file.size,
          thumbnail: thumbnail,
          status: "origin",
          videofileurl: videofileurl,
          screenshots: screenshots,
          mark: false,
          wh: wh,
          fps: fps,
          uploadStatus: true,
          recordVideo: null,
          bitrate: bitrate,
          reverseVideourl,
          jobId,
        };
        files.unshift(arrItem);
        console.log(/\.[^.]+$/.exec(file.name));
        addFileLable =
          (/\.[^.]+$/.exec(file.name) ? /\.[^.]+$/.exec(file.name)[0].toLocaleLowerCase().replace(".", "") : "mp4") +
          "_" +
          (file.size / 1024 / 1024).toFixed(2) +
          "mb_" +
          wh +
          "_" +
          fps +
          "_" +
          bitrate;
        this.sentGAImportVideoSuccess();
        if (!this.props.currFile) {
          this.props.onCurrFileChange(arrItem);
        }
      } else {
        files.forEach(item => {
          if (item.guid === guid) {
            item.duration = duration;
            item.thumbnail = thumbnail;
            item.screenshots = screenshots;
            item.videofileurl = videofileurl;
            item.wh = wh;
            item.fps = fps;
            item.uploadStatus = true;
            item.recordVideo = null;
            item.bitrate = bitrate;
            item.reverseVideourl = reverseVideourl;
            item.jobId = jobId;
            if ((!this.props.currFile || recordVideo || this.props.submitting) && !item.isUploadSuccessNotShow) {
              this.props.onCurrFileChange(item);
            }
            item.isUploadSuccessNotShow = false;
          }
        });
      }
      if (!this.props.submitting) {
        this.props.onProcessPercentChange(0);
        this.props.onUploadPercentChange(0);
      }
      this.props.onProcessModalVisibleChange(false);
      this.props.onUploadingChange(false);
      this.props.onFilesChange(files);
      localStorage.setItem("files", JSON.stringify(files));
      this.props.onPreUploadFileChange(null);
      message.destroy();
      ReactGA.event(
        {
          category: "Upload",
          action: "LocalSerSuccess",
        },
        ["OnlineTracker", "Tracker"]
      );
      if (this.props.work === "reverse" || this.props.work === "loop") {
        let category = this.props.work === "reverse" ? "Video_Reverse" : "Video_Loop";
        let action = this.props.work === "reverse" ? "Reverse_add_files" : "Loop_add_files";
        ReactGA.event(
          {
            category,
            action,
            label: addFileLable,
          },
          ["OnlineTracker", "Tracker"]
        );
      }
    } else {
      if (fileResponse.code === 401) {
        Cookies.remove("authorization", { domain: api_domain_config.DOMAIN });
        window.location.replace(`${accountList.get("accountLoginPage")}?redirect=${window.location.href}`);
      }
      if (!this.props.fileToBlobStatus) {
        this.setState({ uploadFail: true });
      }
      if (fileResponse.code === 500) {
        this.setState({
          warningInfo: true,
          warningInfoText: "The video format is not supported.",
        });
        const fileList = this.props.files.filter(item => item.guid !== guid);
        this.props.onFilesChange(fileList);
        this.props.onCurrFileChange(null);
      }
      this.props.onUploadingChange(false);
      this.props.onUploadPercentChange(0);
      this.props.onProcessPercentChange(0);
      ReactGA.event(
        {
          category: "Upload",
          action: "LocalSerFail",
          label: "uploadVideo_200_" + fileResponse.code + "_none_" + this.props.tokenId + "_none_" + fileResponse.msg,
        },
        ["OnlineTracker", "Tracker"]
      );
    }
  };

  /**
   * 请求上传——因为需要获取上传进度，fetch请求获取不到upload.onprogress，就是用XMLHttpRequest请求
   * @param {上传的文件} file
   */
  uploadFile = file => {
    ReactGA.event(
      {
        category: "Upload",
        action: "LocalSerStart",
      },
      ["OnlineTracker", "Tracker"]
    );
    const formData = new FormData();
    formData.append("files[]", file);
    this.props.onUploadPercentChange(1);
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${api_config.API_URL}/uploadVideo`, true);
      xhr.setRequestHeader("authorization", this.props.authorization);
      xhr.upload.onprogress = event => {
        if (event.lengthComputable) {
          this.props.onUploadPercentChange(parseInt((event.loaded / event.total) * 100));
        }
      };
      xhr.onerror = () => {
        reject("uploadVideo_" + xhr.status);
      };
      xhr.onabort = () => {
        ReactGA.event(
          {
            category: "Upload",
            action: "LoadCancel",
            label: (this.state.file.size / 1024 / 1024).toFixed(2) + "_" + (new Date().getTime() - this.props.startTime.upload) / 1000,
          },
          ["OnlineTracker", "Tracker"]
        );
        reject("cancel");
      };
      xhr.upload.onabort = () => {
        reject("cancel");
      };
      xhr.onload = () => {
        resolve(JSON.parse(xhr.response));
      };
      xhr.send(formData);
      this.setState({ uploadXHR: xhr });
      this.props.onUploadXHRChange(xhr);
    });
  };

  /**
   * 请求倒放视频接口
   * @returns 返回请求后的promise
   */
  getReverseVideo = url => {
    const formData = new FormData();
    formData.append("speed", 1);
    formData.append("mute_type", 0);
    formData.append("file_url", url);
    formData.append("token", this.props.tokenId);
    return new Promise((resolve, reject) => {
      request("reverse", {
        method: "post",
        body: formData,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  handleReverseVideo = async url => {
    let reverseResponse = await this.getReverseVideo(url);
    if (reverseResponse.code === 200) {
      const number = reverseResponse.data.result.number;
      let progressResponse;
      try {
        progressResponse = await Utils.getProgress(number, false, true);
      } catch (error) {
        if (error !== "cancel") {
          message.warning(this.props.t("Sorry, error occurs when analyzing the URL."));
        }
        this.setState({ visible: false });
        // ReactGA.event({
        //   category: "Upload",
        //   action: "URLFail",
        //   label: error,
        // });
        return false;
      }
      return progressResponse.info[0].url;
    } else {
      return false;
    }
  };
  cancelUpload = () => {
    const xhr = this.state.uploadXHR;
    if (xhr && this.props.uploadPercent < 100) {
      xhr.abort();
    } else {
      Utils.cancelProgress();
    }
    this.props.onUploadingChange(false);
    this.props.onUploadPercentChange(0);
    this.props.onProcessPercentChange(0);
  };

  cancelTryAgain = () => {
    this.setState({ uploadFail: false });
    this.props.onUploadingChange(false);
    this.props.onUploadPercentChange(0);
    this.props.onProcessPercentChange(0);
  };

  uploadAgain = () => {
    if (this.state.file) {
      this.upload(this.state.file);
    }
    this.setState({ uploadFail: false });
    ReactGA.event(
      {
        category: "Upload",
        action: "TryAgain",
      },
      ["OnlineTracker", "Tracker"]
    );
  };

  componentDidMount() {
    if (this.props.preUploadFile) {
      if (!this.props.submitting) {
        this.upload(this.props.preUploadFile);
      } else {
        this.upload(this.props.preUploadFile, true);
      }
    }
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("blob")) {
      try {
        fetch(urlParams.get("blob"))
          .then(response => response.blob())
          .then(responseBLOB => {
            const file = new File([responseBLOB], urlParams.has("type") ? "VideoFromOnline" : "videoFromRecorder", {
              type: "video/mp4",
            });
            this.upload(file, false, true);
            // window.history.replaceState({}, document.title, window.location.pathname);
            // window.history.replaceState({}, document.title, window.location.origin + "/online-tools/editor.html");
            window.history.replaceState({}, document.title, window.location.origin + window.location.pathname);
          });
      } catch (e) {
        console.log(e);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.preUploadFile !== this.props.preUploadFile && this.props.preUploadFile) {
      if (!this.props.submitting) {
        this.upload(this.props.preUploadFile);
      } else {
        this.upload(this.props.preUploadFile, true);
      }
    }

    //视频正在后台上传时，点击了 reverse 功能
    if (this.props.fileToBlobStatus && this.props.work === "reverse" && prevProps.work !== "reverse" && this.props.uploading) {
      console.log("显示进度条");
      // this.props.onSubmittingChange(true)
      this.props.onProcessModalVisibleChange(true);
    }
  }

  render() {
    return (
      <div className="Upload" style={!this.props.visible ? { display: "none" } : {}}>
        <AddFile
          upload={this.upload}
          onCurrFileChange={this.props.onCurrFileChange}
          onFilesChange={this.props.onFilesChange}
          onFileListModalChange={this.props.onFileListModalChange}
        />
        {(this.props.work === "addWatermark" || this.props.work === "subtitle") && window.userClient.platform === "mac" ? (
          <div>
            <TipsModalSimple
              visible={this.state.uploadFail}
              onCancel={() => {
                this.setState({ uploadFail: false });
              }}
              content={this.props.submitting ? "Processing failed." : "Failed to load!"}
              cancelBtnHandle={this.cancelTryAgain}
              confirmBtnHandle={this.uploadAgain}
              confirmBtnText="Try Again"
            />
            <TipsModalSimple
              visible={this.state.warningInfo}
              onCancel={() => {
                this.setState({ warningInfo: false });
              }}
              content={this.state.warningInfoText}
              tipsObj={this.state.warningInfoObj}
              cancelBtnHandle={() => {
                this.setState({ warningInfo: false });
              }}
            />
          </div>
        ) : (
          <div>
            <TipsModal
              visible={this.state.uploadFail}
              showGuidText
              onCancel={() => {
                this.setState({ uploadFail: false });
              }}
              content={this.props.submitting ? "Processing failed." : "Failed to load!"}
              cancelBtnHandle={this.cancelTryAgain}
              confirmBtnHandle={this.uploadAgain}
              confirmBtnText="Try Again"
              productKey={
                this.props.work === "removeWatermark"
                  ? "hitpaw-watermark-remover"
                  : window.userClient.platform === "win"
                  ? "hitpaw-video-editor"
                  : undefined
              }
            />
            <TipsModal
              showGuidText
              visible={this.state.warningInfo}
              onCancel={() => {
                this.setState({ warningInfo: false });
              }}
              content={this.state.warningInfoText}
              tipsObj={this.state.warningInfoObj}
              cancelBtnHandle={() => {
                this.setState({ warningInfo: false });
              }}
              hasDownload
              productKey={
                this.props.work === "removeWatermark"
                  ? "hitpaw-watermark-remover"
                  : window.userClient.platform === "win"
                  ? "hitpaw-video-editor"
                  : undefined
              }
            />
          </div>
        )}
        <ProcessModal />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  processPercent: state.work.processPercent,
  tokenId: state.common.tokenId,
  preUploadFile: state.files.preUploadFile,
  work: state.work.work,
  uploading: state.work.uploading,
  uploadPercent: state.work.uploadPercent,
  processStep: state.work.processStep,
  mergeWorkList: state.work.mergeWorkList,
  startTime: state.work.startTime,
  fileToBlobStatus: state.work.fileToBlobStatus,
  submitting: state.work.submitting,
  uploadAgainFile: state.files.uploadAgainFile,
  processModalVisible: state.work.processModalVisible,
  authorization: state.common.authorization,
});

const mapDispatchToProps = dispatch => ({
  onFilesChange: files => dispatch(actions.onFilesChange(files)),
  onProcessPercentChange: processPercent => dispatch(actions.onProcessPercentChange(processPercent)),
  onUploadingChange: uploading => dispatch(actions.onUploadingChange(uploading)),
  onUploadPercentChange: uploadPercent => dispatch(actions.onUploadPercentChange(uploadPercent)),
  onProcessStepChange: processStep => dispatch(actions.onProcessStepChange(processStep)),
  onMergeWorkListChange: mergeWorkList => dispatch(actions.onMergeWorkListChange(mergeWorkList)),
  onStartTimeChange: startTime => dispatch(actions.onStartTimeChange(startTime)),
  onPreUploadFileChange: preUploadFile => dispatch(actions.onPreUploadFileChange(preUploadFile)),
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
  onFileToBlobStatusChange: fileToBlobStatus => dispatch(actions.onFileToBlobStatusChange(fileToBlobStatus)),
  onUploadXHRChange: uploadXHR => dispatch(actions.onUploadXHRChange(uploadXHR)),
  onFileListModalChange: fileListModal => dispatch(actions.onFileListModalChange(fileListModal)),
  onProcessModalVisibleChange: processModalVisible => dispatch(actions.onProcessModalVisibleChange(processModalVisible)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(Upload);
