export default {
  Start: "Başlat",
  End: "Son",
  Save: "Kaydet",
  Cancel: "İptal",
  Download: "İndir",
  downloading: "İndiriliyor",
  "Drag and drop from Files or local disk": "Dosyalardan veya yerel diskten sürükleyip bırakın",
  "Click to Add File": "Dosya Eklemek İçin Tıklayın",
  "The minimum time length is 1s!": "Minimum süre uzunluğu 1s'dir!",
  "The maximum time length is 50s!": "Maksimum süre uzunluğu 50 saniyedir!",
  "Try Again": "Tekrar deneyin",
  "Coming Soon": "Çok yakında",
  "Learn more": "Daha Fazla Bilgi Edinin",
  Yes: "Evet",
  "Please click the Save button to ensure the previous editing to take effect.":
    "Ayar öğelerinin etkili olduğundan emin olmak için lütfen Kaydet düğmesine tıklayın.",
  // **************************TipsModal Message Title*****************************
  "Cut 10 video fragments at most. Get HitPaw Toolkit for more features.":
    "En fazla 10 video parçasını kesin. Daha fazla özellik için HitPaw Toolkit alın.",
  "Max file size for videos is 20 MB. Get HitPaw Toolkit for big file.":
    "Dosya boyutu 20MB'den büyük olamaz. Daha fazla özellik için HitPaw Toolkit alın.",
  "Max file size for videos is 20 MB. Get HitPaw Watermark Remover for big file.":
    "Dosya boyutu 20MB'den büyük olamaz. Daha fazla özellik için HitPaw Watermark Remover alın.",
  "The minimum time length is 1s! Get HitPaw Toolkit for more features.":
    "Minimum süre uzunluğu 1s'dir! Daha fazla özellik için HitPaw Toolkit alın.",
  "Add 3 videos at most. Get HitPaw Toolkit for more features.": "En fazla 3 video ekleyin. Daha fazla özellik için HitPaw Toolkit alın.",
  "This format is not supported currently.": "Bu biçim şu anda desteklenmiyor.",
  "This format is not supported currently. Get HitPaw Toolkit for more formats":
    "Bu biçim şu anda desteklenmiyor. Daha fazla özellik için HitPaw Toolkit alın.",
  "This format is not supported currently. Get HitPaw Watermark Remover for more formats":
    "Bu biçim şu anda desteklenmiyor. Daha fazla özellik için HitPaw Watermark Remover alın.",
  "Processing failed. Get HitPaw Toolkit for stable processing.": "Video Oluşturulamadı!",
  "Failed to load!  Get HitPaw Watermark Remover for stable processing.": "Yükleme başarısız!",
  "Are you sure you want to delete it?": "Silmek istediğinizden emin misiniz?",
  "Failed to extract the audio!": "Ses çıkarılamadı!",
  "Video link copied": "Video bağlantısı kopyalandı",
  "Cut 10 video fragments at most.": "En fazla 10 video parçasını kesin.",
  "Max file size for videos is 20 MB.": "Dosya boyutu 20 MB'den büyük olamaz. ",
  "Max file size for videos is 100 MB.": "Dosya boyutu 100 MB'den büyük olamaz. ",
  "Failed to load!": "Yükleme başarısız!",

  //******************************2021.05.17 v1.3.0 *******************************/
  "Screenshots downloaded.": "Ekran görüntüleri indirildi.",
  "Online service, no download required": "Çevrimiçi hizmet, indirme gerekmez",
  "Add up to 20MB file": "20MB’ye kadar dosya ekleyin",
  "Support common formats": "Ortak biçimleri destekler",
  "Best for general editing requirements": "Genel düzenleme gereksinimleriniz için en iyisi",
  "0 second wait": "0 saniye bekleme",
  "No size limits": "Boyut sınırı yok",
  "Support 1000+ formats": "1000'den fazla biçimi destekler",
  "Stable processing": "Kararlı işleme",
  "Get access to HitPaw Toolkit!": "HitPaw Toolkit'e erişin!",
  "Support cut, crop & rotate, adjust, speed, add music, stop motion, etc.":
    "Kesme, kırpma ve döndürme, ayarlama, hızlandırma, müzik ekleme, hareketi durdurma vb. destekler",
  "The easiest video editing software for beginners": "Yeni başlayanlar için en kolay video düzenleme yazılımı",
  "Remove watermark from videos and images in simple clicks": "Basit tıklamalarla videolardan ve resimlerden filigranı kaldırın",
  "TRY IT FREE": "ÜCRETSİZ DENEYİN",
  "BUY NOW": "ŞİMDİ SATIN AL",

  //******************************2021.09.06 v1.5.0********************************/
  "Add Video": "Video ekle",
  More: "Daha",
  "History files": "Geçmiş dosyaları",
  Share: "Paylaşmak",
  Duration: "Süre",
  "Download all": "Hepsini İndir",
  "Download selected": "Kısmi İndir",
  "Add Audio": "Ses Ekle",

  //********************************2021.10.19 v1.6.0*********************************/
  "GIF does not support editing. Do you want to convert the MP4 for editing?":
    "GIF düzenlemeyi desteklemez. MP4'ü düzenleme için dönüştürmek ister misiniz?",
  "Go to Edit": "Düzenlemeye git",
  "Download GIF": "GIF indir",

  //********************************2021.10.19 v1.6.1*********************************/
  "HitPaw Video Editor": "HitPaw Video Editor",
  "No video size limits": "Video boyutu sınırı yok.",
  "Cut/crop/rotate/resize/merge video easily": "Videoyu kolayca kesin/kırpın/döndürün/yeniden boyutlandırın/birleştirin.",
  "Tons of video effects are available for your video creativity": "Video yaratıcılığınız için tonlarca video efekti mevcuttur.",
  "Unlimited tracks allow you to add multiple audios as you want": "Sınırsız parça, istediğiniz gibi birden fazla ses eklemenize izin verir.",

  //********************************2021.11.02 v1.6.1*********************************/
  "Download HitPaw Video Editor for more features.": "Daha fazla özellik için HitPaw Video Editor'ı indirin.",
  "Get HitPaw Watermark Remover for more features.": "Daha fazla özellik için HitPaw Watermark Remover alın.",
  "Get HitPaw Toolkit for more features.": "Daha fazla özellik için HitPaw Toolkit alın.",
  "Add 3 videos at most.": "En fazla 3 video ekleyin. ",
  "Processing failed.": "İşleme başarısız. ",
  "The uploaded video is less than 1 second.": "Yüklenen video 1 saniyeden az. ",
  "The video format is not supported.": "Video biçimi desteklenmiyor. ",
  "The audio format is not supported.": "Ses biçimi desteklenmiyor. Daha",
  "AddFileByURL.largerSize.HitPawToolkit":
    "Başarıyla analiz ediliyor! Dosyanız 100 MB'den büyük ve HitPaw Online tarafından düzenlenemiyor, lütfen <1><0>videoyu indirin</0></1> ve devam etmek için HitPaw Toolkit'i kullanı",
  "AddFileByURL.largerSize.HitPawWatermarkRemover":
    "Başarıyla analiz ediliyor! Dosyanız 100 MB'den büyük ve HitPaw Online tarafından düzenlenemiyor, lütfen <1><0>videoyu indirin</0></1> ve devam etmek için HitPaw Watermark Remover'i kullanı",
  "AddFileByURL.largerSize.HitPawVideoEditor":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Video Editor to proceed.",
};
