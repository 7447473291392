export default {
  'Image': 'صورة',
  'Text': 'النص',
  'Add image': 'إضافة صورة',
  'Opacity': 'معدل الشفافية',
  "Add text": 'إضافة نص',
  'Text Color': 'لون النص',
  'Outline': 'الإطار الخارجي',
  'Background': 'خلفية',
  'Text here': 'اضف النص هنا',
}