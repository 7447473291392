import React, { Component } from "react";
import ReactGA from "react-ga";
import { NavbarRender } from "./children/NarbarRender";
import { ReactComponent as ResizeThin } from "@/assets/icons/thin/resize.svg";
import { ReactComponent as ResizeBold } from "@/assets/icons/bold/resize.svg";
import { ReactComponent as RemoveWatermarkThin } from "@/assets/icons/thin/remove-watermark.svg";
import { ReactComponent as RemoveWatermarkBold } from "@/assets/icons/bold/remove-watermark.svg";
import { ReactComponent as AddMusicThin } from "@/assets/icons/thin/add-music.svg";
import { ReactComponent as AddMusicBold } from "@/assets/icons/bold/add-music.svg";
import { ReactComponent as SubtitleThin } from "@/assets/icons/thin/subtitle.svg";
import { ReactComponent as SubtitleBold } from "@/assets/icons/bold/subtitle.svg";
import { ReactComponent as AddWatermarkThin } from "@/assets/icons/thin/add-watermark.svg";
import { ReactComponent as AddWatermarkBold } from "@/assets/icons/bold/add-watermark.svg";
import { ReactComponent as CutThin } from "@/assets/icons/thin/cut.svg";
import { ReactComponent as CutBold } from "@/assets/icons/bold/cut.svg";
import { ReactComponent as MergeThin } from "@/assets/icons/thin/merge.svg";
import { ReactComponent as MergeBold } from "@/assets/icons/bold/merge.svg";
import { ReactComponent as SpeedThin } from "@/assets/icons/thin/speed.svg";
import { ReactComponent as SpeedBold } from "@/assets/icons/bold/speed.svg";
import { ReactComponent as CropRotateThin } from "@/assets/icons/thin/crop-rotate.svg";
import { ReactComponent as CropRotateBold } from "@/assets/icons/bold/crop-rotate.svg";
import { ReactComponent as LoopThin } from "@/assets/icons/thin/loop.svg";
import { ReactComponent as LoopBold } from "@/assets/icons/bold/loop.svg";
import { ReactComponent as ReverseThin } from "@/assets/icons/thin/reverse.svg";
import { ReactComponent as ReverseBold } from "@/assets/icons/bold/reverse.svg";

export default class NarbarFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuList: [
        {
          key: "cut",
          title: "Cut",
          renderIcon: () => {
            return this.props.theme === "dark" ? <CutThin /> : <CutBold />;
          },
        },
        {
          key: "speed",
          title: "Speed",
          renderIcon: () => {
            return this.props.theme === "dark" ? <SpeedThin /> : <SpeedBold />;
          },
        },
        {
          key: "merge",
          title: "Merge",
          renderIcon: () => {
            return this.props.theme === "dark" ? <MergeThin /> : <MergeBold />;
          },
        },
        {
          key: "removeWatermark",
          title: "Watermark Remover",
          renderIcon: () => {
            return this.props.theme === "dark" ? <RemoveWatermarkThin /> : <RemoveWatermarkBold />;
          },
        },
        {
          key: "addWatermark",
          title: "Add Watermark",
          renderIcon: () => {
            return this.props.theme === "dark" ? <AddWatermarkThin /> : <AddWatermarkBold />;
          },
        },
        {
          key: "reverse",
          title: "Reverse",
          renderIcon: () => {
            return this.props.theme === "dark" ? <ReverseThin /> : <ReverseBold />;
          },
        },
        {
          key: "loop",
          title: "Loop",
          renderIcon: () => {
            return this.props.theme === "dark" ? <LoopThin /> : <LoopBold />;
          },
        },
        {
          key: "crop_rotate",
          title: "Crop & Rotate",
          renderIcon: () => {
            return this.props.theme === "dark" ? <CropRotateThin /> : <CropRotateBold />;
          },
        },
        {
          key: "subtitle",
          title: "Subtitles",
          renderIcon: () => {
            return this.props.theme === "dark" ? <SubtitleThin /> : <SubtitleBold />;
          },
        },
        {
          key: "resize",
          title: "Resize",
          renderIcon: () => {
            return this.props.theme === "dark" ? <ResizeThin /> : <ResizeBold />;
          },
        },
        {
          key: "addMusic",
          title: "Add Music",
          renderIcon: () => {
            return this.props.theme === "dark" ? <AddMusicThin /> : <AddMusicBold />;
          },
        },
      ],
      pageMenuList: [],
      currPage: 0,
    };
  }

  componentDidMount() {
    this.getPageMenuList();
    window.onresize = () => this.setState({ currPage: 0 }, this.getPageMenuList);
  }

  getPageMenuList = () => {
    const { menuList } = this.state;
    if (document.documentElement.clientWidth <= 992) {
      this.setState({
        pageMenuList: [menuList],
      });
      return false;
    }
    let pageSize = parseInt((document.documentElement.clientHeight - 156) / 72);
    let totalPage = menuList.length % pageSize === 0 ? parseInt(menuList.length / pageSize) : parseInt(menuList.length / pageSize) + 1;
    if (totalPage === 2) {
      pageSize = parseInt((document.documentElement.clientHeight - 156 - 56 - 28) / 72);
      totalPage = menuList.length % pageSize === 0 ? parseInt(menuList.length / pageSize) : parseInt(menuList.length / pageSize) + 1;
    } else if (totalPage > 2) {
      pageSize = parseInt((document.documentElement.clientHeight - 156 - 84) / 72);
      totalPage = menuList.length % pageSize === 0 ? parseInt(menuList.length / pageSize) : parseInt(menuList.length / pageSize) + 1;
    }
    let pageMenuList = [];
    for (let index = 0; index < totalPage; index++) {
      pageMenuList.push(menuList.slice(index * pageSize, index * pageSize + pageSize));
    }
    this.setState({
      pageMenuList,
    });
  };

  handleCurrPage = num => {
    this.setState({
      currPage: this.state.currPage + num,
    });
  };

  downloadClient = () => {
    ReactGA.event(
      {
        category: "Download",
        action: "PageDownload",
        label: window.userClient.platform + "_" + this.props.work,
      },
      ["OnlineTracker", "Tracker"]
    );
    if (this.props.work === "removeWatermark") {
      ReactGA.event(
        {
          category: "Download",
          action: "RemoverDownload",
          label: "page",
        },
        ["OnlineTracker", "Tracker"]
      );
    } else if (window.userClient.platform === "win") {
      ReactGA.event(
        {
          category: "Download",
          action: "Video_Editor_Download",
          label: "page",
        },
        ["OnlineTracker", "Tracker"]
      );
    } else {
      ReactGA.event(
        {
          category: "Download",
          action: "ToolkitDownload",
          label: "page",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
  };

  render() {
    return (
      <NavbarRender
        work={this.props.work}
        fileToBlobStatus={this.props.fileToBlobStatus}
        uploading={this.props.uploading}
        menuList={this.state.menuList}
        pageMenuList={this.state.pageMenuList}
        currPage={this.state.currPage}
        handleCurrPage={this.handleCurrPage}
        handleClick={this.props.handleClick}
        downloadClient={this.downloadClient}
        successFileGuids={this.props.successFileGuids}
        currFile={this.props.currFile}
        submitting={this.props.submitting}
        uploadPercent={this.props.uploadPercent}
        processPercent={this.props.processPercent}
        theme={this.props.theme}
      />
    );
  }
}
