export default {
  Back: "Voltar",
  Cut: "Cortar",
  Speed: "Velocidade",
  "Crop & Rotate": "Recortar & Girar",
  Resize: "Redimensionar",
  Extract: "Extrair",
  "Add Music": "Adicionar Músicas",
  Subtitles: "Legendas",
  Merge: "Unir",
  "Watermark Remover": "Remover Marca d'água",
  "Add Watermark": "Adicionar Marca d'água",
  "To GIF": "Para GIF",
  Convert: "Converter",
  Screenshot: "Print de Tela",
  Reverse: "Reverter",
  Loop: "Loop",
};
