export default {
  'Files': 'Dateien',
  'Refresh': 'Aktualisieren',
  'Original file': 'Originale Datei',
  'Just now': 'Gerade',
  'hour ago': 'Vor {{num}} Stunde',
  'hours ago': 'Vor {{num}} Stunden',
  'minute ago': 'Vor {{num}} Minute',
  'minutes ago': 'Vor {{num}} Minuten',
  'day ago': 'Vor {{num}} Tag',
  'View All': 'Alle anzeigen',
  'No files': 'Keine Dateien...',
  "Don't forget to download your file, it will be deleted after 24 hours": 'Vergessen Sie nicht, Ihre Datei herunterzuladen, sie wird 24 Stunden später im Überfluss vorhanden sein.',
  'Select All': 'Alle auswählen',
  'Delete': 'Löschen',
  'Generate Files Successfully!': 'Dateien werden erfolgreich generiert!',
  'Continue Editing': 'Bearbeiten fortfahren',
  'Edit New Video': 'Neues Video bearbeiten',
  'Download': 'Herunterladen',
  'Click here to open the Files again':'Klicken hier, um die Dateien erneut zu öffnen.',
  'Processing... Please wait': 'Verarbeiten.. Bitte warten',
  'Uploading... Please wait': 'Hochladen.. Bitte warten',
  'Edit Now': 'Bearbeiten',
  'Back to Edit':'Zurück zum Bearbeiten',
  'Go Edit':'zur Bearbeitung gehen',

}