import domain from "./domain";

/**
 * 生产环境
 */
const prod = {
  //cookie顶域
  DOMAIN: domain,
  //api
  API_V2_URL: "https://api.hitpaw.com/api/v2/",
};

/**
 * 开发和测试环境
 */
const dev = {
  //cookie顶域
  DOMAIN: window.location.hostname,
  //api
  API_V2_URL: "https://hitpaw-test-api.afirstsoft.cn/api/v2/",
};

export default process.env.NODE_ENV === "development" || process.env.REACT_APP_BUILD_ENV === "development" ? dev : prod;
