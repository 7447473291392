import React, { Component } from "react";
import { connect } from "react-redux";
import { request } from "@/api/request";
import moment from "moment";
import { workplaceHOC } from "../WorkplaceHOC";
import AddMusicWorkFunction from "./children/addMusicWork/AddMusicWorkFunction";
import actions from "@/action";
import Utils from "@/utils/utils";
import AddMusicTrimAudioModal from "./children/addMusicTrimAudioModal/AddMusicTrimAudioModal";
import TipsModal from "@/components/common/TipsModal";
import ReactGA from "react-ga";
import { api_config } from "@/constants/api";
import Cookies from "js-cookie";
import api_domain_config from "@/constants/common";
import { accountList } from "@/constants/url";

import "@/assets/addMusicWork.scss";

class AddMusicWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recommendFile: null,
      warningInfo: false,
      warningInfoText: "",
      warningInfoObj: {},
      audioFileObj: null,
      trimAudioVisible: false,
      audioStartTime: "00:00.00",
      lastAudioStartTime: "00:00.00",
      audioBufferSource: null,
      trimAudioDuration: "00:00.00",
      waitingUpload: false,
    };
  }

  draggerProps = {
    accept: ".WAV, .MP3, .M4A, .AAC, .WEBM, .FLAC, .MP4, .MOV",
    beforeUpload: file => {
      console.log(file.type);
      const myvideo = document.querySelector("#videoRef video");
      myvideo.pause();
      // if (file.size >= 20 * 1024 * 1024) {
      //==============之前的限制弹窗跟埋点：start===============
      // this.setState({
      //   warningInfo: true,
      //   warningInfoText: "Max file size for videos is 20 MB.",
      //   warningInfoObj: {
      //     type: "size",
      //     size: (file.size / 1024 / 1024).toFixed(2),
      //   },
      // });
      // ReactGA.event({
      //   category: "Upload",
      //   action: "ExceedSize",
      //   label: /\.[^.]+$/.exec(file.name)[0].toLocaleLowerCase() + "_" + (file.size / 1024 / 1024).toFixed(2),
      // });
      //==============之前的限制弹窗跟埋点：end===============
      // return false;
      // } else
      if (!Utils.validateAudio(file)) {
        this.setState({
          warningInfo: true,
          warningInfoText: "The audio format is not supported.",
          warningInfoObj: {
            type: "audioFormat",
            format: /\.[^.]+$/.exec(file.name)[0].toLocaleLowerCase(),
          },
        });
        ReactGA.event(
          {
            category: "AddMusic",
            action: "UnSupportAudio",
            label: `${file.type.split("/")[file.type.split("/").length - 1]}_${file.size}`,
          },
          ["OnlineTracker", "Tracker"]
        );
      } else {
        ReactGA.event(
          {
            category: "AddMusic",
            action: "AudioAdd",
            label: this.state.uploadClick ? "click" : "drag",
          },
          ["OnlineTracker", "Tracker"]
        );
        if (file.size >= 20 * 1024 * 1024) {
          if (Utils.isNeedToShowPurchaseModal()) {
            this.props.onPurchaseModalObjChange({
              visible: true,
              type: "uploadSizeBiggerThanLimit",
            });
            return false;
          }
        }
        this.setState({ uploadClick: false });
        this.uploadAudio(file);
      }
      return false;
    },
  };

  handleUploadClick = () => {
    this.setState({ uploadClick: true });
  };

  uploadAudio = async file => {
    let guid = new Date().getTime();
    const fileToBlobResponse = await this.fileToBlob(file, guid);
    if (!fileToBlobResponse) {
      this.setState({
        warningInfo: true,
        warningInfoText: "The audio format is not supported.",
        warningInfoObj: {
          type: "audioFormat",
          format: /\.[^.]+$/.exec(file.name)[0].toLocaleLowerCase(),
        },
      });
      ReactGA.event(
        {
          category: "AddMusic",
          action: "UnSupportAudio",
          label: `${file.type.split("/")[file.type.split("/").length - 1]}_${file.size}`,
        },
        ["OnlineTracker", "Tracker"]
      );
      return false;
    }
    ReactGA.event(
      {
        category: "AddMusic",
        action: "AudioInfo",
        label: `${file.type.split("/")[file.type.split("/").length - 1]}_${file.size}`,
      },
      ["OnlineTracker", "Tracker"]
    );
    this.props.handleAudioUploading(true);
    let fileResponse;
    try {
      fileResponse = await this.uploadFile(file);
    } catch (error) {
      if (error !== "cancel") {
        this.setState({
          warningInfo: true,
          warningInfoText: "The audio format is not supported.",
          warningInfoObj: {
            type: "audioFormat",
            format: /\.[^.]+$/.exec(file.name)[0].toLocaleLowerCase(),
          },
        });
      }
      this.props.handleAudioUploading(false);

      return false;
    }
    if (fileResponse.code === 200) {
      const fileResponseNumber = fileResponse.data.result.number;
      let progressResponse;
      try {
        progressResponse = await Utils.getProgress(fileResponseNumber, true, true);
      } catch (error) {
        if (error !== "cancel") {
          this.setState({
            warningInfo: true,
            warningInfoText: "The audio format is not supported.",
            warningInfoObj: {
              type: "audioFormat",
              format: /\.[^.]+$/.exec(file.name)[0].toLocaleLowerCase(),
            },
          });
          this.props.handleAudioUploading(false);
        }
        return false;
      }
      // const files = [...this.props.files];
      const duration = moment("00:00:00", "mm:ss:SS")
        .add(parseFloat(progressResponse.info[0].duration), "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1");
      const audiofileurl = fileResponse.data.response_file[0];
      // files.forEach(item => {
      //   if (item.guid === guid) {
      //     item.duration = duration;
      //     item.audiofileurl = audiofileurl;
      //     item.uploadStatus = true;
      //     this.props.onAddMusicWorkObjChange(item);
      //   }
      // });
      // localStorage.setItem("files", JSON.stringify(files));
      // this.props.onFilesChange(files);
      const addMusicWorkObj = { ...this.props.addMusicWorkObj };
      addMusicWorkObj.duration = duration;
      addMusicWorkObj.audiofileurl = audiofileurl;
      addMusicWorkObj.uploadStatus = true;
      this.props.onAddMusicWorkObjChange(addMusicWorkObj);
      this.props.handleAudioUploading(false);
    } else {
      if (fileResponse.code === 401) {
        Cookies.remove("authorization", { domain: api_domain_config.DOMAIN });
        window.location.replace(`${accountList.get("accountLoginPage")}?redirect=${window.location.href}`);
      }
      this.setState({
        warningInfo: true,
        warningInfoText: "The audio format is not supported.",
        warningInfoObj: {
          type: "audioFormat",
          format: /\.[^.]+$/.exec(file.name)[0].toLocaleLowerCase(),
        },
      });
      this.props.handleAudioUploading(false);
    }
  };

  uploadFile = file => {
    const formData = new FormData();
    formData.append("files[]", file);
    formData.append("token", this.props.tokenId);
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${api_config.API_URL}/uploadAudio`, true);
      xhr.setRequestHeader("authorization", this.props.authorization);
      xhr.onerror = () => {
        reject("uploadAudio_" + xhr.status);
      };
      xhr.onabort = () => {
        reject("cancel");
      };
      xhr.upload.onabort = () => {
        reject("cancel");
      };
      xhr.onload = () => {
        resolve(JSON.parse(xhr.response));
      };
      xhr.send(formData);
    });
  };

  fileToBlob = async (file, guid) => {
    let blob;
    try {
      blob = new Blob([file], { type: file.type });
    } catch (err) {
      console.log("Turn Blob Error: ", err);
      return false;
    }
    const blobUrl = URL.createObjectURL(blob);
    console.log(blobUrl);
    let blobDuration;
    try {
      blobDuration = await Utils.getBlobDuration(blobUrl);
    } catch (error) {
      console.log("blobDuration Err:", error);
      return false;
    }
    const duration = moment("00:00.00", "mm:ss.SS").add(blobDuration.toFixed(2), "seconds").format("mm:ss.SS");
    // const files = [...this.props.files];
    const arrItem = {
      name: file.name,
      guid,
      duration: duration,
      size: file.size,
      status: "origin",
      audiofileurl: blobUrl,
      mark: false,
      uploadStatus: false,
      fileObj: file,
    };
    // files.push(arrItem);
    // console.log("files", files);
    // localStorage.setItem("files", JSON.stringify(files));
    // this.props.onFilesChange(files);
    this.props.onAddMusicWorkObjChange(arrItem);
    return true;
  };

  preSubmit = () => {
    const { addMusicWorkObj, addMusicAudioVolumeObj, addMusicAudioStartPlayTime, addMusicAudioTimeObj, currFile } = this.props;
    let label = `${Utils.getSeconds(currFile.duration)}_${addMusicAudioStartPlayTime ? Utils.getSeconds(addMusicAudioStartPlayTime) : 0}_${
      addMusicAudioTimeObj.endTime
        ? Utils.getSeconds(addMusicAudioTimeObj.endTime) - Utils.getSeconds(addMusicAudioTimeObj.startTime)
        : Utils.getSeconds(addMusicWorkObj.duration)
    }_${addMusicAudioVolumeObj.audio * 200}_${addMusicAudioVolumeObj.video * 200}`;
    ReactGA.event(
      {
        category: "AddMusic",
        action: "MusicSave",
        label: label,
      },
      ["OnlineTracker", "Tracker"]
    );
    console.log("pre", this.props.uploading);
    //函数返回true，则是当前已经超过限制
    let flag = this.props.handleVideoEditorTimes();
    if (!flag) {
      this.props.preSubmit(1, this.handleSubmit);
    }
  };

  handleSubmit = async () => {
    const action = "videoAddBgm";
    const { addMusicWorkObj, addMusicAudioVolumeObj, addMusicAudioStartPlayTime, addMusicAudioTimeObj, currFile } = this.props;
    let label = `${Utils.getSeconds(currFile.duration)}_${addMusicAudioStartPlayTime ? Utils.getSeconds(addMusicAudioStartPlayTime) : 0}_${
      addMusicAudioTimeObj.endTime
        ? Utils.getSeconds(addMusicAudioTimeObj.endTime) - Utils.getSeconds(addMusicAudioTimeObj.startTime)
        : Utils.getSeconds(addMusicWorkObj.duration)
    }_${addMusicAudioVolumeObj.audio * 200}_${addMusicAudioVolumeObj.video * 200}`;
    ReactGA.event(
      {
        category: "AddMusic",
        action: "MusicSave",
        label: label,
      },
      ["OnlineTracker", "Tracker"]
    );
    const startTime = { ...this.props.startTime };
    startTime.addMusic = new Date();
    this.props.onStartTimeChange(startTime);
    if (!this.props.submitting) {
      this.props.onProcessPercentChange(1);
      this.props.onProcessStepChange(1);
    }
    let addMusicResponse;
    try {
      addMusicResponse = await this.getAddMusic(action);
    } catch (error) {
      this.props.handleSubmitFailModal(true);
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "AddMusic",
          action: "MusicFail",
          label: error + "_none_none_" + this.props.tokenId + "_none_none",
        },
        ["OnlineTracker", "Tracker"]
      );
      return false;
    }
    if (addMusicResponse.code === 200) {
      let guids = [];
      try {
        guids = await this.props.getNewVideoByProgress("addMusic", addMusicResponse);
      } catch (error) {
        if (error !== "cancel") {
          ReactGA.event(
            {
              category: "AddMusic",
              action: "MusicFail",
              label: action + "_200_200_" + error,
            },
            ["OnlineTracker", "Tracker"]
          );
        }
        return false;
      }
      // const modal = {
      //   getContainer: document.querySelector(".Home"),
      //   show: true,
      //   type: "addMusicSuccess",
      //   guid: guids[0],
      //   title: "Generate Files Successfully!",
      //   buttons: [
      //     {
      //       type: "download",
      //       text: "Download",
      //     },
      //     {
      //       type: "editNew",
      //       text: "Edit New Video",
      //     },
      //   ],
      // };
      // this.props.onFileListModalChange(modal);
      this.props.onSuccessFileGuidsChange(guids);
      this.props.onPrevWorkChange("addMusic");
      // this.props.onWorkChange(null);
      this.props.onProcessPercentChange(0);
      this.props.onSubmittingChange(false);
      this.props.onProcessStepChange(0);
      ReactGA.event(
        {
          category: "AddMusic",
          action: "MusicSuccess",
        },
        ["OnlineTracker", "Tracker"]
      );
    } else {
      if (addMusicResponse.code === 402102) {
        this.props.onPurchaseModalObjChange({
          visible: true,
          type: "videoEditorTimesMoreThanLimit",
        });
      } else {
        this.props.handleSubmitFailModal(true);
      }
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "AddMusic",
          action: "MusicFail",
          label: action + "_200_" + addMusicResponse.code + "_none_" + this.props.tokenId + "_none_" + addMusicResponse.msg,
        },
        ["OnlineTracker", "Tracker"]
      );
    }
  };

  getAddMusic = action => {
    const formData = new FormData();
    formData.append("file_url", this.props.currFile.videofileurl);
    formData.append("audio_url", this.props.addMusicWorkObj.audiofileurl);
    formData.append("video_volume", this.props.addMusicAudioVolumeObj.video * 2);
    formData.append("audio_volume", this.props.addMusicAudioVolumeObj.audio * 2);
    formData.append("audio_startat", this.props.addMusicAudioStartPlayTime ? Utils.getSeconds(this.props.addMusicAudioStartPlayTime) : 0);
    formData.append("audio_starttime", this.props.addMusicAudioTimeObj.startTime ? this.props.addMusicAudioTimeObj.startTime : "00:00.00");
    formData.append(
      "audio_duration",
      this.props.addMusicAudioTimeObj.endTime
        ? Utils.getSeconds(this.props.addMusicAudioTimeObj.endTime) - Utils.getSeconds(this.props.addMusicAudioTimeObj.startTime)
        : Utils.getSeconds(this.props.addMusicWorkObj.duration)
    );
    formData.append("token", this.props.tokenId);
    return new Promise((resolve, reject) => {
      request(action, {
        method: "post",
        body: formData,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  handleTrimAudioModal = () => {
    this.setState({ trimAudioVisible: true });
    const myvideo = document.querySelector("#videoRef video");
    if (!myvideo.paused) {
      myvideo.pause();
    }
    ReactGA.event(
      {
        category: "AddMusic",
        action: "AudioTrim",
      },
      ["OnlineTracker", "Tracker"]
    );
  };

  handleTrimAudio = (startTime, endTime) => {
    this.setState({ trimAudioVisible: false });
    this.props.onAddMusicAudioTimeObjChange({ startTime, endTime });
    const duration = moment("00:00.00", "mm:ss.SS")
      .add((Utils.getSeconds(endTime) - Utils.getSeconds(startTime)).toFixed(2), "seconds")
      .format("mm:ss.SS");
    this.setState({ trimAudioDuration: duration });
    ReactGA.event(
      {
        category: "AddMusic",
        action: "ConfirmTrim",
      },
      ["OnlineTracker", "Tracker"]
    );
  };

  handleAudioStartTime = e => {
    this.props.onUserActionChange(true);
    const time = e.target.value;
    const { currFile } = this.props;
    if (
      !/^\d+:\d+\.\d+$/.test(time) ||
      Utils.getSeconds(time) >= Utils.getSeconds(currFile.duration) ||
      !moment(time.replace(".", ":"), "mm:ss:SS").isValid()
    ) {
      this.setState({ audioStartTime: this.state.lastAudioStartTime });
    } else {
      const myvideo = document.querySelector("#videoRef video");
      if (!myvideo.paused) {
        myvideo.pause();
      }
      this.setState({ lastAudioStartTime: time });
      this.props.onAddMusicAudioStartPlayTimeChange(time);
    }
  };

  selectCurrentTime = () => {
    this.props.onUserActionChange(true);
    const myvideo = document.querySelector("#videoRef video");
    if (!myvideo.paused) {
      myvideo.pause();
    }
    const currentTime =
      myvideo.currentTime === 0
        ? "00:00.00"
        : moment("00:00:00", "mm:ss:SS")
            .add(myvideo.currentTime, "seconds")
            .format("mm:ss:SS")
            .replace(/:([^:]*)$/, ".$1");
    this.props.onAddMusicAudioStartPlayTimeChange(currentTime);
    this.setState({ audioStartTime: currentTime });
  };

  changeVideoVolumn = val => {
    this.props.onUserActionChange(true);
    const myvideo = document.querySelector("#videoRef video");
    myvideo.volume = val;
    this.props.onAddMusicAudioVolumeObjChange({
      ...this.props.addMusicAudioVolumeObj,
      video: val,
    });
    return val;
  };

  changeAudioVolumn = val => {
    this.props.onUserActionChange(true);
    this.props.onAddMusicAudioVolumeObjChange({
      ...this.props.addMusicAudioVolumeObj,
      audio: val,
    });
    return val;
  };

  clearAudio = () => {
    this.props.onAddMusicWorkObjChange({});
    this.props.onAddMusicAudioTimeObjChange({});
    this.props.onAddMusicAudioVolumeObjChange({ audio: 0.5, video: 0.5 });
    this.props.onAddMusicAudioStartPlayTimeChange(null);
    this.setState({ audioStartTime: "00:00.00" });
    const myvideo = document.querySelector("#videoRef video");
    if (!myvideo.paused) {
      myvideo.pause();
    }
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevStates) {
    if (prevProps.addMusicWorkObj !== this.props.addMusicWorkObj && this.props.addMusicWorkObj.duration) {
      if (Utils.getSeconds(this.props.addMusicWorkObj.duration) >= Utils.getSeconds(this.props.currFile.duration)) {
        this.setState({ trimAudioDuration: this.props.currFile.duration });
      } else if (Utils.getSeconds(this.props.addMusicWorkObj.duration) <= Utils.getSeconds(this.props.currFile.duration)) {
        this.setState({
          trimAudioDuration: this.props.addMusicWorkObj.duration,
        });
      }
    }
  }

  render() {
    return (
      <div
        className={"AddMusicWork" + (!this.props.currFile ? " disabled" : "")}
        onClick={() => {
          this.setState({ recommendFile: null });
        }}
      >
        <AddMusicWorkFunction
          draggerProps={this.draggerProps}
          trimAudioDuration={this.state.trimAudioDuration}
          currFile={this.props.currFile}
          addMusicWorkObj={this.props.addMusicWorkObj}
          clearAudio={this.clearAudio}
          audioStartTime={this.state.audioStartTime}
          handleAudioStartTime={this.handleAudioStartTime}
          handleStateAudioStareTime={audioStartTime => this.setState({ audioStartTime: audioStartTime.target.value })}
          selectCurrentTime={this.selectCurrentTime}
          changeAudioVolumn={this.changeAudioVolumn}
          changeVideoVolumn={this.changeVideoVolumn}
          handleTrimAudioModal={this.handleTrimAudioModal}
          handleUploadClick={this.handleUploadClick}
          preSubmit={this.preSubmit}
        />
        {this.state.trimAudioVisible && (
          <AddMusicTrimAudioModal
            visible={this.state.trimAudioVisible}
            onCancel={() => this.setState({ trimAudioVisible: false })}
            confirmBtnHandle={this.handleTrimAudio}
          />
        )}
        <TipsModal
          visible={this.state.warningInfo}
          onCancel={() => {
            this.setState({ warningInfo: false });
          }}
          showGuidText
          content={this.state.warningInfoText}
          productKey={window.userClient.platform === "win" ? "hitpaw-video-editor" : "hitpaw-toolkit"}
          tipsObj={this.state.warningInfoObj}
          cancelBtnHandle={() => {
            this.setState({ warningInfo: false });
          }}
          hasDownload
        />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  tokenId: state.common.tokenId,
  addMusicWorkObj: state.work.addMusicWorkObj,
  addMusicAudioVolumeObj: state.work.addMusicAudioVolumeObj,
  addMusicAudioTimeObj: state.work.addMusicAudioTimeObj,
  addMusicAudioStartPlayTime: state.work.addMusicAudioStartPlayTime,
  submitting: state.work.submitting,
  uploading: state.work.uploading,
  processStep: state.work.processStep,
  processPercent: state.work.processPercent,
  startTime: state.work.startTime,
  authorization: state.common.authorization,
});
const mapDispatchToProps = dispatch => ({
  onFilesChange: files => dispatch(actions.onFilesChange(files)),
  onFileListModalChange: fileListModal => dispatch(actions.onFileListModalChange(fileListModal)),
  onPreUploadFileChange: preUploadFile => dispatch(actions.onPreUploadFileChange(preUploadFile)),
  onProcessPercentChange: processPercent => dispatch(actions.onProcessPercentChange(processPercent)),
  onProcessStepChange: processStep => dispatch(actions.onProcessStepChange(processStep)),
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
  onAddMusicWorkObjChange: addMusicWorkObj => dispatch(actions.onAddMusicWorkObjChange(addMusicWorkObj)),
  onAddMusicAudioTimeObjChange: addMusicAudioTimeObj => dispatch(actions.onAddMusicAudioTimeObjChange(addMusicAudioTimeObj)),
  onAddMusicAudioStartPlayTimeChange: addMusicAudioStartPlayTime => dispatch(actions.onAddMusicAudioStartPlayTimeChange(addMusicAudioStartPlayTime)),
  onAddMusicAudioVolumeObjChange: addMusicAudioVolumeObj => dispatch(actions.onAddMusicAudioVolumeObjChange(addMusicAudioVolumeObj)),
  onSubmittingChange: submitting => dispatch(actions.onSubmittingChange(submitting)),
  onUploadAgainFileChange: uploadAgainFile => dispatch(actions.onUploadAgainFileChange(uploadAgainFile)),
  onStartTimeChange: startTime => dispatch(actions.onStartTimeChange(startTime)),
  onUserActionChange: userAction => dispatch(actions.onUserActionChange(userAction)),
  onWorkChange: work => dispatch(actions.onWorkChange(work)),
  onPrevWorkChange: prevWork => dispatch(actions.onPrevWorkChange(prevWork)),
  onSuccessFileGuidsChange: successFileGuids => dispatch(actions.onSuccessFileGuidsChange(successFileGuids)),
  onPurchaseModalObjChange: purchaseModal => dispatch(actions.onPurchaseModalObjChange(purchaseModal)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(workplaceHOC(AddMusicWork));
