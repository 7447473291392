import React, { Component } from "react";
import { AddMusicWorkRender } from "./children/AddMusicWorkRender";

export default class AddMusicWorkFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <AddMusicWorkRender
        currFile={this.props.currFile}
        draggerProps={this.props.draggerProps}
        addMusicWorkObj={this.props.addMusicWorkObj}
        clearAudio={this.props.clearAudio}
        audioStartTime={this.props.audioStartTime}
        handleAudioStartTime={this.props.handleAudioStartTime}
        handleStateAudioStareTime={this.props.handleStateAudioStareTime}
        handleTrimAudioModal={this.props.handleTrimAudioModal}
        trimAudioDuration={this.props.trimAudioDuration}
        selectCurrentTime={this.props.selectCurrentTime}
        changeAudioVolumn={this.props.changeAudioVolumn}
        changeVideoVolumn={this.props.changeVideoVolumn}
        handleUploadClick={this.props.handleUploadClick}
        preSubmit={this.props.preSubmit}
      />
    );
  }
}
