import React, { Component } from "react";
import { Breadcrumb, Slider, Tooltip } from "antd";
import { ReactComponent as Loading } from "@/assets/images/timg.svg";
import { ReactComponent as Screenshot } from "@/assets/icons/camera.svg";
import { ReactComponent as Pause } from "@/assets/icons/pause.svg";
import { ReactComponent as Play } from "@/assets/icons/play.svg";
import moment from "moment";
import Utils from "@/utils/utils";

export default class MergePreviewFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soundSlide: false,
      playing: false,
      currentTime: "00:00:00",
      currentTimePercent: 0,
      currentVolume: 0,
      cacheCutTime: [],
      miniLengthLimit: false,
      maxLengthLimit: false,
      isTimeRangeVisible: true,
    };
  }

  handleSoundClick = () => {
    const myvideo = document.querySelectorAll("#videoRef .mergeVideoWrap video");
    this.setState({
      currentVolume: myvideo[0].volume * 100,
      soundSlide: !this.state.soundSlide,
    });
  };

  handleSoundDrag = value => {
    const myvideo = document.querySelectorAll("#videoRef .mergeVideoWrap video");
    myvideo[0].volume = value / 100;
    return value;
  };

  handlePlayClick = play => {
    const myvideo = document.querySelectorAll("#videoRef .mergeVideoWrap video");
    if (play) {
      this.setState({ playing: true });
      myvideo.forEach(item => {
        item.play();
      });
      window.requestAnimationFrame(this._getCurrentTime);
    } else {
      this.setState({ playing: false });
      myvideo.forEach(item => {
        item.pause();
      });
    }
  };

  _getCurrentTime = () => {
    console.log(this.state.playing);
    if (!this.state.playing) {
      return false;
    }
    const myvideo = document.querySelectorAll("#videoRef .mergeVideoWrap video");
    let d = 0;
    for (let i = 0; i < this.props.mergeWorkList.length; i++) {
      if (i < Utils.getIndex(this.props.currFile, this.props.mergeWorkList)) {
        d = d + Utils.getSeconds(this.props.mergeWorkList[i].duration);
      }
    }
    this.setState({
      currentTime: moment("00:00:00", "mm:ss:SS")
        .add(myvideo[0].currentTime + d, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1"),
      currentTimePercent: parseInt((myvideo[0].currentTime / myvideo[0].duration) * 100),
    });
    if (myvideo[0].currentTime < myvideo[0].duration) {
      window.requestAnimationFrame(this._getCurrentTime);
    } else if (myvideo[0].duration) {
      if (Utils.getIndex(this.props.currFile, this.props.mergeWorkList) < this.props.mergeWorkList.length - 1) {
        this.props.onCurrFileChange(this.props.mergeWorkList[Utils.getIndex(this.props.currFile, this.props.mergeWorkList) + 1]);
      } else {
        myvideo.forEach(video => {
          if (!video.paused) {
            video.pause();
          }
        });
        this.setState({
          playing: false,
          currentTimePercent: 0,
          currentTime: "00:00:00",
        });
        this.props.onCurrFileChange(this.props.mergeWorkList[0]);
      }
    }
  };

  handleCurrentTimeChange = value => {
    const myvideo = document.querySelectorAll("#videoRef .mergeVideoWrap video");
    myvideo.forEach(item => {
      item.currentTime = item.duration * (value / 100);
    });
    this.setState({
      currentTime: moment("00:00:00", "mm:ss:SS")
        .add(myvideo[0].currentTime, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1"),
      currentTimePercent: value,
    });
  };

  getVideoWrapBoxSize = () => {
    const Preview = document.querySelector(".Preview");
    const videoWrapBox = document.querySelector("#videoRef .videoWrap-box");
    const myvideo = document.querySelectorAll("#videoRef .mergeVideoWrap video");
    if (Preview.clientWidth >= Preview.clientHeight - 200 && Preview.clientWidth / this.props.resolution <= Preview.clientHeight - 200) {
      videoWrapBox.style.width = Preview.clientWidth + "px";
      videoWrapBox.style.height = Preview.clientWidth / this.props.resolution + "px";
    } else {
      videoWrapBox.style.height = Preview.clientHeight - 200 + "px";
      videoWrapBox.style.width = (Preview.clientHeight - 200) * this.props.resolution + "px";
    }
    myvideo.forEach(item => {
      item.load();
    });
    if (!myvideo[0].handleLoadEvent) {
      myvideo[0].handleLoadEvent = e => {
        const videoWidth = e.target.videoWidth;
        const videoHeight = e.target.videoHeight;
        if (videoWidth / videoHeight >= this.props.resolution) {
          myvideo[0].style.width = "100%";
          myvideo[0].style.height = "auto";
          myvideo[1].style.width = "auto";
          myvideo[1].style.height = "100%";
        } else {
          myvideo[0].style.width = "auto";
          myvideo[0].style.height = "100%";
          myvideo[1].style.width = "100%";
          myvideo[1].style.height = "auto";
        }
        this.setState({ videoReady: true }, () => {
          const controls = document.querySelector(".controls");
          controls.style.width = videoWrapBox.clientWidth + "px";
          this.setState({
            isTimeRangeVisible: videoWrapBox.clientWidth < 360 ? false : true,
          });
        });
        if (this.state.playing) {
          this.handlePlayClick(true);
        }
      };
      myvideo[0].addEventListener("loadeddata", myvideo[0].handleLoadEvent);
    }
  };

  initMergeContros = () => {
    let d = 0;
    for (let i = 0; i < this.props.mergeWorkList.length; i++) {
      if (i < Utils.getIndex(this.props.currFile, this.props.mergeWorkList)) {
        d = d + Utils.getSeconds(this.props.mergeWorkList[i].duration);
      }
    }
    this.setState({
      currentTime: moment("00:00:00", "mm:ss:SS")
        .add(d, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1"),
      currentTimePercent: 0,
    });
  };

  componentDidMount() {
    this.getVideoWrapBoxSize();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currFile !== this.props.currFile) {
      this.getVideoWrapBoxSize();
      this.initMergeContros();
    }
    if (prevProps.resolution !== this.props.resolution) {
      this.getVideoWrapBoxSize();
    }

    if (this.props.submitting && !prevProps.submitting) {
      const myvideos = document.querySelectorAll("#videoRef .mergeVideoWrap video");
      if (this.state.playing) {
        this.setState({ playing: false });
        myvideos.forEach(video => video.pause());
      }
    }
  }

  render() {
    return this.state.videoReady ? (
      <div>
        <div className="controls">
          {/* <div className="soundControl">
            <SoundOutlined onClick={this.handleSoundClick} />
            {this.state.soundSlide ? <Slider defaultValue={this.state.currentVolume} tipFormatter={this.handleSoundDrag} /> : null}
          </div> */}
          <div className="controls-lft">
            {this.state.playing ? (
              <Pause
                onClick={() => {
                  this.handlePlayClick(false);
                }}
              />
            ) : (
              <Play
                onClick={() => {
                  this.handlePlayClick(true);
                }}
              />
            )}
          </div>
          <div className="frame-track frame-merge">
            {this.props.mergeWorkList.map((item, i) => (
              <div
                key={i}
                className={Utils.getIndex(this.props.currFile, this.props.mergeWorkList) === i ? "active" : ""}
                style={{ width: Math.round((Utils.getSeconds(item.duration) / Utils.getAllSeconds(this.props.mergeWorkList)) * 100) + "%" }}
              >
                <Slider
                  className="normal play-track"
                  value={this.state.currentTimePercent}
                  tipFormatter={null}
                  onChange={this.handleCurrentTimeChange}
                />
              </div>
            ))}
          </div>
          {this.state.isTimeRangeVisible ? (
            <Breadcrumb>
              <Breadcrumb.Item className="current-time">{this.state.currentTime}</Breadcrumb.Item>
              <Breadcrumb.Item>
                {moment("00:00:00", "mm:ss:SS")
                  .add(Utils.getAllSeconds(this.props.mergeWorkList), "seconds")
                  .format("mm:ss:SS")
                  .replace(/:([^:]*)$/, ".$1")}
              </Breadcrumb.Item>
            </Breadcrumb>
          ) : null}

          <Tooltip placement="top" title={window.i18n.t("navbar:Screenshot")}>
            <Screenshot className="screenshot-icon" onClick={() => Utils.screenshots()} />
          </Tooltip>
        </div>
      </div>
    ) : (
      <div style={{ textAlign: "center" }}>
        <Loading />
      </div>
    );
  }
}
