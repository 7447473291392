import React from "react";
import { Row, Col, Button, Slider } from "antd";
import { useTranslation } from "react-i18next";
import { ReactComponent as MuteVolume } from "@/assets/icons/mute-volume.svg";
import { ReactComponent as OriginalVolume } from "@/assets/icons/original-volume.svg";

import "@/assets/reverseWork.scss";

const ReverseWorkRender = ({ currFile, reverseObj, processModalVisible, handleSpeedChange, handleMuteChange, preSubmit, speedList, submitting }) => {
  const { t } = useTranslation(["common"]);
  return (
    <div className={"ReverseWork" + (!currFile ? " disabled" : "")}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row gutter={[12, 12]} style={{ padding: "0 1.25rem" }}>
            <Col span={24}>
              <Slider
                className="normal"
                // value={playbackRate * 100}
                value={reverseObj.speed}
                min={0.2}
                max={8}
                step={0.01}
                tooltipVisible={currFile && !submitting && !processModalVisible ? true : false}
                tipFormatter={value => "x " + value}
                onChange={value => handleSpeedChange(value)}
              />
            </Col>
          </Row>
          <Row gutter={[12, 12]} style={{ padding: "0 1.25rem" }}>
            {speedList.map(item => (
              <Col span={8} key={item.key}>
                <div className={`setSpeedBtn ${reverseObj.speed === item.key ? "active" : ""}`} onClick={() => handleSpeedChange(item.key)}>
                  {item.title}
                </div>
              </Col>
            ))}
          </Row>
          <hr />
          <Row gutter={[12, 12]} style={{ padding: "0 1.25rem" }}>
            <Col span={12}>
              <div className={`setVolumeBtn ${reverseObj.mute ? "active" : ""}`} onClick={() => handleMuteChange(true)}>
                <MuteVolume />
              </div>
            </Col>
            <Col span={12}>
              <div className={`setVolumeBtn ${!reverseObj.mute ? "active" : ""}`} onClick={() => handleMuteChange(false)}>
                <OriginalVolume />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <div style={{ padding: "0 1.5rem" }}>
        <Button className="save" type="primary" block={true} onClick={preSubmit} size="large">
          {t("common:Save")}
        </Button>
      </div>
    </div>
  );
};

export default ReverseWorkRender;
