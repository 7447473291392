import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Input } from "antd";
import { ReactComponent as Close } from "@/assets/icons/close.svg";

import Utils from "@/utils/utils";
import "@/assets/removeWatermarkWork.scss";

export const RemoveWatermarkWorkRender = ({
  currFile,
  removeWatermarkList,
  removeWork,
  handleStartChange,
  handleEndChange,
  handleSwitchSelect,
  preSubmit,
}) => {
  const { t } = useTranslation(["removeWatermarkWork", "common"]);
  return (
    <div className="RemoveWatermarkWork">
      <div className="wrap">
        <div className="add">
          <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <g fill="currentcolor" fillRule="nonzero">
              <path d="M2.5 5v6a.5.5 0 001 0V5a.5.5 0 00-1 0zM5 3.5h6a.5.5 0 100-1H5a.5.5 0 000 1zM12.5 5v6a.5.5 0 101 0V5a.5.5 0 00-1 0zM5 13.5h6a.5.5 0 100-1H5a.5.5 0 100 1z" />
              <path d="M3 11a2 2 0 01.15 3.995L3 15a2 2 0 01-.15-3.995L3 11zm10 0a2 2 0 01.15 3.995L13 15a2 2 0 01-.15-3.995L13 11zM3 12a1 1 0 100 2 1 1 0 000-2zm10 0a1 1 0 100 2 1 1 0 000-2zM3 1a2 2 0 01.15 3.995L3 5a2 2 0 01-.15-3.995L3 1zm10 0a2 2 0 01.15 3.995L13 5a2 2 0 01-.15-3.995L13 1zM3 2a1 1 0 100 2 1 1 0 000-2zm10 0a1 1 0 100 2 1 1 0 000-2z" />
            </g>
          </svg>
          <span>{t("Add Watermark Selection")}</span>
        </div>
        {currFile ? (
          <div
            className="itemlist"
            onClick={e => {
              handleSwitchSelect(e, -1);
            }}
          >
            {removeWatermarkList.map((item, index) => (
              <div
                className={`item ${item.selected ? "active" : ""}`}
                key={index}
                onClick={e => {
                  handleSwitchSelect(e, index);
                }}
              >
                <div className="item-cont">
                  <div style={{ marginBottom: "16px" }}>
                    {t("Watermark")} {index + 1}
                  </div>
                  <div className="item-time-cont">
                    <div style={{ flexShrink: 0, marginRight: 8 }}>{t("common:Duration")}</div>
                    <div className="item-time">
                      <Input
                        value={item.start}
                        onChange={handleStartChange}
                        onBlur={() => {
                          Utils.handleBlurFormat("REMOVEWATERMARKLIST_CHANGE", [...removeWatermarkList], index);
                        }}
                        bordered={false}
                      />
                      <span>-</span>
                      <Input
                        value={item.end}
                        onChange={handleEndChange}
                        onBlur={() => {
                          Utils.handleBlurFormat("REMOVEWATERMARKLIST_CHANGE", [...removeWatermarkList], index);
                        }}
                        bordered={false}
                      />
                    </div>
                  </div>
                </div>
                {removeWatermarkList.length > 1 ? (
                  <button
                    className="close"
                    onClick={e => {
                      removeWork(e, index);
                    }}
                  >
                    <Close />
                  </button>
                ) : null}
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <div className="save">
        <Button type="primary" block={true} onClick={preSubmit} size="large" disabled={currFile ? false : true}>
          {t("common:Save")}
        </Button>
      </div>
    </div>
  );
};
