export default {
  Start: "أبدأ",
  End: "انتهى",
  Save: "حفظ",
  Cancel: "إلغاء",
  Download: "تحميل",
  downloading: "جارى التحميل",
  "Drag and drop from Files or local disk": "قم بسحب الملفات وإفلاتها من مكتبة الملفات أو محليًا",
  "Click to Add File": "انقر لإضافة ملف",
  "The minimum time length is 1s!": "الحد الأدنى لطول الوقت هو 1s!",
  "The maximum time length is 50s!": "أقصى طول للوقت هو 50s!",
  "Try Again": "حاول مرة أخرى",
  "Coming Soon": "قريباً",
  "Learn more": "اعرف المزيد.",
  Yes: "حسنا",
  "Please click the Save button to ensure the previous editing to take effect.": "إذا لم يتم النقر فوق زر الحفظ ، فلن يتم تفعيل عناصر الإعداد.",
  // **************************TipsModal Message Title*****************************
  "Cut 10 video fragments at most. Get HitPaw Toolkit for more features.": "قص مقاطع فيديو 10 على الأكثر. احصل على HitPaw Toolkit لمزيد من الميزات",
  "Max file size for videos is 20 MB. Get HitPaw Toolkit for big file.":
    "لا يمكن أن يكون حجم الملف أكبر من20MB . احصل على HitPaw Toolkit لمزيد من الميزات. تعرف على المزيد",
  "Max file size for videos is 20 MB. Get HitPaw Watermark Remover for big file.":
    "لا يمكن أن يكون حجم الملف أكبر من20MB . احصل على HitPaw Watermark Remover لمزيد من الميزات. تعرف على المزيد",
  "The minimum time length is 1s! Get HitPaw Toolkit for more features.": "الحد الأدنى لطول الوقت هو 1s! احصل على HitPaw Toolkit لمزيد من الميزات",
  "Add 3 videos at most. Get HitPaw Toolkit for more features.": "أضف 3 مقاطع فيديو على الأكثر. احصل على HitPaw Toolkit لمزيد من الميزات",
  "This format is not supported currently.": "هذا التنسيق غير مدعوم حاليًا.",
  "This format is not supported currently. Get HitPaw Toolkit for more formats":
    "هذا التنسيق غير مدعوم حاليًا. احصل على HitPaw Toolkit لمزيد من الميزات",
  "This format is not supported currently. Get HitPaw Watermark Remover for more formats":
    "هذا التنسيق غير مدعوم حاليًا. احصل على HitPaw Watermark Remover لمزيد من الميزات",
  "Processing failed. Get HitPaw Toolkit for stable processing.": "فشل إنشاء الفيديو!",
  "Failed to load!  Get HitPaw Watermark Remover for stable processing.": "فشل في التحميل!",
  "Are you sure you want to delete it?": "هل أنت متأكد أنك تريد حذف ذلك؟",
  "Failed to extract the audio!": "فشل في استخراج الصوت!",
  "Video link copied": "تم نسخ رابط الفيديو",
  "Cut 10 video fragments at most.": "قص مقاطع فيديو 10 على الأكثر.",
  "Max file size for videos is 20 MB.": "لا يمكن أن يكون حجم الملف أكبر من 20MB.",
  "Max file size for videos is 100 MB.": "لا يمكن أن يكون حجم الملف أكبر من 100MB.",
  "Failed to load!": "فشل في التحميل!",

  //******************************2021.05.17 v1.3.0 *******************************/
  "Screenshots downloaded.": "تم التقاط لقطة شاشة وتنزيلها.",
  "Online service, no download required": "الخدمة عبر الإنترنت ، لا يلزم التنزيل",
  "Add up to 20MB file": "الحد الأقصى لحجم الملف هو 20 ميغا بايت",
  "Support common formats": "دعم التنسيقات الشائعة",
  "Best for general editing requirements": "الأفضل لمتطلبات التحرير العامة",
  "0 second wait": "0 ثواني انتظر",
  "No size limits": "لا حدود للحجم",
  "Support 1000+ formats": "دعم أكثر من 1000 تنسيق",
  "Stable processing": "معالجة مستقرة",
  "Get access to HitPaw Toolkit!": "الوصول إلى HitPaw Toolkit!",
  "Support cut, crop & rotate, adjust, speed, add music, stop motion, etc.":
    "يوفر وظائف مثل الاعتراض أو الدوران أو التقليب وتعديل اللون وتغيير السرعة وإضافة الموسيقى وما إلى ذلك.",
  "The easiest video editing software for beginners": "أسهل برامج تحرير الفيديو للمبتدئين",
  "Remove watermark from videos and images in simple clicks": "قم بإزالة العلامة المائية من مقاطع الفيديو والصور بنقرات بسيطة",
  "TRY IT FREE": "جربها مجانا",
  "BUY NOW": "اشتري الآن",

  //******************************2021.09.06 v1.5.0********************************/
  "Add Video": "أضف فيديو",
  More: "أكثر",
  "History files": "ملفات التاريخ",
  Share: "مشاركة",
  Duration: "مدة",
  "Download all": "تحميل الكل",
  "Download selected": "تحميل الجزء المحدد",
  "Add Audio": "أضف الصوت",

  //********************************2021.10.19 v1.6.0*********************************/
  "GIF does not support editing. Do you want to convert the MP4 for editing?": "GIF لا يدعم التحرير. هل تريد تحويل MP4 للتحرير؟",
  "Go to Edit": "ابدأ التحرير",
  "Download GIF": "تنزيل GIF",

  //********************************2021.10.19 v1.6.1*********************************/
  "HitPaw Video Editor": "HitPaw Video Editor",
  "No video size limits": "لا حدود لحجم الفيديو.",
  "Cut/crop/rotate/resize/merge video easily": "قص / اقتصاص / تدوير / تغيير حجم / دمج الفيديو بسهولة.",
  "Tons of video effects are available for your video creativity": "يتوفر الكثير من تأثيرات الفيديو لإبداع الفيديو الخاص بك.",
  "Unlimited tracks allow you to add multiple audios as you want": "تتيح لك المسارات غير المحدودة إضافة صوتيات متعددة كما تريد.",

  //********************************2021.11.02 v1.6.1*********************************/
  "Download HitPaw Video Editor for more features.": "قم بتنزيل HitPaw Video Editor لمزيد من الميزات.",
  "Get HitPaw Watermark Remover for more features.": "قم بتنزيل HitPaw Watermark Remover لمزيد من الميزات.",
  "Get HitPaw Toolkit for more features.": "قم بتنزيل HitPaw Toolkit لمزيد من الميزات.",
  "Add 3 videos at most.": "أضف 3 مقاطع فيديو على الأكثر.",
  "Processing failed.": "العمليه فشلت.",
  "The uploaded video is less than 1 second.": "الفيديو الذي تم تحميله أقل من ثانية واحدة.",
  "The video format is not supported.": " تنسيق الفيديو غير مدعوم.",
  "The audio format is not supported.": "تنسيق الصوت غير مدعوم.",
  "AddFileByURL.largerSize.HitPawToolkit":
    " اكتمل التحليل بنجاح! الملف الخاص بك أكبر من 100 ميغابايت والذي لا يمكن تحريره بواسطة موقع HitPaw Online، <1><0>الرجاء تحميل الفيديو</0></1> ثم استخدام أدوات HitPaw للمتابعة.",
  "AddFileByURL.largerSize.HitPawWatermarkRemover":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Watermark Remover to proceed.",
  "AddFileByURL.largerSize.HitPawVideoEditor":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Video Editor to proceed.",
};
