import React, { Component } from "react";
import { SubtitleWorkRender } from "./children/SubtitleWorkRender";

export default class SubtitleWorkFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textColorList: ["#4C69FF", "#000000", "#d7d7d7", "#fdf668", "#ffffff"],
      borderColorList: ["#4C69FF", "#000000", "#d7d7d7", "#fdf668", "none"],
      backgroundColorList: ["#4C69FF", "#000000", "#d7d7d7", "#fdf668", "none"],
    };
  }

  render() {
    return (
      <SubtitleWorkRender
        subtitleWorkList={this.props.subtitleWorkList}
        currFile={this.props.currFile}
        fontFamilyList={this.props.fontFamilyList}
        fontSizeList={this.props.fontSizeList}
        color={this.props.color}
        border={this.props.border}
        background={this.props.background}
        textAreaRef={this.props.textAreaRef}
        selectWork={this.props.selectWork}
        handleSetColor={this.props.handleSetColor}
        handleSetPosition={this.props.handleSetPosition}
        handleSetFontFamily={this.props.handleSetFontFamily}
        handleSetFontSize={this.props.handleSetFontSize}
        handleSubtitleChange={this.props.handleSubtitleChange}
        handleQuickSetColor={this.props.handleQuickSetColor}
        handleStartChange={this.props.handleStartChange}
        handleEndChange={this.props.handleEndChange}
        onBlurSubtitle={this.props.onBlurSubtitle}
        onFocusSubtitle={this.props.onFocusSubtitle}
        removeWork={this.props.removeWork}
        addWork={this.props.addWork}
        preSubmit={this.props.preSubmit}
        textColorList={this.state.textColorList}
        borderColorList={this.state.borderColorList}
        backgroundColorList={this.state.backgroundColorList}
      />
    );
  }
}
