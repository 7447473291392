import React from "react";
import { useTranslation } from "react-i18next";
import { Tabs, Row, Col, Input, Button, Slider, Upload, Select } from "antd";
import InputColor from "react-input-color";
import { PlusOutlined } from "@ant-design/icons";
import { ReactComponent as Close } from "@/assets/icons/close.svg";
import Utils from "@/utils/utils";
import "@/assets/addWatermarkWork.scss";

const { TextArea } = Input;
const { TabPane } = Tabs;
const { Option } = Select;

export const AddWatermarkWorkRender = ({
  addWatermarkWorkList,
  currFile,
  uploadProps,
  fontFamilyList,
  fontSizeList,
  color,
  border,
  textAreaRef,
  background,
  handleStartChange,
  handleEndChange,
  selectWork,
  handleQuickSetColor,
  handleSetColor,
  addWork,
  removeWork,
  handleSetOpacity,
  onBlurSubtitle,
  onFocusSubtitle,
  handleSetFontSize,
  handleSetFontFamily,
  handleSubtitleChange,
  preSubmit,
  textColorList,
  borderColorList,
  backgroundColorList,
}) => {
  const { t } = useTranslation(["addWatermarkWork", "common"]);
  return (
    <div className={"AddWatermarkWork" + (!currFile ? " disabled" : "")}>
      <Tabs style={{ flex: 2 }}>
        <TabPane tab={t("Image")} key="image" className="subtitle-list">
          <Upload {...uploadProps}>
            <div className="add">
              <button disabled={currFile ? false : true}>
                <PlusOutlined />
                <span>{t("Add image")}</span>
              </button>
            </div>
          </Upload>
          {currFile ? (
            <div className="itemlist">
              {addWatermarkWorkList.map((item, index) => {
                return item.imageObj ? (
                  <div className={`item ${item.selected ? "active" : ""}`} key={index} onClick={() => selectWork(index)}>
                    <div className="item-info">
                      <div className="img-box" style={{ backgroundImage: `url(${item.imageUrl})` }}></div>
                      <div>
                        <div>{t("common:Duration")}</div>
                        <div className="item-time-cont">
                          <Input
                            value={item.start}
                            onChange={handleStartChange}
                            onBlur={() => {
                              Utils.handleBlurFormat("CUTWORKLIST_CHANGE", [...addWatermarkWorkList], index);
                            }}
                            bordered={false}
                          />
                          <span>-</span>
                          <Input
                            value={item.end}
                            onChange={handleEndChange}
                            onBlur={() => {
                              Utils.handleBlurFormat("CUTWORKLIST_CHANGE", [...addWatermarkWorkList], index);
                            }}
                            bordered={false}
                          />
                        </div>
                      </div>
                    </div>
                    <Row align="middle" style={{ marginTop: "1rem" }}>
                      <span style={{ fontSize: "12px" }}>{t("Opacity")}</span>
                      <Slider
                        className="normal"
                        style={{ flex: "2", marginLeft: ".5rem" }}
                        min={0}
                        max={1}
                        step={0.1}
                        defaultValue={1}
                        onChange={val => handleSetOpacity(val, index)}
                      />
                    </Row>
                    <button
                      className="close"
                      onClick={e => {
                        removeWork(e, index);
                      }}
                    >
                      <Close />
                    </button>
                  </div>
                ) : null;
              })}
            </div>
          ) : null}
        </TabPane>
        <TabPane
          tab={t("Text")}
          key="text"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div className="list">
            <div className="add">
              <button onClick={addWork} disabled={currFile ? false : true}>
                <PlusOutlined />
                <span>{t("Add text")}</span>
              </button>
            </div>
            <div>
              {addWatermarkWorkList &&
                addWatermarkWorkList.map((item, index) => {
                  return !item.imageObj ? (
                    <div className={`subtitle-content ${item.selected ? "active" : ""}`} key={index} onClick={() => selectWork(index)}>
                      <TextArea
                        spellCheck={false}
                        onChange={e => handleSubtitleChange(e, index)}
                        value={item.subtitle}
                        placeholder={t("Text here")}
                        onBlur={e => onBlurSubtitle(e, index)}
                        onFocus={() => onFocusSubtitle(index)}
                        ref={textArea => {
                          textAreaRef[index] = textArea;
                        }}
                      />
                      <div className="time-row">
                        <span style={{ flexShrink: 0, marginBottom: 6 }}>{t("common:Duration")}</span>
                        <div className="time-cont">
                          <Input
                            value={item.start}
                            onChange={handleStartChange}
                            onBlur={() => {
                              Utils.handleBlurFormat("SUBTITLEWORKLIST_CHANGE", [...addWatermarkWorkList], index);
                            }}
                          />
                          -
                          <Input
                            value={item.end}
                            onChange={handleEndChange}
                            onBlur={() => {
                              Utils.handleBlurFormat("SUBTITLEWORKLIST_CHANGE", [...addWatermarkWorkList], index);
                            }}
                          />
                        </div>
                      </div>
                      <button
                        className="close"
                        onClick={e => {
                          removeWork(e, index);
                        }}
                      >
                        <Close />
                      </button>
                    </div>
                  ) : null;
                })}
            </div>
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <hr />
            <Row justify="space-between" gutter={[8, 0]} style={{ margin: "1rem calc( 1.5rem - 4px ) 0" }}>
              <Col span={14}>
                <Select defaultValue="System" onChange={handleSetFontFamily} style={{ width: "100%" }}>
                  {fontFamilyList.map(item => (
                    <Option value={item} key={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={10}>
                <Select defaultValue={22} onChange={handleSetFontSize} style={{ width: "100%" }}>
                  {fontSizeList.map(item => (
                    <Option value={item} key={item}>
                      {item}px
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Tabs className="color-picker-cont" defaultActiveKey="1" style={{ margin: "1rem 1.5rem 0" }}>
              <TabPane tab={t("Text Color")} key="1">
                <div className="color-picker">
                  <div className="color-input">
                    <InputColor initialValue="#000" onChange={val => handleSetColor("color", val)} placement="top" />
                    <span className="color-value">{color}</span>
                  </div>
                  {textColorList.map(item => (
                    <div
                      key={item}
                      className={`presetColor ${color === item ? "active" : ""}`}
                      onClick={() => {
                        handleQuickSetColor("color", item);
                      }}
                    >
                      <div style={{ background: item }}></div>
                    </div>
                  ))}
                </div>
              </TabPane>
              <TabPane tab={t("Outline")} key="2">
                <div className="color-picker">
                  <div className="color-input">
                    <InputColor initialValue="#000" onChange={val => handleSetColor("border", val)} placement="top" />
                    <span className="color-value">{border}</span>
                  </div>
                  {borderColorList.map(item => (
                    <div
                      key={item}
                      className={`presetColor ${border === item ? "active" : ""}`}
                      onClick={() => {
                        handleQuickSetColor("border", item);
                      }}
                    >
                      <div className={item === "none" ? "colorNone" : ""} style={item !== "none" ? { background: item } : {}}></div>
                    </div>
                  ))}
                </div>
              </TabPane>
              <TabPane tab={t("Background")} key="3">
                <div className="color-picker">
                  <div className="color-input">
                    <InputColor initialValue="#000" onChange={val => handleSetColor("background", val)} placement="top" />
                    <span className="color-value">{background}</span>
                  </div>
                  {backgroundColorList.map(item => (
                    <div
                      key={item}
                      className={`presetColor ${background === item ? "active" : ""}`}
                      onClick={() => {
                        handleQuickSetColor("background", item);
                      }}
                    >
                      <div className={item === "none" ? "colorNone" : ""} style={item !== "none" ? { background: item } : {}}></div>
                    </div>
                  ))}
                </div>
              </TabPane>
            </Tabs>
          </div>
        </TabPane>
      </Tabs>
      <div style={{ margin: "0 1.5rem 0" }} className="save">
        <Button type="primary" block={true} onClick={preSubmit} size="large" disabled={addWatermarkWorkList.length === 0}>
          {t("common:Save")}
        </Button>
      </div>
    </div>
  );
};
