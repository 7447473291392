import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography, Slider, Breadcrumb } from "antd";
import { ReactComponent as Pause } from "@/assets/icons/pause.svg";
import { ReactComponent as Play } from "@/assets/icons/play.svg";
import "@/assets/addMusicTrimAudioModal.scss";

const { Title } = Typography;

export const AddMusicTrimAudioRender = ({
  addMusicWorkObj,
  onCancel,
  confirmBtnHandle,
  waveformRef,
  currentTimePercent,
  handleCurrentTimeChange,
  startTime,
  endTime,
  getAudioPercent,
  miniLengthLimit,
  maxLengthLimit,
  handleRangeChange,
  playing,
  handlePlayClick,
  currentTime,
}) => {
  const { t } = useTranslation(["addMusicWork", "common"]);
  return (
    <div>
      <Title level={5}>{t("Trim audio")}</Title>
      <audio id="audio" style={{ display: "none" }}>
        <source src={addMusicWorkObj.audiofileurl} type="audio/mp3" />
      </audio>
      <div className="track">
        <div className="screenshots" ref={waveformRef} style={{ height: "40px", overflow: "hidden" }}></div>
        <div className="frame">
          <Slider value={currentTimePercent} tipFormatter={null} onChange={handleCurrentTimeChange} />
        </div>
        <div className="range">
          <div
            className="cover"
            style={{
              left: 0,
              width: getAudioPercent(startTime) + "%",
            }}
          ></div>
          <div
            className="cover"
            style={{
              left: getAudioPercent(endTime) + "%",
              width: 100 - getAudioPercent(endTime) + "%",
            }}
          ></div>
          <div
            className="include"
            style={{
              left: Math.round(getAudioPercent(startTime)) + "%",
              width: Math.round(getAudioPercent(endTime) - Math.round(getAudioPercent(startTime))) + "%",
            }}
          ></div>
          <Slider range value={[getAudioPercent(startTime), getAudioPercent(endTime)]} tipFormatter={null} onChange={handleRangeChange} />
          {miniLengthLimit ? <div className="miniLengthLimit">{t("common:The minimum time length is 1s!")}</div> : null}
          {maxLengthLimit ? <div className="miniLengthLimit">{t("common:The maximum time length is 50s!")}</div> : null}
        </div>
      </div>
      <div className="footer">
        <div className="controls">
          <div>{playing ? <Pause onClick={handlePlayClick} /> : <Play onClick={handlePlayClick} />}</div>
          <Breadcrumb>
            <Breadcrumb.Item className="current-time">{currentTime}</Breadcrumb.Item>
            <Breadcrumb.Item>{addMusicWorkObj.duration}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div style={{ textAlign: "right" }}>
          <Button style={{ width: "120px", marginLeft: "20px" }} ghost type="primary" onClick={onCancel} size="large">
            {t("common:Cancel")}
          </Button>
          <Button style={{ width: "120px", marginLeft: "20px" }} type="primary" onClick={() => confirmBtnHandle(startTime, endTime)} size="large">
            {t("Trim")}
          </Button>
        </div>
      </div>
    </div>
  );
};
