import {combineReducers} from 'redux'
import work from './work'
import files from './files'
import api from './api'
import common from './common'

const index = combineReducers({
	work: work,
	files: files,
	api: api,
	common: common
})

export default index
