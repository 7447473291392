export default {
  'Image': 'イメージ',
  'Text': 'テキスト',
  'Add image': 'イメージを追加',
  'Opacity': '不透明度',
  "Add text": 'テキストを追加',
  'Text Color': 'テキストカラー',
  'Outline': '外側',
  'Background': '背景',
  'Text here': 'ここにテキストを入力してください',
}