import React, { Component } from "react";
import { AddWatermarkWorkRender } from "./children/AddWatermarkWorkRender";

export default class AddWatermarkWorkFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textColorList: ["#4C69FF", "#000000", "#d7d7d7", "#fdf668", "#1E88E5", "#ffffff"],
      borderColorList: ["#4C69FF", "#000000", "#d7d7d7", "#fdf668", "#1E88E5", "none"],
      backgroundColorList: ["#4C69FF", "#000000", "#d7d7d7", "#fdf668", "#1E88E5", "none"],
    };
  }

  componentDidMount() {}

  render() {
    return (
      <AddWatermarkWorkRender
        addWatermarkWorkList={this.props.addWatermarkWorkList}
        currFile={this.props.currFile}
        uploadProps={this.props.uploadProps}
        fontFamilyList={this.props.fontFamilyList}
        fontSizeList={this.props.fontSizeList}
        color={this.props.color}
        border={this.props.border}
        textAreaRef={this.props.textAreaRef}
        background={this.props.background}
        handleStartChange={this.props.handleStartChange}
        handleEndChange={this.props.handleEndChange}
        selectWork={this.props.selectWork}
        handleQuickSetColor={this.props.handleQuickSetColor}
        handleSetColor={this.props.handleSetColor}
        addWork={this.props.addWork}
        removeWork={this.props.removeWork}
        handleSetOpacity={this.props.handleSetOpacity}
        onBlurSubtitle={this.props.onBlurSubtitle}
        onFocusSubtitle={this.props.onFocusSubtitle}
        handleSetFontSize={this.props.handleSetFontSize}
        handleSetFontFamily={this.props.handleSetFontFamily}
        handleSubtitleChange={this.props.handleSubtitleChange}
        preSubmit={this.props.preSubmit}
        textColorList={this.state.textColorList}
        borderColorList={this.state.borderColorList}
        backgroundColorList={this.state.backgroundColorList}
      />
    );
  }
}
