import React from "react";
import { Breadcrumb, Slider, Tooltip } from "antd";
import { ReactComponent as Pause } from "@/assets/icons/pause.svg";
import { ReactComponent as Play } from "@/assets/icons/play.svg";
import { ReactComponent as Loading } from "@/assets/images/timg.svg";
import { ReactComponent as Screenshot } from "@/assets/icons/camera.svg";
import { useTranslation } from "react-i18next";

export const ResizePreviewRender = ({
  videoReady,
  handlePlayClick,
  handleCurrentTimeChange,
  currentTime,
  currentTimePercent,
  currFile,
  playing,
  screenshots,
  isTimeRangeVisible,
}) => {
  const { t } = useTranslation(["navbar"]);

  return videoReady ? (
    <div>
      <div className="controls">
        <div className="controls-lft">
          {playing ? (
            <Pause
              onClick={() => {
                handlePlayClick(false);
              }}
            />
          ) : (
            <Play
              onClick={() => {
                handlePlayClick(true);
              }}
            />
          )}
        </div>
        <div className="frame-track">
          <div className="active" style={{ width: "100%" }}>
            <Slider className="normal play-track" value={currentTimePercent} tipFormatter={null} onChange={handleCurrentTimeChange} />
          </div>
        </div>
        {isTimeRangeVisible ? (
          <Breadcrumb>
            <Breadcrumb.Item className="current-time">{currentTime}</Breadcrumb.Item>
            <Breadcrumb.Item>{currFile.duration}</Breadcrumb.Item>
          </Breadcrumb>
        ) : null}
        <Tooltip placement="top" title={t("Screenshot")}>
          <Screenshot className="screenshot-icon" onClick={() => screenshots()} />
        </Tooltip>
      </div>
    </div>
  ) : (
    <div style={{ textAlign: "center" }}>
      <Loading />
    </div>
  );
};
