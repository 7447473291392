/**
 * 后端返回的操作类型匹配前端定义的
 */
const videoWorkType = new Map([
  ["Video/Upload", "origin"],
  ["Video/Sample", "origin"],
  ["Video/Cut", "cut"],
  ["Video/Resize", "resize"],
  ["Video/RemoveWatermark", "removeWatermark"],
  ["Video/Merge", "merge"],
  ["Video/AddMusic", "addMusic"],
  ["Video/ChangeSpeed", "speed"],
  ["Video/CropAndRotate", "crop_rotate"],
  ["Video/AddSubtitle", "subtitle"],
  ["Video/AddWatermark", "addWatermark"],
  ["Video/Reverse", "reverse"],
  ["Video/Loop", "loop"],
]);

export default videoWorkType;
