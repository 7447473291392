export default {
  Back: "Terug",
  Cut: "Snijden",
  Speed: "Snelheid",
  "Crop & Rotate": "Bijsnijden & roteren",
  Resize: "Formaat wijzigen",
  Extract: "Uittreksel",
  "Add Music": "Muziek toevoegen",
  Subtitles: "Ondertitels",
  Merge: "Samenvoegen",
  "Watermark Remover": "Watermerk verwijderen",
  "Add Watermark": "Watermerk toevoegen",
  "To GIF": "Naar GIF",
  Convert: "Converteren",
  Screenshot: "Schermafbeelding",
  Reverse: "Omgekeerd",
  Loop: "Loop",
};
