import Utils from "@/utils/utils";
/**
 * 购买信息map
 */
const domainList = new Map([
  ["ar", "hitpaw.com"], //SEO站点特殊，ar.hitpaw.com 是 hitpaw.com 的子域名
  ["br", "hitpaw.com.br"],
  ["cn", "hitpaw.com"], //暂时没有SEO页面，先用英语占位
  ["de", "hitpaw.de"],
  ["es", "hitpaw.es"],
  ["en", "hitpaw.com"],
  ["fr", "hitpaw.fr"],
  ["id", "hitpaw.id"],
  ["in", "hitpaw.in"],
  ["it", "hitpaw.it"],
  ["jp", "hitpaw.jp"],
  ["kr", "hitpaw.kr"],
  ["nl", "hitpaw.nl"],
  ["ru", "hitpaw.ru"],
  ["tr", "hitpaw.com"], //SEO站点特殊，tr.hitpaw.com 是 hitpaw.com 的子域名
  ["tw", "hitpaw.tw"],
]);

export default domainList.get(Utils.getLanguage());
