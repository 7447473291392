import React, { Component } from "react";
import { AddFileByURLRender } from "./children/AddFileByURLRender";

export default class AddFileByURLFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.inputRef = React.createRef();
  }

  /**
   * 点击按钮触发解析url事件
   */
  handleClickBtnToParseUrl = () => {
    // 获取input节点
    if (this.inputRef.current) {
      //通过失去焦点的方式去触发，handleBlurToParseUrl方法去请求接口
      //先focus后blur
      this.inputRef.current.input.focus();
      this.inputRef.current.input.blur();
    }
  };

  handleBlurToParseUrl = event => {
    const url = event.target.value;
    this.props.parseUrl(url);
  };

  render() {
    return (
      <AddFileByURLRender
        handleClickBtnToParseUrl={this.handleClickBtnToParseUrl}
        handleBlurToParseUrl={this.handleBlurToParseUrl}
        inputRef={this.inputRef}
        onUrlChange={this.props.onUrlChange}
        parseSample={this.props.parseSample}
        visible={this.props.visible}
        fetchUrl={this.props.fetchUrl}
        onCancel={this.props.onCancel}
        clickSample={this.props.clickSample}
        url={this.props.url}
      />
    );
  }
}
