import { api_config } from "@/constants/api";

const defaultState = {
  api: api_config.API_URL,
  ws_api: api_config.WS_API_URL,
  api_v2: api_config.API_V2_URL,
};

export default function onAction(state = defaultState) {
  return state;
}
