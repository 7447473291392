import React, { Component } from "react";
import { CutWorkRender } from "./children/CutWorkRender";

export default class CutWorkFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <CutWorkRender
        currFile={this.props.currFile}
        cutWorkList={this.props.cutWorkList}
        addWork={this.props.addWork}
        handleSwitchSelect={this.props.handleSwitchSelect}
        handleStartChange={this.props.handleStartChange}
        handleEndChange={this.props.handleEndChange}
        removeCutWork={this.props.removeCutWork}
        preSubmit={this.props.preSubmit}
      />
    );
  }
}
