import React, { Component } from "react";
import { connect } from "react-redux";
import { workplaceHOC } from "../WorkplaceHOC";
import SubtitleWorkFunction from "./children/SubtitleWorkFunction";
import moment from "moment";
import actions from "@/action";
import Utils from "@/utils/utils";
import Konva from "konva";
import ReactGA from "react-ga";
import "@/assets/subtitleWork.scss";
import { request } from "@/api/request";

class SubtitleWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: "top-center",
      currentItemIndex: 0,
      color: "#000",
      border: "#000",
      background: "#000",
      fontSize: 22,
      fontSizeList: [8, 10, 12, 14, 18, 22, 28, 32, 36, 42, 48, 60, 72],
      fontFamily: "System",
      fontFamilyList: [
        "System",
        "Impact",
        "Arial",
        "Verdana",
        "Courier",
        "Helvetica",
        "Times New Roman",
        "Arial Unicode MS",
        "Georgia",
        "Trebuchet MS",
        "Arial Black",
        "Calibri",
        "Cambria",
        "Cambria Math",
        "Courier New",
        "Microsoft Yahei",
        "Modern",
        "MS Sans Serif",
        "MS Serif",
        "Tahoma",
        "Symbol",
        "Sagoe UI",
      ],
    };
    this.textAreaRef = [];
  }

  initWork = () => {
    const { currFile } = this.props;
    const obj = {
      subtitle: window.i18n.t("subtitleWork:Text here"),
      start: "00:00.00",
      end: Utils.getSeconds(currFile.duration) > 3 ? "00:03.00" : currFile.duration,
      initFlag: true,
      selected: true,
      color: "#ffffff",
      border: "none",
      background: "none",
      fontSize: this.state.fontSize,
      fontFamily: this.state.fontFamily,
      positionInitFlag: true,
      position: { x: 0, y: 0 },
    };
    // obj.position = this.getInitPosition(obj)
    this.props.onSubtitleWorkListChange([obj]);
  };

  addWork = () => {
    this.props.onUserActionChange(true);
    if (this.checkEmptySubtitle()) {
      return false;
    }
    const { subtitleWorkList, currFile } = this.props;
    let list = [...subtitleWorkList];
    list.forEach(item => {
      item.selected = false;
    });
    const lastItem = subtitleWorkList[subtitleWorkList.length - 1];
    const remainingTime = Utils.getSeconds(currFile.duration) - Utils.getSeconds(lastItem.end);
    let newItem = {
      ...lastItem,
      subtitle: window.i18n.t("subtitleWork:Text here"),
      initFlag: true,
      selected: true,
      positionInitFlag: true,
      position: { x: 0, y: 0 },
    };
    if (remainingTime <= 0) {
      newItem.start = lastItem.start;
      newItem.end = lastItem.end;
    } else {
      newItem.start = moment(lastItem.end.replace("."), "mm:ss:SS")
        .add(0.01, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1");
      newItem.end =
        remainingTime > 3
          ? moment(lastItem.end.replace("."), "mm:ss:SS")
              .add(3.01, "seconds")
              .format("mm:ss:SS")
              .replace(/:([^:]*)$/, ".$1")
          : currFile.duration;
    }
    list.push(newItem);
    // this.setState({ currentItemIndex: list.length - 1 })
    this.props.onSubtitleWorkListChange(list);
  };

  checkEmptySubtitle = () => {
    let flag = false;
    this.props.subtitleWorkList.some((item, index) => {
      if (!item.subtitle) {
        this.textAreaRef[index].focus();
        flag = true;
        let list = [...this.props.subtitleWorkList];
        list.forEach(obj => {
          obj.selected = false;
        });
        list[index].selected = true;
        this.props.onSubtitleWorkListChange(list);
        // this.setState({ currentItemIndex: index })
        console.log("hahha", index);
        return true;
      }
      return false;
    });
    console.log(flag);
    return flag;
  };

  removeWork = (e, index) => {
    e.stopPropagation();
    // if(this.checkEmptySubtitle()){
    //   return false
    // }
    const { subtitleWorkList } = this.props;
    const { currentItemIndex } = this.state;
    let list = [...subtitleWorkList];
    list.splice(index, 1);
    this.props.onSubtitleWorkListChange(list);
    console.log(index === 0 ? 0 : index - 1);
    if (currentItemIndex === index) {
      this.setState({ currentItemIndex: index === 0 ? 0 : index - 1 });
    } else {
      this.setState({
        currentItemIndex: currentItemIndex === 0 ? 0 : currentItemIndex - 1,
      });
    }
  };

  selectWork = index => {
    console.log("selectWork", index);
    if (this.checkEmptySubtitle()) {
      return false;
    }
    console.log("jinlaizhhusd");
    let list = [...this.props.subtitleWorkList];
    list.forEach((item, i) => {
      if (i === index) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
    console.log(list);
    this.props.onSubtitleWorkListChange(list);
    this.setState({ currentItemIndex: index });
  };

  handleSetColor = (key, { hex }) => {
    this.props.onUserActionChange(true);
    if (hex === "#000") {
      return false;
    }
    const { subtitleWorkList } = this.props;
    const list = [...subtitleWorkList];
    list.forEach(item => {
      item[key] = hex;
    });
    this.props.onSubtitleWorkListChange(list);
    switch (key) {
      case "color":
        this.setState({ color: hex });
        break;
      case "border":
        this.setState({ border: hex });
        break;
      case "background":
        this.setState({ background: hex });
        break;
      default:
        break;
    }
  };

  handleSetPosition = (position, num) => {
    const item = {
      position,
      time: this.props.quicklyPosition ? this.props.quicklyPosition.time + 1 : 0,
    };
    this.props.onQuicklyPositionChange(item);
    this.setState({ position, clickPosition: num });
  };

  handleSubtitleChange = (e, index) => {
    this.props.onUserActionChange(true);
    const subtitle = e.target.value;
    const { subtitleWorkList } = this.props;
    const list = [...subtitleWorkList];
    list[index] = {
      ...list[index],
      subtitle,
      initFlag: false,
    };
    this.props.onSubtitleWorkListChange(list);
  };

  onBlurSubtitle = (e, index) => {
    if (!e.target.value) {
      this.textAreaRef[index].focus();
    }
  };

  onFocusSubtitle = index => {
    const { subtitleWorkList } = this.props;
    const list = [...subtitleWorkList];
    if (list[index].initFlag) {
      list[index].subtitle = "";
      this.props.onSubtitleWorkListChange(list);
    }
    this.setState({ currentItemIndex: index });
  };

  handleSetFontSize = val => {
    this.props.onUserActionChange(true);
    const { subtitleWorkList } = this.props;
    const list = [...subtitleWorkList];
    list.forEach(item => {
      item.fontSize = val;
    });
    this.props.onSubtitleWorkListChange(list);
    this.setState({ fontSize: val });
  };

  handleSetFontFamily = val => {
    this.props.onUserActionChange(true);
    const { subtitleWorkList } = this.props;
    const list = [...subtitleWorkList];
    list.forEach(item => {
      item.fontFamily = val;
    });
    this.props.onSubtitleWorkListChange(list);
    this.setState({ fontFamily: val });
  };

  handleQuickSetColor = (key, val) => {
    this.props.onUserActionChange(true);
    const { subtitleWorkList } = this.props;
    const list = [...subtitleWorkList];
    list.forEach(item => {
      item[key] = val;
    });
    this.props.onSubtitleWorkListChange(list);
    switch (key) {
      case "color":
        this.setState({ color: val });
        break;
      case "border":
        this.setState({ border: val });
        break;
      case "background":
        this.setState({ background: val });
        break;
      default:
        break;
    }
  };

  handleStartChange = e => {
    this.props.onUserActionChange(true);
    const value = e.target.value;
    const subtitleWorkList = [...this.props.subtitleWorkList];
    const subtitleWork = subtitleWorkList.filter(item => item.selected)[0];
    subtitleWork.start = value;
    this.props.onSubtitleWorkListChange(subtitleWorkList);
    // this.props.onUserActionChange(true)
  };

  handleEndChange = e => {
    this.props.onUserActionChange(true);
    const value = e.target.value;
    const subtitleWorkList = [...this.props.subtitleWorkList];
    const subtitleWork = subtitleWorkList.filter(item => item.selected)[0];
    subtitleWork.end = value;
    this.props.onSubtitleWorkListChange(subtitleWorkList);
    // this.props.onUserActionChange(true)
  };

  handleSubmit = async () => {
    const action = "addWatermark";
    const startTime = { ...this.props.startTime };
    startTime.subtitle = new Date();
    this.props.onStartTimeChange(startTime);
    if (!this.props.uploading && this.props.uploadPercent < 100) {
      this.props.onProcessPercentChange(1);
      this.props.onProcessStepChange(1);
    }
    const list = this.getFormData();
    let subtitleResponse;
    try {
      subtitleResponse = await this.getAddSubtitleToVideo(list, action);
    } catch (error) {
      this.props.handleSubmitFailModal(true);
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "AddText",
          action: "TextFail",
          label: error + "_none_none_" + this.props.tokenId + "_none_none",
        },
        ["OnlineTracker", "Tracker"]
      );
      return false;
    }
    if (subtitleResponse.code === 200) {
      let guids = [];
      try {
        guids = await this.props.getNewVideoByProgress("subtitle", subtitleResponse);
      } catch (error) {
        if (error !== "cancel") {
          ReactGA.event(
            {
              category: "AddText",
              action: "TextFail",
              label: action + "_200_200_" + error,
            },
            ["OnlineTracker", "Tracker"]
          );
        }
        return false;
      }
      // const modal = {
      //   getContainer: document.querySelector(".Home"),
      //   show: true,
      //   type: "subtitleSuccess",
      //   guid: guids[0],
      //   title: "Generate Files Successfully!",
      //   buttons: [
      //     {
      //       type: "download",
      //       text: "Download",
      //     },
      //     {
      //       type: "editNew",
      //       text: "Edit New Video",
      //     },
      //   ],
      // };
      // this.props.onFileListModalChange(modal);
      this.props.onSuccessFileGuidsChange(guids);
      this.props.onPrevWorkChange("subtitle");
      // this.props.onWorkChange(null);
      this.props.onUploadPercentChange(0);
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      // this.props.onSubtitleWorkListChange([])
      // this.initWork()
      ReactGA.event(
        {
          category: "AddText",
          action: "TextSuccess",
        },
        ["OnlineTracker", "Tracker"]
      );
    } else {
      if (subtitleResponse.code === 402102) {
        this.props.onPurchaseModalObjChange({
          visible: true,
          type: "videoEditorTimesMoreThanLimit",
        });
      } else {
        this.props.handleSubmitFailModal(true);
      }
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "AddText",
          action: "TextFail",
          label: "addWatermark_200_" + subtitleResponse.code + this.props.tokenId,
        },
        ["OnlineTracker", "Tracker"]
      );
    }
  };

  preSubmit = () => {
    const { subtitleWorkList } = this.props;
    let label = `${subtitleWorkList.length}_${subtitleWorkList[0].fontFamily}_${subtitleWorkList[0].fontSize}_${
      this.state.clickPosition ? this.state.clickPosition : "none"
    }`;
    ReactGA.event(
      {
        category: "AddText",
        action: "TextSave",
        label,
      },
      ["OnlineTracker", "Tracker"]
    );
    console.log("pre", this.props.processStep);
    //函数返回true，则是当前已经超过限制
    let flag = this.props.handleVideoEditorTimes();
    if (!flag) {
      this.props.preSubmit(1, this.handleSubmit);
    }
  };

  getFormData = () => {
    const video = document.querySelector("#videoRef video");
    const { subtitleWorkList } = this.props;
    const w = video.videoWidth;
    const h = video.videoHeight;
    const list = [];
    const stage = new Konva.Stage({
      container: "container",
      width: w,
      height: h,
    });
    const layer = new Konva.Layer();
    subtitleWorkList.forEach(item => {
      layer.clearBeforeDraw();
      layer.destroyChildren();
      const label = new Konva.Label({
        x: item.position.x,
        y: item.position.y,
      });
      const tag = new Konva.Tag({
        fill: item.background === "none" ? null : item.background,
      });
      const text = new Konva.Text({
        text: item.subtitle,
        fill: item.color,
        fontSize: item.fontSize,
        fontFamily: item.fontFamily,
        padding: item.background !== "none" || item.border !== "none" ? 8 : 0,
        stroke: item.border === "none" ? null : item.border,
        strokeWidth: 0.5,
      });
      label.add(tag);
      label.add(text);
      layer.add(label);
      stage.add(layer);
      layer.draw();
      const obj = {
        enable: `${Utils.getSeconds(item.start)},${Utils.getSeconds(item.end)}`,
        content: stage.toDataURL(),
      };
      list.push(obj);
    });
    return list;
  };

  getAddSubtitleToVideo = (list, action) => {
    const formData = new FormData();
    list.forEach(item => {
      let subtitle = JSON.stringify(item);
      formData.append("locations[]", subtitle);
    });
    formData.append("token", this.props.tokenId);
    formData.append("type", "subtitle");
    formData.append("video_url", this.props.currFile.videofileurl);
    return new Promise((resolve, reject) => {
      request(action, {
        method: "post",
        body: formData,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  componentDidMount() {
    if (this.props.currFile) {
      this.initWork();
    }
    this.setState({
      color: "#ffffff",
      border: "none",
      background: "none",
      fontSize: 22,
      fontFamily: "System",
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currFile !== this.props.currFile || prevProps.work !== this.props.work) {
      this.setState({
        color: "#ffffff",
        border: "none",
        background: "none",
        fontSize: 22,
        fontFamily: "System",
      });
    }
    if (prevProps.currFile !== this.props.currFile && this.props.currFile) {
      this.initWork();
    }
  }

  render() {
    return (
      <div style={{ height: "100%" }}>
        <SubtitleWorkFunction
          subtitleWorkList={this.props.subtitleWorkList}
          currFile={this.props.currFile}
          fontFamilyList={this.state.fontFamilyList}
          fontSizeList={this.state.fontSizeList}
          color={this.state.color}
          border={this.state.border}
          background={this.state.background}
          textAreaRef={this.textAreaRef}
          selectWork={this.selectWork}
          handleSetColor={this.handleSetColor}
          handleSetPosition={this.handleSetPosition}
          handleSubtitleChange={this.handleSubtitleChange}
          handleQuickSetColor={this.handleQuickSetColor}
          onBlurSubtitle={this.onBlurSubtitle}
          onFocusSubtitle={this.onFocusSubtitle}
          removeWork={this.removeWork}
          addWork={this.addWork}
          handleSetFontSize={this.handleSetFontSize}
          handleSetFontFamily={this.handleSetFontFamily}
          handleStartChange={this.handleStartChange}
          handleEndChange={this.handleEndChange}
          preSubmit={this.preSubmit}
        />
        <div id="container" style={{ display: "none" }}></div>
        <div id="containerPosition" style={{ display: "none" }}></div>
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  subtitleWorkList: state.work.subtitleWorkList,

  tokenId: state.common.tokenId,
  quicklyPosition: state.work.quicklyPosition,
  uploading: state.work.uploading,
  processStep: state.work.processStep,
  processPercent: state.work.processPercent,
  cancelProgress: state.work.cancelProgress,
  submitting: state.work.submitting,
  uploadPercent: state.work.uploadPercent,
  startTime: state.work.startTime,
});
const mapDispatchToProps = dispatch => ({
  onSubtitleWorkListChange: subtitleWorkList => dispatch(actions.onSubtitleWorkListChange(subtitleWorkList)),
  onQuicklyPositionChange: quicklyPosition => dispatch(actions.onQuicklyPositionChange(quicklyPosition)),
  onProcessPercentChange: processPercent => dispatch(actions.onProcessPercentChange(processPercent)),
  onProcessStepChange: processStep => dispatch(actions.onProcessStepChange(processStep)),
  onPreUploadFileChange: preUploadFile => dispatch(actions.onPreUploadFileChange(preUploadFile)),
  onUploadAgainFileChange: uploadAgainFile => dispatch(actions.onUploadAgainFileChange(uploadAgainFile)),
  onUploadPercentChange: uploadPercent => dispatch(actions.onUploadPercentChange(uploadPercent)),
  onSubmittingChange: submitting => dispatch(actions.onSubmittingChange(submitting)),
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
  onFilesChange: files => dispatch(actions.onFilesChange(files)),
  onFileListModalChange: fileListModal => dispatch(actions.onFileListModalChange(fileListModal)),
  onStartTimeChange: startTime => dispatch(actions.onStartTimeChange(startTime)),
  onUserActionChange: userAction => dispatch(actions.onUserActionChange(userAction)),
  onWorkChange: work => dispatch(actions.onWorkChange(work)),
  onPrevWorkChange: prevWork => dispatch(actions.onPrevWorkChange(prevWork)),
  onSuccessFileGuidsChange: successFileGuids => dispatch(actions.onSuccessFileGuidsChange(successFileGuids)),
  onPurchaseModalObjChange: purchaseModal => dispatch(actions.onPurchaseModalObjChange(purchaseModal)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(workplaceHOC(SubtitleWork));
