import React from "react";
import { Row, Col, Button } from "antd";
import { useTranslation } from "react-i18next";
import "@/assets/loopWork.scss";

const LoopWorkRender = ({ currFile, loopTimes, handleLoopTimes, preSubmit, loopVideoDuration, submitting, fileListModal }) => {
  const { t } = useTranslation(["loopWork"]);
  return (
    <div className={"LoopWork" + (!currFile ? " disabled" : "")}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row gutter={[12, 12]}>
            <Col span={8}>
              <div className={`setLoopTimesBtn ${loopTimes === 2 ? "active" : ""}`} onClick={() => handleLoopTimes(2)}>
                2x
              </div>
            </Col>
            <Col span={8}>
              <div className={`setLoopTimesBtn ${loopTimes === 3 ? "active" : ""}`} onClick={() => handleLoopTimes(3)}>
                3x
              </div>
            </Col>
            <Col span={8}>
              <div className={`setLoopTimesBtn ${loopTimes === 4 ? "active" : ""}`} onClick={() => handleLoopTimes(4)}>
                4x
              </div>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col span={8}>
              <div className={`setLoopTimesBtn ${loopTimes === 5 ? "active" : ""}`} onClick={() => handleLoopTimes(5)}>
                5x
              </div>
            </Col>
            <Col span={8}>
              <div className={`setLoopTimesBtn ${loopTimes === 6 ? "active" : ""}`} onClick={() => handleLoopTimes(6)}>
                6x
              </div>
            </Col>
            <Col span={8}>
              <div className={`setLoopTimesBtn ${loopTimes === 8 ? "active" : ""}`} onClick={() => handleLoopTimes(8)}>
                8x
              </div>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <div className={`setLoopGIFBtn ${loopTimes === "gif" ? "active" : ""}`} onClick={() => handleLoopTimes("gif")}>
                {t("Loop as a GIF")}
              </div>
            </Col>
            {currFile ? (
              <Col span={24}>
                <p className="loopTips">{loopTimes === "gif" ? t("without sound") : t("Final output duration") + " : " + loopVideoDuration}</p>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>
      <Button className="save" type="primary" block={true} onClick={preSubmit} size="large">
        {t("common:Save")}
      </Button>
    </div>
  );
};

export default LoopWorkRender;
