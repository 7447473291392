import React, { Component } from "react";
import { GifWorkRender } from "./children/GifWorkRender";

export default class GifWorkFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
   
    return (
      <GifWorkRender
        fps={this.props.fps}
        marks={this.props.marks}
        currFile={this.props.currFile}
        cutWorkList={this.props.cutWorkList}
        resolution={this.props.resolution}
        handleStartChange={this.props.handleStartChange}
        handleRateChange={this.props.handleRateChange}
        handleResolutionChange={this.props.handleResolutionChange}
        handleEndChange={this.props.handleEndChange}
        framesRate={this.props.framesRate}
        preSubmit={this.props.preSubmit}
        submitting={this.props.submitting}
        fileListModal={this.props.fileListModal}
      />
    );
  }
}
