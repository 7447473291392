import React, { Component } from "react";
import { connect } from "react-redux";
import { workplaceHOC } from "../WorkplaceHOC";
import actions from "@/action";
import { request } from "@/api/request";
import ResizeWorkFunction from "./children/ResizeWorkFunction";
import ReactGA from "react-ga";
import TipsModal from "@/components/common/TipsModal";
import "@/assets/resizeWork.scss";

class ResizeWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recommendFile: null,
      warningInfo: false,
      warningInfoText: "",
    };
  }

  handleResolutionChange = value => {
    this.props.onResolutionChange(value);
    localStorage.setItem("resolution", value);
    this.props.onUserActionChange(true);
  };

  handleSubmit = async () => {
    // this.getActions();
    // return false;
    const startTime = { ...this.props.startTime };
    startTime.resize = new Date();
    this.props.onStartTimeChange(startTime);
    if (!this.props.uploading && this.props.uploadPercent < 100) {
      this.props.onProcessPercentChange(1);
      this.props.onProcessStepChange(1);
    }
    let ResolutionVideoResponse;
    try {
      ResolutionVideoResponse = await this.getResolutionVideo();
    } catch (error) {
      this.props.handleSubmitFailModal(true);
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "Resize",
          action: "ResizeFail",
          label: error + "_none_none_" + this.props.tokenId + "_none_none",
        },
        ["OnlineTracker", "Tracker"]
      );
      return false;
    }
    if (ResolutionVideoResponse.code === 200) {
      let guids = [];
      try {
        guids = await this.props.getNewVideoByProgress("resize", ResolutionVideoResponse);
      } catch (error) {
        if (error !== "cancel") {
          ReactGA.event(
            {
              category: "Resize",
              action: "ResizeFail",
              label: "resize_200_200_" + error,
            },
            ["OnlineTracker", "Tracker"]
          );
        }
        return false;
      }
      // const modal = {
      //   getContainer: document.querySelector(".Home"),
      //   show: true,
      //   type: "resizeSuccess",
      //   title: "Generate Files Successfully!",
      //   guid: guids[0],
      //   buttons: [
      //     {
      //       type: "download",
      //       text: "Download",
      //     },
      //     {
      //       type: "editNew",
      //       text: "Edit New Video",
      //     },
      //   ],
      // };
      // this.props.onFileListModalChange(modal);
      this.props.onSuccessFileGuidsChange(guids);
      this.props.onPrevWorkChange("resize");
      // this.props.onWorkChange(null);
      this.props.onProcessPercentChange(0);
      this.props.onUploadPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      const newFile = this.props.files.filter(item => item.guid === guids[0])[0];
      const newW = parseInt(newFile.wh.split("x")[0]);
      const newH = parseInt(newFile.wh.split("x")[1]);
      const oldW = parseInt(this.props.currFile.wh.split("x")[0]);
      const oldH = parseInt(this.props.currFile.wh.split("x")[1]);
      ReactGA.event(
        {
          category: "Resize",
          action: "ResizeSuccess",
          label: (oldW / oldH).toFixed(4) + "_" + (newW / newH).toFixed(4),
        },
        ["OnlineTracker", "Tracker"]
      );
    } else {
      if (ResolutionVideoResponse.code === 402102) {
        this.props.onPurchaseModalObjChange({
          visible: true,
          type: "videoEditorTimesMoreThanLimit",
        });
      } else {
        this.props.handleSubmitFailModal(true);
      }
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "Resize",
          action: "ResizeFail",
          label: "resize_200_" + ResolutionVideoResponse.code + "_none_" + this.props.tokenId + "_none_" + ResolutionVideoResponse.msg,
        },
        ["OnlineTracker", "Tracker"]
      );
    }
  };

  getActions = () => {
    let actions = [];
    let warpW, warpH, videoW, videoH, direction, aspect, x, y, padH, padV;
    const videoWidth = parseInt(this.props.currFile.wh.substring(0, this.props.currFile.wh.indexOf("x")));
    const videoHeight = parseInt(this.props.currFile.wh.substring(this.props.currFile.wh.indexOf("x") + 1));
    if (this.props.videoBgColor && this.props.videoBgColor.indexOf("bg") !== -1 && this.props.filling === "fit") {
      if (videoWidth / videoHeight >= this.props.resolution) {
        warpW = videoWidth % 2 === 0 ? videoWidth : videoWidth + 1;
        warpH = parseInt(warpW / this.props.resolution);
        warpH = warpH % 2 === 0 ? warpH : warpH + 1;
        direction = "vertical";
      } else {
        warpH = videoHeight % 2 === 0 ? videoHeight : videoHeight + 1;
        warpW = parseInt(warpH * this.props.resolution);
        warpW = warpW % 2 === 0 ? warpW : warpW + 1;
        direction = "horizontal";
      }
      let obj = {
        type: "overlay",
        scale: `${warpW}:${warpH}`,
        direction: direction,
        background: this.props.videoBgColor + ".png",
      };
      actions.push(obj);
    }
    if (this.props.videoBgColor.indexOf("blur") !== -1 && this.props.filling === "fit") {
      if (videoWidth / videoHeight >= this.props.resolution) {
        direction = "vertical";
        warpW = videoWidth % 2 === 0 ? videoWidth : videoWidth + 1;
        warpH = parseInt(warpW / this.props.resolution);
        warpH = warpH % 2 === 0 ? warpH : warpH + 1;
      } else {
        direction = "horizontal";
        warpH = videoHeight % 2 === 0 ? videoHeight : videoHeight + 1;
        warpW = parseInt(warpH * this.props.resolution);
        warpW = warpW % 2 === 0 ? warpW : warpW + 1;
      }
      switch (this.props.resolution) {
        case 1.77:
          aspect = "16:9";
          break;
        case 0.56:
          aspect = "9:16";
          break;
        case 1.33:
          aspect = "4:3";
          break;
        case 1.25:
          aspect = "5:4";
          break;
        default:
          aspect = "1:1";
          break;
      }
      let obj = {
        type: "blur",
        direction,
        scale: `${warpW}:${warpH}`,
        aspect,
        intensity: this.props.videoBgColor === "blur1" ? 3 : this.props.videoBgColor === "blur2" ? 6 : 10,
      };
      actions.push(obj);
    }
    if (this.props.filling === "fit" && this.props.videoBgColor.indexOf("blur") === -1 && this.props.videoBgColor.indexOf("bg") === -1) {
      if (videoWidth / videoHeight >= this.props.resolution) {
        warpW = videoWidth % 2 === 0 ? videoWidth : videoWidth + 1;
        warpH = parseInt(warpW / this.props.resolution);
        warpH = warpH % 2 === 0 ? warpH : warpH + 1;
        videoW = warpW;
        videoH = videoW / (videoWidth / videoHeight);
        videoH = videoH % 2 === 0 ? videoH : videoH + 1;
      } else {
        warpH = videoHeight % 2 === 0 ? videoHeight : videoHeight + 1;
        warpW = parseInt(warpH * this.props.resolution);
        warpW = warpW % 2 === 0 ? warpW : warpW + 1;
        videoH = warpH;
        videoW = videoH * (videoWidth / videoHeight);
        videoW = videoW % 2 === 0 ? videoW : videoW + 1;
      }
      padH = parseInt((warpW - videoW) / 2);
      padH = padH % 2 === 0 ? padH : padH + 1;
      padV = parseInt((warpH - videoH) / 2);
      padV = padV % 2 === 0 ? padV : padV + 1;
      let obj = {
        type: "cropblack",
        pad: `${warpW}:${warpH}:${padH}:${padV}`,
        scale: `${videoW}:${videoH}`,
        color: this.props.videoBgColor,
      };
      actions.push(obj);
    }
    if (this.props.filling === "fill") {
      const video = document.querySelectorAll(".videoWrap video")[0];
      if (videoWidth / videoHeight >= this.props.resolution) {
        videoH = videoHeight % 2 === 0 ? videoHeight : videoHeight + 1;
        videoW = parseInt(videoH * this.props.resolution);
        videoW = videoW % 2 === 0 ? videoW : videoW + 1;
        x = parseInt(videoWidth / 2 - videoW / 2 - this.props.dragVideoPosition.x * (videoW / video.clientWidth));
        x = x % 2 === 0 ? x : x + 1;
        y = 0;
      } else {
        videoW = videoWidth % 2 === 0 ? videoWidth : videoWidth + 1;
        videoH = parseInt(videoW / this.props.resolution);
        videoH = videoH % 2 === 0 ? videoH : videoH + 1;
        x = 0;
        y = parseInt(videoHeight / 2 - videoH / 2 - this.props.dragVideoPosition.y * (videoH / video.clientHeight));
        y = y % 2 === 0 ? y : y + 1;
      }
      let obj = {
        type: "crop",
        x: Number(((x / videoWidth) * 100).toFixed(2)),
        y: Number(((y / videoHeight) * 100).toFixed(2)),
        w: Number(((videoW / videoWidth) * 100).toFixed(2)),
        h: Number(((videoH / videoHeight) * 100).toFixed(2)),
      };
      actions.push(obj);
    }
    return actions;
  };

  getResolutionVideo = () => {
    const formData = new FormData();
    const actions = {
      actions: this.getActions(),
    };
    formData.append("video_url", this.props.currFile.videofileurl);
    formData.append("token", this.props.tokenId);
    formData.append("add_action[]", JSON.stringify(actions));
    return new Promise((resolve, reject) => {
      request("resize", {
        method: "post",
        body: formData,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  handleSetColor = value => {
    if (value.hex === "#000") {
      this.props.onVideoBgColorChange("blur1");
    } else {
      this.props.onVideoBgColorChange(value.hex);
      this.props.onUserActionChange(true);
    }
  };

  handleQuickSetColor = color => {
    this.props.onVideoBgColorChange(color);
    this.props.onUserActionChange(true);
  };

  setCurrFile = () => {
    if (this.state.nextCurrFile) {
      this.props.onCurrFileChange(this.state.nextCurrFile);
    }
  };

  preSubmit = () => {
    console.log(this.props.videoBgColor.indexOf("bg"));
    ReactGA.event(
      {
        category: "Resize",
        action: "ResizeSave",
        label:
          this.props.resolution +
          "_" +
          this.props.filling +
          (this.props.videoBgColor.indexOf("bg") !== -1 ? "_pic" : this.props.videoBgColor.indexOf("blur") !== -1 ? "_blur" : "_color"),
      },
      ["OnlineTracker", "Tracker"]
    );
    //函数返回true，则是当前已经超过限制
    let flag = this.props.handleVideoEditorTimes();
    if (!flag) {
      this.props.preSubmit(1, this.handleSubmit);
    }
  };

  componentDidMount() {
    this.props.onResolutionChange(16 / 9);
  }

  render() {
    console.log("ddd", this.props.dragVideoPosition);
    return (
      <div className={"ResizeWork" + (!this.props.currFile ? " disabled" : "")}>
        <ResizeWorkFunction
          currFile={this.props.currFile}
          videoBgColor={this.props.videoBgColor}
          resolution={this.props.resolution}
          onResolutionChange={this.props.onResolutionChange}
          onFillingChange={this.props.onFillingChange}
          filling={this.props.filling}
          handleSetColor={this.handleSetColor}
          handleQuickSetColor={this.handleQuickSetColor}
          preSubmit={this.preSubmit}
        />
        <TipsModal
          showGuidText
          visible={this.state.warningInfo}
          onCancel={() => {
            this.setState({ warningInfo: false });
          }}
          content={this.state.warningInfoText}
          cancelBtnHandle={() => {
            this.setState({ warningInfo: false });
          }}
          hasDownload
        />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  fileListModal: state.common.fileListModal,
  resolution: state.work.resolution,
  videoBgColor: state.work.videoBgColor,
  tokenId: state.common.tokenId,
  startTime: state.work.startTime,
  filling: state.work.filling,
  dragVideoPosition: state.work.dragVideoPosition,
  uploading: state.work.uploading,
  processStep: state.work.processStep,
  processPercent: state.work.processPercent,
  cancelProgress: state.work.cancelProgress,
  submitting: state.work.submitting,
  uploadPercent: state.work.uploadPercent,
});
const mapDispatchToProps = dispatch => ({
  onFilesChange: files => dispatch(actions.onFilesChange(files)),
  onFileListModalChange: fileListModal => dispatch(actions.onFileListModalChange(fileListModal)),
  onResolutionChange: resolution => dispatch(actions.onResolutionChange(resolution)),
  onVideoBgColorChange: videoBgColor => dispatch(actions.onVideoBgColorChange(videoBgColor)),
  onPreUploadFileChange: preUploadFile => dispatch(actions.onPreUploadFileChange(preUploadFile)),
  onProcessPercentChange: processPercent => dispatch(actions.onProcessPercentChange(processPercent)),
  onProcessStepChange: processStep => dispatch(actions.onProcessStepChange(processStep)),
  onStartTimeChange: startTime => dispatch(actions.onStartTimeChange(startTime)),
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
  onUserActionChange: userAction => dispatch(actions.onUserActionChange(userAction)),
  onFillingChange: filling => dispatch(actions.onFillingChange(filling)),
  onSubmittingChange: submitting => dispatch(actions.onSubmittingChange(submitting)),
  onUploadAgainFileChange: uploadAgainFile => dispatch(actions.onUploadAgainFileChange(uploadAgainFile)),
  onUploadPercentChange: uploadPercent => dispatch(actions.onUploadPercentChange(uploadPercent)),
  onWorkChange: work => dispatch(actions.onWorkChange(work)),
  onPrevWorkChange: prevWork => dispatch(actions.onPrevWorkChange(prevWork)),
  onSuccessFileGuidsChange: successFileGuids => dispatch(actions.onSuccessFileGuidsChange(successFileGuids)),
  onPurchaseModalObjChange: purchaseModal => dispatch(actions.onPurchaseModalObjChange(purchaseModal)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(workplaceHOC(ResizeWork));
