import React from "react";
import { AddMusicAddFileRender } from "./children/AddMusicAddFileRender";
import { useDrop } from "react-dnd";

export const AddMusicAddFileFunction = () => {
  const [, drop] = useDrop({
    accept: "FileListItem",
    drop: () => ({ name: "AddMusicAddFile" }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: (item) => {
      if (item.file && !item.file.audiofileurl) {
        return false;
      }
      const myvideo = document.querySelector("#videoRef video");
      if (myvideo) {
        myvideo.pause();
      }
      return true;
    },
  });

  return <AddMusicAddFileRender drop={drop} />;
};
