import React, { Component } from "react";
import { LoopPreviewRender } from "./children/LoopPreviewRender";
import moment from "moment";
import Utils from "@/utils/utils";

export default class SpeedPreviewFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soundSlide: false,
      playing: false,
      currentDuration: "00:00:00", //循环次数处理之后的总时长
      currentTime: "00:00:00",
      currentTimePercent: 0,
      currentVolume: 0,
      miniLengthLimit: false,
      maxLengthLimit: false,
      videoReady: false,
      isTimeRangeVisible: true,
    };
    this.currentLoopTime = 0; // 当前循环次数
  }

  handleSoundClick = () => {
    const myvideo = document.querySelector("#videoRef video");
    this.setState({
      currentVolume: myvideo.volume * 100,
      soundSlide: !this.state.soundSlide,
    });
  };

  handleSoundDrag = value => {
    const myvideo = document.querySelector("#videoRef video");
    myvideo.volume = value / 100;
    return value;
  };

  handlePlayClick = () => {
    const myvideo = document.querySelector("#videoRef video");
    if (this.state.playing) {
      myvideo.pause();
    } else {
      myvideo.play();
      window.requestAnimationFrame(this._getCurrentTime);
    }
    this.setState({ playing: !this.state.playing });
  };

  _getCurrentTime = () => {
    if (!this.state.playing) {
      return false;
    }
    const myvideo = document.querySelector("#videoRef video");
    const duration = this.props.loopTimes !== "gif" ? myvideo.duration * this.props.loopTimes : myvideo.duration;
    const currentTime = myvideo.duration * this.currentLoopTime + myvideo.currentTime;
    this.setState({
      currentTime: moment("00:00:00", "mm:ss:SS")
        .add(currentTime, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1"),
      currentTimePercent: (currentTime / duration) * 100,
    });
    if (currentTime < duration) {
      if (myvideo.currentTime >= myvideo.duration) {
        this.currentLoopTime = this.currentLoopTime + 1;
        myvideo.currentTime = 0;
        myvideo.play();
      }
      window.requestAnimationFrame(this._getCurrentTime);
    } else {
      if (!myvideo.paused) {
        myvideo.pause();
      }
      this.setState({
        playing: false,
        currentTimePercent: 0,
        currentTime: "00:00:00",
      });
      myvideo.currentTime = 0;
      this.currentLoopTime = 0;
    }
  };

  handleCurrentTimeChange = value => {
    const myvideo = document.querySelector("#videoRef video");
    const duration = this.props.loopTimes !== "gif" ? myvideo.duration * this.props.loopTimes : myvideo.duration;
    const currentTime = duration * (value / 100);
    const currentLoopTime = parseInt(currentTime / myvideo.duration);
    myvideo.currentTime = currentTime - currentLoopTime * myvideo.duration;
    this.currentLoopTime = currentLoopTime;
    this.setState({
      currentTime: moment("00:00:00", "mm:ss:SS")
        .add(currentTime, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1"),
      currentTimePercent: value,
    });
  };

  getVideoSize = () => {
    const Preview = document.querySelector(".Preview");
    const video = document.querySelector(".videoWrap video");
    const callback = () =>
      this.setState({ videoReady: true }, () => {
        const controls = document.querySelector(".controls");
        controls.style.width = video.clientWidth + "px";
        this.setState({
          isTimeRangeVisible: video.clientWidth < 360 ? false : true,
        });
      });
    Utils.setVideoContainerSize(Preview, video, callback);
  };

  componentDidMount() {
    this.getVideoSize();
    window.addEventListener("resize", this.getVideoSize);
    if (this.props.currFile) {
      this.setState({
        currentDuration: moment("00:00:00", "mm:ss:SS")
          .add(Utils.getSeconds(this.props.currFile.duration) * 2, "seconds")
          .format("mm:ss:SS")
          .replace(/:([^:]*)$/, ".$1"),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currFile !== this.props.currFile) {
      this.getVideoSize();
      this.setState({
        soundSlide: false,
        playing: false,
        currentTime: "00:00:00",
        currentTimePercent: 0,
        currentVolume: 0,
        miniLengthLimit: false,
        maxLengthLimit: false,
        videoReady: false,
      });
      this.currentLoopTime = 0;
      if (this.props.currFile) {
        this.setState({
          currentDuration: moment("00:00:00", "mm:ss:SS")
            .add(Utils.getSeconds(this.props.currFile.duration) * 2, "seconds")
            .format("mm:ss:SS")
            .replace(/:([^:]*)$/, ".$1"),
        });
      }
    }

    if (prevProps.loopTimes !== this.props.loopTimes && this.props.currFile) {
      if (this.state.playing) {
        this.setState(
          {
            playing: false,
          },
          () => {
            const myVideo = document.querySelector(".videoWrap video");
            myVideo.pause();
            myVideo.currentTime = 0;
            this.currentLoopTime = 0;
          }
        );
      }
      this.setState({
        currentTime: "00:00:00",
        currentTimePercent: 0,
        currentVolume: 0,
        currentDuration:
          this.props.loopTimes !== "gif"
            ? moment("00:00:00", "mm:ss:SS")
                .add(Utils.getSeconds(this.props.currFile.duration) * this.props.loopTimes, "seconds")
                .format("mm:ss:SS")
                .replace(/:([^:]*)$/, ".$1")
            : moment("00:00:00", "mm:ss:SS")
                .add(Utils.getSeconds(this.props.currFile.duration), "seconds")
                .format("mm:ss:SS")
                .replace(/:([^:]*)$/, ".$1"),
      });
      const myvideo = document.querySelector("#videoRef video");
      myvideo.currentTime = 0;
    }
    if (this.props.submitting && !prevProps.submitting) {
      const myvideo = document.querySelector("#videoRef video");
      if (this.state.playing) {
        myvideo.pause();
        this.setState({ playing: false });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.getVideoSize);
  }

  render() {
    // console.log("this.state.currentDuration", this.state.currentTimePercent,this.state.currentTime);
    return (
      <LoopPreviewRender
        handlePlayClick={this.handlePlayClick}
        handleCurrentTimeChange={this.handleCurrentTimeChange}
        handleSoundClick={this.handleSoundClick}
        soundSlide={this.state.soundSlide}
        currentVolume={this.state.currentVolume}
        currentTime={this.state.currentTime}
        currentDuration={this.state.currentDuration}
        playing={this.state.playing}
        handleSoundDrag={this.handleSoundDrag}
        currFile={this.props.currFile}
        currentTimePercent={this.state.currentTimePercent}
        videoReady={this.state.videoReady}
        screenshots={Utils.screenshots}
        isTimeRangeVisible={this.state.isTimeRangeVisible}
      />
    );
  }
}
