import Utils from "@/utils/utils";

const purchaseList = new Map([
  [
    "ar",
    new Map([
      ["free", { price: "0", currency: "$", currencyArea: "USD", percent: 0 }],
      ["monthly", { price: "9.99", originalPrice: "$ 12.99", currency: "$", currencyArea: "USD", percent: 50 }],
      ["yearly", { price: "59.88", originalPrice: "$ 99.99", currency: "$", currencyArea: "USD", percent: 50 }],
    ]),
  ],
  [
    "br",
    new Map([
      ["free", { price: "0", currency: "R$", currencyArea: "BRL", percent: 0 }],
      ["monthly", { price: "49.99", originalPrice: "R$ 79.99", currency: "R$", currencyArea: "BRL", percent: 50 }],
      ["yearly", { price: "299.99", originalPrice: "R$ 499.99", currency: "R$", currencyArea: "BRL", percent: 50 }],
    ]),
  ],
  [
    "cn",
    new Map([
      ["free", { price: "0", currency: "$", currencyArea: "USD", percent: 0 }],
      ["monthly", { price: "9.99", originalPrice: "$ 12.99", currency: "$", currencyArea: "USD", percent: 50 }],
      ["yearly", { price: "59.88", originalPrice: "$ 99.99", currency: "$", currencyArea: "USD", percent: 50 }],
    ]),
  ],
  [
    "de",
    new Map([
      ["free", { price: "0", currency: "€", currencyArea: "EUR", percent: 0 }],
      ["monthly", { price: "9.99", originalPrice: "€ 12.99", currency: "€", currencyArea: "EUR", percent: 50 }],
      ["yearly", { price: "59.88", originalPrice: "€ 99.99", currency: "€", currencyArea: "EUR", percent: 50 }],
    ]),
  ],
  [
    "es",
    new Map([
      ["free", { price: "0", currency: "€", currencyArea: "EUR", percent: 0 }],
      ["monthly", { price: "9.99", originalPrice: "€ 12.99", currency: "€", currencyArea: "EUR", percent: 50 }],
      ["yearly", { price: "59.88", originalPrice: "€ 99.99", currency: "€", currencyArea: "EUR", percent: 50 }],
    ]),
  ],
  [
    "en",
    new Map([
      ["free", { price: "0", currency: "$", currencyArea: "USD", percent: 0 }],
      ["monthly", { price: "9.99", originalPrice: "$ 12.99", currency: "$", currencyArea: "USD", percent: 50 }],
      ["yearly", { price: "59.88", originalPrice: "$ 99.99", currency: "$", currencyArea: "USD", percent: 50 }],
    ]),
  ],
  [
    "fr",
    new Map([
      ["free", { price: "0", currency: "€", currencyArea: "EUR", percent: 0 }],
      ["monthly", { price: "9.99", originalPrice: "€ 12.99", currency: "€", currencyArea: "EUR", percent: 50 }],
      ["yearly", { price: "59.88", originalPrice: "€ 99.99", currency: "€", currencyArea: "EUR", percent: 50 }],
    ]),
  ],
  [
    "id",
    new Map([
      ["free", { price: "0", currency: "$", currencyArea: "USD", percent: 0 }],
      ["monthly", { price: "9.99", originalPrice: "$ 12.99", currency: "$", currencyArea: "USD", percent: 50 }],
      ["yearly", { price: "59.88", originalPrice: "$ 99.99", currency: "$", currencyArea: "USD", percent: 50 }],
    ]),
  ],
  [
    "in",
    new Map([
      ["free", { price: "0", currency: "$", currencyArea: "USD", percent: 0 }],
      ["monthly", { price: "9.99", originalPrice: "$ 12.99", currency: "$", currencyArea: "USD", percent: 50 }],
      ["yearly", { price: "59.88", originalPrice: "$ 99.99", currency: "$", currencyArea: "USD", percent: 50 }],
    ]),
  ],
  [
    "it",
    new Map([
      ["free", { price: "0", currency: "€", currencyArea: "EUR", percent: 0 }],
      ["monthly", { price: "9.99", originalPrice: "€ 12.99", currency: "€", currencyArea: "EUR", percent: 50 }],
      ["yearly", { price: "59.88", originalPrice: "€ 99.99", currency: "€", currencyArea: "EUR", percent: 50 }],
    ]),
  ],
  [
    "jp",
    new Map([
      ["free", { price: "0", currency: "￥", currencyArea: "JPY", percent: 0 }],
      ["monthly", { price: "1,099", originalPrice: "1,499円", currency: "￥", currencyArea: "JPY", percent: 50 }],
      ["yearly", { price: "6,578", originalPrice: "11,537円", currency: "￥", currencyArea: "JPY", percent: 50 }],
    ]),
  ],
  [
    "kr",
    new Map([
      ["free", { price: "0", currency: "₩", currencyArea: "KRW", percent: 0 }],
      ["monthly", { price: "11699", originalPrice: "₩ 15699", currency: "₩", currencyArea: "KRW", percent: 50 }],
      ["yearly", { price: "69999", originalPrice: "₩ 119999", currency: "₩", currencyArea: "KRW", percent: 50 }],
    ]),
  ],
  [
    "nl",
    new Map([
      ["free", { price: "0", currency: "€", currencyArea: "EUR", percent: 0 }],
      ["monthly", { price: "9.99", originalPrice: "€ 12.99", currency: "€", currencyArea: "EUR", percent: 50 }],
      ["yearly", { price: "59.88", originalPrice: "€ 99.99", currency: "€", currencyArea: "EUR", percent: 50 }],
    ]),
  ],
  [
    "ru",
    new Map([
      ["free", { price: "0", currency: "P", currencyArea: "RUB", percent: 0 }],
      ["monthly", { price: "749", originalPrice: "1099P", currency: "P", currencyArea: "RUB", percent: 50 }],
      ["yearly", { price: "4489", originalPrice: "8099P", currency: "P", currencyArea: "RUB", percent: 50 }],
    ]),
  ],
  [
    "tr",
    new Map([
      ["free", { price: "0", currency: "$", currencyArea: "USD", percent: 0 }],
      ["monthly", { price: "9.99", originalPrice: "$ 12.99", currency: "$", currencyArea: "USD", percent: 50 }],
      ["yearly", { price: "59.88", originalPrice: "$ 99.99", currency: "$", currencyArea: "USD", percent: 50 }],
    ]),
  ],
  [
    "tw",
    new Map([
      ["free", { price: "0", currency: "NT$", currencyArea: "TWD", percent: 0 }],
      ["monthly", { price: "290", originalPrice: "NT$459", currency: "NT$", currencyArea: "TWD", percent: 50 }],
      ["yearly", { price: "1690", originalPrice: "NT$2599", currency: "NT$", currencyArea: "TWD", percent: 50 }],
    ]),
  ],
]);

export default purchaseList.get(Utils.getLanguage());
