import React, { Component } from "react";
import { connect } from "react-redux";
import TipsModal from "@/components/common/TipsModal";
import TipsModalSimple from "@/components/common/TipsModalSimple";
import AddFileFunction from "./children/AddFileFunction";
import actions from "@/action";
import { requsetDeleteAnonymousFileList } from "@/api/apisFiles";

class AddFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warningInfo: false,
      warningInfoText: "",
      warningInfoObj: {},
    };
  }

  handleWarningObj = ({ warningInfo, warningInfoText, warningInfoObj }) => {
    this.setState({
      warningInfo,
      warningInfoText,
      warningInfoObj,
    });
  };

  handlePurchaseModalObjChange = purchaseModalObj => {
    this.props.onPurchaseModalObjChange(purchaseModalObj);
  };

  /**
   * 请求删除文件夹
   * @param {Array} jobIds 删除的文件id
   */
  requsetDeleteAnonymousFileList = jobIds => {
    const formData = new FormData();
    formData.append("job_ids", jobIds);
    requsetDeleteAnonymousFileList({ body: formData })
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    return (
      <div className="AddFile">
        <AddFileFunction
          files={this.props.files}
          handleWarningObj={this.handleWarningObj}
          upload={this.props.upload}
          work={this.props.work}
          onCurrFileChange={this.props.onCurrFileChange}
          onFilesChange={this.props.onFilesChange}
          onFileListModalChange={this.props.onFileListModalChange}
          handlePurchaseModalObjChange={this.handlePurchaseModalObjChange}
          requsetDeleteAnonymousFileList={this.requsetDeleteAnonymousFileList}
        />
        {(this.props.work === "subtitle" || this.props.work === "addWatermark") && window.userClient.platform === "mac" ? (
          <TipsModalSimple
            visible={this.state.warningInfo}
            onCancel={() => {
              this.setState({ warningInfo: false });
            }}
            content={this.state.warningInfoText}
            tipsObj={this.state.warningInfoObj}
            cancelBtnHandle={() => {
              this.setState({ warningInfo: false });
            }}
          />
        ) : (
          <TipsModal
            visible={this.state.warningInfo}
            onCancel={() => {
              this.setState({ warningInfo: false });
            }}
            content={this.state.warningInfoText}
            showGuidText
            tipsObj={this.state.warningInfoObj}
            cancelBtnHandle={() => {
              this.setState({ warningInfo: false });
            }}
            hasDownload
            productKey={
              this.props.work === "removeWatermark"
                ? "hitpaw-watermark-remover"
                : window.userClient.platform === "win"
                ? "hitpaw-video-editor"
                : undefined
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  work: state.work.work,
  purchaseModalObj: state.common.purchaseModalObj,
});

const mapDispatchToProps = dispatch => ({
  onPurchaseModalObjChange: purchaseModalObj => dispatch(actions.onPurchaseModalObjChange(purchaseModalObj)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(AddFile);
