export default {
  Back: "돌아가기",
  Cut: "잘라내기",
  Speed: "속도 조정",
  "Crop & Rotate": "자르기 및 회전",
  Resize: "사이즈 조정",
  Extract: "추출",
  "Add Music": "음악 추가",
  Subtitles: "자막 추가",
  Merge: "병합",
  "Watermark Remover": "워터마크 제거",
  "Add Watermark": "워터마크 추가",
  "To GIF": "GIF로 변환",
  Convert: "형식 전환",
  Screenshot: "스크린샷",
  Reverse: "역재생",
  Loop: "반복재생",
};
