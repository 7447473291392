import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "@/action";
import ReversePreviewFunction from "./children/ReversePreviewFunction";
import { useDrop } from "react-dnd";
import { ReactComponent as Close } from "@/assets/icons/close-circle.svg";

const VideoWrap = ({ currFile, onCurrFileChange, reverseObj }) => {
  const [, drop] = useDrop({
    accept: "FileListItem",
    drop: () => ({ name: "SpeedVideoWrap" }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: item => {
      if ((item.file && item.file.audiofileurl) || (item.file && item.file.status === "gif")) {
        return false;
      }
      return true;
    },
  });
  return (
    <div ref={drop} className="videoWrap" id="videoRef">
      <div style={{ width: "fit-content", height: "fit-content", margin: "auto", position: "relative" }}>
        <button className="close" onClick={() => onCurrFileChange(null)}>
          <Close />
        </button>
        <video className="active" crossOrigin="Anonymous" playsInline muted={reverseObj.mute}>
          <source src={currFile.reverseVideourl} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

class ReversePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const myvideo = document.querySelector("#videoRef video");
    myvideo.playbackRate = this.props.reverseObj.speed;
  }

  render() {
    return (
      <div className="SpeedPreview">
        <VideoWrap currFile={this.props.currFile} onCurrFileChange={this.props.onCurrFileChange} reverseObj={this.props.reverseObj} />
        <ReversePreviewFunction
          currFile={this.props.currFile}
          playbackRate={this.props.reverseObj.speed}
          reverseObj={this.props.reverseObj}
          submitting={this.props.submitting}
        />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  work: state.work.work,
  reverseObj: state.work.reverseObj,
  submitting: state.work.submitting,
});

const mapDispatchToProps = dispatch => ({
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(ReversePreview);
