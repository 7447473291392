import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import AddFileByURLFunction from "./children/AddFileByURLFunction";
import { message } from "antd";
import Utils from "@/utils/utils";
import actions from "@/action";
import TipsModal from "@/components/common/TipsModal";
import TipsModalSimple from "@/components/common/TipsModalSimple";
import ReactGA from "react-ga";
import moment from "moment";
import { request } from "@/api/request";
import { api_config } from "@/constants/api";
import Cookies from "js-cookie";
import api_domain_config from "@/constants/common";
import { accountList } from "@/constants/url";

class AddFileByURL extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      fetchUrl: "",
      visible: false,
      uploadXHR: null,
      sampleUrl: "https://api.hitpaw.com/sample/video-1.mp4",
      warningInfo: false,
      warningInfoText: "",
      warningInfoTitle: "",
      warningInfoObj: {},
      fileObj: {},
    };
  }

  parseUrl = async url => {
    if (!url || this.state.visible) {
      return false;
    }
    ReactGA.event(
      {
        category: "Upload",
        action: "URLInput",
        label: this.props.work,
      },
      ["OnlineTracker", "Tracker"]
    );
    let urlResponse;
    try {
      this.setState({ visible: true, fetchUrl: url, clickSample: false });
      urlResponse = await this.getParseUrl(url);
    } catch (error) {
      this.setState({ visible: false });
      if (error !== "cancel") {
        message.warning(this.props.t("Sorry, error occurs when analyzing the URL."));
        ReactGA.event(
          {
            category: "Upload",
            action: "URLFail",
            label: error,
          },
          ["OnlineTracker", "Tracker"]
        );
      }
      return false;
    }
    this.setState({ uploadXHR: null });
    if (urlResponse.code === 200) {
      if (urlResponse.data.result.number) {
        // get videofileurl
        const fileResponseNumber = urlResponse.data.result.number;
        let progressResponse;
        try {
          progressResponse = await Utils.getProgress(fileResponseNumber, true, true);
        } catch (error) {
          if (error !== "cancel") {
            message.warning(this.props.t("Sorry, error occurs when analyzing the URL."));
          }
          this.setState({ visible: false });
          ReactGA.event(
            {
              category: "Upload",
              action: "URLFail",
              label: error,
            },
            ["OnlineTracker", "Tracker"]
          );
          return false;
        }
        const videofileurl = urlResponse.data.response_file[0];
        const duration = moment("00:00:00", "mm:ss:SS")
          .add(parseFloat(progressResponse.info[0].duration), "seconds")
          .format("mm:ss:SS")
          .replace(/:([^:]*)$/, ".$1");
        const fps = progressResponse.info[0].fps;
        const size = parseInt(progressResponse.info[0].size);
        // size > 100MB
        if ((size / 1024 / 1024).toFixed(2) > 100) {
          this.setState({
            warningInfo: true,
            warningInfoText:
              "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please download the video and then use HitPaw Video Editor to proceed.",
            warningInfoObj: {
              type: "urlSize",
              duration: size,
            },
            fileObj: {
              videofileurl: videofileurl,
              name: videofileurl.split("/")[videofileurl.split("/").length - 1],
            },
          });
          ReactGA.event(
            {
              category: "Upload",
              action: "URLSizePopup",
              label: `${(size / 1024 / 1024).toFixed(2)}_${urlResponse.data.response_hostname[0]}`,
            },
            ["OnlineTracker", "Tracker"]
          );
          this.setState({ visible: false });
          return false;
        }
        let wh;
        try {
          wh = progressResponse.info[0].wh.match(/^\d+x\d+/)[0];
        } catch (e) {
          wh = "0x0";
        }
        // get screenshots
        const d = Utils.getSeconds(duration);
        if (d < 1) {
          this.setState({
            warningInfo: true,
            warningInfoText: "The uploaded video is less than 1 second.",
            warningInfoObj: {
              type: "duration",
              duration: d,
            },
          });
          this.setState({ visible: false });
          return false;
        }

        //如果当前工作区是视频倒放功能需要请求倒放接口
        let reverseVideourl;
        if (this.props.work === "reverse") {
          const url = await this.handleReverseVideo(progressResponse.info[0].url);
          if (!url) {
            return false;
          }
          reverseVideourl = url;
        }

        const screenshots = progressResponse.thumbnail[0].img;
        const thumbnail = screenshots[0];
        const files = [...this.props.files];
        const arrItem = {
          name: "HitPawVideo",
          guid: new Date().getTime(),
          duration: duration,
          size: size,
          thumbnail: thumbnail,
          status: "origin",
          videofileurl: videofileurl,
          screenshots: screenshots,
          mark: false,
          wh: wh,
          fps: fps,
          uploadStatus: true,
          reverseVideourl,
          jobId: urlResponse.data.result.number,
        };
        files.unshift(arrItem);
        this.props.onCurrFileChange(arrItem);
        localStorage.setItem("files", JSON.stringify(files));
        this.props.onFilesChange(files);
        this.props.onPreUploadFileChange(null);
        this.props.onProcessPercentChange(0);
        this.setState({ visible: false });
        this.clearUrl();
        ReactGA.event(
          {
            category: "Upload",
            action: "URLSuccess",
            label: `${(size / 1024 / 1024).toFixed(2)}_${urlResponse.data.response_hostname}`,
          },
          ["OnlineTracker", "Tracker"]
        );
        message.destroy();
      }
    } else {
      console.log("urlResponse", urlResponse);
      if (urlResponse.code === 401) {
        Cookies.remove("authorization", { domain: api_domain_config.DOMAIN });
        window.location.replace(`${accountList.get("accountLoginPage")}?redirect=${window.location.href}`);
      }
      if (urlResponse.data && urlResponse.data.downloadVideo) {
        ReactGA.event(
          {
            category: "Upload",
            action: "URLSuccess",
            label: `${(urlResponse.data.downloadVideo.filesize / 1024 / 1024).toFixed(2)}_${urlResponse.data.response_hostname}`,
          },
          ["OnlineTracker", "Tracker"]
        );
        if (this.props.work === "addWatermark" || this.props.work === "subtitle") {
          this.setState({
            warningInfo: true,
            warningInfoText: "Max file size for videos is 100 MB.",
            warningInfoObj: {
              type: "urlSize",
              duration: urlResponse.data.downloadVideo.filesize,
            },
            fileObj: {
              videofileurl: urlResponse.data.downloadVideo.url,
              name: "parseVideoByURL",
              format: urlResponse.data.downloadVideo.ext,
            },
          });
        } else {
          this.setState({
            warningInfo: true,
            warningInfoText:
              "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please download the video and then use HitPaw Video Editor to proceed.",
            warningInfoObj: {
              type: "urlSize",
              duration: urlResponse.data.downloadVideo.filesize,
            },
            fileObj: {
              videofileurl: urlResponse.data.downloadVideo.url,
              name: "parseVideoByURL",
              format: urlResponse.data.downloadVideo.ext,
            },
          });
        }

        this.setState({ visible: false });
        ReactGA.event(
          {
            category: "Upload",
            action: "URLSizePopup",
            label: `${(urlResponse.data.downloadVideo.filesize / 1024 / 1024).toFixed(2)}_${urlResponse.data.response_hostname[0]}`,
          },
          ["OnlineTracker", "Tracker"]
        );
        return false;
      }
      this.setState({ visible: false });
      message.warning(this.props.t("Sorry, error occurs when analyzing the URL."));
      ReactGA.event(
        {
          category: "Upload",
          action: "URLFail",
          label: urlResponse.msg,
        },
        ["OnlineTracker", "Tracker"]
      );
    }
  };

  getParseUrl = url => {
    const formData = new FormData();
    formData.append("token", this.props.tokenId);
    formData.append("url", url);
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${api_config.API_URL}/uploadUrl`, true);
      xhr.setRequestHeader("authorization", this.props.authorization);
      xhr.onerror = () => {
        reject("parseUrl_" + xhr.status);
      };
      xhr.onabort = () => {
        reject("cancel");
      };
      xhr.onload = () => {
        resolve(JSON.parse(xhr.response));
      };
      xhr.send(formData);
      this.setState({ uploadXHR: xhr });
    });
  };

  parseSample = async e => {
    e.stopPropagation();
    ReactGA.event(
      {
        category: "Upload",
        action: "TrySample",
      },
      ["OnlineTracker", "Tracker"]
    );
    this.setState({
      visible: true,
      fetchUrl: this.state.sampleUrl,
      clickSample: true,
    });
    let sampleResponse;
    try {
      sampleResponse = await this.getSample();
    } catch (error) {
      this.setState({ visible: false });
      return false;
    }
    if (sampleResponse.code === 200) {
      const number = sampleResponse.data.result.number;
      let progressResponse;
      try {
        progressResponse = await Utils.getProgress(number, true, true);
      } catch (error) {
        if (error !== "cancel") {
          message.warning(this.props.t("Sorry, error occurs when analyzing the URL."));
        }
        this.setState({ visible: false });
        ReactGA.event(
          {
            category: "Upload",
            action: "URLFail",
            label: error,
          },
          ["OnlineTracker", "Tracker"]
        );
        return false;
      }

      //如果当前工作区是视频倒放功能需要请求倒放接口
      let reverseVideourl;
      if (this.props.work === "reverse") {
        const url = await this.handleReverseVideo(progressResponse.info[0].url);
        if (!url) {
          return false;
        }
        reverseVideourl = url;
      }

      const screenshots = progressResponse.thumbnail[0].img;
      const info = progressResponse.info[0];
      const thumbnail = screenshots[0];
      const files = [...this.props.files];
      const arrItem = {
        name: "HitPaw_sample_video.mp4",
        guid: new Date().getTime(),
        duration: moment("00:00:00", "mm:ss:SS")
          .add(parseFloat(info.duration), "seconds")
          .format("mm:ss:SS")
          .replace(/:([^:]*)$/, ".$1"),
        size: info.size * 1,
        thumbnail: thumbnail,
        status: "origin",
        videofileurl: info.url,
        screenshots: screenshots,
        mark: false,
        wh: info.wh,
        fps: info.fps,
        uploadStatus: true,
        reverseVideourl,
        jobId: sampleResponse.data.result.number,
      };
      files.unshift(arrItem);
      this.props.onCurrFileChange(arrItem);
      localStorage.setItem("files", JSON.stringify(files));
      this.props.onFilesChange(files);
      this.props.onPreUploadFileChange(null);
      this.props.onProcessPercentChange(0);
      this.setState({ visible: false });
      this.clearUrl();
    } else {
      if (sampleResponse.code === 401) {
        Cookies.remove("authorization", { domain: api_domain_config.DOMAIN });
        window.location.replace(`${accountList.get("accountLoginPage")}?redirect=${window.location.href}`);
      }
      this.setState({ visible: false });
      message.warning(this.props.t("Sorry, error occurs when analyzing the URL."));
    }
    console.log(sampleResponse);
  };

  getSample = () => {
    const formData = new FormData();
    formData.append("number", 12);
    formData.append("size", "100x40");
    formData.append("filename", "video-1.mp4");
    formData.append("ext", "jpg");
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${api_config.API_URL}/loadSample`, true);
      xhr.setRequestHeader("authorization", this.props.authorization);
      xhr.onerror = () => {
        reject("loadsample_" + xhr.status);
      };
      xhr.onabort = () => {
        console.log("ggg");
        reject("cancel");
      };
      xhr.onload = () => {
        resolve(JSON.parse(xhr.response));
      };
      xhr.send(formData);
      this.setState({ uploadXHR: xhr });
    });
  };

  handleReverseVideo = async url => {
    let reverseResponse = await this.getReverseVideo(url);
    if (reverseResponse.code === 200) {
      const number = reverseResponse.data.result.number;
      let progressResponse;
      try {
        progressResponse = await Utils.getProgress(number, true, true);
      } catch (error) {
        if (error !== "cancel") {
          message.warning(this.props.t("Sorry, error occurs when analyzing the URL."));
        }
        this.setState({ visible: false });
        // ReactGA.event({
        //   category: "Upload",
        //   action: "URLFail",
        //   label: error,
        // });
        return false;
      }
      return progressResponse.info[0].url;
    } else {
      return false;
    }
  };

  /**
   * 请求倒放视频接口
   * @returns 返回请求后的promise
   */
  getReverseVideo = url => {
    const formData = new FormData();
    formData.append("speed", 1);
    formData.append("mute_type", 0);
    formData.append("file_url", url);
    formData.append("token", this.props.tokenId);
    return new Promise((resolve, reject) => {
      request("reverse", {
        method: "post",
        body: formData,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  clearUrl = () => {
    this.setState({ url: "" });
  };

  onUrlChange = e => {
    const url = e.target.value;
    this.setState({ url });
  };

  onCancel = () => {
    this.setState({ visible: false });
    const xhr = this.state.uploadXHR;
    if (xhr) {
      xhr.abort();
    } else {
      Utils.cancelProgress();
    }
  };

  componentDidMount() {
    this.clearUrl();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currFile !== this.props.currFile) {
      this.clearUrl();
    }
  }

  render() {
    return (
      <div
        className="AddFileByURL"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <AddFileByURLFunction
          parseUrl={this.parseUrl}
          onUrlChange={this.onUrlChange}
          parseSample={this.parseSample}
          visible={this.state.visible}
          fetchUrl={this.state.fetchUrl}
          onCancel={this.onCancel}
          clickSample={this.state.clickSample}
          url={this.state.url}
        />
        {(this.props.work === "subtitle" || this.props.work === "addWatermark") && window.userClient.platform === "win" ? (
          <TipsModalSimple
            visible={this.state.warningInfo}
            onCancel={() => {
              this.setState({ warningInfo: false });
              this.clearUrl();
            }}
            content={this.state.warningInfoText}
            tipsObj={this.state.warningInfoObj}
            cancelBtnHandle={() => {
              this.setState({ warningInfo: false });
              this.clearUrl();
            }}
          />
        ) : (
          <TipsModal
            visible={this.state.warningInfo}
            showGuidText
            onCancel={() => {
              this.setState({ warningInfo: false });
              this.clearUrl();
            }}
            content={this.state.warningInfoText}
            contentLink={this.state.warningInfoLinkText}
            content2={this.state.warningInfoText2}
            tipsObj={this.state.warningInfoObj}
            cancelBtnHandle={() => {
              this.setState({ warningInfo: false });
              this.clearUrl();
            }}
            hasDownload
            learnMoreHidden
            hasFileDownload
            fileObj={this.state.fileObj}
            checked
            productKey={
              this.props.work === "removeWatermark"
                ? "hitpaw-watermark-remover"
                : window.userClient.platform === "win"
                ? "hitpaw-video-editor"
                : undefined
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  work: state.work.work,
  authorization: state.common.authorization,
});

const mapDispatchToProps = dispatch => ({
  onFilesChange: files => dispatch(actions.onFilesChange(files)),
  onProcessPercentChange: processPercent => dispatch(actions.onProcessPercentChange(processPercent)),
  onUploadingChange: uploading => dispatch(actions.onUploadingChange(uploading)),
  onProcessStepChange: processStep => dispatch(actions.onProcessStepChange(processStep)),
  onPreUploadFileChange: preUploadFile => dispatch(actions.onPreUploadFileChange(preUploadFile)),
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(withTranslation(["upload"])(AddFileByURL));
