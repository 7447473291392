import React from "react";
import { useTranslation } from "react-i18next";
// import { Input, Modal } from "antd";
import { Modal } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import "@/assets/upload.scss";

// const { Search } = Input;

const FetchModal = data => {
  const { t } = useTranslation(["upload", "common"]);
  return (
    <Modal
      getContainer={document.querySelector(".Home")}
      centered
      visible={data.visible}
      footer={null}
      width="100%"
      height="100%"
      className="fetchModal"
      keyboard={false}
      closable={false}
    >
      <div className="uploading">
        <span className="timeIcon"></span>
        {data.sample ? (
          <div className="msg">
            <p style={{ fontSize: "16px" }}>{t("Loading")} ...</p>
          </div>
        ) : (
          <div className="msg">
            <p style={{ fontSize: "16px" }}>
              {t("Analyzing")} {data.url}
            </p>
            <div className="close" onClick={data.onCancel} style={{ fontSize: "16px" }}>
              <CloseCircleOutlined /> {t("common:Cancel")}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export const AddFileByURLRender = ({
  // onUrlChange,
  parseSample,
  visible,
  fetchUrl,
  onCancel,
  clickSample,
  // url,
  // handleClickBtnToParseUrl,
  // handleBlurToParseUrl,
  // inputRef,
}) => {
  const { t } = useTranslation(["upload", "common"]);
  return (
    <div>
      {/* <Search
        spellCheck={false}
        ref={inputRef}
        onPressEnter={e => {
          console.log(e.target);
          e.stopPropagation();
          e.target.blur();
        }}
        enterButton={
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 15L14 9L8 3" stroke="currentColor" strokeWidth="1.5" />
            <path d="M13.498 9H0" stroke="currentColor" strokeWidth="1.5" />
          </svg>
        }
        onBlur={handleBlurToParseUrl}
        onSearch={handleClickBtnToParseUrl}
        onChange={onUrlChange}
        value={url}
        placeholder={t("Please paste a URL")}
        prefix={
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(1 3)" stroke="#979797" fill="none" fillRule="evenodd">
              <rect x=".5" y="1" width="21" height="16" rx="4" />
              <path strokeLinejoin="round" d="M9.5 6v6l5-3z" />
            </g>
          </svg>
        }
      /> */}
      <p className="tips" onClick={parseSample}>
        {t("Try a sample")}
      </p>
      <FetchModal visible={visible} url={fetchUrl} onCancel={onCancel} sample={clickSample} />
    </div>
  );
};
