import ReactGA from "react-ga";

/**
 * 上传视频大小导致的购买弹窗展示埋点
 */
const GAPurchaseModalVisibleBySizeLimit = () => {
  ReactGA.event(
    {
      category: "premium_popup",
      action: "premium_popup_20",
    },
    ["OnlineTracker", "Tracker"]
  );
};

/**
 * 处理次数导致的购买弹窗展示埋点
 */
const GAPurchaseModalVisibleByTimesLimit = () => {
  ReactGA.event(
    {
      category: "premium_popup",
      action: "premium_popup_6",
    },
    ["OnlineTracker", "Tracker"]
  );
};

/**
 * 购买弹窗去注册
 */
const GAPurchaseModalSignUpClick = () => {
  ReactGA.event(
    {
      category: "premium_popup",
      action: "premium_popup_login",
    },
    ["OnlineTracker", "Tracker"]
  );
};

/**
 * 购买弹窗去购买
 */
const GAPurchaseModalBuyClick = () => {
  ReactGA.event(
    {
      category: "premium_popup",
      action: "premium_popup_buy",
    },
    ["OnlineTracker", "Tracker"]
  );
};

export { GAPurchaseModalVisibleBySizeLimit, GAPurchaseModalVisibleByTimesLimit, GAPurchaseModalSignUpClick, GAPurchaseModalBuyClick };
