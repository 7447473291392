export default {
  freeTimesLimitTitle: "Ваш пробный период закончился сегодня, пожалуйста, обновите до Pro, чтобы получить доступ ко всем премиум-функциям",
  fileSizeLimitTitle:
    "Размер файла для бесплатных учетных записей не может превышать 20 МБ, пожалуйста, обновите до Pro, чтобы получить доступ ко всем премиум-функциям.",
  total: "Все",
  enterCode: "Ввести код",
  signUpToPurchaseBtn: "Зарегистрироваться для покупки",
  goPurchseBtn: "Перейти к покупке",
  saveTips: "Сэкономить до {{num}}%",
  discount: "Скидка",
  montly: "Ежемесячный",
  yearly: "Ежегодный",
  vipTips1: "Нет ограничений по размеру и количеству видео",
  vipTips2: "Удалить фон фото без ограничений",
  vipTips3: "Повышена скорость обработки видео и загрузки.",
  vipTips4: "Доступ ко всем бесплатным инструментам и премиальным функциям",
  purchaseTips: "Подписка будет автоматически продлена, и вы можете отменить ее в любое время.",
  tips: "Примечание:",
  promoCodeInvalidTips: "Ваш промокод недействителен",
};
