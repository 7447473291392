import { Modal, message } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import PurchaseModalRender from "./children/PurchaseModalRender";
import purchaseList from "@/constants/purchase";
import { accountList } from "@/constants/url";
import actions from "@/action";
import { requsetGetPurchase, requsetGetSubscription } from "@/api/apis";
import { GAPurchaseModalVisibleBySizeLimit, GAPurchaseModalVisibleByTimesLimit } from "@/ga/purchase";
import Utils from "@/utils/utils";

class PurchaseModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currPackageKey: "monthly",
      packageMap: purchaseList,
      signUpUrl: accountList.get("accountLoginPage"),
      enterCodeInputVisible: false,
      visible: false,
      purchaseLoading: false,
    };
    this.timer = null;
  }

  componentDidMount() {
    //埋点
    if (this.props.purchaseModalObj.type === "uploadSizeBiggerThanLimit") {
      GAPurchaseModalVisibleBySizeLimit();
    } else {
      GAPurchaseModalVisibleByTimesLimit();
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  /**
   * 控制当前套餐
   * @param {Boolean} checked 控制当前套餐
   */
  handleEnterCodeInputVisibleChange = enterCodeInputVisible => {
    this.setState({
      enterCodeInputVisible,
    });
  };

  /**
   * 控制当前套餐
   * @param {Boolean} checked 控制当前套餐
   */
  handleCurrPackageKeyChange = checked => {
    let currPackageKey = checked ? "yearly" : "monthly";
    this.setState({
      currPackageKey,
      currPackage: this.state.packageMap.get(currPackageKey),
    });
  };

  /**
   * 轮询订阅信息
   */
  handleListenerSubscription = () => {
    this.timer = setInterval(() => {
      requsetGetSubscription()
        .then(res => {
          if (res.code === 200) {
            if (
              res.data.subscription &&
              res.data.subscription === "Active" &&
              (!this.props.subscription || (this.props.subscription && this.props.subscription.status === "Expired"))
            ) {
              console.log("监听到了用户已经购买");
              this.props.onPurchaseModalObjChange({
                ...this.props.purchaseModalObj,
                visible: false,
              });
            }
            this.props.onSubscriptionChange(res.data.subscription);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }, 12000);
  };

  /**
   * 请求购买链接
   */
  getPurchase = () => {
    this.setState({
      purchaseLoading: true,
    });
    const { currPackageKey } = this.state;
    let site = Utils.getLanguage();
    let plan = currPackageKey === "monthly" ? "month" : "year";

    const formData = new FormData();
    formData.append("plan", plan);
    formData.append("site", site);
    requsetGetPurchase({
      body: formData,
    })
      .then(res => {
        console.log(res);
        if (res.code === 200) {
          //轮询监听用户购买
          this.handleListenerSubscription();
          //购物车地址
          let cartUrl = res.data.cart_url;
          window.open(cartUrl, "_blank");
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          purchaseLoading: false,
        });
      });
  };

  handleSentCode = e => {
    e.stopPropagation();
    message.warn(window.i18n.t("purchase:promoCodeInvalidTips"));
  };

  render() {
    const { purchaseModalObj, authorization } = this.props;
    return (
      <Modal
        className="purchaseModal"
        centered
        visible={purchaseModalObj.visible}
        title={null}
        footer={null}
        getContainer={document.querySelector(".Home")}
        width={548}
        onCancel={() => this.props.onPurchaseModalObjChange({ ...purchaseModalObj, visible: false })}
      >
        <PurchaseModalRender
          currPackageKey={this.state.currPackageKey}
          enterCodeInputVisible={this.state.enterCodeInputVisible}
          currPackage={this.state.currPackage ? this.state.currPackage : this.state.packageMap.get(this.state.currPackageKey)}
          handleCurrPackageKeyChange={this.handleCurrPackageKeyChange}
          handleEnterCodeInputVisibleChange={this.handleEnterCodeInputVisibleChange}
          signUpUrl={this.state.signUpUrl}
          purchaseType={!this.props.profileObj ? "account" : "cartUrl"}
          getPurchase={this.getPurchase}
          purchaseLoading={this.state.purchaseLoading}
          purchaseModalObj={purchaseModalObj}
          authorization={authorization}
          handleSentCode={this.handleSentCode}
        />
      </Modal>
    );
  }
}

const mapStateToPropos = state => ({
  purchaseModalObj: state.common.purchaseModalObj,
  profileObj: state.common.profileObj,
  subscription: state.common.subscription,
  authorization: state.common.authorization,
});
const mapDispatchToProps = dispatch => ({
  onPurchaseModalObjChange: purchaseModalObj => dispatch(actions.onPurchaseModalObjChange(purchaseModalObj)),
  onSubscriptionChange: subscription => dispatch(actions.onSubscriptionChange(subscription)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(PurchaseModal);
