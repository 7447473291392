import React, { Component } from "react";
import { Modal, Button } from "antd";
import ReactGA from "react-ga";
import { ReactComponent as Notice } from "../../assets/icons/notice.svg";
import { ReactComponent as Checked } from "../../assets/icons/checked.svg";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import Utils from "@/utils/utils";

class TipsModalSimple extends Component {
  downloadClient = () => {
    if (this.props.tipsObj) {
      if (this.props.tipsObj.type === "size") {
        ReactGA.event(
          {
            category: "Download",
            action: "SizeDownload",
            label: this.props.tipsObj.size,
          },
          ["OnlineTracker", "Tracker"]
        );
      }
      if (this.props.tipsObj.type === "format") {
        ReactGA.event(
          {
            category: "Download",
            action: "FormatDownload",
            label: this.props.tipsObj.format,
          },
          ["OnlineTracker", "Tracker"]
        );
      }
      if (this.props.tipsObj.type === "duration") {
        ReactGA.event(
          {
            category: "Download",
            action: "DurationDownload",
            label: this.props.tipsObj.duration,
          },
          ["OnlineTracker", "Tracker"]
        );
      }
      if (this.props.tipsObj.type === "urlSize") {
        ReactGA.event(
          {
            category: "Download",
            action: "URLDownload",
          },
          ["OnlineTracker", "Tracker"]
        );
      }
      if (this.props.tipsObj.type === "audioFormat") {
        ReactGA.event(
          {
            category: "Download",
            action: "AudioDownload",
            label: this.props.tipsObj.format,
          },
          ["OnlineTracker", "Tracker"]
        );
      }
    } else {
      ReactGA.event(
        {
          category: "Download",
          action: "UnknownDownload",
          label: "",
        },
        ["OnlineTracker", "Tracker"]
      );
    }
  };

  downloadFile = () => {
    ReactGA.event(
      {
        category: "Download",
        action: "URLVidDownload",
      },
      ["OnlineTracker", "Tracker"]
    );
  };
  render() {
    const { t } = this.props;
    return (
      <Modal
        centered
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        footer={null}
        width="520px"
        height="200px"
        getContainer={document.querySelector(".Home")}
        className="tipsModal"
        style={{ top: "-30%" }}
      >
        <div className="tip-cont">
          <div style={{ display: "flex", alignItems: "center" }}>
            {this.props.checked ? <Checked /> : <Notice />}
            <div style={{ marginLeft: "1rem" }}>
              {this.props.title && <span style={{ display: "block" }}>{t(this.props.title)}</span>}
              {this.props.hasFileDownload ? (
                <span>
                  {t(this.props.content)}
                  <a
                    style={{ textDecoration: "underline" }}
                    href={this.props.fileObj.videofileurl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={this.downloadFile}
                  >
                    {t(this.props.contentLink)}
                  </a>
                  {t(this.props.content2)}
                </span>
              ) : (
                <span>{t(this.props.content)}</span>
              )}
              {this.props.hasDownload && !this.props.learnMoreHidden ? (
                <a
                  href={Utils.getHitPawHomeLink()}
                  style={{ marginLeft: "0.5rem", textDecoration: "underline" }}
                  onClick={this.props.cancelBtnHandle}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("Learn more")}
                </a>
              ) : null}
            </div>
          </div>
          <div style={{ textAlign: "right" }}>
            {this.props.cancelBtnHandle ? (
              <Button
                style={{ width: "120px", marginTop: "20px", fontSize: "14px" }}
                type="primary"
                ghost
                onClick={this.props.cancelBtnHandle}
                size="large"
              >
                {t("Cancel")}
              </Button>
            ) : null}
            {this.props.customBtns
              ? this.props.customBtns.map((item, index) => (
                  <Button
                    type={item.btnType ? item.btnType : "primary"}
                    size="large"
                    onClick={item.clickFunc}
                    style={item.style ? item.style : {}}
                    ghost={item.ghost}
                    key={index}
                  >
                    {t(item.text)}
                  </Button>
                ))
              : null}
            {this.props.confirmBtnHandle ? (
              <Button
                style={{
                  minWidth: "120px",
                  marginTop: "20px",
                  marginLeft: "20px",
                  fontSize: "14px",
                }}
                type="primary"
                onClick={this.props.confirmBtnHandle}
                size="large"
              >
                {t(this.props.confirmBtnText)}
              </Button>
            ) : null}
            {this.props.hasDownload ? (
              window.userClient.platform === "win" ? (
                <Button
                  style={
                    this.props.hasFileDownload
                      ? {
                          minWidth: "160px",
                          marginTop: "20px",
                          marginLeft: "20px",
                          fontSize: "14px",
                        }
                      : {
                          minWidth: "120px",
                          marginTop: "20px",
                          marginLeft: "20px",
                        }
                  }
                  type="primary"
                  href={Utils.getHitPawToolKitEXELink()}
                  onClick={this.downloadClient}
                  target="_blank"
                  size="large"
                >
                  {this.props.hasFileDownload ? (
                    <span>
                      <VerticalAlignBottomOutlined style={{ marginRight: ".2rem" }} />
                      HitPaw Toolkit
                    </span>
                  ) : (
                    t("Download")
                  )}
                </Button>
              ) : (
                <Button
                  style={{
                    minWidth: "120px",
                    marginTop: "20px",
                    marginLeft: "20px",
                    fontSize: "14px",
                  }}
                  type="primary"
                  href="https://www.hitpaw.com/"
                  target="_blank"
                  size="large"
                >
                  {t("Coming Soon")}
                </Button>
              )
            ) : null}
          </div>
        </div>
      </Modal>
    );
  }
}
export default withTranslation(["common"])(TipsModalSimple);
