import React, { Component } from "react";
import moment from "moment";
import WaveSurfer from "wavesurfer.js";
import { AddMusicTrimAudioRender } from "./children/AddMusicTrimAudioModalRender";
import Utils from "@/utils/utils";
import "@/assets/addMusicTrimAudioModal.scss";

export default class AddMusicTrimAudioFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
      currentTime: "00:00.00",
      currentTimePercent: 0,
      currentVolume: 0,
      cacheCutTime: [],
      miniLengthLimit: false,
      maxLengthLimit: false,
      videoReady: false,
      startTime: "00:00.00",
      endTime: "00:00.00",
      getWaveSuccess: null,
    };
    this.waveformRef = React.createRef();
    this.wavesurfer = null;
  }

  handleCurrentTimeChange = value => {
    const myaudio = document.querySelector("#audio");
    myaudio.currentTime = myaudio.duration * (value / 100);
    this.setState({
      currentTime: moment("00:00:00", "mm:ss:SS")
        .add(myaudio.currentTime, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1"),
      currentTimePercent: value,
    });
  };

  handleRangeChange = value => {
    const myaudio = document.querySelector("#audio");
    if (this.state.playing) {
      this.handlePlayClick();
    }
    const d = Utils.getSeconds(this.props.addMusicWorkObj.duration);
    if ((d * value[1]) / 100 - (d * value[0]) / 100 <= 1) {
      this.setState({ miniLengthLimit: true });
      return false;
    }
    if ((d * value[1]) / 100 - (d * value[0]) / 100 > 51 && this.props.work === "gif") {
      this.setState({ maxLengthLimit: true });
      return false;
    }
    const startTime = moment("00:00:00", "mm:ss:SS")
      .add((d * value[0]) / 100, "seconds")
      .format("mm:ss:SS")
      .replace(/:([^:]*)$/, ".$1");
    const endTime = moment("00:00:00", "mm:ss:SS")
      .add((d * value[1]) / 100, "seconds")
      .format("mm:ss:SS")
      .replace(/:([^:]*)$/, ".$1");
    if (this.state.startTime === startTime && this.state.endTime !== endTime) {
      myaudio.currentTime = Utils.getSeconds(endTime);
      this.setState({
        currentTime: endTime,
        currentTimePercent: this.getAudioPercent(endTime),
      });
    } else if (this.state.endTime === endTime && this.state.startTime !== startTime) {
      myaudio.currentTime = Utils.getSeconds(startTime);
      this.setState({
        currentTime: startTime,
        currentTimePercent: this.getAudioPercent(startTime),
      });
    }
    this.setState({
      miniLengthLimit: false,
      maxLengthLimit: false,
      startTime,
      endTime,
    });
  };

  getAudioPercent = value => {
    const d = Utils.getSeconds(this.props.addMusicWorkObj.duration);
    const t = Utils.getSeconds(value);
    return (t / d) * 100;
  };

  handlePlayClick = () => {
    const myaudio = document.querySelector("#audio");
    if (this.state.playing) {
      myaudio.pause();
    } else {
      // 保证指针在选框范围内
      if (myaudio.currentTime < Utils.getSeconds(this.state.startTime) || myaudio.currentTime.toFixed(2) >= Utils.getSeconds(this.state.endTime)) {
        myaudio.currentTime = Utils.getSeconds(this.state.startTime);
      }
      myaudio.play();
      window.requestAnimationFrame(this._getCurrentTime);
    }
    this.setState({ playing: !this.state.playing });
  };

  _getCurrentTime = () => {
    const myaudio = document.querySelector("#audio");
    if (!this.state.playing || !myaudio) {
      return false;
    }
    this.setState({
      currentTime: moment("00:00:00", "mm:ss:SS")
        .add(myaudio.currentTime, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1"),
      currentTimePercent: (myaudio.currentTime / myaudio.duration) * 100,
    });
    let endTime = Utils.getSeconds(this.state.endTime);
    if (endTime > myaudio.duration) {
      endTime = myaudio.duration;
    }
    if (myaudio.currentTime < endTime) {
      window.requestAnimationFrame(this._getCurrentTime);
    } else {
      this.setState({
        playing: false,
        currentTimePercent: this.getAudioPercent(this.state.startTime),
        currentTime: moment(this.state.startTime.replace(".", ":"), "mm:ss:SS")
          .format("mm:ss:SS")
          .replace(/:([^:]*)$/, ".$1"),
      });
      myaudio.currentTime = Utils.getSeconds(this.state.startTime);
      myaudio.pause();
    }
  };

  getWave = () => {
    this.wavesurfer = WaveSurfer.create({
      container: this.waveformRef.current,
      waveColor: "#8372FF",
      progressColor: "#8372FF",
      height: 40,
      cursorColor: "transparent",
    });
    this.wavesurfer.load(this.props.addMusicWorkObj.audiofileurl);
    this.setState({ getWaveSuccess: true });
  };

  componentDidMount() {
    if (this.waveformRef.current && !this.state.getWaveSuccess && this.props.addMusicWorkObj.audiofileurl) {
      this.getWave();
    }
    if (this.props.addMusicAudioTimeObj.startTime) {
      this.setState({ startTime: this.props.addMusicAudioTimeObj.startTime });
    }
    if (this.props.addMusicAudioTimeObj.endTime) {
      this.setState({ endTime: this.props.addMusicAudioTimeObj.endTime });
    } else {
      if (
        this.props.addMusicWorkObj.duration &&
        Utils.getSeconds(this.props.addMusicWorkObj.duration) < Utils.getSeconds(this.props.currFile.duration)
      ) {
        this.setState({ endTime: this.props.addMusicWorkObj.duration });
      } else if (
        this.props.addMusicWorkObj.duration &&
        Utils.getSeconds(this.props.addMusicWorkObj.duration) > Utils.getSeconds(this.props.currFile.duration)
      ) {
        this.setState({ endTime: this.props.currFile.duration });
      }
    }
  }

  componentDidUpdate() {
    if (this.waveformRef.current && !this.state.getWaveSuccess && this.props.addMusicWorkObj.audiofileurl) {
      this.getWave();
    }
  }

  componentWillUnmount() {
    if (this.wavesurfer) {
      this.wavesurfer.destroy();
    }
  }

  render() {
    return (
      <AddMusicTrimAudioRender
        addMusicWorkObj={this.props.addMusicWorkObj}
        onCancel={this.props.onCancel}
        confirmBtnHandle={this.props.confirmBtnHandle}
        waveformRef={this.waveformRef}
        currentTimePercent={this.state.currentTimePercent}
        handleCurrentTimeChange={this.handleCurrentTimeChange}
        startTime={this.state.startTime}
        endTime={this.state.endTime}
        getAudioPercent={this.getAudioPercent}
        miniLengthLimit={this.state.miniLengthLimit}
        maxLengthLimit={this.state.maxLengthLimit}
        handleRangeChange={this.handleRangeChange}
        playing={this.state.playing}
        handlePlayClick={this.handlePlayClick}
        currentTime={this.state.currentTime}
      />
    );
  }
}
