import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import WebFont from "webfontloader";
import * as serviceWorker from "./serviceWorker";
import ReactGA from "react-ga";
import Utils from "./utils/utils";
import Storage from "./utils/storage";
import "./index.css";
import "./i18n";
console.log(Utils);
// ReactGA.initialize("UA-177200317-10");
ReactGA.initialize([
  { trackingId: "UA-216947947-1", gaOptions: { name: "OnlineTracker" } },
  { trackingId: "UA-177200317-10", gaOptions: { name: "Tracker" } },
]);
ReactGA.pageview(window.location.pathname);

let urlParams = new URLSearchParams(window.location.search);
if (urlParams.has("work")) {
  ReactGA.set(
    {
      dimension1: "1.7.0",
      dimension3: "record",
      dimension4: Utils.getLanguage(),
    },
    ["OnlineTracker", "Tracker"]
  );
} else {
  ReactGA.set(
    {
      dimension1: "1.7.0",
      dimension3: "none",
      dimension4: Utils.getLanguage(),
    },
    ["OnlineTracker", "Tracker"]
  );
}

//使用自定义本地存储类
window.Storage = new Storage();

window.onbeforeunload = function () {
  return "close";
};

WebFont.load({
  google: {
    families: ["Mulish: 200,300,400,500,600,700,800,900"],
  },
});

// userClient
const userClient = {
  agent: navigator.userAgent.toLowerCase(),
  platform: navigator.platform.match(/mac/i)
    ? "mac"
    : navigator.platform.match(/win/i)
    ? "win"
    : navigator.platform.match(/ip/i)
    ? "ios"
    : navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("Adr") > -1
    ? "android"
    : navigator.platform.match(/linux/i)
    ? "linux"
    : "other",
  browser: navigator.userAgent.match(/edge/i)
    ? "edge"
    : navigator.userAgent.match(/firefox/i)
    ? "firefox"
    : navigator.userAgent.match(/chrome/i)
    ? "chrome"
    : navigator.userAgent.match(/safari/i)
    ? "safari"
    : navigator.userAgent.match(/trident/i)
    ? "ie"
    : "other",
  device: "desktop",
};
userClient.device = userClient.platform === "ios" || userClient.platform === "android" ? "mobile" : "desktop";
window.userClient = userClient;
document.body.setAttribute("data-sys", userClient.platform);
document.body.setAttribute("data-dev", userClient.device);

//设置阿语反向布局
let lang = "";
if (window.location.pathname.match(/\/ar\//) || window.location.search.includes("lang=ar") || window.location.hostname.match(/ar\./)) {
  document.body.setAttribute("data-lang", "ar");
  lang = "ar";
}

//测试环境下可以根据链接 www.xxxxx.com?theme=light || www.xxxxx.com?theme=dark 切换主题
if (process.env.NODE_ENV === "development" || process.env.REACT_APP_BUILD_ENV === "development") {
  let urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("theme")) {
    document.documentElement.setAttribute("data-theme", urlParams.get("theme"));
  }
}

ReactDOM.render(
  <Provider store={store}>
    <App lang={lang} />
  </Provider>,
  document.getElementById("hitpaw-root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
