export default {
  fileSizeLimitTitle:
    "O tamanho do arquivo para conta gratuita não pode exceder 20MB, por favor, atualize para Pro para acessar todos os recursos premium",
  freeTimesLimitTitle: "Seu teste acabou hoje, por favor, atualize para Pro para acessar todos os recursos premium",
  total: "Total",
  enterCode: "Digitar o código",
  signUpToPurchaseBtn: "Registrar-se para comprar",
  goPurchseBtn: "Ir para comprar",
  saveTips: "Economizar até {{num}}%.",
  discount: "Desconto",
  montly: "Mensal",
  yearly: "Anual",
  vipTips1: "Sem limites de tamanho e quantidade de vídeo",
  vipTips2: "Remover fundo de imagem sem limites",
  vipTips3: "Velocidade de processamento e de baixar mais rápida",
  vipTips4: "Acesso a todas as ferramentas gratuitas e recursos especiais",
  purchaseTips: "Renovar automaticamente e cancelar a qualquer momento.",
  tips: "Dicas:",
  promoCodeInvalidTips: "Seu código de promoção é inválido",
};
