import React, { Component } from "react";
import { CropRotatePreviewRender } from "./children/CropRotatePreviewRender";
import moment from "moment";
import Utils from "@/utils/utils";

export default class CropRotatePreviewFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soundSlide: false,
      playing: false,
      currentTime: "00:00:00",
      currentTimePercent: 0,
      currentVolume: 0,
      miniLengthLimit: false,
      maxLengthLimit: false,
    };
  }

  handleSoundClick = () => {
    const myvideo = document.querySelector("#videoRef video");
    this.setState({
      currentVolume: myvideo.volume * 100,
      soundSlide: !this.state.soundSlide,
    });
  };

  handleSoundDrag = value => {
    const myvideo = document.querySelector("#videoRef video");
    myvideo.volume = value / 100;
    return value;
  };

  handlePlayClick = () => {
    const myvideo = document.querySelector("#videoRef video");
    if (this.state.playing) {
      myvideo.pause();
    } else {
      myvideo.play();
      window.requestAnimationFrame(this._getCurrentTime);
    }
    this.setState({ playing: !this.state.playing });
  };

  _getCurrentTime = () => {
    if (!this.state.playing) {
      return false;
    }
    const myvideo = document.querySelector("#videoRef video");
    this.setState({
      currentTime: moment("00:00:00", "mm:ss:SS")
        .add(myvideo.currentTime, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1"),
      currentTimePercent: (myvideo.currentTime / myvideo.duration) * 100,
    });
    if (myvideo.currentTime < myvideo.duration) {
      window.requestAnimationFrame(this._getCurrentTime);
    } else {
      if (!myvideo.paused) {
        myvideo.pause();
      }
      this.setState({
        playing: false,
        currentTimePercent: 0,
        currentTime: "00:00:00",
      });
      myvideo.currentTime = 0;
    }
  };

  handleCurrentTimeChange = value => {
    const myvideo = document.querySelector("#videoRef video");
    myvideo.currentTime = myvideo.duration * (value / 100);
    this.setState({
      currentTime: moment("00:00:00", "mm:ss:SS")
        .add(myvideo.currentTime, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1"),
      currentTimePercent: value,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.currFile !== this.props.currFile) {
      this.setState({
        soundSlide: false,
        playing: false,
        currentTime: "00:00:00",
        currentTimePercent: 0,
        currentVolume: 0,
        miniLengthLimit: false,
        maxLengthLimit: false,
      });
    }

    if (this.props.submitting && !prevProps.submitting) {
      const myvideo = document.querySelector("#videoRef video");
      if (this.state.playing) {
        myvideo.pause();
        this.setState({ playing: false });
      }
    }
  }

  render() {
    return (
      <CropRotatePreviewRender
        currFile={this.props.currFile}
        currentVolume={this.state.currentVolume}
        currentTime={this.state.currentTime}
        currentTimePercent={this.state.currentTimePercent}
        videoReady={this.props.videoReady}
        playing={this.state.playing}
        soundSlide={this.state.soundSlide}
        handleSoundClick={this.handleSoundClick}
        handleSoundDrag={this.handleSoundDrag}
        handlePlayClick={this.handlePlayClick}
        handleCurrentTimeChange={this.handleCurrentTimeChange}
        screenshots={Utils.screenshots}
        isTimeRangeVisible={this.props.isTimeRangeVisible}
      />
    );
  }
}
