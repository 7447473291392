import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "@/action";
import { useDrop } from "react-dnd";
import MergePreviewFunction from "./children/MergePreviewFunction";

const VideoWrap = ({ currFile, videoBgColor }) => {
  const [, drop] = useDrop({
    accept: "FileListItem",
    drop: () => ({ name: "MergeVideoWrap" }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: item => {
      if ((item.file && item.file.audiofileurl) || (item.file && item.file.status === "gif")) {
        return false;
      }
      return true;
    },
  });
  return (
    <div ref={drop} className="videoWrap" id="videoRef">
      <div
        className="videoWrap-box"
        style={{
          overflow: "hidden",
          margin: "0 auto",
          position: "relative",
          width: "100%",
          height: "100%",
          backgroundColor: videoBgColor === "blur" ? null : videoBgColor,
        }}
      >
        <div className="mergeVideoWrap active">
          <video crossOrigin="Anonymous">
            <source src={currFile.videofileurl} type="video/mp4" />
          </video>
          <video
            muted
            style={{
              zIndex: 1,
              filter: "blur(6px)",
              opacity: videoBgColor === "blur" ? 1 : 0,
            }}
          >
            <source src={currFile.videofileurl} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

class MergePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="MergePreview">
        <VideoWrap currFile={this.props.currFile} videoBgColor={this.props.videoBgColor} />
        <MergePreviewFunction
          mergeWorkList={this.props.mergeWorkList}
          currFile={this.props.currFile}
          resolution={this.props.resolution}
          onCurrFileChange={this.props.onCurrFileChange}
          submitting={this.props.submitting}
        />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  work: state.work.work,
  resolution: state.work.resolution,
  videoBgColor: state.work.videoBgColor,
  mergeWorkList: state.work.mergeWorkList,
  submitting: state.work.submitting,
});

const mapDispatchToProps = dispatch => ({
  onFilesChange: files => dispatch(actions.onFilesChange(files)),
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(MergePreview);
