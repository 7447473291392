import React, { Component } from "react";
import { VideoPreviewRender } from "./children/VideoPreviewRender";
import moment from "moment";
import Utils from "@/utils/utils";

export default class VideoPreviewFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soundSlide: false,
      playing: false,
      currentTime: "00:00:00",
      currentTimePercent: 0,
      currentVolume: 0,
      miniLengthLimit: false,
      maxLengthLimit: false,
      videoReady: false,
      isTimeRangeVisible: true,
    };
  }

  handleSoundClick = () => {
    const myvideo = document.querySelector("#videoRef video");
    this.setState({
      currentVolume: myvideo.volume * 100,
      soundSlide: !this.state.soundSlide,
    });
  };

  handleSoundDrag = value => {
    const myvideo = document.querySelector("#videoRef video");
    myvideo.volume = value / 100;
    return value;
  };

  handlePlayClick = () => {
    const myvideo = document.querySelector("#videoRef video");
    if (this.state.playing) {
      myvideo.pause();
    } else {
      myvideo.play();
      window.requestAnimationFrame(this._getCurrentTime);
    }
    this.setState({ playing: !this.state.playing });
  };

  _getCurrentTime = () => {
    if (!this.state.playing) {
      return false;
    }
    const myvideo = document.querySelector("#videoRef video");
    this.setState({
      currentTime: moment("00:00:00", "mm:ss:SS")
        .add(myvideo.currentTime, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1"),
      currentTimePercent: (myvideo.currentTime / myvideo.duration) * 100,
    });
    if (myvideo.currentTime < myvideo.duration) {
      window.requestAnimationFrame(this._getCurrentTime);
    } else {
      if (!myvideo.paused) {
        myvideo.pause();
      }
      this.setState({
        playing: false,
        currentTimePercent: 0,
        currentTime: "00:00:00",
      });
      myvideo.currentTime = 0;
    }
  };

  handleCurrentTimeChange = value => {
    const myvideo = document.querySelector("#videoRef video");
    myvideo.currentTime = myvideo.duration * (value / 100);
    this.setState({
      currentTime: moment("00:00:00", "mm:ss:SS")
        .add(myvideo.currentTime, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1"),
      currentTimePercent: value,
    });
  };

  getVideoSize = () => {
    const Preview = document.querySelector(".Preview");
    const video = document.querySelector(".videoWrap video");
    const callback = () =>
      this.setState({ videoReady: true }, () => {
        const controls = document.querySelector(".controls");
        controls.style.width = video.clientWidth + "px";
        this.setState({
          isTimeRangeVisible: video.clientWidth < 360 ? false : true,
        });
      });
    Utils.setVideoContainerSize(Preview, video, callback, null, window.innerWidth >= 992 ? 300 : 0);
  };

  componentDidMount() {
    this.getVideoSize();
    window.addEventListener("resize", this.getVideoSize);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currFile !== this.props.currFile) {
      this.getVideoSize();
      this.setState({
        soundSlide: false,
        playing: false,
        currentTime: "00:00:00",
        currentTimePercent: 0,
        currentVolume: 0,
        miniLengthLimit: false,
        maxLengthLimit: false,
        videoReady: false,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.getVideoSize);
  }

  render() {
    return (
      <VideoPreviewRender
        handlePlayClick={this.handlePlayClick}
        handleCurrentTimeChange={this.handleCurrentTimeChange}
        handleSoundClick={this.handleSoundClick}
        soundSlide={this.state.soundSlide}
        currentVolume={this.state.currentVolume}
        currentTime={this.state.currentTime}
        playing={this.state.playing}
        handleSoundDrag={this.handleSoundDrag}
        currFile={this.props.currFile}
        currentTimePercent={this.state.currentTimePercent}
        videoReady={this.state.videoReady}
        screenshots={Utils.screenshots}
        isTimeRangeVisible={this.state.isTimeRangeVisible}
        successFiles={this.props.successFiles}
      />
    );
  }
}
