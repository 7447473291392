export default {
  'Files': 'File',
  'Refresh': 'Refresh',
  'Original file': 'File asli',
  'Just now': 'Baru saja',
  'hour ago': '{{num}} jam yang lalu',
  'hours ago': '{{num}} jam yang lalu',
  'minute ago': '{{num}} menit yang lalu',
  'minutes ago': '{{num}} menit yang lalu',
  'day ago': '{{num}} 天前',
  'View All': 'Lihat Semua',
  'No files': 'Tidak ada file...',
  "Don't forget to download your file, it will be deleted after 24 hours": 'Jangan lupa untuk mengunduh file Anda, file akan dihapus setelah 24 jam.',
  'Select All': 'Pilih Semua',
  'Delete': 'Hapus',
  'Generate Files Successfully!': 'Berhasil Membuat File!',
  'Continue Editing': 'Lanjutkan Mengedit',
  'Edit New Video': 'Edit Video Baru',
  'Download': 'Unduh',
  'Click here to open the Files again':'Klik di sini untuk membuka File lagi.',
  'Processing... Please wait': 'Memproses... Silahkan tunggu',
  'Uploading... Please wait': 'Mengunggah… Silahkan tunggu',
  'Edit Now': 'Edit Sekarang',
  'Back to Edit':'Kembali ke Edit',
  'Go Edit':'Sunting',
}