import React, { Component } from "react";
import { connect } from "react-redux";
import { workplaceHOC } from "../WorkplaceHOC";
import { request } from "@/api/request";
import LoopWorkFunction from "./children/LoopWorkFunction";
import actions from "@/action";
import Utils from "@/utils/utils";
import ReactGA from "react-ga";
import "@/assets/loopWork.scss";

class LoopWork extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * 改变视频循环次数
   * @param {*} loopTimes 循环次数
   */
  handleLoopTimes = loopTimes => {
    this.props.onLoopTimesChange(loopTimes);
  };

  handleSubmit = async () => {
    const startTime = { ...this.props.startTime };
    startTime.loop = new Date();
    this.props.onStartTimeChange(startTime);
    if (!this.props.uploading && this.props.uploadPercent < 100) {
      this.props.onProcessPercentChange(1);
      this.props.onProcessStepChange(1);
    }
    let loopResponse;
    try {
      if (this.props.loopTimes === "gif") {
        loopResponse = await this.getGif();
      } else {
        loopResponse = await this.getLoopVideo();
      }
    } catch (error) {
      this.props.handleSubmitFailModal(true);
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "Video_Loop",
          action: "Loop_fail",
          label: error + "_none_none_" + this.props.tokenId + "_none_none",
        },
        ["OnlineTracker", "Tracker"]
      );
      return false;
    }
    if (loopResponse.code === 200) {
      let guids = [];
      try {
        guids = await this.props.getNewVideoByProgress("loop", loopResponse);
      } catch (error) {
        if (error !== "cancel") {
          ReactGA.event(
            {
              category: "Video_Loop",
              action: "Loop_fail",
              label: "loopVideo_200_200_" + error,
            },
            ["OnlineTracker", "Tracker"]
          );
        }
        return false;
      }
      this.props.onSuccessFileGuidsChange(guids);
      this.props.onPrevWorkChange("loop");
      this.props.onUploadPercentChange(0);
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "Video_Loop",
          action: "Loop_success",
        },
        ["OnlineTracker", "Tracker"]
      );
    } else {
      if (loopResponse.code === 402102) {
        this.props.onPurchaseModalObjChange({
          visible: true,
          type: "videoEditorTimesMoreThanLimit",
        });
      } else {
        this.props.handleSubmitFailModal(true);
      }
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "Video_Loop",
          action: "Loop_fail",
          label: "loopVideo_200_" + loopResponse.code + "_none_" + this.props.tokenId + "_none_" + loopResponse.msg,
        },
        ["OnlineTracker", "Tracker"]
      );
    }
  };

  preSubmit = () => {
    ReactGA.event(
      {
        category: "Video_Loop",
        action: "Loop_save",
        label: this.props.loopTimes,
      },
      ["OnlineTracker", "Tracker"]
    );
    //函数返回true，则是当前已经超过限制
    let flag = this.props.handleVideoEditorTimes();
    if (!flag) {
      this.props.preSubmit(1, this.handleSubmit);
    }
  };

  /**
   * 请求视频转gif
   * @returns 返回请求后的promise
   */
  getGif = () => {
    const formData = new FormData();
    const currFile = this.props.currFile;
    const fps = parseFloat(currFile.fps);
    formData.append("ext", "gif");
    formData.append("start_time", "00:00.00");
    formData.append("continued_time", Utils.getSeconds(currFile.duration));
    formData.append("video_url", currFile.videofileurl);
    formData.append("resolution", `${currFile.wh.substring(0, currFile.wh.indexOf("x"))}:-1`);
    formData.append("number_of_frames", fps > 10 ? 10 : fps);
    formData.append("token", this.props.tokenId);
    return new Promise((resolve, reject) => {
      request("convertGif", {
        method: "post",
        body: formData,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  /**
   * 请求循环视频接口
   * @returns 返回请求后的promise
   */
  getLoopVideo = () => {
    const formData = new FormData();
    formData.append("loop_num", this.props.loopTimes - 1);
    formData.append("file_url", this.props.currFile.videofileurl);
    formData.append("token", this.props.tokenId);
    return new Promise((resolve, reject) => {
      request("loopVideo", {
        method: "post",
        body: formData,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  componentDidMount() {
    //重置设置项
    this.props.onLoopTimesChange(2);
  }

  componentDidUpdate(prevProps) {
    //重置设置项
    if (this.props.currFile !== prevProps.currFile || this.props.work !== prevProps.work) {
      this.props.onLoopTimesChange(2);
    }
  }

  render() {
    return (
      <div style={{ height: "100%" }}>
        <LoopWorkFunction
          preSubmit={this.preSubmit}
          currFile={this.props.currFile}
          loopTimes={this.props.loopTimes}
          submitting={this.props.submitting}
          fileListModal={this.props.fileListModal}
          handleLoopTimes={this.handleLoopTimes}
        />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  tokenId: state.common.tokenId,
  work: state.work.work,
  startTime: state.work.startTime,
  loopTimes: state.work.loopTimes,
  uploading: state.work.uploading,
  processStep: state.work.processStep,
  processPercent: state.work.processPercent,
  cancelProgress: state.work.cancelProgress,
  submitting: state.work.submitting,
  uploadPercent: state.work.uploadPercent,
  fileListModal: state.common.fileListModal,
});
const mapDispatchToProps = dispatch => ({
  onFilesChange: files => dispatch(actions.onFilesChange(files)),
  onFileListModalChange: fileListModal => dispatch(actions.onFileListModalChange(fileListModal)),
  onProcessPercentChange: processPercent => dispatch(actions.onProcessPercentChange(processPercent)),
  onProcessStepChange: processStep => dispatch(actions.onProcessStepChange(processStep)),
  onStartTimeChange: startTime => dispatch(actions.onStartTimeChange(startTime)),
  onUserActionChange: userAction => dispatch(actions.onUserActionChange(userAction)),
  onUploadPercentChange: uploadPercent => dispatch(actions.onUploadPercentChange(uploadPercent)),
  onSubmittingChange: submitting => dispatch(actions.onSubmittingChange(submitting)),
  onWorkChange: work => dispatch(actions.onWorkChange(work)),
  onPrevWorkChange: prevWork => dispatch(actions.onPrevWorkChange(prevWork)),
  onSuccessFileGuidsChange: successFileGuids => dispatch(actions.onSuccessFileGuidsChange(successFileGuids)),
  onLoopTimesChange: loopTimes => dispatch(actions.onLoopTimesChange(loopTimes)),
  onPurchaseModalObjChange: purchaseModal => dispatch(actions.onPurchaseModalObjChange(purchaseModal)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(workplaceHOC(LoopWork));
