export default {
  Start: "शुरू करें",
  End: "समाप्त करें",
  Save: "सहेजें",
  Cancel: "रद्द करें",
  Download: "डाउनलोड करें",
  downloading: "डाउनलोड हो रहा है",
  "Drag and drop from Files or local disk": "फाइल या लोकल डिस्क से खींचकर छोड़ें",
  "Click to Add File": "फाइल जोड़ने के लिए क्लिक करें",
  "The minimum time length is 1s!": "न्यूनतम समय अवधि 1s है!",
  "The maximum time length is 50s!": "最大时长为50s！",
  "Try Again": "दोबारा कोशिश करें",
  "Coming Soon": "जल्द आ रहा है",
  "Learn more": "ज़्यादा जानें",
  Yes: "हाँ",
  "Please click the Save button to ensure the previous editing to take effect.":
    "सेट की जाने वाली चीज़ों को प्रभावी बनाने के लिए कृपया सहेजें बटन पर क्लिक करें।",
  // **************************TipsModal Message Title*****************************
  "Cut 10 video fragments at most. Get HitPaw Toolkit for more features.":
    "वीडियो के अधिकतम 10 टुकड़े काटें! ज़्यादा सुविधाओं के लिए HitPaw टूलकिट प्राप्त करें।",
  "Max file size for videos is 20 MB. Get HitPaw Toolkit for big file.":
    "फाइल का आकार 20MB से ज़्यादा बड़ा नहीं हो सकता है। ज़्यादा सुविधाओं के लिए HitPaw टूलकिट प्राप्त करें।",
  "Max file size for videos is 20 MB. Get HitPaw Watermark Remover for big file.":
    "फाइल का आकार 20MB से ज़्यादा बड़ा नहीं हो सकता है। ज़्यादा सुविधाओं के लिए HitPaw टूलकिट प्राप्त करें।",
  "The minimum time length is 1s! Get HitPaw Toolkit for more features.":
    "न्यूनतम समय अवधि 1s है! ज़्यादा सुविधाओं के लिए HitPaw टूलकिट प्राप्त करें।",
  "Add 3 videos at most. Get HitPaw Toolkit for more features.": "अधिकतम 3 वीडियो डालें।. ज़्यादा सुविधाओं के लिए HitPaw टूलकिट प्राप्त करें।",
  "This format is not supported currently.": "इस समय यह फॉर्मेट समर्थित नहीं है।",
  "This format is not supported currently. Get HitPaw Toolkit for more formats":
    "इस समय यह फॉर्मेट समर्थित नहीं है।.  ज़्यादा सुविधाओं के लिए HitPaw टूलकिट प्राप्त करें।",
  "This format is not supported currently. Get HitPaw Watermark Remover for more formats":
    "इस समय यह फॉर्मेट समर्थित नहीं है।.  ज़्यादा सुविधाओं के लिए HitPaw टूलकिट प्राप्त करें।",
  "Processing failed. Get HitPaw Toolkit for stable processing.": "वीडियो जनरेट करने में विफल!",
  "Failed to load!  Get HitPaw Watermark Remover for stable processing.": "लोड करने में विफल",
  "Are you sure you want to delete it?": "क्या आप इसे हटाना चाहते हैं?",
  "Failed to extract the audio!": "ऑडियो निकालने में विफल!",
  "Video link copied": "वीडियो लिंक कॉपी किया गया",
  "Cut 10 video fragments at most.": "वीडियो के अधिकतम 10 टुकड़े काटें!",
  "Max file size for videos is 20 MB.": "फ़ाइल का आकार 20Mb से बड़ा नहीं हो सकता।",
  "Max file size for videos is 100 MB.": "फ़ाइल का आकार 100Mb से बड़ा नहीं हो सकता।",
  "Failed to load!": "लोड करने में विफल",

  //******************************2021.05.17 v1.3.0 *******************************/
  "Screenshots downloaded.": "स्क्रीनशॉट डाउनलोड किया गया।",
  "Online service, no download required": "ऑनलाइन सेवा, डाउनलोड की ज़रूरत नहीं",
  "Add up to 20MB file": "20 एमबी तक की फाइल जोड़ें",
  "Support common formats": "सामान्य फॉर्मेट का समर्थन करें",
  "Best for general editing requirements": "सामान्य एडिटिंग की ज़रूरतों के लिए सर्वश्रेष्ठ",
  "0 second wait": "0 सेकंड प्रतीक्षा",
  "No size limits": "आकार के लिए कोई सीमा नहीं",
  "Support 1000+ formats": "1000+ फॉर्मेट का समर्थन करता है",
  "Stable processing": "स्थिर संसाधन",
  "Get access to HitPaw Toolkit!": "HitPaw टूलकिट का एक्सेस पाएं!",
  "Support cut, crop & rotate, adjust, speed, add music, stop motion, etc.":
    "कट, क्रॉप और रोटेट, समायोजन, गति, संगीत जोड़ने, स्टॉप मोशन आदि का समर्थन करता है",
  "The easiest video editing software for beginners": "नए लोगों के लिए सबसे आसान वीडियो एडिटिंग सॉफ्टवेयर",
  "Remove watermark from videos and images in simple clicks": "आसान क्लिक में वीडियो और छवियों से वॉटरमार्क हटाएं",
  "TRY IT FREE": "इसे मुफ़्त आजमाएं",
  "BUY NOW": "अभी ख़रीदें",

  //******************************2021.09.06 v1.5.0********************************/
  "Add Video": "वीडियो जोड़ें",
  More: "और अधिक",
  "History files": "पुरानी फाइल",
  Share: "शेयर करें",
  Duration: "अवधि",
  "Download all": "सभी डाउनलोड करें",
  "Download selected": "चयनित डाउनलोड करें",
  "Add Audio": "ऑडियो जोड़ें",

  //********************************2021.10.19 v1.6.0*********************************/
  "GIF does not support editing. Do you want to convert the MP4 for editing?":
    "GIF संपादन का समर्थन नहीं करता है। क्या आप संपादन के लिए MP4 कंवर्ट करना चाहते हैं?",
  "Go to Edit": "संपादन पर जाएं",
  "Download GIF": "GIF डाउनलोड करें",

  //********************************2021.10.19 v1.6.1*********************************/
  "HitPaw Video Editor": "हिटपॉ वीडियो एडिटर",
  "No video size limits": "कोई वीडियो आकार सीमा नहीं।",
  "Cut/crop/rotate/resize/merge video easily": "वीडियो को आसानी से काटें/फसल/घुमाएं/आकार बदलें/मर्ज करें।",
  "Tons of video effects are available for your video creativity": "आपकी वीडियो रचनात्मकता के लिए ढ़ेरों वीडियो प्रभाव उपलब्ध हैं।",
  "Unlimited tracks allow you to add multiple audios as you want": "असीमित ट्रैक आपको अपनी इच्छानुसार कई ऑडियो जोड़ने की अनुमति देते हैं।",

  //********************************2021.11.02 v1.6.1*********************************/
  "Download HitPaw Video Editor for more features.": "अधिक सुविधाओं के लिए HitPaw वीडियो संपादक डाउनलोड करें। ",
  "Get HitPaw Watermark Remover for more features.": "ज़्यादा सुविधाओं के लिए HitPaw टूलकिट प्राप्त करें। ",
  "Get HitPaw Toolkit for more features.": "ज़्यादा सुविधाओं के लिए HitPaw टूलकिट प्राप्त करें। ",
  "Add 3 videos at most.": "अपलोड किया गया वीडियो 3 सेकंड से कम का है। ",
  "Processing failed.": "प्रसंस्करण विफल। ",
  "The uploaded video is less than 1 second.": "अपलोड किया गया वीडियो 1 सेकंड से कम का है। ",
  "The video format is not supported.": "वीडियो प्रारूप समर्थित नहीं है। ",
  "The audio format is not supported.": "ऑडियो प्रारूप समर्थित नहीं है। अधिक",
  "AddFileByURL.largerSize.HitPawToolkit":
    "विश्लेषण सफल! आपकी फाइल 100 MB से ज़्यादा की है जिसे HitPaw ऑनलाइन से संपादित नहीं किया जा सकता, कृपया <1><0>वीडियो डाउनलोड</0></1> करें और इसके बाद आगे बढ़ने के लिए HitPaw टूलकिट प्रयोग करें।",
  "AddFileByURL.largerSize.HitPawWatermarkRemover":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Watermark Remover to proceed.",
  "AddFileByURL.largerSize.HitPawVideoEditor":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Video Editor to proceed.",
};
