import React, { Component } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { message } from "antd";
import { ScreenshotWorkRender } from "./children/ScreenshotWorkRender";
import Utils from "@/utils/utils";
import ReactGA from "react-ga";

export default class ScreenshotWorkFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenshotList: [],
      w: null,
      h: null,
      context: null,
      screenshotContentList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      ready: false,
    };
  }

  deleteItem = index => {
    const { screenshotList } = this.state;
    let list = [...screenshotList];
    list.splice(index, 1);
    this.setState({ screenshotList: list });
  };

  handleScreenshot = () => {
    this.props.handleUserActionChange();
    const { context, w, h, screenshotList } = this.state;
    const video = document.querySelector("#videoRef video");
    const canvas = document.querySelector("#canvas");
    context.fillRect(0, 0, w, h);
    context.drawImage(video, 0, 0, w, h);
    const dataUrl = canvas.toDataURL("image/jpg");
    let files = [...screenshotList];
    const item = {
      picUrl: dataUrl,
    };
    files.push(item);
    this.setState({ screenshotList: files });
  };

  getVideoCanvas = () => {
    const video = document.querySelector("#videoRef video");
    const canvas = document.querySelector("#canvas");
    const context = canvas.getContext("2d");
    if (!this.state.context) {
      this.setState({ context: context });
    }
    if (video) {
      if (video.videoWidth !== canvas.width) {
        this.setState({ ready: true });
        this.initCanvas(video, canvas);
      }
      // //add loadedmetadata which will helps to identify video attributes......
      video.addEventListener(
        "loadedmetadata",
        () => {
          this.initCanvas(video, canvas);
          this.setState({ ready: true });
        },
        false
      );
    }
  };

  initCanvas = (video, canvas) => {
    const w = video.videoWidth;
    const h = video.videoHeight;
    this.setState({ w, h });
    canvas.width = w;
    canvas.height = h;
  };

  handleSubmit = () => {
    const { screenshotList } = this.state;
    ReactGA.event(
      {
        category: "Screenshot",
        action: "ScreenshotSave",
        label: screenshotList ? screenshotList.length : 0,
      },
      ["OnlineTracker", "Tracker"]
    );
    if (!screenshotList || (screenshotList && !screenshotList.length)) {
      return false;
    }
    message.loading(window.i18n.t("common:downloading"), 0);
    if (screenshotList && screenshotList.length > 1) {
      const zip = new JSZip();
      const images = zip.folder("hitpaw-screenshot-images");
      screenshotList.forEach((item, index) => {
        const base64 = item.picUrl.substring(22);
        images.file(`screenshot-${index}.jpg`, base64, { base64: true });
      });
      zip
        .generateAsync({ type: "blob" })
        .then(function (content) {
          message.destroy();
          message.success("Download successfully!");
          saveAs(content, "hitpaw-screenshot-images.zip");
        })
        .catch(error => {
          message.success("Download failed!");
        });
    } else if (screenshotList && screenshotList.length === 1) {
      Utils.downloadBase64(screenshotList[0].picUrl);
      message.destroy();
      message.success("Download successfully!");
    }
  };

  componentDidMount() {
    this.setState({ ready: false });
    this.getVideoCanvas();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currFile !== this.props.currFile) {
      this.setState({ screenshotList: [], context: null });
    }
    this.getVideoCanvas();
  }

  render() {
    const { screenshotList, screenshotContentList, w, h, ready } = this.state;
    return (
      <ScreenshotWorkRender
        screenshotList={screenshotList}
        currFile={this.props.currFile}
        w={w}
        h={h}
        ready={ready}
        screenshotContentList={screenshotContentList}
        handleScreenshot={this.handleScreenshot}
        deleteItem={this.deleteItem}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}
