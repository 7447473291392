export default {
  freeTimesLimitTitle: "Le tue opportunità di prova di oggi sono state esaurite, passa a Pro per accedere a tutte le funzionalità avanzate",
  fileSizeLimitTitle:
    "La dimensione del file per gli account gratuiti non può superare i 20 MB, aggiorna a Pro per accedere a tutte le funzionalità premium",
  total: "Totale",
  enterCode: "Inserisci il codice",
  signUpToPurchaseBtn: "Registra per acquistare",
  goPurchseBtn: "Vai all'acquisto",
  saveTips: "Risparmia fino al {{num}}%",
  discount: "Sconto",
  montly: "Mensile",
  yearly: "Annuale",
  vipTips1: "Nessun limite di dimensioni e quantità del video",
  vipTips2: "Rimuovi gli sfondi delle immagini senza limiti",
  vipTips3: "Processo video e velocità di download più veloci",
  vipTips4: "Accesso a tutti gli strumenti gratuiti e alle funzionalità premium",
  purchaseTips: "Rinnova automaticamente e annulla in qualsiasi momento.",
  tips: "Suggerimenti:",
  promoCodeInvalidTips: "Il tuo codice promozionale non è valido",
};
