export default {
  Back: "عودة",
  Cut: "قطع",
  Speed: "السرعة",
  "Crop & Rotate": "قص وتدوير",
  Resize: "تغيير الحجم",
  Extract: "استخراج",
  "Add Music": "إضافة موسيقى",
  Subtitles: "العناوين الفرعية",
  Merge: "دمج",
  "Watermark Remover": "إزالة العلامة المائية",
  "Add Watermark": "إضافة علامة مائية",
  "To GIF": "إلى الصور المتحركة",
  Convert: "تحول",
  Screenshot: "لقطة الشاشة",
  Reverse: "عكس",
  Loop: "تكرار",
};
