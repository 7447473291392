import React from "react";
import { useTranslation } from "react-i18next";
import { Tabs, Row, Col, Input, Button, Select } from "antd";
import InputColor from "react-input-color";
import { PlusOutlined } from "@ant-design/icons";
import { ReactComponent as Close } from "@/assets/icons/close.svg";
import Utils from "@/utils/utils";
import "@/assets/subtitleWork.scss";

const { TextArea } = Input;
const { TabPane } = Tabs;
const { Option } = Select;

export const SubtitleWorkRender = ({
  subtitleWorkList,
  currFile,
  fontFamilyList,
  fontSizeList,
  color,
  border,
  background,
  textAreaRef,
  selectWork,
  handleSetColor,
  handleSetPosition,
  handleSetFontFamily,
  handleSetFontSize,
  handleSubtitleChange,
  handleQuickSetColor,
  handleStartChange,
  handleEndChange,
  onBlurSubtitle,
  onFocusSubtitle,
  removeWork,
  addWork,
  preSubmit,
  textColorList,
  borderColorList,
  backgroundColorList,
}) => {
  const { t } = useTranslation(["subtitleWork", "common"]);
  return (
    <div className={"SubtitleWork" + (!currFile ? " disabled" : "")}>
      <Tabs defaultActiveKey="1">
        <TabPane tab={t("Text")} key="1" className="subtitle-list">
          <div className="add">
            <button onClick={addWork} disabled={currFile ? false : true}>
              <PlusOutlined />
              <span>{t("Add subtitles")}</span>
            </button>
          </div>
          {subtitleWorkList &&
            subtitleWorkList.map((item, index) => (
              <div className={`subtitle-content ${item.selected ? "active" : ""}`} key={index} onClick={() => selectWork(index)}>
                <TextArea
                  placeholder={t("Text here")}
                  spellCheck={false}
                  onChange={e => handleSubtitleChange(e, index)}
                  value={item.subtitle}
                  onBlur={e => onBlurSubtitle(e, index)}
                  onFocus={() => onFocusSubtitle(index)}
                  ref={textArea => {
                    textAreaRef[index] = textArea;
                  }}
                />
                <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                  <span style={{ flexShrink: 0, marginBottom: 6 }}>{t("common:Duration")}</span>
                  <div className="time-row">
                    <Input
                      value={item.start}
                      onChange={handleStartChange}
                      onBlur={() => {
                        Utils.handleBlurFormat("SUBTITLEWORKLIST_CHANGE", [...subtitleWorkList], index);
                      }}
                    />
                    <span style={{ marginRight: 0 }}>-</span>
                    <Input
                      value={item.end}
                      onChange={handleEndChange}
                      onBlur={() => {
                        Utils.handleBlurFormat("SUBTITLEWORKLIST_CHANGE", [...subtitleWorkList], index);
                      }}
                    />
                  </div>
                </div>
                {subtitleWorkList.length > 1 && (
                  <button
                    className="close"
                    onClick={e => {
                      removeWork(e, index);
                    }}
                  >
                    <Close />
                  </button>
                )}
              </div>
            ))}
        </TabPane>
        <TabPane tab={t("Styles")} key="2" style={{ overflow: "hidden" }}>
          <Row justify="space-between" gutter={[8, 0]}>
            <Col span={14}>
              <Select defaultValue="System" onChange={handleSetFontFamily} style={{ width: "100%" }}>
                {fontFamilyList.map(item => (
                  <Option value={item} key={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={10}>
              {/* <InputNumber value={.fontSize} formatter={value => `${value} px`} onChange={handleSetFontSize} style={{ width: '100%' }} /> */}
              <Select defaultValue={22} onChange={handleSetFontSize} style={{ width: "100%" }}>
                {fontSizeList.map(item => (
                  <Option value={item} key={item}>
                    {item}px
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Tabs defaultActiveKey="1" className="color-picker-cont">
            <TabPane tab={t("Text Color")} key="1">
              <div className="color-picker">
                <div className="color-input">
                  <InputColor initialValue="#000" onChange={val => handleSetColor("color", val)} placement="top" />
                  <span className="color-value">{color}</span>
                </div>
                {textColorList.map(item => (
                  <div
                    key={item}
                    className={`presetColor ${color === item ? "active" : ""}`}
                    onClick={() => {
                      handleQuickSetColor("color", item);
                    }}
                  >
                    <div style={{ background: item }}></div>
                  </div>
                ))}
              </div>
            </TabPane>
            <TabPane tab={t("Outline")} key="2">
              <div className="color-picker">
                <div className="color-input">
                  <InputColor initialValue="#000" onChange={val => handleSetColor("border", val)} placement="top" />
                  <span className="color-value">{border}</span>
                </div>
                {borderColorList.map(item => (
                  <div
                    key={item}
                    className={`presetColor ${border === item ? "active" : ""}`}
                    onClick={() => {
                      handleQuickSetColor("border", item);
                    }}
                  >
                    <div className={item === "none" ? "colorNone" : ""} style={item !== "none" ? { background: item } : {}}></div>
                  </div>
                ))}
              </div>
            </TabPane>
            <TabPane tab={t("Background")} key="3">
              <div className="color-picker">
                <div className="color-input">
                  <InputColor initialValue="#000" onChange={val => handleSetColor("background", val)} placement="top" />
                  <span className="color-value">{background}</span>
                </div>
                {backgroundColorList.map(item => (
                  <div
                    key={item}
                    className={`presetColor ${background === item ? "active" : ""}`}
                    onClick={() => {
                      handleQuickSetColor("background", item);
                    }}
                  >
                    <div className={item === "none" ? "colorNone" : ""} style={item !== "none" ? { background: item } : {}}></div>
                  </div>
                ))}
              </div>
            </TabPane>
          </Tabs>
          <div style={{ marginTop: "1.2rem" }}>
            <p style={{ marginBottom: "0.625rem", color: "#979798" }}>{t("Position")}</p>
            <Row gutter={[6, 6]} className="position">
              <Col span={8}>
                <div onClick={() => handleSetPosition("top-left", 1)}></div>
              </Col>
              <Col span={8}>
                <div onClick={() => handleSetPosition("top-center", 2)}></div>
              </Col>
              <Col span={8}>
                <div onClick={() => handleSetPosition("top-right", 3)}></div>
              </Col>
              <Col span={8}>
                <div onClick={() => handleSetPosition("middle-left", 4)}></div>
              </Col>
              <Col span={8}>
                <div onClick={() => handleSetPosition("middle-center", 5)}></div>
              </Col>
              <Col span={8}>
                <div onClick={() => handleSetPosition("middle-right", 6)}></div>
              </Col>
              <Col span={8}>
                <div onClick={() => handleSetPosition("bottom-left", 7)}></div>
              </Col>
              <Col span={8}>
                <div onClick={() => handleSetPosition("bottom-center", 8)}></div>
              </Col>
              <Col span={8}>
                <div onClick={() => handleSetPosition("bottom-right", 9)}></div>
              </Col>
            </Row>
          </div>
        </TabPane>
      </Tabs>
      <div style={{ margin: "0 1.5rem 0" }} className="save">
        <Button type="primary" block={true} onClick={preSubmit} size="large">
          {t("common:Save")}
        </Button>
      </div>
    </div>
  );
};
