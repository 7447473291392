import React from "react";
import { Row, Col, Slider, Button } from "antd";
import "@/assets/speedWork.scss";
import { useTranslation } from "react-i18next";

export const SpeedWorkRender = ({ currFile, playbackRate, handleSpeedChange, preSubmit, submitting, fileListModal }) => {
  const { t } = useTranslation(["common"]);
  return (
    <div className={"SpeedWork" + (!currFile ? " disabled" : "")}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Slider
            className="normal"
            value={playbackRate * 100}
            min={20}
            max={400}
            tooltipVisible={currFile && !submitting && (!fileListModal || !fileListModal.show) ? true : false}
            tipFormatter={value => "x " + value / 100}
            onChange={value => {
              handleSpeedChange(value / 100);
            }}
          />
        </Col>
        <Col span={24}>
          <Row gutter={[0, 16]}>
            <Col span={8}>
              <div
                className={`setSpeedBtn ${playbackRate === 0.25 ? "active" : ""}`}
                onClick={() => {
                  handleSpeedChange(0.25);
                }}
              >
                x 0.25
              </div>
            </Col>
            <Col span={8}>
              <div
                className={`setSpeedBtn ${playbackRate === 0.5 ? "active" : ""}`}
                onClick={() => {
                  handleSpeedChange(0.5);
                }}
              >
                x 0.50
              </div>
            </Col>
            <Col span={8}>
              <div
                className={`setSpeedBtn ${playbackRate === 0.75 ? "active" : ""}`}
                onClick={() => {
                  handleSpeedChange(0.75);
                }}
              >
                x 0.75
              </div>
            </Col>
          </Row>
          <Row gutter={[0, 16]}>
            <Col span={8}>
              <div
                className={`setSpeedBtn ${playbackRate === 1.25 ? "active" : ""}`}
                onClick={() => {
                  handleSpeedChange(1.25);
                }}
              >
                x 1.25
              </div>
            </Col>
            <Col span={8}>
              <div
                className={`setSpeedBtn ${playbackRate === 1.5 ? "active" : ""}`}
                onClick={() => {
                  handleSpeedChange(1.5);
                }}
              >
                x 1.50
              </div>
            </Col>
            <Col span={8}>
              <div
                className={`setSpeedBtn ${playbackRate === 2 ? "active" : ""}`}
                onClick={() => {
                  handleSpeedChange(2);
                }}
              >
                x 2.00
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Button className="save" type="primary" block={true} onClick={preSubmit} size="large">
        {t("common:Save")}
      </Button>
    </div>
  );
};
