export default {
  Back: "Назад",
  Cut: "Резать",
  Speed: "Переменить скорость",
  "Crop & Rotate": "обрезка&поворот",
  Resize: "Изменить размер",
  Extract: "Извлечь видео и аудио",
  "Add Music": "Добавить музыку",
  Subtitles: "Добавить субтитры",
  Merge: "Объединить",
  "Watermark Remover": "Удалить водяной знак",
  "Add Watermark": "Добавить водяной знак",
  "To GIF": "В GIF",
  Convert: "Преобразовать формат",
  Screenshot: "Снимок экрана",
  Reverse: "Реверс",
  Loop: "Зациклить",
};
