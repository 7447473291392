import React from "react";
import { useTranslation } from "react-i18next";
import { Row } from "antd";
import FileItemRender from "./children/FileItemRender";
import "@/assets/fileListModal.scss";

const FileListModalRender = ({ files, handleSelectClick, handleDeleteModalShow, handleDownloadClick }) => {
  const { t } = useTranslation(["fileList"]);

  return (
    <div>
      <div className="Files">
        <Row gutter={[16, 16]} justify="flex-start" align="middle">
          {files.length > 0
            ? files.map((item, index) => (
                <FileItemRender
                  handleSelectClick={handleSelectClick}
                  handleDeleteModalShow={handleDeleteModalShow}
                  handleDownloadClick={handleDownloadClick}
                  key={index}
                  file={item}
                />
              ))
            : null}
        </Row>
      </div>
      <p className="tips">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="8" cy="8" r="7" stroke="#A1A1A2" />
          <path d="M8 7L8 12" stroke="#A1A1A2" />
          <path d="M8.5 5H7.5" stroke="#A1A1A2" />
        </svg>
        {t("Don't forget to download your file, it will be deleted after 24 hours")}
      </p>
    </div>
  );
};

export default FileListModalRender;
