export default {
  freeTimesLimitTitle: "La prueba de hoy se ha agotado, por favor, actualice a Pro para acceder a todas las funciones premium",
  fileSizeLimitTitle:
    "El tamaño de los archivos de las cuentas gratuitas no puede superar los 20 MB, actualice a Pro para acceder a todas las funciones premium",
  total: "Total",
  enterCode: "Introduzca el código",
  signUpToPurchaseBtn: "Regístrese para comprar",
  goPurchseBtn: "Ir a la compra",
  saveTips: "Ahorre hasta un {{num}}%",
  discount: "Descuento",
  montly: "Mensualmente",
  yearly: "Anual",
  vipTips1: "Sin límites en el tamaño y la cantidad de vídeos",
  vipTips2: "Sin límites en eliminar fondos de imágenes",
  vipTips3: "Mejorar el procesamiento de video y la velocidad de descarga",
  vipTips4: "Acceso a todas las herramientas gratuitas & funciones premium",
  purchaseTips: "Renovar automáticamente y cancelar en cualquier momento.",
  tips: "Consejos:",
  promoCodeInvalidTips: "Su código de promoción no es válido",
};
