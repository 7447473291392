export default {
  freeTimesLimitTitle:
    "Sie haben heute keine Testoptionen mehr, sondern müssen auf die Pro-Version upgraden, um alle Premium-Funktionen zu erhalten.",
  fileSizeLimitTitle:
    "Das kostenlose Konto erlaubt nur die Bearbeitung von Dateien bis zu 20 MB, ein Upgrade auf das Pro-Konto bietet alle Premium-Funktionen.",
  total: "Gesamt",
  enterCode: "Code eingeben",
  signUpToPurchaseBtn: "Anmelden und kaufen",
  goPurchseBtn: "Zum Einkaufen gehen",
  saveTips: "Sparen Sie bis zu {{num}}%",
  discount: "Rabatt",
  montly: "Monatlich",
  yearly: "Jährlich",
  vipTips1: "Keine Begrenzung der Videogröße und -menge",
  vipTips2: "Bildhintergründe ohne Einschränkungen entfernen",
  vipTips3: "Schnellere Videoverarbeitung und Download-Geschwindigkeit",
  vipTips4: "Zugang zu allen kostenlosen Tools & Premium-Funktionen",
  purchaseTips: "Automatische Verlängerung und jederzeitige Kündigung.",
  tips: "Hinweise:",
  promoCodeInvalidTips: "Ihr Promo-Code ist ungültig",
};
