export default {
  'Image': 'Görüntü',
  'Text': 'Metin',
  'Add image': 'Görüntü ekle',
  'Opacity': 'Opaklık',
  "Add text": 'Metin ekle',
  'Text Color': 'Metin rengi',
  'Outline': 'Anahat',
  'Background': 'Arka plan',
  'Text here': 'Metin buraya',
}