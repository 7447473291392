import { Button, Switch, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { GAPurchaseModalBuyClick, GAPurchaseModalSignUpClick } from "@/ga/purchase";

const PurchaseModalRender = ({
  currPackageKey,
  currPackage,
  enterCodeInputVisible,
  signUpUrl,
  purchaseType,
  handleCurrPackageKeyChange,
  handleEnterCodeInputVisibleChange,
  getPurchase,
  purchaseLoading,
  purchaseModalObj,
  authorization,
  handleSentCode,
}) => {
  const { t } = useTranslation(["purchase"]);

  return (
    <div onClick={() => handleEnterCodeInputVisibleChange(false)}>
      <h1>{purchaseModalObj.type === "uploadSizeBiggerThanLimit" ? t("fileSizeLimitTitle") : t("freeTimesLimitTitle")}</h1>
      <div className="price-content">
        <div className="change-package">
          <span>{t("montly")}</span>
          <Switch size="small" checked={currPackageKey === "yearly"} onChange={handleCurrPackageKeyChange} />
          <span>{t("yearly")}</span>
        </div>

        <span className="tips">{t("saveTips", { num: currPackage.percent })}</span>
        <div className="price">
          <span className="currency">{currPackage.currency}</span>
          <span className="price-number">{currPackage.price}</span>
          <span className="currency-area">{currPackage.currencyArea}</span>
        </div>
        <div className="total-price">
          <div className="discount">
            <label>{t("discount")}</label>
            {enterCodeInputVisible ? (
              <div onClick={e => e.stopPropagation()}>
                <Input.Group compact>
                  <Input />
                  <Button type="primary" onClick={handleSentCode}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 15L14 9L8 3" stroke="currentColor" strokeWidth="1.5" />
                      <path d="M13.498 9H0" stroke="currentColor" strokeWidth="1.5" />
                    </svg>
                  </Button>
                </Input.Group>
              </div>
            ) : (
              <span
                className="code"
                onClick={e => {
                  e.stopPropagation();
                  handleEnterCodeInputVisibleChange(true);
                }}
              >
                {t("enterCode")}
              </span>
            )}
          </div>
          <div className="total">
            <label>{t("total")}</label>
            <div>
              {currPackage.price} {currPackage.currencyArea}
            </div>
          </div>
        </div>
        {purchaseType === "account" ? (
          <Button
            className="pruchase-btn"
            type="primary"
            onClick={GAPurchaseModalSignUpClick}
            href={`${signUpUrl}?redirect=${window.location.href}&to=purchase&auth=${authorization}`}
          >
            {t("signUpToPurchaseBtn")}
          </Button>
        ) : (
          <Button
            className="pruchase-btn"
            type="primary"
            onClick={() => {
              getPurchase();
              GAPurchaseModalBuyClick();
            }}
            loading={purchaseLoading}
          >
            {t("goPurchseBtn")}
          </Button>
        )}
      </div>
      <ul className="list">
        <li>{t("vipTips1")}</li>
        <li>{t("vipTips2")}</li>
        <li>{t("vipTips3")}</li>
        <li>{t("vipTips4")}</li>
      </ul>
      <div className="tips-cont">
        <span>{t("tips")}</span> {t("purchaseTips")}
      </div>
    </div>
  );
};

export default PurchaseModalRender;
