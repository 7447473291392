import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "@/action";
import LoopPreviewFunction from "./children/LoopPreviewFunction";
import { useDrop } from "react-dnd";
import { ReactComponent as Close } from "@/assets/icons/close-circle.svg";

const VideoWrap = ({ currFile, onCurrFileChange }) => {
  const [, drop] = useDrop({
    accept: "FileListItem",
    drop: () => ({ name: "SpeedVideoWrap" }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: item => {
      if ((item.file && item.file.audiofileurl) || (item.file && item.file.status === "gif")) {
        return false;
      }
      return true;
    },
  });
  return (
    <div ref={drop} className="videoWrap" id="videoRef">
      <div style={{ width: "fit-content", height: "fit-content", margin: "auto", position: "relative" }}>
        <button className="close" onClick={() => onCurrFileChange(null)}>
          <Close />
        </button>
        <video className="active" crossOrigin="Anonymous">
          <source src={currFile.videofileurl} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

class LoopPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // componentDidMount() {
  //   // const myvideo = document.querySelector("#videoRef video");

  // }

  // componentDidUpdate(prevProps) {
  //   if (this.props.loopTimes !== prevProps.loopTimes) {
  //     // const myvideo = document.querySelector("#videoRef video");
  //   }
  // }

  render() {
    return (
      <div className="SpeedPreview">
        <VideoWrap currFile={this.props.currFile} onCurrFileChange={this.props.onCurrFileChange} />
        <LoopPreviewFunction currFile={this.props.currFile} submitting={this.props.submitting} loopTimes={this.props.loopTimes} />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  work: state.work.work,
  loopTimes: state.work.loopTimes,
  submitting: state.work.submitting,
});

const mapDispatchToProps = dispatch => ({
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(LoopPreview);
