export default {
  Back: "Volver",
  Cut: "Cortar",
  Speed: "Velocidad",
  "Crop & Rotate": "Recortar & Rotar",
  Resize: "Ajustar",
  Extract: "Extraer",
  "Add Music": "Añadir música",
  Subtitles: "Subtítulos",
  Merge: "Unir",
  "Watermark Remover": "Quitar marca de agua",
  "Add Watermark": "Añadir marca de agua",
  "To GIF": "A GIF",
  Convert: "Convertir",
  Screenshot: "Captura de pantalla",
  Reverse: "Inverso",
  Loop: "Bucle",
};
