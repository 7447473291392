export default {
  FILES_CHANGE: "FILES_CHANGE",
  WORK_CHANGE: "WORK_CHANGE",
  PREVWORK_CHANGE: "PREVWORK_CHANGE",
  CUTWORKLIST_CHANGE: "CUTWORKLIST_CHANGE",
  RESOLUTION_CHANGE: "RESOLUTION_CHANGE",
  FILELISTMODAL_CHANGE: "FILELISTMODAL_CHANGE",
  PROCESSPERCENT_CHANGE: "PROCESSPERCENT_CHANGE",
  MERGEREBGCOLOR_CHANGE: "MERGEREBGCOLOR_CHANGE",
  TOKENID_CHANGE: "TOKENID_CHANGE",
  PREUPLOADFILE_CHANGE: "PREUPLOADFILE_CHANGE",
  UPLOADING_CHANGE: "UPLOADING_CHANGE",
  UPLOADPERCENT_CHANGE: "UPLOADPERCENT_CHANGE",
  PROGRESSSTEP_CHANGE: "PROGRESSSTEP_CHANGE",
  MERGEWORKLIST_CHANGE: "MERGEWORKLIST_CHANGE",
  HANDLEIMGERR_CHANGE: "HANDLEIMGERR_CHANGE",
  CURRPROGRESS_CHANGE: "CURRPROGRESS_CHANGE",
  STARTTIME_CHANGE: "STARTTIME_CHANGE",
  CURRFILE_CHANGE: "CURRFILE_CHANGE",
  CANCELPROGRESS_CHANGE: "CANCELPROGRESS_CHANGE",
  USERACTION_CHANGE: "USERACTION_CHANGE",
  PLAYBACKRATE_CHANGE: "PLAYBACKRATE_CHANGE",
  CROP_CHANGE: "CROP_CHANGE",
  ROTATE_CHANGE: "ROTATE_CHANGE",
  FLIP_CHANGE: "FLIP_CHANGE",
  FILLING_CHANGE: "FILLING_CHANGE",
  REMOVEWATERMARKLIST_CHANGE: "REMOVEWATERMARKLIST_CHANGE",
  DROPVIDEOPOSITION_CHANGE: "DROPVIDEOPOSITION_CHANGE",
  FILETOBLOBSTATUS_CHANGE: "FILETOBLOBSTATUS_CHANGE",
  SUBMITTING_CHANGE: "SUBMITTING_CHANGE",
  UPLOADAGAINFILE_CHANGE: "UPLOADAGAINFILE_CHANGE",
  UPLOADXHR_CHANGE: "UPLOADXHR_CHANGE",
  ADDMUSICWORKOBJ_CHANGE: "ADDMUSICWORKOBJ_CHANGE",
  ADDMUSICAUDIOTIMEOBJ_CHANGE: "ADDMUSICAUDIOTIMEOBJ_CHANGE",
  ADDMUSICAUDIOSTARTPLAYTIME_CHANGE: "ADDMUSICAUDIOSTARTPLAYTIME_CHANGE",
  ADDMUSICAUDIOVOLUMEOBJ_CHANGE: "ADDMUSICAUDIOVOLUMEOBJ_CHANGE",
  SUBTITLEWORKLIST_CHANGE: "SUBTITLEWORKLIST_CHANGE",
  QUICKLYPOSITION_CHANGE: "QUICKLYPOSITION_CHANGE",
  ADDWATERMARKWORKLIST_CHANGE: "ADDWATERMARKWORKLIST_CHANGE",
  FETCHABORT_CHANGE: "FETCHABORT_CHANGE",
  SUCCESSFILEGUIDS_CHANGE: "SUCCESSFILEGUIDS_CHANGE",
  SELECTEDFILE_CHANGE: "SELECTEDFILE_CHANGE",
  LOOPTIMES_CHANGE: "LOOPTIMES_CHANGE", //循环次数
  REVERSEOBJ_CHANGE: "REVERSEOBJ_CHANGE", //视频倒放参数对象
  PROCESSMODALVISIBLE_CHANGE: "PROCESSMODALVISIBLE_CHANGE", //进度条弹窗是否直接显示，设置为true不管其他条件是啥，可直接显示进度条

  PURCHASEMODALOBJ_CHANGE: "PURCHASEMODALOBJ_CHANGE", //购买弹窗
  PROFILEOBJ_CHANGE: "PROFILEOBJ_CHANGE", //用户个人信息
  AUTHORIZATION_CHANGE: "AUTHORIZATION_CHANGE", //认证信息
  SUBSCRIPTION_CHANGE: "SUBSCRIPTION_CHANGE", //订阅信息

  THEME_CHANGE: "THEME_CHANGE",
  VIDEOEDITORTIMES_CHANGE: "VIDEOEDITORTIMES_CHANGE",
};
