import common from "./common";
import cutWork from "./cutWork";
import cropRotateWork from "./cropRotateWork";
import resizeWork from "./resizeWork";
import muteWork from "./muteWork";
import addMusicWork from "./addMusicWork";
import subtitleWork from "./subtitleWork";
import mergeWork from "./mergeWork";
import removeWatermarkWork from "./removeWatermarkWork";
import addWatermarkWork from "./addWatermarkWork";
import gifWork from "./gifWork";
import convertWork from "./convertWork";
import screenshotWork from "./screenshotWork";
import loopWork from "./loopWork.js";
import navbar from "./navbar";
import upload from "./upload";
import fileList from "./fileList";
import purchase from "./purchase";
export default {
  common: {
    ...common,
  },
  cutWork: {
    ...cutWork,
  },
  cropRotateWork: {
    ...cropRotateWork,
  },
  resizeWork: {
    ...resizeWork,
  },
  muteWork: {
    ...muteWork,
  },
  addMusicWork: {
    ...addMusicWork,
  },
  subtitleWork: {
    ...subtitleWork,
  },
  mergeWork: {
    ...mergeWork,
  },
  removeWatermarkWork: {
    ...removeWatermarkWork,
  },
  addWatermarkWork: {
    ...addWatermarkWork,
  },
  gifWork: {
    ...gifWork,
  },
  convertWork: {
    ...convertWork,
  },
  screenshotWork: {
    ...screenshotWork,
  },
  loopWork: {
    ...loopWork,
  },
  navbar: {
    ...navbar,
  },
  upload: {
    ...upload,
  },
  fileList: {
    ...fileList,
  },
  purchase: {
    ...purchase,
  },
};
