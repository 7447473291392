import Types from "../../action/types";
import Cookies from "js-cookie";

const defaultState = {
  fileListModal: null,
  tokenId: null,
  handleImgErr: [],
  userAction: false,
  purchaseModalObj: {
    visible: false,
  },
  authorization: Cookies.get("authorization") || Cookies.get("anonymousAuth"),
  profileObj: null,
  subscription: null,
  theme: localStorage.getItem("theme") || "dark", //主题
  videoEditorTimes: Cookies.get("videoEditorTimes") ? Number(Cookies.get("videoEditorTimes")) : 0, //当前用户处理视频数
};

export default function onAction(state = defaultState, action) {
  switch (action.type) {
    case Types.FILELISTMODAL_CHANGE:
      return {
        ...state,
        fileListModal: action.fileListModal,
      };
    case Types.TOKENID_CHANGE:
      return {
        ...state,
        tokenId: action.tokenId,
      };
    case Types.HANDLEIMGERR_CHANGE:
      return {
        ...state,
        handleImgErr: action.handleImgErr,
      };
    case Types.USERACTION_CHANGE:
      return {
        ...state,
        userAction: action.userAction,
      };
    case Types.PURCHASEMODALOBJ_CHANGE:
      return {
        ...state,
        purchaseModalObj: action.purchaseModalObj,
      };
    case Types.AUTHORIZATION_CHANGE:
      return {
        ...state,
        authorization: action.authorization,
      };
    case Types.PROFILEOBJ_CHANGE:
      return {
        ...state,
        profileObj: action.profileObj,
      };
    case Types.SUBSCRIPTION_CHANGE:
      return {
        ...state,
        subscription: action.subscription,
      };
    case Types.THEME_CHANGE:
      return {
        ...state,
        theme: action.theme,
      };
    case Types.VIDEOEDITORTIMES_CHANGE:
      return {
        ...state,
        videoEditorTimes: action.videoEditorTimes,
      };
    default:
      return state;
  }
}
