export default {
  Back: "Indietro",
  Cut: "Tagliare",
  Speed: "Velocità",
  "Crop & Rotate": "Ritagliare & Ruotare",
  Resize: "Ridimensionare",
  Extract: "Estrai",
  "Add Music": "Aggiungere Musica",
  Subtitles: "Sottotitolo",
  Merge: "Unire",
  "Watermark Remover": "Rimuovere Filigrana",
  "Add Watermark": "Aggiungere Filigrana",
  "To GIF": "In GIF",
  Convert: "Converti",
  Screenshot: "Screenshot",
  Reverse: "Invertire",
  Loop: "Ciclo",
};
