export default {
  'Files': '파일 라이브러리',
  'Refresh': '새로 고침',
  'Original file': '파일 원본',
  'Just now': '방금',
  'hour ago': '{{num}} 시전',
  'hours ago': '{{num}} 시전',
  'minute ago': '{{num}} 분전',
  'minutes ago': '{{num}} 분전',
  'day ago': '{{num}} 天前',
  'View All': '모두 보기',
  'No files': '파일이 없습니다...',
  "Don't forget to download your file, it will be deleted after 24 hours": '파일을 다운로드하는 것을 잊지 마십시오. 24시간 후에는 파일이 완전히 삭제됩니다.',
  'Select All': '모두 선택',
  'Delete': '삭제',
  'Generate Files Successfully!': '파일을 생성했습니다!',
  'Continue Editing': '편집을 계속합니다',
  'Edit New Video': '새 비디오를 편집합니다',
  'Download': '다운로드',
  'Click here to open the Files again':'파일을 다시 열려면 여기를 클릭하십시오.',
  'Processing... Please wait': '처리 중입니다... 기다려 주세요.',
  'Uploading... Please wait': '업로드 중입니다. 기다려 주세요.',
  'Edit Now': '지금 편집하기',
  'Back to Edit':'다시 편집하십시오',
  'Go Edit':'편집하다'

}