export default {
  Start: "Begin",
  End: "Einde",
  Save: "Opslaan",
  Cancel: "Annuleren",
  Download: "Downloaden",
  downloading: "Downloaden",
  "Drag and drop from Files or local disk": "Slepen en neerzetten vanuit Bestanden of lokale schijf",
  "Click to Add File": "Klik om bestand toe te voegen",
  "The minimum time length is 1s!": "De minimale tijdsduur is 1s!",
  "The maximum time length is 50s!": "De maximale tijdsduur is 50s!",
  "Try Again": "Probeer opnieuw",
  "Coming Soon": "Binnenkort",
  "Learn more": "Leer meer",
  Yes: "Ja",
  "Please click the Save button to ensure the previous editing to take effect.":
    "Alstublieft klik op de knop Opslaan om ervoor te zorgen dat de instellingen van kracht worden.",
  // **************************TipsModal Message Title*****************************
  "Cut 10 video fragments at most. Get HitPaw Toolkit for more features.":
    "Knip 10 video fragmenten hooguit. Krijg HitPaw Toolkit voor meer functies.",
  "Max file size for videos is 20 MB. Get HitPaw Toolkit for big file.":
    "De bestandsgrootte mag niet groter zijn dan 20MB. Krijg HitPaw Toolkit voor meer functies.",
  "Max file size for videos is 20 MB. Get HitPaw Watermark Remover for big file.":
    "De bestandsgrootte mag niet groter zijn dan 20MB. Krijg HitPaw Watermark Remover voor meer functies.",
  "The minimum time length is 1s! Get HitPaw Toolkit for more features.": "De minimale tijdsduur is 1s! Krijg HitPaw Toolkit voor meer functies.",
  "Add 3 videos at most. Get HitPaw Toolkit for more features.": "Voeg hooguit x-video's toe. Krijg HitPaw Toolkit voor meer functies.",
  "This format is not supported currently.": "Dit formaat wordt momenteel niet ondersteund.",
  "This format is not supported currently. Get HitPaw Toolkit for more formats":
    "Dit formaat wordt momenteel niet ondersteund. Krijg HitPaw Toolkit voor meer functies.",
  "This format is not supported currently. Get HitPaw Watermark Remover for more formats":
    "Dit formaat wordt momenteel niet ondersteund. Krijg HitPaw Watermark Remover voor meer functies.",
  "Processing failed. Get HitPaw Toolkit for stable processing.": "Mislukt om video te genereren!",
  "Failed to load!  Get HitPaw Watermark Remover for stable processing.": "Mislukt te laden!",
  "Are you sure you want to delete it?": "Weet u zeker dat u het wilt verwijderen?",
  "Failed to extract the audio!": "Het lukt niet om de audio uit te pakken!",
  "Video link copied": "Video link gekopieerd",
  "Cut 10 video fragments at most.": "Knip 10 video fragmenten hooguit.",
  "Max file size for videos is 20 MB.": "De bestandsgrootte mag niet groter zijn dan 20Mb. ",
  "Max file size for videos is 100 MB.": "De bestandsgrootte mag niet groter zijn dan 100Mb. ",
  "Failed to load!": "Mislukt te laden!",

  //******************************2021.05.17 v1.3.0 *******************************/
  "Screenshots downloaded.": "Screenshots gedownload.",
  "Online service, no download required": "Online service, geen download vereist",
  "Add up to 20MB file": "Voeg tot 20MB bestand toe",
  "Support common formats": "Ondersteunt gangbare formaten",
  "Best for general editing requirements": "Het beste voor algemene bewerkingsbehoeften",
  "0 second wait": "0 seconden wachttijd",
  "No size limits": "Geen groottebeperkingen",
  "Support 1000+ formats": "Ondersteunt 1000+ formaten",
  "Stable processing": "Stabiele verwerking",
  "Get access to HitPaw Toolkit!": "Krijg toegang tot de HitPaw Toolkit!",
  "Support cut, crop & rotate, adjust, speed, add music, stop motion, etc.":
    "Ondersteunt knippen, bijsnijden & roteren, aanpassen, versnellen, muziek toevoegen, stop motion, enz.",
  "The easiest video editing software for beginners": "De gemakkelijkste videobewerkingssoftware voor beginners",
  "Remove watermark from videos and images in simple clicks": "Verwijder watermerk van video's en afbeeldingen met eenvoudige muisklikken",
  "TRY IT FREE": "PROBEER HET GRATIS",
  "BUY NOW": "NU KOPEN",

  //******************************2021.09.06 v1.5.0********************************/
  "Add Video": "Video toevoegen",
  More: "Meer",
  "History files": "Geschiedenis bestanden",
  Share: "Delen",
  Duration: "Duur",
  "Download all": "Alles downloaden",
  "Download selected": "Geselecteerd downloaden",
  "Add Audio": "Audio toevoegen",

  //********************************2021.10.19 v1.6.0*********************************/
  "GIF does not support editing. Do you want to convert the MP4 for editing?":
    "GIF kan niet bewerkt worden. Wilt u de MP4 converteren om te bewerken?",
  "Go to Edit": "Ga naar Bewerken",
  "Download GIF": "GIF downloaden",

  //********************************2021.10.19 v1.6.1*********************************/
  "HitPaw Video Editor": "HitPaw Video-redacteur",
  "No video size limits": "Geen beperkingen voor de grootte van video's.",
  "Cut/crop/rotate/resize/merge video easily": "Video's gemakkelijk knippen/snijden/draaien/vergroten/samenvoegen.",
  "Tons of video effects are available for your video creativity": "Tonnen video-effecten zijn beschikbaar voor uw video-creativiteit.",
  "Unlimited tracks allow you to add multiple audios as you want": "Onbeperkt aantal sporen, zodat u meerdere audio's kunt toevoegen als u wilt.",

  //********************************2021.11.02 v1.6.1*********************************/
  "Download HitPaw Video Editor for more features.": "Downloaden HitPaw Video Redacteur voor meer functies.",
  "Get HitPaw Watermark Remover for more features.": "Krijg HitPaw Watermark Remover voor meer functies.",
  "Get HitPaw Toolkit for more features.": "Krijg HitPaw Toolkit voor meer functies.",
  "Add 3 videos at most.": "Voeg maximaal 3 video's toe.",
  "Processing failed.": "Verwerking mislukt. ",
  "The uploaded video is less than 1 second.": "De geüploade video is korter dan 1 seconden. ",
  "The video format is not supported.": "Het videoformaat wordt niet ondersteund. ",
  "The audio format is not supported.": "Het audioformaat wordt niet ondersteund. ",
  "AddFileByURL.largerSize.HitPawToolkit":
    "Analyse succesvol! Uw bestand is groter dan 100MB en kan niet worden bewerkt door HitPaw Online, alstublieft <1><0>download de video</0></1> en gebruik daarna HitPaw Toolkit om verder te gaan.",
  "AddFileByURL.largerSize.HitPawWatermarkRemover":
    "Analyse succesvol! Uw bestand is groter dan 100MB en kan niet worden bewerkt door HitPaw Online, alstublieft <1><0>download de video</0></1> en gebruik daarna HitPaw Watermark Remover om verder te gaan.",
  "AddFileByURL.largerSize.HitPawVideoEditor":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Video Editor to proceed.",
};
