import Types from "../types";

export function onFileListModalChange(fileListModal) {
  return { type: Types.FILELISTMODAL_CHANGE, fileListModal: fileListModal };
}

export function onTokenIdChange(tokenId) {
  return { type: Types.TOKENID_CHANGE, tokenId: tokenId };
}

export function onHandleImgErrChange(handleImgErr) {
  return { type: Types.HANDLEIMGERR_CHANGE, handleImgErr: handleImgErr };
}

export function onUserActionChange(userAction) {
  return { type: Types.USERACTION_CHANGE, userAction: userAction };
}

export function onPurchaseModalObjChange(purchaseModalObj) {
  return { type: Types.PURCHASEMODALOBJ_CHANGE, purchaseModalObj: purchaseModalObj };
}

export function onProfileObjChange(profileObj) {
  return { type: Types.PROFILEOBJ_CHANGE, profileObj: profileObj };
}

export function onAuthorizationChange(authorization) {
  return { type: Types.AUTHORIZATION_CHANGE, authorization: authorization };
}

export function onSubscriptionChange(subscription) {
  return { type: Types.SUBSCRIPTION_CHANGE, subscription: subscription };
}

export function onThemeChange(theme) {
  return { type: Types.THEME_CHANGE, theme: theme };
}

export function onVideoEditorTimesChange(videoEditorTimes) {
  return { type: Types.VIDEOEDITORTIMES_CHANGE, videoEditorTimes: videoEditorTimes };
}
