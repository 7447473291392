import React from "react";
import { useTranslation } from "react-i18next";
import { Select, Button, Upload } from "antd";
import Utils from "@/utils/utils";
import InputColor from "react-input-color";
import { MergeAddFile } from "./children/mergeAddFile/MergeAddFile";
import { MergeItem } from "./children/mergeItem/MergeItem";
import "@/assets/mergeWork.scss";

const { Option } = Select;
const { Dragger } = Upload;

export const MergeWorkRender = ({
  mergeWorkList,
  currFile,
  color,
  resolution,
  draggerProps,
  recommendFile,
  recommendFileConfirm,
  handleQuickSetColor,
  handleResolutionChange,
  handleSetColor,
  handleMergeItemClick,
  removeMergeWork,
  orderMergeList,
  setCurrFile,
  preSubmit,
  colorList,
  videoBgColor,
}) => {
  const { t } = useTranslation(["mergeWork", "common"]);
  return (
    <div className="MergeWork">
      <div className="fileList">
        <div style={{ position: "relative", zIndex: 1 }}>
          <Dragger {...draggerProps}>
            <div>
              <MergeAddFile />
            </div>
          </Dragger>
          {recommendFile ? (
            <div className="recommendFile" onClick={recommendFileConfirm}>
              <div>
                <p>{t("common:Add Video")}</p>
                <div
                  className="img-box"
                  style={{
                    backgroundImage: `url(${recommendFile.thumbnail})`,
                  }}
                ></div>
              </div>
            </div>
          ) : null}
        </div>
        {mergeWorkList.length > 0
          ? mergeWorkList.map((item, index) => (
              <MergeItem
                key={index}
                mergeWork={item}
                index={index}
                active={currFile && Utils.getIndex(currFile, mergeWorkList) === index}
                handleMergeItemClick={handleMergeItemClick}
                removeMergeWork={removeMergeWork}
                orderMergeList={orderMergeList}
                setCurrFile={setCurrFile}
              />
            ))
          : null}
      </div>
      <hr />
      <div className="footer">
        <div className={!currFile ? "disabled" : ""}>
          <div>{t("Resize for")}</div>
          <Select value={resolution} style={{ width: "100%" }} size="small" onChange={handleResolutionChange}>
            <Option value={1}>1:1</Option>
            <Option value={1.33}>4:3</Option>
            <Option value={1.25}>5:4</Option>
            <Option value={1.77}>16:9</Option>
            <Option value={0.56}>9:16</Option>
          </Select>
          <div className="color-picker">
            <div className="color-picker-form">
              <InputColor initialValue="#000" onChange={handleSetColor} placement="top" />
              {color}
            </div>
            <div
              className={`presetColor ${/.*blur.*/.test(videoBgColor) ? "active" : ""}`}
              onClick={() => {
                handleQuickSetColor("blur");
              }}
            >
              <div
                style={{
                  background: currFile ? `url("${currFile.thumbnail}")` : "#000000",
                  filter: "blur(1px)",
                  overflow: "hidden",
                }}
              >
                {currFile && currFile.thumbnail.startsWith("blob:") ? (
                  <video
                    preload="metadata"
                    src={currFile.thumbnail}
                    className="img"
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  ></video>
                ) : null}
              </div>
            </div>
            {colorList.map(item => (
              <div
                key={item}
                className={`presetColor ${!/.*blur.*/.test(videoBgColor) && color === item ? "active" : ""}`}
                onClick={() => {
                  handleQuickSetColor(item);
                }}
              >
                <div style={{ background: item }}></div>
              </div>
            ))}
          </div>
          <Button type="primary" block={true} onClick={preSubmit} size="large">
            {t("common:Save")}
          </Button>
        </div>
      </div>
    </div>
  );
};
