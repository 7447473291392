export default {
  Start: "시작",
  End: "종료",
  Save: "내보내기",
  Cancel: "취소",
  Download: "다운로드",
  downloading: "다운로드 중",
  "Drag and drop from Files or local disk": "파일 또는 로컬 디스크에서 드래그 앤 드롭합니다.",
  "Click to Add File": "클릭하여 파일을 추가합니다.",
  "The minimum time length is 1s!": "최소 시간 길이는 1초입니다!!",
  "The maximum time length is 50s!": "최대 시간 길이는 50s입니다!",
  "Try Again": "再试一次",
  "Coming Soon": "곧 다가오기",
  "Learn more": "더 알아보기",
  Yes: "네",
  "Please click the Save button to ensure the previous editing to take effect.": "설정 항목을 적용하려면 [저장] 단추를 클릭하십시오.",
  // **************************TipsModal Message Title*****************************
  "Cut 10 video fragments at most. Get HitPaw Toolkit for more features.":
    "비디오 조각을 최대 10개 잘라낼 수 있습니다. HitPaw Toolkit을 통해 더 많은 기능을 확인할 수 있습니다.",
  "Max file size for videos is 20 MB. Get HitPaw Toolkit for big file.":
    "파일 크기는 20MB보다 클 수 없습니다. HitPaw Toolkit을 통해 더 많은 기능을 확인할 수 있습니다.",
  "Max file size for videos is 20 MB. Get HitPaw Watermark Remover for big file.":
    "파일 크기는 20MB보다 클 수 없습니다. HitPaw Watermark Remover을 통해 더 많은 기능을 확인할 수 있습니다.",
  "The minimum time length is 1s! Get HitPaw Toolkit for more features.":
    "최소 시간 길이는 1초입니다! HitPaw Toolkit을 통해 더 많은 기능을 확인할 수 있습니다.",
  "Add 3 videos at most. Get HitPaw Toolkit for more features.":
    "최대 3개 비디오를 추가합니다. HitPaw Toolkit을 통해 더 많은 기능을 확인할 수 있습니다.",
  "This format is not supported currently.": "이 형식은 현재 지원되지 않습니다.",
  "This format is not supported currently. Get HitPaw Toolkit for more formats":
    "이 형식은 현재 지원되지 않습니다. HitPaw Toolkit을 통해 더 많은 기능을 확인할 수 있습니다.",
  "This format is not supported currently. Get HitPaw Watermark Remover for more formats":
    "이 형식은 현재 지원되지 않습니다. HitPaw Watermark Remover을 통해 더 많은 기능을 확인할 수 있습니다.",
  "Processing failed. Get HitPaw Toolkit for stable processing.": "비디오를 생성하지 못했습니다!",
  "Failed to load!  Get HitPaw Watermark Remover for stable processing.": "로딩 실패!",
  "Are you sure you want to delete it?": "삭제하시겠습니까?",
  "Failed to extract the audio!": "오디오를 추출하지 못했습니다!",
  "Video link copied": "비디오 링크가 복사되었습니다.",
  "Cut 10 video fragments at most.": "비디오 조각을 최대 10개 잘라낼 수 있습니다.",
  "Max file size for videos is 20 MB.": "파일 크기는 20Mb를 초과할 수 없습니다.",
  "Max file size for videos is 100 MB.": "파일 크기는 100Mb를 초과할 수 없습니다.",
  "Failed to load!": "로딩 실패!",

  //******************************2021.05.17 v1.3.0 *******************************/
  "Screenshots downloaded.": "캡처하여 다운로드하였습니다.",
  "Online service, no download required": "온라인 서비스입니다.다운로드가 필요 없습니다.",
  "Add up to 20MB file": "파일 최대 20MB",
  "Support common formats": "유행형식 지원",
  "Best for general editing requirements": "일상적인 편집 수요를 해결합니다.",
  "0 second wait": "기다릴 필요 없이",
  "No size limits": "파일 크기 제한이 없이",
  "Support 1000+ formats": "1000여 가지 형식 지원",
  "Stable processing": "안정적 처리",
  "Get access to HitPaw Toolkit!": "HitPaw Toolkit에 액세스하십시오!",
  "Support cut, crop & rotate, adjust, speed, add music, stop motion, etc.": "절단, 자르기 및 회전, 조정, 속도, 음악 추가, 스텁 모션 등 지원",
  "The easiest video editing software for beginners": "초보자를 위한 가장 쉬운 비디오 편집 소프트웨어",
  "Remove watermark from videos and images in simple clicks": "클릭 한번으로 비디오 및 이미지에서 워터마크 제거",
  "TRY IT FREE": "무료 이용",
  "BUY NOW": "지금 구입",

  //******************************2021.09.06 v1.5.0********************************/
  "Add Video": "비디오 추가",
  More: "더보기",
  "History files": "히스토리 파일",
  Share: "공유하기",
  Duration: "기간",
  "Download all": "모두 다운로드",
  "Download selected": "선택된 부분 다운로드",
  "Add Audio": "오디오 추가",

  //********************************2021.10.19 v1.6.0*********************************/
  "GIF does not support editing. Do you want to convert the MP4 for editing?": "GIF는 편집을 지원하지 않습니다. MP4를 편집용으로 변환하시겠습니까?",
  "Go to Edit": "편집하기",
  "Download GIF": "GIF 다운로드",

  //********************************2021.10.19 v1.6.1*********************************/
  "HitPaw Video Editor": "HitPaw Video Editor",
  "No video size limits": "비디오 크기 제한이 없습니다.",
  "Cut/crop/rotate/resize/merge video easily": "비디오를 쉽게 잘라내거나 잘라내거나 회전하거나 크기를 조정하거나 병합할 수 있습니다.",
  "Tons of video effects are available for your video creativity": "비디오 창의성을 위해 수많은 비디오 효과를 사용할 수 있습니다.",
  "Unlimited tracks allow you to add multiple audios as you want": "무제한 트랙을 통해 원하는 대로 여러 오디오를 추가할 수 있습니다.",

  //********************************2021.11.02 v1.6.1*********************************/
  "Download HitPaw Video Editor for more features.": " 자세한 기능을 보려면 HitPaw Video Editor를 다운로드하십시오. ",
  "Get HitPaw Watermark Remover for more features.": "HitPaw Watermark Remover 통해 더 많은 기능을 확인할 수 있습니다.",
  "Get HitPaw Toolkit for more features.": "HitPaw Toolkit을 통해 더 많은 기능을 확인할 수 있습니다.",
  "Add 3 videos at most.": "최대 3개의 비디오를 추가하세요. ",
  "Processing failed.": "처리하지 못했습니다. ",
  "The uploaded video is less than 1 second.": "업로드된 영상은 1초 미만입니다. ",
  "The video format is not supported.": "동영상 형식이 지원되지 않습니다. ",
  "The audio format is not supported.": "오디오 형식이 지원되지 않습니다. ",
  "AddFileByURL.largerSize.HitPawToolkit":
    "영상 해석 성공입니다! 파일이 100MB보다 커서 HitPaw Online에서 편집할 수 없습니다. <1><0>동영상을 다운로드</0></1> 한 다음 HitPaw Toolkit을 사용하여 계속 진행하십시오.",
  "AddFileByURL.largerSize.HitPawWatermarkRemover":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Watermark Remover to proceed.",
  "AddFileByURL.largerSize.HitPawVideoEditor":
    "Analyzing successfully! Your file is larger than 100 MB which cannot be edited by HitPaw Online, please <1><0>download the video</0></1> and then use HitPaw Video Editor to proceed.",
};
