export default {
  Back: "पीछे",
  Cut: "काटें",
  Speed: "गति",
  "Crop & Rotate": "क्रॉप और रोटेट करें",
  Resize: "आकार बदलें",
  Extract: "निकालें",
  "Add Music": "म्यूज़िक डालें",
  Subtitles: "उपशीर्षक",
  Merge: "मिलाएं",
  "Watermark Remover": "वॉटरमार्क रिमूवर",
  "Add Watermark": "वॉटरमार्क जोड़ें",
  "To GIF": "GIF में",
  Convert: "बदलें",
  Screenshot: "स्क्रीनशॉट",
  Reverse: "रिवर्स करें",
  Loop: "लूप करें",
};
