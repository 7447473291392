import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "@/action";
import { request } from "@/api/request";
import { workplaceHOC } from "../WorkplaceHOC";
import ReactGA from "react-ga";
import TipsModal from "@/components/common/TipsModal";
import CropRotateWorkFunction from "./children/CropRotateWorkFunction";
import "@/assets/crop_rotateWork.scss";

class CropRotateWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warningInfo: false,
      warningInfoText: "",
    };
  }

  rotateVideo = () => {
    return {
      type: "rotate",
      rotate: JSON.stringify(this.props.flip === "horizontal" || this.props.flip === "vertical" ? -this.props.rotate : this.props.rotate),
      flip: this.props.flip,
    };
  };

  cropVideo = () => {
    const video = document.querySelector(".videoWrap video");
    let x, y;
    let width, height;
    if (this.props.rotate === 90 || this.props.rotate === -90 || this.props.rotate === -270 || this.props.rotate === 270) {
      width = parseFloat((this.props.crop.width / video.clientHeight) * 100).toFixed(2);
      height = parseFloat((this.props.crop.height / video.clientWidth) * 100).toFixed(2);
      x = parseFloat(((this.props.crop.x - (video.parentNode.clientWidth / 2 - video.clientHeight / 2)) / video.clientHeight) * 100).toFixed(2);
      y = parseFloat(((this.props.crop.y - (video.parentNode.clientHeight / 2 - video.clientWidth / 2)) / video.clientWidth) * 100).toFixed(2);
    } else {
      width = parseFloat((this.props.crop.width / video.clientWidth) * 100).toFixed(2);
      height = parseFloat((this.props.crop.height / video.clientHeight) * 100).toFixed(2);
      x = parseFloat(((this.props.crop.x - (video.parentNode.clientWidth / 2 - video.clientWidth / 2)) / video.clientWidth) * 100).toFixed(2);
      y = parseFloat(((this.props.crop.y - (video.parentNode.clientHeight / 2 - video.clientHeight / 2)) / video.clientHeight) * 100).toFixed(2);
    }
    console.log(width, height, x, y);
    return {
      type: "crop",
      x: Number(x),
      y: Number(y),
      w: Number(width),
      h: Number(height),
    };
  };

  handleSubmit = async () => {
    if (!this.props.uploading && this.props.uploadPercent < 100) {
      this.props.onProcessStepChange(1);
      this.props.onProcessPercentChange(1);
    }
    console.log(JSON.stringify(this.props.crop) + "_" + this.props.rotate);
    const startTime = { ...this.props.startTime };
    startTime.crop_rotate = new Date();
    this.props.onStartTimeChange(startTime);
    let actions = [];
    if (this.props.rotate !== 0 || this.props.flip) {
      actions.push(this.rotateVideo());
      if (this.props.resolution !== "origin") {
        actions.push(this.cropVideo());
      }
    } else {
      actions.push(this.cropVideo());
    }
    console.log(actions);
    let cropRotateResponse;
    try {
      cropRotateResponse = await this.getCropRotateVideo(actions);
    } catch (error) {
      this.props.handleSubmitFailModal(true);
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "Crop&Rotate",
          action: "Crop&RotateFail",
          label: error + "_none_none_" + this.props.tokenId + "_none_none",
        },
        ["OnlineTracker", "Tracker"]
      );
      return false;
    }
    if (cropRotateResponse.code === 200) {
      let guids = [];
      try {
        guids = await this.props.getNewVideoByProgress("crop_rotate", cropRotateResponse);
      } catch (error) {
        if (error !== "cancel") {
          ReactGA.event(
            {
              category: "Crop&Rotate",
              action: "Crop&RotateFail",
              label: "cropRotate_200_200_" + error,
            },
            ["OnlineTracker", "Tracker"]
          );
        }
        return false;
      }
      // const modal = {
      //   getContainer: document.querySelector(".Home"),
      //   show: true,
      //   type: "crop_rotateSuccess",
      //   guid: guids[0],
      //   title: "Generate Files Successfully!",
      //   buttons: [
      //     {
      //       type: "download",
      //       text: "Download",
      //     },
      //     {
      //       type: "editNew",
      //       text: "Edit New Video",
      //     },
      //   ],
      // };
      // this.props.onFileListModalChange(modal);
      this.props.onSuccessFileGuidsChange(guids);
      this.props.onPrevWorkChange("crop_rotate");
      // this.props.onWorkChange(null);
      this.props.onProcessPercentChange(0);
      this.props.onUploadPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);

      ReactGA.event(
        {
          category: "Crop&Rotate",
          action: "Crop&RotateSuccess",
          lable: "1",
        },
        ["OnlineTracker", "Tracker"]
      );

      // ReactGA.event({
      //   category: "Crop&Rotate",
      //   action: "Crop&RotateSuccess",
      // });
    } else {
      if (cropRotateResponse.code === 402102) {
        this.props.onPurchaseModalObjChange({
          visible: true,
          type: "videoEditorTimesMoreThanLimit",
        });
      } else {
        this.props.handleSubmitFailModal(true);
      }
      this.props.onProcessPercentChange(0);
      this.props.onProcessStepChange(0);
      this.props.onSubmittingChange(false);
      ReactGA.event(
        {
          category: "Crop&Rotate",
          action: "Crop&RotateFail",
          label: "cropRotate_200_" + cropRotateResponse.code + "_none_" + this.props.tokenId + "_none_" + cropRotateResponse.msg,
        },
        ["OnlineTracker", "Tracker"]
      );
    }
  };

  getCropRotateVideo = actions => {
    const formData = new FormData();
    const actionsData = { actions };
    formData.append("add_action[]", JSON.stringify(actionsData));
    formData.append("video_url", this.props.currFile.videofileurl);
    formData.append("token", this.props.tokenId);
    return new Promise((resolve, reject) => {
      request("cropRotate", {
        method: "post",
        body: formData,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  handleRotateChange = value => {
    this.props.onUserActionChange(true);
    let rotate = this.props.rotate;
    if (value === "left") {
      rotate = rotate - 90;
      if (rotate <= -270) {
        rotate = 90;
      }
      this.props.onRotateChange(rotate);
    }
    if (value === "right") {
      rotate = rotate + 90;
      if (rotate >= 270) {
        rotate = -90;
      }
      this.props.onRotateChange(rotate);
    }
  };

  handleFlipChange = value => {
    this.props.onUserActionChange(true);
    if (value === "horizontal") {
      if (this.props.flip === "horizontal") {
        this.props.onFlipChange("");
        this.props.onRotateChange(-this.props.rotate);
      } else if (this.props.flip === "vertical") {
        this.props.onFlipChange("");
        if (this.props.rotate === 0) {
          this.props.onRotateChange(180);
        }
        if (this.props.rotate === 180 || this.props.rotate === -180) {
          this.props.onRotateChange(0);
        }
      } else {
        this.props.onFlipChange(value);
        this.props.onRotateChange(-this.props.rotate);
      }
    }
    if (value === "vertical") {
      if (this.props.flip === "vertical") {
        this.props.onFlipChange("");
        this.props.onRotateChange(-this.props.rotate);
      } else if (this.props.flip === "horizontal") {
        this.props.onFlipChange("");
        if (this.props.rotate === 0) {
          this.props.onRotateChange(180);
        }
        if (this.props.rotate === 180 || this.props.rotate === -180) {
          this.props.onRotateChange(0);
        }
      } else {
        this.props.onFlipChange(value);
        this.props.onRotateChange(-this.props.rotate);
      }
    }
  };

  preSubmit = () => {
    ReactGA.event(
      {
        category: "Crop&Rotate",
        action: "Crop&RotateSave",
        label: this.props.resolution + "_" + this.props.rotate + "_" + this.props.flip,
      },
      ["OnlineTracker", "Tracker"]
    );
    //函数返回true，则是当前已经超过限制
    let flag = this.props.handleVideoEditorTimes();
    if (!flag) {
      this.props.preSubmit(1, this.handleSubmit);
    }
  };

  componentDidMount() {
    this.props.onResolutionChange("custom");
    // console.log("CCCCCCCCCCCCcccccc初始化了");
  }

  componentDidUpdate(prevProps) {
    if (this.props.currFile !== prevProps.currFile) {
      this.props.onRotateChange(0);
      this.props.onResolutionChange("custom");
      this.props.onFlipChange("");
    }
  }

  render() {
    return (
      <div style={{ height: "100%" }}>
        <CropRotateWorkFunction
          currFile={this.props.currFile}
          resolution={this.props.resolution}
          crop={this.props.crop}
          rotate={this.props.rotate}
          onResolutionChange={this.props.onResolutionChange}
          onCropChange={this.props.onCropChange}
          handleRotateChange={this.handleRotateChange}
          handleFlipChange={this.handleFlipChange}
          preSubmit={this.preSubmit}
        />
        <TipsModal
          showGuidText
          visible={this.state.warningInfo}
          content={this.state.warningInfoText}
          onCancel={() => {
            this.setState({ warningInfo: false });
          }}
          confirmBtnHandle={() => {
            this.setState({ warningInfo: false });
          }}
          confirmBtnText="OK"
        />
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  tokenId: state.common.tokenId,
  work: state.work.work,
  startTime: state.work.startTime,
  crop: state.work.crop,
  rotate: state.work.rotate,
  resolution: state.work.resolution,
  flip: state.work.flip,
  uploading: state.work.uploading,
  processStep: state.work.processStep,
  processPercent: state.work.processPercent,
  cancelProgress: state.work.cancelProgress,
  submitting: state.work.submitting,
  uploadPercent: state.work.uploadPercent,
});
const mapDispatchToProps = dispatch => ({
  onFilesChange: files => dispatch(actions.onFilesChange(files)),
  onFileListModalChange: fileListModal => dispatch(actions.onFileListModalChange(fileListModal)),
  onProcessPercentChange: processPercent => dispatch(actions.onProcessPercentChange(processPercent)),
  onProcessStepChange: processStep => dispatch(actions.onProcessStepChange(processStep)),
  onStartTimeChange: startTime => dispatch(actions.onStartTimeChange(startTime)),
  onRotateChange: rotate => dispatch(actions.onRotateChange(rotate)),
  onResolutionChange: resolution => dispatch(actions.onResolutionChange(resolution)),
  onCropChange: crop => dispatch(actions.onCropChange(crop)),
  onFlipChange: flip => dispatch(actions.onFlipChange(flip)),
  onSubmittingChange: submitting => dispatch(actions.onSubmittingChange(submitting)),
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
  onPreUploadFileChange: preUploadFile => dispatch(actions.onPreUploadFileChange(preUploadFile)),
  onUploadAgainFileChange: uploadAgainFile => dispatch(actions.onUploadAgainFileChange(uploadAgainFile)),
  onUploadPercentChange: uploadPercent => dispatch(actions.onUploadPercentChange(uploadPercent)),
  onUserActionChange: userAction => dispatch(actions.onUserActionChange(userAction)),
  onWorkChange: work => dispatch(actions.onWorkChange(work)),
  onPrevWorkChange: prevWork => dispatch(actions.onPrevWorkChange(prevWork)),
  onSuccessFileGuidsChange: successFileGuids => dispatch(actions.onSuccessFileGuidsChange(successFileGuids)),
  onPurchaseModalObjChange: purchaseModal => dispatch(actions.onPurchaseModalObjChange(purchaseModal)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(workplaceHOC(CropRotateWork));
