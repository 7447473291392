import React, { Component } from "react";
import moment from "moment";
import Utils from "@/utils/utils";
import { AddMusicPreviewRender } from "./children/AddMusicPreviewRender";

export default class AddMusicPreviewFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soundSlide: false,
      playing: false,
      currentTime: "00:00.00",
      currentTimePercent: 0,
      currentVolume: 0,
      miniLengthLimit: false,
      maxLengthLimit: false,
      videoReady: false,
      // audio: null,
      audioPaused: false,
      flag: false,
    };
  }

  handleSoundClick = () => {
    const myvideo = document.querySelector("#videoRef video");
    this.setState({
      currentVolume: myvideo.volume * 100,
      soundSlide: !this.state.soundSlide,
    });
  };

  // soundTipFormatter = (value)

  handleSoundDrag = value => {
    const myvideo = document.querySelector("#videoRef video");
    myvideo.volume = value / 100;
    this.setState({ currentVolume: myvideo.volume * 100 });
    return value;
  };

  getAudioCurrentTime = (myvideo, myaudio) => {
    const { addMusicAudioTimeObj } = this.props;
    if (myvideo.currentTime === 0 && addMusicAudioTimeObj.startTime && myaudio) {
      myaudio.currentTime = Utils.getSeconds(addMusicAudioTimeObj.startTime);
    }
  };

  handleAudio = (myvideo, myaudio) => {
    const { addMusicAudioStartPlayTime, addMusicAudioTimeObj } = this.props;
    const { audioPaused } = this.state;
    if (!myaudio) {
      return false;
    }
    let audioDuration = myaudio.duration;
    if (addMusicAudioTimeObj.startTime && addMusicAudioTimeObj.endTime) {
      audioDuration = Utils.getSeconds(addMusicAudioTimeObj.endTime) - Utils.getSeconds(addMusicAudioTimeObj.startTime);
    }
    if (addMusicAudioStartPlayTime) {
      if (
        myvideo.currentTime >= Utils.getSeconds(addMusicAudioStartPlayTime) &&
        myaudio.paused &&
        !myvideo.paused &&
        myvideo.currentTime < Utils.getSeconds(addMusicAudioStartPlayTime) + audioDuration &&
        !audioPaused
      ) {
        myaudio.play();
      } else if (!myaudio.paused && myvideo.currentTime >= Utils.getSeconds(addMusicAudioStartPlayTime) + audioDuration) {
        myaudio.pause();
        myaudio.currentTime = addMusicAudioTimeObj.startTime ? Utils.getSeconds(addMusicAudioTimeObj.startTime) : 0;
      }
    } else if (myaudio.paused && myvideo.currentTime <= audioDuration && !audioPaused && !myvideo.paused) {
      myaudio.play();
    } else if (!myaudio.paused && myvideo.currentTime >= audioDuration) {
      myaudio.pause();
      myaudio.currentTime = addMusicAudioTimeObj.startTime ? Utils.getSeconds(addMusicAudioTimeObj.startTime) : 0;
    }
  };

  handlePlayClick = () => {
    const myvideo = document.querySelector("#videoRef video");
    const myaudio = document.querySelector("#audioBox audio");
    // const { audio } = this.state
    if (this.state.playing) {
      myvideo.pause();
      if (myaudio && !myaudio.paused) {
        myaudio.pause();
      }
    } else {
      this.getAudioCurrentTime(myvideo);
      myvideo.play();
      window.requestAnimationFrame(this._getCurrentTime);
    }
    this.setState({ playing: !this.state.playing });
  };

  _getCurrentTime = () => {
    // const { audio } = this.state
    const myvideo = document.querySelector("#videoRef video");
    const myaudio = document.querySelector("#audioBox audio");
    if (!this.state.playing) {
      return false;
    }

    if (myvideo.paused) {
      if (myaudio && !myaudio.paused) {
        myaudio.pause();
      }
      if (myvideo.paused && this.state.playing) {
        this.setState({ playing: false });
      }
    }
    this.handleAudio(myvideo, myaudio);
    this.setState({
      currentTime: moment("00:00:00", "mm:ss:SS")
        .add(myvideo.currentTime, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1"),
      currentTimePercent: (myvideo.currentTime / myvideo.duration) * 100,
    });
    if (myvideo.currentTime < myvideo.duration) {
      window.requestAnimationFrame(this._getCurrentTime);
    } else {
      if (myaudio && !myaudio.paused) {
        myaudio.pause();
      }
      this.setState({
        playing: false,
        currentTimePercent: 0,
        currentTime: "00:00:00",
      });
      myvideo.currentTime = 0;
      if (myaudio) {
        myaudio.currentTime = this.props.addMusicAudioTimeObj.startTime ? Utils.getSeconds(this.props.addMusicAudioTimeObj.startTime) : 0;
      }
    }
  };

  handleCurrentTimeBlur = () => {
    const { addMusicAudioStartPlayTime, addMusicAudioTimeObj } = this.props;
    const myvideo = document.querySelector("#videoRef video");
    const myaudio = document.querySelector("#audioBox audio");
    if (!myaudio) {
      return false;
    }
    let audioDuration = myaudio.duration;
    if (addMusicAudioTimeObj.startTime && addMusicAudioTimeObj.endTime) {
      audioDuration = Utils.getSeconds(addMusicAudioTimeObj.endTime) - Utils.getSeconds(addMusicAudioTimeObj.startTime);
    }
    if (addMusicAudioStartPlayTime) {
      if (addMusicAudioTimeObj.startTime) {
        const currentTime = myvideo.currentTime - Utils.getSeconds(addMusicAudioStartPlayTime) + Utils.getSeconds(addMusicAudioTimeObj.startTime);
        myaudio.currentTime = currentTime <= audioDuration ? currentTime : Utils.getSeconds(addMusicAudioTimeObj.startTime);
      } else {
        const currentTime = myvideo.currentTime - Utils.getSeconds(addMusicAudioStartPlayTime);
        myaudio.currentTime = currentTime <= audioDuration ? currentTime : 0;
      }
    } else {
      if (addMusicAudioTimeObj.startTime) {
        const currentTime = myvideo.currentTime + Utils.getSeconds(addMusicAudioTimeObj.startTime);
        myaudio.currentTime = currentTime <= audioDuration ? currentTime : Utils.getSeconds(addMusicAudioTimeObj.startTime);
      } else {
        myaudio.currentTime = myvideo.currentTime <= audioDuration ? myvideo.currentTime : 0;
      }
    }
    this.setState({ audioPaused: false });
  };

  handleCurrentTimeChange = value => {
    const myaudio = document.querySelector("#audioBox audio");
    if (myaudio) {
      myaudio.pause();
      this.setState({ audioPaused: true });
    }
    const myvideo = document.querySelector("#videoRef video");
    myvideo.currentTime = myvideo.duration * (value / 100);
    this.setState({
      currentTime: moment("00:00:00", "mm:ss:SS")
        .add(myvideo.currentTime, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1"),
      currentTimePercent: value,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.currFile !== this.props.currFile) {
      if (this.props.currFile) {
        const myvideo = document.querySelector("#videoRef video");
        const myaudio = document.querySelector("#audioBox audio");
        myvideo.volume = this.props.addMusicAudioVolumeObj.video || 0.5;
        myvideo.load();
        myaudio.pause();
        this.handleCurrentTimeBlur();
      }
      this.setState({
        soundSlide: false,
        playing: false,
        currentTime: "00:00:00",
        currentTimePercent: 0,
        currentVolume: 0,
        miniLengthLimit: false,
        maxLengthLimit: false,
      });
    }
    if (prevProps.addMusicWorkObj !== this.props.addMusicWorkObj) {
      const myaudio = document.querySelector("#audioBox audio");
      myaudio.load(); //初始化audio标签
      myaudio.volume = this.props.addMusicAudioVolumeObj.audio || 0.5;
      if (this.props.addMusicWorkObj.audiofileurl) {
        setTimeout(() => this.handleCurrentTimeBlur(), 500);
      }
    }
    if (prevProps.addMusicAudioTimeObj !== this.props.addMusicAudioTimeObj) {
      this.handleCurrentTimeBlur();
    }
    if (prevProps.addMusicAudioVolumeObj.audio !== this.props.addMusicAudioVolumeObj.audio) {
      const myaudio = document.querySelector("#audioBox audio");
      myaudio.volume = this.props.addMusicAudioVolumeObj.audio;
      this.setState({ flag: !this.state.flag });
    }
    if (prevProps.addMusicAudioVolumeObj.video !== this.props.addMusicAudioVolumeObj.video) {
      const myvideo = document.querySelector("#videoRef video");
      this.setState({ currentVolume: myvideo.volume * 100 });
    }
    if (prevProps.addMusicAudioStartPlayTime !== this.props.addMusicAudioStartPlayTime) {
      this.handleCurrentTimeBlur();
    }

    if (this.props.submitting && !prevProps.submitting) {
      const myvideo = document.querySelector("#videoRef video");
      const myaudio = document.querySelector("#audioBox audio");
      if (this.state.playing) {
        myvideo.pause();
        if (myaudio && !myaudio.paused) {
          myaudio.pause();
        }
        this.setState({ playing: false });
      }
    }
  }

  render() {
    return (
      <AddMusicPreviewRender
        videoReady={this.props.videoReady}
        currFile={this.props.currFile}
        currentTime={this.state.currentTime}
        currentTimePercent={this.state.currentTimePercent}
        currentVolume={this.state.currentVolume}
        handleSoundClick={this.handleSoundClick}
        handleSoundDrag={this.handleSoundDrag}
        handlePlayClick={this.handlePlayClick}
        handleCurrentTimeBlur={this.handleCurrentTimeBlur}
        handleCurrentTimeChange={this.handleCurrentTimeChange}
        playbackRate={this.props.playbackRate}
        soundSlide={this.state.soundSlide}
        playing={this.state.playing}
        screenshots={Utils.screenshots}
        isTimeRangeVisible={this.props.isTimeRangeVisible}
      />
    );
  }
}
