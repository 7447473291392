export default {
  'Files': 'Dosyalar',
  'Refresh': 'Yenile',
  'Original file': 'Orijinal dosya',
  'Just now': 'Şu anda',
  'hour ago': '{{num}} saat önce',
  'hours ago': '{{num}} saat önce',
  'minute ago': '{{num}} dakika önce',
  'minutes ago': '{{num}} dakika önce',
  'day ago': '{{num}} gün önce',
  'View All': 'Tümünü Görüntüle',
  'No files': 'Dosya yok...',
  "Don't forget to download your file, it will be deleted after 24 hours": 'Dosyanızı indirmeyi unutmayın, 24 saat sonra silinecektir.',
  'Select All': 'Tümünü Seç',
  'Delete': 'Sil',
  'Generate Files Successfully!': 'Dosyaları Başarıyla Oluşturun!',
  'Continue Editing': 'Düzenlemeye Devam Et',
  'Edit New Video': 'Yeni Videoyu Düzenle',
  'Download': 'İndir',
  'Click here to open the Files again':'Dosyaları yeniden açmak için buraya tıklayın.',
  'Processing... Please wait': 'İşleniyor... Lütfen bekleyin',
  'Uploading... Please wait': 'Yükleniyor... Lütfen bekleyin',
  'Edit Now': 'Şimdi Düzenle',
  'Back to Edit':'Düzenlemeye Geri Dön',
  'Go Edit':'Düzenleme gidin'

}