export default {
  'Image': 'Bild',
  'Text': 'Text',
  'Add image': 'Bild hinzufügen',
  'Opacity': 'Opazität',
  "Add text": 'Text hinzufügen',
  'Text Color': 'Textfarbe',
  'Outline': 'Außenhaut',
  'Background': 'Hintergrund',
  'Text here': 'Text hier',
}