import React from "react";
import { useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";

export const MergeAddFile = () => {
  const [, drop] = useDrop({
    accept: "FileListItem",
    drop: () => ({ name: "MergeAddFile" }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: item => {
      if ((item.file && item.file.audiofileurl) || (item.file && item.file.status === "gif")) {
        return false;
      }
      return true;
    },
  });

  const { t } = useTranslation(["common"]);

  return (
    <div ref={drop} className="add">
      <div className="add-btn">
        <PlusOutlined />
        {t("Add Video")}
      </div>
      <p>{t("Drag and drop from Files or local disk")}</p>
    </div>
  );
};
