import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "@/action";
import { useDrop } from "react-dnd";
import { Breadcrumb, Slider, Tooltip } from "antd";
import { ReactComponent as Pause } from "@/assets/icons/pause.svg";
import { ReactComponent as Play } from "@/assets/icons/play.svg";
import { Stage, Layer, Text, Label, Tag } from "react-konva";
import { ReactComponent as Loading } from "@/assets/images/timg.svg";
import { ReactComponent as Screenshot } from "@/assets/icons/camera.svg";
import { ReactComponent as Close } from "@/assets/icons/close-circle.svg";
import moment from "moment";
import Utils from "@/utils/utils";

const VideoWrap = ({
  currFile,
  subtitleWorkList,
  videoReady,
  canvasClient,
  onDragEnd,
  handleSubtitleShow,
  videoScale,
  layerRef,
  onCurrFileChange,
  onSubmittingChange,
}) => {
  const [, drop] = useDrop({
    accept: "FileListItem",
    drop: () => ({ name: "CutVideoWrap" }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: item => {
      if (item.file && item.file.audiofileurl) {
        return false;
      }
      return true;
    },
  });
  return (
    <div ref={drop} className="videoWrap" id="videoRef">
      <div
        style={{
          width: "fit-content",
          height: "fit-content",
          margin: "auto",
          position: "relative",
        }}
      >
        <button
          className="close"
          onClick={() => {
            onCurrFileChange(null);
            onSubmittingChange(false);
          }}
        >
          <Close />
        </button>
        <video className="active" crossOrigin="Anonymous">
          <source src={currFile.videofileurl} type="video/mp4" />
        </video>
        <div className="Draggable">
          {videoReady && subtitleWorkList.length ? (
            <Stage width={canvasClient.w} height={canvasClient.h} scale={{ x: 1 / videoScale.x, y: 1 / videoScale.y }} id="subtitleCanvas">
              <Layer ref={layerRef}>
                {subtitleWorkList.map((item, index) => (
                  <Label
                    key={index}
                    draggable
                    x={item.position.x}
                    y={item.position.y}
                    onDragEnd={e => onDragEnd(e, index)}
                    onMouseEnter={() => {
                      if (layerRef.current) {
                        layerRef.current.parent.container().style.cursor = "move";
                      }
                    }}
                    onMouseLeave={() => {
                      if (layerRef.current) {
                        layerRef.current.parent.container().style.cursor = "default";
                      }
                    }}
                    opacity={handleSubtitleShow(item) ? 1 : 0}
                    listening={true}
                  >
                    <Tag fill={item.background === "none" ? null : item.background} />
                    <Text
                      text={item.subtitle}
                      fill={item.color}
                      fontSize={item.fontSize}
                      fontFamily={item.fontFamily ? item.fontFamily : null}
                      // padding={item.background !== 'none' ? 8 : 0}
                      padding={8}
                      stroke={item.border === "none" ? null : item.border}
                      strokeWidth={0.5}
                      // ref={}
                    />
                  </Label>
                ))}
              </Layer>
            </Stage>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

class SubtitlePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soundSlide: false,
      playing: false,
      currentTime: "00:00:00",
      currentTimePercent: 0,
      currentVolume: 0,
      cacheCutTime: [],
      miniLengthLimit: false,
      maxLengthLimit: false,
      videoReady: false,
      subtitleDragPosition: {
        x: 0,
        y: 0,
      },
      videoScale: {
        x: 1,
        y: 1,
      },
      canvasClient: {
        w: 0,
        h: 0,
      },
      initStage: true, //是否初始化画布
    };
    this.layerRef = React.createRef();
  }

  handleSoundClick = () => {
    const myvideo = document.querySelector("#videoRef video");
    this.setState({
      currentVolume: myvideo.volume * 100,
      soundSlide: !this.state.soundSlide,
    });
  };

  handleSoundDrag = value => {
    const myvideo = document.querySelector("#videoRef video");
    myvideo.volume = value / 100;
    return value;
  };

  handlePlayClick = () => {
    const list = [...this.props.subtitleWorkList];
    list.forEach(item => (item.selected = false));
    this.props.onSubtitleWorkListChange(list);
    const myvideo = document.querySelector("#videoRef video");
    if (this.state.playing) {
      myvideo.pause();
    } else {
      myvideo.play();
      window.requestAnimationFrame(this._getCurrentTime);
    }
    this.setState({ playing: !this.state.playing });
  };

  _getCurrentTime = () => {
    if (!this.state.playing) {
      return false;
    }
    const myvideo = document.querySelector("#videoRef video");
    this.setState({
      currentTime: moment("00:00:00", "mm:ss:SS")
        .add(myvideo.currentTime, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1"),
      currentTimePercent: (myvideo.currentTime / myvideo.duration) * 100,
    });
    if (myvideo.currentTime < myvideo.duration) {
      window.requestAnimationFrame(this._getCurrentTime);
    } else {
      if (!myvideo.paused) {
        myvideo.pause();
      }
      this.setState({
        playing: false,
        currentTimePercent: 0,
        currentTime: "00:00:00",
      });
      myvideo.currentTime = 0;
    }
  };

  handleCurrentTimeChange = value => {
    const myvideo = document.querySelector("#videoRef video");
    myvideo.currentTime = myvideo.duration * (value / 100);
    this.setState({
      currentTime: moment("00:00:00", "mm:ss:SS")
        .add(myvideo.currentTime, "seconds")
        .format("mm:ss:SS")
        .replace(/:([^:]*)$/, ".$1"),
      currentTimePercent: value,
    });
  };

  handleRangeChange = value => {
    if (this.state.playing) {
      this.handlePlayClick();
    }
    const d = Utils.getSeconds(this.props.currFile.duration);
    if ((d * value[1]) / 100 - (d * value[0]) / 100 <= 1) {
      this.setState({ miniLengthLimit: true });
      return false;
    }
    if ((d * value[1]) / 100 - (d * value[0]) / 100 > 51 && this.props.work === "gif") {
      this.setState({ maxLengthLimit: true });
      return false;
    }
    this.setState({ miniLengthLimit: false });
    this.setState({ maxLengthLimit: false });

    const start = moment("00:00:00", "mm:ss:SS")
      .add((d * value[0]) / 100, "seconds")
      .format("mm:ss:SS")
      .replace(/:([^:]*)$/, ".$1");
    const end = moment("00:00:00", "mm:ss:SS")
      .add((d * value[1]) / 100, "seconds")
      .format("mm:ss:SS")
      .replace(/:([^:]*)$/, ".$1");
    const subtitleWorkList = [...this.props.subtitleWorkList];
    let cutWork = subtitleWorkList.filter(item => item.selected)[0];
    cutWork.start = start;
    cutWork.end = end;
    this.props.onSubtitleWorkListChange(subtitleWorkList);
  };

  getCutWorkPercent = value => {
    const d = Utils.getSeconds(this.props.currFile.duration);
    const t = Utils.getSeconds(value);
    return (t / d) * 100;
  };

  getVideoSize = () => {
    const Preview = document.querySelector(".Preview");
    const video = document.querySelector(".videoWrap video");
    const draggable = document.querySelector(".Draggable");
    const callback = () => {
      const videoClientRect = video.getBoundingClientRect();
      draggable.style.width = videoClientRect.width + "px";
      draggable.style.height = videoClientRect.height + "px";
      let videoScale = {
        x: video.videoWidth / videoClientRect.width,
        y: video.videoHeight / videoClientRect.height,
      };
      this.setState(
        {
          videoReady: true,
          videoScale,
          canvasClient: {
            w: videoClientRect.width,
            h: videoClientRect.height,
          },
        },
        () => {
          const controls = document.querySelector(".controls");
          controls.style.width = videoClientRect.width + "px";
        }
      );
    };
    Utils.setVideoContainerSize(Preview, video, callback);
  };

  handleSubtitleDrag = (e, iu, index) => {
    const video = document.querySelector(".videoWrap video");
    video.pause();
    this.setState({ playing: false });
    const draggable = document.querySelector(".Draggable");
    const draggableClient = draggable.getBoundingClientRect();
    const subtitleClient = iu.node.getBoundingClientRect();
    const limitX = draggableClient.width - subtitleClient.width;
    const limitY = draggableClient.height - subtitleClient.height;
    const list = [...this.props.subtitleWorkList];
    let position = {};
    if (iu.x < 0) {
      position.x = 0;
    } else if (iu.x > limitX) {
      position.x = limitX;
    } else {
      position.x = iu.x;
    }
    if (iu.y < 0) {
      position.y = 0;
    } else if (iu.y > limitY) {
      position.y = limitY;
    } else {
      position.y = iu.y;
    }
    list[index].position = position;
    list.forEach(item => (item.selected = false));
    list[index].selected = true;
    this.props.onSubtitleWorkListChange(list);
    this.setState({ subtitleDragPosition: position });
  };

  onDragEnd = (e, index) => {
    const video = document.querySelector(".videoWrap video");
    video.pause();
    this.setState({ playing: false });
    const x = e.target.x();
    const y = e.target.y();
    const list = [...this.props.subtitleWorkList];
    let position = { x, y };
    list[index].position = position;
    list.forEach(item => (item.selected = false));
    list[index].selected = true;
    this.props.onSubtitleWorkListChange(list);
    this.setState({ subtitleDragPosition: position });
  };

  handleSubtitleShow = subtitleWorkObj => {
    const { currentTime } = this.state;
    if (
      Utils.getSeconds(subtitleWorkObj.start) <= Utils.getSeconds(currentTime) &&
      Utils.getSeconds(subtitleWorkObj.end) >= Utils.getSeconds(currentTime)
    ) {
      return true;
    }
    return false;
  };

  getQuicklyPostionData = (currRef, quicklyPosition) => {
    const video = document.querySelector(".videoWrap video");
    quicklyPosition = quicklyPosition ? quicklyPosition : this.props.quicklyPosition;
    // const { quicklyPosition } = this.props;
    let positionData = { x: 0, y: 0 };
    switch (quicklyPosition.position) {
      case "top-left":
        positionData.x = 0;
        positionData.y = 0;
        break;
      case "top-center":
        positionData.x = video.videoWidth / 2 - currRef.getWidth() / 2;
        positionData.y = 0;
        break;
      case "top-right":
        positionData.x = video.videoWidth - currRef.getWidth();
        positionData.y = 0;
        break;
      case "middle-left":
        positionData.x = 0;
        positionData.y = video.videoHeight / 2 - currRef.getHeight() / 2;
        break;
      case "middle-center":
        positionData.x = video.videoWidth / 2 - currRef.getWidth() / 2;
        positionData.y = video.videoHeight / 2 - currRef.getHeight() / 2;
        break;
      case "middle-right":
        positionData.x = video.videoWidth - currRef.getWidth();
        positionData.y = video.videoHeight / 2 - currRef.getHeight() / 2;
        break;
      case "bottom-left":
        positionData.x = 0;
        positionData.y = video.videoHeight - currRef.getHeight();
        break;
      case "bottom-center":
        positionData.x = video.videoWidth / 2 - currRef.getWidth() / 2;
        positionData.y = video.videoHeight - currRef.getHeight();
        break;
      case "bottom-right":
        positionData.x = video.videoWidth - currRef.getWidth();
        positionData.y = video.videoHeight - currRef.getHeight();
        break;
      default:
        positionData.x = 0;
        positionData.y = 0;
        break;
    }
    return positionData;
  };

  handleSetPosition = () => {
    const { subtitleWorkList } = this.props;
    const list = [...subtitleWorkList];
    if (this.layerRef.current) {
      list.forEach((item, index) => {
        const position = this.getQuicklyPostionData(this.layerRef.current.children[index]);
        item.position = position;
      });
    }
    this.props.onSubtitleWorkListChange(list);
  };

  componentDidMount() {
    this.getVideoSize();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.quicklyPosition !== this.props.quicklyPosition) {
      this.handleSetPosition();
    }
    if (prevProps.currFile !== this.props.currFile) {
      this.getVideoSize();
      this.setState({
        soundSlide: false,
        playing: false,
        currentTime: "00:00:00",
        currentTimePercent: 0,
        currentVolume: 0,
        cacheCutTime: [],
        miniLengthLimit: false,
        maxLengthLimit: false,
        videoReady: false,
      });
    }

    if (this.layerRef.current && this.props.subtitleWorkList.some(item => item.positionInitFlag)) {
      const subtitleWorkList = [...this.props.subtitleWorkList];
      subtitleWorkList.forEach((item, i) => {
        if (item.positionInitFlag) {
          const position = this.getQuicklyPostionData(this.layerRef.current.children[i], { position: "bottom-center" });
          item.position = position;
          item.positionInitFlag = false;
        }
      });
      this.props.onSubtitleWorkListChange(subtitleWorkList);
    }

    if (this.props.subtitleWorkList !== prevProps.subtitleWorkList && this.props.subtitleWorkList.some(item => item.selected)) {
      const myvideo = document.querySelector("#videoRef video");
      myvideo.pause();
      this.setState({ playing: false });
      const cutWork = this.props.subtitleWorkList.filter(item => item.selected)[0];
      if (cutWork.start === this.state.cacheCutTime[0] && cutWork.end !== this.state.cacheCutTime[1]) {
        myvideo.currentTime = Utils.getSeconds(this.props.subtitleWorkList.filter(item => item.selected)[0].end);
        this.setState({
          currentTime: this.props.subtitleWorkList.filter(item => item.selected)[0].end,
          currentTimePercent: this.getCutWorkPercent(this.props.subtitleWorkList.filter(item => item.selected)[0].end),
        });
      } else {
        myvideo.currentTime = Utils.getSeconds(this.props.subtitleWorkList.filter(item => item.selected)[0].start);
        this.setState({
          currentTime: this.props.subtitleWorkList.filter(item => item.selected)[0].start,
          currentTimePercent: this.getCutWorkPercent(this.props.subtitleWorkList.filter(item => item.selected)[0].start),
        });
      }
      this.setState({
        cacheCutTime: [
          this.props.subtitleWorkList.filter(item => item.selected)[0].start,
          this.props.subtitleWorkList.filter(item => item.selected)[0].end,
        ],
      });
    }
    if (this.props.submitting && !prevProps.submitting) {
      const myvideo = document.querySelector("#videoRef video");
      if (this.state.playing) {
        myvideo.pause();
        this.setState({ playing: false });
      }
    }
  }

  render() {
    return (
      <div className="CutPreview SubtitlePreview">
        <VideoWrap
          currFile={this.props.currFile}
          subtitleWorkList={this.props.subtitleWorkList}
          videoReady={this.state.videoReady}
          handleSubtitleShow={this.handleSubtitleShow}
          handleSubtitleDrag={this.handleSubtitleDrag}
          subtitleDragPosition={this.state.subtitleDragPosition}
          videoScale={this.state.videoScale}
          onDragEnd={this.onDragEnd}
          canvasClient={this.state.canvasClient}
          layerRef={this.layerRef}
          onCurrFileChange={this.props.onCurrFileChange}
          onSubmittingChange={this.props.onSubmittingChange}
        />
        {this.state.videoReady ? (
          <div>
            <div className="controls">
              <div className="controls-lft">
                {this.state.playing ? <Pause onClick={this.handlePlayClick} /> : <Play onClick={this.handlePlayClick} />}
              </div>
              <div className="controls-rgt">
                <Breadcrumb>
                  <Breadcrumb.Item className="current-time">{this.state.currentTime}</Breadcrumb.Item>
                  <Breadcrumb.Item>{this.props.currFile.duration}</Breadcrumb.Item>
                </Breadcrumb>
                <Tooltip placement="top" title={window.i18n.t("navbar:Screenshot")}>
                  <Screenshot className="screenshot-icon" onClick={() => Utils.screenshots()} />
                </Tooltip>
              </div>
            </div>

            <div className="track">
              <div className="screenshots">
                {this.props.currFile.screenshots.map((item, index) => (
                  // <div className="screenshot" key={index} style={{width: parseInt((document.querySelector('.PreviewContent').clientWidth - 40)/this.props.currFile.screenshots.length)+'px'}}>
                  //   <div className="img-box" style={{backgroundImage: `url(${item})`}}></div>
                  // </div>
                  <div
                    className="screenshot"
                    key={index}
                    style={{
                      width: parseInt((document.querySelector(".PreviewContent").clientWidth - 40) / this.props.currFile.screenshots.length) + "px",
                    }}
                  >
                    {item.startsWith("blob:") ? (
                      <video
                        preload="metadata"
                        src={item}
                        className="loaded"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      ></video>
                    ) : (
                      <div className="img-box" style={{ backgroundImage: `url(${item})` }}></div>
                    )}
                  </div>
                ))}
              </div>
              <div className="frame">
                <Slider value={this.state.currentTimePercent} tipFormatter={null} onChange={this.handleCurrentTimeChange} />
              </div>
              {this.props.subtitleWorkList.length > 0 && !this.state.playing && this.props.subtitleWorkList.some(item => item.selected) ? (
                <div className="range">
                  <div
                    className="cover"
                    style={{
                      left: 0,
                      width: this.getCutWorkPercent(this.props.subtitleWorkList.filter(item => item.selected)[0].start) + "%",
                    }}
                  ></div>
                  <div
                    className="cover"
                    style={{
                      left: this.getCutWorkPercent(this.props.subtitleWorkList.filter(item => item.selected)[0].end) + "%",
                      width: 100 - this.getCutWorkPercent(this.props.subtitleWorkList.filter(item => item.selected)[0].end) + "%",
                    }}
                  ></div>
                  <div
                    className="include"
                    style={{
                      left: Math.round(this.getCutWorkPercent(this.props.subtitleWorkList.filter(item => item.selected)[0].start)) + "%",
                      width:
                        Math.round(this.getCutWorkPercent(this.props.subtitleWorkList.filter(item => item.selected)[0].end)) -
                        Math.round(this.getCutWorkPercent(this.props.subtitleWorkList.filter(item => item.selected)[0].start)) +
                        "%",
                    }}
                  ></div>
                  <Slider
                    range
                    value={[
                      this.getCutWorkPercent(this.props.subtitleWorkList.filter(item => item.selected)[0].start),
                      this.getCutWorkPercent(this.props.subtitleWorkList.filter(item => item.selected)[0].end),
                    ]}
                    tipFormatter={null}
                    onChange={this.handleRangeChange}
                  />
                  {this.state.miniLengthLimit ? (
                    <div className="miniLengthLimit">{window.i18n.t("common:The minimum time length is 1s!")}</div>
                  ) : null}
                  {this.state.maxLengthLimit ? (
                    <div className="miniLengthLimit">{window.i18n.t("common:The maximum time length is 50s!")}</div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Loading />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToPropos = state => ({
  files: state.files.files,
  currFile: state.files.currFile,
  work: state.work.work,
  subtitleWorkList: state.work.subtitleWorkList,
  quicklyPosition: state.work.quicklyPosition,
  submitting: state.work.submitting,
});

const mapDispatchToProps = dispatch => ({
  onFilesChange: files => dispatch(actions.onFilesChange(files)),
  onSubtitleWorkListChange: subtitleWorkList => dispatch(actions.onSubtitleWorkListChange(subtitleWorkList)),
  onCurrFileChange: currFile => dispatch(actions.onCurrFileChange(currFile)),
  onSubmittingChange: submitting => dispatch(actions.onSubmittingChange(submitting)),
});

export default connect(mapStateToPropos, mapDispatchToProps)(SubtitlePreview);
