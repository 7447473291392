import React from "react";
import { Row, Col, Button, Input } from "antd";
import { useTranslation } from "react-i18next";
import { ReactComponent as RotateLeft } from "@/assets/icons/rotate-left.svg";
import { ReactComponent as RotateRight } from "@/assets/icons/rotate-right.svg";
import { ReactComponent as MirrorH } from "@/assets/icons/mirror-horizontal.svg";
import { ReactComponent as MirrorV } from "@/assets/icons/mirror-vertical.svg";
import "@/assets/crop_rotateWork.scss";

export const CropRotateWorkRender = ({
  currFile,
  resolution,
  onResolutionChange,
  handleRotateChange,
  handleFlipChange,
  preSubmit,
  width,
  height,
  handleHeightChange,
  handleWidthChange,
  handleHeightBlur,
  handleWidthBlur,
  handleHeightFocus,
  handleWidthFocus,
}) => {
  const { t } = useTranslation(["cropRotateWork", "common"]);
  return (
    <div className={"CropRotateWork" + (!currFile ? " disabled" : "")}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row gutter={[0, 16]} style={{ padding: "0 1rem" }}>
            <Col span={8} style={{ minWidth: "33.333333%", flex: "auto", maxWidth: "fit-content" }}>
              <div
                className={`setCropBtn ${resolution === "custom" ? "active" : ""}`}
                onClick={() => {
                  onResolutionChange("custom");
                }}
              >
                {t("Custom")}
              </div>
            </Col>
            <Col span={8} style={{ minWidth: "33.333333%", flex: "auto", maxWidth: "fit-content" }}>
              <div
                className={`setCropBtn ${resolution === "origin" ? "active" : ""}`}
                onClick={() => {
                  onResolutionChange("origin");
                }}
              >
                {t("Original")}
              </div>
            </Col>
            <Col span={8} style={{ minWidth: "33.333333%", flex: "auto", maxWidth: "fit-content" }}>
              <div
                className={`setCropBtn ${resolution === 1 ? "active" : ""}`}
                onClick={() => {
                  onResolutionChange(1);
                }}
              >
                1 : 1
              </div>
            </Col>
            <Col span={8} style={{ minWidth: "33.333333%", flex: "auto", maxWidth: "fit-content" }}>
              <div
                className={`setCropBtn ${resolution === 1.77 ? "active" : ""}`}
                onClick={() => {
                  onResolutionChange(1.77);
                }}
              >
                16 : 9
              </div>
            </Col>
            <Col span={8} style={{ minWidth: "33.333333%", flex: "auto", maxWidth: "fit-content" }}>
              <div
                className={`setCropBtn ${resolution === 0.56 ? "active" : ""}`}
                onClick={() => {
                  onResolutionChange(0.56);
                }}
              >
                9 : 16
              </div>
            </Col>
            <Col span={8} style={{ minWidth: "33.333333%", flex: "auto", maxWidth: "fit-content" }}>
              <div
                className={`setCropBtn ${resolution === 1.33 ? "active" : ""}`}
                onClick={() => {
                  onResolutionChange(1.33);
                }}
              >
                4 : 3
              </div>
            </Col>
            <Col span={8} style={{ minWidth: "33.333333%", flex: "auto", maxWidth: "fit-content" }}>
              <div
                className={`setCropBtn ${resolution === 1.25 ? "active" : ""}`}
                onClick={() => {
                  onResolutionChange(1.25);
                }}
              >
                5 : 4
              </div>
            </Col>
            <Col span={8} style={{ minWidth: "33.333333%", flex: "auto", maxWidth: "fit-content" }}>
              <div
                className={`setCropBtn ${resolution === 0.8 ? "active" : ""}`}
                onClick={() => {
                  onResolutionChange(0.8);
                }}
              >
                4 : 5
              </div>
            </Col>
            <Col span={8} style={{ minWidth: "33.333333%", flex: "auto", maxWidth: "fit-content" }}>
              <div
                className={`setCropBtn ${resolution === 0.75 ? "active" : ""}`}
                onClick={() => {
                  onResolutionChange(0.75);
                }}
              >
                3 : 4
              </div>
            </Col>
          </Row>
          <Row gutter={[0, 16]} style={{ padding: "0 1.4rem" }}>
            {resolution === "custom" ? (
              <Col span={11}>
                <Input value={width ? width.toFixed(0) : width} onChange={handleWidthChange} onFocus={handleWidthFocus} onBlur={handleWidthBlur} />
              </Col>
            ) : null}
            {resolution === "custom" ? (
              <Col span={2} style={{ textAlign: "center" }}>
                <span>x</span>
              </Col>
            ) : null}
            {resolution === "custom" ? (
              <Col span={11}>
                <Input
                  value={height ? height.toFixed(0) : height}
                  onChange={handleHeightChange}
                  onFocus={handleHeightFocus}
                  onBlur={handleHeightBlur}
                />
              </Col>
            ) : null}
          </Row>
          <hr />
          <Row gutter={[0, 16]} style={{ padding: "0 1rem" }}>
            <Col span={6}>
              <div
                className={`setRotateBtn`}
                onClick={() => {
                  handleRotateChange("left");
                }}
              >
                <RotateLeft />
              </div>
            </Col>
            <Col span={6}>
              <div
                className={`setRotateBtn`}
                onClick={() => {
                  handleRotateChange("right");
                }}
              >
                <RotateRight />
              </div>
            </Col>
            <Col span={6}>
              <div
                className={`setRotateBtn`}
                onClick={() => {
                  handleFlipChange("horizontal");
                }}
              >
                <MirrorH />
              </div>
            </Col>
            <Col span={6}>
              <div
                className={`setRotateBtn`}
                onClick={() => {
                  handleFlipChange("vertical");
                }}
              >
                <MirrorV />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="save">
        <Button type="primary" block={true} onClick={preSubmit} size="large">
          {t("common:Save")}
        </Button>
      </div>
    </div>
  );
};
