export default {
  'Image': 'Gambar',
  'Text': 'Teks',
  'Add image': 'Tambah gambar',
  'Opacity': 'Opasitas',
  "Add text": 'Tambah teks',
  'Text Color': 'Warna teks',
  'Outline': 'Outline',
  'Background': 'Latar',
  'Text here': 'Teks disini',
}