export default {
  'Image': 'Изображение',
  'Text': 'Текст',
  'Add image': 'Добавить изображение',
  'Opacity': 'Непрозрачность',
  "Add text": 'Добавить текст',
  'Text Color': 'Цвет текста',
  'Outline': 'Контур',
  'Background': 'Фон',
  'Text here': 'Введите текст здесь',
}