/**
 * 重新定义的一个本地存储类，增加了过期的监听
 */
export default class Storage {
  constructor(props) {
    this.props = props || {};
    this.source = this.props.source || window.localStorage;
    this.initRun();
  }
  initRun() {
    const reg = new RegExp("__expires__");
    let data = this.source;
    let list = Object.keys(data);
    if (list.length > 0) {
      list.map((key, v) => {
        if (!reg.test(key)) {
          let now = Date.now();
          let expires = data[`${key}__expires__`] || Date.now + 1;
          if (now >= expires) {
            this.remove(key);
          }
        }
        return key;
      });
    }
  }

  set(key, value, expired) {
    /*
     * set 存储方法
     * @ param {String}     key 键
     * @ param {String}     value 值，
     * @ param {String}     expired 过期时间，以毫秒为单位，非必须
     */
    let source = this.source;
    if (value && Object.prototype.toString.call(value) === "[object Object]") {
      source[key] = JSON.stringify(value);
    } else if (JSON.stringify(value) !== "undefined" && JSON.stringify(value) !== "null") {
      source[key] = value;
    }
    if (expired) {
      source[`${key}__expires__`] = Date.now() + expired;
    }
    return value;
  }

  get(key) {
    /*
     * get 获取方法
     * @ param {String}     key 键
     * @ param {String}     expired 存储时为非必须字段，所以有可能取不到，默认为 Date.now+1
     */
    const source = this.source;
    const expired = source[`${key}__expires__`] || Date.now + 1;
    const now = Date.now();
    if (now >= expired) {
      this.remove(key);
      return;
    }
    let value;
    if (source[key] && typeof source[key] == "string") {
      try {
        value = JSON.parse(source[key]);
      } catch (e) {
        value = source[key];
      }
    } else {
      value = source[key];
    }
    return value;
  }

  remove(key) {
    const data = this.source,
      value = data[key];
    delete data[key];
    delete data[`${key}__expires__`];
    return value;
  }
}
